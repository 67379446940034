import { FulfillableItemWithProductDataAndQuantity } from '@monolith/legacy/types/fulfillment';

export interface State {
  items: FulfillableItemWithProductDataAndQuantity[];
}

const getInitialState = (): State => ({
  items: [],
});

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    hasItems: (state: State): boolean => {
      return !!state.items.length;
    },
    hasItemsQuantity: (state: State): boolean => {
      return state.items.some((item) => item.declaredQuantity !== 0);
    },
    getAllItems: (state: State): FulfillableItemWithProductDataAndQuantity[] => state.items,
    getItemById: (state: State) => (id: string) => {
      return state.items.find((item) => item.fulfillmentItemId === id);
    },
  },

  mutations: {
    ADD_ITEM(state: State, item: FulfillableItemWithProductDataAndQuantity): void {
      const targetItem = state.items.find((existingItem) => existingItem.fulfillmentItemId === item.fulfillmentItemId);

      if (targetItem === undefined) {
        state.items.unshift(item);
        state.items = [...state.items];
      }
    },
    REMOVE_ITEM(state: State, fulfillmentItemId: string): void {
      const targetItemIndex = state.items.findIndex((existingItem) => existingItem.fulfillmentItemId === fulfillmentItemId);

      if (targetItemIndex !== undefined) {
        state.items.splice(targetItemIndex, 1);
      }
    },
    UPDATE_ITEM_QUANTITY(state: State, item: FulfillableItemWithProductDataAndQuantity): void {
      const targetItem = state.items.find((existingItem) => existingItem.fulfillmentItemId === item.fulfillmentItemId);

      if (targetItem !== undefined) {
        targetItem.declaredQuantity = item.declaredQuantity;
      }
      // reassign to re-evaluate computed properties
      state.items = [...state.items];
    },
    RESET_STATE(state: State) {
      Object.assign(state, getInitialState());
    },
  },

  actions: {
    addItem: ({ commit }, { itemData }: { itemData: FulfillableItemWithProductDataAndQuantity }) => {
      commit('ADD_ITEM', itemData);
    },
    removeItem: ({ commit }, { itemData }: { itemData: FulfillableItemWithProductDataAndQuantity }) => {
      commit('REMOVE_ITEM', itemData.fulfillmentItemId);
    },
    updateItemQuantity: ({ commit }, { itemData }: { itemData: FulfillableItemWithProductDataAndQuantity }) => {
      commit('UPDATE_ITEM_QUANTITY', itemData);
    },
    resetState({ commit }) {
      commit('RESET_STATE');
    },
  },
};
