import { Retailer } from '@monolith/legacy/types/api/retailer';

export type RetailerProperty = Pick<Retailer, 'id'> & Partial<Pick<Retailer, 'name'>>;

export function createRetailerProperty({ id, name }: RetailerProperty): RetailerProperty {
  return {
    id,
    name,
  };
}
