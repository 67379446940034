import AnalyticsEvent from '../analytics-event';
import { SignUpProperty, createSignUpProperty } from '../../properties/sign-up-property';
import { OriginProperty } from '@monolith/legacy/services/analytics/properties/origin-property';

export default class NotRetailerBackEvent extends AnalyticsEvent {
  public readonly name = 'Sign Up Pop Up Sorry Go Back';
  public properties: {
    signUp: SignUpProperty;
    origin: OriginProperty;
  };

  constructor(signUp: SignUpProperty, origin: OriginProperty) {
    super();
    this.properties = {
      signUp: createSignUpProperty(signUp),
      origin,
    };
  }
}
