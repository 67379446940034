<template>
  <router-link :to="{ name: redirectUrl }">
    <template #default="{ href, navigate }">
      <a
        :href="href"
        class="badge badge--accent-alt"
        :class="['badge--' + size, { 'badge--no-padding': noPadding }]"
        @click="
          trackAnalyticsEvent();
          navigate($event);
        "
      >
        <img
          class="label-plus"
          src="/images/logo/ankorstore_plus_symbol.svg"
          :alt="$t('ankorstorePlus.program.name')"
        />
      </a>
    </template>
  </router-link>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

import Analytics from '@monolith/legacy/services/analytics';
import UserClick from '@monolith/legacy/services/analytics/events/user-click.event';

import { OfferProgramEligibility } from '@monolith/legacy/modules/promote-engage/services/offer';

import { useStore } from '@core/composables/use-store';

export default defineComponent({
  name: 'AnkorstorePlusBadge',
  props: {
    size: {
      type: String,
      default: 'md',
      validator: function (value: string) {
        return ['md', 'xl'].indexOf(value) !== -1;
      },
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();

    const ankorstorePlus = computed((): OfferProgramEligibility | null => {
      return store.getters['offers/ankorstorePlus'];
    });

    const trackAnalyticsEvent = () => {
      Analytics.track(
        new UserClick({
          component: 'ankorstore-plus-badge',
          action: 'click_ankorstore_plus_badge',
        })
      );
    };
    const redirectUrl = computed(() => {
      return ankorstorePlus.value?.optIn ? 'ankorstore-plus' : 'ankorstore-plus-signup';
    });
    return {
      trackAnalyticsEvent,
      ankorstorePlus,
      redirectUrl,
    };
  },
});
</script>
<style lang="scss" scoped>
.badge {
  @apply ds-rounded-full ds-px-1 ds-py-1 ds-inline-flex;
  &--accent-alt {
    @apply ds-bg-accent-alt;
  }
  &--md {
    width: 21px;
    height: 21px;
  }
  &--xl {
    height: 35px;
    width: 35px;
    @apply ds-p-2;
  }
  &--no-padding {
    @apply ds-p-0;
  }
}
</style>
