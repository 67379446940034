import products from './products';

import List, { Lists, Status } from '@monolith/legacy/types/wishList';
import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';
import {
  trackProductAddedToWishlist,
  trackProductRemovedFromWishlist,
  trackProductWishlistCreated,
  trackProductWishlistDeleted,
} from './analytics';
import http from '@monolith/legacy/services/api/http';

export interface State {
  lists: Lists;
  status: string;
  productLikeCount: {
    count: number;
    shouldDisplayTooltip: boolean;
  };
}

export const createInitialState = (): State => ({
  lists: {},
  status: Status.pending,
  productLikeCount: {
    count: 0,
    shouldDisplayTooltip: false,
  },
});

export default {
  namespaced: true,
  state: createInitialState,
  modules: {
    products,
  },
  actions: {
    ...actions,

    async createNewList({ commit }, { name }): Promise<List> {
      const {
        data: { data },
      } = await http().post('/api/wishlists', { name });

      const newList: List = {
        ...data,
        editable: true,
        collapsed: false,
        productsChecked: [],
        products: [],
        sort: {
          column: 'addedAt',
          direction: 'desc',
        },
      };

      trackProductWishlistCreated(newList);

      commit('SET_NEW_LIST', newList);

      return Promise.resolve(newList);
    },
    setEditableMode({ commit }, list: List): void {
      commit('SET_EDITABLE_MODE', list);
    },
    async renameWishList({ commit }, newList: List): Promise<void> {
      await http().patch(`/api/wishlists/${newList.id}`, newList);
      commit('SET_NEW_LIST', { ...newList, editable: false });
    },
    addProductsToMoveList({ commit }, { item, isChecked }): void {
      isChecked ? commit('SET_TEMP_PRODUCT_TO_MOVE', item) : commit('UNSET_TEMP_PRODUCT_TO_MOVE', item);
    },
    async moveProductToAnotherlist(context, { from, to, createdList }): Promise<void> {
      const fromList = context.getters.getList(from) as List;
      const toList = context.getters.getList(to) as List;

      const {
        data: { data },
      } = await http().put(`/api/wishlists/${fromList.id}/move/${toList.id}`, {
        products: fromList.productsChecked,
      });

      await context.dispatch('updateAList', { listId: toList.id });
      trackProductRemovedFromWishlist(fromList, fromList.productsChecked);
      trackProductAddedToWishlist(toList, fromList.productsChecked);

      if (createdList) {
        data[1].editable = true;
      }
      context.commit(
        'MOVE_PRODUCTS_TO_ANOTHER_LIST',
        Object.fromEntries(
          data.map((list) => [
            list.id,
            {
              ...list,
              productsChecked: [],
              products: [],
              collapsed: list.id !== to,
              sort: {
                column: 'addedAt',
                direction: 'desc',
              },
            },
          ])
        )
      );
      await context.commit('SET_STATUS_OF_WISHLIST', {
        listId: fromList.id,
        status: Status.success,
      });
      await context.commit('SET_STATUS_OF_WISHLIST', {
        listId: toList.id,
        status: Status.success,
      });
    },
    async moveProductToAnotherlistDirectly({ commit, dispatch, getters }, { from, to, productId }): Promise<void> {
      const fromList = getters.getList(from) as List;
      const toList = getters.getList(to) as List;

      const {
        data: { data },
      } = await http().put(`/api/wishlists/${fromList.id}/move/${toList.id}`, {
        products: [productId],
      });

      trackProductRemovedFromWishlist(fromList, [productId]);
      trackProductAddedToWishlist(toList, [productId]);

      commit(
        'MOVE_PRODUCTS_TO_ANOTHER_LIST',
        Object.fromEntries(
          data.map((list) => [
            list.id,
            {
              ...list,
              productsChecked: [],
              products: [],
              collapsed: list.id !== to,
              sort: {
                column: 'addedAt',
                direction: 'desc',
              },
            },
          ])
        )
      );
      dispatch('movedProductToListSuccess');
    },
    refreshWishlist({ dispatch, commit }): Promise<void[]> {
      commit('CLEAR_LISTS');
      return Promise.all([dispatch('fetchWishlist'), dispatch('fetchRetailerProductLikeCount')]);
    },
    addAllProductsOfListToMove({ commit, state }, { listId, checked }): void {
      checked
        ? commit('SET_ALL_TEMP_PRODUCTS_TO_MOVE', {
            products: state.lists[listId].products.map((product) => [product.id]).flat(1),
            listId: listId,
          })
        : commit('SET_ALL_TEMP_PRODUCTS_TO_MOVE', {
            products: [],
            listId: listId,
          });
    },
    setStatus({ commit }, status): void {
      commit('SET_STATUS', status);
    },
    async removeList({ commit, dispatch }, list: List) {
      commit('REMOVE_LIST', list);
      try {
        await http().delete(`/api/wishlists/${list.id}`);
      } catch (e) {
        dispatch('refreshWishlist');
      }

      trackProductWishlistDeleted(list);
    },
    movedProductToListSuccess() {},
  },
  mutations: {
    ...mutations,
    SET_STATUS(state: State, status): void {
      state.status = status;
    },
    SET_LISTS(state: State, lists: Lists): void {
      state.lists = lists;
    },
    SET_NEW_LIST(state: State, list: List): void {
      state.lists = {
        ...state.lists,
        [list.id]: { ...list },
      };
    },
    SET_COLLAPSE(state: State, { id, isCollapsed }): void {
      state.lists[id].collapsed = isCollapsed;
    },
    SET_ALL_TEMP_PRODUCTS_TO_MOVE(state: State, { listId, products }): void {
      state.lists[listId].productsChecked = products;
    },
    SET_TEMP_PRODUCT_TO_MOVE(state: State, item): void {
      state.lists[item.listId].productsChecked.push(item.product.id);
    },
    UNSET_TEMP_PRODUCT_TO_MOVE(state: State, item): void {
      state.lists[item.listId].productsChecked = state.lists[item.listId].productsChecked.filter((id) => id != item.product.id);
    },
    MOVE_PRODUCTS_TO_ANOTHER_LIST(state: State, lists): void {
      state.lists = { ...state.lists, ...lists };
    },
    UPDATE_PRODUCTS_OF_LIST(state: State, { products, listId, meta }): void {
      state.lists[listId].products = products;
      state.lists[listId].meta = meta;
    },
    ADD_PRODUCTS_TO_LIST(state: State, { products, listId, meta }): void {
      state.lists[listId].products = [...state.lists[listId].products, ...products];
      state.lists[listId].meta = meta;
    },
    CHANGE_SORT(state: State, { sort, listId }): void {
      state.lists[listId].sort = sort;
    },
    CLEAR_LISTS(state: State): void {
      state.lists = {};
    },
    SET_EDITABLE_MODE(state: State, { id }: List): void {
      const list = state.lists[id];
      state.lists[id] = { ...list, editable: true };
    },
    REMOVE_LIST(state: State, list: List): void {
      const newList = { ...state.lists };
      delete newList[list.id];
      state.lists = newList;
    },
  },
  getters,
};
