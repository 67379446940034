import { getCurrentInstance } from 'vue';
import { getCategories } from '@monolith/legacy/services/metas/categories';

const useGlobals = () => {
  const vm = getCurrentInstance().proxy;
  return Object.freeze({
    lang: vm.$lang,
    cdn: vm.$cdn,
    breakpoints: vm.$breakpoints,
    currency: vm.$currency,
    isImpersonating: vm.$isImpersonating,
    categories: getCategories(),
    tags: vm.$tags,
    theme: vm.$theme,
    formatPrice: vm.formatPrice,
    isEnabled: vm.$isEnabled as (name: string) => boolean,
  });
};

export default useGlobals;
