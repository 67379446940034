import AnalyticsEvent from './analytics-event';
import { ConversationProperty, createConversationProperty } from '../properties/conversation-property';
import { Conversation } from '@monolith/legacy/types/conversation';

export default class MessageReadEvent extends AnalyticsEvent {
  public readonly name = 'Message Read';
  public properties: {
    conversation: ConversationProperty;
  };

  constructor(conversation: Conversation) {
    super();
    this.properties = {
      conversation: createConversationProperty(conversation),
    };
  }
}
