<template>
  <div v-if="!isCheckoutPage">
    <div>
      <AkIcon
        symbol="search"
        size="md"
        @click="onFocus()"
      />
    </div>
    <AkModal
      ref="productSearchMobileModal"
      size="2xl"
      :with-padding="false"
      :with-closer="false"
    >
      <search-autocomplete @close="closeModal" />
    </AkModal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SearchAutocomplete from './search-autocomplete.vue';
import { AkModal, AkIcon } from '@ankorstore/design-system';
import useIsCheckout from '@core/composables/use-enclosed-checkout';
import { useSearchStore } from '../composables/use-search-store';

export default defineComponent({
  name: 'SearchMobile',
  components: {
    AkModal,
    AkIcon,
    SearchAutocomplete,
  },
  setup() {
    useSearchStore();
    const { isCheckoutPage } = useIsCheckout();

    return {
      isCheckoutPage,
    };
  },
  methods: {
    onFocus(): void {
      (this.$refs.productSearchMobileModal as DS['AkModal']).openModal();
    },
    closeModal() {
      (this.$refs.productSearchMobileModal as DS['AkModal']).close();
    },
  },
});
</script>
<style scoped lang="scss">
@import '@css/vue-import';
:deep(.ak-modal .ak-modal__wrapper) {
  @apply ds-align-top;
}

:deep(.ak-modal .ak-modal__container) {
  @apply md:ds-bg-transparent;
}
</style>
