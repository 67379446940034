<!-- eslint-disable @intlify/vue-i18n/no-dynamic-keys -->
<template>
  <div>
    <div
      v-if="isRevamp"
      class="country-select"
    >
      <AkAutocomplete
        v-model="selectedCountry"
        :label="$t(question.label)"
        :options="options"
        required
        data-testid="registerAddressCountrySelect"
        :reset-btn-visible="false"
        @change="(value) => onCountryChanged(+value)"
      />
    </div>
    <div
      v-else
      class="form-label-group select-custom-box"
    >
      <select
        id="country"
        v-model="selectedCountry"
        required
        class="form-control hide-placeholder-on-focus"
        data-testid="registerAddressCountrySelect"
        @change="(e) => onCountryChanged(+e.target.value)"
      >
        <optgroup
          v-for="(countriesList, index) in countries"
          :key="index"
        >
          <option
            v-for="country in countriesList"
            :key="country.id"
            data-testid="registerAddressOption"
            :value="country.id"
          >
            {{ country.name }}
          </option>
        </optgroup>
      </select>
    </div>
  </div>
</template>

<script lang="ts">
// @ts-nocheck
import { defineComponent } from 'vue';
import Country, { Countries } from '@core/types/country';
import { mapGetters } from 'vuex';
import { Question } from '@monolith/legacy/types/question';
import { AkAutocomplete } from '@ankorstore/design-system';

export default defineComponent({
  name: 'CountrySelect',
  components: {
    AkAutocomplete,
  },
  props: {
    question: {
      type: Object as () => Question,
      required: true,
    },
    isRevamp: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['change'],
  data() {
    return {
      selectedCountry: 0,
    };
  },
  computed: {
    ...mapGetters(['userCountry']),
    ...mapGetters('global/countries', ['getOpenedRetailerCountries', 'getCountriesWithoutRetailersOpened']),
    getParsedOpenedRetailerCountries() {
      return Object.values(this.getOpenedRetailerCountries).sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
    },
    getParsedCountriesWithoutRetailersOpened() {
      return Object.values(this.getCountriesWithoutRetailersOpened as Countries).sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
    },
    countries(): Country[][] {
      return [[...this.getParsedOpenedRetailerCountries], [...this.getParsedCountriesWithoutRetailersOpened]];
    },
    options(): AkSelectOption[] {
      const emptyOption = { label: '', value: null, disabled: true };
      const options: AkSelectOption[] = [];
      this.countries.flatMap((countriesGroup, index) => {
        if (!!index && countriesGroup.length) {
          options.push(emptyOption);
        }
        countriesGroup.map((country) => options.push(this.getOption(country)));
      });
      return options;
    },
  },
  async created() {
    await this.$store.dispatch('global/countries/fetchCountries');
    const { id } = this.$store.state.global.countries.countries[this.userCountry];
    this.onCountryChanged(id);
  },
  methods: {
    getCountry(id: number): Country {
      return this.countries.flat().find((item) => item.id === id);
    },
    getOption(country: Country): AkSelectOption {
      return {
        label: country.name,
        value: country.id,
      };
    },
    onCountryChanged(countryId) {
      this.selectedCountry = countryId;
      const country = this.getCountry(countryId);
      this.$store.dispatch('signUp/setCountryCode', country?.iso_code);
      this.$emit('change', this.question.alias, {
        answer_id: country?.id,
        content: country?.id,
      });
    },
  },
});
</script>
<style lang="scss">
.country-select {
  .ak-autocomplete__icons {
    .icon-x {
      @apply ds-hidden;
    }
  }
}
</style>
