import { roundDecimals } from '@monolith/legacy/services/utils/round';
import { ProductCollectionItem } from '@monolith/legacy/types/account/product-collections';
import { TailwindConfig } from '@ankorstore/design-system';
import { forEach } from 'lodash-es';
import { BUDGET_CONFIG } from '@bc/advertisement/feature/ads-dashboard/ads-dashboard.config';
import Product from '@monolith/legacy/types/product';
import {
  AdsPerformanceGraphs,
  GraphContentSlug,
  TimeRange,
  TimeRangeOption,
} from '@bc/advertisement/feature/ads-dashboard/types/ads-dashboard.types';
import i18n from '@monolith/legacy/services/i18n';
import {
  AdSectionType,
  AdsTableItem,
  CampaignInfo,
  CampaignInfoApiResponse,
  IntervalEnum,
  OriginType,
  TimeSeriesData,
  trackAdCampaignFunnel,
} from '@bc/advertisement/domain';
import { Amount } from '@core/types/amount';
import { comparePrices, getMaxAmount, getMinAmount, toUnit } from '@core/utilities/price';
import { captureMonetizationException } from '@core/plugins/sentry/helper';
import { differenceInDays, differenceInSeconds, endOfToday, startOfDay, subDays } from 'date-fns';
import { formatDate } from '@monolith/legacy/services/date-locale';
import { getCurrency } from '@monolith/legacy/services/metas/currency';
import { CampaignWizardStep } from '@bc/advertisement/feature/ads-dashboard/types/campaign-creation.types';

/**
 * Calculates the difference between two dates in days. Can be float number.
 * @param startDate
 * @param endDate
 */
export const calculateDateDifferenceInDays = (startDate: Date, endDate: Date): number => {
  // Use seconds for a bigger precision since here we consider duration as float number
  return differenceInSeconds(endDate, startDate) / 24 / 60 / 60;
};

/**
 * Calculates the maximum budget for the campaign based on the weekly budget and the number of days
 * @param weeklyBudget
 * @param days
 * @returns {number}
 */
export const calculateMaxBudget = (weeklyBudget: number, days: number): number => {
  return weeklyBudget * (days / 7);
};

/**
 * Calculates the maximum clicks for the campaign based on the maximum budget and the cost per click
 * @param maxBudget
 * @param costPerClick
 * @returns {number}
 */
export const calculateMaxClicks = (maxBudget: number, costPerClick: number): number => {
  return roundDecimals(maxBudget / costPerClick, 0);
};

/**
 * It calculates the price range for a product collection item and returns it as a string in the format of `priceMin - priceMax`
 * In the case where the min and max prices are the same, it returns only one price
 * @param item
 * @returns {[Amount] | [Amount, Amount]}
 */
export const calculatePriceRangeForProductCollectionItem = (
  item: ProductCollectionItem | Product
): [Amount, Amount] | [Amount] => {
  const wholesalePrices = item.variants.map((variant) => ({ ...variant.price.wholesale_price }));
  const min = getMinAmount(wholesalePrices);
  const max = getMaxAmount(wholesalePrices);

  // If the min and max prices are the same, return only one price
  if (comparePrices({ ...min, precision: 2 }, { ...max, precision: 2 })) {
    return [min];
  }

  return [min, max];
};

export const calculateTotalStockFroProductCollectionItem = (item: ProductCollectionItem | Product): number => {
  let totalStock = 0;

  forEach(item.variants, (variant) => {
    totalStock += variant.stock?.stock_quantity;
  });

  return totalStock;
};

export const getWeeklyBudgetFromTotalAmount = (totalBudget: number, days: number | undefined): number => {
  // if totalBudget is undefined, return BUDGET_CONFIG default value
  if (!totalBudget || !days) {
    captureMonetizationException(new Error('Weekly budget match not found'), [
      { label: 'totalBudget', value: totalBudget.toString() },
      { label: 'days', value: days.toString() },
    ]);

    return BUDGET_CONFIG.find((budget) => budget.isDefault)?.value || 0;
  }

  const approximatedWeeklyBudget = totalBudget / (days / 7);
  // It finds the closest value to BUDGET_CONFIG value for approximatedWeeklyBudget
  const closestBudget = BUDGET_CONFIG.reduce((prev, curr) => {
    return Math.abs(curr.value - approximatedWeeklyBudget) < Math.abs(prev.value - approximatedWeeklyBudget) ? curr : prev;
  });

  return closestBudget.value;
};

export const parseCampaignInfoResponse = (campaignInfo: CampaignInfoApiResponse): CampaignInfo => {
  return {
    ...campaignInfo,
    startDate: new Date(campaignInfo.startDate),
    endDate: new Date(campaignInfo.endDate),
  };
};

export const parseProductToAdsProductItem = (product: Product): AdsTableItem => {
  return {
    images: product.images,
    name: product.name,
    options: product.options,
    retail_price: product.retail_price,
    variants: product.variants,
    product_id: product.id,
    product_uuid: product.uuid,
    priceRange: calculatePriceRangeForProductCollectionItem(product),
    totalStock: calculateTotalStockFroProductCollectionItem(product),
  };
};

/**
 * This function will parse the time series data to the format that the graph component needs
 * @param timeSeriesData
 * @param revenueTimeSeriesData
 * @returns {AdsPerformanceGraphs}
 */
export function parseTimeSeriesDataToGraphData(
  timeSeriesData: TimeSeriesData[],
  revenueTimeSeriesData: TimeSeriesData[] = timeSeriesData
): AdsPerformanceGraphs {
  const parsedData: AdsPerformanceGraphs = {
    [GraphContentSlug.Impressions]: {
      labels: [],
      datasets: [
        {
          label: i18n.global.t('graphSection.impressionsLine.label'),
          backgroundColor: TailwindConfig.theme.colors.accent[700],
          data: [],
        },
      ],
    },
    [GraphContentSlug.Clicks]: {
      labels: [],
      datasets: [
        {
          label: i18n.global.t('graphSection.clicks.label'),
          backgroundColor: TailwindConfig.theme.colors.accent[700],
          data: [],
        },
      ],
    },
    [GraphContentSlug.Revenue]: {
      labels: [],
      datasets: [
        {
          label: i18n.global.t('graphSection.revenue.label'),
          backgroundColor: TailwindConfig.theme.colors.accent[700],
          data: [],
        },
      ],
    },
  };

  timeSeriesData.forEach((data) => {
    const formattedDate = formatDate(data.date, 'PP');

    parsedData[GraphContentSlug.Impressions].labels.push(formattedDate);
    parsedData[GraphContentSlug.Clicks].labels.push(formattedDate);

    parsedData[GraphContentSlug.Impressions].datasets[0].data.push(data.impressionsCount);
    parsedData[GraphContentSlug.Clicks].datasets[0].data.push(data.clicksCount);
  });

  revenueTimeSeriesData.forEach((data) => {
    const formattedDate = formatDate(data.date, 'PP');

    parsedData[GraphContentSlug.Revenue].labels.push(formattedDate);

    parsedData[GraphContentSlug.Revenue].datasets[0].data.push(
      toUnit({ amount: data.gmvAmount, currency: getCurrency(), precision: 2 })
    );
  });

  return parsedData;
}

/**
 * Function to track the funnel steps, transforms the enum step number into the corresponding section and track the funnel
 */
export async function trackFunnelStep(step: CampaignWizardStep, origin: OriginType) {
  const originStep = `step_${step + 1}` as AdSectionType;

  await trackAdCampaignFunnel(originStep, origin);
}

/**
 * Function to get start and end date range depending on provided range option.
 * Custom range option is not handled since it should be entered manually by user
 */
export function getTimeRangeByOption(option: TimeRangeOption): TimeRange {
  const daysToSub: number =
    {
      [TimeRangeOption.LastMonth]: 30,
      [TimeRangeOption.Last3Months]: 91,
      [TimeRangeOption.LastYear]: 365,
    }[option] ?? 30;
  const endDate = endOfToday();
  const startDate = startOfDay(subDays(endDate, daysToSub));

  return { startDate, endDate };
}

/**
 * Function to get proper step interval for a dashboard graph depending on a time range
 */
export function getIntervalFromTimeRange(startDate: Date, endDate: Date) {
  const daysDiff = differenceInDays(endDate, startDate);

  switch (true) {
    case daysDiff <= 31:
      return IntervalEnum.Daily;
    case daysDiff <= 92:
      return IntervalEnum.Weekly;
    case daysDiff <= 1000:
      return IntervalEnum.Monthly;
    case daysDiff > 1000:
      return IntervalEnum.Quarterly;
    default:
      return IntervalEnum.Weekly;
  }
}
