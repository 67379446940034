import { OfferConditionResolutionContext, SitewideOfferContent } from '@monolith/legacy/types/offer';
import { resolveSitewideOfferContent } from '@monolith/legacy/utilities/offer';
import { defineComponent } from 'vue';

export const sitewideOfferMixin = defineComponent({
  name: 'SitewideOfferMixin',
  data() {
    return {
      sitewideOfferContent: {} as SitewideOfferContent,
    };
  },
  watch: {
    sitewideOfferContext: {
      handler: function (context: OfferConditionResolutionContext) {
        this.loadOfferContent(context);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async loadOfferContent(context: OfferConditionResolutionContext) {
      if (!context) {
        return;
      }
      this.sitewideOfferContent = (await resolveSitewideOfferContent(context)) || {};
    },
  },
});
