import { BrandProperty, createBrandProperty } from '../properties/brand-property';
import { ProductProperty, createProductProperty } from '../properties/product-property';
import AnalyticsEvent from './analytics-event';
import { IdAlgo } from '@bc/shared';

export class ProductQuickAddToCartEvent extends AnalyticsEvent {
  public readonly name = 'Product Quick Added to Cart';
  public properties: {
    product: ProductProperty;
    brand: BrandProperty;
    page: number;
    quantity: number;
    id_section?: string;
    id_algo?: IdAlgo;
  };

  constructor(
    product: ProductProperty,
    brand: BrandProperty,
    page: number,
    quantity: number,
    sectionId: string,
    idAlgo?: IdAlgo
  ) {
    super();
    this.properties = {
      page: page,
      product: createProductProperty(product),
      brand: createBrandProperty(brand),
      quantity: quantity,
      id_section: sectionId,
      id_algo: idAlgo,
    };
  }
}
