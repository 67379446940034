export enum SectionType {
  BrandShipping = 'brand_shipping',
  BrandValidation = 'brand_validation',
  PaymentMethodsLoaded = 'CheckoutPaymentMethodsLoadedEvent',
  CheckoutDisplay = 'checkout_content_display_event',
  CartContentDisplay = 'cart_content_display_event',
  RecoCarousel = 'reco_carousel',
  ProductSnippet = 'product_snippet',
  // Fulfillment section types
  BrandFulfillment = 'brand_fulfillment',
  ReplenishmentDetails = 'replenishment_details',
  OfferCarousel = 'offer_carousel',
  HpBanner = 'hp_banner',
}
