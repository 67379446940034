<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="ds-fill-warning-700"
  >
    <path
      d="M10.9922 1.83203C8.96727 1.83203 7.32552 3.47378 7.32552 5.4987C5.96518 5.4987 3.77344 5.4987 3.65885 5.4987C3.15285 5.4987 2.74219 5.90936 2.74219 6.41536V16.4987C2.74219 18.5236 4.38394 20.1654 6.40885 20.1654H15.5755C17.6004 20.1654 19.2422 18.5236 19.2422 16.4987V6.41536C19.2422 5.90936 18.8315 5.4987 18.3255 5.4987C18.2109 5.4987 16.0192 5.4987 14.6588 5.4987C14.6588 3.47378 13.0171 1.83203 10.9922 1.83203ZM10.9922 3.66536C12.0051 3.66536 12.8255 4.48578 12.8255 5.4987C11.1535 5.4987 10.8308 5.4987 9.15885 5.4987C9.15885 4.48578 9.97926 3.66536 10.9922 3.66536ZM4.57552 7.33203C5.07969 7.33203 6.85893 7.33203 8.24218 7.33203C10.3175 7.33203 11.6668 7.33203 13.7422 7.33203C15.1254 7.33203 16.9047 7.33203 17.4088 7.33203V16.4987C17.4088 17.5116 16.5884 18.332 15.5755 18.332H6.40885C5.39594 18.332 4.57552 17.5116 4.57552 16.4987V7.33203Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 10C11.4142 10 11.75 10.3521 11.75 10.7864V15.2136C11.75 15.6479 11.4142 16 11 16C10.5858 16 10.25 15.6479 10.25 15.2136V10.7864C10.25 10.3521 10.5858 10 11 10Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 12.8594C8 12.4452 8.28624 12.1094 8.63934 12.1094L13.3607 12.1094C13.7138 12.1094 14 12.4452 14 12.8594C14 13.2736 13.7138 13.6094 13.3607 13.6094L8.63934 13.6094C8.28624 13.6094 8 13.2736 8 12.8594Z"
      fill="#FFFFFF"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: 'SVGAddToCartIcon',
};
</script>
