<template>
  <div
    class="ds-flex"
    :class="{
      'ds-gap-4 ds-flex-col xl:ds-gap-2 xl:ds-flex-row': isHorizontal,
      'ds-gap-4 ds-flex-col': isVertical,
    }"
  >
    <AkSelect
      v-if="isMultipleProduct"
      v-model="selectedVariantId"
      :label="computedLabel"
      :options="computedVariants"
      class="ds-flex-grow"
    />
    <div id="variant-options__quantity">
      <AkSelect
        v-model="quantity"
        class="variant-options__select"
        :label="$t('Quantity')"
        :placeholder="$t('Quantity')"
        :disabled="!selectedVariant"
        :options="listValuesForSelect"
        data-testid="productQuantitySelect"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { getFirstAvailableVariant, getMaxQuantity, getVariantQuantity, isVariantOutOfStock } from '@monolith/legacy/services/product-variants';
import Product, { Variant, VariantLayout } from '@monolith/legacy/types/product';
import { useStore } from '@core/composables/use-store';

export default defineComponent({
  name: 'VariantForm',
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
    layout: {
      type: String as PropType<VariantLayout>,
      default: VariantLayout.Vertical,
      validator: (value: VariantLayout) => Object.values(VariantLayout).includes(value),
    },
  },
  emits: ['selected-variant'],
  setup() {
    const store = useStore();

    const itemByVariantId = (id: number) => store.getters['cart/itemByVariantId'](id);

    return {
      itemByVariantId,
    };
  },
  data() {
    const { id } = getFirstAvailableVariant(this.product);
    return {
      quantity: 1,
      selectedVariantId: id,
      translatedLabels: {
        size: this.$t('brand.product.productVariant.size.label'),
        color: this.$t('brand.product.productVariant.color.label'),
        other: this.$t('brand.product.productVariant.other.label'),
      },
    };
  },
  computed: {
    isHorizontal(): boolean {
      return this.layout === VariantLayout.Horizontal;
    },
    isVertical(): boolean {
      return this.layout === VariantLayout.Vertical;
    },
    variants(): Variant[] {
      return this.product.variants.filter((variant) => !isVariantOutOfStock(variant));
    },
    isMultipleProduct(): boolean {
      return this.firstVariant.options.length > 0;
    },
    firstVariant(): Variant {
      return this.variants[0];
    },
    optionNames(): string[] {
      return this.firstVariant.options.map(({ name }) => name);
    },
    computedLabel(): string {
      return this.firstVariant.options.map(({ name }) => this.translatedLabels[name]).join(' / ');
    },
    computedVariants(): Array<{ value: number; label: string }> {
      return this.variants.map(({ id, options }) => ({
        value: id,
        label: options.map((o) => o.value).join(' / '),
      }));
    },
    selectedVariant(): Variant | undefined {
      return this.variants.find(({ id }) => id === this.selectedVariantId);
    },
    listValuesForSelect(): number[] {
      const quantityList = [];
      const alreadyInCart = Boolean(this.selectedVariant && this.itemByVariantId(this.selectedVariant.id));
      const unitMultiplier = this.product.unit_multiplier;

      let quantity = alreadyInCart ? 0 : unitMultiplier;

      const maxQuantity = getMaxQuantity(getVariantQuantity(this.selectedVariant as Variant), this.quantity, unitMultiplier);

      for (let i = alreadyInCart ? 0 : 1; i * unitMultiplier <= maxQuantity; i++) {
        quantityList.push({
          label: quantity.toString(),
          value: i,
          disabled: false,
        });
        quantity += unitMultiplier;
      }
      return quantityList;
    },
  },
  watch: {
    quantity(): void {
      this.emitSelectedVariant();
    },
    selectedVariant: {
      immediate: true,
      handler(): void {
        this.emitSelectedVariant();
      },
    },
  },
  methods: {
    emitSelectedVariant(): void {
      this.$emit('selected-variant', {
        variant: this.selectedVariant,
        quantity: this.quantity,
      });
    },
  },
});
</script>
