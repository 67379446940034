<template>
  <div>
    <AkHeading4> {{ $t('brand.account.products.csvUploadMatch.heading') }} </AkHeading4>
    <div class="ds-text-base ds-py-2">
      {{ $t('brand.account.products.csvUploadMatch.subHeading') }}
    </div>
    <div class="ds-text-left ds-mt-8">
      <AkTable
        :columns="columns"
        :data="tableData"
        responsive
      >
        <template #[`column.userHeaders`]>
          <div class="ds-flex ds-flex-wrap ds-items-baseline">
            <AkParagraph
              class="ds-mr-2"
              size="sm"
            >
              {{ $t('brand.account.products.csvUploadMatch.headers') }}
            </AkParagraph>
            <AkBadge
              size="xs"
              color="blue"
              :content="$t('brand.account.products.csvUploadMatch.sku')"
            />
          </div>
        </template>

        <template #[`item.uploadedFileHeaders`]="{ item }">
          {{ item.user_header }}
        </template>
        <template #[`item.preview`]="{ item }">
          <div class="preview">
            <p>{{ item.preview }}</p>
          </div>
        </template>
        <template #[`item.userHeaders`]="{ item }">
          <div
            :class="{ 'ds-bg-success-100': mapping && mapping[item.index] }"
            class="ds-p-2 ds-rounded-lg"
          >
            <AkSelect
              :value="mapping ? mapping[item.index] : null"
              :options="ankHeadersOptions"
              @change="onChange($event, item)"
            />
          </div>
        </template>
      </AkTable>
    </div>
  </div>
</template>

<script lang="ts">
import { AkTable, AkSelect, AkSelectOptionType } from '@ankorstore/design-system';
import { defineComponent, PropType } from 'vue';
import { ImportProductFields } from '@monolith/legacy/types/brand-onboarding';

interface HeaderOption {
  value: string;
  label: string;
  disabled: boolean;
}

interface TableRow {
  user_header: string;
  index: number;
  preview: string;
}

type TableColumn = {
  name: string;
  label: string;
  align?: string;
  width?: string;
};

type ImportProductKey = Partial<ImportProductFields>;

type MappingType = Record<number, string>;

type Item = {
  [key in ImportProductKey]: string;
};

type ProductField = {
  mandatory?: any;
  recommended?: any;
  variants_attributes?: any;
  optional?: any;
  optional_tags?: any;
  items: Item;
};

interface ComponentData {
  productFields: Array<ProductField>;
  columns: TableColumn[];
  newMapping: MappingType;
  ankHeadersOptions: HeaderOption[];
}

export default defineComponent({
  name: 'CsvUploadMatch',
  components: {
    AkTable,
    AkSelect,
  },
  props: {
    fileHeaders: {
      type: Array as PropType<string[]>,
      required: true,
    },
    firstRow: {
      type: Array as PropType<string[]>,
      default: null,
    },
    mapping: {
      type: Object as PropType<MappingType>,
      default: () => {},
    },
  },
  emits: ['mappingChanged'],
  data(): ComponentData {
    const productFields = [
      {
        mandatory: this.$t('brand.account.products.csvUploadMatch.mandatory'),
        items: {
          sku: this.$t('brand.account.products.importFields.sku'),
        }
      },
      {
        recommended: this.$t('brand.account.products.csvUploadMatch.recommended'),
        items: {
          name: this.$t('brand.account.products.importFields.name'),
          description: this.$t('brand.account.products.importFields.description'),
          wholesale_price: this.$t('brand.account.products.importFields.wholesale_price'),
          retail_price: this.$t('brand.account.products.importFields.retail_price'),
          vat_rate: this.$t('brand.account.products.importFields.vat_rate'),
          stock: this.$t('brand.account.products.importFields.stock'),
          unit_multiplier: this.$t('brand.account.products.importFields.unit_multiplier'),
          image: this.$t('brand.account.products.importFields.image'),
          dimensions: this.$t('brand.account.products.importFields.dimensions'),
          net_weight: this.$t('brand.account.products.importFields.net_weight'),
          capacity: this.$t('brand.account.products.importFields.capacity'),
        }
      },
      {
        variants_attributes: this.$t('brand.account.products.csvUploadMatch.variantsAttributes'),
        items: {
          color: this.$t('brand.account.products.importFields.color'),
          variant_image: this.$t('brand.account.products.importFields.variant_image'),
          size: this.$t('brand.account.products.importFields.size'),
          other: this.$t('brand.account.products.importFields.other'),
        }
      },
      {
        optional: this.$t('brand.account.products.csvUploadMatch.optional'),
        items: {
          hs_code: this.$t('brand.account.products.importFields.hs_code'),
          ian: this.$t('brand.account.products.importFields.ian'),
          fashion_composition: this.$t('brand.account.products.importFields.fashion_composition'),
          cosmetic_inci_list: this.$t('brand.account.products.importFields.cosmetic_inci_list'),
          grocery_ingredients_list: this.$t('brand.account.products.importFields.grocery_ingredients_list'),
          home_materials: this.$t('brand.account.products.importFields.home_materials'),
          grocery_ubd: this.$t('brand.account.products.importFields.grocery_ubd'),
          grocery_dmd: this.$t('brand.account.products.importFields.grocery_dmd'),
          made_in_country: this.$t('brand.account.products.importFields.made_in_country'),
        },
      },
      {
        optional_tags: this.$t('brand.account.products.csvUploadMatch.optionalTags'),
        items: {
          bestseller: this.$t('brand.account.products.importFields.bestseller'),
          contains_alcohol: this.$t('brand.account.products.importFields.contains_alcohol'),
          cruelty_free: this.$t('brand.account.products.importFields.cruelty_free'),
          eco_friendly: this.$t('brand.account.products.importFields.eco_friendly'),
          grocery_frozen: this.$t('brand.account.products.importFields.grocery_frozen'),
          handmade: this.$t('brand.account.products.importFields.handmade'),
          grocery_refrigerated: this.$t('brand.account.products.importFields.grocery_refrigerated'),
          organic: this.$t('brand.account.products.importFields.organic'),
          vegan: this.$t('brand.account.products.importFields.vegan'),
          no_waste: this.$t('brand.account.products.importFields.no_waste'),
        },
      },
    ];

    return {
      productFields,
      columns: [
        {
          name: 'uploadedFileHeaders',
          label: this.$t('Uploaded file headers'),
          align: 'left',
          width: '30%',
        },
        {
          name: 'preview',
          label: this.$t('Information preview'),
          align: 'left',
          width: '30%',
        },
        {
          name: 'userHeaders',
          label: this.$t('Template headers'),
          align: 'center',
          width: '40%',
        },
      ],
      newMapping: {},
      ankHeadersOptions: [],
    };
  },
  computed: {
    tableData(): TableRow[] {
      return this.fileHeaders
        ?.map((header, index) => {
          return {
            user_header: header,
            index,
            preview: this.firstRow?.[index],
          };
        })
        .filter((header) => header);
    },
  },
  created() {
    this.newMapping = this.mapping ? { ...this.mapping } : {};
    this.ankHeadersOptions = this.generateOptions();
  },
  methods: {
    headerIsSelected(header: string): boolean {
      const keyOfSavedMapping = Object.keys(this.newMapping).find((key) => this.newMapping[key] === header);
      return !!keyOfSavedMapping && !!this.fileHeaders[keyOfSavedMapping];
    },
    onChange(header, item): void {
      if (header) {
        this.newMapping[item.index] = header;
      } else {
        delete this.newMapping[item.index];
      }
      this.$emit('mappingChanged', this.newMapping);
      this.ankHeadersOptions = this.generateOptions();
    },
    generateOptions(): HeaderOption[] {
      const flatArray = [];
      const encounteredGroups = new Set();
      this.productFields.forEach(group => {
        const groupName = Object.keys(group)[0];
        const groupLabel = group[groupName];
        flatArray.push({ label: groupLabel, type: AkSelectOptionType.DIVIDER });
        encounteredGroups.add(groupLabel);
        Object.entries(group.items).forEach(([headerKey, value]) => {
          if (!encounteredGroups.has(value)) {
            flatArray.push({
              label: value,
              value: headerKey,
              disabled: this.headerIsSelected(headerKey) && !['image', 'variant_image'].includes(headerKey),
              type: null,
            });
          }
        });
      });

      return [{ label: this.$t('Select'), value: null, disabled: false }, ...flatArray];
    },
  },
});
</script>
<style scoped>
:deep(.ak-table) {
  @apply ds-table-fixed ds-w-full;
}

:deep(.ak-table td) {
  @apply ds-overflow-hidden ds-text-ellipsis;
  max-width: 33%;
}
</style>
