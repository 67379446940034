import { R2BOfferConfiguration } from '@monolith/legacy/types/r2b';
import mutations from '@monolith/legacy/store/offers/mutations';
import actions from '@monolith/legacy/store/offers/actions';
import getters from '@monolith/legacy/store/offers/getters';
import { BusinessEventPeriode } from '@monolith/legacy/types/business-event';
import { OfferProgramEligibility } from '@monolith/legacy/modules/promote-engage/services/offer';

export { OFFERS_STORE_ID } from './constants';

export interface OffersState {
  r2b: {
    offers_config: R2BOfferConfiguration;
    offers_config_fetched: boolean;
  };
  events: BusinessEventPeriode[];
  offerEligibility: {
    eligibility: OfferProgramEligibility[];
    eligibilityFetched: boolean;
  };
}

const state: OffersState = {
  r2b: {
    offers_config: null,
    offers_config_fetched: false,
  },
  events: [],
  offerEligibility: {
    eligibility: [],
    eligibilityFetched: false,
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
