<template>
  <div v-if="categories.length">
    <p class="product-type-categories__title">
      {{ $t('brand.productManagement.categories.title') }}
    </p>
    <AkAlert
      type="neutral"
      hide-icon
    >
      <template
        v-for="category in categories"
        :key="category.id"
      >
        <p class="product-type-categories__text">
          {{ category.attributes.fullPath }}
        </p>
      </template>
    </AkAlert>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { AkAlert } from '@ankorstore/design-system';
import { getProductTypeCategories } from '@bc/brands/feature/product-management/services/api';
import { ProductTypeCategories } from '@bc/brands/feature/product-management/types/product-types';
import { ProductTypeAttributeValue } from '@bc/brands/feature/product-management/types/product-type-attributes';
import { captureException } from '@core/plugins/sentry';

type ComponentDataType = {
  categories: ProductTypeCategories[];
};

export default defineComponent({
  name: 'ProductTypeCategories',
  components: {
    AkAlert,
  },
  props: {
    productTypeId: {
      type: Number,
      required: true,
    },
    attributes: {
      type: Array as PropType<ProductTypeAttributeValue[]>,
      default: () => [],
    },
  },
  data(): ComponentDataType {
    return {
      categories: [],
    };
  },
  computed: {
    productId() {
      return this.productTypeId;
    },
    attributesList() {
      return this.attributes;
    },
  },
  watch: {
    productId() {
      this.getCategories();
    },
    attributesList() {
      this.getCategories();
    },
  },
  async mounted() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      try {
        const attributes = this.attributesList.filter((item) => item.value);
        this.categories = await getProductTypeCategories(this.productId, attributes);
      } catch (error) {
        captureException(error);
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.product-type-categories__title {
  @apply ds-text-base ds-font-bold ds-text-neutral-900 ds-mb-2;
}

.product-type-categories__text {
  @apply ds-text-neutral-900 ds-mb-3;
  &:last-child {
    @apply ds-mb-0;
  }
}
</style>
