<template>
  <div class="ds-mb-6">
    <slot name="label">
      <AkFormLabel
        v-if="label"
        :text="label"
      >
        <template #info>
          <slot name="info" />
        </template>
      </AkFormLabel>
    </slot>
    <slot name="formControl" />
    <slot name="notification">
      <AkFormNotification
        v-if="message"
        :message="message"
        :variant="variant"
      />
    </slot>
  </div>
</template>

<script lang="ts">
import AkFormLabel from './ak-form-label.vue';
import AkFormNotification from './ak-form-notification.vue';
import { defineComponent, PropType } from 'vue';
import { TFieldValidateValue } from '@monolith/legacy/components/review/AkForm/types';

export default defineComponent({
  name: 'AkFormField',
  components: {
    AkFormLabel,
    AkFormNotification,
  },
  props: {
    label: {
      type: String as PropType<string | null>,
      default: null,
    },
    errorMessage: {
      type: String as PropType<TFieldValidateValue>,
      default: null,
    },
    successMessage: {
      type: String as PropType<TFieldValidateValue>,
      default: null,
    },
  },
  computed: {
    message() {
      return this.errorMessage || this.successMessage;
    },
    variant(): string {
      return this.errorMessage ? 'error' : 'success';
    },
  },
});
</script>
