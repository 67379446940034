import { deserialize } from '@monolith/legacy/services/utils/jsonapi-parser';

import ProductDraft, {
  CatalogIntegrationSetting,
  ExportCatalogue,
  FormattedProductDraft,
  CatalogueIntegrationBatch,
  IntegrationInternalState,
  ProductDraftIssues,
  ProductIntegration,
  GoogleBatchPayload,
  Workflow,
  ExportCatalogueStatus,
  CsvBatchPayload,
  XlsxBatchPayload,
} from '@monolith/legacy/types/account/account-products/catalog-integration/product-integration';
import {
  generateSpreadsheet,
  getCatalogExports,
  getCatalogIntegration,
  getCatalogIntegrationSettings,
  getBatches,
  getIntegrationStatistics,
  getSingleCatalogIntegration,
  approveIntegrations,
  integrationsGetParameters,
  postCatalogExports,
  postGoogleIntegrationBatches,
  putInternalState,
  removeSpreadsheet,
  postCsvIntegrationBatches,
  postXlsxIntegrationBatches,
} from '@monolith/legacy/services/api/brand-catalog-integration';
import { buildAdditionalDatas } from '@monolith/legacy/store/account/products';
import { Variant } from '@monolith/legacy/types/product';
import { SaveBarMode } from '@monolith/legacy/store/account/types';
import { Amount } from '@core/types/amount';
import { isMultipleProduct } from '@monolith/legacy/services/product-variants';
import { isBatchInprogress } from '@monolith/legacy/utilities/account/account-products/utils';
import { createProductRequestImages } from '@monolith/legacy/services/product';
import { getTags, Tag } from '@monolith/legacy/services/metas/tag';

export enum SelectionMode {
  All,
  READY,
  None,
}

export interface Drafts {
  [index: string]: ProductIntegration;
}

export interface State {
  drafts: Drafts;
  order: string[];
  readyOrder: string[];
  selected: string[];
  readySelected: string[];
  allReadyTotal: number;
  pagination: {
    page: number;
    perPage: number;
    total: number;
    totalReady: number;
  };
  loading: boolean;
  draftsFound: boolean;
  lastImportBatch: CatalogueIntegrationBatch;
  settings: CatalogIntegrationSetting;
  lastExport: ExportCatalogue;
  lastActivation: CatalogueIntegrationBatch;
  lastUpdate: CatalogueIntegrationBatch;
  isCsvUploadModalOpen: boolean;
}

export const createInitialState = (): State => ({
  drafts: {},
  order: [],
  readyOrder: [],
  selected: [],
  readySelected: [],
  allReadyTotal: 0,
  pagination: {
    page: 1,
    perPage: 50,
    total: 0,
    totalReady: 0,
  },
  loading: false,
  draftsFound: false,
  lastImportBatch: null,
  settings: null,
  lastExport: null,
  lastActivation: null,
  lastUpdate: null,
  isCsvUploadModalOpen: false,
});

const getUUIDs = (data: ProductIntegration[]) => {
  return data.map(({ uuid }) => `${uuid}`);
};

const isIntegrationReady = (item: ProductIntegration) => {
  return item?.issues.length === 0;
};

const filterReadyIntegrations = (data: ProductIntegration[]) => {
  return data.filter((item) => isIntegrationReady(item));
};

const toggleSingleSelection = (uuid: string, selectedValues: string[]) => {
  return selectedValues.includes(`${uuid}`)
    ? selectedValues.filter((item) => `${uuid}` !== item)
    : [...selectedValues, `${uuid}`];
};

const formatIssues = (item: ProductIntegration): ProductDraftIssues => {
  const draftIssues = {};
  item.issues.forEach((issue: IntegrationInternalState) => {
    draftIssues[issue.request_field] = [...(draftIssues[issue.request_field] ?? []), issue.message];
  });

  return draftIssues;
};

type AmountOrNull = Amount | null | undefined;

export const convertAmountFromCents = (amountObject: AmountOrNull): AmountOrNull => {
  return typeof amountObject === 'object'
    ? {
        ...amountObject,
        amount: amountObject.amount / 100,
      }
    : null;
};

const convertAmountToCents = (amountObject: AmountOrNull): AmountOrNull => {
  return typeof amountObject === 'object'
    ? {
        ...amountObject,
        amount: amountObject.amount * 100,
      }
    : null;
};

export default {
  namespaced: true,
  state: createInitialState,
  actions: {
    resetSelectedProducts({ commit }): void {
      commit('RESET_SELECTED');
      commit('RESET_READY_SELECTED');
    },

    async fetchProductsIntegration({ commit, state }, workflow = Workflow.REPLACE): Promise<unknown> {
      commit('TOGGLE_LOADING', true);
      const params: integrationsGetParameters = {
        page: state.pagination.page,
        perPage: state.pagination.perPage,
        workflow,
        sort: ['-internal_product_id', '-issues'],
      };
      const response = await getCatalogIntegration(params);
      const deserialized = deserialize(response);

      const readyIntegrations = filterReadyIntegrations(deserialized.data);

      commit('SET_PRODUCTS_INTEGRATION', {
        integrations: Object.fromEntries(response?.data.map((item) => [item.uuid, item])),
        order: getUUIDs(response?.data),
        readyOrder: getUUIDs(readyIntegrations),
      });
      response?.data.map((item) => {
        commit('FORMAT_PRODUCT_DRAFT_PRICES', item.uuid);
        commit('FORMAT_PRODUCT_DRAFT_VARIANT_PRICES', item.uuid);
        commit('FORMAT_PRODUCT_DRAFT_DESCRIPTION', item.uuid);
      });
      commit('SET_SELECTED', []);
      commit('SET_READY_SELECTED', []);
      commit('SET_TOTAL_READY_ITEMS', readyIntegrations.length);
      commit('TOGGLE_LOADING', false);
      commit('TOGGLE_DRAFTS_FOUND', response?.data.length > 0);
      return response;
    },

    async fetchProductIntegration({ commit }, uuid: string): Promise<void> {
      commit('TOGGLE_LOADING', true);
      const response = await getSingleCatalogIntegration(uuid);
      const { data } = deserialize(response);
      commit('SET_PRODUCT_INTEGRATION', data);
      commit('FORMAT_PRODUCT_DRAFT_PRICES', uuid);
      commit('FORMAT_PRODUCT_DRAFT_VARIANT_PRICES', uuid);
      commit('FORMAT_PRODUCT_DRAFT_DESCRIPTION', uuid);
      commit('TOGGLE_LOADING', false);
    },

    async fetchIntegrationStatistic({ commit }): Promise<void> {
      commit('TOGGLE_LOADING', true);
      const response = await getIntegrationStatistics();
      const { meta } = deserialize(response);
      commit('SET_TOTAL_ITEMS', meta.products_total_count);
      commit('SET_ALL_READY_TOTAL', meta.products_without_issues_total_count);
      commit('TOGGLE_LOADING', false);
    },

    changeCurrentPage({ commit, dispatch }, page: number): Promise<unknown> {
      commit('SET_CURRENT_PAGE', page);
      return dispatch('fetchProductsIntegration');
    },

    toggleWholeSelection({ commit, state }, mode: SelectionMode): void {
      commit('SET_SELECTED', mode === SelectionMode.All ? state.order : []);
      commit('RESET_READY_SELECTED');
    },

    toggleWholeReadySelection({ commit, state }, mode: SelectionMode): void {
      commit('SET_READY_SELECTED', mode === SelectionMode.READY ? state.readyOrder : []);
      commit('RESET_SELECTED');
    },

    toggleProductSelection({ commit, state }, uuid: string): void {
      commit('TOGGLE_SELECTION');
      commit('RESET_READY_SELECTED');
      commit('SET_SELECTED', toggleSingleSelection(uuid, state.selected));
    },

    toggleDraftsFound({ commit }, value: boolean): void {
      commit('TOGGLE_DRAFTS_FOUND', value);
    },

    productDraftSaveRequested(): void {},

    productDraftSaveAndActivateRequested(): void {},

    singleProductDraftDeleteRequested(): void {},

    async persistProductDraft(
      { commit, dispatch },
      {
        uuid,
        internal_product_id,
        name,
        hs_code,
        description,
        categories,
        product_type_id,
        attributes,
        retail_price,
        original_wholesale_price,
        unit_multiplier,
        made_in,
        vat_rate,
        discount_rate,
        variants,
        tags,
        properties,
        images,
      }
    ): Promise<void> {
      commit('TOGGLE_LOADING', true);

      const isMultiple = isMultipleProduct(variants);

      properties.forEach((property) => {
        delete property['type'];
        property['value'] = property['original_content'];
      });

      const cleanedUpProductDraft = {
        id: internal_product_id,
        name,
        hs_code,
        description,
        unit_multiplier,
        vat_rate,
        discount_rate,
        variants: variants.map((variant: Variant) => {
          const { price } = variant;
          const updatedImages = createProductRequestImages(variant.images);
          return {
            ...variant,
            images: updatedImages,
            price: {
              ...price,
              retail_price: convertAmountFromCents(price.retail_price),
              original_wholesale_price: convertAmountFromCents(price.original_wholesale_price),
            },
          };
        }),
        images: images.map((image) => (typeof image.filename === 'object' ? image.filename.key : image.filename)),
        categories,
        product_type_id,
        attributes,
        tags: tags.filter(({ checked }) => checked).map(({ name }) => name),
        retail_price: isMultiple ? convertAmountFromCents(retail_price) : convertAmountFromCents(variants[0].price.retail_price),
        original_wholesale_price: isMultiple
          ? convertAmountFromCents(original_wholesale_price)
          : convertAmountFromCents(variants[0].price.original_wholesale_price),
        made_in,
        properties,
      };

      await putInternalState(uuid, cleanedUpProductDraft)
        .then((response) => {
          const deserialized = deserialize(response);
          commit('SET_PRODUCT_INTEGRATION', deserialized.data);
          commit('FORMAT_PRODUCT_DRAFT_PRICES', deserialized.data.uuid);
          commit('FORMAT_PRODUCT_DRAFT_VARIANT_PRICES', deserialized.data.uuid);
          commit('FORMAT_PRODUCT_DRAFT_DESCRIPTION', deserialized.data.uuid);
          commit('TOGGLE_LOADING', false);
        })
        .finally(() => {
          dispatch('account/changeSaveBarMode', SaveBarMode.Disabled, {
            root: true,
          });
        });
    },

    async activateDrafts({ commit, dispatch }, uuids: string[]): Promise<unknown> {
      commit('TOGGLE_LOADING', true);
      return approveIntegrations(uuids)
        .then((response) => {
          const deserialized = deserialize(response);
          const lastActivation = deserialized.data;
          commit('SET_LAST_ACTIVATION_BATCH', lastActivation);
          dispatch('fetchProductsIntegration');
        })
        .finally(() => {
          commit('RESET_SELECTED');
          commit('RESET_READY_SELECTED');
          commit('TOGGLE_LOADING', false);
        });
    },

    async importFromSource(
      { commit },
      {
        payload,
        source,
      }: { payload: GoogleBatchPayload | CsvBatchPayload | XlsxBatchPayload; source: 'spreadsheet' | 'csv' | 'xlsx' }
    ): Promise<void> {
      commit('TOGGLE_LOADING', true);

      switch (source) {
        case 'spreadsheet':
          await postGoogleIntegrationBatches(payload as GoogleBatchPayload);
          break;

        case 'csv':
          await postCsvIntegrationBatches(payload as CsvBatchPayload);
          break;

        case 'xlsx':
          await postXlsxIntegrationBatches(payload as XlsxBatchPayload);
          break;
      }
      commit('TOGGLE_LOADING', false);
      if (payload?.workflow === Workflow.REPLACE) {
        commit('RESET_ORDER');
        commit('PURGE_DRAFTS');
        commit('RESET_SELECTED');
        commit('RESET_READY_SELECTED');
      }
    },

    async importFromSpreadsheet({ dispatch }, payload: GoogleBatchPayload): Promise<void> {
      await dispatch('importFromSource', { payload, source: 'spreadsheet' });
    },

    async importFromCsv({ dispatch }, payload: CsvBatchPayload): Promise<void> {
      await dispatch('importFromSource', { payload, source: 'csv' });
    },

    async importFromXlsx({ dispatch }, payload: XlsxBatchPayload): Promise<void> {
      await dispatch('importFromSource', { payload, source: 'xlsx' });
    },

    async fetchSettings({ commit }, uuid: string): Promise<void> {
      const response = await getCatalogIntegrationSettings(uuid);
      const deserialized = deserialize(response);
      commit('SET_SETTING', deserialized.data);
    },

    initializeCsvUploadModal({ commit, getters }): void {
      if (!getters.isImportInProgress) {
        commit('OPEN_CSV_UPLOAD_MODAL');
      }
    },

    closeCsvUploadModal({ commit }): void {
      commit('OPEN_CSV_UPLOAD_MODAL', false);
    },

    async generateNewSpreadsheet({ commit }, uuid: string): Promise<CatalogIntegrationSetting> {
      const response = await generateSpreadsheet(uuid);
      const deserialized = deserialize(response);
      commit('SET_SETTING', deserialized.data);
      return deserialized.data;
    },

    async removeExistingSpreadsheet({ commit }, uuid: string): Promise<void> {
      const response = await removeSpreadsheet(uuid);
      const deserialized = deserialize(response);
      commit('SET_SETTING', deserialized.data);
    },

    async exportCatalogue({ commit }, outputFormat: string): Promise<ExportCatalogue> {
      const response = await postCatalogExports(outputFormat);

      const deserialized = deserialize(response);
      commit('SET_LAST_EXPORT', deserialized.data);
      return deserialized.data;
    },

    async fetchLastExportCatalogue({ commit }): Promise<ExportCatalogue> {
      const response = await getCatalogExports({
        output_format: 'google_spreadsheet',
        'page[limit]': 1,
      });
      const deserialized = deserialize(response);
      if (deserialized.data[0]) {
        commit('SET_LAST_EXPORT', deserialized.data[0]);
      }
      return deserialized.data[0];
    },

    async fetchLastActivationBatch({ commit, state }): Promise<CatalogueIntegrationBatch> {
      const response = await getBatches({
        page: state.pagination.page,
        perPage: 1,
        workflow: Workflow.ACTIVATE,
        sort: ['created_at'],
        direction: 'DESC',
      });
      const deserialized = deserialize(response);
      const meta = deserialized.meta ?? { product_pending_updates_count: 0 };
      const lastActivation = deserialized.data[0] ? { ...deserialized.data[0], ...meta } : null;
      commit('SET_LAST_ACTIVATION_BATCH', lastActivation);
      return lastActivation;
    },

    async updateFromSpreadsheet({ commit, state }): Promise<void> {
      const payload: GoogleBatchPayload = {
        payload: {
          spreadsheet_url: state.lastExport?.google_spreadsheet_url,
          drive_url: state.settings?.images_google_folder_url,
        }
      };
      const response = await postGoogleIntegrationBatches(payload);
      const deserialized = deserialize(response);
      if (deserialized.data) {
        commit('SET_LAST_UPDATE', deserialized.data);
      }
      return deserialized.data;
    },

    async fetchLastUpdateBatch({ commit, state }): Promise<CatalogueIntegrationBatch> {
      const response = await getBatches({
        page: state.pagination.page,
        perPage: 1,
        workflow: Workflow.UPDATE,
        sort: ['created_at'],
        direction: 'DESC',
      });
      const deserialized = deserialize(response);
      const meta = deserialized.meta ?? { product_pending_updates_count: 0 };

      if (deserialized.data[0]) {
        commit('SET_LAST_UPDATE', { ...deserialized.data[0], ...meta });
      }
      return { ...deserialized.data[0], ...meta };
    },
  },
  mutations: {
    TOGGLE_LOADING(state: State, value: boolean): void {
      state.loading = value;
    },

    TOGGLE_DRAFTS_FOUND(state: State, value: boolean): void {
      state.draftsFound = value;
    },

    SET_PRODUCTS_INTEGRATION(
      state: State,
      {
        integrations,
        order,
        readyOrder,
      }: {
        integrations: Drafts;
        order: string[];
        readyOrder: string[];
      }
    ): void {
      state.drafts = integrations;
      state.order = [...order];
      state.readyOrder = [...readyOrder];
    },

    SET_PRODUCT_INTEGRATION(state: State, draft: ProductIntegration): void {
      state.drafts = {
        ...state.drafts,
        [draft.uuid]: draft,
      };
    },

    SET_LAST_IMPORT_BATCH(state: State, batch: CatalogueIntegrationBatch): void {
      state.lastImportBatch = batch;
    },

    SET_TOTAL_ITEMS(state: State, items: number): void {
      state.pagination.total = items;
    },

    SET_TOTAL_READY_ITEMS(state: State, items: number): void {
      state.pagination.totalReady = items;
    },

    SET_ORDER(state: State, order: string[]): void {
      state.order = [...order];
    },

    SET_ALL_READY_TOTAL(state: State, total: number): void {
      state.allReadyTotal = total;
    },

    SET_SELECTED(state: State, uuids: string[]): void {
      state.selected = [...uuids];
    },

    RESET_SELECTED(state: State): void {
      state.selected = [];
    },

    SET_READY_ORDER(state: State, readyOrder: string[]): void {
      state.readyOrder = [...readyOrder];
    },

    SET_READY_SELECTED(state: State, uuids: string[]): void {
      state.readySelected = [...uuids];
    },

    RESET_READY_SELECTED(state: State): void {
      state.readySelected = [];
    },

    TOGGLE_SELECTION(state: State): void {
      state.selected = [...state.selected, ...state.readySelected];
    },

    FORMAT_PRODUCT_DRAFT_PRICES(state: State, uuid: string): void {
      state.drafts[uuid].internal_state.discount_rate = state.drafts[uuid].internal_state.discount_rate ?? 0;

      state.drafts[uuid].internal_state.vat_rate = state.drafts[uuid].internal_state.vat_rate ?? 0;

      state.drafts[uuid].internal_state.original_wholesale_price = convertAmountToCents(
        state.drafts[uuid].internal_state.original_wholesale_price ?? {
          amount: 0,
          currency: state.drafts[uuid].internal_state.currency,
        }
      );

      state.drafts[uuid].internal_state.wholesale_price = convertAmountToCents(
        state.drafts[uuid].internal_state.wholesale_price ?? {
          amount: 0,
          currency: state.drafts[uuid].internal_state.currency,
        }
      );

      state.drafts[uuid].internal_state.retail_price = convertAmountToCents(
        state.drafts[uuid].internal_state.retail_price ?? {
          amount: 0,
          currency: state.drafts[uuid].internal_state.currency,
        }
      );
    },

    FORMAT_PRODUCT_DRAFT_VARIANT_PRICES(state: State, uuid: string): void {
      state.drafts[uuid].internal_state.variants = state.drafts[uuid].internal_state.variants.map((variant) => {
        variant.price.original_wholesale_price = convertAmountToCents(
          variant.price.original_wholesale_price ?? {
            amount: 0,
            currency: variant.price.currency,
          }
        );

        variant.price.retail_price = convertAmountToCents(
          variant.price.retail_price ?? {
            amount: 0,
            currency: variant.price.currency,
          }
        );

        return variant;
      });
    },

    FORMAT_PRODUCT_DRAFT_DESCRIPTION(state: State, uuid: string): void {
      state.drafts[uuid].internal_state.description = state.drafts[uuid].internal_state.description || '';
    },

    SET_CURRENT_PAGE(state: State, page: number): void {
      state.pagination.page = page;
    },

    SET_SETTING(state: State, settings: CatalogIntegrationSetting): void {
      state.settings = settings;
    },

    PURGE_DRAFTS(state: State): void {
      state.drafts = {};
    },

    SET_LAST_EXPORT(state: State, lastExport: ExportCatalogue): void {
      state.lastExport = lastExport;
    },

    SET_LAST_ACTIVATION_BATCH(state: State, lastActivation: CatalogueIntegrationBatch): void {
      state.lastActivation = lastActivation;
    },

    SET_LAST_UPDATE(state: State, lastUpdate: CatalogueIntegrationBatch): void {
      state.lastUpdate = lastUpdate;
    },

    RESET_ORDER(state: State): void {
      state.order = [];
    },
    OPEN_CSV_UPLOAD_MODAL(state: State, isOpen = true): void {
      state.isCsvUploadModalOpen = isOpen;
    },
  },
  getters: {
    getTotalItems: (state: State): number => state.pagination.total,
    getItemsPerPage: (state: State): number => state.pagination.perPage,
    getCurrentPage: (state: State): number => state.pagination.page,
    /**
     *
     * getOrderedProductsIntegration
     *
     * @param state
     * @returns sorted drafts
     */
    getOrderedProductsIntegration: (state: State): ProductIntegration[] => {
      return state.order.map((uuid) => {
        return state.drafts[uuid];
      });
    },
    getProductDraft:
      ({ drafts }: { drafts: Drafts }) =>
      (uuid: string): ProductDraft => {
        return drafts[uuid]
          ? Object.assign({}, drafts[uuid].internal_state, {
              ...drafts[uuid].internal_state,
              uuid,
            })
          : undefined;
      },
    getFormattedProductDraft:
      ({ drafts }: { drafts: Drafts }) =>
      (uuid: string): FormattedProductDraft | undefined => {
        return drafts[uuid]
          ? Object.assign({}, drafts[uuid].internal_state, {
              ...drafts[uuid].internal_state,
              uuid,
              internal_product_id: drafts[uuid].internal_state.id,
              tags: Object.values(getTags()).map((tag: Tag) => ({
                ...tag,
                checked: drafts[uuid].internal_state.tags.includes(tag.name),
              })),
              made_in: drafts[uuid].internal_state.made_in || null,
              properties: buildAdditionalDatas().map((property) => ({
                ...property,
                original_content:
                  drafts[uuid].internal_state.properties.find(({ key }) => key === property.key)?.original_content ?? null,
              })),
              images: drafts[uuid].internal_state.images.map((image, index) => ({
                filename: image,
                height: '100',
                width: '100',
                order: index,
              })),
              errors: formatIssues(drafts[uuid]),
            })
          : undefined;
      },
    getSelectedAllCount: (state: State): number => state.selected.length,
    getSelectedReadyCount: (state: State): number => state.readySelected.length,
    getSelectCount: (state: State): number => {
      return state.selected.length > 0 ? state.selected.length : state.readySelected.length;
    },
    getOrderCount: (state: State): number => state.order.length,
    isProductSelected:
      (state: State) =>
      (uuid: string): boolean => {
        return state.selected.includes(`${uuid}`) || state.readySelected.includes(`${uuid}`);
      },
    getValidSelection: ({ drafts, selected }: { drafts: Drafts; selected: string[] }): string[] => {
      return selected.filter((uuid) => {
        return isIntegrationReady(drafts[uuid]);
      });
    },
    isSelectionValid: (state: State, getters): boolean => {
      return getters.getValidSelection && getters.getValidSelection.length === state.selected.length;
    },
    isLoading: (state: State): boolean => state.loading,
    draftsFound: (state: State): boolean => state.draftsFound,
    getPageReadyDrafts: (state: State): number => state.pagination.totalReady,
    getAllReadyDraftsCount: (state: State): number => state.allReadyTotal,
    getAllNonReadyDraftsCount: (state: State): number => state.pagination.total - state.allReadyTotal,
    getLastImportBatch: (state: State): CatalogueIntegrationBatch => state.lastImportBatch,
    getSelected: (state: State): string[] => [...state.selected, ...state.readySelected],
    getSettings: (state: State): CatalogIntegrationSetting => state.settings,
    getLastExport: (state: State): ExportCatalogue => state.lastExport,
    getLastActivation: (state: State): CatalogueIntegrationBatch => state.lastActivation,
    getLastUpdate: (state: State): CatalogueIntegrationBatch => state.lastUpdate,
    isImportInProgress: (state: State): boolean => {
      return !!state.lastImportBatch && isBatchInprogress(state.lastImportBatch);
    },
    isUpdateInProgress: (state: State): boolean => {
      return !!state.lastUpdate && isBatchInprogress(state.lastUpdate);
    },
    isActivationInProgress: (state: State): boolean => {
      return !!state.lastActivation && isBatchInprogress(state.lastActivation);
    },
    isExportInProgress(state: State): boolean {
      const inprogressStatus = [ExportCatalogueStatus.IN_PROGRESS, ExportCatalogueStatus.WAITING];
      return !!state.lastExport && inprogressStatus.includes(state.lastExport.status);
    },
  },
};
