<template>
  <div class="ak-retail-information-account">
    <template v-if="!!content">
      <RetailMenuTouchpoint
        data-testid="retailInfoMenuAccountTouchpoint"
        :content="content"
      />
    </template>
    <template v-else-if="isEligibleForAnkorstorePlus && !isAnkorstorePlusMember">
      <AkParagraph
        weight="bold"
        size="sm"
        class="ak-retail-information-account__description"
        data-testid="retailInfoMenuAccountAnkorstorePlusTitle"
      >
        {{ $t('menu.retailer.ankorstorePlus.information.description') }}
      </AkParagraph>
      <AkButton
        size="md"
        symbol="arrow-right"
        symbol-position="right"
        class="ak-retail-information-account__cta"
        data-testid="retailInfoMenuAccountAnkorstorePlusLink"
        @click="redirectAnkorstorePlus"
      >
        {{ $t('menu.retailer.ankorstorePlus.information.cta') }}
      </AkButton>
    </template>
    <template v-else-if="r2bWalletCreditOffer">
      <AkParagraph
        weight="bold"
        size="sm"
        data-testid="retailInfoMenuAccountR2bContentTitle"
      >
        {{
          $t('Refer your favourite brands and receive {REFERRAL_GIFT_AMOUNT} for every brand online!', {
            REFERRAL_GIFT_AMOUNT: formatPrice(r2bWalletCreditOffer.amount, '$0'),
          })
        }}
      </AkParagraph>
      <AkButton
        size="md"
        symbol="arrow-right"
        symbol-position="right"
        class="ak-retail-information-account__cta"
        data-testid="retailInfoMenuAccountR2bContentLink"
        @click="suggestBrandButtonClicked"
      >
        {{
          $t('Get {REFERRAL_GIFT_AMOUNT} now', {
            REFERRAL_GIFT_AMOUNT: formatPrice(r2bWalletCreditOffer.amount, '$0'),
          })
        }}
      </AkButton>
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import Analytics from '@monolith/legacy/services/analytics';
import { UserClick } from '@monolith/legacy/services/analytics/events/user-click.event';
import { AkButton, AkParagraph } from '@ankorstore/design-system';
import RetailMenuTouchpoint from '@monolith/legacy/components/touchpoints/components/retail-menu-touchpoint.vue';
import { prismic } from '@core/mixins/prismic';
import { formatPrice } from '@core/utilities/price';

export default defineComponent({
  name: 'RetailInformationMenuAccount',
  components: {
    AkButton,
    AkParagraph,
    RetailMenuTouchpoint,
  },
  mixins: [prismic],
  data() {
    return {
      content: null,
    };
  },
  computed: {
    ...mapGetters('offers', ['r2bWalletCreditOffer', 'ankorstorePlus']),
    isEligibleForAnkorstorePlus(): boolean {
      return !!this.ankorstorePlus;
    },
    isAnkorstorePlusMember(): boolean {
      return !!this.ankorstorePlus && this.ankorstorePlus?.optIn;
    },
  },
  created() {
    this.fetchR2bOfferConfig();
    this.getContent();
  },
  methods: {
    ...mapActions('offers', ['fetchR2bOfferConfig']),
    suggestBrandButtonClicked(): void {
      Analytics.track(
        new UserClick({
          component: 'r2b_menu_banner',
          action: 'go_to_r2b_page',
        })
      );
      this.goToLink('/account/suggestbrand');
    },
    getContent(): Promise<void> {
      return (
        this.getSingle('touchpoint_retailer_account')
          .then((document) => {
            if (document) {
              this.content = {
                ...this.content,
                //@ts-ignore
                ...document.data,
              };
            }
          })
          // We didn't catch error here because we will implement it with the display system after that
          .catch(() => {})
      );
    },
    redirectAnkorstorePlus(): void {
      Analytics.track(
        new UserClick({
          component: 'retail_information_menu_account',
          action: 'go_to_ankorstoreplus_page',
        })
      );
      this.goToLink('/ankorstore-plus-signup');
    },
    goToLink(link): void {
      const url = new URL(link, window.location.origin).toString();
      this.$router ? this.$router.push(link) : (window.location.href = url);
    },
    formatPrice,
  },
});
</script>
<style lang="scss">
@import '@css/vue-import';

.ak-retail-information-account {
  @apply ds-p-4 ds-pb-3;
  white-space: normal;
  text-align: center;
  &__description {
    @apply ds-mb-4;
  }
  &__cta {
    @apply ds-w-full;
  }
}
</style>
