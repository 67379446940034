<template>
  <div v-if="!!content">
    <AkParagraph
      weight="bold"
      size="sm"
      class="ak-retail-information-account__description"
      data-testid="retailInfoMenuAccountTouchpointDescription"
    >
      {{ content.description }}
    </AkParagraph>
    <router-link :to="link">
      <AkButton
        size="md"
        symbol="arrow-right"
        symbol-position="right"
        class="ak-retail-information-account__cta"
        data-testid="retailInfoMenuAccountTouchpointLink"
        @click="trackClick"
      >
        {{ content.btn_text }}
      </AkButton>
    </router-link>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, computed } from 'vue';
import { AkParagraph, AkButton } from '@ankorstore/design-system';
import Analytics from '@monolith/legacy/services/analytics';
import { UserClick } from '@monolith/legacy/services/analytics/events/user-click.event';

export interface RetailMenuTouchpointContent {
  description: string;
  btn_text: string;
  link: string;
  tracking_event_name: string;
}

export default defineComponent({
  name: 'RetailMenuTouchpoint',
  components: {
    AkParagraph,
    AkButton,
  },
  props: {
    content: {
      type: Object as PropType<RetailMenuTouchpointContent>,
      required: true,
    },
  },
  setup(props) {
    const link = computed((): string => {
      return '/' + props.content.link;
    });

    function trackClick(): void {
      Analytics.track(
        new UserClick({
          component: 'retail_information_menu_account',
          action: props.content.tracking_event_name,
        })
      );
    }

    return {
      link,
      trackClick,
    };
  },
});
</script>
