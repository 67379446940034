import { BatchStatus, CatalogueIntegrationBatch } from '@monolith/legacy/types/account/account-products/catalog-integration/product-integration';

export const isBatchInprogress = (batch: CatalogueIntegrationBatch): boolean => {
  const status = [
    BatchStatus.FETCHING_PENDING,
    BatchStatus.FETCHING_IN_PROGRESS,
    BatchStatus.IMPORTATION_IN_PROGRESS,
    BatchStatus.INTEGRATION_PENDING,
    BatchStatus.INTEGRATION_IN_PROGRESS,
    BatchStatus.IN_PROGRESS,
    BatchStatus.IMPORTING,
  ];
  return status.includes(batch.status);
};
