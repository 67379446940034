<template v-if="$isEnabled('help_center_link')">
  <li>
    <a
      :href="link"
      target="_blank"
      rel="noopener"
      @click="trackHelpCenterLinkClicked(HelpCenterLinkLocation.MENU_MOBILE)"
    >
      {{ $t('Help center') }}
    </a>
  </li>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { helpCenterLinkAnalyticsMixin } from '@core/mixins/help-center-link-analytics';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'MenuMobileItemHelpCenter',
  mixins: [helpCenterLinkAnalyticsMixin],
  computed: {
    ...mapGetters(['userIsRetailer']),
    link(): string {
      if (this.userIsRetailer) {
        return this.$t('help_center_link_retailer');
      } else {
        return this.$t('help_center_link_brand');
      }
    },
  },
});
</script>
