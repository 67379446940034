import AnalyticsEvent from '@monolith/legacy/services/analytics/events/analytics-event';
import { AdSectionType, OriginType } from '@bc/advertisement/domain/tracking/events.enum';

export class AdsClickEvent extends AnalyticsEvent {
  name = 'user_click';

  constructor(
    public readonly properties: {
      action: string;
      component?: string;
      productId?: number;
      page_number?: number;
      sectionId?: string;
      position?: number;
      itemtype?: string;
    }
  ) {
    super();
  }
}

export class AdCampaignFunnelEvent extends AnalyticsEvent {
  name = 'ad_campaign_funnel';

  constructor(
    public readonly properties: {
      section_type: AdSectionType;
      user_origin: OriginType;
    }
  ) {
    super();
  }
}
