<template>
  <span class="message">
    {{ message }}
    <template v-if="link">
      <a
        class="link"
        :href="link.href"
        target="_blank"
      >
        {{ link.text }}
      </a>
    </template>

    <template v-if="tooltip">
      <AkTooltipInformation
        trigger="hover"
        placement="bottom"
        type="invert"
        :title="tooltip.text"
        class="vat-tooltip"
      >
        <AkIcon
          symbol="info"
          class="vat-tooltip__info-icon"
        />
        <template #container>
          <ul

            class="vat-tooltip__list"
          >
            <li
              v-for="item in tooltip.items"
              :key="item"
            >
              {{ item }}
            </li>
          </ul>
        </template>
      </AkTooltipInformation>
    </template>
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { AkIcon, AkTooltipInformation } from '@ankorstore/design-system';

export default defineComponent({
  name: 'VatMessage',
  components: { AkIcon, AkTooltipInformation },
  props: {
    message: {
      type: String,
      default: '',
    },
    link: {
      type: Object,
      default: null,
    },
    tooltip: {
      type: Object,
      default: null,
    },
  },
});
</script>

<style scoped>
.message {
  @apply ds-text-info-700 ds-leading-5 ds-text-sm;
}

.link {
  @apply ds-underline;
}

.link:active,
.link:visited,
.link:hover,
.link:focus {
  @apply ds-text-info-700;
}

.vat-tooltip {
  @apply ds-inline;
}

.vat-tooltip__info-icon {
  @apply ds-ml-1 ds-cursor-pointer;
}

.vat-tooltip__info-icon:before {
  @apply ds-text-primary;
}

.vat-tooltip__list {
  @apply ds-list-disc ds-mx-4 ds-my-2;
}
</style>
