<template>
  <form
    class="vat-form"
    @submit.prevent="submit"
  >
    <div class="content">
      <div>
        <template v-if="countryConfig.business_identifier_type === 'tax_number' && !soleTraderViewActive">
          <InputBoxes
            v-if="countryConfig.tax_number_length"
            v-model="taxNumber"
            :is-login="true"
            :length="countryConfig.tax_number_length"
            :label="countryConfig.tax_number_label"
          />
          <div
            v-else
            class="input-field"
          >
            <label>{{ countryConfig.tax_number_label }}</label>
            <div class="input-container">
              <AkInput
                v-model="taxNumber"
                v-mask="optionalTaxNumberVMask"
                class="tax-number-input"
                type="text"
              />
            </div>
          </div>
        </template>
        <template v-else-if="soleTraderViewActive">
          <div v-if="shouldDisplayUkForm">
            <FormSoleTrader
              :with-business-name="true"
              @changed="submit"
            />
          </div>
          <div v-else>
            <label
              class="form-label"
              for="soleTraderNumber"
            >
              {{ getLabelForInput }}
            </label>
            <AkInput
              id="soleTraderNumber"
              v-model="soleTraderNumber"
              type="text"
            />
          </div>
        </template>

        <VatInput
          v-else
          :iso-code="iso_code"
          @change="onVatChange($event)"
        />

        <span
          v-if="errors.tax_number"
          class="error-message"
        >
          {{ errors.tax_number[0] }}
        </span>
      </div>

      <div
        v-if="errors.global"
        class="error"
      >
        {{ errors.global }}
      </div>
      <AkButton
        :disabled="!formValid && !isSoleTraderInfoValid"
        class="button--extended ds-mt-3"
        type="submit"
        size="lg"
      >
        {{ !showUnlockAddToCart ? $t('Unlock the wholesale prices') : $t('Unlock add to cart') }}
      </AkButton>
      <div
        v-if="soleTraderViewActive && shouldDisplayUkForm"
        class="ds-py-2 ds-text-justify ds-text-xs"
      >
        <i18n-t
          keypath="Our payment partner Hokodo uses soft searches with credit reference agencies. These do not affect your credit rating. More information {0}."
          tag="div"
          class="sub-title"
        >
          <a
            class="small-link ds-underline"
            href="https://www.experian.co.uk/legal/crain/"
            target="_blank"
            rel="noopener"
          >
            {{ $t('here') }}
          </a>
        </i18n-t>
      </div>
      <div
        v-if="isAllowedCountrySoletrader"
        id="switcher-tva"
      >
        <a
          ref="sole-trader-btn"
          data-testid="sole-trader"
          href="#"
          :class="{ back: soleTraderViewActive }"
          @click.prevent="switchSoleTrader()"
        >
          <span>
            {{ getSwitcherText }}
          </span>
        </a>
      </div>
    </div>

    <div
      v-if="!fromPreparedOrder"
      class="footer"
    >
      <p class="footer__alternative">
        <a
          class="ak-link inline"
          href="#"
          data-testid="register-confirm-my-status-later-link"
          @click.prevent="confirmVatLater"
        >
          <span v-if="isAnkorstart">{{
            $t('I don’t have a {vat} yet', {
              vat: countryConfig.tax_number_label,
            })
          }}</span>
          <span v-else>{{ $t('Confirm my professional status later') }}</span>
        </a>
        <br>
        <template v-if="fromAddCart">
          {{ $t("(Only the retail prices will be displayed and you won't be able to add products to your cart)") }}
        </template>
        <template v-else>
          {{ $t('(Only the retail prices will be displayed)') }}
        </template>
      </p>
      <p
        v-if="isGermanBrand && fromRegistration"
        class="mt-3"
        data-testid="sole-trader-info"
      >
        {{ $t('retailer.vat.form.germanSoleTrader.message') }}
      </p>
    </div>
  </form>
</template>

<script lang="ts">
import { mapGetters, mapActions } from 'vuex';

import Analytics from '@monolith/legacy/services/analytics';
import { SignUpEventNames } from '@monolith/legacy/services/analytics/events/sign-up/common/event-names';
import SignUpEvent from '@monolith/legacy/services/analytics/events/sign-up/sign-up-event';
import { FeatureFlag } from '@monolith/legacy/services/features';
import { CountryIso, CountryIsoCode } from '@core/types/country';
import { AkButton } from '@ankorstore/design-system';
import http from '@monolith/legacy/services/api/http';
import InputBoxes from '@monolith/legacy/components/input-boxes.vue';
import FormSoleTrader from '@monolith/legacy/components/cart/form-sole-trader.vue';
import VatInput from '@monolith/legacy/components/vat-input.vue';

import { defineComponent } from 'vue';
import { CountryConfig, getGlobalConfig } from '@monolith/legacy/services/global-config';
import { getCountryIsoCode } from '@core/utilities/locale';

type Data = {
  allowedCountrySoletrader: CountryIsoCode[];
  taxNumber: string;
  errors: Record<string, string>;
  iso_code: CountryIsoCode;
  countryConfig: CountryConfig;
  isVATValid: boolean;
  isSoleTraderNumberInvalid: boolean;
  vatNumber: string;
  soleTraderNumber: string;
  soleTraderViewActive: boolean;
  FeatureFlag: typeof FeatureFlag;
};

export default defineComponent({
  name: 'VatForm',
  components: { AkButton, FormSoleTrader, InputBoxes, VatInput },
  props: {
    fromAddCart: {
      type: Boolean,
      default: false,
    },
    fromRegistration: {
      type: Boolean,
      default: false,
    },
    countryCode: {
      type: String,
      default: 'FR',
    },
    taxCode: {
      type: String,
      default: '',
    },
    fromPreparedOrder: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['success'],
  data(): Data {
    return {
      allowedCountrySoletrader: ['GB', 'DE'],
      taxNumber: this.taxCode,
      errors: {},
      iso_code: 'FR',
      countryConfig: getGlobalConfig().countries_config['FR'],
      isVATValid: false,
      isSoleTraderNumberInvalid: false,
      vatNumber: '',
      soleTraderNumber: '',
      soleTraderViewActive: false,
      FeatureFlag,
    };
  },
  computed: {
    ...mapGetters(['retailer']),
    ...mapGetters('account/soleTrader', ['soleTrader', 'soleTraderDetailsErrors', 'isSoleTraderInfoValid']),
    ...mapGetters('signUp', ['isAnkorstart']),
    optionalTaxNumberVMask(): string {
      if ('CH' === this.iso_code) {
        return 'CHE-###.###.###';
      }

      return null;
    },
    showUnlockAddToCart(): boolean {
      return this.fromPreparedOrder || this.fromAddCart;
    },
    isGermanBrand(): boolean {
      return this.iso_code === CountryIso.Germany;
    },
    isAllowedCountrySoletrader(): boolean {
      return this.allowedCountrySoletrader.includes(getCountryIsoCode(this.countryConfig.locale));
    },
    formValid(): boolean {
      if (this.soleTraderViewActive) {
        return this.shouldDisplayUkForm ? this.isSoleTraderInfoValid : this.soleTraderNumber.length !== 0;
      } else {
        if (this.countryConfig.business_identifier_type === 'tax_number') {
          const taxNbLength = this.countryConfig.tax_number_length;
          return taxNbLength ? this.taxNumber.length === this.countryConfig.tax_number_length : this.taxNumber.length > 0;
        }
        // Else, we want to ask the vat first instead of the tax number
        return this.isVATValid;
      }
    },

    getLabelForInput(): string {
      return this.countryConfig.sole_trader_number_label;
    },
    getSwitcherText(): string {
      if (this.soleTraderViewActive) {
        if (this.countryConfig.sole_trader_label) {
          return this.$t('I am not a {soleTraderLabel}', {
            soleTraderLabel: this.countryConfig.sole_trader_label,
          });
        }

        return this.$t("I don't have a {identificationName}", {
          identificationName: this.countryConfig.sole_trader_number_label,
        });
      } else {
        if (this.countryConfig.sole_trader_label) {
          return this.$t('I am a {soleTraderLabel}', {
            soleTraderLabel: this.countryConfig.sole_trader_label,
          });
        }
        if (this.countryConfig.business_identifier_type === 'vat_number') {
          return this.$t("I don't have a VAT number");
        } else {
          return this.$t("I don't have a {identificationName}", {
            identificationName: this.countryConfig.tax_number_label,
          });
        }
      }
    },
    shouldDisplayUkForm(): boolean {
      return this.isRetailerFromCountry('GB') && this.$isEnabled('sole_traders_uk');
    },
  },
  mounted(): void {
    Analytics.track(
      new SignUpEvent(SignUpEventNames.CompanyNumberViewedEvent, {
        type: 'Element Viewed',
        is_opening_soon: this.isAnkorstart,
        is_prefilled: !!this.taxCode,
      })
    );
  },
  created(): void {
    this.iso_code = this.retailer ? this.retailer.business.country.iso_code : this.countryCode;
    this.countryConfig = getGlobalConfig().countries_config[this.iso_code];
  },
  methods: {
    ...mapActions('account/soleTrader', ['validateSoleTrader', 'setSoleTraderDetail']),
    isRetailerFromCountry(country: CountryIsoCode): boolean {
      return this.countryConfig.locale.split('_')[1] === country;
    },
    switchSoleTrader(): void {
      this.resetErrors();
      this.soleTraderViewActive = !this.soleTraderViewActive;

      if (this.soleTraderViewActive) {
        Analytics.track(
          new SignUpEvent(SignUpEventNames.CompanyNumberSoleTrader, {
            type: 'Click',
            is_opening_soon: this.isAnkorstart,
          })
        );
      } else {
        Analytics.track(
          new SignUpEvent(SignUpEventNames.CompanyNumberSoleTraderBack, {
            type: 'Click',
            is_opening_soon: this.isAnkorstart,
          })
        );
      }
    },
    onVatChange({ value, isValid }): void {
      this.isVATValid = isValid;
      this.vatNumber = value;
    },
    resetErrors(): void {
      this.errors = {};
    },
    submit(): void {
      if (!this.formValid) {
        Analytics.track(
          new SignUpEvent(SignUpEventNames.CompanyNumberMandatoryEvent, {
            type: 'Click',
            is_opening_soon: this.isAnkorstart,
          })
        );
        return;
      }

      this.errors = {};

      if (this.soleTraderViewActive) {
        if (this.shouldDisplayUkForm) {
          this.updateSoleTrader();
        } else {
          this.updateBusinessNumber('/api/me/sole-trader-number', {
            sole_trader_number: this.soleTraderNumber,
          });
        }
      } else {
        let url = '/api/me/tax-number';
        let params = {};

        if (this.countryConfig.business_identifier_type === 'vat_number') {
          // for Germany, we want to ask the vat first instead of the tax number
          params = {
            vat_number: this.vatNumber.replace(/\s/g, '').toUpperCase(),
          };
          url = '/api/me/vat';
        } else {
          params = {
            tax_number: this.taxNumber.replace(/\s/g, '').toUpperCase(),
          };
        }
        this.updateBusinessNumber(url, params);
      }

      Analytics.track(
        new SignUpEvent(SignUpEventNames.CompanyNumberSubmittedEvent, {
          type: 'Click or Form Submit',
          is_opening_soon: this.isAnkorstart,
        })
      );
    },
    async updateSoleTrader() {
      const data = await this.validateSoleTrader();

      this.errors = this.soleTraderDetailsErrors;
      if (!Object.keys(this.errors).length) {
        this.$store.dispatch('replaceRetailer', data?.data?.data);
        this.$emit('success');
      }
    },
    updateBusinessNumber(url, params): void {
      http()
        .patch(url, params)
        .then(({ data: { data } }) => {
          this.$store.dispatch('replaceRetailer', data);
          this.$emit('success');
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.errors) {
            this.errors = error.response.data.errors;

            Analytics.track(
              new SignUpEvent(SignUpEventNames.CompanyNumberMandatoryEvent, {
                type: 'Click',
                is_opening_soon: this.isAnkorstart,
              })
            );
          } else {
            this.errors = {
              global: this.$t('Cannot log-in. Please refresh and retry!'),
            };
          }
        });
    },
    confirmVatLater() {
      Analytics.track(
        new SignUpEvent(SignUpEventNames.CompanyNumberLaterEvent, {
          type: 'Click',
          is_opening_soon: this.isAnkorstart,
        })
      );
      this.$emit('success');
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';

.sub-title {
  margin-bottom: 32px;

  strong {
    @apply ds-bg-accent-alt;
    font-weight: 400;
  }
}

.title__icon {
  display: block;
  height: 40px;
  width: 40px;
  margin: auto;
  margin-top: 18px;
}

form .footer p.footer__alternative {
  @apply ds-mb-0 ds-text-neutral-700 ds-text-xs;
  a {
    @apply ds-text-neutral-700 ds-underline ds-text-sm;
    line-height: 21px;
  }
}

label {
  margin-bottom: 5px;
}

.vat-form {
  padding: 26px 18px;
  @apply ds-mb-0;

  &__title {
    @apply ds-text-lg;
  }

  .footer {
    margin-top: 20px;

    p {
      span {
        display: block;
      }
    }
  }

  .header {
    .title {
      margin-bottom: 10px;

      span {
        display: block;
        font-size: 40px;
        margin-bottom: 20px;
      }
    }
  }

  .content {
    .form-group {
      max-width: none;

      label {
        font-size: 14px;
        line-height: 18px;
        @apply ds-text-primary ds-font-basic;
      }

      &.form-vertical {
        padding: 0;
      }
    }

    .submit {
      padding: 0 20px;
    }

    input.button-black {
      margin-top: 25px;
      padding: 14px 15px;
      width: 100%;
    }

    .tax-number-input {
      width: 100%;
      margin: 5px 0;

      &--bordered {
        @apply ds-border ds-border-solid ds-border-neutral-300;
        height: 40px;
        padding: 0 15px;
      }
    }
  }
}

#switcher-tva {
  text-align: center;
  @apply ds-text-primary;
  margin-top: 30px;

  a {
    &::after {
      content: '>';
    }

    span {
      text-decoration: underline;
      margin: 0 8px;
    }

    &.back {
      &::after {
        display: none;
      }

      &::before {
        content: '<';
      }
    }
  }
}

.form-label {
  @apply ds-mb-2 ds-block;
}
</style>
