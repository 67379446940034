import { NafoCustomer } from '@monolith/legacy/types/order';
import { postNafoCustomer, patchNafoCustomer } from '@monolith/legacy/services/order/order.service';

export interface State {
  customer: NafoCustomer;
}

const initialState: State = {
  customer: null,
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    SET_CUSTOMER(state: State, customer: NafoCustomer): void {
      state.customer = customer;
    },
    CLEAR_CUSTOMER(state: State): void {
      state.customer = null;
    },
  },
  actions: {
    setCustomer: ({ commit }, { customerData }: { customerData: NafoCustomer }) => {
      commit('SET_CUSTOMER', customerData);
    },
    clearCustomer: ({ commit }) => {
      commit('CLEAR_CUSTOMER');
    },
    createCustomer: async ({ commit }, { customerData }: { customerData: NafoCustomer }) => {
      const res = await postNafoCustomer(customerData);
      commit('SET_CUSTOMER', res);
    },
    updateCustomer: async ({ commit }, { customerId, payload }: { customerId: string; payload: NafoCustomer }) => {
      const res = await patchNafoCustomer(customerId, payload);
      commit('SET_CUSTOMER', res);
    },
  },
  getters: {
    hasCustomer: (state: State): boolean => !!state.customer,
    getCustomer: (state: State): NafoCustomer => state.customer,
  },
};
