<template>
  <div class="file-uploader">
    <AkFileUploader
      :ref="fileUploaderRef"
      :primary-label="$t('Drag and drop your files here or')"
      :secondary-label="$t('browse your computer')"
      :above-max-file-size-message="$t('One of your file is too heavy')"
      :unaccepted-type-message="allowedTypesLabel"
      :types-label="typesLabel"
      :size-label="sizeLabel"
      :max-file-size="getConfig.maxFileSize"
      :max-file-quantity-message="$t('You reached the limit of files to upload')"
      :uploaded-file-label-max-length="getConfig.maxFileLabelLength"
      :max-file-quantity="maxFilesQuantity"
      :required="true"
      :multiple="true"
      :accepted-types="allowedFileTypes"
      @change="uploadFiles"
    />
    <div class="ds-flex ds-flex-col">
      <AkUploadedFile
        v-for="(file, index) in files"
        :key="index"
        :label="trimFileName(file.name)"
        class="ds-mt-4 ds-w-max"
        @click="removeFile(index)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { orderIssueFileUploadingMixin } from '@monolith/legacy/components/account/orders/order/order-retailer-reject/mixins/order-issue-file-uploading';

export default defineComponent({
  name: 'FileUploader',
  mixins: [orderIssueFileUploadingMixin],
  props: {
    imagesOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    maxQuantity: {
      type: Number,
      required: false,
      default: null,
    },
  },
  emits: ['set-loader', 'upload-file', 'remove-file'],
  data() {
    return {
      fileUploaderRef: 'refection-form-file-uploader',
      fileUploader: null,
      files: [],
      maxFilesQuantity: null,
    };
  },
  computed: {
    ...mapGetters('orderRetailerRejection', ['getConfig']),
    sizeLabel(): string {
      const size = this.getConfig.maxFileSize / (1024 * 1024);
      return `Max. ${size}Mb`;
    },
    allowedTypesLabel(): string {
      if (this.imagesOnly) {
        return this.$t('Allowed file types: images (png / jpg)');
      } else {
        return this.$t('Allowed file types: images (png / jpg), PDF documents');
      }
    },
    typesLabel(): string {
      if (this.imagesOnly) {
        return this.$t('JPEG and PNG only.');
      } else {
        return this.$t('JPEG, PNG and PDF only.');
      }
    },
    allowedFileTypes(): Array<string> {
      return this.imagesOnly ? this.getConfig.allowedImageTypes : this.getConfig.allowedFileTypes;
    },
  },
  mounted() {
    this.fileUploader = this.$refs[this.fileUploaderRef];
    this.maxFilesQuantity = this.maxQuantity ?? this.getConfig.maxPackagingFileQuantity;
  },
  methods: {
    async uploadFiles(files: FileList): Promise<void> {
      this.fileUploader.files = null;

      if (files?.length) {
        this.$emit('set-loader', true);
        // @ts-ignore
        files = Array.from(files);

        for (let index = 0; index < files.length; index++) {
          if (this.files.length >= this.maxFilesQuantity) {
            const errors = [this.$t('You reached the limit of files to upload')];
            this.setFileUploaderErrors(errors);
            this.$emit('set-loader', false);
            break;
          }

          const file = files[index];
          this.$emit('upload-file', file, (result: string) => {
            if (result) {
              this.files.push(file);
            } else {
              const errors = [this.$t('An error occurred. Please retry.')];
              this.setFileUploaderErrors(errors);
            }

            if (index === files.length - 1) {
              this.$emit('set-loader', false);
            }
          });
        }
      }
    },
    removeFile(index: number): void {
      this.removeUploadedFile(index, (file) => {
        this.$emit('remove-file', file);
      });
    },
  },
});
</script>
