export const scrollToRef = (ref, offsetHeight = 0): void => {
  const headers = document.getElementsByClassName('ak-header');
  const displayedHeader = Array.from(headers).filter((element) => element.getBoundingClientRect().height > 0)[0];
  const FixHeaderHeight: number = displayedHeader?.getBoundingClientRect().height || 0;
  const element = ref.$el || ref;
  const headerOffset = FixHeaderHeight;
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.scrollY - headerOffset - offsetHeight;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
};
