<script setup lang="ts">
/* eslint @intlify/vue-i18n/no-v-html: 'off' */
import { onMounted } from 'vue';
import { Amount } from '@core/types/amount';
import { AkIcon, AkLogoAKS, AkParagraph } from '@ankorstore/design-system';
import AnkorstorePlusJoin from '@monolith/legacy/modules/promote-engage/subscription-program/UI/ankorstore-plus-join.vue';
import { useI18n } from 'vue-i18n';
import { RetailerShippingFeesMessageTypeName, CartRepeatOffer } from '@monolith/legacy/modules/cart-2/types';
import { formatPrice } from '@core/utilities/price';

import Analytics from '@monolith/legacy/services/analytics';
import { UserDisplayEvent, UserDisplayEventAction } from '@monolith/legacy/services/analytics/events/user-display.event';

const props = defineProps<{
  type: RetailerShippingFeesMessageTypeName;
  isCheckout: boolean;
  shippingFeeAmount: Amount;
  untilFreeShippingAmount?: Amount;
  displayAksPlusCta: boolean;
  francoAmount?: Amount;
  repeatOffer?: CartRepeatOffer;
  brandId: string;
}>();

function trackRepeatOffer() {
  Analytics.track(
    new UserDisplayEvent({
      component: 'cart_repeat_offer_brand_to_unlock',
      action: UserDisplayEventAction.DISPLAY,
      value: props.brandId,
    })
  );
}

onMounted(() => {
  if (!!props.repeatOffer && props.repeatOffer.percentage > 0 && props.type === 'freeShipping') {
    trackRepeatOffer();
  }
});

const { t } = useI18n();
</script>

<template>
  <div
    class="shipping-fees-banner-container"
    data-testid="shippingFeesBanner"
  >
    <div
      v-if="type === 'firstOrder-freeShipping'"
      class="shipping-fees-banner"
      data-testid="firstOrderShippingBanner"
    >
      <AkIcon
        symbol="ankorstore-logo"
        size="md"
        class="shipping-fees-banner__icon"
      />
      <AkParagraph
        class="shipping-fees-banner__content"
        size="sm"
      >
        <span v-html="t('retailer.cart.shippingFee.firstOrder.lbl')" />
        <AnkorstorePlusJoin
          v-if="displayAksPlusCta"
          data-testid="firstOrderShippingBannerJoinAksPlusLink"
        />
        <div
          v-if="displayAksPlusCta"
          data-testid="firstOrderShippingBannerJoinAksPlusMessage"
          class="first-order__cart"
        >
          {{ t('retailer.cart.shippingFees.aksPlusUnlock.lbl') }}
        </div>
      </AkParagraph>
    </div>

    <div
      v-if="type === 'reorder-aboveFranco'"
      class="shipping-fees-banner"
      data-testid="reorderAboveFrancoBanner"
    >
      <AkIcon
        symbol="telegram"
        size="md"
        class="shipping-fees-banner__icon"
      />
      <AkParagraph
        v-if="!isCheckout"
        class="shipping-fees-banner__content"
        size="sm"
        data-testid="reorderAboveFrancoBannerCart"
      >
        <span v-html="t('retailer.cart.shippingFees.aboveFranco.lbl')" />
        <AnkorstorePlusJoin v-if="displayAksPlusCta" />
        <span v-if="displayAksPlusCta">{{ t('retailer.cart.shippingFees.aksPlusUnlock.lbl') }} </span>
      </AkParagraph>
      <AkParagraph
        v-if="isCheckout"
        class="shipping-fees-banner__content"
        size="sm"
        data-testid="reorderAboveFrancoBannerCheckout"
      >
        <span v-html="t('retailer.checkout.shippingFees.aboveFranco.lbl', { amount: formatPrice(francoAmount) })" />
      </AkParagraph>
    </div>

    <div
      v-if="type === 'reorder-belowFranco'"
      class="shipping-fees-banner"
      data-testid="reorderBelowFrancoBanner"
    >
      <AkIcon
        symbol="telegram"
        size="md"
        class="shipping-fees-banner__icon"
      />
      <AkParagraph
        v-if="!isCheckout"
        size="sm"
        data-testid="reorderBelowFrancoBannerCart"
        class="shipping-fees-banner__content"
      >
        <span v-html="t('retailer.cart.shippingFees.belowFranco.lbl', { amount: formatPrice(untilFreeShippingAmount) })" />
        <AnkorstorePlusJoin v-if="displayAksPlusCta" />
        <span v-if="displayAksPlusCta">{{ t('retailer.cart.shippingFees.aksPlusUnlock.lbl') }}</span>
      </AkParagraph>
      <AkParagraph
        v-else
        size="sm"
        data-testid="reorderBelowFrancoBannerCheckout"
        class="shipping-fees-banner__content"
      >
        <span
          v-html="
            t('retailer.checkout.shippingFees.belowFranco.lbl', {
              amount: formatPrice(francoAmount),
              shippingFeeAmount: formatPrice(shippingFeeAmount),
            })
          "
        />
      </AkParagraph>
    </div>
    <div
      v-if="type === 'ineligible'"
      class="shipping-fees-banner"
      data-testid="ineligibleShippingFeeBanner"
    >
      <AkIcon
        symbol="telegram"
        size="md"
        class="shipping-fees-banner__icon"
      />
      <AkParagraph
        v-if="!isCheckout"
        size="sm"
        data-testid="ineligibleShippingFeeBannerCart"
        class="shipping-fees-banner__content"
      >
        <span v-html="t('retailer.cart.shippingFees.ineligible.lbl', { amount: formatPrice(shippingFeeAmount) })" />
        <a
          :href="t('retailer.cart.shippingFees.ineligible.link.href')"
          target="_blank"
          class="non-eligible-fee-banner__link"
        >
          {{ t('Learn more') }}</a>
      </AkParagraph>
      <AkParagraph
        v-else
        size="sm"
        data-testid="ineligibleShippingFeeBannerCheckout"
        class="shipping-fees-banner__content"
      >
        <span
          v-html="t('retailer.checkout.shippingFees.ineligible.lbl', { shippingFeeAmount: formatPrice(shippingFeeAmount) })"
        />
      </AkParagraph>
    </div>
    <div
      v-if="type === 'freeShipping'"
      class="shipping-fees-banner"
      data-testid="freeShippingBanner"
    >
      <AkParagraph
        class="shipping-fees-banner__content shipping-fees-banner__content--guttered"
        size="sm"
      >
        <template v-if="type === 'freeShipping' && !!repeatOffer && repeatOffer.percentage > 0">
          <i18n-t
            keypath="retailer.cart.shippingFees.freeShippingWithRepeatOffer.lbl"
            data-testid="repeatOfferLabel"
          >
            <template #logoAks>
              <AkLogoAKS
                type="symbol"
                size="base"
              />
            </template>
            <template #logoRepeat>
              <AkIcon
                symbol="repeat-offer"
                class="shipping-fees-banner__icon shipping-fees-banner__icon--aks"
                size="md"
              />
            </template>
            <template #titleFree>
              <strong>{{ t('retailer.cart.shippingFees.freeShipping.lbl.title') }}</strong>
            </template>
            <template #titleRepeat>
              <strong>{{ t('retailer.cart.shippingFees.repeatOffers.lbl.title') }}</strong>
            </template>
            <template #amountLeftToReachThreshold>
              <strong>{{ formatPrice(repeatOffer.amountLeftToReachThreshold) }}</strong>
            </template>
            <template #percentage>
              <strong>{{ t('retailer.cart.shippingFees.repeatOffers.discount', { percentage: repeatOffer.percentage }) }}</strong>
            </template>
          </i18n-t>
        </template>

        <template v-else>
          <i18n-t keypath="retailer.cart.shippingFees.freeShipping.lbl">
            <template #logoAks>
              <AkLogoAKS
                type="symbol"
                size="base"
              />
            </template>
            <template #titleFree>
              <strong>{{ t('retailer.cart.shippingFees.freeShipping.lbl.title') }}</strong>
            </template>
          </i18n-t>
        </template>
      </AkParagraph>
    </div>
  </div>
</template>

<style lang="scss">
.shipping-fees-banner-container {
  @apply ds-flex ds-h-full;
}
.shipping-fees-banner {
  @apply ds-flex ds-flex-row ds-gap-2;
  &__icon {
    @apply ds-text-info-700 ds-rounded-lg;
    &--aks {
      @apply ds-bg-accent-alt ds-text-primary ds-flex ds-items-center ds-justify-center;
      width: 21px;
      height: 21px;
    }
  }
  &__bold {
    @apply ds-font-semibold;
  }
  &__content {
    @apply ds-inline-flex ds-flex-wrap ds-items-center;
    &--guttered {
      @apply ds-gap-1;
    }
  }
  strong {
    @apply ds-font-bold;
  }
}
.non-eligible-fee-banner__link {
  @apply ds-underline ds-px-1;
}
.first-order__cart {
  @apply ds-flex;
}
</style>
