import http from '@monolith/legacy/services/api/http';

interface State {
  payments: PaymentItem[];
  currentPage: number;
  itemsPerPage: number;
  totalItemsCount: number;
  isLoading: boolean;
}

const initialState: State = {
  payments: [],
  currentPage: 1,
  itemsPerPage: 5,
  totalItemsCount: 0,
  isLoading: false,
};

export default {
  namespaced: true,
  state: initialState,
  actions: {
    fetchPayments({ commit, state }) {
      const params = {
        page: state.currentPage,
        _fields:
          'id,stripe_created_at,stripe_id,stripe_arrived_at,amount{amount,currency},description,status,orders{id,retailer{name},brand_net_amount{amount,currency}}',
      };

      commit('TOGGLE_PAYMENT_LOADING', true);

      return http()
        .get(`/api/me/brand/payouts`, { params })
        .then(({ data }) => {
          commit('SET_USER_PAYMENTS', Object.freeze(data.data));
          commit('SET_PAYMENT_PAGINATION', data.meta);
          commit('TOGGLE_PAYMENT_LOADING', false);
        });
    },
    changeCurrentPage({ commit, dispatch }, page: number) {
      commit('SET_PAYMENT_CURRENT_PAGE', page);
      dispatch('fetchPayments');
    },
  },
  mutations: {
    SET_USER_PAYMENTS(state, data) {
      state.payments = data;
    },
    SET_PAYMENT_PAGINATION(state, meta) {
      state.currentPage = meta.current_page;
      state.totalItemsCount = meta.total;
      state.itemsPerPage = meta.per_page;
    },
    SET_PAYMENT_CURRENT_PAGE(state, page) {
      state.currentPage = page;
    },
    TOGGLE_PAYMENT_LOADING(state, value) {
      state.isLoading = value;
    },
  },
  getters: {
    isLoading: (state: State): boolean => state.isLoading,
    getCurrentPage: (state: State): number => state.currentPage,
    getItemsPerPage: (state: State): number => state.itemsPerPage,
    getTotalItemsCount: (state: State): number => state.totalItemsCount,
    getPayments: (state: State): PaymentItem[] => state.payments,
  },
};
