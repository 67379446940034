<template>
  <AkFormField
    :label="label"
    :error-message="errorMessage"
  >
    <template
      v-if="$slots.label"
      #label
    >
      <slot name="label" />
    </template>
    <template #info>
      <slot name="info" />
    </template>
    <template #formControl>
      <AkInput
        ref="controlComponentRef"
        :key="`ak-input-field--${name}`"
        v-model="value"
        :type="inputType"
        :placeholder="placeholder"
        :max-length="maxLength"
        :has-error="hasError"
        :disabled="isDisabled"
      />
    </template>
  </AkFormField>
</template>

<script lang="ts">
import AkFormField from '@monolith/legacy/components/review/AkForm/AkFormField/ak-form-field.vue';
import AkInput from '@monolith/legacy/components/review/AkForm/AkInput/ak-input.vue';
import { formFieldProps, useFormConnection } from '@monolith/legacy/components/review/AkForm/use-form-connection';
import { toRefs } from 'vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AkInputField',
  components: { AkFormField, AkInput },
  props: {
    ...formFieldProps,
    inputType: {
      type: String,
      default: 'input',
      validator(value: string) {
        return ['input', 'textarea'].includes(value);
      },
    },
    placeholder: {
      type: String,
      default: null,
    },
    maxLength: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { name, validate } = toRefs(props);

    return useFormConnection(name, validate);
  },
});
</script>
