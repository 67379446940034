/**
 * NOTE: This module is going to be deprecated soon in favor of the new carts and checkout modules.
 * @deprecated
 */
import Analytics from '@monolith/legacy/services/analytics';
import ProductRemovedFromCartEvent from '@monolith/legacy/services/analytics/events/product-removed-from-cart-event';
import { ProductProperty } from '@monolith/legacy/services/analytics/properties/product-property';
import ProductQuantityInCartChangedEvent from '@monolith/legacy/services/analytics/events/product-quantity-changed-event';
import ProductAddedToCartEvent from '@monolith/legacy/services/analytics/events/product-added-to-cart-event';
import CartReachedMinimumEvent from '@monolith/legacy/services/analytics/events/cart-reached-minimum-event';
import { Commit, Dispatch } from 'vuex';
import { Cart, CartTracking, CartType, Checkout, NeededInformations } from '@monolith/legacy/types/cart';
import { CartProperty } from '@monolith/legacy/services/analytics/properties/cart-property';
import CartSavedForLaterEvent from '@monolith/legacy/services/analytics/events/cart-saved-for-later-event';
import CartRestoredEvent from '@monolith/legacy/services/analytics/events/cart-restored-event';
import { EoriCheckoutBypassFailure } from '@monolith/legacy/services/analytics/events/eori/eori-checkout-bypass-failure.event';
import { CartItemRemovedDueToMandatoryEori } from '@monolith/legacy/services/analytics/events/eori/cart-item-removed-due-to-mandatory-eori.event';
import { EoriCheckoutEntryError } from '@monolith/legacy/services/analytics/events/eori/eori-checkout-entry-error.event';
import { EoriCheckoutEntrySuccess } from '@monolith/legacy/services/analytics/events/eori/eori-checkout-entry-success.event';
import { TaxNumberType } from '@monolith/legacy/types/tax-number';
import { ActionName } from '@monolith/legacy/store/account/business/actions';
import { CartState } from '@monolith/legacy/store/cart/index';
import { HokodoCompany } from '@monolith/legacy/types/api/retailer';
import {
  ADD_PROMOCODE,
  ADD_REFERRAL_PROMOCODE,
  ADD_TO_CART,
  APPLY_CREDIT,
  CALCULATE_CHECKOUT,
  CART_BULK_UPDATE,
  CHANGE_EORI,
  CHANGE_RECARGO_ENABLED,
  CHANGE_TAB,
  CLEAR_COMPANY_ID_ERRORS,
  CLEAR_EORI_ERRORS,
  CLEAR_RECARGO_ERRORS,
  CLEAR_TAX_NUMBER_ERRORS,
  DELETE_ITEM,
  DELETE_PROMOCODE,
  GET_CHECKOUT,
  GET_CURRENT_CHECKOUT,
  GET_CURRENT_CHECKOUT_TOTALS,
  INIT_BILLING_WRAPPER_STATE,
  INIT_TAX_NUMBER_TYPE,
  SAVE_FOR_LATER,
  PERSIST_SAVE_CART_FOR_LATER,
  PROCEED_TO_CHECKOUT,
  REFRESH_CHECKOUT,
  REMOVE_LOCAL_CART,
  REMOVE_REMOTE_BRAND,
  SCROLL_TO_ERROR,
  SEARCH_HOKODO_COMPANY,
  SEND_EORI_INFORMATION,
  SEND_HOKODO_COMPANY_ID,
  SEND_RECARGO_INFORMATION,
  SEND_SOLE_TRADER_DETAILS,
  SEND_TAX_NUMBER,
  SET_BUSINESS_VALIDATION_STEP,
  SET_COMPANY_ID_ERRORS,
  SET_EORI_ERRORS,
  SET_EORI_SUCCESS,
  SET_HOKODO_COMPANY_ID,
  SET_PROVIDING_TAX_NUMBER_CHOICE,
  SET_RECARGO_ERRORS,
  SET_TAX_NUMBER,
  SET_TAX_NUMBER_ERRORS,
  SET_TAX_NUMBER_TYPE,
  SET_TAX_NUMBER_VALUE,
  TOGGLE_SAVE_CART_FOR_LATER,
  TOGGLE_SKIP_TAX_NUMBER,
  TRACK_MINIMUM_REACHED,
  UPDATE_BULK,
  UPDATE_ITEM_QUANTITY,
  VALIDATE_CART,
  SELECT_BRAND,
  GET_BRANDS_DISCOUNTS,
  GET_ORDER_SUMMARY,
  GET_ITEMS_COUNT,
  UPDATE_VAT_EXEMPTION,
} from '@monolith/legacy/store/cart/action-types';
import * as Mutations from './mutation-types';
import {
  addPromocode,
  applyCredit,
  BulkUpdate,
  deletePromocode,
  getCartItemsCount,
  getCheckout,
  getCurrentCheckout,
  getOrderSummary,
  getReferralCode,
  NewBulkUpdate,
  removeRemoteBrand,
  saveCartForLater,
  sendRecargoEnabled,
  sendTaxNumber,
  updateItemsBulk,
} from '@monolith/legacy/services/api/cart';
import { searchForCompany, sendCompanyId } from '@monolith/legacy/services/api/hokodo';
import { FeatureFlag, isEnabled } from '@monolith/legacy/services/features';
import { debounce } from 'lodash-es';
import { updateCartsSelected } from '@monolith/legacy/services/api/cart-evolution';
import { BrandsBulk } from '@monolith/legacy/types/api/cart';
import Getters from '@monolith/legacy/store/cart/getters';
import { capturePurchasingException } from '@core/plugins/sentry/helper';
import { getPersonalDiscount } from '@monolith/legacy/services/api/brand-discount';
import { BrandDiscount, BrandDiscountContext } from '@monolith/legacy/types/api/brand-discount';
import { MOVE_CART } from './mutation-types';
import { getGlobalConfig } from '@monolith/legacy/services/global-config';
import { productsCount } from '@monolith/legacy/modules/cart-2/composables/use-counters';

const BULK_UPDATE_TIMEOUT = 2000;

const cartActions = {
  [UPDATE_BULK]: debounce(async ({ commit, getters, dispatch }) => {
    const updates = getters.getUpdates;

    if (updates.length === 0) {
      return;
    }

    try {
      commit(Mutations.SET_TOTALS_LOADING, true);
      await updateItemsBulk(updates);
      dispatch(GET_ITEMS_COUNT);
    } finally {
      commit(Mutations.RESET_BULK_UPDATES);
      commit(Mutations.SET_TOTALS_LOADING, false);
      commit(Mutations.SET_CART_LOADING, false);
    }
  }, BULK_UPDATE_TIMEOUT),

  [CART_BULK_UPDATE]: async (
    { dispatch, commit },
    { update, cartType = CartType.Main }: { update: BulkUpdate; cartType?: CartType }
  ) => {
    commit(Mutations.SET_CART_LOADING, true);
    commit(Mutations.UPDATE_BULK, update);
    commit(Mutations.SET_TOTALS_LOADING, true);
    dispatch(UPDATE_BULK, { cartType });
  },

  async [DELETE_ITEM]({ dispatch, commit, getters, rootGetters }, { brand, item, cartType }) {
    const cart = { ...getters.cartByBrandId(brand.id) };
    commit(Mutations.DELETE_ITEM, { brand, item, cartType });

    await Analytics.track(
      new ProductRemovedFromCartEvent(
        {
          id: item.product.id,
          name: item.product.name,
          wholesalePrice: item.wholesale_price,
          option: {
            id: item.option.id,
            name: item.option.name,
          },
          variant: {
            id: item.variant?.id,
            name: item.variant?.name,
          },
        } as ProductProperty,
        brand,
        {
          id: cart.id,
          totalAmountWithVat: cart.total_amount_with_vat,
        }
      )
    );

    dispatch(TRACK_MINIMUM_REACHED, cart);

    if (isEnabled(FeatureFlag.MandatoryEori) && cart.shipping_is_restricted_by_eori) {
      await Analytics.track(
        new CartItemRemovedDueToMandatoryEori({
          productId: item.product.id,
          optionId: item.option.id,
          variantId: item.variant?.id,
          checkoutId: getters.checkout.id,
          retailerId: rootGetters.retailer.id,
        })
      );
      dispatch(CLEAR_EORI_ERRORS);
    }

    const update: BulkUpdate = {
      ...{ order_item_id: item.id },
      quantity: 0,
    };
    dispatch(CART_BULK_UPDATE, { update });
  },
  async [UPDATE_ITEM_QUANTITY]({ dispatch, commit, getters }, { brand, item, quantity, cartType = CartType.Main }) {
    try {
      commit(Mutations.CHANGE_ITEM_QUANTITY, { brand, item, quantity, cartType });
    } catch (e) {
      capturePurchasingException(e);
    }
    if (cartType === CartType.SavedForLater) {
      commit(Mutations.CALCULATE_BRANDS_TOTALS, { cartType });
    } else {
      dispatch(CALCULATE_CHECKOUT);
    }
    dispatch(CLEAR_EORI_ERRORS);
    const cart = getters.cartByBrandId(brand.id);
    Analytics.track(
      new ProductQuantityInCartChangedEvent(
        {
          id: item.product.id,
          name: item.product.name,
          wholesalePrice: item.wholesale_price,
          option: {
            id: item.option.id,
            name: item.option.name,
          },
          variant: {
            id: item.variant?.id,
            name: item.variant?.name,
          },
        } as ProductProperty,
        quantity * item.option.unit_multiplier,
        brand,
        {
          id: cart.id,
          totalAmountWithVat: cart.total_amount_with_vat,
        }
      )
    );
    dispatch(TRACK_MINIMUM_REACHED, cart);

    let update: BulkUpdate | NewBulkUpdate;

    // NOTE: By using Quick add item could be not yet in the cart, but the retail customer can increase the quantity
    if (item.id !== null) {
      update = {
        ...{ order_item_id: item.id },
        quantity,
      };
    } else {
      update = {
        option_id: item.option.id,
        product_variant_id: item.variant?.id,
        quantity,
      };
    }

    dispatch(CART_BULK_UPDATE, { update, cartType });
  },
  async [ADD_TO_CART]({ dispatch, getters, commit, rootGetters }, { brand, option, variant, quantity, noTrack }) {
    commit(Mutations.SET_CART_LOADING, true);

    const product = rootGetters.productByOptionId(option.id);
    if (product) {
      commit(Mutations.ADD_ITEM, {
        brand,
        option,
        variant,
        product,
        quantity,
      });

      dispatch(CALCULATE_CHECKOUT);
      const cart = getters.cartByBrandId(brand.id);
      if (!noTrack) {
        await Analytics.track(
          new ProductAddedToCartEvent(
            {
              id: product.id,
              name: product.name,
              wholesalePrice: product.wholesale_price,
              option,
              variant,
            } as ProductProperty,
            quantity * product.unit_multiplier,
            brand,
            {
              id: cart.id,
              totalAmountWithVat: cart.total_amount_with_vat,
            }
          )
        );
      }
      dispatch(TRACK_MINIMUM_REACHED, cart);
      commit(Mutations.SET_CART_LOADING, false);
    }

    const update: BulkUpdate = {
      option_id: option.id as number,
      product_variant_id: variant?.id,
      quantity,
    };
    dispatch(CART_BULK_UPDATE, { update });
  },
  async [TRACK_MINIMUM_REACHED]({ commit, getters }, cart) {
    if (!cart.minimum?.above && getters.hasEventMinimumReachedSent(cart.id)) {
      return commit(Mutations.CART_MINIMUM_HAS_GONE, cart.id);
    }

    if (cart.minimum.above && !getters.hasEventMinimumReachedSent(cart.id)) {
      await Analytics.track(
        new CartReachedMinimumEvent(
          {
            id: cart.id,
            totalAmountWithVat: cart.total_amount_with_vat,
          },
          cart.brand
        )
      );
      commit(Mutations.CART_MINIMUM_REACHED, cart.id);
    }
  },
  async [DELETE_PROMOCODE]({ dispatch }, { checkoutId, promocodeId }) {
    await deletePromocode(checkoutId, promocodeId);
    dispatch(GET_CHECKOUT, { checkoutId: checkoutId });
  },
  async [ADD_PROMOCODE]({ dispatch, commit }, { code, checkoutId }) {
    const res = await addPromocode(checkoutId, code);
    if (res?.response?.data?.errorMessage) {
      commit(Mutations.SET_LAST_PROMOCODE_CALL_RESULT, {
        code,
        errorMessage: res.response.data.errorMessage,
      });
      return;
    }

    commit(Mutations.SET_LAST_PROMOCODE_CALL_RESULT, {
      code,
      errorMessage: '',
    });

    dispatch(GET_CHECKOUT, { checkoutId: checkoutId });
  },
  async [ADD_REFERRAL_PROMOCODE]({ dispatch }, { checkout }) {
    if (!checkout || !checkout.is_first_checkout || checkout.promocodes?.length) {
      return;
    }

    for (const cart of checkout.carts) {
      if (cart.promocodes?.length) {
        return;
      }
    }

    const res = await getReferralCode();

    if (res.status !== 200) {
      return;
    }

    dispatch(ADD_PROMOCODE, {
      checkoutId: checkout.id,
      code: res.data.data.code,
    });
  },
  async [APPLY_CREDIT]({ dispatch }, { amount, checkoutId }) {
    await applyCredit(amount);
    dispatch(GET_CHECKOUT, { checkoutId: checkoutId });
  },
  [REFRESH_CHECKOUT]({ state, commit }, checkout) {
    // when the answer comes from the api, if another call to the api has been made we want to invalidate
    if (state.apiCallCount > 0) {
      return;
    }
    commit(Mutations.REFRESH_CHECKOUT, checkout);
    commit(Mutations.SET_TOTALS_LOADING, false);
    commit(Mutations.SET_CART_LOADING, false);
  },
  async [GET_CHECKOUT]({ dispatch }, { checkoutId }): Promise<Checkout> {
    const checkout = await getCheckout(checkoutId);
    await dispatch(REFRESH_CHECKOUT, checkout);
    return checkout;
  },

  async [GET_CURRENT_CHECKOUT_TOTALS]({ dispatch, commit, getters }, withLoader = true): Promise<Checkout | undefined> {
    if (withLoader) {
      commit(Mutations.SET_TOTALS_LOADING, true);
    }

    try {
      const currentCheckout = await getCurrentCheckout();

      if (!currentCheckout) {
        return undefined;
      }

      const tracking: CartTracking = {
        minimumReachedEventsSent: [...getters.aboveCarts.map((cart) => cart.id)],
      };

      commit(Mutations.SET_TRACKING, tracking);
      await dispatch(REFRESH_CHECKOUT, currentCheckout);
      await dispatch(INIT_BILLING_WRAPPER_STATE);

      return currentCheckout;
    } finally {
      if (withLoader) {
        commit(Mutations.SET_TOTALS_LOADING, false);
      }
    }
  },
  async [GET_CURRENT_CHECKOUT]({ state, commit }): Promise<void> {
    // when the answer comes from the api, if another call to the api has been made we want to invalidate
    if (state.apiCallCount > 0) {
      return;
    }

    commit(Mutations.SET_CART_LOADING, true);

    try {
      //replace with cart api call
      // const checkout = await getFloatingCart();
      // if (checkout) {
      //   await dispatch(REFRESH_CHECKOUT, checkout);
      // }
    } finally {
      commit(Mutations.SET_CART_LOADING, false);
      commit(Mutations.SET_TOTALS_LOADING, false);
    }
  },
  [TOGGLE_SAVE_CART_FOR_LATER]({ commit, dispatch }, cart) {
    dispatch(CLEAR_EORI_ERRORS);
    return commit(Mutations.TOGGLE_CART_SAVE_FOR_LATER, cart);
  },

  // NEW ACTION FOR SAVE FOR LATER / MAIN CART
  async [SELECT_BRAND](
    {
      dispatch,
    }: {
      dispatch: Dispatch;
    },
    { brands }: { brands: BrandsBulk[] }
  ) {
    const checkout = await updateCartsSelected(brands);
    dispatch(REFRESH_CHECKOUT, checkout);
  },

  async [PERSIST_SAVE_CART_FOR_LATER](_, { cart, isGotoSavedForLater = true }: { cart: Cart; isGotoSavedForLater: boolean }) {
    try {
      await saveCartForLater(cart.brand.id, isGotoSavedForLater);
    } catch (e) {
      capturePurchasingException(e);
      return Promise.reject(e);
    }
    return Promise.resolve();
  },

  /**
   * Persists a cart to be saved for later / back in the main cart
   *
   * @param {ActionContext} [vuexContext] - The Vuex action context
   * @param {Object} payload - The action payload
   * @param {Cart} payload.cart - The cart to be saved for later
   * @param {number} payload.checkoutId - The checkout id
   * @param {boolean} [payload.isGotoSavedForLater] - Whether to go to the saved for later or main cart. Optional
   * @param {boolean} [payload.track] - Whether to track the event. Optional
   * @returns resoled Promise
   *
   */
  async [SAVE_FOR_LATER](
    { dispatch, commit, state }: { dispatch: Dispatch; commit: Commit; state: CartState },
    {
      cart,
      isGotoSavedForLater = null,
      track = true,
    }: {
      cart: Cart;
      isGotoSavedForLater: boolean | null;
      track: boolean;
    }
  ) {
    //Todo: check if this is needed
    await dispatch('showProgressBar', undefined, { root: true });

    if (cart.minimum.above) {
      commit(Mutations.SET_TOTALS_LOADING, true);
    }

    // When not specified, toggle the cart to the other cart tab.
    isGotoSavedForLater = isGotoSavedForLater ?? !state.isSavedForLaterDisplayed;

    const cartProperty: CartProperty = {
      id: cart.id,
      totalAmountWithVat: cart.total_amount_with_vat,
    };

    if (track) {
      Analytics.track(
        isGotoSavedForLater
          ? new CartSavedForLaterEvent(cartProperty, cart.brand)
          : new CartRestoredEvent(cartProperty, cart.brand)
      );
    }

    try {
      await dispatch(PERSIST_SAVE_CART_FOR_LATER, { cart, isGotoSavedForLater });
    } catch (_e) {
      // toDo. Add error handling
    }

    dispatch(GET_ITEMS_COUNT);

    // Order summary is coming in the next iteration
    // await dispatch(GET_ORDER_SUMMARY);
    if (!cart.minimum.above) {
      return commit(MOVE_CART, { cart, isGotoSavedForLater });
    }
    await dispatch(GET_CURRENT_CHECKOUT_TOTALS, false);
    return commit(Mutations.SET_TOTALS_LOADING, false);
  },
  [REMOVE_LOCAL_CART]({ commit }, cartId: number) {
    return commit(Mutations.REMOVE_LOCAL_CART, cartId);
  },
  async [REMOVE_REMOTE_BRAND]({ dispatch }: { dispatch: Dispatch }, brandId: number) {
    await dispatch('showProgressBar', undefined, { root: true });

    try {
      await removeRemoteBrand(brandId);
    } catch {
      return dispatch(SCROLL_TO_ERROR);
    }
  },
  [SCROLL_TO_ERROR]() {
    window.scrollTo(0, 0);
  },
  [SET_TAX_NUMBER_ERRORS]({ commit }, payload) {
    commit(Mutations.SET_TAX_NUMBER_ERRORS, payload);
  },
  [SET_RECARGO_ERRORS]({ commit }, payload) {
    commit(Mutations.SET_RECARGO_ERRORS, payload);
  },
  [SET_EORI_ERRORS]({ commit }, payload) {
    commit(Mutations.SET_EORI_ERRORS, payload);
  },
  [CHANGE_RECARGO_ENABLED]({ commit, dispatch }, payload) {
    dispatch(CLEAR_RECARGO_ERRORS);
    commit(Mutations.CHANGE_RECARGO_ENABLED, payload);
  },
  [CHANGE_EORI]({ commit, dispatch }, payload) {
    dispatch(CLEAR_EORI_ERRORS);
    dispatch(SET_EORI_SUCCESS, false);
    commit(Mutations.CHANGE_EORI, payload);
  },
  [CLEAR_TAX_NUMBER_ERRORS]({ commit }) {
    commit(Mutations.CLEAR_TAX_NUMBER_ERRORS);
  },
  [SET_COMPANY_ID_ERRORS]({ commit }, payload) {
    commit(Mutations.SET_COMPANY_ID_ERRORS, payload);
  },
  [CLEAR_COMPANY_ID_ERRORS]({ commit }) {
    commit(Mutations.CLEAR_COMPANY_ID_ERRORS);
  },
  [SET_TAX_NUMBER]({ commit }, payload) {
    commit(Mutations.SET_TAX_NUMBER, payload);
  },
  [CLEAR_RECARGO_ERRORS]({ commit }) {
    commit(Mutations.CLEAR_RECARGO_ERRORS);
  },
  [CLEAR_EORI_ERRORS]({ commit }) {
    commit(Mutations.CLEAR_EORI_ERRORS);
  },
  [SET_EORI_SUCCESS]({ commit }, payload) {
    commit(Mutations.SET_EORI_SUCCESS, payload);
  },
  async [SEND_TAX_NUMBER]({ dispatch, state }: { dispatch: Dispatch; state: CartState }) {
    const taxNumber = state.taxNumber;
    try {
      const result = await sendTaxNumber(taxNumber.type, taxNumber.value);

      if ('DE' !== state.checkout.retailer_country?.iso_code) {
        return dispatch(PROCEED_TO_CHECKOUT);
      }

      await dispatch('replaceRetailer', result, { root: true });
      await dispatch(SET_TAX_NUMBER_VALUE, {
        value: result.business.tax_number,
      });

      await dispatch(SCROLL_TO_ERROR);
      await dispatch(SET_COMPANY_ID_ERRORS);
      return dispatch(SET_BUSINESS_VALIDATION_STEP, NeededInformations.CompanyId);
    } catch ({ response: { data } }) {
      await dispatch(SCROLL_TO_ERROR);
      await dispatch(SET_TAX_NUMBER_ERRORS, data.errors[taxNumber.type]);
    }
  },
  async [SEND_SOLE_TRADER_DETAILS]({ dispatch }: { dispatch: Dispatch }) {
    try {
      await dispatch('account/soleTrader/sendSoleTraderDetails', null, {
        root: true,
      });
      return dispatch(PROCEED_TO_CHECKOUT);
    } catch {
      return dispatch(SCROLL_TO_ERROR);
    }
  },
  async [SEARCH_HOKODO_COMPANY]({ state, commit }: { state: CartState; commit: Commit }): Promise<HokodoCompany[]> {
    if (state.taxNumber.type && state.taxNumber.type !== TaxNumberType.TaxNumber) {
      return Promise.reject('Wrong tax number type');
    }
    if (!state.taxNumber.value) {
      return Promise.reject('Empty tax number');
    }
    commit(Mutations.TOGGLE_HOKODO_LOADING, true);
    let result = [];
    try {
      result = await searchForCompany(state.taxNumber.value);
    } catch (err) {
      capturePurchasingException(err);
    } finally {
      commit(Mutations.TOGGLE_HOKODO_LOADING, false);
    }
    return Promise.resolve(result);
  },
  async [SET_HOKODO_COMPANY_ID]({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, value: string) {
    await dispatch(CLEAR_COMPANY_ID_ERRORS);
    commit(Mutations.SET_HOKODO_COMPANY_ID, value);
    return dispatch(
      SET_BUSINESS_VALIDATION_STEP,
      value === 'none' ? NeededInformations.CompanyRegistration : NeededInformations.CompanyId
    );
  },
  async [SEND_HOKODO_COMPANY_ID]({ dispatch }: { dispatch: Dispatch }, companyId: number): Promise<void> {
    const result = await sendCompanyId(`${companyId}`);
    return dispatch('replaceRetailer', result, { root: true });
  },
  [PROCEED_TO_CHECKOUT]({ getters }, proceedToCheckout = true) {
    if (!getters.hasErrors && proceedToCheckout) {
      window.location.href = '/checkout';
    }
  },
  [SET_TAX_NUMBER_TYPE]({ commit }: { commit: Commit }, type: TaxNumberType | null) {
    return commit(Mutations.SET_TAX_NUMBER, {
      type,
      value: null,
      isSkipped: false,
      isValid: false,
    });
  },

  /**
   * NOT USED ????!!!!!!
   */
  async [SET_PROVIDING_TAX_NUMBER_CHOICE]({ dispatch, getters }, isSkipped: boolean) {
    await dispatch(CLEAR_TAX_NUMBER_ERRORS);
    if (!isSkipped) {
      await dispatch(SET_BUSINESS_VALIDATION_STEP, NeededInformations.TaxNumber);
    } else {
      if (getters.canRetailerBeSoleTrader) {
        await dispatch(SET_BUSINESS_VALIDATION_STEP, NeededInformations.SoleTrader);
      } else {
        await dispatch(TOGGLE_SKIP_TAX_NUMBER, isSkipped);
        return dispatch(SET_BUSINESS_VALIDATION_STEP, NeededInformations.None);
      }
    }
    return dispatch(TOGGLE_SKIP_TAX_NUMBER, isSkipped);
  },
  [TOGGLE_SKIP_TAX_NUMBER]({ commit }, isSkipped: boolean) {
    commit(Mutations.TOGGLE_SKIP_TAX_NUMBER, isSkipped);
  },
  [SET_BUSINESS_VALIDATION_STEP]({ commit }, step: NeededInformations) {
    commit(Mutations.SET_BUSINESS_VALIDATION_STEP, step);
  },
  [INIT_TAX_NUMBER_TYPE]({ state, dispatch }) {
    if (!getGlobalConfig().countries_config[state.checkout?.retailer_country?.iso_code]) {
      return Promise.reject('RETAILER COUNTRY NOT OPEN');
    }
    const type =
      getGlobalConfig().countries_config[state.checkout.retailer_country?.iso_code].business_identifier_type ===
      TaxNumberType.VatNumber
        ? TaxNumberType.TaxNumber
        : TaxNumberType.VatNumber;

    return dispatch(SET_TAX_NUMBER_TYPE, type);
  },
  [SET_TAX_NUMBER_VALUE]({ commit, dispatch, state }, { forceStep, ...value }) {
    commit(Mutations.SET_TAX_NUMBER_VALUE, value);
    if (forceStep && forceStep !== state.businessValidationStep) {
      return dispatch(SET_BUSINESS_VALIDATION_STEP, forceStep);
    }
  },
  async [CALCULATE_CHECKOUT]({ commit }) {
    commit(Mutations.CALCULATE_BRANDS_TOTALS, { cartType: CartType.Main });
    commit(Mutations.CALCULATE_CHECKOUT);
  },
  async [INIT_BILLING_WRAPPER_STATE]({
    state,
    dispatch,
    getters,
    rootGetters,
  }: {
    state: CartState;
    dispatch: Dispatch;
    getters: typeof Getters;
    rootGetters: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }) {
    // DE retailer with hokodo company id
    if (getters.isRetailerGerman && rootGetters.hasHokodoCompany) {
      return;
    }

    await dispatch(INIT_TAX_NUMBER_TYPE);

    if (rootGetters.retailer.business.tax_number && state.taxNumber.type === TaxNumberType.TaxNumber) {
      await dispatch(SET_TAX_NUMBER_VALUE, {
        value: rootGetters.retailer.business.tax_number,
      });

      if (!getters.canRetailerBeSoleTrader) {
        return;
      }
    }

    if (state.taxNumber.type === TaxNumberType.VatNumber) {
      let vatNumber = rootGetters.retailer.business.vat_number;
      if (!vatNumber && 'CH' === state.checkout.retailer_country.iso_code) {
        let suffix = 'TVA';
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((this as any).$lang === 'de') {
          suffix = 'MWST';
        }

        vatNumber = `${rootGetters.retailer.business.tax_number} ${suffix}`;
      }

      if (vatNumber) {
        await dispatch(SET_TAX_NUMBER_VALUE, {
          value: vatNumber,
        });
      }

      if (rootGetters.retailer.business.vat_number) {
        return;
      }
    }
    const businessValidationStep =
      state.taxNumber.value === null || !getters.canRetailerBeSoleTrader
        ? NeededInformations.TaxNumber
        : NeededInformations.CompanyId;
    if (businessValidationStep === NeededInformations.CompanyId) {
      await dispatch(SET_COMPANY_ID_ERRORS);
    }
    return dispatch(SET_BUSINESS_VALIDATION_STEP, businessValidationStep);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async [SEND_RECARGO_INFORMATION]({ dispatch, state, rootGetters }: { dispatch: Dispatch; state: CartState; rootGetters: any }) {
    const recargo = state.recargoEnabled ?? rootGetters['account/business/isPayingRecargo'];

    try {
      await sendRecargoEnabled(recargo);
    } catch ({ response: { data } }) {
      await dispatch(SCROLL_TO_ERROR);
      await dispatch(SET_RECARGO_ERRORS, data.errors.is_paying_recargo);
    }
  },
  async [SEND_EORI_INFORMATION]({
    dispatch,
    state,
    getters,
    rootGetters,
  }: {
    dispatch: Dispatch;
    state: CartState;
    getters: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    rootGetters: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }) {
    await dispatch(CLEAR_EORI_ERRORS);
    const eori = state.eori;

    try {
      await dispatch(`account/business/${ActionName.UpdateEoriNumber}`, eori, {
        root: true,
      });

      await dispatch(SET_EORI_SUCCESS, true);

      Analytics.track(
        new EoriCheckoutEntrySuccess({
          checkoutId: getters.checkout.id,
          retailerId: rootGetters.retailer.id,
          value: eori,
        })
      );
    } catch ({ response: { data } }) {
      await dispatch(SCROLL_TO_ERROR);
      await dispatch(SET_EORI_ERRORS, data.errors?.eori_number);

      if (eori?.length === 0) {
        // User attempted to skip page without entering number.
        Analytics.track(
          new EoriCheckoutBypassFailure({
            checkoutId: getters.checkout.id,
            retailerId: rootGetters.retailer.id,
          })
        );
      } else {
        // User entered an invalid value.
        Analytics.track(
          new EoriCheckoutEntryError({
            checkoutId: getters.checkout.id,
            message: data.errors?.eori_number?.toString(),
            retailerId: rootGetters.retailer.id,
            value: eori,
          })
        );
      }
    }
  },
  async [VALIDATE_CART]({ commit, state, getters, dispatch }, proceedToCheckout = true) {
    commit(Mutations.SET_CHECKOUT_START_LOADING_TIME, window.performance.now());
    await dispatch(CLEAR_TAX_NUMBER_ERRORS);
    await dispatch('account/soleTrader/clearSoleTraderDetailsErrors', null, {
      root: true,
    });
    await dispatch(CLEAR_COMPANY_ID_ERRORS);
    if (getters.shouldShowRecargoQuestion) {
      await dispatch(SEND_RECARGO_INFORMATION);
    }

    if (getters.shouldShowEoriQuestion) {
      await dispatch(SEND_EORI_INFORMATION);
    }

    if (state.businessValidationStep === NeededInformations.None) {
      await dispatch(UPDATE_VAT_EXEMPTION);
      return dispatch(PROCEED_TO_CHECKOUT, proceedToCheckout);
    }
    switch (state.businessValidationStep) {
      case NeededInformations.CompanyId:
        if (null === state.hokodoCompany) {
          await dispatch(SCROLL_TO_ERROR);
          await dispatch(SET_COMPANY_ID_ERRORS);
          return;
        }
        // if no match with companies returned by Hokodo we display the sole trader form
        if ('none' === state.hokodoCompany) {
          return dispatch(SET_BUSINESS_VALIDATION_STEP, NeededInformations.SoleTrader);
        }
        // else we send the company id to the backend and then proceedToCheckout
        await dispatch(SEND_HOKODO_COMPANY_ID, state.hokodoCompany);
        return dispatch(PROCEED_TO_CHECKOUT, proceedToCheckout);

      case NeededInformations.TaxNumber:
        if (getters['shouldDisplayCheckoutSoleTraderUk'] && !state.taxNumber.value) {
          return dispatch(SEND_SOLE_TRADER_DETAILS);
        }
        if (state.taxNumber.isSkipped) {
          return dispatch(PROCEED_TO_CHECKOUT, proceedToCheckout);
        }
        if (state.taxNumber.type === TaxNumberType.VatNumber) {
          await dispatch(UPDATE_VAT_EXEMPTION);
        }
        return dispatch(SEND_TAX_NUMBER);
      case NeededInformations.SoleTrader:
      case NeededInformations.CompanyRegistration:
        return dispatch(SEND_SOLE_TRADER_DETAILS);
    }
  },
  async [GET_BRANDS_DISCOUNTS]({ commit }, brandIds: number[]) {
    let discounts: BrandDiscount[] = [];
    try {
      discounts = await getPersonalDiscount(BrandDiscountContext.cartPage, brandIds);
      commit(Mutations.SET_BRANDS_DISCOUNTS, discounts);
    } catch (e) {
      capturePurchasingException(e.message);
    }
  },
  async [CHANGE_TAB]({ commit }, tab: CartType) {
    commit(Mutations.SET_CURRENT_TAB, tab === CartType.SavedForLater);
  },
  async [GET_ORDER_SUMMARY]({ commit }) {
    const orderSummary = await getOrderSummary();
    delete orderSummary.carts;
    commit(Mutations.SET_ORDER_SUMMARY, orderSummary);
    return orderSummary;
  },
  async [GET_ITEMS_COUNT]({ rootState, commit }) {
    const itemsCount = await getCartItemsCount(rootState.user.business.retailer.uuid);
    productsCount.value = itemsCount.main;
    commit(Mutations.SET_ITEMS_COUNT, itemsCount);
    return itemsCount;
  },
  async [UPDATE_VAT_EXEMPTION]({ getters, dispatch }) {
    return await dispatch(
      `account/business/${ActionName.UpdateVatInfo}`,
      {
        aboveIntracommThreshold: false,
        vatExemption: getters['getVatExemption'],
      },
      { root: true }
    );
  },
};

export default cartActions;
export type CartActions = typeof cartActions;
