<template>
  <IntersectionObserver
    :threshold="threshold"
    @change="sectionViewed"
  >
    <slot></slot>
  </IntersectionObserver>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import IntersectionObserver from '@monolith/legacy/components/global/interception-observer.vue';

export default defineComponent({
  name: 'ShownObserver',
  components: {
    IntersectionObserver,
  },
  props: {
    callback: {
      type: Function,
      required: true,
    },
    threshold: {
      type: Number,
      required: false,
      default: 0.6,
    },
  },
  methods: {
    sectionViewed({ isIntersecting }, unsubscribe) {
      if (isIntersecting) {
        this.callback();
        unsubscribe();
      }
    },
  },
});
</script>
