import http from './http';

import { Cart, CartType, Checkout } from '@monolith/legacy/types/cart';
import { NewProductUpdate } from '@monolith/legacy/services/api/cart';
import { BrandsBulk } from '@monolith/legacy/types/api/cart';

const ROUTES_PREFIX = '/api/internal/cart';

export interface MoveProductsFromCartPayload {
  item_ids?: string[];
  all_items?: boolean;
}

export interface MoveProductsFromCartResponse {
  carts: Cart[];
  checkout: Checkout;
}

export const moveProductsFromCart = async (
  from: number,
  to: number,
  payload: MoveProductsFromCartPayload
): Promise<MoveProductsFromCartResponse> => {
  const response = await http().put(`${ROUTES_PREFIX}/move/${from}/${to}`, {
    item_ids: payload,
  });

  return response?.data?.data;
};

export const addItemsToCart = async (items: NewProductUpdate[], cartType: CartType = CartType.Main) => {
  const response = await http().patch(`${ROUTES_PREFIX}/${cartType}/items`, {
    items,
  });

  return response?.data?.data;
};

/** MONO CHECKOUT */

export const updateCartsSelected = async (brands: BrandsBulk[]): Promise<Checkout> => {
  const response = await http().patch('/api/internal/mono-checkout/brands', {
    brands: brands,
  });

  return response?.data?.data;
};
