import { Brand } from '@monolith/legacy/types/api/brand';

export type BrandProperty = Pick<Brand, 'id'> & Partial<Pick<Brand, 'name'>>;

export function createBrandProperty({ id, name }: BrandProperty): BrandProperty {
  const result: BrandProperty = {
    id,
  };

  if (name) {
    result.name = name;
  }

  return result;
}
