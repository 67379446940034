import {
  useState as useRootState,
  useGetters as useRootGetters,
  useActions as useRootActions,
  useMutations as useRootMutations,
} from 'vuex-composition-helpers';

import type { RootState, RootGetters, RootActions, RootMutations } from '@monolith/legacy/store';
import type { KnownKeys } from 'vuex-composition-helpers/dist/types/util';

export const useState = (map: KnownKeys<RootState>[]) => useRootState<RootState>(map);
export const useGetters = (map: KnownKeys<RootGetters>[]) => useRootGetters<RootGetters>(map);
export const useActions = (map: KnownKeys<RootActions>[]) => useRootActions<RootActions>(map);
export const useMutations = (map: KnownKeys<RootMutations>[]) => useRootMutations<RootMutations>(map);

export default {
  useState,
  useGetters,
  useActions,
  useMutations,
};
