import { Amount } from '@core/types/amount';
import { BillingItem } from '@monolith/legacy/types/billing-item';
import { Promocode } from '@monolith/legacy/types/promocode';
import { CheckoutAddress, CheckoutVatApplicationDetails } from '@monolith/legacy/components/cart/checkout/checkout.types';
import Product, { Option, Variant } from '@monolith/legacy/types/product';
import { ShippingLeadTime } from '@monolith/legacy/types/order';
import { ShippingFee } from '@monolith/legacy/types/shipping/shipping-fees';
import { Brand } from '@monolith/legacy/types/api/brand';
import { ApplicableFee } from '@monolith/legacy/modules/cart-2/types';

export enum NeededInformations {
  None = 'None',
  TaxNumber = 'TaxNumber',
  CompanyId = 'CompanyId',
  CompanyRegistration = 'CompanyRegistration',
  SoleTrader = 'SoleTrader',
}

export interface Checkout {
  type: 'checkout';
  id: number | null;
  status: 'unsubmitted';
  billing_items: BillingItem[];
  retailer_grand_total_amount_with_vat: Amount;
  amount_left_for_free_shipping: Amount;
  total_amount_with_vat: Amount;
  total_amount_vat: Amount;
  total_amount: Amount;
  carts: Cart[];
  free_shipping_minimum_amount: Amount;
  free_shipping: boolean;
  regular_shipping_fees_amount: Amount;
  retailer_country?: {
    iso_code: string;
  };
  firstInit: boolean;
  is_first_checkout: boolean;
  billing_address?: CheckoutAddress;
  shipping_address?: CheckoutAddress;
  extra_taxes: ExtraTaxes;
  order_informations?: {
    order_items_count_main: number;
    order_items_count_saved_for_later: number;
    type: 'order_informations';
  };
  vat_application_details?: CheckoutVatApplicationDetails[];
}

export interface Cart {
  type: 'cart';
  id: number;
  brand: CartBrand;
  items: Item[];
  saved_for_later: boolean;
  selected?: boolean;
  minimum: {
    above: boolean;
    amount: Amount;
  };
  retailer_net_amount: Amount | null;
  total_amount_with_vat: Amount | null;
  total_amount_vat: Amount | null;
  total_amount: Amount | null;
  billing_items: BillingItem[];
  minimumReachedEventSent: boolean;
  is_first_order_on_this_brand: boolean;
  recargo_de_equivalencia_amount: Amount | null;
  shipping_is_restricted_by_eori?: boolean;
  promocodes?: Promocode[];
  zero_rate_export?: string;
  vat_application_details?: { message: string };
  total_amount_before_discount?: Amount | null;
  shipping_lead_time: ShippingLeadTime;
  customs_shipping_fees: CustomsShippingFees;
  amount_left_for_free_shipping: Amount;
  applicable_shipping_fees?: ShippingFee[];
  applicableFees?: ApplicableFee[];
}

export interface CustomsShippingFees {
  refundable: string;
  applicable: string;
}

export type CartBrand = Pick<
  Brand,
  | 'id'
  | 'name'
  | 'isOpenedInUserCountry'
  | 'opened_countries'
  | 'link'
  | 'images'
  | 'brand_tags'
  | 'shipping_lead_time_details'
  | 'addresses'
  | 'uuid'
> & {
  is_eligible_for_discovery_free_shipping: boolean;
  is_discovery: boolean;
  applicable_shipping_fees: BillingItem[];
  event_special_discount?: number;
  country?: Country;
  display_address?: {
    country: string;
  };
};

export interface Item {
  type: 'order_item';
  id: number | null;
  active: boolean;
  quantity: number;
  multiplied_quantity: number;
  unit_price: Amount;
  amount_with_vat: Amount;
  amount_vat: Amount;
  amount: Amount;
  wholesale_price?: Amount;
  option: Option;
  variant?: Variant;
  product: Product;
  fakeItemForFrontFeedback?: boolean;
  amount_before_discount?: Amount;
  applicable_shipping_fees?: ShippingFee[];
  unit_price_before_discount?: Amount;
  unit_multiplier?: number;
}

export interface ExtraTaxes {
  recargo_de_equivalencia?: string;
}

/**
 * Cart evolution
 * @link https://ankorstore.github.io/ankorstore/#tag/AnkorstoreInternal_Cart/operation/get-all-carts
 **/
interface Country {
  type: 'country';
  id: number;
  name: string;
  iso_code: string;
  calling_code: number;
  opened_to_retailers: boolean;
  opened_to_brands: boolean;
}

export interface RetailerCountry {
  id: number;
  created_at: string;
  updated_at: string;
  iso_code: string;
  calling_code: number;
  opened_to_retailers: boolean;
  opened_to_brands: boolean;
  display_made_in: boolean;
  name: {
    [key: string]: string;
  };
}

export interface CartBrands {
  type: 'cart_brand';
  id: number;
  minimum: {
    above: boolean;
    amount: Amount;
  };
  brand: CartBrand;
}

export enum CartType {
  Main = 'main',
  SavedForLater = 'saved_for_later',
}

export interface CartTracking {
  minimumReachedEventsSent: Array<Cart['id']>;
}

export interface CartSelectCompanyOption {
  value: string;
  label: string;
  disabled?: boolean;
}

export type CheckoutWithoutCarts = Omit<Checkout, 'carts'>;
