<template>
  <StockAlertBtn
    v-if="productVariantId"
    btn-type="outlined"
    btn-size="md"
    :full-width="true"
    :is-loading="isStockAlertLoading"
    :product-variant-id="productVariantId"
    :stock-alert="productVariantStockAlert"
    :alert-text="alertText"
    :subscribed-alert-text="subscribedAlertText"
    :multiline="true"
    @set-stock-alert="onSetStockAlert"
  />
</template>

<script setup lang="ts">
import StockAlertBtn from '@monolith/legacy/modules/reorder/stock-status/stock-alert-btn.vue';
import { computed, onMounted, PropType } from 'vue';
import { OrderedProduct } from '@monolith/legacy/types/re-order';
import { useStockStatus } from '@monolith/legacy/modules/reorder/stock-status/composables/stock-status';
import { useBreakpoints } from '@monolith/legacy/modules/design-system-candidates';
import { StockAlert } from '@monolith/legacy/services/back-in-stock/back-in-stock';
import useI18n from '@core/composables/use-i18n';

const props = defineProps({
  product: {
    type: Object as PropType<OrderedProduct>,
    required: true,
  },
  restockAlerts: {
    type: Array as PropType<StockAlert[]>,
    required: true,
  },
});
const emit = defineEmits(['set-restock-alert']);

const { productVariantStockAlert, isStockAlertLoading, createStockAlert } = useStockStatus();
const { isMobile } = useBreakpoints();
const i18n = useI18n();

const productVariantId = computed((): number | null => {
  return (props.product.variants && props.product.variants[0]?.id) ?? null;
});

const existingRestockAlert = computed((): StockAlert | null => {
  return props.restockAlerts.find((alert) => alert.product_variant_id === productVariantId.value) ?? null;
});

const alertText = computed((): string => {
  if (isMobile.value) {
    return i18n.t('reorderProductCard.restockAlertBtn.setAlert.mobile');
  }

  return null;
});

const subscribedAlertText = computed((): string => {
  if (isMobile.value) {
    return i18n.t('reorderProductCard.restockAlertBtn.alertSet.mobile');
  }

  return i18n.t('reorderProductCard.restockAlertBtn.alertSet');
});

async function onSetStockAlert() {
  await createStockAlert(props.product.id, props.product.brand.id, productVariantId.value);

  if (productVariantStockAlert.value) {
    emit('set-restock-alert');
  }
}

onMounted(() => {
  productVariantStockAlert.value = existingRestockAlert.value;
});
</script>
