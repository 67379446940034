import { defineComponent } from 'vue';
import {
  getMediaQueryLists,
  getNewStateUpdated,
  addMatchMediaQueryListener,
  removeMatchMediaQueryListener,
  getNewStateUpdatedWithCurrentDevice,
} from '@monolith/legacy/modules/design-system-candidates/breakpoints/utils';
import { BreakpointsState } from '@monolith/legacy/modules/design-system-candidates/breakpoints/types';

const withBreakpoints = defineComponent({
  name: 'WithBreakpoints',
  data: function () {
    const queries = getMediaQueryLists();
    const state = getNewStateUpdatedWithCurrentDevice({
      isMobile: queries[0].matches,
      isXlMobile: queries[1].matches,
      isTablet: queries[2].matches,
      isXlTablet: queries[3].matches,
      isDesktop: queries[4].matches,
      isXlDesktop: queries[5].matches,
      currentDevice: null,
    });
    return {
      queries,
      ...state,
    };
  },
  created() {
    this.queries.forEach((query) => {
      addMatchMediaQueryListener(query, this.onMediaQueryChange);
    });
  },
  unmounted() {
    this.queries.forEach((query) => {
      removeMatchMediaQueryListener(query, this.onMediaQueryChange);
    });
  },
  methods: {
    getCurrentState(): BreakpointsState {
      return {
        isMobile: this.isMobile,
        isXlMobile: this.isXlMobile,
        isTablet: this.isTablet,
        isXlTablet: this.isXlTablet,
        isDesktop: this.isDesktop,
        isXlDesktop: this.isXlDesktop,
        currentDevice: this.currentDevice,
      };
    },
    updateState(newState: BreakpointsState) {
      this.isMobile = newState.isMobile;
      this.isXlMobile = newState.isXlMobile;
      this.isTablet = newState.isTablet;
      this.isXlTablet = newState.isXlTablet;
      this.isDesktop = newState.isDesktop;
      this.isXlDesktop = newState.isXlDesktop;
      this.currentDevice = newState.currentDevice;
    },
    onMediaQueryChange(event) {
      this.updateState(getNewStateUpdated(event, this.queries, this.getCurrentState()));
    },
  },
});

export default withBreakpoints;
