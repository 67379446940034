import List, { RetailerProductLikeCount } from '@monolith/legacy/types/wishList';
import { State } from '.';

export const mutations = {
  UPDATE_POSITIONS(state: State, lists: List[]): void {
    const listsWithUpdatedPositions = Object.fromEntries(lists.map((list, index) => [list.id, { ...list, position: index + 1 }]));

    state.lists = listsWithUpdatedPositions;
  },

  UPDATE_PRODUCT_LIKE_COUNT(state: State, { count, should_display_tooltip }: RetailerProductLikeCount): void {
    state.productLikeCount = {
      count: count ?? 0,
      shouldDisplayTooltip: Boolean(should_display_tooltip),
    };
  },

  LIKE_PRODUCT(state: State): void {
    state.productLikeCount = {
      ...state.productLikeCount,
      count: state.productLikeCount.count + 1,
    };
  },

  UNLIKE_PRODUCT(state: State): void {
    const count = Math.max(0, state.productLikeCount.count - 1);

    state.productLikeCount = {
      ...state.productLikeCount,
      count,
    };
  },
  SET_STATUS_OF_WISHLIST(state: State, { status: status, listId: listId }): void {
    state.lists[listId].status = status;
  },
};

export default mutations;
