import { Business } from '@monolith/legacy/types/api/business';
import { MutationTree } from 'vuex';
import { Mutation } from './mutation-types';
import { State } from './state';
import { VatInfo } from '@monolith/legacy/types/vat-info';

export const mutations: MutationTree<State> = {
  [Mutation.UPDATE_BUSINESS]: (state, payload: { business: Business }) => {
    if (state.brand) {
      state.brand.business = payload.business;
    }

    if (state.retailer) {
      state.retailer.business = payload.business;
    }
  },
  [Mutation.UPDATE_VAT_INFO]: (state, payload: VatInfo) => {
    if (state.brand) {
      state.brand.business = {
        ...state.brand.business,
        vat_exemption: payload.vatExemption,
        above_intracomm_threshold: payload.aboveIntracommThreshold,
        vat_info_updated: payload.vatInfoUpdated,
      };
    }

    if (state.retailer) {
      state.retailer.business = {
        ...state.retailer.business,
        vat_exemption: payload.vatExemption,
        above_intracomm_threshold: payload.aboveIntracommThreshold,
        vat_info_updated: payload.vatInfoUpdated,
      };
    }
  },
  [Mutation.UPDATE_FAST_PAYMENT]: (state, fastPaymentValue: boolean) => {
    state.brand.enable_fast_payment = fastPaymentValue;
  },
};
