<template>
  <div class="ds-flex ds-flex-row ds-flex-wrap">
    <div
      v-for="attachment in sortedAttachments"
      :key="attachment.url"
      class="message-attachment"
      :class="isImage(attachment.type) ? 'message-attachment-image' : 'message-attachment-file'"
    >
      <AkTooltipMenu
        class="message-attachment-tooltip-menu"
        position="right"
        type="click"
        color="white"
      >
        <template #toggle>
          <AkButton
            color="white"
            outlined
            symbol="three-dots"
            size="sm"
          />
        </template>
        <AkTooltipMenuList>
          <AkTooltipMenuListItem
            data-testid="message-attachment-new-tab-btn"
            @on-click="openFileInNewTab(attachment.url)"
          >
            {{ $t('Open in new tab') }}
          </AkTooltipMenuListItem>
          <AkTooltipMenuListItem
            data-testid="message-attachment-download-btn"
            @on-click="downloadAttachment(attachment.id)"
          >
            {{ $t('Download') }}
          </AkTooltipMenuListItem>
          <AkTooltipMenuListItem
            data-testid="message-attachment-delete-btn"
            :class="attachment.is_removable ? 'ds-text-warning-700' : 'ds-text-disabled'"
            @on-click="deleteAttachment(attachment.id, attachment.is_removable)"
          >
            {{ $t('Delete file') }}
          </AkTooltipMenuListItem>
        </AkTooltipMenuList>
      </AkTooltipMenu>
      <img
        v-if="isImage(attachment.type)"
        :src="attachment.url"
        :alt="attachment.name"
        class="message-attachment-image--content"
      />
      <button
        v-else
        class="message-attachment-file--content"
        @onClick="openFileInNewTab(attachment.url)"
      >
        <div class="file-name">
          {{ attachment.name }}
        </div>
        <div class="ds-uppercase ds-font-bold ds-mt-1">
          {{ getFileExtension(attachment.name) }}
        </div>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { mapActions } from 'vuex';
import { defineComponent, PropType } from 'vue';

interface Attachment {
  id: number;
  name: string;
  is_removable: boolean;
  url: string;
  type: string;
}

export default defineComponent({
  name: 'MessageBoxAttachments',
  props: {
    attachments: {
      type: Array as PropType<Attachment[]>,
      required: true,
    },
    conversationId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    sortedAttachments() {
      const imageAttachments = this.getAttachmentsByType(this.attachments, 'image');
      const fileAttachments = this.getAttachmentsByType(this.attachments, 'file');
      return imageAttachments.concat(fileAttachments);
    },
  },
  methods: {
    ...mapActions('conversations', ['deleteMessageAttachment', 'loadConversationMessages']),
    openFileInNewTab(url: string): void {
      window.open(url, '_blank');
    },
    downloadAttachment(id: number): void {
      window.open(`/api/conversations/messages/attachments/${id}/download`);
    },
    async deleteAttachment(id: number, isRemovable: boolean): Promise<void> {
      if (isRemovable) {
        await this.deleteMessageAttachment({ id });
        this.loadConversationMessages(this.conversationId);
      }
    },
    getFileExtension(value: string): string {
      const index = value.lastIndexOf('.');

      return value.slice(index + 1);
    },
    isImage(type: string) {
      return type === 'image';
    },
    getAttachmentsByType(attachments: Attachment[], type: string): Attachment[] {
      return attachments.filter((attachment) => attachment.type === type);
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';
.message-attachment {
  @apply ds-mr-5 ds-mt-5 ds-h-9 ds-relative ds-rounded-sm;

  @include media-breakpoint-down(lg) {
    @apply ds-mr-4 ds-mt-4 ds-h-8;
  }
}

.message-attachment-image {
  @apply ds-w-9;

  @include media-breakpoint-down(lg) {
    @apply ds-w-8;
  }

  &--content {
    @apply ds-h-full ds-w-full ds-object-cover ds-rounded-sm;
  }
}

.message-attachment-file {
  --file-attachment-width: 12rem;

  @include media-breakpoint-down(lg) {
    --file-attachment-width: 9rem;
  }

  @apply ds-mb-4;
  width: var(--file-attachment-width);

  &--content {
    @apply ds-flex ds-flex-col ds-justify-center ds-items-center ds-w-full ds-h-full ds-px-4 ds-py-2 ds-text-base ds-whitespace-nowrap
    ds-border ds-border-neutral-500 ds-border-solid ds-bg-neutral-100 ds-rounded-sm ds-shadow-sm;
  }

  .file-name {
    @apply ds-w-full ds-text-left ds-text-ellipsis ds-overflow-hidden;
  }
}

.message-attachment-tooltip-menu {
  @apply ds-absolute ds-right-0 ds--top-6;
  z-index: 20;
}
</style>

<style lang="scss">
.message-attachment-tooltip-menu .popper {
  @apply ds-relative;
  z-index: 1;
}
</style>
