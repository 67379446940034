<template>
  <div class="upload-packaging">
    <div v-if="showRevampedVersion">
      <AkParagraph :class="showSectionsExpander ? '' : 'ds-text-lg ds-font-semibold'">
        {{ content.title }}
      </AkParagraph>
      <AkRadio
        v-if="showRadio('show')"
        class="ds-mt-4 ds-pb-4"
        value="show"
        :is-checked="showContent"
        name="upload-packaging-options"
        data-testid="showUploadPackagingOption"
        @change="switchContent"
      >
        <AkParagraph
          class="ds-mb-0"
          :class="showContent ? 'ds-text-primary' : 'ds-text-neutral-900'"
        >
          <span class="ds-font-bold"> {{ $t('Yes') }}, </span>
          <span>{{ content.yesOption }}</span>
        </AkParagraph>
      </AkRadio>
      <AkRadio
        v-if="showRadio('hide')"
        value="hide"
        :is-checked="hideContent"
        name="upload-packaging-options"
        @change="switchContent"
      >
        <AkParagraph
          class="ds-mb-0"
          :class="hideContent ? 'ds-text-primary' : 'ds-text-neutral-900'"
        >
          <span class="ds-font-bold">
            {{ $t('No') }}
          </span>
          <span>{{ content.noOption }}</span>
        </AkParagraph>
      </AkRadio>
    </div>
    <div v-show="showFileUploader">
      <AkParagraph
        v-if="showRevampedVersion"
        :class="['ds-mt-6 ds-font-semibold', { 'ds-text-lg': !showSectionsExpander }]"
      >
        {{ $t('Attach photos of the parcel') }}
      </AkParagraph>
      <div class="ds-flex ds-items-center ds-my-4">
        <AkParagraph class="ds-text-neutral-900 ds-mb-0">
          {{ content.uploaderTitle }}
        </AkParagraph>
        <AkTooltipInformation
          v-if="!showRevampedVersion"
          trigger="hover"
          type="success"
          :content="
            $t(
              `You've remarked something suspicious on the parcel before opening it? if you've taken a photo, that's where you can add it!`
            )
          "
        >
          <AkIcon
            symbol="info-circle"
            size="md"
            class="ds-ml-1"
          />
        </AkTooltipInformation>
      </div>
      <FileUploader
        @upload-file="uploadFile"
        @remove-file="removeFile"
        @set-loader="setLoader"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { AkRadio } from '@ankorstore/design-system';
import { ParcelIssueOption, CaseReason, FormFileType } from '@monolith/legacy/types/order-retailer-rejection';
import FileUploader from '@monolith/legacy/components/account/orders/order/order-retailer-reject/components/file-uploader.vue';
import { orderIssueFileUploadingMixin } from '@monolith/legacy/components/account/orders/order/order-retailer-reject/mixins/order-issue-file-uploading';

export default defineComponent({
  name: 'UploadPackaging',
  components: {
    AkRadio,
    FileUploader,
  },
  mixins: [orderIssueFileUploadingMixin],
  emits: ['set-checked'],
  data() {
    return {
      enableContent: true,
    };
  },
  computed: {
    ...mapGetters('orderRetailerRejection', ['getCaseReason', 'getCurrentStep', 'showSectionsExpander', 'showRevampedVersion']),
    showFileUploader(): boolean {
      return this.showRevampedVersion ? this.enableContent : true;
    },
    showContent(): boolean {
      return this.enableContent === true;
    },
    hideContent(): boolean {
      return this.enableContent === false;
    },
    content() {
      switch (this.getCaseReason) {
        case CaseReason.DamagedItems:
          return {
            title: this.$t('Before opening the parcel, did you see any external damages or marks?'),
            yesOption: this.$t('parcel had some external damages'),
            noOption: this.$t('external damage on the parcel'),
            uploaderTitle: this.showRevampedVersion
              ? this.$t('We need to see the external damages')
              : this.$t('Add a photo of your unopened parcel(s)'),
          };
        case CaseReason.MissingItems:
          return {
            title: this.$t('When you received the parcel, did you see any signs it has been previously opened?'),
            yesOption: this.$t('there were signs of opening'),
            noOption: this.$t('signs of opening'),
            uploaderTitle: this.showRevampedVersion
              ? this.$t('We need to see the signs of opening')
              : this.$t('Add a photo of your unopened parcel(s)'),
          };
        default: {
          return {};
        }
      }
    },
  },
  mounted() {
    if (this.showRevampedVersion) {
      this.enableContent = null;
    }
  },
  methods: {
    ...mapActions('orderRetailerRejection', ['setParcelIssueOption', 'uploadFormFile', 'removeFormFile']),
    showRadio(label: string): boolean {
      if (this.showSectionsExpander) {
        if (label === 'show') {
          return this.showContent;
        } else {
          return this.hideContent;
        }
      }

      return true;
    },
    switchContent(checked: boolean, radioValue = 'show') {
      const value = radioValue === 'show' && checked;
      this.enableContent = value;
      this.setParcelIssueOption({ issueType: ParcelIssueOption.ParcelExternalIssues, value });
      this.$emit('set-checked', true);
    },
    async uploadFile(file: File, callback): Promise<void> {
      const result = await this.uploadFormFile({ file, type: FormFileType.Packaging });
      callback(result);
    },
    removeFile(file: File): void {
      this.removeFormFile({ file });
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';

.upload-packaging {
  @apply ds-font-basic;

  &__headings {
    @apply ds-flex ds-items-center ds-mb-4;

    @include media-breakpoint-down(lg) {
      @apply ds-items-start;
    }
  }
}

:deep(.ak-radio:last-of-type) {
  @apply ds-pb-0;
}

:deep(.ak-radio__sidelabel) {
  @apply ds-mt-1;
}
</style>
