<template>
  <div class="ds-pb-4">
    <section class="ds-flex ds-w-full ds-justify-between">
      <AkHeading3 size="md">
        {{ $t('All Reviews') }}
      </AkHeading3>
      <div
        v-if="$isEnabled('ret-650')"
        class="ds-flex ds-justify-end ds-items-center ds-w-3/12"
      >
        <span class="ds-block ds-font-bold ds-text-xs ds-w-1/3 ds-mr-2">{{ $t('Sort by:') }}</span>
        <AkSelect
          :value="sortBy.value"
          :options="sortOptions"
        />
      </div>
    </section>
    <section class="ds-flex">
      <template v-if="isFetching">
        <LoaderIcon class="my-5" />
      </template>
      <template v-else>
        <div class="ds-flex ds-flex-wrap ds-flex-column">
          <ReviewPreviewer
            v-for="review in reviews"
            :key="review.id"
            class="review"
            :review="review"
            show-brand-answer
          >
            <template #footer>
              <p class="ds-mt-4 ds-font-basic ds-text-sm">
                {{ reviewFooterText(review) }}
              </p>
            </template>
          </ReviewPreviewer>
        </div>
      </template>
    </section>
    <section class="ds-flex ds-justify-center ds-w-full">
      <AkButton
        :disabled="disableShowMore"
        color="white"
        outlined
        @click="showAll"
      >
        {{ $t('Show more') }}
      </AkButton>
    </section>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { formatDate } from '@monolith/legacy/services/date-locale';
import { Review } from '@monolith/legacy/components/review/types';
import LoaderIcon from '@monolith/legacy/components/loader-icon.vue';
import ReviewPreviewer from '@monolith/legacy/components/review/review-previewer.vue';

type ReviewSortCriteria = 'ratings' | 'creationDate';

interface ReviewSortOption {
  value: ReviewSortCriteria;
  label: string;
}

export default defineComponent({
  name: 'ReviewsList',
  components: {
    ReviewPreviewer,
    LoaderIcon,
  },
  props: {
    reviews: {
      type: Array as PropType<Review[]>,
      default: () => [],
    },
    totalReviews: {
      type: Number,
      default: 0,
    },
    isFetching: {
      type: Boolean,
    },
  },
  emits: ['showAllReviews'],
  data() {
    const DEFAULT_SORT_OPTIONS: ReviewSortOption[] = [
      {
        value: 'ratings',
        label: this.$t('Relevance'),
      },
      {
        value: 'creationDate',
        label: this.$t('Date'),
      },
    ];

    return {
      sortBy: DEFAULT_SORT_OPTIONS[0],
      sortOptions: DEFAULT_SORT_OPTIONS,
      maxReviewsToDisplay: 5,
    };
  },
  computed: {
    allAreDisplayed(): boolean {
      return this.reviews.length === this.totalReviews;
    },
    disableShowMore(): boolean {
      return this.isFetching || this.allAreDisplayed;
    },
  },
  methods: {
    showAll() {
      this.$emit('showAllReviews');
    },
    reviewFooterText(review: Review): string {
      const retailerFirstName = review.retailer.first_name;
      const retailerCountry = review.retailer.country;
      const submittedOnDate = formatDate(review.created_at, 'PPP');

      return `${retailerFirstName} - ${retailerCountry}, ${submittedOnDate}`;
    },
  },
});
</script>

<style scoped>
.review {
  @apply ds-w-full ds-mb-2 ds-border-b ds-border-solid ds-border-neutral-300 ds-pb-6 ds-pt-2;
}
</style>
