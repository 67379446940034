<template>
  <div
    :class="{
      'review-previewer': true,
      'review-previewer--removed': isRemoved,
    }"
  >
    <AkRating
      class="review-previewer__rating"
      :model-value="overallRating"
      read-only
    />
    <p
      class="ds-mt-2.5 sm:ds-mt-1.5 ds-text-base sm:ds-text-lg ds-font-bold"
      :class="{ 'ds-text-neutral-700': isRemoved }"
    >
      {{ title }}
    </p>
    <p :class="{ 'ds-text-neutral-700': isRemoved }">
      {{ review.description }}
    </p>
    <div class="ds-flex ds-gap-2 ds-flex-wrap">
      <div
        v-for="category in categories"
        :key="category.title"
        class="ds-bg-neutral-100 ds-rounded-md ds-p-2 ds-flex ds-gap-1 ds-items-baseline"
        :class="{ 'ds-text-neutral-700': isRemoved }"
      >
        {{ category.title }}
        <span class="ds-font-bold">{{ category.value }}</span>
        <span class="review-previewer__category-star"><AkIcon symbol="star" /></span>
      </div>
    </div>
    <slot name="footer"></slot>
    <template v-if="showBrandAnswer && hasBrandAnswer">
      <div
        class="ds-mt-4 ds-font-basic ds-text-xs ds-cursor-pointer"
        @click="toggleBrandAnswerBlock"
      >
        <div class="ds-inline-flex ds-items-center">
          <AkButton
            link
            size="md"
          >
            {{ replyText }}
          </AkButton>
          <AkIcon
            symbol="chevron-down"
            class="ds-ml-0.5"
          />
        </div>
      </div>
      <div class="ds-mt-4 ds-font-basic ds-text-xs">
        <AkExpander
          :open="isBrandAnswerBlockOpened"
          @toggle="toggleBrandAnswerBlock"
        >
          <div class="brand-answer">
            <section>
              <AkParagraph
                size="xs"
                class="ds-inline-flex ds-items-center"
              >
                <img
                  :src="brandImgUrl"
                  alt="brand logo"
                  class="ds-w-5 ds-h-5 ds-block ds-rounded-full ds-object-cover mr-1"
                />
                <span class="mr-1">{{ $t('Reply from') }}</span>
                <span class="ds-font-bold">{{ review.brand_answer.brand_name }} </span>
              </AkParagraph>
            </section>
            <section>
              <AkParagraph
                size="sm"
                class="ds-inline-flex ds-items-center"
              >
                {{ review.brand_answer.answer }}
              </AkParagraph>
            </section>
            <section>
              <AkParagraph
                size="xs"
                class="ds-inline-flex ds-items-center ds-font-bold ds-mb-0"
              >
                {{ brandAnswerDate }}
              </AkParagraph>
            </section>
          </div>
        </AkExpander>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { AkIcon } from '@ankorstore/design-system';
import AkRating from '@monolith/legacy/components/review/AkRating/AkRating.vue';
import { formatDate } from '@monolith/legacy/services/date-locale';
import { mapGetters } from 'vuex';
import { Review, ReviewCriteria } from '@monolith/legacy/components/review/types';

export default defineComponent<{ review: Review } & { overallRating: number }>({
  name: 'ReviewPreviewer',
  components: {
    AkIcon,
    AkRating,
  },
  props: {
    review: {
      type: Object as PropType<Review>,
      required: true,
    },
    showBrandAnswer: {
      type: Boolean,
    },
    isRemoved: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      isBrandAnswerBlockOpened: false,
      imgPlaceholder: '/images/reviews/brand-img-placeholder.svg',
    };
  },
  computed: {
    ...mapGetters(['brand']),
    brandId(): number {
      return this.brand?.id;
    },
    overallRating(): number {
      return this.review.ratings[ReviewCriteria.OVERALL];
    },
    title(): string {
      return this.review.title;
    },
    hasBrandAnswer(): boolean {
      return !!this.review.brand_answer;
    },
    brandImgUrl(): string {
      return this.review.brand_answer?.brand_image ?? (this.imgPlaceholder as string);
    },
    brandAnswerDate(): string {
      return formatDate(this.review.brand_answer?.created_at, 'PPP');
    },
    categories(): { title: string; value: string }[] {
      return [
        [this.$t('Accuracy'), ReviewCriteria.ACCURACY],
        [this.$t('Packaging'), ReviewCriteria.PRODUCT_AND_PACKAGING],
        [this.$t('Communication'), ReviewCriteria.COMMUNICATION],
        [this.$t('Quality'), ReviewCriteria.QUALITY],
      ].map(([title, criteria]) => ({
        title,
        value: this.review.ratings[criteria].toFixed(1),
      }));
    },
    brandLoggedInIsSeeingTheirReviews(): boolean {
      return this.brandId === this.review?.brand?.id;
    },
    replyText(): string {
      return this.brandLoggedInIsSeeingTheirReviews ? this.$t('View your answer') : this.$t('View reply');
    },
  },
  methods: {
    toggleBrandAnswerBlock() {
      this.isBrandAnswerBlockOpened = !this.isBrandAnswerBlockOpened;
    },
  },
});
</script>

<style lang="scss" scoped>
.brand-answer {
  @apply ds-flex ds-items-start ds-flex-col ds-justify-start ds-rounded-md ds-bg-warm-white ds-p-4 ds-w-11/12 ds-mx-auto;
}

.review-previewer {
  $self: &;

  &--removed {
    --ak-rating-star-color: theme('colors.neutral.700');

    #{$self}__category-star {
      color: theme('colors.neutral.700');
    }
  }

  &__rating {
    :deep(.icon--md) {
      font-size: 1.5rem;
    }
  }

  &__category-star {
    @apply ds-text-ratings;
  }
}
</style>
