import type { RouteRecordRaw } from 'vue-router';
import { campaignCreateGuard } from './route-guards';

export const routes: RouteRecordRaw[] = [
  {
    path: 'campaigns/add',
    name: 'campaign-add',
    component: () => import('./create-campaigns.vue'),
    props: true,
    beforeEnter: campaignCreateGuard,
  },
];
