import { FulfillmentIneligibilityReason, JSONApiErrorItem } from '@monolith/legacy/types/fulfillment';

/**
 * Represents an instance where we have attempted to validate an order for fulfillment, but we have detected already
 * on the client that some of the items that are contained within the order do not contain a fulfillable ID. In this
 * case, we can assert (without calling the server) that the order has items that are undeclared.
 */
export class UndeclaredFulfillmentItemsError extends Error {
  public name: string;
  public errors: { detail: string }[];

  constructor() {
    super();
    this.name = 'UndeclaredFulfillmentItemsError';

    this.errors = [
      {
        detail: 'client_undeclared_items',
      },
    ];
  }
}

type FulfillmentEligibilityErrorMeta = {
  unavailableItems?: string[];
  undeclaredItems?: string[];
};
export const extractFulfillmentEligibilityErrors = (errors: JSONApiErrorItem[]): FulfillmentIneligibilityReason[] => {
  const reasons: FulfillmentIneligibilityReason[] = [];

  errors.forEach(({ detail, meta }) => {
    if (detail === 'not_available_for_international_orders') {
      reasons.push(FulfillmentIneligibilityReason.UnsupportedDestination);
    } else if (detail === 'unavailable_items') {
      const unavailableItems = (meta as FulfillmentEligibilityErrorMeta)?.unavailableItems ?? [];
      const undeclaredItems = (meta as FulfillmentEligibilityErrorMeta)?.undeclaredItems ?? [];
      if (unavailableItems.length > 0) {
        reasons.push(FulfillmentIneligibilityReason.UnavailableItems);
      }
      if (undeclaredItems.length > 0) {
        reasons.push(FulfillmentIneligibilityReason.UndeclaredItems);
      }
    } else if (detail === 'client_undeclared_items') {
      reasons.push(FulfillmentIneligibilityReason.UndeclaredItems);
    }
  });

  return reasons;
};

/** Tag each function with a descriptive error object which will help us identify the point of failure in Sentry. */
export const throws = <T extends (...args: any[]) => any>(
  type: string,
  prefix: string,
  datacall: T
): ((...args: Parameters<typeof datacall>) => Promise<ReturnType<typeof datacall>>) => {
  return async (...args) => {
    try {
      return await datacall(...args);
    } catch (err) {
      const e = new Error(`[${prefix}]: There was a problem while completing the "${type}" operation`);
      e.name = type;
      throw e;
    }
  };
};
