import http from '@monolith/legacy/services/api/http';
import {
  AddressApiResourceResponse,
  BrandBestPriceEnrollmentPayload,
  BrandShippingSettingsApiPayload,
  BrandShippingSettingsApiResponse,
  CommunicationsConsentState,
  BrandBestPriceEnrollmentStatus,
  BrandCompanyDetailsApiPayload,
  BrandCompanyDetailsApiResponse,
  BrandShippingFeeSettingsApiResponse,
  BrandShippingFeeSetting,
  UpdateBrandShippingFeeSettingApiPayload,
  BrandHolidaySettingsApiPayload,
  BrandHolidaySettingsApiResponse,
} from '@monolith/legacy/modules/brand-account/types/settings';
import { deserialize } from '@monolith/legacy/services/utils/jsonapi-parser';
import { Brands } from '@ankorstore/api-internal/Brands';
import { GetApiInternalV1BrandsBrandIdParams } from '@ankorstore/api-internal/data-contracts';
import { formatToDateOnly, parseDateIntoLocalDateTime } from '@core/utilities/date';

const jsonApiConfigs = {
  headers: {
    'Content-Type': 'application/vnd.api+json',
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const wrapPayloadForJsonApi = (attributes: any) => ({
  data: {
    attributes,
  },
});

export const getBrandShippingSettings = async (brandUuid: string): Promise<BrandShippingSettingsApiResponse> => {
  const apiRoute = `/api/internal/v1/brands/shipping-settings/${brandUuid}`;
  const response = await http().get(apiRoute, jsonApiConfigs);
  return response.data?.data?.attributes;
};

export const getBrandShippingFeeSettings = async (): Promise<BrandShippingFeeSetting[]> => {
  const apiRoute = `/api/internal/v1/brands/franco-settings`;
  const response = await http().get<BrandShippingFeeSettingsApiResponse>(apiRoute, jsonApiConfigs);

  return deserialize(response?.data)?.data;
};

export const updateBrandShippingFeeSettings = async (
  payload: UpdateBrandShippingFeeSettingApiPayload
): Promise<BrandShippingFeeSetting> => {
  const apiRoute = `/api/internal/v1/brands/franco-settings`;
  const response = await http().post(apiRoute, wrapPayloadForJsonApi(payload), jsonApiConfigs);
  return deserialize(response?.data)?.data;
};

export const getBrandAddresses = async (): Promise<AddressApiResourceResponse> => {
  const response = await http().get('/api/me/brand/addresses');
  return response.data;
};

export const updateBrandShippingSettings = async (
  brandUuid: string,
  payload: BrandShippingSettingsApiPayload
): Promise<BrandShippingSettingsApiResponse> => {
  const apiRoute = `/api/internal/v1/brands/shipping-settings/${brandUuid}`;
  const response = await http().patch(apiRoute, payload, jsonApiConfigs);
  return response.data?.data?.attributes;
};

export const getBrandHolidayDates = async (brandUuid: string): Promise<BrandHolidaySettingsApiResponse> => {
  const brandsHttpClient = new Brands();
  const requestModel: GetApiInternalV1BrandsBrandIdParams = {
    brandId: brandUuid,
  };
  const brandModelResponse = await brandsHttpClient.getApiInternalV1BrandsBrandId(requestModel);
  
  const holidayData: BrandHolidaySettingsApiResponse = {
    startDate: parseDateIntoLocalDateTime(brandModelResponse.data.attributes.holidayStartDate),
    endDate: parseDateIntoLocalDateTime(brandModelResponse.data.attributes.holidayEndDate),
  };

  return holidayData;
};

export const updateBrandHolidayDates = async (
  brandUuid: string,
  payload: BrandHolidaySettingsApiPayload
): Promise<void> => {
  const brandsHttpClient = new Brands();

  const requestData = {
    holidayStartDate: formatToDateOnly(payload.startDate),
    holidayEndDate: formatToDateOnly(payload.endDate),
  };
  
  await brandsHttpClient.patchApiInternalV1BrandsBrandId(brandUuid, requestData);
};

export const getConsents = async (): Promise<CommunicationsConsentState> =>
  http()
    .get(`/api/network/consents`)
    .then((response) => response.data.data);

export const updateConsents = async (value: boolean): Promise<Pick<CommunicationsConsentState, 'contact_opt_in'>> =>
  http()
    .patch(`/api/network/consents`, {
      contact_opt_in: value,
    })
    .then((response) => response.data.data);

export const isConsentsNeeded = async (): Promise<boolean> =>
  http()
    .get(`/api/network/consents/ask`)
    .then((response) => (response.data.data ? response.data.data.should_ask_contact_opt_in : true));

/**
 * Exports getBestPriceEnrollmentStatus,
 * fetches data for best price enrollment status
 * @returns BrandGetBestPriceEnrollmentStatusApiResponse data
 */
export const getBestPriceEnrollmentStatus = async (): Promise<BrandBestPriceEnrollmentStatus> =>
  http()
    .get(`/api/me/brand/pricing/bpg/enrollment`)
    .then((response) => response.data.data);

/**
 * Exports postBestPriceEnrollmentStatus
 * posts input from brand for signing up for BPG
 */
export const postBestPriceEnrollmentStatus = async (
  payload: BrandBestPriceEnrollmentPayload
): Promise<BrandBestPriceEnrollmentStatus> =>
  http()
    .post(`/api/me/brand/pricing/bpg/enrollment`, payload)
    .then((response) => response.data.data);

export const getBrandCompanyDetails = async (brandUuid: string): Promise<BrandCompanyDetailsApiResponse> => {
  const apiRoute = `/api/internal/v1/brands/company/${brandUuid}`;
  const response = await http().get(apiRoute, jsonApiConfigs);
  return response.data?.data?.attributes;
};

export const updateBrandCompanyDetails = async (
  brandUuid: string,
  payload: BrandCompanyDetailsApiPayload
): Promise<BrandCompanyDetailsApiResponse> => {
  const apiRoute = `/api/internal/v1/brands/company/${brandUuid}`;
  const response = await http().patch(apiRoute, payload, jsonApiConfigs);
  return response.data?.data?.attributes;
};
