export enum BrandClickOrigin {
  EmailsTemplateStep = 'emails-template-step',
  ImportClientsConfirmation = 'import-clients-confirmation',
  ImportClientsStep = 'import-clients-step',
  Network = 'network-page',
  BulkActions = 'bulk-actions',
  Orders = 'orders',
  PreparedOrder = 'prepared-orders',
  NetworkBanner = 'network-banner',
  NetworkTable = 'network-table',
  DiscountCreation = 'discount-creation',
  DiscountRemoval = 'discount-removal',
  ContactDeletion = 'contact-deletion',
  HolidayMode = 'holiday-mode-page',
  CampaignPage = 'campaign-page',
}

export enum BrandClickAction {
  BrandCantInvite = 'brand_cant_invite',
  BrandClosePendingInvitationReminder = 'brand_close_pending_invitation_reminder',
  BrandDoItLater = 'brand_do_it_later',
  BrandGoToInviteViaPendingInvitationReminder = 'brand_go_to_invite_via_pending_invitation_reminder',
  BrandSaveAndSendInvitation = 'brand_save_and_send_invitation',
  BrandSaveList = 'brand_save_list',
  BrandStartInvitation = 'brand_start_invitation',
  BrandStartInviteMyContacts = 'brand_start_invite_my_contacts',
  BrandExportContacts = 'export',
  BrandSendCampaign = 'send-campaign',
  BrandAddDiscount = 'add-discount',
  BrandAddDiscountBulk = 'bulk-add-discount',
  BrandDeleteContactsBulk = 'bulk-delete-contacts',
  BrandDeleteContactsBulkSave = 'save-bulk-delete-contact',
  BrandRemoveDiscount = 'remove-discount',
  BrandDeleteContact = 'delete-contact',
  BrandGoToCreateOrder = 'brand-go-to-create-order',
  BrandNewOrderQuantityNotMultipleOfUnitMultiplier = 'brand-new-order-quantity-not-multiple-of-unit-multiplier',
  BrandCopyPreparedOrderLink = 'brand-copy-prepare-order-link',
  BrandOpenContactsFilter = 'open-filter',
  BrandConfirmRemoveDiscount = 'save_remove_discount',
  BrandConfirmRemoveDiscountBulk = 'save-bulk-remove-discount',
  BrandConfirmAddDiscount = 'save_discount',
  BrandConfirmDeleteContact = 'save_delete_contact',
  BrandApplyFilters = 'apply_filters',
  BrandSetHolidayMode = 'set-holiday-mode',
  BrandUnsetHolidayMode = 'unset-holiday-mode',
  BrandCopyLiftCode = 'copy-liftcode',
  BrandRegisterWebinar = 'register-webinar',
}
