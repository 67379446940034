<template>
  <div
    v-if="isLiked"
    class="alert-created"
  >
    <AkIcon
      symbol="check"
      size="sm"
    />
    {{ $t('Alert created') }}
  </div>
  <AkButton
    v-else
    outlined
    :size="size"
    @click.stop.prevent="clickButton"
  >
    {{ $t('Create an alert') }}
  </AkButton>
</template>

<script lang="ts">
import { mapGetters, mapActions } from 'vuex';
import { loginPopinMixin } from '@core/mixins/login-popin';
import { RetailerFormType } from '@monolith/legacy/types/retailer-form-type';
import http from '@monolith/legacy/services/api/http';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ComingSoonAlertButton',
  mixins: [loginPopinMixin],
  props: {
    id: {
      type: Number,
      required: true,
    },
    small: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String as () => 'md' | 'lg' | 'xl',
      default: 'md',
      validator: function (value: string) {
        return ['md', 'lg', 'xl'].includes(value);
      },
    },
  },
  computed: {
    ...mapGetters(['user', 'brandIsLiked']),
    isLiked(): boolean {
      return this.brandIsLiked(this.id);
    },
  },
  methods: {
    ...mapActions(['likeBrand', 'unLikeBrand']),
    unLike() {
      this.unLikeBrand({ id: this.id });
    },
    like() {
      this.likeBrand({ id: this.id });
    },
    clickButton() {
      if (!this.user) {
        this.openLoginPopin(RetailerFormType.Login, null, () => {
          // here we use directly axios call and not the store method because we don't have the user in the store yet.
          http()
            .put(`/api/likes/brand/${this.id}`)
            .then(() => {
              window.location.reload();
            });
        });
        return;
      }

      if (this.isLiked) {
        this.unLike();
      } else {
        this.like();
      }
    },
  },
});
</script>
