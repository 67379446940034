<template>
  <div
    v-if="shouldShowCarousel"
    class="brand-logo-carousel"
    data-testid="brandLogoCarousel"
  >
    <div class="wrapper">
      <div class="brand-logo-carousel__container">
        <AkCarousel
          :per-page="7"
          :per-move="7"
          :gap-size="isDesktop ? 48 : 24"
          type="loop"
          :pagination="false"
          :breakpoints="responsiveSettings"
          controller-size="xxl"
          controller-type="transparent"
        >
          <AkCarouselItem
            v-for="(image, index) in content.images"
            :key="index"
          >
            <a
              v-if="image.brand_link"
              :href="image.brand_link"
              class="brand-logo-carousel__image"
              @click="triggerClick"
            >
              <img
                :src="image.brand_logo.url"
                :width="image.brand_logo.dimensions.width"
                :height="image.brand_logo.dimensions.height"
                :alt="image.brand_logo.alt"
              >
            </a>
          </AkCarouselItem>
        </AkCarousel>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import { AkCarousel, AkCarouselItem } from '@ankorstore/design-system';

import { useBreakpoints } from '@monolith/legacy/modules/design-system-candidates';

import Analytics from '@monolith/legacy/services/analytics';
import UserClick from '@monolith/legacy/services/analytics/events/user-click.event';
import { PrismicImage } from '@core/types/prismic';

export interface BrandLogoContent {
  images: ImageBrandLogoContent[];
}

interface ImageBrandLogoContent {
  brand_link: string;
  brand_logo: PrismicImage;
}

export default defineComponent({
  name: 'BrandLogoCarousel',
  components: {
    AkCarousel,
    AkCarouselItem,
  },
  props: {
    content: {
      type: Object as () => BrandLogoContent,
      required: true,
    },
  },
  setup(props) {
    const { isDesktop } = useBreakpoints();

    /** Set Computed to show the carousel if images array length is higher than 0 */
    const shouldShowCarousel = computed(() => {
      return props.content.images && props.content.images.length > 0;
    });

    /** Set responsive settings for the carousel */
    const responsiveSettings = ref({
      768: {
        perPage: 4,
        perMove: 4,
      },
      480: {
        perPage: 2,
        perMove: 2,
      },
    });

    /** Set UserClick event on click for brand logo */
    const triggerClick = async () => {
      await Analytics.track(
        new UserClick({
          component: 'brand_logo_carousel',
          action: 'click_logo',
        })
      );
    };

    return {
      shouldShowCarousel,
      isDesktop,
      responsiveSettings,
      triggerClick,
    };
  },
});
</script>
<style lang="scss">
.brand-logo-carousel {
  @apply ds-bg-neutral-300 ds-py-6 ds-mt-6;
  .splide__slide {
    @apply ds-rounded-full ds-overflow-hidden;
  }
  &__container {
    @apply ds-px-7;
    @media screen and (min-width: 768px) {
      padding: 0 120px;
    }
  }
  .ak-carousel-control {
    &__icon {
      @apply ds-text-neutral-500;
    }
  }
  .splide__arrow--prev,
  .splide__arrow--next {
    &:focus,
    &:hover {
      @apply ds-outline-none;
    }
  }
  .splide__arrows > .splide__arrow--prev {
    @apply ds--left-7 #{!important};
    @media screen and (min-width: 768px) {
      left: -120px !important;
    }
  }
  .splide__arrows > .splide__arrow--next {
    @apply ds--right-7 #{!important};
    @media screen and (min-width: 768px) {
      right: -120px !important;
    }
  }
}
</style>
