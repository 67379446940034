<template>
  <div @click.stop.prevent>
    <AkButton
      class="multivariant-selector"
      outlined
      size="md"
      @click="openMultivariantModal"
    >
      {{ $t(`reorderProductCard.variantSelector.selectBtn.lbl`) }}
    </AkButton>
    <Teleport to="body">
      <AkModal
        ref="multivariantModal"
        size="xl"
        :with-padding="false"
        data-testid="multivariantModal"
        @cancelled="closeModal"
      >
        <AddToCartPopin
          :product-id="productId"
          :price-breakdown="priceBreakdown"
          class="multivariant-selector-modal"
          @add-to-cart-variant="handleAddToCartVariant"
          @update-item-cart="handleUpdateItemCart"
        />
        <div class="reorder-notification__wrapper">
          <ReorderNotification
            ref="reorderNotification"
            class="reorder-notification"
          >
            {{ reorderNotificationMessage }}
          </ReorderNotification>
        </div>
      </AkModal>
    </Teleport>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import AddToCartPopin from '@monolith/legacy/components/popins/add-to-cart-popin.vue';
import { PriceBreakdown } from '@monolith/legacy/types/product';
import { AkModal } from '@ankorstore/design-system';
import ReorderNotification from '@monolith/legacy/modules/reorder/product-card/reorder-notification.vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import Analytics from '@monolith/legacy/services/analytics';
import {
  ReorderProductCardActions,
  ReorderProductCardTrackingData,
  ProductQuickAddToCartEvent,
} from '@monolith/legacy/modules/reorder/product-card/tracking';
import useI18n from '@core/composables/use-i18n';
import { OrderedBrand } from '@monolith/legacy/services/reorder/reorder';
const { useGetters: useCartGetters } = createNamespacedHelpers('cart');
import { ProductProperty } from '@monolith/legacy/services/analytics/properties/product-property';
import UserClick from '@monolith/legacy/services/analytics/events/user-click.event';

export default defineComponent({
  name: 'MultivariantSelector',
  components: {
    AddToCartPopin,
    AkModal,
    ReorderNotification,
  },
  props: {
    product: {
      type: Object as PropType<ProductProperty>,
      default: null,
    },
    productId: {
      type: Number,
      required: true,
    },
    priceBreakdown: {
      type: Object as () => PriceBreakdown,
      required: true,
    },
    trackingData: {
      type: Object as PropType<ReorderProductCardTrackingData>,
      required: false,
      default: null,
    },
    brand: {
      type: Object as PropType<OrderedBrand>,
      required: true,
    },
  },
  emits: ['add-to-cart', 'modalOpened', 'modalClosed'],
  setup(props, { emit }) {
    const multivariantModal = ref();
    const reorderNotification = ref();
    const reorderNotificationMessage = ref<string>(null);
    const productsCount = ref(0);
    const isLocalLoading = ref(false);

    const { isCartLoading } = useCartGetters(['isCartLoading']);
    const i18n = useI18n();

    const isLoading = computed(() => {
      return isCartLoading.value && isLocalLoading.value;
    });

    watch(
      () => isLoading.value,
      (newValue) => {
        if (!newValue) {
          setTimeout(() => {
            isLocalLoading.value = false;
            reorderNotification.value.openNotification();
          }, 200);
        }
      }
    );

    const openMultivariantModal = () => {
      emit('modalOpened');
      multivariantModal.value.openModal();
      Analytics.track(
        new UserClick({
          component: 'reco_carousel',
          action: ReorderProductCardActions.QUICK_ADD_TO_CART_CLICKED,
          id_section: props.trackingData?.sectionId,
          itemtype: 'product',
          productId: props.product.id,
          position: props.trackingData?.index,
          lb_value_proposition: props.trackingData?.lbValueProposition,
        })
      );
    };

    const closeModal = () => {
      emit('modalClosed');
    };
    const handleAddToCartVariant = async (payload) => {
      isLocalLoading.value = true;
      productsCount.value = payload.quantity * payload.option?.unit_multiplier;
      reorderNotificationMessage.value = i18n.t('reorderProductCard.notification.addedToCart', productsCount.value);

      emit('add-to-cart');

      await Analytics.track(
        new ProductQuickAddToCartEvent(props.product, props.trackingData.index, props.trackingData.sectionId, props.brand)
      );
    };

    const handleUpdateItemCart = (payload) => {
      isLocalLoading.value = true;
      productsCount.value = payload.quantity * payload.item?.option?.unit_multiplier;
      reorderNotificationMessage.value = i18n.t('reorderProductCard.notification.addedToCart', productsCount.value);
    };

    return {
      multivariantModal,
      openMultivariantModal,
      handleAddToCartVariant,
      productsCount,
      reorderNotification,
      reorderNotificationMessage,
      handleUpdateItemCart,
      closeModal
    };
  },
});
</script>

<style lang="scss" scoped>
.multivariant-selector {
  @apply ds-w-full ds-font-basic;
}

.reorder-notification {
  @apply ds-w-[260px];
  &__wrapper {
    @apply ds-w-full ds-flex ds-justify-center;
  }
}

.multivariant-selector-modal {
  @apply ds-font-basic;
}
</style>
