import { Business } from '@monolith/legacy/types/api/business';
import { VatInfo } from '@monolith/legacy/types/vat-info';
import { GetterTree } from 'vuex';
import { State } from '../../state';

export const getters: GetterTree<unknown, State> = {
  getBusiness: (_state, _getters, rootState): Business => rootState.retailer?.business ?? rootState.brand?.business,
  getEoriNumber: (_state, _getters): string => {
    const business = _getters.getBusiness;
    return business?.eori_number;
  },
  getVatInfo: (_state, _getters): VatInfo => {
    const business = _getters.getBusiness;

    return {
      vatExemption: business.vat_exemption,
      vatInfoUpdated: business.vat_info_updated,
      aboveIntracommThreshold: business.above_intracomm_threshold,
      soletraderNumber: business.sole_trader_number,
    };
  },
  getVatNumber: (_state, _getters): string => {
    const business = _getters.getBusiness;
    return business?.vat_number || null;
  },
  // TODO : Left shift to database this ugly exlcuded countries list.
  isVatComplianceAvailableInCountry: (_state, getters): boolean => !['GB', 'CH'].includes(getters.getBusiness.country.iso_code),
  shouldShowRecargoQuestion: (_state, _getters): string => {
    const business = _getters.getBusiness;
    return business?.should_show_recargo_question;
  },
  isPayingRecargo: (_state, _getters): string => {
    const business = _getters.getBusiness;
    return business?.is_paying_recargo;
  },
  isEligibleToRecargo: (_state, getters): boolean => getters.getBusiness.country.iso_code === 'ES',
};
