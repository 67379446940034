import { AkLocale } from '@core/types/base';
import { ProductHit } from '@monolith/legacy/types/product';
import { BrandHit } from '@bc/discovery/domain/catalog';
import { ESProductHit } from '../catalog';

export const hitAdapter = (lang: AkLocale, hit: ProductHit | BrandHit): ProductHit | BrandHit => {
  // event tile
  if ('type' in hit && hit.type === 'businessEventPeriod') {
    return fromEvent(hit as ProductHit);
  }

  if ('brand' in hit) {
    // product tile
    return fromProductHit(lang, hit as ProductHit);
  }

  // Brand tile
  return hit as BrandHit;
};

export const fromProductHit = <T extends ESProductHit | ProductHit>(lang: AkLocale, productHit: T): T => {
  return {
    ...productHit,
    name: productHit.name[lang],
    description: productHit.description[lang],
    tags: productHit.tags[lang],
  };
};

export const fromEvent = (productHit: ProductHit): ProductHit => {
  return {
    type: productHit.external_cms_content['catalogue-tile'][0].body[0].slice_type,
    id: `${productHit.external_cms_content['catalogue-tile'][0].body[0].slice_type}-${productHit.external_cms_content['catalogue-tile'][0].body[0].primary.position}`,
    primary: productHit.external_cms_content['catalogue-tile'][0].body[0].primary,
    position: parseInt(productHit.external_cms_content['catalogue-tile'][0].body[0].primary.position, 10),
    path: `/${productHit.redirect_url}`,
    //(eventName && { eventName }),
  };
};
