<!-- eslint-disable @intlify/vue-i18n/no-dynamic-keys -->
<template>
  <div :class="{ 'phone-container': !isRevamp }">
    <AkParagraph
      v-if="isRevamp"
      size="sm"
      class="ds-mb-2 ds-text-neutral-700"
    >
      {{ $t(question.label) }}
    </AkParagraph>
    <div
      :class="{
        'phone-container-revamp ak-input ak-input--medium ak-input--icon-none': isRevamp,
        'ak-input--error': isRevamp && state[question.alias] && state[question.alias].$error,
      }"
    >
      <PhoneInput
        v-if="selectedCountryCode"
        :default-country="selectedCountryCode"
        :preferred-countries="topCountriesOrder"
        :name="question.alias"
        data-testid="register-phone-number-input"
        :with-hidden-inputs="false"
        @validation="checkPhoneValid"
        @change="phoneChanged"
      />
    </div>
    <div
      v-if="state[question.alias] && state[question.alias].$dirty && state[question.alias].required.$invalid"
      :class="[isRevamp ? 'ak-field-error' : 'error-message']"
    >
      {{ $t('This field is required') }}
    </div>
    <div
      v-else-if="state[question.alias] && state[question.alias].$dirty && state[question.alias].phoneFormat.$invalid"
      :class="[isRevamp ? 'ak-field-error' : 'error-message']"
    >
      {{ $t('Phone number is invalid') }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Question } from '@monolith/legacy/types/question';
import PhoneInput from '@monolith/legacy/components/phone-input.vue';
import { PHONE_COUNTRY_CODE_ALIAS } from '../constants';
import { State } from '@monolith/legacy/types/state';
import { AkParagraph } from '@ankorstore/design-system';

export default defineComponent({
  name: 'QuestionPhone',
  components: { PhoneInput, AkParagraph },
  props: {
    question: {
      type: Object as () => Question,
      required: true,
    },
    state: {
      type: Object as () => State,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    isRevamp: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['change'],
  data() {
    return {
      phoneValue: '',
      countryCodeValue: null,
    };
  },
  computed: {
    topCountriesOrder() {
      return this.$store.state.global.countries.topCountriesOrder;
    },
    selectedCountryCode() {
      return this.$store.getters['signUp/selectedCountryCode'];
    },
  },
  watch: {
    phoneValue(value, oldValue) {
      if (value !== oldValue) {
        this.$emit('change', this.question.alias, {
          answer_id: this.question.id,
          content: value,
        });
      }
    },
    countryCodeValue(countryCode) {
      this.$emit('change', PHONE_COUNTRY_CODE_ALIAS, {
        answer_id: this.question.id,
        content: countryCode,
      });
    },
  },
  methods: {
    checkPhoneValid(valid) {
      this.$store.dispatch('signUp/setPhoneValidity', valid);
    },
    phoneChanged({ value, countryCode }) {
      this.phoneValue = value;
      this.countryCodeValue = countryCode;
    },
  },
});
</script>
<style lang="scss">
.phone-container {
  @apply ds-relative ds-z-10;
}

.phone-container-revamp {
  @apply ds-relative ds-flex ds-justify-items-stretch ds-items-stretch;

  .phone-input {
    @apply ds-w-full;
  }

  .vue-tel-input {
    @apply ds-h-full ds-border-none ds-outline-none;

    &:focus-within {
      @apply ds-shadow-none;
    }

    .vti__dropdown {
      position: inherit;
    }

    .vti__dropdown-list {
      @apply ds-max-w-full ds-rounded-md ds-mt-1;
      width: 500px;
      min-height: 210px;
    }

    .vti__dropdown-item {
      @apply focus:ds-outline-none;
      height: 40px;
    }
  }
}
</style>
