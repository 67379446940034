import { ActionTree, Dispatch, GetterTree, MutationTree } from 'vuex';
import { SoleTrader } from '@monolith/legacy/types/sole-trader';
import { sendSoleTraderDetails } from '@monolith/legacy/services/api/sole-trader';

export interface SoleTraderState {
  soleTrader: SoleTrader;
  soleTraderFormErrors: object;
}

export const createInitialState = (): SoleTraderState => ({
  soleTrader: {
    owner_birthdate: '',
    owner_address: {},
    business: {},
  },
  soleTraderFormErrors: {},
});

const requiredAddressKeys = ['first_name', 'last_name', 'street', 'postal_code', 'city', 'country_id'];

export const getters: GetterTree<SoleTraderState, any> = {
  soleTraderDetailsErrors: (state: SoleTraderState) => state.soleTraderFormErrors,
  soleTrader: (state: SoleTraderState) => state.soleTrader,
  isSoleTraderInfoValid: (state: SoleTraderState) => {
    const hasRequiredAddressKeys =
      requiredAddressKeys.filter(
        (key) =>
          Object.prototype.hasOwnProperty.call(state.soleTrader.owner_address, key) && state.soleTrader.owner_address[key] != null
      ).length === requiredAddressKeys.length;

    return !!state.soleTrader.owner_birthdate && hasRequiredAddressKeys;
  },
};

export const mutations: MutationTree<SoleTraderState> = {
  SET_SOLE_TRADER_DETAIL(state: SoleTraderState, value) {
    if ('owner_birthdate' in value) {
      return (state.soleTrader.owner_birthdate = value.owner_birthdate);
    }
    if ('business_name' in value) {
      return (state.soleTrader.business = {
        ...state.soleTrader.business,
        name: value.business_name,
      });
    }

    return (state.soleTrader.owner_address = {
      ...state.soleTrader.owner_address,
      ...value,
    });
  },
  CLEAR_SOLE_TRADER_DETAILS_ERRORS(state: SoleTraderState) {
    state.soleTraderFormErrors = {};
  },
  ADD_SOLE_TRADER_DETAILS_ERRORS(state: SoleTraderState, payload) {
    state.soleTraderFormErrors = {
      ...state.soleTraderFormErrors,
      ...payload,
    };
  },
};
export const actions: ActionTree<SoleTraderState, any> = {
  async sendSoleTraderDetails({ dispatch, state }: { dispatch: Dispatch; state: SoleTraderState }) {
    return sendSoleTraderDetails(state.soleTrader).catch(({ response }) => {
      dispatch('addSoleTraderDetailsErrors', response.data.errors);
      throw new Error(response.data.errors);
    });
  },
  setSoleTraderDetail({ commit }, payload) {
    commit('SET_SOLE_TRADER_DETAIL', payload);
  },
  addSoleTraderDetailsErrors({ commit }, payload) {
    commit('ADD_SOLE_TRADER_DETAILS_ERRORS', payload);
  },
  clearSoleTraderDetailsErrors({ commit }) {
    commit('CLEAR_SOLE_TRADER_DETAILS_ERRORS');
  },
  async validateSoleTrader({ dispatch }: { dispatch: Dispatch }) {
    await dispatch('clearSoleTraderDetailsErrors');

    return dispatch('sendSoleTraderDetails');
  },
};

export default {
  namespaced: true,
  state: createInitialState(),
  actions,
  mutations,
  getters,
};
