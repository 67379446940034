import { ImageResource, Review, ReviewStatistics, ReviewStatus } from '@monolith/legacy/components/review/types';
import {
  getOrderReview,
  createDraftOrderReview,
  getAllBrandReviews,
  getBrandOrderAverageStatisticsReview,
} from '@monolith/legacy/services/reviews/reviews';
import { captureRetailerCoreDiscoveryException } from '@core/plugins/sentry/helper';

export interface ReviewsState {
  loading: boolean;
  review: Review | null;
  responseDrawerType: ResponseDrawerType;
  savedMessages: {
    [key: number]: string;
  };
  savedReports: {
    [key: number]: string;
  };
  brandReviewsData: BrandsReviewsData;
  isLoadingBrandReviewsData: boolean;
}

export enum ResponseDrawerType {
  public = 'public',
  report = 'report',
  flag = 'flag',
}

export interface BrandsReviewsData {
  data: {
    reviews: Review[] | void;
    statistics: ReviewStatistics | void;
  };
  error: Error | void;
}

const getInitialState = (): ReviewsState => {
  const storedMessages = sessionStorage.getItem('savedMessages');
  const storedReports = sessionStorage.getItem('savedReports');
  let savedMessages = {};
  let savedReports = {};

  if (storedMessages) {
    savedMessages = JSON.parse(storedMessages);
  }

  if (storedReports) {
    savedReports = JSON.parse(storedReports);
  }

  return {
    loading: false,
    review: null,
    savedMessages,
    savedReports,
    responseDrawerType: null,
    brandReviewsData: null,
    isLoadingBrandReviewsData: false,
  };
};

export const MODULE_NAME = 'reviews';

export const reviewsModuleConfig = {
  namespaced: true,
  state: getInitialState(),
  getters: {
    getStatus(state: ReviewsState): ReviewStatus {
      return state.review?.status ?? ReviewStatus.UNKNOWN;
    },
    brandName(state: ReviewsState): string {
      return state.review?.brand?.name || '';
    },
    isLoading(state: ReviewsState): boolean {
      return state.loading;
    },
    draftId(state: ReviewsState): number {
      return state.review?.id || null;
    },
    reviewImages(state: ReviewsState): ImageResource[] {
      return state.review?.images || [];
    },
    currentReview(state: ReviewsState): Review {
      return state.review;
    },
    getMessageForReview: (state: ReviewsState) => (reviewId: number) => {
      return state.savedMessages[reviewId];
    },
    getReportForReview: (state: ReviewsState) => (reviewId: number) => {
      return state.savedReports[reviewId];
    },
    responseDrawerType(state: ReviewsState): ResponseDrawerType {
      return state.responseDrawerType;
    },
    isLoadingBrandReviewsData(state: ReviewsState): boolean {
      return state.isLoadingBrandReviewsData;
    },
    brandReviewsData(state: ReviewsState): BrandsReviewsData {
      return state.brandReviewsData;
    },
  },
  mutations: {
    setLoading(state: ReviewsState, loading: boolean) {
      state.loading = loading;
    },
    setReviewData(state: ReviewsState, data: Review) {
      state.review = data;
    },
    uploadImage(state: ReviewsState, newImage: ImageResource) {
      state.review.images.push(newImage);
    },
    deleteImage(state: ReviewsState, imageId: number) {
      state.review.images = state.review.images.filter((image) => image.id !== imageId);
    },
    saveMessage(state: ReviewsState, value: { reviewId: number; message: string }) {
      state.savedMessages[value.reviewId] = value.message;
    },
    saveReport(state: ReviewsState, value: { reviewId: number; message: string }) {
      state.savedReports[value.reviewId] = value.message;
    },
    clearMessage(state: ReviewsState, reviewId: number) {
      delete state.savedMessages[reviewId];
    },
    clearReport(state: ReviewsState, reviewId: number) {
      delete state.savedReports[reviewId];
    },
    setDrawerResponseType(state: ReviewsState, type: ResponseDrawerType) {
      state.responseDrawerType = type;
    },
    setBrandReviewsData(state: ReviewsState, data: BrandsReviewsData) {
      state.brandReviewsData = data;
    },
    clearBrandReviewsData(state: ReviewsState) {
      state.brandReviewsData = null;
    },
    setIsLoadingBrandReviewsData(state: ReviewsState, isLoading: boolean) {
      state.isLoadingBrandReviewsData = isLoading;
    },
  },
  actions: {
    async updateBrandReviewsData({ commit }, value: { brandId: number }) {
      commit('setIsLoadingBrandReviewsData', true);
      let brandReviews;
      let brandStatistics;
      let brandReviewError;
      try {
        brandReviews = await getAllBrandReviews();
        brandStatistics = await getBrandOrderAverageStatisticsReview(value.brandId);
      } catch (error) {
        captureRetailerCoreDiscoveryException(error, [
          { label: 'component', value: 'ReviewsStoreAction' },
          { label: 'action', value: 'Update brand reviews data' },
        ]);
        brandReviewError = error;
      } finally {
        commit('setBrandReviewsData', {
          data: {
            reviews: brandReviews,
            statistics: brandStatistics,
          },
          error: brandReviewError,
        });
        commit('setIsLoadingBrandReviewsData', false);
      }
    },
    clearBrandReviewsData({ commit }) {
      commit('clearBrandReviewsData');
    },
    saveMessage({ commit, state }, value: { reviewId: number; message: string }) {
      commit('saveMessage', value);

      sessionStorage.setItem('savedMessages', JSON.stringify(state.savedMessages));
    },
    clearMessage({ commit, state }, reviewId: number) {
      commit('clearMessage', reviewId);

      sessionStorage.setItem('savedMessages', JSON.stringify(state.savedMessages));
    },
    saveReport({ commit, state }, value: { reviewId: number; message: string }) {
      commit('saveReport', value);

      sessionStorage.setItem('savedReports', JSON.stringify(state.savedReports));
    },
    clearReport({ commit, state }, reviewId: number) {
      commit('clearReport', reviewId);

      sessionStorage.setItem('savedReports', JSON.stringify(state.savedReports));
    },
    setDrawerResponseType({ commit }, type: ResponseDrawerType) {
      commit('setDrawerResponseType', type);
    },
    setCurrentReview({ commit }, review: Review) {
      commit('setReviewData', review);
    },
    async getOrderReview({ commit }, orderId: string) {
      commit('setLoading', true);
      try {
        const data = await getOrderReview(orderId);
        commit('setReviewData', data);
      } catch (error) {
        commit('setReviewData', null);
      } finally {
        commit('setLoading', false);
      }
    },
    async createDraftReview({ commit }, orderId: string) {
      commit('setLoading', true);

      try {
        const data = await createDraftOrderReview(orderId);
        commit('setReviewData', data);
      } catch (error) {
        captureRetailerCoreDiscoveryException(error, [
          { label: 'component', value: 'ReviewsStoreAction' },
          { label: 'action', value: 'Create draft review' },
        ]);
        commit('setReviewData', null);
      } finally {
        commit('setLoading', false);
      }
    },
  },
};

export default reviewsModuleConfig;
