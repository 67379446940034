// node_modules
import http from './http';
import { memoize } from 'lodash-es';

// external_modules
import type { AkLocale } from '@core/types/base';
import type { Category, SearchCategory } from '@monolith/legacy/types/api/category';

type CategoryResponse = { data: Category[] };
export const getCategories = memoize(async () => {
  const response = await http().get<CategoryResponse>(`/api/categories`, { noProgressBar: true });
  return response.data?.data;
});

type CategoriesByLanguageResponse = { data: Record<string, SearchCategory> };
export const getCategoriesByLanguage = memoize(async (lang: AkLocale) => {
  const response = await http().get<CategoriesByLanguageResponse>(`/api/search/categories?language=${lang}`, {
    noProgressBar: true,
  });
  return response.data?.data;
});
