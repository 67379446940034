import {
  deleteContact,
  deleteContacts,
  getContacts,
  getFilters,
  exportContacts,
  getBrandSegments,
  setDiscount,
  removeDiscount,
} from '@bc/brands/domain/contact-management/services/api';
import { type ContactFilter, type PersonalisedDiscountResponse } from '@bc/brands/domain/contact-management/types/api.types';
import { getFacetFromColumn } from '@bc/brands/domain/contact-management/services/sorting.util';
import { ContactManagementEvents } from '@bc/brands/domain/contact-management/types/events.types';
import { Sort } from '@monolith/legacy/types/sorting';
import store from '@monolith/legacy/store';

export const actions = {
  changePage({ commit, dispatch }, page: number): Promise<void> {
    commit('SET_CONTACTS_CURRENT_PAGE', page);
    return dispatch('fetchContacts');
  },

  async fetchContacts({ commit, state, getters }, updateFacets = false): Promise<void> {
    try {
      const {
        data,
        meta: { facets, page },
      } = await getContacts({
        searchQuery: state.searchParameters.searchQuery,
        facets: state.searchParameters.facets,
        filters: updateFacets ? {} : state.searchParameters.filters,
        sort: state.searchParameters.sort,
        currentPage: state.searchParameters.page.current,
        size: state.searchParameters.page.size,
      });

      commit('SET_CONTACTS', data);
      if (updateFacets) {
        commit('SET_CONTACTS_FACETS', facets);
      }
      if (!updateFacets || getters.noFiltersApplied) {
        commit('SET_DYNAMIC_FACETS', facets);
      }
      commit('SET_CONTACTS_PAGE', page);
      return Promise.resolve();
    } catch (error) {
      commit('SET_CONTACTS', []);
      commit('SET_CONTACTS_FACETS', []);
      commit('SET_CONTACTS_PAGE', { current: 1, total: 0 });
      return Promise.reject(error);
    }
  },

  async fetchFilters({ commit }): Promise<void> {
    try {
      const { data } = await getFilters();
      commit('SET_FILTERS', data);
    } catch (error) {
      commit('SET_FILTERS', []);
    }
  },

  setFilters({ commit, dispatch }, filters: Record<string, ContactFilter>): void {
    commit('SET_CONTACTS_SEGMENT_FILTER', null);
    commit('SET_CONTACTS_FILTERS', filters);

    return dispatch('fetchContacts');
  },

  setFilter({ commit, dispatch }, payload: [string, ContactFilter]): void {
    commit('SET_CONTACTS_SEGMENT_FILTER', null);
    commit('SET_CONTACTS_CURRENT_PAGE', 1);
    commit('SET_CONTACT_FILTER', payload);
    return dispatch('fetchContacts');
  },

  async resetFilter({ commit, dispatch }, payload: [string, ContactFilter]): Promise<void> {
    commit('SET_CONTACTS_FILTERS', {});
    await dispatch('setFilter', payload);
  },

  async resetFilters({ commit, dispatch }, payload: Record<string, ContactFilter>): Promise<void> {
    commit('SET_CONTACTS_FILTERS', {});
    await dispatch('setFilters', payload);
  },

  setSegment({ commit, dispatch }, segmentName: string): void {
    commit('SET_CONTACTS_FILTERS', {});
    commit('SET_CONTACTS_CURRENT_PAGE', 1);
    commit('SET_CONTACTS_SEGMENT_FILTER', segmentName);
    dispatch('fetchContacts');
  },

  async removeContacts({ dispatch }, payload: Record<'id', string>[]): Promise<Blob> {
    try {
      const result = await deleteContacts(payload);
      dispatch('reloadTableWithDelay');

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  sortContacts({ commit, dispatch, state }, col: string): Promise<null> {
    const facet = getFacetFromColumn(col);
    const order = state.searchParameters.sort[facet] === Sort.Asc ? Sort.Desc : Sort.Asc;
    commit('SET_SORTING', {
      col: facet,
      order,
    });
    return dispatch('fetchContacts');
  },

  async downloadContacts({ state }): Promise<void> {
    try {
      const fileBlob = await exportContacts({
        searchQuery: state.searchParameters.searchQuery,
        filters: state.searchParameters.filters,
      });

      const downloadUrl = window.URL.createObjectURL(
        new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), fileBlob], {
          type: 'text/csv;charset=UTF-8',
        })
      );

      const link = document.createElement('a');

      link.setAttribute('href', downloadUrl);
      link.setAttribute('download', 'network_contacts.csv');

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
      store['_vm'].$emit(ContactManagementEvents.ContactsDownloaded, true);
    } catch {
      store['_vm'].$emit(ContactManagementEvents.ContactsDownloaded, false);
    }
  },

  reloadTableWithDelay({ commit, dispatch, getters }, page = 0): void {
    // We are delayin reloading of the page in order for backend to run indexation and without need of pooling the backend
    const DEFAULT_DELAY = 1000;

    const reloadPageNumber = page || getters.getPagination.current || 1;
    commit('SET_CONTACTS_CURRENT_PAGE', reloadPageNumber);

    setTimeout(function () {
      dispatch('fetchContacts');
    }, DEFAULT_DELAY);
  },

  async fetchSegments({ commit }): Promise<void> {
    const response = await getBrandSegments();
    commit('account/clients/SET_SEGMENTS', response, { root: true });
  },

  async deleteContact({ commit, dispatch }, clientUuid: string): Promise<void> {
    await deleteContact(clientUuid);
    commit('account/clients/DELETE_CONTACT', { clientUuid }, { root: true });
    dispatch('reloadTableWithDelay');
  },

  async setClientDiscount(
    { commit, dispatch },
    {
      value,
      contactIds,
    }: {
      value: number;
      contactIds: string[];
    }
  ): Promise<PersonalisedDiscountResponse> {
    try {
      const data = await setDiscount(value, contactIds);
      commit('account/clients/SET_CLIENT_DISCOUNT', { value, contactIds }, { root: true });
      dispatch('reloadTableWithDelay');
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async removeClientDiscount(
    { commit, dispatch },
    {
      contactIds,
    }: {
      contactIds: string[];
    }
  ): Promise<void> {
    await removeDiscount(contactIds);
    commit('account/clients/REMOVE_CLIENT_DISCOUNT', { contactIds }, { root: true });
    dispatch('reloadTableWithDelay');
  },
};

export default actions;
