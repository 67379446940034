<template>
  <div
    v-if="brandWidget"
    class="content-widget"
  >
    <h1 v-if="brandWidget.title[0].text">
      {{ brandWidget.title[0].text }}
    </h1>
    <p
      v-for="(paragraph, index) in brandWidget.subtitle"
      :key="index"
      class="content-widget__subtitle"
    >
      {{ paragraph.text }}
    </p>
    <div class="bloc-container">
      <div
        v-if="brandWidget['bloc-1'][0].text"
        class="bloc-container__item"
      >
        <p>{{ brandWidget['bloc-1'][0].text }}</p>
        <span class="item__bullet"><i class="icon-cash"></i></span>
      </div>
      <div
        v-if="brandWidget['bloc-2'][0].text"
        class="bloc-container__item"
      >
        <p>{{ brandWidget['bloc-2'][0].text }}</p>
        <span class="item__bullet"><i class="icon-arrow-down-circle"></i></span>
      </div>
      <div
        v-if="brandWidget['bloc-3'][0].text"
        class="bloc-container__item"
      >
        <p>{{ brandWidget['bloc-3'][0].text }}</p>
        <span class="item__bullet"><i class="icon-truck"></i></span>
      </div>
    </div>
    <div
      v-if="brandWidget['button-label'][0].text"
      class="button-container"
    >
      <a
        :href="brandurl"
        target="_PARENT"
      >
        <AkButton
          class="button--extended"
          size="lg"
        >
          {{ brandWidget['button-label'][0].text }}
        </AkButton>
      </a>
    </div>
  </div>
  <div v-else>
    <span
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    ></span>
  </div>
</template>
<script lang="ts">
import { prismic } from '@core/mixins/prismic';
import { AkButton } from '@ankorstore/design-system';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BrandWidget',
  components: { AkButton },
  mixins: [prismic],
  props: {
    brandurl: {
      type: String,
      required: true,
    },
    brandid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      brandWidget: null,
    };
  },
  async created() {
    const brand = await this.$store.dispatch('shop/fetchBrand', {
      brand_id: this.brandid,
    });
    this.$store.commit('REPLACE_BRAND', brand);
    this.getSingle('brand-widget').then((document) => {
      if (document) {
        this.brandWidget = document.data;
      }
    });
  },
});
</script>
<style lang="scss">
@import '@css/vue-import';
.widgetContainer {
  min-height: 100vh;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .content-widget {
    padding: 24px;
    @apply ds-bg-white ds-font-basic;
    font-size: 12px;
    max-width: 920px;
    max-height: 445px;
    &__subtitle {
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      @apply ds-text-primary;
    }
    @include media-breakpoint-up(sm) {
      padding: 36px;
      font-size: 14px;
    }
    @include media-breakpoint-up(md) {
      padding: 64px;
    }
    @include media-breakpoint-up(lg) {
      padding: 36px 116px;
    }
  }

  .bloc-container {
    display: grid;
    grid-gap: 12px;
    max-height: 168px;
    grid-template-columns: repeat(3, calc(75% - 40px));
    grid-template-rows: minmax(150px, 1fr);
    overflow-x: scroll;
    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(3, 1fr);
      overflow: hidden;
    }
    &__item {
      @apply ds-bg-warm-white ds-items-center ds-text-center ds-flex ds-flex-col;
      padding: 15px 10px;
      & > p {
        font-weight: 400;
        margin-bottom: 25px;
        min-height: 36px;
      }
      .item__bullet {
        @apply ds-text-primary;
        @apply ds-bg-neutral-300;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        border-radius: 30px;
        font-size: 30px;
        .icon-truck {
          margin-top: -10px;
        }
      }
    }
  }

  h1 {
    font-weight: 600;
    text-align: center;
    @apply ds-text-primary ds-font-basic;
    font-size: 21px;
    @include media-breakpoint-up(sm) {
      font-size: 21px;
    }
  }

  .content-widget__subtitle,
  .bloc-container__item,
  h1 {
    margin-bottom: 20px;
  }

  .button-container a {
    width: 100%;
  }
}
</style>
