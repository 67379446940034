import { computed, Ref, unref } from 'vue';
import { RetailerShippingFeesMessageTypeName, PurchasableAggregate } from '@monolith/legacy/modules/cart-2/types';
import useCurrency from '@core/composables/use-currency';
import { BillingItemType } from '@monolith/legacy/types/billing-item';
import { Amount } from '@core/types/amount';
import { useStore } from '@core/composables/use-store';

const usePurchasableAggregate = (purchasableAggregate: Ref<PurchasableAggregate>) => {
  const retailer = useStore().getters.retailer;
  const isRetailerEligibleAksPlus =
    retailer.business?.addresses.find(
      (address) => address.type === 'shipping_address' && !['IT', 'AT'].includes(address.country?.iso_code)
    ) ?? null;
  const isRetailerEu8 = retailer.is_eu_8 as boolean;
  const { $currency } = useCurrency();

  const retailerShippingFeeMessageType = computed<RetailerShippingFeesMessageTypeName>(() => {
    if (!unref(retailerShippingFees)) {
      return null;
    }
    if (unref(isDiscovery)) {
      if (unref(isAksPlus)) {
        return unref(isBrandEligibleForAkPlusFreeShipping) ? 'freeShipping' : 'firstOrder-freeShipping';
      }
      return unref(isEligibleForDiscoveryFreeShipping) ? 'firstOrder-freeShipping' : 'ineligible';
    }
    if (unref(isAksPlus) && unref(isBrandEligibleForAkPlusFreeShipping)) {
      return 'freeShipping';
    }
    return unref(isAboveFranco) ? 'reorder-aboveFranco' : 'reorder-belowFranco';
  });

  const shouldShowAksPlusSignupCta = computed(
    () => isRetailerEu8 && isRetailerEligibleAksPlus && !unref(isAksPlus) && unref(isBrandEligibleForAkPlusFreeShipping)
  );

  // get fees by type
  const retailerShippingFees = computed(
    () => unref(purchasableAggregate).applicableFees?.find(({ type }) => type === BillingItemType.retailerShippingFees) ?? null
  );

  // shipping fees / franco

  const isAksPlus = computed(() => unref(retailerShippingFees)?.metadata?.offerName === 'ankorstore_plus');
  const isDiscovery = computed(() => unref(retailerShippingFees)?.metadata?.isDiscovery);
  const isEligibleForDiscoveryFreeShipping = computed(
    () => unref(retailerShippingFees)?.metadata?.isEligibleForDiscoveryFreeShipping
  );
  const isBrandEligibleForAkPlusFreeShipping = computed(
    () => !unref(retailerShippingFees)?.metadata?.brandNonEligibleForAkPlusFreeShipping
  );

  const isAboveFranco = computed(() => !unref(retailerShippingFees)?.amount?.amount);

  const retailerShippingFeesAmount = computed<Amount>(() => unref(retailerShippingFees)?.amount ?? null);

  const francoAmount = computed<Amount>(() =>
    unref(retailerShippingFees)
      ? {
          amount: unref(retailerShippingFees).metadata.francoAmount,
          currency: $currency.value,
        }
      : null
  );

  const francoFeeAmount = computed<Amount>(() =>
    unref(retailerShippingFees)
      ? {
          amount: unref(retailerShippingFees).metadata.francoFeeAmount,
          currency: $currency.value,
        }
      : null
  );

  const francoMaxFeeAmount = computed<Amount>(() =>
    unref(retailerShippingFees)
      ? {
          amount: unref(retailerShippingFees).metadata.francoMaxFeeAmount,
          currency: $currency.value,
        }
      : null
  );

  const amountLeftForFreeShipping = computed<Amount>(() => unref(retailerShippingFees)?.amountLeftForFreeShipping ?? null);

  return {
    amountLeftForFreeShipping,
    retailerShippingFeeMessageType,
    isAksPlus,
    isDiscovery,
    retailerShippingFees,
    retailerShippingFeesAmount,
    francoMaxFeeAmount,
    francoAmount,
    francoFeeAmount,
    isAboveFranco,
    shouldShowAksPlusSignupCta,
  };
};

export default usePurchasableAggregate;
