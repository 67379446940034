<template>
  <form
    class="resetPwd-form"
    data-akt="reset-password-form"
    @submit.prevent="submit"
  >
    <div class="header">
      <h3 class="title">
        {{ $t('Reset password') }}
      </h3>
    </div>

    <div class="content">
      <div
        v-if="successMessage"
        class="reset-succes"
        data-testid="reset-password-success"
      >
        {{ successMessage }}
      </div>

      <div
        v-else
        class="form-label-group"
      >
        <input
          id="email"
          v-model="email"
          type="email"
          class="form-control hide-placeholder-on-focus"
          name="email"
          autocomplete="username"
          :placeholder="$t('E-Mail Address')"
          required
          data-testid="reset-password-email-input"
        >
        <label for="email">{{ $t('E-Mail Address') }}</label>
        <div
          v-if="errorMessage"
          class="global-error"
          data-testid="reset-password-error"
        >
          {{ errorMessage }}
        </div>

        <AkButton
          class="button--extended"
          type="submit"
          data-testid="reset-password-submit-button"
        >
          {{ $t('Reset password') }}
        </AkButton>
      </div>
    </div>

    <div class="footer">
      <AkButton
        link
        @click.prevent="$emit('change-to-login')"
      >
        {{ $t('Log in now') }}
      </AkButton>
    </div>
  </form>
</template>

<script lang="ts">
import { AkButton } from '@ankorstore/design-system';
import { defineComponent } from 'vue';
import http from '@monolith/legacy/services/api/http';

export default defineComponent({
  name: 'ResetPasswordForm',
  components: { AkButton },
  emits: ['change-to-login'],
  data: () => {
    return {
      email: '',
      successMessage: '',
      errorMessage: '',
    };
  },
  methods: {
    submit() {
      this.$store.commit('SET_PROGRESS_BAR_STATE', 'loading');
      this.errorMessage = '';

      http()
        .post('/password/email', {
          email: this.email,
        })
        .then((response) => {
          this.successMessage = response.data.message;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.message) {
            this.errorMessage = error.response.data.message;
          } else {
            this.errorMessage = this.$t('Failed to reset password. Please refresh and retry!');
          }
        })
        .finally(() => {
          this.$store.commit('SET_PROGRESS_BAR_STATE', 'ending');
        });
    },
  },
});
</script>
<style lang="scss" scoped>
@import '@css/vue-import';
.footer {
  @apply ds-flex ds-justify-center;
}
</style>
