import { Amount } from '@core/types/amount';

export type CartProperty = {
  id: number;
  totalAmountWithVat: Amount;
};

export function createCartProperty({ id, totalAmountWithVat }: CartProperty): CartProperty {
  return {
    id,
    totalAmountWithVat,
  };
}
