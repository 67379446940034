<template>
  <div class="tile-container">
    <a
      :href="tile.path"
      class="ds-block ds-h-full ds-w-full"
      @click="$emit('click')"
    >
      <div
        class="tile-background"
        :class="backgroundClass"
        :style="backgroundStyle"
      >
        <prismic-rich-text
          v-for="(item, index) in tile.items"
          :key="index"
          :field="item.text_content"
          :style="createTextStyle(item)"
          class="text-content"
        />
        <button
          type="button"
          class="tile-cta"
          :style="buttonStyle"
        >
          {{ tile.primary.cta_button_text }}
        </button>
      </div>
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue';
import { SpecialTileGlobal } from '@monolith/legacy/modules/promote-engage/promotion-tile/types';

type BackgroundStyle = {
  '--bg-image': string;
  '--bg-image-hover': string;
  '--bg-colour': string;
  '--bg-colour-hover': string;
};

type ButtonStyle = {
  '--text-color': string;
  '--text-color-hover': string;
  '--bg-colour': string;
  '--bg-colour-hover': string;
};

interface TextStyle {
  '--text-color': string;
  '--font-size': string;
}

export default defineComponent({
  name: 'SpecialTile1',
  props: {
    tile: {
      type: Object as PropType<SpecialTileGlobal>,
      required: true,
    },
  },
  emits: ['click'],
  setup(props) {
    const backgroundClass = computed(() => {
      return {
        'tile-background--bg-colour-hover': Boolean(props.tile.primary.background_colour_hover),
        'tile-background--bg-image-hover': Boolean(props.tile.primary.background_image_hover?.url),
      };
    });
    const backgroundStyle = computed((): BackgroundStyle => {
      return {
        '--bg-image': `url('${props.tile.primary.background_image?.url}')`,
        '--bg-image-hover': `url('${props.tile.primary.background_image_hover?.url}')`,
        '--bg-colour': props.tile.primary.background_colour,
        '--bg-colour-hover': props.tile.primary.background_colour_hover,
      };
    });
    const buttonStyle = computed((): ButtonStyle => {
      return {
        '--text-color': props.tile.primary.cta_button_text_colour,
        '--text-color-hover': props.tile.primary.cta_button_text_colour_on_hover,
        '--bg-colour': props.tile.primary.cta_button_background_colour,
        '--bg-colour-hover': props.tile.primary.cta_background_colour_on_hover,
      };
    });

    function createTextStyle(item: SpecialTileGlobal['items'][number]): TextStyle {
      return {
        '--text-color': item.text_colour,
        '--font-size': item.font_size + 'px',
      };
    }
    return {
      backgroundClass,
      backgroundStyle,
      buttonStyle,
      createTextStyle,
    };
  },
});
</script>

<style lang="scss" scoped>
.tile-container {
  @apply ds-cursor-pointer ds-box-border;

  flex: 0 0 100%;
  max-width: 100%;
  height: 100%;
}

.tile-background {
  @apply ds-h-full ds-w-full ds-flex ds-flex-col ds-items-center ds-justify-evenly ds-bg-no-repeat ds-bg-cover ds-bg-center;
  padding: 15px;

  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;

  background-color: var(--bg-colour);
  background-image: var(--bg-image, none);

  &--bg-image-hover {
    transition-property: background-image;
    &:hover {
      background-image: var(--bg-image-hover);
    }
  }

  &--bg-colour-hover {
    transition-property: background-color;
    &:hover {
      background-color: var(--bg-colour-hover);
    }
  }
}

.text-content {
  @apply ds-text-center;
  color: var(--text-color);
  font-size: var(--font-size);
  line-height: var(--font-size);
}

.tile-cta {
  @apply ds-font-basic ds-rounded-xs ds-bg-white ds-pt-2 ds-pb-2 ds-pl-4 ds-pr-4 ds-text-base ds-box-border ds-self-stretch;

  transition-property: background-color, color;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;

  background-color: var(--bg-colour);
  color: var(--text-color);

  &:hover {
    background-color: var(--bg-colour-hover, var(--bg-colour));
    color: var(--text-color-hover, var(--text-color));
  }
}
</style>
