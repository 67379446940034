<template>
  <div>
    <VatInput
      v-if="question.alias === 'vat_number'"
      :iso-code="selectedCountryCode"
      :initial-value="currentModel"
      :is-disabled="question.disabled"
      @change="({ value }) => onExtraContentChange(value)"
    />
    <InputBoxes
      v-else-if="countryConfig.tax_number_length"
      v-model="currentModel"
      :is-login="true"
      :is-disabled="question.disabled"
      :length="countryConfig.tax_number_length"
      :label="countryConfig.tax_number_label"
      @change="(value) => onExtraContentChange(value)"
    />
    <AkInput
      v-else
      v-model="currentModel"
      :name="question.alias"
      :has-error="state[question.alias] && state[question.alias].$error"
      :errors="getErrors"
      :placeholder="currentLabel"
      :required="question.required"
      :disabled="question.disabled"
      @change="({ target }) => onExtraContentChange(target.value)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Question } from '@monolith/legacy/types/question';
import { vuelidateFieldClassName } from '@core/utilities/fieldClassName';
import { State } from '@monolith/legacy/types/state';
import InputBoxes from '@monolith/legacy/components/input-boxes.vue';
import VatInput from '@monolith/legacy/components/vat-input.vue';
import { AkInput } from '@ankorstore/design-system';
import { getGlobalConfig } from '@monolith/legacy/services/global-config';

export default defineComponent({
  name: 'CompanyNumber',
  components: { InputBoxes, AkInput, VatInput },
  props: {
    question: {
      type: Object as () => Question,
      required: true,
    },
    state: {
      type: Object as () => State,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    isRevamp: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['change'],
  data() {
    return {
      currentModel: '',
      countryConfig: getGlobalConfig().countries_config['FR'],
    };
  },
  computed: {
    getErrors() {
      return this.state[this.question.alias]?.$error ? this.$t('This field is required') : '';
    },
    currentLabel() {
      // eslint-disable-next-line @intlify/vue-i18n/no-dynamic-keys
      return this.label ? this.$t(this.label) : this.$t(this.question.label);
    },
    selectedCountryCode() {
      return this.$store.getters['signUp/selectedCountryCode'];
    },
  },
  watch: {
    model({ content }) {
      this.currentModel = content;
    },
  },
  created(): void {
    this.countryConfig = getGlobalConfig().countries_config[this.selectedCountryCode];
  },
  mounted() {
    if (this.isRevamp) {
      this.currentModel = this.model.content;
    }
  },
  methods: {
    onExtraContentChange(value): void {
      this.$emit('change', this.question.alias, {
        answer_id: this.question.id,
        content: value,
      });
    },
    vuelidateFieldClassName,
  },
});
</script>

<style scoped lang="scss">
.textarea-form {
  textarea {
    min-height: 70px;
    font-size: 14px;
  }
}
</style>
