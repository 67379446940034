import AnalyticsEvent from '@monolith/legacy/services/analytics/events/analytics-event';
import { ReviewsSummaryTrackingProperty } from '@monolith/legacy/services/analytics/properties/reviews-property';

const USER_SECTION_VIEW = 'User Section View';
export const REVIEW_BRAND_PAGE_COMPONENT = 'review_brand_page';
export const REVIEW_SUMMARY_SECTION = 'review_summary';

export class ReviewsSummaryViewEvent extends AnalyticsEvent {
  public readonly name = USER_SECTION_VIEW;
  public properties: ReviewsSummaryTrackingProperty;

  constructor({ payload }) {
    super();

    this.properties = {
      ...payload,
      component: REVIEW_BRAND_PAGE_COMPONENT,
      section: REVIEW_SUMMARY_SECTION,
    };
  }
}
