import http from '@monolith/legacy/services/api/http';
import type { AxiosResponse } from 'axios';
import { Event, Integration, Subscription } from '@monolith/legacy/types/brand-integration-api';
import { deserialize } from '@monolith/legacy/services/utils/jsonapi-parser';

export interface ApplicationPayload {
  type: 'applications';
  id: string;
  developerEmail: string;
  attributes: Omit<Integration, 'id' | 'webhookSubscriptions'>;
  relationships: {
    webhookSubscriptions: {
      data: Subscription[];
    };
  };
}

export interface SubscriptionPayload {
  type: 'webhook-subscriptions';
  id: string;
  attributes: Omit<Subscription, 'id'>;
}

export const getAppsRequest = async () => {
  const url = '/api/internal/v1/applications?include=webhookSubscriptions';
  let response = await http().get(url);
  response = deserialize(response.data);
  return response.data;
};

export const postAppRequest = async (integrationPayload: Pick<Integration, 'name' | 'developerEmail'>) => {
  const url = '/api/internal/v1/applications?include=webhookSubscriptions';
  let response = await http().post(url, integrationPayload);
  response = deserialize(response.data);
  return response.data;
};

export const patchAppRequest = async (integrationPatchPayload: Pick<Integration, 'name' | 'developerEmail' | 'id'>) => {
  const url = `/api/internal/v1/applications/${integrationPatchPayload.id}?include=webhookSubscriptions`;
  let response = await http().patch(url, integrationPatchPayload);
  response = deserialize(response.data);
  return response.data;
};

export const deleteAppRequest = ({ id }: Pick<Integration, 'id'>): Promise<AxiosResponse<''>> => {
  const url = `/api/internal/v1/applications/${id}`;
  return http().delete(url, {
    headers: {
      'Accept': 'application/vnd.api+json, application/json'
    }
  });
};

export const getAvailableEventsRequest = async (): Promise<Event[]> => {
  const url = '/api/v1/webhook-subscriptions/events';
  let response = await http().get(url);
  response = deserialize(response.data);
  return response.data;
};

export const postSubscriptionRequest = async (payload) => {
  const url = `/api/v1/webhook-subscriptions`;
  let response = await http().post(url, payload, {
    headers: {
      'Content-Type': 'application/vnd.api+json',
    },
  });
  response = deserialize(response.data);
  return response.data;
};

export const deleteSubscriptionRequest = ({ id }: Pick<Subscription, 'id'>): Promise<AxiosResponse<''>> => {
  const url = `/api/v1/webhook-subscriptions/${id}`;
  return http().delete(url, {
    headers: {
      'Accept': 'application/vnd.api+json, application/json',
    }
  });
};

export const patchSubscriptionRequest = async (subscriptionPatchPayload) => {
  const url = `/api/v1/webhook-subscriptions/${subscriptionPatchPayload.data.id}`;
  let response = await http().patch(url, subscriptionPatchPayload, {
    headers: {
      'Content-Type': 'application/vnd.api+json',
    },
  });
  response = deserialize(response.data);
  return response.data;
};

export const postRegenerateCredentialsRequest = async (appId: string) => {
  const url = `/api/internal/v1/applications/${appId}/regenerate-secret`;
  let response = await http().post(url, null, {
    headers: {
      'Content-Type': 'application/vnd.api+json',
    },
  });
  response = deserialize(response.data);
  return response.data;
};
