<template>
  <div
    v-if="content"
    class="curated-section"
  >
    <div class="wrapper">
      <div
        class="curated-section__wrapper"
        data-testid="curatedSection"
      >
        <div
          v-if="content.firstImage || content.secondImage"
          class="curated-section__images"
        >
          <img
            v-if="content.firstImage && content.firstImage.url"
            data-testid="curatedSectionImg1"
            :src="content.firstImage.url"
            :alt="content.firstImage.alt"
          />
          <img
            v-if="content.secondImage && content.secondImage.url"
            data-testid="curatedSectionImg2"
            :src="content.secondImage.url"
            :alt="content.secondImage.alt"
          />
        </div>
        <div class="curated-section__info">
          <AkHeading3
            v-if="content.title"
            class="curated-section__title"
            data-testid="curatedSectionTitle"
          >
            {{ content.title }}
          </AkHeading3>
          <div class="curated-section__header">
            <div
              v-if="content.logo"
              class="curated-section__header-logo"
            >
              <a
                v-if="content.cta_link"
                :href="content.cta_link"
                rel="noopener noreferrer"
                target="_blank"
                @click="trackAnalytic"
              >
                <img
                  data-testid="curatedSectionLogo"
                  loading="lazy"
                  :src="content.logo.url"
                  :alt="content.logo.alt"
                />
              </a>
              <img
                v-else
                data-testid="curatedSectionLogo"
                loading="lazy"
                :src="content.logo.url"
                :alt="content.logo.alt"
              />
            </div>
            <template v-if="content.name">
              <a
                v-if="content.cta_link"
                :href="content.cta_link"
                rel="noopener noreferrer"
                target="_blank"
                @click="trackAnalytic"
              >
                <AkHeading4
                  v-if="content.name"
                  data-testid="curatedSectionName"
                >
                  {{ content.name }}
                </AkHeading4>
              </a>

              <AkHeading4
                v-else
                data-testid="curatedSectionName"
              >
                {{ content.name }}
              </AkHeading4>
            </template>
          </div>
          <PrismicRichText
            v-if="content.description"
            :field="content.description"
            class="curated-section__description"
            data-testid="curatedSectionDescription"
          />
          <a
            v-if="content.cta_link && content.cta"
            data-testid="curatedSectionCta"
            :href="content.cta_link"
            class="curated-section__cta"
            rel="noopener noreferrer"
            target="_blank"
            @click="trackAnalytic"
          >{{ content.cta }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

import Analytics from '@monolith/legacy/services/analytics';
import UserClick from '@monolith/legacy/services/analytics/events/user-click.event';

import { CuratedSectionContent } from '@monolith/legacy/types/home/home';

export default defineComponent({
  name: 'CuratedSection',
  props: {
    content: {
      type: Object as () => CuratedSectionContent,
    },
  },
  setup() {
    async function trackAnalytic(): Promise<void> {
      await Analytics.track(
        new UserClick({
          component: 'curated_section',
          action: 'click',
        })
      );
    }

    return {
      trackAnalytic,
    };
  },
});
</script>
<style lang="scss">
.curated-section {
  @apply ds-bg-warm-white;
  &__wrapper {
    @apply ds-text-center lg:ds-text-left lg:ds-flex lg:ds-items-center lg:ds-px-4;
  }
  &__title {
    @apply ds-mb-4;
  }
  &__description {
    @apply ds-mb-4;
    p {
      @apply ds-mb-3;
    }
  }
  &__images {
    @apply ds-grid ds-grid-flow-col ds-place-content-center ds-gap-2.5 lg:ds-place-content-end lg:ds-w-3/5 lg:ds-order-1;
  }
  &__info {
    @apply ds-pt-4 ds-pb-6 ds-px-6 lg:ds-py-2.5 lg:ds-pr-4 lg:ds-pl-0 lg:ds-w-2/5;
  }
  &__header {
    @apply ds-flex ds-items-center ds-justify-center ds-mb-4 lg:ds-justify-start;
    &-logo {
      @apply ds-w-max ds-rounded-full ds-overflow-hidden ds-border-solid ds-border-neutral-300 ds-border ds-mr-2.5;
    }
  }
  &__cta {
    @apply ds-underline;
  }
}
</style>
