import Product from '@monolith/legacy/types/product';
import type { RichTextField } from '@prismicio/client';

export const PRODUCT_COLLECTION_TYPE = 'product_collection';

export type ProductCollectionId = number;

export enum ProductCollectionStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export type ProductCollectionItem = Pick<Product, 'images' | 'name' | 'options' | 'retail_price' | 'variants'> & {
  product_id: Product['id'];
  product_uuid: string;
};

export type ProductCollectionItemList = ProductCollectionItem[];

export type ProductCollection = {
  cover_image: string;
  link?: string;
  description: string | RichTextField<'filled'>;
  id: ProductCollectionId;
  item_count?: number;
  items?: ProductCollectionItemList;
  original_description?: string;
  status?: ProductCollectionStatus;
  title: string;
  type?: typeof PRODUCT_COLLECTION_TYPE;
  uuid: string;
};

export type ProductCollectionLight = Omit<ProductCollection, 'items'>;

export type NewProductCollection = Omit<ProductCollection, 'id' | 'uuid' | 'link'>;

export type ProductCollectionComputedProperties =
  // translation for "original_description" field
  | 'description'
  // number of items
  | 'item_count'
  // connected resource of item_ids
  | 'items'
  // collection page public link
  | 'link';

export type SavedOrNewProductCollection = ProductCollection | NewProductCollection;

export type ProductCollectionsList = ProductCollectionLight[];

// Even though this is implementation, it's very much tied to the types and
// implementing it here makes more sense than in any other modules.
export const isSavedProductCollection = (
  productCollection: SavedOrNewProductCollection
): productCollection is ProductCollection => {
  return 'id' in productCollection;
};
