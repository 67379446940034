import algoliaSearch, { type SearchClient } from 'algoliasearch/lite';
import type { MultipleQueriesOptions, MultipleQueriesQuery, MultipleQueriesResponse } from '@algolia/client-search';
import type { RequestOptions } from '@algolia/transporter';
import type { AxiosInstance } from 'axios';

import { isEnabled } from '@monolith/legacy/services/features';

type RequestTransformer = (
  request: Readonly<MultipleQueriesQuery[]>
) => Readonly<MultipleQueriesQuery[]> | Promise<Readonly<MultipleQueriesQuery[]>>;

type ResponseTransformer = <TResult>(
  response: Readonly<MultipleQueriesResponse<TResult>>
) => Readonly<MultipleQueriesResponse<TResult>> | Promise<Readonly<MultipleQueriesResponse<TResult>>>;

export class SearchApiClient implements SearchClient {
  // singleton instance of the Algolia SearchClient to be used across the application.
  // This ensures that the client is only initialized once and the cache is shared.
  private static client: SearchClient;

  private static init(http: AxiosInstance, host: string) {
    if (!SearchApiClient.client) {
      SearchApiClient.client = algoliaSearch('ankorstore', 'ankorstore', {
        hosts: [
          {
            // The mock search engine is used for E2E tests.
            url: isEnabled('mock_searchengine') ? `${host}/mocks/searchengine` : `${host}/api/v2`,
            protocol: 'https',
          },
        ],
        timeouts: {
          connect: 10,
          read: 10,
          write: 30,
        },
        requester: {
          send: async (request) => {
            try {
              const response = await http.request({
                ...request,
                // Prevent axios from parsing the response as JSON.
                // We want to keep it as a string because the Algolia client will parse it.
                transformResponse: [],
              });

              return {
                content: response.data,
                isTimedOut: false,
                status: response.status,
              };
            } catch (error) {
              return {
                content: error.response?.data,
                isTimedOut: error.code === 'ECONNABORTED',
                status: error.response?.status,
              };
            }
          },
        },
      });
    }
  }

  private readonly requestTransformer: RequestTransformer[] = [];
  private readonly responseTransformer: ResponseTransformer[] = [];

  public constructor(http: AxiosInstance, host: string) {
    SearchApiClient.init(http, host);
  }

  public get searchForFacetValues() {
    return SearchApiClient.client.searchForFacetValues;
  }

  public get initIndex() {
    return SearchApiClient.client.initIndex;
  }

  public get addAlgoliaAgent() {
    return SearchApiClient.client.addAlgoliaAgent;
  }

  public get appId() {
    return SearchApiClient.client.appId;
  }

  public get transporter() {
    return SearchApiClient.client.transporter;
  }

  public get clearCache() {
    return SearchApiClient.client.clearCache;
  }

  public get customRequest() {
    return SearchApiClient.client.customRequest;
  }

  public get getRecommendations() {
    return SearchApiClient.client.getRecommendations;
  }

  public async search<TResult>(requests: Readonly<MultipleQueriesQuery[]>, options?: RequestOptions & MultipleQueriesOptions) {
    const transformedRequests = await this.requestTransformer.reduce(
      async (acc, transformer) => transformer(await acc),
      Promise.resolve(requests)
    );

    const response = await SearchApiClient.client.search<TResult>(transformedRequests, options);

    const transformedResponse = await this.responseTransformer.reduce(
      async (acc, transformer) => transformer(await acc),
      Promise.resolve(response)
    );

    return transformedResponse;
  }

  public onRequest(transformer: RequestTransformer) {
    this.requestTransformer.push(transformer);

    return this;
  }

  public onResponse(transformer: ResponseTransformer) {
    this.responseTransformer.push(transformer);

    return this;
  }

  public destroy() {
    this.requestTransformer.length = 0;
    this.responseTransformer.length = 0;
  }
}
