<template>
  <DiscoveryCatalog
    v-if="config && brand"
    :config="config"
    :index-name="indexName"
    :custom-filter="customFilter"
    :brand-id="brand.id"
    :category="selectedCategoryId"
    :base-path="basePath"
  />
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { getProductCollectionsCustomFilter } from '@monolith/legacy/utilities/product-collections/product-collections';
import { ProductCollection } from '@monolith/legacy/types/account/product-collections';
import { Brand } from '@monolith/legacy/types/api/brand';
import { DiscoveryCatalog, Context } from '@bc/discovery';
import getIndexName, { IndexName } from '@monolith/legacy/services/analytics/algolia/index-name';
import { useCatalogContent } from '@bc/discovery';
import useGlobals from '@core/composables/use-globals';
import { useRoute } from 'vue-router';

defineOptions({ name: 'BrandCatalogProducts' });
const props = defineProps({
  brand: {
    type: Object as () => Brand,
    required: true,
  },
  collection: {
    type: Object as () => ProductCollection,
    default: null,
  },
});

const { lang } = useGlobals();
const route = useRoute();
const selectedCategoryId = computed(() => {
  const categories = Array.isArray(route.query.categories) ? route.query.categories : route.query.categories?.split(',');
  return Number(categories?.at(-1));
});
const customFilter = computed(() => getProductCollectionsCustomFilter(props.collection));
const indexName = getIndexName(IndexName.Products);
const { config } = useCatalogContent({ context: ref(Context.BRAND), selectedCategoryId, lang: ref(lang) });
const basePath = computed(() => props.collection?.link ?? props.brand.link);
</script>
