<template>
  <div class="ds-absolute ak-dropdown popper ds-block">
    <slot />
    <ul
      v-if="options.length"
      class="autocomplete-list"
    >
      <AutocompleteDropdownItem
        v-for="(option, index) in options"
        :key="index"
        data-testid="autocompleteDropdownItem"
        :index="index"
        :selected-option-id="selectedOptionId"
        :option="option"
        @button-tab="$emit('button-tab', index)"
        @select-option="$emit('select-option', option, index)"
      >
        <template #item>
          <slot
            v-bind="{ option }"
            name="item"
          />
        </template>
      </AutocompleteDropdownItem>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Company from '@monolith/legacy/types/api/company';
import AutocompleteDropdownItem from '@monolith/legacy/components/sign-up/common/autocomplete/autocomplete-dropdown-item.vue';

export default defineComponent({
  name: 'AutocompleteDropdown',
  components: { AutocompleteDropdownItem },
  props: {
    options: {
      type: Array as PropType<Company[]>,
      required: false,
      default: () => [],
    },
    selectedOptionId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  emits: ['select-option', 'button-tab'],
});
</script>
<style scoped lang="scss">
.ak-dropdown {
  top: 68px;
}
.autocomplete-list {
  max-height: 160px;
}
</style>
