// external_modules
import Analytics from '@monolith/legacy/services/analytics';
import UserClick from '@monolith/legacy/services/analytics/events/user-click.event';
import { SectionCreatedEvent } from '@monolith/legacy/services/analytics/events/section_created.event';
import { ProductProperty } from '@monolith/legacy/services/analytics/properties/product-property';
import { BrandProperty } from '@monolith/legacy/services/analytics/properties/brand-property';
import { ProductQuickAddToCartEvent } from '@monolith/legacy/services/analytics/events/product-quick-added-to-cart.event';
import { DiscoveryTrackingEvents, SectionType } from '@bc/discovery/domain/tracking';
import { EventEmitterTypes } from '@bc/shared/util';
import { useEventBus, getSectionId } from '@bc/shared';

// internal_modules
import type { RecommendedBrandBannerProps } from './types';
import { watch } from 'vue';

export const recommendedBrandBannersTrackingData: {
  component: string;
  getValueProposition: (text?: string) => string;
  sectionType: SectionType;
} = {
  component: 'reco_carousel',
  getValueProposition: () => 'we_think_you_would_love_these_brands',
  sectionType: SectionType.RecoCarousel,
};

export const useRecommendedBrandBannersTracking = (trackingDataOverride?: {
  recommendedBrandBannersTrackingDataOverride?: Partial<typeof recommendedBrandBannersTrackingData>;
  trackRecommendedBrandBannerClickedOverride?: {
    action?: string;
  };
}) => {
  const { eventBus } = useEventBus();

  let trackingData = recommendedBrandBannersTrackingData;

  if (trackingDataOverride) {
    trackingData = {
      ...recommendedBrandBannersTrackingData,
      ...trackingDataOverride?.recommendedBrandBannersTrackingDataOverride,
    };
  }

  const trackRecommendedBrandBannerCreated = async ({
    sectionId,
    brandId,
    brandPosition,
    productIds,
  }: {
    sectionId: string;
    brandId: number;
    brandPosition: number;
    productIds: number[];
  }) => {
    Analytics.track(
      new SectionCreatedEvent({
        id_section: sectionId,
        id_algo: 1,
        value_proposition: trackingData.getValueProposition(`${brandId}`),
        section_type: trackingData.sectionType,
        position: brandPosition,
        id_brand: [{ id: brandId, position: brandPosition }],
        id_product: productIds.map((id, position) => ({ id, position })),
      })
    );
  };

  const trackRecommendedBrandBannerClicked = ({ id_section, brandId, brandPosition }) => {
    const valueProposition = trackingData.getValueProposition(`${brandId}`);
    const overrides = trackingDataOverride?.trackRecommendedBrandBannerClickedOverride ?? {};
    Analytics.track(
      new UserClick({
        id_section,
        component: trackingData.component,
        brand_id: brandId,
        itemType: 'brand',
        position: brandPosition,
        action: `click_${valueProposition}`,
        value_proposition: valueProposition,
        section_type: trackingData.sectionType,
        ...overrides,
      })
    );
  };

  const trackRecommendedBrandBannerBrandClicked = ({ brandId, position, id_section }) => {
    Analytics.track(
      new UserClick({
        id_section,
        component: trackingData.component,
        action: 'brand_clicked',
        itemType: 'product',
        brand_id: brandId,
        position,
        value_proposition: trackingData.getValueProposition(brandId),
        section_type: trackingData.sectionType,
      })
    );
  };

  const trackRecommendedBrandBannerProductClicked = ({ product, position, id_section }) => {
    Analytics.track(
      new UserClick({
        component: trackingData.component,
        action: 'product_clicked',
        itemType: 'product',
        id_section,
        product_id: product.id,
        position,
        value_proposition: trackingData.getValueProposition(product.brand.id),
        section_type: trackingData.sectionType,
      })
    );
  };

  const trackRecommendedBrandBannerQuickAddToCartClicked = ({
    productId,
    position,
    sectionId,
  }: {
    productId: number;
    position: number;
    sectionId: string;
  }) => {
    Analytics.track(
      new UserClick({
        component: trackingData.component,
        action: 'quick_add_to_cart_clicked',
        itemType: 'product',
        id_section: sectionId,
        productId,
        position,
        value_proposition: trackingData.getValueProposition(`${productId}`),
        section_type: trackingData.sectionType,
      })
    );
  };

  const trackRecommendedBrandBannerCartProductAdded = ({
    productProperty,
    brandProperty,
    page,
    quantity,
    sectionId,
  }: {
    productProperty: ProductProperty;
    brandProperty: BrandProperty;
    page: number;
    quantity: number;
    sectionId: string;
  }) => {
    Analytics.track(new ProductQuickAddToCartEvent(productProperty, brandProperty, page, quantity, sectionId));
  };

  const getSectionIdOverride = (brandId: number) =>
    getSectionId({
      valueProposition: `${trackingData.getValueProposition}_${brandId}`,
      sectionType: trackingData.sectionType,
    });

  const eventNamesMap = {
    [DiscoveryTrackingEvents.PRODUCT_SNIPPET_PRODUCT_CLICKED]: `${DiscoveryTrackingEvents.PRODUCT_SNIPPET_PRODUCT_CLICKED}--recommended-brand-banner`,
    [DiscoveryTrackingEvents.PRODUCT_SNIPPET_BRAND_CLICKED]: `${DiscoveryTrackingEvents.PRODUCT_SNIPPET_BRAND_CLICKED}--recommended-brand-banner`,
    [DiscoveryTrackingEvents.QUICK_ADD_TO_CART_CLICKED]: `${DiscoveryTrackingEvents.QUICK_ADD_TO_CART_CLICKED}--recommended-brand-banner`,
    [DiscoveryTrackingEvents.QUICK_ADD_TO_CART_PRODUCT_ADDED]: `${DiscoveryTrackingEvents.QUICK_ADD_TO_CART_PRODUCT_ADDED}--recommended-brand-banner`,
  };

  const transformEvent: EventEmitterTypes.TransformEventFn = (event, detail) => ({
    event: eventNamesMap[event],
    detail,
  });

  eventBus
    .map(DiscoveryTrackingEvents.PRODUCT_SNIPPET_PRODUCT_CLICKED, transformEvent)
    .map(DiscoveryTrackingEvents.PRODUCT_SNIPPET_BRAND_CLICKED, transformEvent)
    .map(DiscoveryTrackingEvents.QUICK_ADD_TO_CART_CLICKED, transformEvent)
    .map(DiscoveryTrackingEvents.QUICK_ADD_TO_CART_PRODUCT_ADDED, transformEvent)
    .on(DiscoveryTrackingEvents.RECOMMENDED_BRAND_BANNER_CREATED, trackRecommendedBrandBannerCreated)
    .on(DiscoveryTrackingEvents.RECOMMENDED_BRAND_BANNER_CLICKED, trackRecommendedBrandBannerClicked)
    .on(eventNamesMap[DiscoveryTrackingEvents.PRODUCT_SNIPPET_BRAND_CLICKED], trackRecommendedBrandBannerBrandClicked)
    .on(eventNamesMap[DiscoveryTrackingEvents.PRODUCT_SNIPPET_PRODUCT_CLICKED], trackRecommendedBrandBannerProductClicked)
    .on(eventNamesMap[DiscoveryTrackingEvents.QUICK_ADD_TO_CART_CLICKED], trackRecommendedBrandBannerQuickAddToCartClicked)
    .on(eventNamesMap[DiscoveryTrackingEvents.QUICK_ADD_TO_CART_PRODUCT_ADDED], trackRecommendedBrandBannerCartProductAdded);

  return { getSectionId: getSectionIdOverride };
};

export const useRecommendedBrandBannerTracking = (props: RecommendedBrandBannerProps) => {
  const { eventBus } = useEventBus();

  const mapClickEvent: EventEmitterTypes.TransformEventFn = (
    event: EventEmitterTypes.EventKey,
    payload: EventEmitterTypes.EventDetail
  ) => ({
    event,
    detail: {
      ...payload,
      id_section: props.banner.sectionId,
    },
  });

  const trackRecommendedBrandClicked = () => {
    eventBus.emit(DiscoveryTrackingEvents.RECOMMENDED_BRAND_BANNER_CLICKED, {
      sectionId: props.banner.sectionId,
      brandId: props.banner.brand.id,
      brandPosition: props.index,
      productIds: props.banner.products.map((product) => product.id),
    });
  };

  eventBus
    .map(DiscoveryTrackingEvents.PRODUCT_SNIPPET_PRODUCT_CLICKED, mapClickEvent)
    .map(DiscoveryTrackingEvents.PRODUCT_SNIPPET_BRAND_CLICKED, mapClickEvent);

  watch(
    () => props.banner,
    (banner, previousBanner) => {
      if (!banner?.brand || previousBanner?.brand.id === banner.brand.id) {
        return;
      }
      eventBus.emit(DiscoveryTrackingEvents.RECOMMENDED_BRAND_BANNER_CREATED, {
        sectionId: props.banner.sectionId,
        brandId: props.banner.brand.id,
        brandPosition: props.index,
        productIds: props.banner.products.map((product) => product.id),
      });
    },
    { immediate: true }
  );

  return { trackRecommendedBrandClicked };
};
