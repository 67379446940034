import { CLIENT_DEVICE } from '@monolith/legacy/modules/design-system-candidates/breakpoints';

export const MAX_ITEMS = Object.freeze({
  default: {
    [CLIENT_DEVICE.mobile]: 2,
    [CLIENT_DEVICE.tablet]: 3,
    [CLIENT_DEVICE.desktop]: 5,
  },
  categories: {
    [CLIENT_DEVICE.mobile]: 2,
    [CLIENT_DEVICE.tablet]: 3,
    [CLIENT_DEVICE.desktop]: 4,
  },
});

export const ITEM_PREVIEW_RATIO = Object.freeze({
  [CLIENT_DEVICE.mobile]: 1 / 5,
  [CLIENT_DEVICE.tablet]: 3 / 5,
  [CLIENT_DEVICE.desktop]: 1 / 2,
});

export const MAX_ITEM_PREVIEW_RATIO = 9 / 10;

export const ITEM_SPACING = 24;
export const ITEM_SPACING_MOBILE = 16;

export const DEBOUNCE_TIME = 100;
