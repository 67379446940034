<template>
  <div
    class="badge badge--accent-alt"
    :class="'badge--' + size"
  >
    <img
      class="symbol"
      src="/images/logo/ankorstore-plus.svg"
      :alt="$t('ankorstorePlus.program.name')"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { oneOf } from '@core/utilities/propValidators';

export default defineComponent({
  name: 'AnkorstorePlusBadgeBrand',
  props: {
    size: {
      type: String,
      default: 'small',
      validator: oneOf(['small', 'medium']),
    },
  },
});
</script>
<style lang="scss" scoped>
.badge {
  @apply ds-flex ds-items-center ds-justify-center;
  &--accent-alt {
    @apply ds-bg-accent-alt;
  }
}

.badge--small {
  @apply ds-p-2 ds-rounded-sm;
  max-height: 24px;
  .symbol {
    max-height: 9px;
  }
}

.badge--medium {
  @apply ds-p-3 ds-rounded-md;
  max-height: 40px;
  .symbol {
    max-height: 12px;
  }
}
</style>
