import store from '@monolith/legacy/store';
import { GET_CURRENT_CHECKOUT } from '@monolith/legacy/store/cart/action-types';
import { PREORDER_GET_CURRENT_CHECKOUT } from '@monolith/legacy/store/cart-preorder/action-types';
import { isEnabled } from '@monolith/legacy/services/features';

export default function loadCarts(to) {
  const pagesWithCarts = ['product', 'brand', 'search', 'brand-collection', 'connected', 'preorderPayment'];
  const isPageRequiredCarts = pagesWithCarts.includes(to.name);
  const isCartLoading = store.getters['cart/isCartLoading'];
  const isPreorderCartLoading = store.getters['cartPreorder/isCartLoading'];
  const isRetailer = store.getters.userIsRetailer;

  if (isRetailer && isPageRequiredCarts && !isCartLoading && !isPreorderCartLoading) {
    store.dispatch('cart/' + GET_CURRENT_CHECKOUT);
    if (isEnabled('oxp-1484-r3')) {
      store.dispatch('cartPreorder/' + PREORDER_GET_CURRENT_CHECKOUT);
    }
  }
}
