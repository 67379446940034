import { PriceBreakdown, ProductDiscount } from '@monolith/legacy/types/product';
import { Amount } from '@core/types/amount';
import { DineroObject } from 'dinero.js';

const getDiscountsValues = function (originalPrice: DineroObject, ...discounts: ProductDiscount[]): Record<string, Amount> {
  const result: Record<string, Amount> = {};

  for (const discount of discounts) {
    if (discount?.percentage) {
      result[discount.origin] = {
        ...originalPrice,
        amount: Math.round((originalPrice.amount / 100) * discount.percentage),
      };
    }
  }

  return result;
};

const getPriceBreakdown = function (
  originalPrice: DineroObject,
  retailPrice: DineroObject,
  wholesalePrice: DineroObject,
  ...discounts: ProductDiscount[]
): PriceBreakdown {
  const discountValues = getDiscountsValues(originalPrice, ...discounts);
  let finalPrice = originalPrice.amount;

  if (!discountValues['brand'] && originalPrice.amount !== wholesalePrice.amount) {
    // in this case we have a black friday event and a product discount
    finalPrice = wholesalePrice.amount;
  }

  for (const discountOrigin in discountValues) {
    finalPrice -= discountValues[discountOrigin].amount;
  }

  return {
    originalPrice,
    retailPrice,
    wholesalePrice,
    discounts: discountValues,
    finalPrice: {
      amount: finalPrice,
      currency: originalPrice.currency,
    },
  };
};

const getDiscount = function (percentage: number, origin: string): ProductDiscount {
  if (!percentage) {
    return null;
  }

  return {
    origin,
    percentage,
  };
};

export { getDiscountsValues, getPriceBreakdown, getDiscount };
