import Country, { CountryIsoCode } from '@core/types/country';
import { BrandLight } from '@monolith/legacy/types/api/brand';
import { Amount } from '@core/types/amount';
import { LanguageIsoCode } from '@core/types/base';
import { AlgoliaHit } from 'instantsearch.js';
import { ShapeProperties } from '@monolith/legacy/types/product-shape';
import { StockStatus } from '@monolith/legacy/services/product-variants';
import { UploadedFile } from '@monolith/legacy/services/api/upload-file';
import { Currency } from 'dinero.js';
import { Brand } from './api/brand';
import { Tag } from '@monolith/legacy/services/metas/tag';

export interface Category {
  id: number;
  parent_id: number;
  order: number;
  active: number;
  new: number;
  tree: string;
  name: Record<LanguageIsoCode, string>;
  path: string;
}

export interface ProductLight {
  brand: BrandLight;
  discount_rate: number;
  id: number;
  images: Array<string>;
  is_new?: boolean;
  link: string;
  name: string;
  options: Array<Option>;
  original_wholesale_price: Amount;
  out_of_stock?: boolean;
  retail_price: Amount;
  tags: Array<string>;
  type: string;
  unit_multiplier: number;
  wholesale_price: Amount;
}

export default interface Product {
  type: string;
  id: number;
  uuid?: string;
  link: string;
  created_at: string;
  name: string;
  categories: Category[];
  unit_multiplier: number;
  retail_price: Amount;
  original_wholesale_price: Amount;
  images: string[];
  description: string;
  description_auto_translate: boolean;
  description_original_content: string;
  original_language: string;
  original_language_label: string;
  discount_type?: DiscountType | null;
  brand: Brand;
  out_of_stock?: boolean;
  properties: Property[];
  tags: string[];
  made_in: Country | null;
  variation_type?: string;
  options: Option[];
  variants?: Variant[];
  requires_category_update?: boolean;
  discount_rate?: number;
  wholesale_price?: Amount;
  is_best_seller?: boolean;
  is_new?: boolean;
  refreshed?: boolean;
  is_discounted: boolean;
  is_discount_editable: boolean;
  hs_code: string;
  vat_rate: number;
  product_type_id: number;
  attributes: ProductAttribute[];
  has_pending_draft?: boolean;
  isChecked?: boolean;
  available_at?: string;
}

export interface VariantPrice {
  currency: Currency;
  original_wholesale_price: Amount;
  retail_price: Amount;
  wholesale_price?: Amount;
  discount_rate?: number;
}
interface BaseVariantOption {
  id: number;
  name: string;
  display_name: string;
}
export interface VariantOption extends BaseVariantOption {
  value: string;
}
export interface VariantConfig extends BaseVariantOption {
  placeholder: string;
  presets?: VariantPresetConfig[];
}
export interface VariantPresetConfig {
  label: string;
  value: string[];
}

export type Variant = {
  id: number;
  uuid?: string;
  sku: string;
  ian?: string;
  name: string;
  out_of_stock?: boolean;
  stock: Stock;
  price: VariantPrice;
  options: VariantOption[];
  created_at: string;
  unit_multiplier: number;
  shape_properties: ShapeProperties;
  images?: string[];
  index?: number;
  fulfillableId?: string;
  available_at?: string;
  is_sellable_as_classic_product?: boolean;
};

export interface Stock {
  id: number;
  stock_quantity: number;
  reserved_quantity: number;
  available_quantity: number;
  is_always_in_stock: boolean;
  status?: StockStatus;
}

export interface ProductDiscount {
  origin: string;
  percentage: number;
}

export interface PriceBreakdown {
  originalPrice: Amount;
  finalPrice: Amount;
  wholesalePrice: Amount;
  discounts: Record<string, Amount>;
  retailPrice: Amount;
}

export interface FormattedProduct extends Omit<Product, 'tags' | 'retail_price' | 'original_wholesale_price' | 'properties'> {
  tags: Tag[];
  retail_price: Amount;
  original_wholesale_price: Amount;
  properties: PropertyWithFormat[];
}

export interface Option {
  type: string;
  id: number;
  name: string;
  sku: string;
  unit_multiplier: number;
  created_at: string;
  variation?: Variation;
  ian: string;
  stock?: Stock;
  out_of_stock?: boolean;
  product_variant_id?: number;
}

export interface Property {
  name: string;
  key: string;
  value: string;
  original_content: string;
  auto_translate: boolean;
}

export interface ProductAttribute {
  name: string;
  value: string;
}

export interface PropertyWithFormat {
  name: string;
  key: string;
  original_content: string;
  type: string;
  category?: number[] | undefined;
}

export enum DiscountType {
  BRAND = 'brand_discount',
  EVENT_SPECIAL = 'event_special_discount',
  LOYALTY = 'loyalty_discount',
}

export type ProductHitBrand = Pick<
  Brand,
  'id' | 'name' | 'link' | 'minimum' | 'opened_countries' | 'images' | 'type' | 'brand_tags'
> & {
  country_code: CountryIsoCode;
  country: string;
  business_events: Record<
    number,
    {
      id: number;
      discount: number;
      ranking: number;
    }
  >;
};

export type ProductHit = AlgoliaHit<{
  id: number;
  retail_price: Amount;
  wholesale_price: Amount;
  original_wholesale_price: Amount;
  discount_rate: number;
  discount_type?: DiscountType | null;
  unit_multiplier: number;
  tags: Record<LanguageIsoCode, string[]> | string[];
  link: string;
  name: Record<LanguageIsoCode, string> | string;
  display_name: Record<LanguageIsoCode, string> | string;
  description: Record<LanguageIsoCode, string> | string;
  brand: ProductHitBrand;
  options: Option[];
  variants: Variant[];
  images: string[];
  objectID: string;
  aardvarkServeId?: string;
  available_at?: string;
}>;

export enum VariantType {
  COLOR = 'color',
  SIZE = 'size',
  OTHER = 'other',
}

export interface Variation {
  name: string;
}

export const isProduct = (product: unknown): product is Product => {
  return (product as Product)?.type === 'product';
};

export type EditedProductImage = {
  filename: ProductImage;
  order: number;
};

export type ProductImageUrl = string;
export type ProductImage = ProductImageUrl | UploadedFile;
export enum VariantLayout {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}
