<script lang="ts">
import { defineComponent, PropType, toRefs, computed } from 'vue';
import { partition } from '../utils';
import { ParentItem, Item } from '../types';
import { useOpenClosed } from '../composables/use-open-close';

const isParentItem = (item: Item): item is ParentItem => 'children' in item;

export default defineComponent({
  name: 'CheckboxFilterTree',
  props: {
    items: {
      type: Array as PropType<ParentItem[]>,
      required: true,
    },
    grid: {
      type: Boolean,
      default: true,
    },
    showCounts: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const { items, grid } = toRefs(props);
    const itemIds = computed(() => items.value.flatMap((item) => [item, ...item.children]).map((item) => item.id));
    const columns = computed<ParentItem[][]>(() => {
      if (!grid.value) {
        return [items.value];
      }

      /**
       * Put all even number indexes into column 1 and odd into column 2.
       * This ensures the horizontal order is maintained on screen.
       *  _________________
       * | Item 1 | Item 2 |
       * | Item 3 | Item 4 |
       * | Item 5 | Item 6 |
       * | Item 7 | etc... |
       */
      return partition(items.value, (_, index) => index % 2 === 0);
    });
    const { isOpen, open, close, toggle } = useOpenClosed(itemIds);

    const updateItem = (item: Item | ParentItem, isSelected: boolean) => {
      item.selected = isSelected;

      if (!item.selected && isParentItem(item)) {
        // uncheck all children when the parent is deselected
        item.children.forEach((item) => (item.selected = false));
      }

      if (item.selected && isParentItem(item)) {
        open(item.id);
      } else {
        close(item.id);
      }

      emit('input', items.value);
    };

    const getSelectedChildCount = (item: ParentItem) => item.children.filter((child) => child.selected).length;

    return { updateItem, columns, toggle, isOpen, getSelectedChildCount };
  },
});
</script>

<template>
  <div
    :class="{
      'ds-grid': grid,
      'md:ds-grid-cols-2': grid,
      'ds-gap-x-6': grid,
    }"
    role="tree"
  >
    <ul
      v-for="(column, index) in columns"
      :key="index"
      role="group"
      class="ds-m-0 checkbox-filter-tree__list"
    >
      <template
        v-for="parent in column"
        :key="parent.id + (typeof parent.count === 'number' ? ` (${parent.count})` : '')"
      >
        <li
          v-if="!showCounts || (showCounts && parent.count)"
          class="checkbox-filter-tree__list"
          :data-testid="'CheckboxFilterTreeItem-' + parent.id"
        >
          <div class="ds-flex ds-justify-between ds-items-center ds-mt-4">
            <AkCheckbox
              role="treeitem"
              class="ds-grow-0 ds-w-min ds-whitespace-nowrap"
              style="width: min-content"
              :value="parent.id"
              :checked="parent.selected"
              @change="updateItem(parent, $event)"
            >
              <span class="ds-text-base">{{ parent.label }}</span>
              <span
                v-if="showCounts"
                class="ds-text-neutral-700 ds-font-basic ds-text-xs"
              >
                ({{ `${parent.count ? parent.count : 0}` }})
              </span>
            </AkCheckbox>

            <span v-if="!isOpen(parent.id) && getSelectedChildCount(parent) > 0 && !showCounts">
              &nbsp;({{ getSelectedChildCount(parent) }})
            </span>
            <button
              data-testid="ExpandToggleButton"
              class="ds-flex-grow ds-flex ds-justify-end focus:ds-outline-none"
              @click="toggle(parent.id)"
            >
              <span
                class="ds-transition-transform"
                :class="{ 'ds-rotate-180': isOpen(parent.id) }"
              >
                <AkIcon
                  v-if="parent.children.length"
                  symbol="chevron-down"
                />
              </span>
            </button>
          </div>
          <AkExpander
            v-if="parent.children.length"
            :open="isOpen(parent.id)"
          >
            <ul role="group">
              <template
                v-for="child in parent.children"
                :key="child.id + (typeof child.count === 'number' ? ` (${child.count})` : '')"
              >
                <li
                  v-if="!showCounts || (showCounts && child.count)"
                  :data-testid="'CheckboxFilterTreeItem-' + child.id"
                >
                  <AkCheckbox
                    role="treeitem"
                    class="ds-my-4 ds-ml-5"
                    :value="child.id"
                    :checked="child.selected"
                    @change="updateItem(child, $event)"
                  >
                    <span class="ds-text-base">{{ child.label }}</span>
                    <span
                      v-if="showCounts"
                      class="ds-text-neutral-700 ds-font-basic ds-text-xs"
                    >
                      ({{ `${child.count ? child.count : 0}` }})
                    </span>
                  </AkCheckbox>
                </li>
              </template>
            </ul>
          </AkExpander>
        </li>
      </template>
    </ul>
  </div>
</template>
