<template>
  <div
    class="holiday-banner-inner"
    data-testid="brandHolidayBanner"
  >
    <div class="holiday-banner-inner__icon">
      <AkIcon
        symbol="megaphone"
        size="md"
      />
    </div>
    <div class="holiday-banner-inner__main">
      <div class="title">
        <AkParagraph
          size="sm"
          data-test-id="brandHolidayBannerText"
        >
          <i18n-t keypath="brand.holidayMode.sharedBanner.mainMessage">
            <template #brandOnHoliday>
              <strong>{{ $t('brand.holidayMode.sharedBanner.brandOnHoliday', { returnDate }) }}</strong>
            </template>
          </i18n-t>
        </AkParagraph>
      </div>
      <div class="holiday-banner-inner__tag">
        <AkParagraph
          class="no-wrap"
          weight="bold"
        >
          {{ $t('brand.holidayMode.sharedBanner.onHolidayTag') }}
        </AkParagraph>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { formatDate } from '@monolith/legacy/services/date-locale';
import { AkIcon, AkParagraph } from '@ankorstore/design-system';
import { computed } from 'vue';
import { BrandHolidayDateRange } from '@bc/brands/domain';
import { addDurationShortFomat } from '@core/utilities/date';

const props = defineProps<{ brandHolidayDateRange: BrandHolidayDateRange }>();
const returnDate = computed(() => {
  const endDate = props.brandHolidayDateRange.end_date;
  const addedDate = addDurationShortFomat(endDate, { days: 1 });
  return formatDate(addedDate, 'PPP');
});
</script>

<style scoped lang="scss">
.holiday-banner-inner {
  @apply ds-flex ds-gap-2 ds-flex-row ds-items-center;
  &__icon {
    @apply ds-rounded-full ds-bg-info-700 ds-flex ds-items-center ds-w-6 ds-h-6 ds-justify-center ds-text-white ds-self-start;
  }
  &__main {
    @apply ds-flex-1 ds-flex ds-justify-between ds-gap-2;
  }
  &__tag {
    @apply ds-text-info-700;
  }
  strong {
    @apply ds-font-bold;
  }
  .no-wrap {
    text-wrap: nowrap;
  }
}
</style>
