import http from '@monolith/legacy/services/api/http';

export const uploadOrderFile = async (file: File, orderId: string) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('orderId', `${orderId}`);

  return http()
    .post('/api/support/forms/upload-file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return null;
    });
};
