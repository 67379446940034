<template>
  <AkModal
    ref="requestInformationModal"
    size="2xl"
    :cancel-button-text="$t('Skip')"
    :validate-button-text="$t('Add information')"
    data-test-id="early-verification-popin"
    @cancelled="closeModal"
    @validated="goToSettings"
  >
    <div
      class="request-information-sign-up-modal__container"
      data-testid="request-information-sign-up-modal-container"
    >
      <AkParagraph
        label
        class="request-information-sign-up-modal__label"
      >
        {{ $t('Pro tip') }}
      </AkParagraph>
      <AkHeading2 class="request-information-sign-up-modal__title">
        {{ title }}
      </AkHeading2>
      <AkParagraph>
        {{
          $t(
            'When you provide documentation upfront to our team, it takes less time to validate and send your order to the brand.'
          )
        }}
      </AkParagraph>
      <AkParagraph class="ak-link">
        <a
          :href="$t('more_info_about_early_validation')"
          rel="noopener"
          target="_blank"
          >{{ $t('More about the validation process') }}</a
        >
      </AkParagraph>
    </div>
  </AkModal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { AkModal, AkHeading2, AkParagraph } from '@ankorstore/design-system';
import Analytics from '@monolith/legacy/services/analytics';
import SignUpEvent from '@monolith/legacy/services/analytics/events/sign-up/sign-up-event';
import { SignUpEventNames } from '@monolith/legacy/services/analytics/events/sign-up/common/event-names';

export default defineComponent({
  name: 'RequestInformationSignUpPopin',
  components: {
    AkModal,
    AkHeading2,
    AkParagraph,
  },
  emits: ['skip', 'success'],
  setup() {
    const requestInformationModal = ref<InstanceType<typeof AkModal>>();
    return { requestInformationModal };
  },
  computed: {
    title(): string {
      switch (this.$variant('early_verification_title')) {
        case 'save':
          return this.$t('Save time on your first order');
        case 'check':
          return this.$t('We need to check you’re a retailer');
        case 'off':
        default:
          return this.$t('5 minutes today or several hours another day?');
      }
    },
  },
  mounted() {
    this.openModal();
    Analytics.track(
      new SignUpEvent(SignUpEventNames.RequestInformationShownEvent, {
        type: 'Element Viewed',
      })
    );
  },
  methods: {
    openModal() {
      this.requestInformationModal?.openModal?.();
    },
    closeModal() {
      this.requestInformationModal?.close?.();
      Analytics.track(
        new SignUpEvent(SignUpEventNames.RequestInformationClosedEvent, {
          type: 'Click',
        })
      );
      this.$emit('skip');
    },
    goToSettings() {
      Analytics.track(
        new SignUpEvent(SignUpEventNames.RequestInformationInformationEvent, {
          type: 'Click',
        })
      );
      this.$emit('success');
    },
  },
});
</script>

<style scoped lang="scss">
.request-information-sign-up-modal {
  &__container {
    @apply ds-w-8/12 ds-m-auto;
  }
  &__label {
    @apply ds-text-warning-700 ds-mb-1;
  }
  &__title {
    @apply ds-mb-5;
  }
}
</style>
