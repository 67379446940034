<template>
  <div>
    <component
      :is="titleComponent"
      v-if="titleComponent"
      data-testid="noResultTitle"
    >
      {{ titleText }}
    </component>
    <AkParagraph
      class="ds-mb-7"
      data-testid="noResultMessage"
    >
      <template v-if="hasError">
        <template v-if="isSearchContext">
          {{ $t('@@core_discovery.search.no_results.msg.error') }}
        </template>
        <template v-else>
          {{ $t('@@core_discovery.category.no_results.msg.error') }}
        </template>
      </template>
      <template v-else-if="hasRefinements">
        <template v-if="isSearchContext">
          <i18n-t keypath="@@core_discovery.search.no_results.msg.withFiltersApplied">
            <template #cta>
              <AkButton
                link
                size="lg"
                @click="handleClick"
              >
                {{ $t('@@core_discovery.search.no_results.msg.btn.withFiltersApplied') }}
              </AkButton>
            </template>
          </i18n-t>
        </template>
        <template v-else>
          <i18n-t keypath="@@core_discovery.category.no_results.msg.withFiltersApplied">
            <template #cta>
              <AkButton
                link
                size="lg"
                @click="handleClick"
              >
                {{ $t('@@core_discovery.category.no_results.msg.btn.withFiltersApplied') }}
              </AkButton>
            </template>
          </i18n-t>
        </template>
      </template>
      <template v-else-if="isSearchContext">
        {{ $t('@@core_discovery.search.no_results.msg.emptyQuery') }}
      </template>
    </AkParagraph>
    <transition
      name="fade"
      mode="out-in"
      css
    >
      <div
        v-if="shouldDisplayCategoryTiles"
        class="ds-mb-6"
        data-testid="noResultCategoryTiles"
      >
        <AkHeading4 class="ds-mb-4">
          {{ $t('@@core_discovery.search.no_results.category_tiles.heading') }}
        </AkHeading4>
        <CategoryTiles
          class="ds-max-w-3xl"
          :columns="isMobile ? 2 : 4"
          :categories-tile-data="categoriesTileData"
        />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { AkButton, AkHeading1, AkHeading2, AkHeading4, AkParagraph } from '@ankorstore/design-system';
import { useCategoriesTileData } from '@bc/discovery/domain/use-category-tile-data';
import { CategoryTiles } from '@bc/discovery/ui/category-tiles';
import { NO_RESULT_CASE } from '../types';
import { useBreakpoints } from '@monolith/legacy/modules/design-system-candidates';
import { Context } from '@bc/discovery/domain/catalog';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'SearchNoResult',
  components: {
    AkHeading1,
    AkHeading2,
    AkHeading4,
    AkParagraph,
    AkButton,
    CategoryTiles,
  },
  props: {
    searchTerm: {
      type: String,
      default: '',
    },
    hasRefinements: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    context: {
      type: String as PropType<Context>,
      required: true,
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { isMobile } = useBreakpoints();
    const { hasCategoriesTileData, categoriesTileData } = useCategoriesTileData();
    const isSearchContext = computed(() => [Context.SEARCH, Context.BRAND].includes(props.context));
    const isCategoriesContext = computed(() => [Context.CATEGORY_PRODUCTS, Context.CATEGORY_BRANDS].includes(props.context));
    const variant = computed(() => {
      if (props.hasError) {
        return NO_RESULT_CASE.Error;
      } else if (props.hasRefinements) {
        return NO_RESULT_CASE.FiltersApplied;
      }
      return NO_RESULT_CASE.SearchQuery;
    });
    const titleComponent = computed(() => {
      return {
        [NO_RESULT_CASE.FiltersApplied]: AkHeading2,
        [NO_RESULT_CASE.Error]: AkHeading4,
      }[variant.value];
    });
    const titleText = computed(() => {
      if (isSearchContext.value) {
        return {
          [NO_RESULT_CASE.FiltersApplied]: t('@@core_discovery.search.no_results.header.withFiltersApplied', {
            searchTerm: props.searchTerm,
          }),
          [NO_RESULT_CASE.Error]: t('@@core_discovery.search.no_results.header.error'),
        }[variant.value];
      } else {
        return {
          [NO_RESULT_CASE.FiltersApplied]: t('@@core_discovery.category.no_results.header.withFiltersApplied'),
          [NO_RESULT_CASE.Error]: t('@@core_discovery.category.no_results.header.error'),
        }[variant.value];
      }
    });
    const handleClick = () => {
      emit('click');
    };
    const shouldDisplayCategoryTiles = computed(
      () =>
        (isSearchContext.value || isCategoriesContext.value) &&
        hasCategoriesTileData.value &&
        [NO_RESULT_CASE.Error, NO_RESULT_CASE.SearchQuery].includes(variant.value)
    );
    return {
      isMobile,
      isSearchContext,
      titleComponent,
      titleText,
      handleClick,
      shouldDisplayCategoryTiles,
      categoriesTileData,
    };
  },
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
