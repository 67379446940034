<template>
  <div
    class="review-summary ds-flex ds-flex-col ds-p-4 md:ds-py-5 md:ds-px-5"
    :class="[compact ? 'review-summary--compact' : 'review-summary--regular']"
    data-testid="reviewSummary"
  >
    <ReviewAverageRating
      :average="average"
      :brand-name="brandName"
      :compact="compact"
      class="review-summary__rating"
    />
    <RatingBreakdown
      :data="breakdown"
      class="review-summary__breakdown"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import ReviewAverageRating from '@monolith/legacy/components/review/review-average-rating.vue';
import RatingBreakdown from '@monolith/legacy/components/review/rating-breakdown.vue';
import { ReviewRatings, ReviewsBreakdown, ReviewStatistics } from '@monolith/legacy/components/review/types';

export default defineComponent({
  name: 'ReviewSummary',
  components: {
    ReviewAverageRating,
    RatingBreakdown,
  },
  props: {
    brandName: {
      type: String,
      required: true,
    },
    statistics: {
      type: Object as PropType<ReviewStatistics>,
      required: true,
    },
    compact: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  computed: {
    average(): ReviewRatings {
      return this.statistics?.average;
    },
    breakdown(): ReviewsBreakdown {
      return (
        this.statistics?.breakdown ?? {
          total: 0,
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
        }
      );
    },
  },
});
</script>

<style lang="scss">
.review-summary {
  $self: &;
  &__rating {
    @apply ds-mb-4;
  }
  &__breakdown {
    @apply ds-relative;
    @apply ds-pt-4;
    &:before {
      content: '';
      @apply ds-absolute ds-top-0 ds-left-0 ds-right-0;
      @apply ds-block;
      @apply ds-bg-neutral-300;
      height: 1px;
    }
  }
  &--regular {
    @apply lg:ds-flex-row;
    #{$self}__rating {
      @apply lg:ds-w-3/5 lg:ds-m-0 lg:ds-pr-8 xl:ds-pr-9;
    }
    #{$self}__breakdown {
      @apply lg:ds-w-2/5 lg:ds-pt-0 lg:ds-pl-6;
      &:before {
        @apply lg:ds-left-0 lg:ds-top-0 lg:ds-bottom-0;
        @apply lg:ds-h-auto;
        @apply lg:ds-w-px;
      }
    }
  }
  &--compact {
    @apply xl:ds-flex-row;
    #{$self}__rating {
      @apply xl:ds-w-2/5 xl:ds-m-0 xl:ds-pr-8 2xl:ds-pr-8;
    }
    #{$self}__breakdown {
      @apply xl:ds-w-3/5 xl:ds-pt-0 lg:ds-pl-7 lg:ds-pr-9;
      &:before {
        @apply xl:ds-left-0 xl:ds-top-0 xl:ds-bottom-0;
        @apply xl:ds-h-auto;
        @apply xl:ds-w-px;
      }
    }
  }
}
</style>
