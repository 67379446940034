import { Brand } from '@monolith/legacy/types/api/brand';
import { Retailer } from '@monolith/legacy/types/api/retailer';
import { getInjectedContent } from './injected-content';
// List of the meta tag used to initialize other global properties
export enum GlobalMetaTagName {
  CSRFToken = 'csrf-token',
  Features = 'features',
  Experiments = 'experiments',
  BrowserId = 'browser_id',
  CDN = 'cdn',
  GlobalConfig = 'global-config',
  Environment = 'environment',
  ManagedLanguages = 'managed-languages',
  Categories = 'categories',
  Tags = 'tags',
  SentryDSN = 'sentry-dsn',
}

// List of the meta tag used to initialize the store
export enum StateMetaTagName {
  Country = 'state.country',
  Currency = 'state.currency',
  CurrenciesRates = 'state.currencies_rates',
  User = 'state.user',
  Retailer = 'state.retailer',
  Brand = 'state.brand',
  Stripe = 'state.stripe',
  EligibleLiftDayFreeShipping = 'state.eligible_lift_day_free_shipping',
  EligiblePreShowFreeShipping = 'state.eligible_preshow_free_shipping',
  ShowRequestedInformationForm = 'state.show_requested_information_form',
}

// meta tag that are not available in every page and render dynamically
export enum DynamicMetaTagName {
  BrandCatalogues = 'brand-catalogues',
  BrandCatalogueTiles = 'brand-catalogue-tiles',
  ShopBrands = 'state.shop.brands',
}

interface InitialState {
  brand?: Brand;
  country?: string;
  currency?: string;
  currenciesRates?: unknown;
  user?: unknown;
  retailer?: Retailer;
  stripe?: unknown;
  isEligibleLiftDayForFreeShipping?: boolean;
  isEligiblePreshowForFreeShipping?: boolean;
  showRequestedInformationForm?: boolean;
}

const stateToMetaTagNameMap: Record<keyof InitialState, StateMetaTagName> = {
  brand: StateMetaTagName.Brand,
  country: StateMetaTagName.Country,
  currenciesRates: StateMetaTagName.CurrenciesRates,
  currency: StateMetaTagName.Currency,
  isEligibleLiftDayForFreeShipping: StateMetaTagName.EligibleLiftDayFreeShipping,
  isEligiblePreshowForFreeShipping: StateMetaTagName.EligiblePreShowFreeShipping,
  retailer: StateMetaTagName.Retailer,
  stripe: StateMetaTagName.Stripe,
  user: StateMetaTagName.User,
  showRequestedInformationForm: StateMetaTagName.ShowRequestedInformationForm,
};
// Because some of the content injected is JSON Formatted and some is not, this config is used to help parsing process of the content
export const InitialStateConfig: Record<StateMetaTagName | GlobalMetaTagName | DynamicMetaTagName, boolean> = {
  [GlobalMetaTagName.CSRFToken]: false,
  [GlobalMetaTagName.Features]: true,
  [GlobalMetaTagName.Experiments]: true,
  [GlobalMetaTagName.BrowserId]: true,
  [GlobalMetaTagName.CDN]: false,
  [GlobalMetaTagName.GlobalConfig]: true,
  [GlobalMetaTagName.Environment]: false,
  [GlobalMetaTagName.ManagedLanguages]: true,
  [GlobalMetaTagName.Categories]: true,
  [GlobalMetaTagName.Tags]: true,
  [GlobalMetaTagName.SentryDSN]: false,
  [StateMetaTagName.Country]: true,
  [StateMetaTagName.Currency]: true,
  [StateMetaTagName.CurrenciesRates]: true,
  [StateMetaTagName.User]: true,
  [StateMetaTagName.Retailer]: true,
  [StateMetaTagName.Brand]: true,
  [StateMetaTagName.Stripe]: true,
  [StateMetaTagName.EligibleLiftDayFreeShipping]: true,
  [StateMetaTagName.EligiblePreShowFreeShipping]: true,
  [StateMetaTagName.ShowRequestedInformationForm]: true,
  [DynamicMetaTagName.BrandCatalogues]: true,
  [DynamicMetaTagName.BrandCatalogueTiles]: true,
  [DynamicMetaTagName.ShopBrands]: true,
};

export function getInitialState(): InitialState {
  return Object.fromEntries(
    Object.entries(stateToMetaTagNameMap)
      .map(([key, tagName]) => [key, getInjectedContent(tagName)])
      .filter(([, value]) => value !== null && value !== undefined)
  );
}
