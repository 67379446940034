import type { App } from 'vue';
import type { Router } from 'vue-router';
import { captureEvent, captureException, setContext, setTag, setUser, withScope } from '@sentry/browser';

import * as Sentry from '@sentry/vue';
import { isEnabled } from '@monolith/legacy/services/features';
import config from '@core/plugins/prismic.config.json';
import { getInjectedContent } from '@monolith/legacy/services/injected-content';
import { GlobalMetaTagName } from '@monolith/legacy/services/initial-state';
import { getApplicationCommitSha } from '@core/utilities/application/appVersion';

const excludedAxiosStatusErrors = [400, 401, 403, 404, 422];
const projectName = 'ankorstore-vue';

const recordPrismicCalls = () => {
  if (!('PerformanceObserver' in globalThis)) {
    return undefined;
  }

  const observer = new PerformanceObserver((list) => {
    const prismicApiRequests = Array.from(list.getEntries()).filter((entry) => entry.name.includes(config.prismicApiEndpoint));
    const prismicAssetsRequests = Array.from(list.getEntries()).filter((entry) =>
      entry.name.includes(config.prismicAssetsEndpoint)
    );
    const totalRequests = prismicApiRequests.length + prismicAssetsRequests.length;

    if (totalRequests > 0) {
      Sentry.metrics.increment('prismicRequestsTotal', totalRequests);
      Sentry.metrics.increment('prismicApiRequestsTotal', prismicApiRequests.length);
      Sentry.metrics.increment('prismicAssetsRequestsTotal', prismicAssetsRequests.length);
    }
  });

  observer.observe({ type: 'resource', buffered: true });

  return observer;
};

export const initSentry = (app: App, router?: Router) => {
  const sentryDSN = getInjectedContent<string>(GlobalMetaTagName.SentryDSN);
  const environment = getInjectedContent<string>(GlobalMetaTagName.Environment);
  if (process.env.NODE_ENV !== 'development' && sentryDSN) {
    const dsn = sentryDSN;

    const integrations = [];
    if (router) {
      integrations.push(Sentry.browserTracingIntegration({ router }));
    } else {
      integrations.push(Sentry.browserTracingIntegration());
    }

    if (isEnabled('sentryReplay')) {
      integrations.push(Sentry.replayIntegration());
    }

    Sentry.init({
      app,
      dsn,
      integrations,
      environment,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.2,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost'],

      // Set release name with commit sha
      release: `${projectName}@${getApplicationCommitSha()}`,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 0.1,
      beforeSend(event, { originalException }) {
        if (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          (originalException?.isAxiosError && excludedAxiosStatusErrors.includes(originalException.response?.status)) ||
          originalException === 'Timeout'
        ) {
          return null;
        }
        return event;
      },
    });

    recordPrismicCalls();
  }
};

export { captureEvent, captureException, setContext, setTag, setUser, withScope };
