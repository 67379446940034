<template>
  <form
    class="register-form"
    novalidate
    @submit.prevent="submit"
  >
    <div class="header">
      <h3 class="title">
        {{ $t('Reset password') }}
      </h3>
    </div>

    <div class="content">
      <div
        v-if="successMessage"
        class="reset-succes"
      >
        {{ successMessage }}
      </div>

      <div
        v-else
        class="form"
      >
        <div class="form-label-group">
          <input
            id="token"
            type="hidden"
            name="token"
            :value="model.token"
          />

          <div class="form-label-group">
            <input
              id="email"
              v-model.lazy.trim="model.email"
              type="email"
              class="form-control hide-placeholder-on-focus"
              name="email"
              autocomplete="email"
              :class="fieldClassName(v$.email)"
              :placeholder="$t('Email')"
              data-testid="reset-password-email-input"
            />
            <label for="email">{{ $t('Email') }}</label>
            <FieldErrorMessage
              v-for="error in v$.email.$errors"
              :key="error.$uid"
              :validator="error.$validator"
              :error-messages="{
                required: $t('Email is required'),
                email: $t('Email is invalid'),
              }"
            />
          </div>

          <div class="form-label-group">
            <input
              id="password"
              v-model.lazy.trim="model.password"
              type="password"
              class="form-control hide-placeholder-on-focus"
              name="password"
              :class="fieldClassName(v$.password)"
              :placeholder="$t('Password')"
            />
            <label for="password">{{ $t('Password') }}</label>
            <FieldErrorMessage
              v-for="error in v$.password.$errors"
              :key="error.$uid"
              :validator="error.$validator"
              :error-messages="{
                required: $t('Password is required'),
                minLength: $t('Password must have at least 8 chars'),
              }"
            />
          </div>

          <div class="form-label-group">
            <input
              id="confirmPassword"
              v-model.lazy.trim="model.confirmPassword"
              name="confirmPassword"
              :class="fieldClassName(v$.confirmPassword)"
              type="password"
              class="form-control hide-placeholder-on-focus"
              :placeholder="$t('Confirm Password')"
            />
            <label for="confirmPassword">{{ $t('Confirm Password') }}</label>
            <FieldErrorMessage
              v-for="error in v$.confirmPassword.$errors"
              :key="error.$uid"
              :validator="error.$validator"
              :error-messages="{
                required: $t('Confirm password is a required field'),
                sameAsPassword: $t('Passwords do not match'),
              }"
            />
          </div>

          <div
            v-if="serverErrorMessage"
            class="global-error ds-mb-4"
          >
            {{ serverErrorMessage }}
          </div>

          <AkButton
            class="button--extended"
            type="submit"
            data-testid="reset-password-submit-button"
          >
            {{ $t('Reset password') }}
          </AkButton>
        </div>
      </div>
    </div>

    <div class="footer">
      <p>
        <a
          href="/login"
          @click.prevent="$emit('change-to-login')"
        >
          {{ $t('Log in now') }}
        </a>
      </p>
    </div>
  </form>
</template>

<script lang="ts">
import http from '@monolith/legacy/services/api/http';
import { AkButton } from '@ankorstore/design-system';
import { defineComponent, onMounted, h } from 'vue';
import type { PropType } from 'vue';
import { required, email, sameAs, minLength } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { reactive } from 'vue';

const FieldErrorMessage = (props) => h('p', { class: 'error-message' }, props.errorMessages[props.validator]);
FieldErrorMessage.props = {
  validator: String,
  errorMessages: Object as PropType<{ [key: string]: string }>,
};

export default defineComponent({
  name: 'ResetPasswordInputForm',
  components: { AkButton, FieldErrorMessage },
  emits: ['change-to-login'],
  setup() {
    const model = reactive({
      token: '',
      email: '',
      password: '',
      confirmPassword: '',
    });
    const rules = () => ({
      email: { required, email },
      password: { required, minLength: minLength(8) },
      confirmPassword: { required, sameAsPassword: sameAs(model.password) },
    });
    const v$ = useVuelidate(rules, model);

    onMounted(() => {
      model.token = window.location.pathname.split('/').pop();
    });

    return { v$, model };
  },
  data() {
    return {
      successMessage: '',
      serverErrorMessage: '',
    };
  },
  methods: {
    async submit() {
      this.serverErrorMessage = '';
      this.$store.commit('SET_PROGRESS_BAR_STATE', 'loading');
      this.v$.$touch();
      const isValid = await this.v$.$validate();
      if (isValid) {
        try {
          await http().post('/auth/password/reset', {
            email: this.model.email,
            password: this.model.password,
            password_confirmation: this.model.confirmPassword,
            token: this.model.token,
          });
          window.location.href = '/shop/new';
        } catch (error) {
          this.$store.commit('SET_PROGRESS_BAR_STATE', 'ending');
          if (error.response && error.response.data && error.response.data.message) {
            this.serverErrorMessage = error.response.data.message;
          } else {
            this.serverErrorMessage = this.$t('Failed to reset password. Please refresh and retry!');
          }
        }
      }
    },
    fieldClassName(field) {
      if (!field) {
        return '';
      }
      if ((field.$touched || field.$submitted) && field.$valid) {
        return 'is-valid';
      }
      if ((field.$touched || field.$submitted) && field.$invalid) {
        return 'is-invalid';
      }
      return '';
    },
  },
});
</script>
