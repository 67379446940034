import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import { AdInfo, CampaignInfo, CampaignListMeta, CampaignWizard } from '@bc/advertisement/domain';
import { addDate, TypeToAdd } from '@monolith/legacy/services/date-locale';

export interface InitialState {
  adsCampaigns: {
    data: CampaignInfo[];
    meta: CampaignListMeta;
  };
  campaignWizardInfo: CampaignWizard;
  adsList: AdInfo[];
}

export const initialState: InitialState = {
  adsCampaigns: { data: [], meta: { page: { total: 0 } } },
  adsList: [],
  campaignWizardInfo: {
    meta: null,
    budgetInterval: null,
    budgetIntervalAmount: null,
    status: null,
    externalBrandId: null,
    createdAt: null,
    updatedAt: null,
    type: '',
    name: '',
    budget: { amount: 0, currency: 'EUR', formatted: '' },
    startDate: new Date(),
    endDate: addDate(new Date(), 27, TypeToAdd.Days),
    weeklyBudget: undefined,
    adsProducts: [],
    id: null,
  },
};

export default {
  namespaced: true,
  state: { ...initialState },
  getters,
  mutations,
  actions,
};
