<template>
  <div>
    <BannerMissingEoriWarning
      v-if="!hasApplied"
      @marked-as-submitted="onMarkAsApplied"
      @apply-for-eori="onApplyForEori"
    />

    <BannerMissingEoriSubmitted
      v-if="hasApplied"
      @add-eori="onAddEori"
      @navigate-to-help-desk="onNavigateToHelpDesk"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Analytics from '@monolith/legacy/services/analytics';
import { EoriWarningInteraction } from '@monolith/legacy/services/analytics/events/segment-ecommerce-types';
import { EoriAppliedForEvent, EoriNavigatedToAddEvent, EoriNavigatedToHelpDeskEvent } from '@monolith/legacy/services/analytics/events/eori';
import { oneOf } from '@core/utilities/propValidators';
import BannerMissingEoriWarning from '@monolith/legacy/components/banners/banner-missing-eori-warning.vue';
import BannerMissingEoriSubmitted from '@monolith/legacy/components/banners/banner-missing-eori-submitted.vue';

export default defineComponent({
  name: 'BannerMissingEori',
  components: { BannerMissingEoriSubmitted, BannerMissingEoriWarning },
  props: {
    eventOrigin: {
      validator: oneOf<EoriWarningInteraction['origin']>(['brand-orders', 'retailer-orders', 'retailer-checkout']),
      required: true,
      type: String as PropType<EoriWarningInteraction['origin']>,
    },
  },
  data() {
    return {
      hasApplied: false,
    };
  },
  methods: {
    onMarkAsApplied: function () {
      this.hasApplied = true;
    },
    onApplyForEori: function () {
      Analytics.track(new EoriAppliedForEvent({ origin: this.eventOrigin }));
    },
    onAddEori: function () {
      Analytics.track(new EoriNavigatedToAddEvent({ origin: this.eventOrigin }));
    },
    onNavigateToHelpDesk: function () {
      Analytics.track(new EoriNavigatedToHelpDeskEvent({ origin: this.eventOrigin }));
    },
  },
});
</script>
