<template>
  <div
    data-testid="qualification-question"
    :class="{ 'form-label-group': !isRevamp, 'ds-mb-4': isRevamp }"
    class="item-wrapper"
  >
    <p
      v-if="hasLabel"
      class="form-label"
      data-testid="question-label"
    >
      <!-- eslint-disable-next-line @intlify/vue-i18n/no-dynamic-keys -->
      {{ $t(question.label) }}
    </p>
    <div :class="{ 'required-field': question.required }">
      <div :data-testid="`qualification-question-${question.alias}`">
        <component
          :is="currentTypeQuestion.component"
          v-if="checkFeatureFlag(question.question_type)"
          :ref="currentTypeQuestion.component"
          :model="model[question.alias]"
          :question="question"
          :is-revamp="isRevamp"
          :state="state"
          :errors="errors"
          :global-model="model"
          :related-alias="relatedAlias"
          @change="onModelUpdate"
          @input="onModelInput"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { questionTypeMap } from '@monolith/legacy/components/sign-up/questions/constants';
import { State } from '@monolith/legacy/types/state';
import { QuestionType, AnswerType } from '@monolith/legacy/types/question';
import QuestionSelect from '@monolith/legacy/components/sign-up/questions/types/question-select.vue';
import QuestionCheckboxes from '@monolith/legacy/components/sign-up/questions/types/question-checkboxes.vue';
import QuestionRadio from '@monolith/legacy/components/sign-up/questions/types/question-radio.vue';
import QuestionPassword from '@monolith/legacy/components/sign-up/questions/types/question-password.vue';
import QuestionFree from '@monolith/legacy/components/sign-up/questions/types/question-free.vue';
import CompanySearch from '@monolith/legacy/components/sign-up/questions/types/company-search.vue';
import QuestionPhone from '@monolith/legacy/components/sign-up/questions/types/question-phone.vue';
import QuestionPromocode from '@monolith/legacy/components/sign-up/questions/types/question-promocode.vue';
import QuestionDate from '@monolith/legacy/components/sign-up/questions/types/question-date.vue';
import CountrySelect from '@monolith/legacy/components/sign-up/questions/types/country-select.vue';
import CompanyNumber from '@monolith/legacy/components/sign-up/questions/types/company-number.vue';
import GoogleAutocomplete from '@monolith/legacy/components/sign-up/questions/types/google-autocomplete.vue';
import QuestionWebsite from '@monolith/legacy/components/sign-up/questions/types/question-website.vue';
import { defineComponent } from 'vue';
import QuestionPreferences from '@monolith/legacy/components/sign-up/questions/types/question-preferences.vue';
import { QualificationQuestion } from '@monolith/legacy/types/registration-funnel-questions';

export default defineComponent({
  name: 'QuestionItem',
  components: {
    QuestionSelect,
    QuestionCheckboxes,
    QuestionRadio,
    QuestionFree,
    CompanySearch,
    QuestionPassword,
    QuestionPhone,
    CountrySelect,
    CompanyNumber,
    GoogleAutocomplete,
    QuestionPromocode,
    QuestionDate,
    QuestionWebsite,
    QuestionPreferences,
  },
  props: {
    question: {
      type: Object as () => QualificationQuestion,
      required: true,
    },
    state: {
      type: Object as () => State,
      required: true,
    },
    prefilledValue: {
      type: String,
      default: '',
      required: false,
    },
    relatedAlias: {
      type: String,
      default: '',
      required: false,
    },
    model: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
      default: null,
    },
    isRevamp: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['model-update', 'model-input'],
  data() {
    return {
      QuestionTypeMap: questionTypeMap,
      QuestionType: QuestionType,
      AnswerType: AnswerType,
    };
  },
  computed: {
    currentTypeQuestion() {
      const { question_type } = this.question;

      if (this.question.alias === 'preferred_categories') {
        return {
          component: 'QuestionPreferences',
          label: '',
        };
      }
      if (this.question.alias === 'shop_address_country') {
        return {
          component: 'CountrySelect',
          label: '',
        };
      }
      if (['vat_number', 'tax_number'].includes(this.question.alias)) {
        return {
          component: 'CompanyNumber',
          label: '',
        };
      }

      return {
        ...this.QuestionTypeMap.get(question_type),
        isEnabled: this.checkFeatureFlag(question_type),
      };
    },
    hasLabel() {
      return this.question.alias !== 'preferred_categories' && this.currentTypeQuestion.label;
    },
  },
  created() {
    this.$emit('model-update', this.question.alias, { content: this.prefilledValue, answer_id: null }, this.question.isRegister);
  },
  methods: {
    checkFeatureFlag(type) {
      if (!this.QuestionTypeMap.get(type)?.featureFlag) {
        return true;
      }
      return this.$isEnabled(this.QuestionTypeMap.get(type).featureFlag);
    },
    onModelUpdate(alias, value, register?) {
      const isRegister = register || this.question.isRegister;
      this.$emit('model-update', alias, value, isRegister);
    },
    onModelInput(alias, value, register?) {
      const isRegister = register || this.question.isRegister;
      this.$emit('model-input', alias, value, isRegister);
    },
  },
});
</script>

<style lang="scss">
.form-label {
  @apply ds-text-neutral-700 ds-mb-2;
}

form.register-form .content .row .item-wrapper .form-label-group:first-child {
  margin-bottom: 5px;
}
</style>
