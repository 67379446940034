<template>
  <AkTabBar
    class="brand-catalog-tabs"
    name="brand-catalog-tabs"
    :active="activeIndex"
    :items="items"
    tab-style="primary"
    @tab-bar-click="onChange"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Analytics from '@monolith/legacy/services/analytics';
import { Brand } from '@monolith/legacy/types/api/brand';
import { UserClick } from '@monolith/legacy/services/analytics/events/user-click.event';

import { AkTabBarItemInterface } from '@ankorstore/design-system';
import { mapGetters } from 'vuex';

export enum BrandCatalogView {
  Products = 'products',
  Collections = 'collections',
  Ordered = 'ordered',
}

export default defineComponent({
  name: 'BrandCatalogTabs',
  props: {
    brand: {
      type: Object as () => Brand,
      required: true,
    },
    active: {
      type: String,
      default: BrandCatalogView.Products,
    },
    hasCollections: {
      type: Boolean,
      default: false,
    },
    orderedItemsNumber: {
      type: Number,
      default: 0,
    },
  },
  emits: ['update:active'],
  computed: {
    ...mapGetters(['user', 'retailer']),
    items(): AkTabBarItemInterface[] {
      const tabsArray: AkTabBarItemInterface[] = [
        {
          label: this.$t('All Products'),
          pillContent: '',
          contentId: BrandCatalogView.Products,
        },
      ];

      if (this.hasCollections) {
        tabsArray.push({
          label: this.$t('Collections'),
          pillContent: '',
          contentId: BrandCatalogView.Collections,
        });
      }

      if (this.orderedItemsNumber) {
        tabsArray.push({
          label: this.$t('retailer.buyAgainTab.label'),
          pillContent: this.orderedItemsNumber + '',
          contentId: BrandCatalogView.Ordered,
        });
      }

      return tabsArray;
    },
    activeIndex() {
      return this.items.findIndex(({ contentId }) => contentId === this.active);
    },
  },
  mounted() {},
  methods: {
    onChange(target: HTMLElement): void {
      const activeTab = target.getAttribute('content-id');
      this.$emit('update:active', activeTab);
      if (activeTab === BrandCatalogView.Products) {
        Analytics.track(
          new UserClick({
            component: 'brand_page_reorder',
            action: 'click_all_products',
            id_brand: this.brand.id,
            id_retailer: this.retailer.id,
          })
        );
      } else if (activeTab === BrandCatalogView.Collections) {
        Analytics.track(
          new UserClick({
            component: 'brand-catalog-tabs',
            action: 'view_collection_tab',
            id_brand: this.brand.id,
          })
        );
      } else if (activeTab === BrandCatalogView.Ordered) {
        Analytics.track(
          new UserClick({
            component: 'brand_page_reorder',
            action: 'click_buy_again',
            id_brand: this.brand.id,
            id_retailer: this.retailer.id,
          })
        );
      }
    },
  },
});
</script>
<style lang="scss">
.brand-catalog-tabs {
  @apply ds-px-4 md:ds-px-5 xl:ds-px-6;
}
</style>
