import Dinero, { DineroObject } from 'dinero.js';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export const convertPriceMixins = defineComponent({
  name: 'ConvertPriceMixins',
  computed: {
    ...mapGetters(['getCurrencyRate']),
  },
  methods: {
    async getConvertedPrice(price): Promise<DineroObject> {
      const dinero =
        this.$currency === price.currency
          ? Dinero(price)
          : await Dinero(price).convert(this.$currency, {
              endpoint: Promise.resolve(this.getCurrencyRate(price.currency, this.$currency)),
            });
      return dinero.toObject();
    },
  },
});
