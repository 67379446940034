import { OriginProperty } from '@monolith/legacy/services/analytics/properties/origin-property';
import { Brand } from './api/brand';
import { Retailer } from './api/retailer';

export interface ConversationMember {
  id: number;
  type: 'conversation_member';
  entity: Brand | Retailer;
}

export enum ConversationTypeMember {
  brand = 'brand',
  retailer = 'retailer',
}

export interface Links {
  next: string | null;
}

export interface Messages {
  edges: ConversationMessageData[];
  links: Links;
  meta: Record<string, any>;
}

export interface ConversationMessageData {
  data: ConversationMessage;
}

export interface ConversationMessageAttachment {
  name: string;
  url: string;
  type: string;
  is_removable: boolean;
}

export interface ConversationMessageAttachments {
  data: Array<ConversationMessageAttachment>;
}

export interface ConversationMessage {
  id: number;
  date: Date;
  content: string;
  author: ConversationMember;
  origin: OriginProperty;
  attachments: ConversationMessageAttachments;
}

export interface Conversation {
  id: number;
  sender: ConversationMember;
  recipient: ConversationMember;
  type: 'conversation';
  messages: Messages;
  unread_messages_count: number;
}

export interface Recipient {
  id: number;
  name: string;
}

export interface UploadedFile {
  name: string;
  type: string;
  path: string;
  url: string;
}

export interface UploadedFiles {
  [index: number]: UploadedFile;
}

export default interface Conversations {
  [index: number]: Conversation;
}

export interface RecipientOption {
  label: string;
  value: number;
}
