<template>
  <section
    v-if="items.length > 0"
    class="section-proof-line"
  >
    <SocialProofElement
      v-for="(item, index) in items"
      :key="index"
      :item="item"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SocialProofElement from '@monolith/legacy/components/home/social-proof-element.vue';

export default defineComponent({
  name: 'SocialProofLine',
  components: { SocialProofElement },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
});
</script>

<style lang="scss">
.section-proof-line {
  @apply ds-flex ds-justify-center;
}
</style>
