/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Failure,
  FulfillmentCreateBrandBillingCategoryEnum,
  FulfillmentCreateBrandData,
  FulfillmentCreateBrandPalletCarrierEnum,
  FulfillmentCreateBrandParcelCarrierEnum,
  FulfillmentCreateItemData,
  FulfillmentCreateOrderDetailEnum,
  FulfillmentCreateOrderParams,
  FulfillmentCreateOrderRecipientTypeEnum,
  FulfillmentCreateReplenishmentData,
  FulfillmentCreateReplenishmentShipmentTypeEnum,
  FulfillmentGetWarehouseData,
  FulfillmentItemGroupedQuantityAdjustmentsData,
  FulfillmentItemGroupedQuantityAdjustmentsParams,
  FulfillmentListBundlesData,
  FulfillmentListBundlesParams,
  FulfillmentListGroupedQuantityAdjustmentsRelationshipsData,
  FulfillmentListGroupedQuantityAdjustmentsRelationshipsParams,
  FulfillmentListItemLotsParams,
  FulfillmentListItemsData,
  FulfillmentListItemsParams,
  FulfillmentListLotsRelationshipsData,
  FulfillmentListLotsRelationshipsParams,
  FulfillmentListOrdersData,
  FulfillmentListOrdersParams,
  FulfillmentListReplenishmentsData,
  FulfillmentListReplenishmentsParams,
  FulfillmentListWarehousesData,
  FulfillmentPatchReplenishmentShipmentTypeEnum,
  FulfillmentPatchReplenishmentStatusEnum,
  FulfillmentPatchReplenishmentStatusEnum1,
  InternalFulfillmentGetOrderData,
  InternalFulfillmentGetOrderParams,
  InternalFulfillmentListLotsData,
  InternalFulfillmentListLotsParams,
  Jsonapi,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Fulfillment<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Returns a list of fulfillable bundles
   *
   * @tags Fulfillment
   * @name FulfillmentListBundles
   * @summary Fulfillment bundles
   * @request GET:/api/internal/v1/fulfillment/bundles
   * @response `200` `FulfillmentListBundlesData` Set of fulfillable bundles
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `500` `Failure`
   */
  fulfillmentListBundles = (query: FulfillmentListBundlesParams, params: RequestParams = {}) =>
    this.request<FulfillmentListBundlesData, Failure>({
      path: `/api/internal/v1/fulfillment/bundles`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * @description Returns a list of lots
   *
   * @tags Fulfillment
   * @name InternalFulfillmentListLots
   * @summary List lots in the warehouse
   * @request GET:/api/internal/v1/fulfillment/lots
   * @response `200` `InternalFulfillmentListLotsData` Set of fulfillment lots
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `500` `Failure`
   */
  internalFulfillmentListLots = (query: InternalFulfillmentListLotsParams, params: RequestParams = {}) =>
    this.request<InternalFulfillmentListLotsData, Failure>({
      path: `/api/internal/v1/fulfillment/lots`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * @description Returns a list of orders being or having been fulfilled
   *
   * @tags Fulfillment
   * @name FulfillmentListOrders
   * @summary List of fulfillment orders for the currently authenticated brand
   * @request GET:/api/internal/v1/fulfillment/orders
   * @response `200` `FulfillmentListOrdersData` Set of fulfillment orders
   * @response `400` `Failure`
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `500` `Failure`
   */
  fulfillmentListOrders = (query: FulfillmentListOrdersParams, params: RequestParams = {}) =>
    this.request<FulfillmentListOrdersData, Failure>({
      path: `/api/internal/v1/fulfillment/orders`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
 * @description Creates a new fulfillment order
 *
 * @tags Fulfillment
 * @name FulfillmentCreateOrder
 * @summary Create Fulfillment Order
 * @request POST:/api/internal/v1/fulfillment/orders
 * @response `200` `void` This status is sent when validation is requested and succeeded
 * @response `201` `void` This status is sent when order is successfuly created
 * @response `400` `{
  \** An object describing the server's implementation *\
    jsonapi?: Jsonapi,
    errors?: ({
    detail?: FulfillmentCreateOrderDetailEnum,
  \** The HTTP status code applicable to this problem, expressed as a string value. *\
    status?: string,
  \** General description of the problem *\
    title?: string,
    meta?: {
    undeclaredItems?: (string)[],
    unavailableItems?: (string)[],
    [key: string]: any,

},

})[],

}` Fulfillment order creation error response
 * @response `401` `Failure`
 * @response `403` `Failure`
 * @response `406` `Failure`
 * @response `415` `Failure`
 * @response `500` `Failure`
 */
  fulfillmentCreateOrder = (
    query: FulfillmentCreateOrderParams,
    data: {
      reference?: string;
      /** Ankorstore order ID, or null for direct fulfillment order */
      orderId?: number | null;
      /**
       * Ankorstore master order uuid
       * @format uuid
       */
      masterOrderUuid: string;
      /** @format uuid */
      fulfillmentBrandId?: string;
      /** Is shipping fee charged for order, default to true */
      shippingCharged?: boolean;
      /** Shipping address for the recipient. Required for order creation */
      shippingAddress: any;
      /** Optional billing address for the recipient */
      billingAddress?: {
        /** @minLength 1 */
        firstName?: string;
        /** @minLength 1 */
        lastName?: string;
        company?: string;
        /** @minLength 1 */
        phone?: string;
        email?: string;
        /** @minLength 1 */
        street?: string;
        /** @minLength 1 */
        city?: string;
        postalCode?: string;
        /** @pattern ^[A-Z]{2}$ */
        country?: string;
      } | null;
      items: (
        | {
            /** @format uuid */
            fulfillableId: string;
            /** quantity in batches */
            batchQuantity: number;
          }
        | {
            /** @format uuid */
            fulfillableId: string;
            /** quantity in units */
            unitQuantity: number;
          }
        | {
            /** @format uuid */
            fulfillableId: string;
            /** quantity in batches */
            quantity: number;
          }
      )[];
      /**
       * The type of recipient for a fulfillment order
       * @default "business"
       */
      recipientType?: FulfillmentCreateOrderRecipientTypeEnum;
    },
    params: RequestParams = {}
  ) =>
    this.request<
      void,
      | {
          /** An object describing the server's implementation */
          jsonapi?: Jsonapi;
          errors?: {
            detail?: FulfillmentCreateOrderDetailEnum;
            /** The HTTP status code applicable to this problem, expressed as a string value. */
            status?: string;
            /** General description of the problem */
            title?: string;
            meta?: {
              undeclaredItems?: string[];
              unavailableItems?: string[];
              [key: string]: any;
            };
          }[];
        }
      | Failure
    >({
      path: `/api/internal/v1/fulfillment/orders`,
      method: 'POST',
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Returns details of a single fulfillment order
   *
   * @tags Fulfillment
   * @name InternalFulfillmentGetOrder
   * @summary Get Fulfillment Order
   * @request GET:/api/internal/v1/fulfillment/orders/{id}
   * @response `200` `InternalFulfillmentGetOrderData` Single fulfillment order
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `404` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `500` `Failure`
   */
  internalFulfillmentGetOrder = ({ id, ...query }: InternalFulfillmentGetOrderParams, params: RequestParams = {}) =>
    this.request<InternalFulfillmentGetOrderData, Failure>({
      path: `/api/internal/v1/fulfillment/orders/${id}`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * @description Returns a list of declared replenishments
   *
   * @tags Fulfillment
   * @name FulfillmentListReplenishments
   * @summary List Brand Replenishments
   * @request GET:/api/internal/v1/fulfillment/replenishments
   * @response `200` `FulfillmentListReplenishmentsData` Set of replenishments
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `500` `Failure`
   */
  fulfillmentListReplenishments = (query: FulfillmentListReplenishmentsParams, params: RequestParams = {}) =>
    this.request<FulfillmentListReplenishmentsData, Failure>({
      path: `/api/internal/v1/fulfillment/replenishments`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * @description Creates a new replenishment
   *
   * @tags Fulfillment
   * @name FulfillmentCreateReplenishment
   * @summary Create Replenishment
   * @request POST:/api/internal/v1/fulfillment/replenishments
   * @response `200` `FulfillmentCreateReplenishmentData` Single replenishment
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `500` `Failure`
   */
  fulfillmentCreateReplenishment = (
    data: {
      /**
       * deprecated, use fulfillmentBrandId instead
       * @deprecated
       */
      brandId?: number;
      /** @format uuid */
      fulfillmentBrandId?: string;
      /** @format uuid */
      warehouseId: string;
      shippingCarrierName: string;
      shipmentType: FulfillmentCreateReplenishmentShipmentTypeEnum;
      items: any[];
    } & {
      /** @format uuid */
      id?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<FulfillmentCreateReplenishmentData, Failure>({
      path: `/api/internal/v1/fulfillment/replenishments`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Returns details of a single replenishment
   *
   * @tags Fulfillment
   * @name FulfillmentGetReplenishment
   * @summary Details of a single replenishment
   * @request GET:/api/internal/v1/fulfillment/replenishments/{id}
   * @response `200` `void`
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `404` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `500` `Failure`
   */
  fulfillmentGetReplenishment = (id: string, params: RequestParams = {}) =>
    this.request<void, Failure>({
      path: `/api/internal/v1/fulfillment/replenishments/${id}`,
      method: 'GET',
      ...params,
    });
  /**
   * @description Update a replenishment
   *
   * @tags Fulfillment
   * @name FulfillmentPatchReplenishment
   * @summary Update a replenishment
   * @request PATCH:/api/internal/v1/fulfillment/replenishments/{id}
   * @response `200` `void`
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `404` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `422` `Failure`
   * @response `500` `Failure`
   */
  fulfillmentPatchReplenishment = (
    id: string,
    data: {
      data?: {
        shippingCarrierName?: string;
        shipmentType?: FulfillmentPatchReplenishmentShipmentTypeEnum;
        status?: FulfillmentPatchReplenishmentStatusEnum;
        items?: {
          /** @format uuid */
          fulfillmentItemId: string;
          quantity: number;
          status?: FulfillmentPatchReplenishmentStatusEnum1;
        }[];
      };
    },
    params: RequestParams = {}
  ) =>
    this.request<void, Failure>({
      path: `/api/internal/v1/fulfillment/replenishments/${id}`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
 * @description Delete a draft replenishment
 *
 * @tags Fulfillment
 * @name FulfillmentDeleteDraftReplenishment
 * @summary Delete a draft replenishment
 * @request DELETE:/api/internal/v1/fulfillment/replenishments/{id}
 * @response `200` `{
  \** An object describing the server's implementation *\
    jsonapi?: Jsonapi,
    data?: null,

}`
 * @response `401` `Failure`
 * @response `403` `Failure`
 * @response `404` `Failure`
 * @response `406` `Failure`
 * @response `415` `Failure`
 * @response `422` `Failure`
 * @response `500` `Failure`
 */
  fulfillmentDeleteDraftReplenishment = (id: string, params: RequestParams = {}) =>
    this.request<
      {
        /** An object describing the server's implementation */
        jsonapi?: Jsonapi;
        data?: null;
      },
      Failure
    >({
      path: `/api/internal/v1/fulfillment/replenishments/${id}`,
      method: 'DELETE',
      ...params,
    });
  /**
   * @description Returns a list of warehouses available to provide fulfillment services
   *
   * @tags Fulfillment
   * @name FulfillmentListWarehouses
   * @summary List of warehouses available to handle fulfillment
   * @request GET:/api/internal/v1/fulfillment/warehouses
   * @response `200` `FulfillmentListWarehousesData` Set of warehouses
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `500` `Failure`
   */
  fulfillmentListWarehouses = (params: RequestParams = {}) =>
    this.request<FulfillmentListWarehousesData, Failure>({
      path: `/api/internal/v1/fulfillment/warehouses`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Returns details of a warehouse
   *
   * @tags Fulfillment
   * @name FulfillmentGetWarehouse
   * @summary Details of a single warehouse
   * @request GET:/api/internal/v1/fulfillment/warehouses/{id}
   * @response `200` `FulfillmentGetWarehouseData` Single warehouse
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `500` `Failure`
   */
  fulfillmentGetWarehouse = (id: string, params: RequestParams = {}) =>
    this.request<FulfillmentGetWarehouseData, Failure>({
      path: `/api/internal/v1/fulfillment/warehouses/${id}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Returns a list of fulfillment items
   *
   * @tags Fulfillment
   * @name FulfillmentListItems
   * @summary Returns list of items being prepared or available for fulfillment for the current authenticated Brand
   * @request GET:/api/internal/v1/fulfillment/items
   * @response `200` `FulfillmentListItemsData` Set of fulfillment items
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `500` `Failure`
   */
  fulfillmentListItems = (query: FulfillmentListItemsParams, params: RequestParams = {}) =>
    this.request<FulfillmentListItemsData, Failure>({
      path: `/api/internal/v1/fulfillment/items`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * @description Create an fulfillment item
   *
   * @tags Fulfillment
   * @name FulfillmentCreateItem
   * @summary Create an fulfillment item
   * @request POST:/api/internal/v1/fulfillment/items
   * @response `200` `FulfillmentCreateItemData` Single fulfillment item
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `422` `Failure`
   * @response `500` `Failure`
   */
  fulfillmentCreateItem = (
    data: {
      /** FulfillmentItem */
      data: {
        /** @format uuid */
        productVariantId: string;
        /** @pattern ^\d{13,14}$ */
        barcode: string;
        unitsPerBatch: number;
        /**
         * @deprecated
         * @format float
         */
        unitCostMargin?: number;
        /** @format float */
        unitCost: number;
        sku: string;
        productName: string;
        categoryName: string;
        classificationCode?: string;
        /** @default false */
        expiryTracked?: boolean;
        shelfLife?: number | null;
        /** @default false */
        lotTracked?: boolean;
        /**
         * Whether the item is considered fragile
         * @default false
         */
        isFragile?: boolean;
        /**
         * Whether the item is considered organic
         * @default false
         */
        isOrganic?: boolean;
        /**
         * Whether the item needs to be repacked before shipping
         * @default false
         */
        needRepack?: boolean;
        /**
         * Whether the item needs to be relabeled before shipping
         * @default false
         */
        needRelabel?: boolean;
        /** Code for alcohol classification, as used in the warehouse */
        alcoholCode?: number;
        /**
         * Percentage of alcohol in the item
         * @format float
         */
        alcoholPercentage?: number;
        /**
         * Volume of liquid per pack in liter
         * @format float
         */
        volumeOfLiquidPerPack?: number;
        /** Installation Classified for the Protection of the Environment, as used in the warehouse */
        icpeCode?: number;
        availableQuantity?: number;
      };
    },
    params: RequestParams = {}
  ) =>
    this.request<FulfillmentCreateItemData, Failure>({
      path: `/api/internal/v1/fulfillment/items`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Returns a fulfillment item created for the authenticated brand
   *
   * @tags Fulfillment
   * @name FulfillmentGetItem
   * @summary Details of a single Fulfillment item
   * @request GET:/api/internal/v1/fulfillment/items/{id}
   * @response `200` `void`
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `404` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `500` `Failure`
   */
  fulfillmentGetItem = (id: string, params: RequestParams = {}) =>
    this.request<void, Failure>({
      path: `/api/internal/v1/fulfillment/items/${id}`,
      method: 'GET',
      ...params,
    });
  /**
   * @description Returns a list of lots related to an item
   *
   * @tags Fulfillment
   * @name FulfillmentListItemLots
   * @summary Returns related lots
   * @request GET:/api/internal/v1/fulfillment/items/{id}/lots
   * @response `200` `void`
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `404` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `500` `Failure`
   */
  fulfillmentListItemLots = ({ id, ...query }: FulfillmentListItemLotsParams, params: RequestParams = {}) =>
    this.request<void, Failure>({
      path: `/api/internal/v1/fulfillment/items/${id}/lots`,
      method: 'GET',
      query: query,
      ...params,
    });
  /**
   * @description Returns a list of lots related to an item
   *
   * @tags Fulfillment
   * @name FulfillmentListLotsRelationships
   * @summary Returns lots
   * @request GET:/api/internal/v1/fulfillment/items/{id}/relationships/lots
   * @response `200` `FulfillmentListLotsRelationshipsData` Set of fulfillment lots id and links
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `404` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `500` `Failure`
   */
  fulfillmentListLotsRelationships = ({ id, ...query }: FulfillmentListLotsRelationshipsParams, params: RequestParams = {}) =>
    this.request<FulfillmentListLotsRelationshipsData, Failure>({
      path: `/api/internal/v1/fulfillment/items/${id}/relationships/lots`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * @description Returns a list of quantity adjustments grouped by fulfillmentItemId, reasonCode and either replenishmentUuid or fulfillmentOrderUuid
   *
   * @tags Fulfillment
   * @name FulfillmentItemGroupedQuantityAdjustments
   * @summary Returns grouped quantity adjustments for item
   * @request GET:/api/internal/v1/fulfillment/items/{id}/grouped-quantity-adjustments
   * @response `200` `FulfillmentItemGroupedQuantityAdjustmentsData` Set of fulfillment quantity adjustments grouped by fulfillmentItemId, reasonCode and either replenishmentUuid or fulfillmentOrderUuid
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `404` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `500` `Failure`
   */
  fulfillmentItemGroupedQuantityAdjustments = (
    { id, ...query }: FulfillmentItemGroupedQuantityAdjustmentsParams,
    params: RequestParams = {}
  ) =>
    this.request<FulfillmentItemGroupedQuantityAdjustmentsData, Failure>({
      path: `/api/internal/v1/fulfillment/items/${id}/grouped-quantity-adjustments`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * @description Returns a list of quantity adjustments related to an item, grouped by fulfillmentItemId, reasonCode and either replenishmentUuid or fulfillmentOrderUuid
   *
   * @tags Fulfillment
   * @name FulfillmentListGroupedQuantityAdjustmentsRelationships
   * @summary Returns grouped quantity adjustments
   * @request GET:/api/internal/v1/fulfillment/items/{id}/relationships/grouped-quantity-adjustments
   * @response `200` `FulfillmentListGroupedQuantityAdjustmentsRelationshipsData` Set of grouped fulfillment quantity adjustments id and links
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `404` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `500` `Failure`
   */
  fulfillmentListGroupedQuantityAdjustmentsRelationships = (
    { id, ...query }: FulfillmentListGroupedQuantityAdjustmentsRelationshipsParams,
    params: RequestParams = {}
  ) =>
    this.request<FulfillmentListGroupedQuantityAdjustmentsRelationshipsData, Failure>({
      path: `/api/internal/v1/fulfillment/items/${id}/relationships/grouped-quantity-adjustments`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * @description Returns a fulfillment brand
   *
   * @tags Fulfillment
   * @name FulfillmentGetBrand
   * @summary Details of a single Fulfillment brand
   * @request GET:/api/internal/v1/fulfillment/fulfillment-brands/{id}
   * @response `200` `void`
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `404` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `500` `Failure`
   */
  fulfillmentGetBrand = (id: string, params: RequestParams = {}) =>
    this.request<void, Failure>({
      path: `/api/internal/v1/fulfillment/fulfillment-brands/${id}`,
      method: 'GET',
      ...params,
    });
  /**
   * @description Creates a new fulfillment brand
   *
   * @tags Fulfillment
   * @name FulfillmentCreateBrand
   * @summary Create Fulfillment Brand
   * @request POST:/api/internal/v1/fulfillment/fulfillment-brands
   * @response `200` `FulfillmentCreateBrandData` Single fulfillment brand
   * @response `201` `void`
   * @response `400` `Failure`
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `404` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `500` `Failure`
   */
  fulfillmentCreateBrand = (
    data: {
      /** Ankorstore brand ID */
      brandId: number;
      brandName: string;
      /** @format uuid */
      warehouseId?: string;
      billingCategory?: FulfillmentCreateBrandBillingCategoryEnum;
      parcelCarrier?: FulfillmentCreateBrandParcelCarrierEnum;
      palletCarrier?: FulfillmentCreateBrandPalletCarrierEnum;
      maxParcelShipmentWeight?: number | null;
    },
    params: RequestParams = {}
  ) =>
    this.request<FulfillmentCreateBrandData, Failure>({
      path: `/api/internal/v1/fulfillment/fulfillment-brands`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
