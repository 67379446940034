/**
 * NOTE: This module is going to be deprecated soon in favor of the new carts and checkout modules.
 * @deprecated
 */
import { CartBrand, Cart, Checkout, NeededInformations } from '@monolith/legacy/types/cart';
import { FeatureFlag, isEnabled } from '@monolith/legacy/services/features';
import { CartState } from '@monolith/legacy/store/cart/index';
import { BillingItem, BillingItemType } from '@monolith/legacy/types/billing-item';
import { isVariantOutOfStock } from '@monolith/legacy/services/product-variants';
import { isBillingItemShippingFee } from '@monolith/legacy/utilities/shipping/shipping-fees';

const cartGetters = {
  allCarts: (state: CartState): Cart[] => state.checkout.carts.concat(state.savedForLater),
  aboveCarts: (state: CartState): Cart[] => state.checkout.carts.filter((cart) => cart.minimum.above),
  belowCarts: (state: CartState): Cart[] => state.checkout.carts.filter((cart) => !cart.minimum.above),
  checkout: (state: CartState): Checkout => state.checkout,
  isCheckoutLoaded: (state: CartState): boolean => state.checkout?.id !== null && state.checkout?.id !== undefined,
  laterCarts: (state: CartState): Cart[] => state.savedForLater,
  getAmountLeftForFreeShipping: (state) => {
    return state.checkout.amount_left_for_free_shipping;
  },
  getPercentFreeShipping: (state, getters) => {
    return (1 - getters.getAmountLeftForFreeShipping.amount / state.checkout.free_shipping_minimum_amount.amount) * 100;
  },
  totalAmountAboveCarts: (_state: CartState, getters) => {
    return getters.aboveCarts.reduce((previousValue, cart) => {
      previousValue += cart.total_amount.amount;

      return previousValue;
    }, 0);
  },
  totalAmountWithoutDiscountAboveCarts: (_state: CartState, getters) => {
    return getters.aboveCarts.reduce((previousValue, cart) => {
      previousValue += cart.total_amount_before_discount.amount;

      return previousValue;
    }, 0);
  },
  totalAmountBelowCarts: (_state: CartState, getters) => {
    let c = 0;

    getters.belowCarts.forEach((cart) => {
      c += cart.total_amount.amount;
    });

    return c;
  },
  cartById: (state: CartState) => (id: number) => {
    return state.checkout.carts.find((card) => card.id === id) || null;
  },
  cartByBrandId: (state: CartState) => (id: number) => {
    const cart = state.checkout.carts.concat(state.savedForLater)?.find((c) => c.brand.id === id);

    if (!cart) {
      return null;
    }

    return cart;
  },
  getGlobalDiscountAmount: (state: CartState, _getters, rootState) => {
    return (
      state.checkout.billing_items.find((element) => element.billing_item_type === BillingItemType.eventDiscount)?.amount ?? {
        amount: 0,
        currency: rootState.currency,
      }
    );
  },
  getCountArticlesInCart: () => (cart: Cart) => {
    return cart.items?.reduce((previousValue, item) => {
      if (item.active && (!isVariantOutOfStock(item.option) || !isVariantOutOfStock(item.variant))) {
        previousValue += item.multiplied_quantity;
      }
      return previousValue;
    }, 0);
  },
  hasRetailerShippingFees: () => (cart: Cart) => {
    if (cart?.billing_items?.length > 0) {
      const retailerShippingFees = cart.billing_items.filter(
        ({ billing_item_type }) => billing_item_type === BillingItemType.retailerShippingFees
      );
      return retailerShippingFees?.[0]?.amount ? retailerShippingFees?.[0]?.amount.amount !== 0 : false;
    }
    return false;
  },
  retailerShippingFees: () => (cart: Cart) =>
    cart?.billing_items?.find(
      (item) => item.billing_item_type === BillingItemType.retailerShippingFees && item.amount?.amount !== 0
    ) ?? null,
  totalCountArticles: (state: CartState) => state.itemsCounts.main,
  totalCountSavedForLaterArticles: (state: CartState) => state.itemsCounts.savedForLater,
  itemByOptionId: (state: CartState) => (id: number) => {
    for (const cart of state?.checkout?.carts ?? []) {
      const item = cart?.items.find((product) => product.option?.id === id);

      if (item) {
        return item;
      }
    }

    return null;
  },
  itemByVariantId: (state: CartState) => (id: number) => {
    for (const cart of state.checkout.carts) {
      const item = cart.items.find((product) => product.variant?.id === id);

      if (item) {
        return item;
      }
    }
    return null;
  },
  isFirstInit: (state: CartState) => state.checkout.firstInit,
  isRetailerGerman: (state: CartState): boolean => state.checkout?.retailer_country?.iso_code === 'DE',
  isRetailerUK: (state: CartState): boolean => state.checkout?.retailer_country?.iso_code === 'GB',
  isBrandOpenedInUserCountry:
    (_state, _getters, _rootState, { userCountry }) =>
    (brand: CartBrand): boolean => {
      let isOpenedInUserCountry = true;

      if (brand.opened_countries) {
        isOpenedInUserCountry = brand.opened_countries?.includes(userCountry) ?? false;
      }

      return isOpenedInUserCountry;
    },
  canRetailerBeSoleTrader: (state, getters): boolean => {
    return isEnabled('sole_traders_uk')
      ? ['DE', 'GB'].includes(state.checkout?.retailer_country?.iso_code)
      : getters.isRetailerGerman;
  },
  shouldDisplaySoleTraderFormForUk: (_state: CartState, getters): boolean => isEnabled('sole_traders_uk') && getters.isRetailerUK,
  shouldDisplayCheckoutSoleTraderUk: (_state, getters, _rootState, rootGetters): boolean =>
    getters.shouldDisplaySoleTraderFormForUk && !rootGetters.hasHokodoCompany,
  hasEventMinimumReachedSent:
    (state: CartState) =>
    (id: number): boolean =>
      state.tracking.minimumReachedEventsSent.some((i) => i === id),
  isCheckoutReady: (state: CartState): boolean => state.apiCallCount === 0,
  getRetailerCountry: (state: CartState) => state.checkout.retailer_country,
  getTaxNumberErrors: (state: CartState) => state.checkoutFormErrors.taxNumber,
  hasCompanyIdErrors: (state: CartState) => state.checkoutFormErrors.companyId,
  getRecargoErrors: (state: CartState) => state.checkoutFormErrors.recargo,
  getEori: (state: CartState) => state.eori,
  getEoriErrors: (state: CartState) => state.checkoutFormErrors.eori,
  getEoriSuccess: (state: CartState) => state.checkoutFormSuccess.eori,
  hasErrors: (state: CartState, _getters, _rootState, rootGetters): boolean => {
    const checkoutFormErrors = [
      ...state.checkoutFormErrors.taxNumber,
      ...state.checkoutFormErrors.recargo,
      ...state.checkoutFormErrors.eori,
    ];
    return (
      checkoutFormErrors.length > 0 ||
      state.checkoutFormErrors.companyId ||
      Object.keys(rootGetters['account/soleTrader/soleTraderDetailsErrors']).length > 0
    );
  },
  isCartDisabled: (state: CartState): boolean => !state.checkout.total_amount,
  getNeededInformations: (state: CartState): NeededInformations => state.businessValidationStep,
  getTaxNumber: (state: CartState) => state.taxNumber,
  hasSelectedHokodoCompany: (state: CartState): boolean => state.hokodoCompany && state.hokodoCompany !== 'none',
  getVatExemption: (state: CartState): boolean => state.taxNumber.vatExemption,
  getIsCartButtonLoading: (state: CartState): boolean => state.isCartButtonLoading,
  isCartLoading: (state: CartState) => state.isCartLoading,
  getUpdates: (state: CartState) => state.bulkUpdates,
  isTotalsLoading: (state: CartState) => state.isTotalsLoading,
  hasCheckoutRecargo: (state: CartState) => state.checkout.extra_taxes?.recargo_de_equivalencia,
  shouldShowRecargoQuestion: (_state: CartState, _getters, _rootState, rootGetters) =>
    rootGetters['account/business/shouldShowRecargoQuestion'],
  shouldShowEoriQuestion: (_state: CartState, getters, _rootState, rootGetters) => {
    const ffEnabled = isEnabled(FeatureFlag.MandatoryEori);
    const hasEori = rootGetters['account/business/getEoriNumber']?.length > 0;
    const hasRestrictedCarts = getters.aboveCarts.some(({ shipping_is_restricted_by_eori }) => shipping_is_restricted_by_eori);

    return ffEnabled && !hasEori && hasRestrictedCarts;
  },
  lastPromocodeCallResult: (state: CartState) => state.lastPromocodeCallResult,
  billingItems: (_: CartState, getters): BillingItem[] =>
    getters.aboveCarts.flatMap((cart) => cart.billing_items).concat(getters.checkout?.billing_items),
  discountsBillingItems: (_: CartState, getters): BillingItem[] =>
    getters.billingItems.filter((item: BillingItem) => item?.amount?.amount < 0),
  shippingBillingItems: (_: CartState, getters): BillingItem[] =>
    getters.billingItems.filter((item: BillingItem) => isBillingItemShippingFee(item)),
  checkoutStartLoadingTime: (state: CartState): number => state.checkoutStartLoadingTime,
  getPersonalDiscounts: (state: CartState) => state.brandsDiscounts,
  savedForLater: (state: CartState) => state.savedForLater,
  retailerGrandTotalAmountWithVat: (state: CartState) => state.checkout.retailer_grand_total_amount_with_vat,
};

export default cartGetters;
export type CartGetters = typeof cartGetters;
