import { AxiosRequestConfig } from 'axios';
import {
  FulfillableBatchesSpecification,
  FulfillmentOrder,
  GetFulfillmentItemsResponse,
  GetFulfillmentOrdersResponse,
  JsonApiResponse,
  PaginatedParams,
  PostFulfillmentOrderPayload,
  PostNonAnkorstoreFulfilledOrderPayload,
} from '@monolith/legacy/types/fulfillment';
import http from '@monolith/legacy/services/api/http';
import { deserialize } from '@monolith/legacy/services/utils/jsonapi-parser';
import { get, post, UndeclaredFulfillmentItemsError } from '@monolith/legacy/services/fulfillment/utilities';
import { FeatureFlag, isEnabled } from '@monolith/legacy/services/features';
import { Fulfillment } from '@ankorstore/api-internal/Fulfillment';
import { SortEnum2 } from '@ankorstore/api-internal/data-contracts';

export interface GetFulfillmentOrdersParams extends PaginatedParams {
  filter?: {
    /** Request only direct orders (true) or only Ankorstore-related orders (false)*/
    isDirect?: boolean;
    /** Request a specific status only */
    status?: boolean;
    /** Request a specific subset of order UUIDs only */
    uuid?: string[];
  };

  sort?: SortEnum2;
}

export const getFulfillmentOrders = async (
  { offset, limit, filter = { isDirect: true }, sort = null }: GetFulfillmentOrdersParams,
  config: AxiosRequestConfig = {}
): Promise<GetFulfillmentOrdersResponse> => {
  const url = '/api/internal/v1/fulfillment/orders';
  const params = {
    'page[limit]': limit,
    'page[offset]': offset,
    ...(filter && { 'filter[isDirect]': filter.isDirect }),
    ...(filter && { 'filter[uuid]': filter.uuid }),
    ...(sort && { sort }),
  };
  let deserialisedResponse: GetFulfillmentOrdersResponse;

  if (isEnabled(FeatureFlag.TSClientFulfillmentOrders)) {
    const fulfillmentService = new Fulfillment();
    const response = await fulfillmentService.fulfillmentListOrders(params);
    deserialisedResponse = deserialize(response);
  } else {
    const response = await http().get<GetFulfillmentItemsResponse>(url, {
      params,
      ...config,
    });

    deserialisedResponse = deserialize(response.data);
  }

  return deserialisedResponse;
};

export const postFulfillmentOrder = async (
  payload: PostFulfillmentOrderPayload | PostNonAnkorstoreFulfilledOrderPayload
): Promise<FulfillmentOrder> => {
  return post('/api/v1/external-orders/non-ankorstore-fulfillment-orders', payload);
};

/** Produces a response that can indicate whether the order can be fulfilled or not. Will not create the order. */
export const validateFulfillmentOrder = async (payload: PostFulfillmentOrderPayload): Promise<FulfillmentOrder> => {
  const hasQuantity = ({ unitQuantity }: FulfillableBatchesSpecification) => unitQuantity > 0;

  const hasFulfillableIdsForItems = payload.items.filter(hasQuantity).every((item) => typeof item.fulfillableId === 'string');

  if (!hasFulfillableIdsForItems) {
    // If no fulfillableIds, then some/all items can be assumed to be undeclared. No need to validate with API.
    throw new UndeclaredFulfillmentItemsError();
  }

  let response: FulfillmentOrder;

  const modifiedPayload = {
    ...payload,
    validateOrder: true,
    /** Orders that have been removed completely from the order should not be taken into consideration. */
    items: payload.items.filter(hasQuantity),
  };

  if (isEnabled(FeatureFlag.TSClientFulfillmentOrders)) {
    const fulfillmentService = new Fulfillment();
    response = ((await fulfillmentService.fulfillmentCreateOrder(undefined, modifiedPayload)) as any)?.data?.attributes;
  } else {
    response = await post('/api/internal/v1/fulfillment/orders', modifiedPayload);
  }

  return response;
};

export const getIsFulfillmentOrderFulfillable = async (uuid: string): Promise<null> =>
  get(`/api/v1/orders/${uuid}/is-fulfillable`);

export const getFulfillmentOrder = async (id: string): Promise<JsonApiResponse<FulfillmentOrder>> => {
  let deserialisedResponse: JsonApiResponse<FulfillmentOrder>;

  if (isEnabled(FeatureFlag.TSClientFulfillmentOrders)) {
    const fulfillmentService = new Fulfillment();
    const response = await fulfillmentService.internalFulfillmentGetOrder({ id });
    deserialisedResponse = deserialize(response);
  } else {
    const res = await http().get(`/api/v1/fulfillment/orders/${id}?include=shipments`);
    deserialisedResponse = deserialize(res.data);
  }

  return deserialisedResponse;
};

export const getFulfillmentOrdersByMasterOrderUuid = async (
  masterOrderUuid: string,
  config: AxiosRequestConfig = {}
): Promise<GetFulfillmentOrdersResponse> => {
  const url = `/api/v1/master-orders/${masterOrderUuid}/fulfillment-orders`;

  const response = await http().get<GetFulfillmentOrdersResponse>(url, {
    ...config,
  });

  return deserialize(response.data) as GetFulfillmentOrdersResponse;
};
