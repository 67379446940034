<template>
  <div>
    <AKPassword
      :state="state"
      :is-revamp="isRevamp"
      @change="passwordChanged"
      @input="onInput"
    />
    <span
      v-if="state[question.alias] && state[question.alias].$dirty && state[question.alias].required.$invalid"
      class="error-message"
      data-testid="questionPasswordError"
    >{{ $t('This field is required') }}</span>
    <span
      v-else-if="state[question.alias] && state[question.alias].$dirty && state[question.alias].passwordMinlength.$invalid"
      class="error-message"
      data-testid="questionPasswordError"
    >{{ $t('Password must have at least 8 chars') }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AKPassword from '@monolith/legacy/components/sign-up/common/ak-password.vue';
import { Question } from '@monolith/legacy/types/question';

export default defineComponent({
  name: 'QuestionPassword',
  components: {
    AKPassword,
  },
  props: {
    question: {
      type: Object as () => Question,
      required: true,
    },
    state: {
      type: Object,
      required: true,
    },
    isRevamp: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['change', 'input'],
  methods: {
    passwordChanged(value): void {
      this.$emit('change', this.question.alias, {
        answer_id: this.question.id,
        content: value,
      });
    },
    onInput(value): void {
      this.$emit('input', this.question.alias, {
        answer_id: this.question.id,
        content: value,
      });
    },
  },
});
</script>
