import http from '@monolith/legacy/services/api/http';

const API_FILES_URL = '/api/files';

export const MAX_FILE_SIZE_IN_MEGABYTES = 10;
export const MAX_FILE_SIZE = MAX_FILE_SIZE_IN_MEGABYTES * 1024 * 1024;

export const AcceptedTypes = {
  IMAGES: ['image/jpeg', 'image/png'],
};

const createConfig = () => ({
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export type UploadedFile = {
  key: string;
  original_name: string;
  type: string;
  url: string;
};

export type ApiFilesResponse = {
  data: UploadedFile;
};

export const uploadFile = async (file: File): Promise<UploadedFile> => {
  const config = createConfig();
  const formData = new FormData();
  formData.append('file', file);

  const response = await http().post<ApiFilesResponse>(API_FILES_URL, formData, config);
  return response.data.data;
};
