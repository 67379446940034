import { defineComponent } from 'vue';

import { captureException } from '@core/plugins/sentry';

import { EventPageContent } from '@monolith/legacy/types/prismic/event-page-touchpoint';
import { getManagedLanguages } from '@monolith/legacy/services/metas/managed-languages';
import { getGlobalConfig } from '@monolith/legacy/services/global-config';
import * as prismicClient from '@prismicio/client';

export const prismic = defineComponent({
  name: 'PrismicMixin',
  methods: {
    async getSingle(name: string, lang: string = null, fallback: string | null = null): Promise<Record<string, unknown>> {
      const document = await this.$prismic?.client?.getSingle(name, {
        lang: getManagedLanguages()[lang]?.prismic_locale ?? getGlobalConfig().language_config.prismic_locale,
      });

      if (document) {
        return Promise.resolve({
          ...document,
          data: this.deepReplace(document.data),
        });
      }

      captureException(`Prismic template not found : ${name}`);

      if (!fallback) {
        return Promise.reject(`Prismic template not found : ${name}`);
      }

      return this.getSingle(fallback);
    },
    async getContentByUid(
      type: string,
      uid: string,
      lang?: string | null,
      fallback: string | null = null,
      logToSentry = true,
      fetchLinks: string[] | null = null
    ): Promise<Record<string, unknown>> {
      const document = await this.$prismic.client.getByUID(type, uid, {
        lang: getManagedLanguages()[lang]?.prismic_locale ?? getGlobalConfig().language_config.prismic_locale,
        fetchLinks,
      });

      if (document) {
        return Promise.resolve({
          ...document,
          data: this.deepReplace(document.data),
        });
      }

      if (logToSentry) {
        captureException(`Prismic UID not found : ${uid}`);
      }

      if (!fallback) {
        return Promise.reject(`Prismic UID not found : ${uid}`);
      }

      return this.getContentByUid(type, uid, lang, fallback);
    },
    getContentById(id, lang: string) {
      return this.$prismic.client
        .getByID(id, {
          lang: getManagedLanguages()[lang]?.prismic_locale ?? getGlobalConfig().language_config.prismic_locale,
        })
        .then((document) => {
          return document;
        });
    },
    async getRelatedPromotionnalBannerByCategory(shop_url: string): Promise<EventPageContent> {
      return await this.$prismic.client.get({
        predicates: prismicClient.filter.at(`my.promotional_banner.shop_url`, shop_url),
        lang: getGlobalConfig().language_config.prismic_locale,
      });
    },
    async getRelatedSearchesByCategoryId(type: string, id: string): Promise<Record<string, unknown>> {
      return await this.$prismic.client.get({
        predicates: prismicClient.filter.at(`my.${type}.main_category_id`, id),
        lang: getGlobalConfig().language_config.prismic_locale,
      });
    },
    deepReplace(data: object[] | object) {
      if (Array.isArray(data)) {
        return data.map((item) => this.deepReplace(item));
      }

      if (typeof data === 'object') {
        return Object.fromEntries(
          Object.entries(data).map(([key, value]) => {
            switch (typeof value) {
              case 'string':
                return [key, this.replacePlaceHolder(value)];
              case 'object':
                return [key, value ? this.deepReplace(value) : value];
              default:
                return [key, value];
            }
          })
        );
      }
      return data;
    },
    replacePlaceHolder(value): string {
      if (value === '' || value === undefined) {
        return '';
      }

      if (value.indexOf('{{minimumOrder}}') != -1 && getGlobalConfig().minimum_order_amount) {
        value = value.replace(/{{minimumOrder}}/g, this.$root.formatPrice(getGlobalConfig().minimum_order_amount, '$0'));
      }

      if (value.indexOf('{{freeShippingAmount}}') != -1 && getGlobalConfig().free_shipping_minimum_amount) {
        value = value.replace(
          /{{freeShippingAmount}}/g,
          this.$root.formatPrice(getGlobalConfig().free_shipping_minimum_amount, '$0')
        );
      }

      if (value.indexOf('{{liftCodeAmount}}') != -1 && this.$store.state.brand?.lift_promocode?.amount !== undefined) {
        value = value.replace(/{{liftCodeAmount}}/g, this.$root.formatPrice(this.$store.state.brand.lift_promocode.amount, '$0'));
      }

      if (value.indexOf('{{liftCodeMinCheckout}}') != -1 && this.$store.state.brand?.lift_promocode !== undefined) {
        value = value.replace(
          /{{liftCodeMinCheckout}}/g,
          this.$root.formatPrice(this.$store.state.brand.lift_promocode.minimum_checkout, '$0')
        );
      }

      if (value.indexOf('{{liftCode}}') != -1 && this.$store.state.brand?.lift_promocode !== undefined) {
        value = value.replace(/{{liftCode}}/g, this.$store.state.brand.lift_promocode.code);
      }

      if (value.indexOf('{{liftCodeSpecific}}') != -1 && this.$store.state.brand?.lift_promocode !== undefined) {
        value = value.replace(
          /{{liftCodeSpecific}}/g,
          '<span class="text-nowrap">' + this.$store.state.brand.lift_promocode.code + '</span>'
        );
      }

      if (value.indexOf('{{brandName}}') != -1 && this.$store.state.brand?.name) {
        value = value.replace(/{{brandName}}/g, this.$store.state.brand.name);
      }

      if (value.indexOf('{{userEmail}}') != -1 && this.$store.state.user?.email) {
        value = value.replace(/{{userEmail}}/g, this.$store.state.user.email);
      }

      if (value.indexOf('{{userFirstName}}') != -1 && this.$store.state.user?.first_name) {
        value = value.replace(/{{userFirstName}}/g, this.$store.state.user.first_name);
      }

      if (value.indexOf('{{userLastName}}') != -1 && this.$store.state.user?.last_name) {
        value = value.replace(/{{userLastName}}/g, this.$store.state.user.last_name);
      }

      if (value.indexOf('{{brandUrl}}') != -1 && this.$store.state.brand?.link) {
        value = value.replace(/{{brandUrl}}/g, this.$store.state.brand.link);
      }

      if (value.indexOf('{{promotionCoupon}}') != -1 && this.codeFirstOrder) {
        value = value.replace(/{{promotionCoupon}}/g, this.codeFirstOrder);
      }

      if (value.indexOf('{{liftCodeBrandWidgetV2}}') != -1 && this.$store.state.brand?.lift_promocode) {
        value = value.replace(
          /{{liftCodeBrandWidgetV2}}/g,
          '<span class="lift-code">' + this.$store.state.brand.lift_promocode.code + '</span>'
        );
      }

      return value;
    },
  },
});
