import AnalyticsEvent from './analytics.event';
import type { SectionType as DiscoverySectionType } from '@bc/discovery';
import type { IdPosition } from '@bc/shared';
import { SectionType } from '../properties/section_type';
import { SectionItemType } from '../properties/section_item_type';

export type SectionCreatedEventProperties = {
  section_type?: SectionType | DiscoverySectionType;
  page_number?: number;
  itemtype?: SectionItemType;
  value_proposition?: string;
  value_proposition_id?: number;
  id_product?: IdPosition[];
  id_brand?: IdPosition[];
  id_section?: string;
  filters?: unknown[];
  [key: string]: unknown;
};

export class SectionCreatedEvent extends AnalyticsEvent {
  public readonly name = 'Section Created';

  constructor(public readonly properties: SectionCreatedEventProperties) {
    super();

    if (!('nb_tiles' in this.properties)) {
      const productCount = this.properties.id_product?.length ?? 0;
      const brandCount = this.properties.id_brand?.length ?? 0;

      this.properties.nb_tiles = productCount + brandCount;
    }
  }
}
