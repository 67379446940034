<template>
  <div>
    <LabelInfo
      v-if="showComponent('LabelInfo')"
      :title="$t('What is a damaged item?')"
      :content="
        $t(
          'Any item that is unusable or impaired in some way, and is no longer sellable. The risk of a damaged item can take place during shipment.'
        )
      "
    />
    <UploadPackaging
      v-show="showComponent('UploadPackaging')"
      @set-loader="setLoader"
    />
    <ParcelReception
      v-show="showComponent('ParcelReception')"
      @set-checked="setParcelReceptionReady"
      @set-loader="setLoader"
    />
    <ParcelDetails
      v-show="showComponent('ParcelDetails')"
      @set-checked="setParcelDetailsReady"
      @set-loader="setLoader"
    />
    <ProductsCheckList v-show="showComponent('ProductsCheckList')" />
    <ProductsList
      v-if="getSelectedProducts.length"
      v-show="showComponent('ProductsList')"
      ref="productsListRef"
      :is-active="showComponent('ProductsList')"
      with-attachments
      with-description
      @set-loader="setLoader"
      @products-validation="productsDataChanged"
      @all-products-removed="backToCheckList"
    />
    <CaseActions
      :next-step-ready="nextStepReady()"
      :next-button-click="nextButtonClick()"
      :show-back-button="showBackButton"
      @set-loader="setLoader"
      @submit="$emit('submit')"
    />
  </div>
</template>

<script lang="ts">
import BaseItemsCase from '@monolith/legacy/components/account/orders/order/order-retailer-reject/cases/base-items-case.vue';
import LabelInfo from '@monolith/legacy/components/account/orders/order/order-retailer-reject/components/label-info.vue';
import UploadPackaging from '@monolith/legacy/components/account/orders/order/order-retailer-reject/components/upload-packaging.vue';
import ProductsCheckList from '@monolith/legacy/components/account/orders/order/order-retailer-reject/components/products-check-list.vue';
import ProductsList from '@monolith/legacy/components/account/orders/order/order-retailer-reject/components/products-list.vue';
import CaseActions from '@monolith/legacy/components/account/orders/order/order-retailer-reject/components/case-actions.vue';
import ParcelReception from '@monolith/legacy/components/account/orders/order/order-retailer-reject/components/parcel-reception.vue';
import ParcelDetails from '@monolith/legacy/components/account/orders/order/order-retailer-reject/components/parcel-details.vue';
import { CaseReasonStep } from '@monolith/legacy/types/order-retailer-rejection';
import { defineComponent } from 'vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers';

export default defineComponent({
  name: 'DamagedItems',
  components: {
    LabelInfo,
    UploadPackaging,
    ProductsList,
    ProductsCheckList,
    CaseActions,
    ParcelReception,
    ParcelDetails,
  },
  extends: BaseItemsCase,
  emits: ['submit'],
  setup() {
    const { useGetters: useShowRevampedVersionGetters } = createNamespacedHelpers('orderRetailerRejection');
    const { showRevampedVersion } = useShowRevampedVersionGetters(['showRevampedVersion']);

    return {
      showRevampedVersion,
    }
  },
  data() {
    return {
      parcelReceptionReady: false,
      parcelDetailsReady: false,
    };
  },
  methods: {
    showComponent(component: string): boolean {
      const currentKey = this.getCurrentStep.key;

      switch (component) {
        case 'TrackParcels':
          return currentKey === CaseReasonStep.TrackWithLink;
        case 'LabelInfo':
          return currentKey === CaseReasonStep.OrderInfo;
        case 'UploadPackaging':
          return !this.showRevampedVersion && (currentKey === CaseReasonStep.OrderInfo || currentKey === CaseReasonStep.Summary);
        case 'ParcelReception':
          return (
            this.showRevampedVersion && (currentKey === CaseReasonStep.ParcelReception || currentKey === CaseReasonStep.Summary)
          );
        case 'ParcelDetails':
          return (
            this.showRevampedVersion && (currentKey === CaseReasonStep.ParcelDetails || currentKey === CaseReasonStep.Summary)
          );
        case 'ProductsCheckList':
          return currentKey === CaseReasonStep.ProductsCheckList;
        case 'ProductsList':
          return currentKey === CaseReasonStep.ProductsList || currentKey === CaseReasonStep.Summary;
        default:
          return false;
      }
    },
    nextStepReady(): boolean {
      switch (this.getCurrentStep.key) {
        case CaseReasonStep.TrackWithLink:
          return true;
        case CaseReasonStep.OrderInfo:
          return !this.hasFormErrors;
        case CaseReasonStep.ParcelReception:
          return this.parcelReceptionReady;
        case CaseReasonStep.ParcelDetails:
          return this.parcelDetailsReady;
        case CaseReasonStep.ProductsCheckList:
          return this.getSelectedProducts.length > 0;
        case CaseReasonStep.ProductsList:
          return this.productsDataReady;
        case CaseReasonStep.Summary:
          return this.productsDataReady && !this.hasFormErrors && !this.loading;
        default:
          return false;
      }
    },
    setParcelReceptionReady(value: boolean): void {
      this.parcelReceptionReady = value;
    },
    setParcelDetailsReady(value: boolean): void {
      this.parcelDetailsReady = value;
    },
  },
});
</script>
