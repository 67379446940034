import { memoize } from 'lodash-es';
import { BusinessEventPeriode } from '@monolith/legacy/types/business-event';
import {
  BrandAttributes,
  Offer,
  OfferCondition,
  OfferConditionResolutionContext,
  RelativeAttributes,
  RetailerAttributes,
  SitewideOfferContent,
} from '@monolith/legacy/types/offer';
import { compare } from '@core/utilities/comparison';
import { Retailer } from '@monolith/legacy/types/api/retailer';
import { Comparator } from '@monolith/legacy/types/logic';
import { PrismicSitewideOfferPayloadSlice, SitewideOfferSliceTypes } from '@core/types/prismic';
import { CartBrand } from '@monolith/legacy/types/cart';
import Store from '@monolith/legacy/store/index';

/**
 * Defines the offer 'slice types' defined in Prismic. When we add
 * new Prismic UI that can relate to an offer (e.g. offer-badges),
 * we will need to add it here also.
 * */
const APPLICABLE_OFFER_SLICE_TYPES = [
  SitewideOfferSliceTypes.OfferBanner,
  SitewideOfferSliceTypes.OfferBadge,
  SitewideOfferSliceTypes.OfferMessage,
];

export const compareNestedAttribute = (
  attributes: RetailerAttributes[] | BrandAttributes[] | RelativeAttributes[],
  entity: Retailer | CartBrand,
  comparator: Comparator,
  value: string | number
) => {
  const attributeValue = (attributes as RetailerAttributes[] | BrandAttributes[] | RelativeAttributes[]).reduce(
    (thisEntity, attribute) => thisEntity?.[attribute],
    entity
  );

  return compare(comparator, attributeValue, value);
};

export const resolveConditions = (conditions: OfferCondition, context: OfferConditionResolutionContext) => {
  let succeededConditions = 0;
  let failedConditions = 0;

  for (const condition of conditions.conditions) {
    if (condition.conditions) {
      if (resolveConditions(condition, context)) {
        succeededConditions++;
      } else {
        failedConditions++;
      }

      continue;
    }

    if (condition.retailer_attributes?.length > 0) {
      if (
        context.retailer &&
        compareNestedAttribute(condition.retailer_attributes, context.retailer, condition.comparator, condition.value)
      ) {
        succeededConditions++;
      } else {
        failedConditions++;
      }

      continue;
    }

    if (condition.brand_attributes?.length > 0) {
      if (
        context.brand &&
        compareNestedAttribute(condition.brand_attributes, context.brand, condition.comparator, condition.value)
      ) {
        succeededConditions++;
      } else {
        failedConditions++;
      }

      continue;
    }

    if (condition.relative_attributes) {
      if (
        (context.relativeEntities?.brand || context.relativeEntities?.retailer) &&
        compareNestedAttribute(
          condition.relative_attributes.attributes,
          condition.relative_attributes.entity === 'brand' ? context.relativeEntities.brand : context.relativeEntities.retailer,
          condition.comparator,
          condition.value
        )
      ) {
        succeededConditions++;
      } else {
        failedConditions++;
      }
    }
  }

  if (conditions.operator === 'OR') {
    return succeededConditions > 0;
  } else {
    // 'AND' operator behaviour is default.
    return 0 === failedConditions;
  }
};

export const prioritiseMarketingOffers = (a: Offer) => {
  if (a.offer.context === 'structural') {
    return -1;
  }
  if (a.offer.context === 'marketing') {
    return 1;
  }
  return 0;
};

export const toOfferContent = ({
  slice_label,
  primary: { heading, body, icon_key, content, message },
}: PrismicSitewideOfferPayloadSlice) => {
  let offerContent = null;

  if (slice_label.includes(SitewideOfferSliceTypes.OfferBanner)) {
    offerContent = {
      title: heading[0].text,
      body: body[0].text,
      icon: icon_key[0].text,
    };
  }

  if (slice_label.includes(SitewideOfferSliceTypes.OfferBadge)) {
    offerContent = {
      content: content[0].text,
      icon: icon_key?.[0]?.text,
    };
  }

  if (slice_label.includes(SitewideOfferSliceTypes.OfferMessage)) {
    offerContent = {
      message: message[0].text,
    };
  }

  return [slice_label, offerContent];
};

export const toSitewideOfferContent =
  (context: OfferConditionResolutionContext) => (content: SitewideOfferContent, offer: Offer) => {
    const hasPassedCondition = offer?.offer?.conditions?.offer_conditions
      ? resolveConditions(offer?.offer?.conditions?.offer_conditions, context)
      : true;

    const components = offer?.offer?.external_cms_content?.['sitewide-offer']?.[0]?.body;

    if (!components) {
      return content;
    }

    const newContent = Object.fromEntries(
      Object.values(components)
        .filter(({ primary }) => hasPassedCondition || primary.bypass_condition)
        .filter(({ slice_type }) => APPLICABLE_OFFER_SLICE_TYPES.some((type) => slice_type.includes(type)))
        .map(toOfferContent)
    );

    return {
      ...content,
      ...newContent,
    } as SitewideOfferContent;
  };

export const parseConditions = (offer: Offer): Offer => ({
  ...offer,
  offer: {
    ...offer.offer,
    conditions: offer.offer.conditions,
  },
});

export const toOffers = ({ business_event_period_offers }: BusinessEventPeriode) =>
  business_event_period_offers.map(parseConditions);

/**
 * Returns content related to any sitewide offers that are currently ongoing. Memoized
 * response ensures that the content is only calculated once per SPA browsing session.
 * */
export const resolveSitewideOfferContent = memoize(
  async (context: OfferConditionResolutionContext): Promise<SitewideOfferContent> => {
    const events = Store.state?.offers?.events;
    return events?.flatMap(toOffers).sort(prioritiseMarketingOffers).reduce(toSitewideOfferContent(context), {});
  }
);
