<template>
  <div
    ref="productTypeTreeSelect"
    class="product-type-tree-select"
  >
    <div class="product-type-tree-select__selectedBranch">
      <div class="ds-mb-2">
        {{ $t('brand.productManagement.productTypeTree.title') }}
      </div>
      <div class="ds-flex ds-cursor-default">
        <div
          v-for="(item, index) in selectedBranch"
          :key="index"
          class="product-type-tree-select__branchNode"
        >
          <span v-if="index === 0 || index >= selectedBranch.length - 2">
            <span
              :class="isLastItem(index, selectedBranch) ? 'ds-text-neutral-700' : 'ds-underline ds-cursor-pointer'"
              class="ds-whitespace-nowrap"
              @click="onItemClick(item, index)"
            >{{ item.attributes && item.attributes.name }}</span>
            <span
              v-if="!isLastItem(index, selectedBranch)"
              class="ds-mx-1"
            >></span>
          </span>
          <span v-else-if="index === 1"><span>...</span><span class="ds-mx-1.5">></span></span>
        </div>
      </div>
    </div>
    <div
      ref="container"
      class="product-type-tree-select__container"
    >
      <ul
        v-for="(level, levelIndex) in tree"
        :key="levelIndex"
        :ref="setLevelRef"
      >
        <li
          v-for="item in level"
          :key="item.id"
          :class="{ selected: item.isHighlighted }"
          class="ds-flex ds-justify-between ds-items-center"
          @click="onItemClick(item, levelIndex)"
        >
          <div>{{ item.attributes && item.attributes.name }}</div>
          <div class="ds-flex ds-items-center">
            <AkPill
              v-if="item.attributes && item.attributes.isSelectable && item.id === value"
              content="Selected"
              color="success-700"
            />
            <div class="ds-w-5">
              <AkIcon
                v-if="item.attributes && item.attributes.children"
                symbol="chevron-right"
                size="sm"
                class="ds-float-right"
              />
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { captureException } from '@core/plugins/sentry';
import { getProductTypesTree } from '@bc/brands/feature/product-management/services/api';
import { ProductTypeTreeItem } from '@bc/brands/feature/product-management/types/product-types';
import { AkPill } from '@ankorstore/design-system';

interface TreeBranch extends ProductTypeTreeItem {
  isHighlighted?: boolean;
}

type ComponentDataType = {
  value: number;
  data: ProductTypeTreeItem[];
  selectedLevelIndex: number;
  tree: TreeBranch[][];
  levelRefs: HTMLUListElement[];
};

export default defineComponent({
  name: 'ProductTypeTreeSelect',
  components: {
    AkPill,
  },
  emits: ['change'],
  data(): ComponentDataType {
    return {
      value: null,
      data: [],
      selectedLevelIndex: 0,
      tree: [],
      levelRefs: [],
    };
  },
  computed: {
    selectedBranch(): TreeBranch[] {
      return this.tree.map((level) => level.find((item) => item.isHighlighted)).filter(Boolean);
    },
  },
  async created() {
    try {
      this.data = await getProductTypesTree();
      this.createLevel({ id: null, type: 'product-type', attributes: { name: '', isSelectable: false, children: this.data } });
    } catch (e) {
      captureException(e);
    }
  },
  beforeUpdate() {
    this.levelRefs = [];
  },
  methods: {
    setLevelRef(el: HTMLUListElement): void {
      if (el) {
        this.levelRefs.push(el);
      }
    },
    scrollUp(): void {
      const elem = (this.$refs.productTypeTreeSelect as HTMLElement).parentNode as HTMLElement;
      const parentElement = elem.parentElement;

      if (parentElement?.scrollHeight > parentElement?.clientHeight) {
        parentElement?.scrollTo({
          top: elem.offsetTop,
          behavior: 'smooth',
        });
      }
    },
    isLastItem(index: number, array: unknown[]): boolean {
      return index === array.length - 1;
    },
    slideToView(levelIndex: number): void {
      const ul = this.levelRefs[levelIndex];
      const container = this.$refs.container as HTMLElement;
      const ulLeftOffset = ul.getBoundingClientRect().left - container.getBoundingClientRect().left;
      container.style.transform = `translateX(-${ulLeftOffset}px)`;
    },
    onItemClick(item: TreeBranch, levelIndex: number): void {
      this.value = item.id;
      this.tree[levelIndex].forEach((el) => (el.isHighlighted = el.id === item.id));
      this.tree = this.tree.slice(0, levelIndex + 1);
      this.createLevel(item);
      const slideToLevelIndex = item.attributes?.children ? levelIndex : levelIndex - 1;
      this.slideToView(slideToLevelIndex);
      item.attributes?.isSelectable && this.$emit('change', this.value);
      item.attributes?.children && this.scrollUp();
    },
    createLevel(item: ProductTypeTreeItem): void {
      const newLevel = item.attributes?.children;
      if (newLevel?.length) {
        this.tree.push(JSON.parse(JSON.stringify(newLevel)));
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.product-type-tree-select {
  @apply ds-w-full ds-text-left;

  .product-type-tree-select__container {
    @apply ds-flex ds-relative ds-transition-transform ds-duration-500;
  }

  .product-type-tree-select__selectedBranch {
    @apply ds-px-3 ds-py-4 ds-bg-neutral-300 ds-text-left ds-sticky ds-top-0 ds-z-10;
  }

  .product-type-tree-select__branchNode {
    @apply ds-whitespace-nowrap;
  }

  ul {
    @apply ds-border-r ds-border-solid ds-border-neutral-300 ds-min-w-[50%];
  }

  li {
    @apply ds-p-2 ds-text-neutral-900 hover:ds-bg-neutral-300 ds-cursor-pointer focus:ds-bg-neutral-100;
  }

  li.selected {
    @apply ds-font-bold ds-bg-warm-white;
  }
}
</style>
