<template>
  <div class="ds-w-full ds-flex ds-justify-center ds-fixed ds-bottom-8 ds-z-50">
    <AkToaster
      ref="likeToaster"
      type="success"
      :duration="5000"
    >
      <div class="toaster__message">
        <AkParagraph>
          {{ $t('Added to favorites!') }}
        </AkParagraph>
        <AkButton
          outlined
          @click="handleClickAddToList"
        >
          {{ $t('Add to a list') }}
        </AkButton>
      </div>
    </AkToaster>
    <AkToaster
      ref="unLikeToaster"
      type="success"
      :show-symbol="false"
      :duration="5000"
    >
      <div class="toaster__message">
        <AkParagraph>
          {{ $t('Removed from favorites') }}
        </AkParagraph>
      </div>
    </AkToaster>
    <AkToaster
      ref="addedToListToaster"
      type="success"
      :duration="5000"
    >
      <div class="toaster__message">
        <AkParagraph>
          {{ $t('Products added to list') }}
        </AkParagraph>
        <router-link
          class="router-link"
          to="/my-list"
        >
          {{ $t('Show lists') }}
        </router-link>
      </div>
    </AkToaster>
    <AkModal
      ref="wishlistLists"
      :with-padding="false"
    >
      <WishlistLists
        :product-id="latestId"
        @close="closeModal"
      />
    </AkModal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import WishlistLists from '../popins/wishlist-lists.vue';
import Analytics from '@monolith/legacy/services/analytics';
import { UserClick } from '@monolith/legacy/services/analytics/events/user-click.event';
import { AkToaster, AkModal, AkParagraph } from '@ankorstore/design-system';

export default defineComponent({
  name: 'WishlistToasters',
  components: {
    AkModal,
    AkParagraph,
    AkToaster,
    WishlistLists,
  },
  emits: ['addToList'],
  data() {
    return {
      unsubscribe: () => {},
      latestId: null,
    };
  },
  created() {
    this.unsubscribe = this.$store.subscribeAction({
      after: (action) => {
        if (action.type === 'likeProduct') {
          this.latestId = action.payload.id;
          this.closeAll();
          this.openLikeToaster();
        }

        if (action.type === 'unLikeProduct') {
          this.latestId = action.payload.id;
          this.closeAll();
          this.openUnLikeToaster();
        }

        if (action.type === 'wishlist/movedProductToListSuccess') {
          this.closeAll();
          this.openAddToListSucessToaster();
        }
      },
    });
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  methods: {
    closeAll() {
      (this.$refs.likeToaster as DS['AkToaster']).closeToaster();
      (this.$refs.unLikeToaster as DS['AkToaster']).closeToaster();
      (this.$refs.addedToListToaster as DS['AkToaster']).closeToaster();
    },
    openLikeToaster() {
      (this.$refs.likeToaster as DS['AkToaster']).openToaster();
    },
    openUnLikeToaster() {
      (this.$refs.unLikeToaster as DS['AkToaster']).openToaster();
    },
    openAddToListSucessToaster() {
      (this.$refs.addedToListToaster as DS['AkToaster']).openToaster();
    },
    handleClickAddToList() {
      Analytics.track(new UserClick({ component: 'cta', action: 'create_list' }));

      this.$emit('addToList', { id: this.latestId });
      this.closeAll();
      (this.$refs.wishlistLists as DS['AkModal']).openModal();
    },
    closeModal() {
      (this.$refs.wishlistLists as DS['AkModal']).close();
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@css/vue-import';
.toaster {
  &__message {
    @apply ds-flex ds-justify-between ds-items-center ds-gap-2;
  }
}
p {
  margin: 0;
}
.router-link {
  text-decoration: underline;
  font-weight: normal;
  &:active,
  &:hover,
  &:focus {
    @apply ds-text-white;
  }
}
</style>
