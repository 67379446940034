import { qualificationQuestionsMocks } from '@monolith/legacy/components/sign-up/questions/mockQuestions';
import { ANKORSTART, COMPANY_SEARCH, SHOP_TYPE } from '@monolith/legacy/components/sign-up/questions/constants';
import { Question } from '@monolith/legacy/types/question';
import { required, validationsByQuestion } from '@monolith/legacy/components/sign-up/questions/validations';
import { merge, set } from 'lodash-es';
import { QualificationQuestion } from '@monolith/legacy/types/registration-funnel-questions';
import { getQualificationQuestions } from '@monolith/legacy/services/api/qualification-questions';

export const fetchQuestions = async (STEP: number, isMocked?: boolean) => {
  const { data } = await getQualificationQuestions(STEP);
  const questions = [];
  if (isMocked) {
    const nextId = getNextFreeId(data);

    qualificationQuestionsMocks.data.forEach((question, index) => {
      questions.push({ ...question, id: nextId + index });
    });
    data.forEach((question) => {
      if (question.alias === SHOP_TYPE) {
        questions.splice(3, 0, question);
      } else if (question.alias === COMPANY_SEARCH) {
        questions.splice(6, 0, question);
      } else if (question.alias === ANKORSTART) {
        questions.splice(questions.length - 1, 0, question);
      } else {
        questions.push(question);
      }
    });
  } else {
    data.forEach((question) => {
      questions.push(question);
    });
  }
  return questions;
};

export const getQuestionsValidations = (questions: (Question | QualificationQuestion)[], isRevamp?: boolean) => {
  return questions.reduce((currentQuestions, question) => {
    let questionValidation = {};

    if (question.required) {
      set(questionValidation, `${question.alias}.required`, required(question));
    }
    if (validationsByQuestion[question.question_type]) {
      questionValidation = merge(questionValidation, validationsByQuestion[question.question_type](question, isRevamp));
    }
    return {
      ...currentQuestions,
      ...questionValidation,
    };
  }, {});
};

const getNextFreeId = (questions: Question[]) => questions.reduce((max, item) => (item.id > max ? item.id : max), 0) + 1;
