<template>
  <div class="ds-relative">
    <AkInput
      v-model="value"
      :has-error="!!error"
      :errors="error"
      :data-testid="`register-${name}-input`"
      :name="name"
      autocomplete="off"
      :placeholder="placeholder"
      :label="label"
      required
      @focus="$emit('focused')"
      @focusout="$emit('focusout', $event)"
      @input="$emit('input', value)"
    />
    <div
      v-if="isLoading"
      class="ds-absolute ds-top-3 ds-right-3"
    >
      <LoaderIcon :small="true" />
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoaderIcon from '@monolith/legacy/components/loader-icon.vue';
import { AkInput } from '@ankorstore/design-system';

export default defineComponent({
  name: 'InputSearch',
  components: { LoaderIcon, AkInput },
  props: {
    model: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['input', 'focused', 'focusout'],
  data() {
    return {
      value: '',
    };
  },
  watch: {
    model(value) {
      this.value = value;
    },
  },
});
</script>
