<template>
  <div
    class="review-average-rating"
    :class="{
      'review-average-rating--compact': compact,
    }"
  >
    <div class="review-average-rating__title">
      <div class="review-average-rating__title-text">
        {{ overallRating }}
      </div>
      <AkRating
        class="review-average-rating__title-stars"
        :model-value="overallRatingValue"
        :read-only="true"
      />
    </div>
    <div class="review-average-rating__categories">
      <div
        v-for="category in categories"
        :key="category.title"
        class="review-average-rating__categories-cell"
      >
        <AkIcon
          class="review-average-rating__categories-cell__icon"
          :symbol="category.icon"
          size="md"
        />
        <div class="review-average-rating__categories-cell__criteria">
          {{ category.title }}
          <AkTooltipInformation
            class="review-average-rating__categories-cell__info-icon"
            trigger="hover"
            :placement="isMobile ? 'bottom' : 'right'"
            type="invert"
            :content="category.tooltipText"
          >
            <AkIcon
              class
              symbol="info-circle"
              size="md"
            />
          </AkTooltipInformation>
        </div>
        <div class="review-average-rating__categories-cell__criteria-value">
          {{ category.value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { AkIcon, AkTooltipInformation } from '@ankorstore/design-system';
import AkRating from '@monolith/legacy/components/review/AkRating/AkRating.vue';

import { withBreakpoints } from '@monolith/legacy/modules/design-system-candidates';
import { ReviewCriteria, ReviewRatings } from '@monolith/legacy/components/review/types';

export default defineComponent({
  name: 'ReviewAverageRating',
  components: {
    AkIcon,
    AkRating,
    AkTooltipInformation,
  },
  mixins: [withBreakpoints],
  props: {
    average: {
      type: Object as PropType<ReviewRatings>,
      required: true,
    },
    brandName: {
      type: String,
      required: true,
    },
    compact: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  computed: {
    overallRatingValue(): number {
      return parseFloat(this.overallRating);
    },
    overallRating(): string {
      return this.average[ReviewCriteria.OVERALL]?.toFixed(1) || '';
    },
    categories(): {
      title: string;
      value: string;
      icon: string;
      tooltipText: string;
    }[] {
      return [
        {
          title: this.$t('Accuracy'),
          value: this.average[ReviewCriteria.ACCURACY]?.toFixed(1),
          icon: 'award',
          tooltipText: this.$t('Were the product descriptions complete and accurate?'),
        },
        {
          title: this.$t('Product & Packaging'),
          value: this.average[ReviewCriteria.PRODUCT_AND_PACKAGING]?.toFixed(1),
          icon: 'box-seam',
          tooltipText: this.$t('Did your order arrive in good condition?'),
        },
        {
          title: this.$t('Communication'),
          value: this.average[ReviewCriteria.COMMUNICATION]?.toFixed(1),
          icon: 'chat-square-dots',
          tooltipText: this.$t('Was the {brandName} responsive and easy to communicate with?', { brandName: this.brandName }),
        },
        {
          title: this.$t('Quality'),
          value: this.average[ReviewCriteria.QUALITY]?.toFixed(1),
          icon: 'patch-check',
          tooltipText: this.$t('Are you satisfied with the quality of the product(s) received?'),
        },
      ];
    },
  },
});
</script>

<style lang="scss" scoped>
.review-average-rating {
  $self: &;

  &--compact {
    #{$self}__categories {
      @apply ds-flex-col;
      &-cell {
        @apply ds-py-1;
      }
    }
  }

  &__title {
    @apply ds-flex ds-flex-row ds-items-center;

    &-text {
      @apply ds-font-basic ds-text-3xl md:ds-text-5xl ds-mr-2.5;

      font-weight: 500;
    }

    &-stars {
      :deep(.icon--md) {
        @apply ds-text-2xl md:ds-text-4xl;
      }
    }
  }

  &__categories {
    @apply ds-flex ds-flex-auto ds-flex-col md:ds-flex-row ds-flex-wrap ds-gap-x-6 ds-gap-y-4;
    @apply ds-mt-4;

    &-cell {
      @apply ds-w-full ds-flex ds-items-center;
      @apply ds-py-2 lg:ds-py-4;

      flex: 40%;
      &__icon {
        @apply ds-text-xl md:ds-text-2xl ds-rounded-full ds-px-2 ds-py-1 ds-mr-2;
        @apply ds-bg-accent-700 ds-text-success-100;
      }

      &__criteria {
        @apply ds-inline-block ds-font-basic ds-text-sm md:ds-text-base;
      }

      &__info-icon {
        @apply ds-inline-block ds-align-middle ds-pr-2;
      }

      &__criteria-value {
        @apply ds-font-basic ds-text-sm md:ds-text-lg ds-font-bold ds-pl-2 ds-ml-auto;
      }
    }
  }
}
</style>
