import { memoize } from 'lodash-es';
import { isAmountObject } from '@core/types/amount';
import { priceFormatter } from '@core/utilities/formatters';
import { DineroObject } from 'dinero.js';
import { RichTextItem } from '@core/types/prismic';

export const isString = (val): boolean => typeof val === 'string' || val instanceof String;

export const highlightTextByQuery = ({
  query,
  content,
  bold = false,
}: {
  query: string;
  content?: string;
  bold?: boolean;
}): string => {
  try {
    query = query?.replace(/\\/g, '\\\\');
    return !query
      ? content
      : content?.replace(
          new RegExp(query, 'gi'),
          (match) => `<span class="highlightTextByQueryClass" style="font-weight: ${bold ? 'bold' : 'normal'}">${match}</span>`
        );
  } catch {
    return content;
  }
};

const flattenObject = memoize(<T extends object>(obj: T, parentKey?: string) => {
  let result = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    const _key = parentKey ? parentKey + '.' + key : key;

    if (typeof value === 'object' && !isAmountObject(value) && value !== null) {
      result = { ...result, ...flattenObject(value, _key) };
    } else {
      result[_key] = value;
    }
  });

  return result;
});

export const transformString = <T extends object, U extends object>(
  text: string,
  values: T,
  locale: string,
  extraValues: U = null
): string => {
  // Replace all occurrences of {key} with the value of key in the values object

  if (!values) {
    return text;
  }

  const flattenedValues = flattenObject(values);

  return text?.replace(/{(\w+(\.\w+)*)}/g, (_match, key) => {
    const value = flattenedValues[key];
    const extraValue = extraValues?.[key];

    if (isAmountObject(value)) {
      return priceFormatter(value as DineroObject, '$0.00', locale);
    } else if (isAmountObject(extraValue)) {
      return priceFormatter(extraValue as DineroObject, '$0.00', locale);
    }

    return value ?? extraValue;
  });
};

export const transformRichText = <T extends object, U extends object>(
  text: RichTextItem[],
  values: T,
  locale: string,
  extraValues: U = null
): RichTextItem[] => {
  if (!values) {
    return text;
  }

  return text.map((item) => ({
    ...item,
    text: transformString(item.text, values, locale, extraValues),
  }));
};

export const capitalise = (string: string) => string && `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
