<template>
  <div>
    <form
      v-show="isOnFormPage"
      class="retailer-qualification-form"
      data-testid="register-about-your-shop-form"
      @submit.prevent="submit"
    >
      <div
        v-if="!loadingQuestions"
        class="content"
      >
        <div
          v-for="question in questions"
          :key="question.alias"
          data-testid="question-item"
        >
          <QuestionItem
            :ref="question.question_type"
            :question="question"
            :state="v$.model"
            :model="model"
            :errors="errors"
            :is-revamp="true"
            @model-update="updateModel"
          />
        </div>
        <p
          v-if="'global' in errors"
          class="global-error"
          data-testid="global-error"
        >
          {{ errors.global }}
        </p>
        <AkButton
          v-if="!sending"
          size="xl"
          class="button--extended submit-button"
          data-testid="register-second-step-submit-button"
          type="submit"
        >
          {{ $t('Confirm') }}
        </AkButton>
        <LoaderIcon v-else />
      </div>
      <div
        v-else
        class="content"
      >
        <LoaderIcon />
      </div>
    </form>
    <NotRetailer
      v-show="!isOnFormPage"
      data-testid="not-retailer"
      :is-ankorstart="isAnkorstart"
      @redirect="onRedirectToGenericOptionsPage"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Analytics from '@monolith/legacy/services/analytics';
import NotRetailer from '@monolith/legacy/components/sign-up/common/not-retailer.vue';
import { SignUpEventNames } from '@monolith/legacy/services/analytics/events/sign-up/common/event-names';
import SignUpEvent from '@monolith/legacy/services/analytics/events/sign-up/sign-up-event';
import { AkButton } from '@ankorstore/design-system';
import http from '@monolith/legacy/services/api/http';
import { omit } from 'lodash-es';
import { getQualificationQuestions, patchQualificationQuestionsFromModel } from '@monolith/legacy/services/api/qualification-questions';
import { getQuestionsValidations } from '@monolith/legacy/services/signup/qualification-questions';
import { FeatureFlag } from '@monolith/legacy/services/features';
import { mapGetters } from 'vuex';
import LoaderIcon from '@monolith/legacy/components/loader-icon.vue';
import QuestionItem from '@monolith/legacy/components/sign-up/questions/question-item.vue';
import { website } from './questions/mockQuestions';
import useVuelidate from '@vuelidate/core';

const STEP = 1;

interface StepFormModel {
  website: { content: string };
  shop_age?: { answer_id: number };
}

export default defineComponent({
  name: 'SecondStepForm',
  components: { AkButton, LoaderIcon, NotRetailer, QuestionItem },
  emits: ['success'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      errors: {},
      model: {} as StepFormModel,
      questions: [],
      loadingQuestions: true,
      sending: false,
      autocomplete: null,
      isOnFormPage: true,
      FeatureFlag,
    };
  },
  computed: {
    ...mapGetters('signUp', ['isAnkorstart']),
  },
  mounted() {
    this.fetchQuestions();
    Analytics.track(
      new SignUpEvent(SignUpEventNames.AboutRetailerViewedEvent, {
        type: 'Element Viewed',
        is_opening_soon: this.isAnkorstart,
      })
    );
  },
  validations() {
    return {
      model: getQuestionsValidations(this.questions),
    };
  },
  methods: {
    urlWithHttps(url) {
      return url.startsWith('https://') ? url : `https://${url}`;
    },
    fetchQuestions() {
      getQualificationQuestions(STEP)
        .then(({ data }) => {
          this.questions = [...data, website];
          this.loadingQuestions = false;
        })
        .catch((errors) => {
          this.errors = errors;
          this.sending = false;
        })
        .finally(() => {
          this.loadingQuestions = false;
        });
    },
    updateModel(alias, value) {
      const hadAlias = !!this.model[alias];
      this.model[alias] = value;

      if (hadAlias) {
        this.v$.model[alias].$touch();
      }
    },
    retailerQualificationPatch() {
      patchQualificationQuestionsFromModel(STEP, omit(this.model, ['website']))
        .then(({ data }) => {
          this.$emit('success', data);
        })
        .catch((errors) => {
          this.errors = errors;
          this.sending = false;
        });
    },
    patchWebsite() {
      http()
        .patch('/api/me/retail/store_url', {
          store_url: this.urlWithHttps(this.model.website.content),
        })
        .then(({ data }) => {
          this.$emit('success', data);
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.errors) {
            this.errors = error.response.data.errors;
          } else {
            this.errors = {
              global: this.$t('An error occurred. Please retry.'),
            };
          }
          this.sending = false;
        });
    },
    async submit() {
      this.v$.$touch();
      if (!this.isFormValid()) {
        return;
      }
      if (this.isNotRetailer()) {
        this.isOnFormPage = false;
        Analytics.track(
          new SignUpEvent(SignUpEventNames.NotRetailerViewedEvent, {
            type: 'Element Viewed',
            is_opening_soon: this.isAnkorstart,
          })
        );
        return;
      }

      this.sending = true;
      if (this.model.website?.content?.length) {
        this.patchWebsite();
      }

      Analytics.track(
        new SignUpEvent(SignUpEventNames.AboutRetailerSubmittedEvent, {
          type: 'Click or Form Submit',
          is_opening_soon: this.isAnkorstart,
        })
      );
      this.errors = {};
      this.retailerQualificationPatch();
    },
    isFormValid() {
      if (!this.v$.$invalid) {
        return true;
      } else {
        Analytics.track(
          new SignUpEvent(SignUpEventNames.AboutRetailerMandatoryEvent, {
            type: 'Element Viewed',
            is_opening_soon: this.isAnkorstart,
          })
        );

        return false;
      }
    },
    isNotRetailer() {
      const questionType = 'online_presence';
      const onlinePresence = this.questions.find((question) => question.alias === questionType);

      return onlinePresence?.answers.find(
        ({ id, alias }) => alias === 'not_retailer' && id === this.model[questionType]?.answer_id
      );
    },
    isOpeningSoon() {
      const shopAgeQuestion = this.questions.find((question) => question.alias === 'shop_age');

      const openingSoonAnswer = shopAgeQuestion?.answers.find((answer) => answer.alias === 'opening_soon');

      return this.model.shop_age?.answer_id === openingSoonAnswer?.id;
    },
    onRedirectToGenericOptionsPage() {
      this.isOnFormPage = true;
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';
.sub-title {
  strong {
    font-weight: 400;
  }
}
.header {
  @apply ds-mt-4.5 ds-mb-4;
}

.submit-button {
  @apply ds-mt-4.5;
}

.content {
  @apply ds-mt-0 md:ds-mt-4.5;
}
</style>
