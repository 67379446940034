import Analytics from '@monolith/legacy/services/analytics';
export { trackClickOfNativeAds as trackProductClickWithAardvarkService } from '@bc/advertisement/domain';
import type { ESSponsoredProductHit } from '@bc/discovery';
import { AdCampaignFunnelEvent, AdsClickEvent } from '@bc/advertisement/domain/tracking/events';
import { AdSectionType, OriginType } from '@bc/advertisement/domain/tracking/events.enum';

export const trackAdsProductTooltipClick = async (
  product: ESSponsoredProductHit,
  sectionId: string,
  pageNumber: number,
  position: number
) => {
  await Analytics.track(
    new AdsClickEvent({
      sectionId: sectionId,
      component: 'native_ads',
      itemtype: 'product',
      action: 'ads_tooltip_clicked',
      productId: product.id,
      page_number: pageNumber,
      position: position,
    })
  );
};

export const trackAdCampaignFunnel = async (sectionType: AdSectionType, userOrigin: OriginType) => {
  await Analytics.track(
    new AdCampaignFunnelEvent({
      section_type: sectionType,
      user_origin: userOrigin,
    })
  );
};

export const trackCampaignSave = async () => {
  await Analytics.track(
    new AdsClickEvent({
      action: 'button_end_of_funnel',
    })
  );
};
