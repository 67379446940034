<template>
  <AkAlert
    class="missing-eori-warning"
    type="warning"
    icon="inbox"
  >
    <AkParagraph
      class="missing-eori-warning__heading"
      weight="bold"
      size="sm"
    >
      {{ $t('At least one order requires an EORI number') }}
    </AkParagraph>

    <AkParagraph
      class="missing-eori-warning__text"
      size="sm"
    >
      {{
        $t(
          'Customs authorities require retailers buying goods between EU/UK have an EORI number to clear customs. It is fast and easy to apply for an EORI if you do not have one already.'
        )
      }}
      <a
        :href="moreInfoLink"
        data-testId="missing-eori-more-info-link"
        target="_blank"
        rel="noreferrer"
      >
        <AkButton
          class="missing-eori-warning__inline-link"
          link
        >
          {{ $t('Find more information on EORI.') }}
        </AkButton>
      </a>
    </AkParagraph>
    <AkParagraph size="sm">
      {{
        $t(
          'In the case of missing EORI numbers, goods may not clear customs processes or delivery may be delayed until requisite information is provided.'
        )
      }}
    </AkParagraph>

    <div class="missing-eori-warning__link-container">
      <span>
        <a
          :href="eoriCustomsSiteLink"
          target="_blank"
          rel="noreferrer"
        >
          <AkButton
            data-testId="missing-eori-apply-for-eori"
            @on-click="onApplyForEori"
          >
            {{ $t('Apply for an EORI number now') }}
          </AkButton>
        </a>
      </span>
      <AkButton
        link
        data-testId="missing-eori-mark-as-submitted"
        @on-click="onMarkAsSubmitted"
      >
        {{ $t('I already applied for an EORI number') }}
      </AkButton>
    </div>
  </AkAlert>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { AkAlert, AkButton, AkParagraph } from '@ankorstore/design-system';
import { getGlobalConfig } from '@monolith/legacy/services/global-config';

export default defineComponent({
  name: 'BannerMissingEoriWarning',
  components: { AkAlert, AkButton, AkParagraph },
  emits: ['markedAsSubmitted', 'applyForEori'],
  data() {
    const languageConfig = getGlobalConfig().language_config;
    const countriesConfig = getGlobalConfig().countries_config;

    const isRetailer = this.$store.getters.userIsRetailer;
    const countryCode = this.$store.getters.user.business.country.iso_code;
    const eoriCustomsSiteLink = countriesConfig[countryCode]?.eori_customs_site_link;
    const retailerAssistanceLink = languageConfig.retailer_eori_assistance_link;
    const brandAssistanceLink = languageConfig.brand_eori_assistance_link;

    return {
      moreInfoLink: isRetailer ? retailerAssistanceLink : brandAssistanceLink,
      eoriCustomsSiteLink,
    };
  },
  methods: {
    onMarkAsSubmitted: function () {
      this.$emit('markedAsSubmitted');
    },
    onApplyForEori: function () {
      this.$emit('applyForEori');
    },
  },
});
</script>

<style scoped lang="scss">
:deep(.ak-alert__icon) {
  @apply ds-font-normal;
}
.missing-eori-warning {
  :deep(.ak-alert__content) {
    overflow: hidden;
  }

  .missing-eori-warning__heading {
    @apply ds-mb-2 ds-text-warning-700;
  }
  .missing-eori-warning__text {
    @apply ds-mb-1;
  }
  .missing-eori-warning__inline-link {
    display: inline;
  }
  .missing-eori-warning__link-container {
    @apply ds-flex ds-mt-4 ds-justify-center ds-flex-wrap ds-gap-2;

    span {
      @apply ds-mr-4;
    }
  }
}
</style>
