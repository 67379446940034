<template>
  <div class="px-4">
    <ReviewPreviewer :review="review">
      <template #footer>
        <div class="ds-mt-4 ds-font-basic ds-text-xs">
          <span>{{ $t('Submitted on') }}</span>
          <span class="ds-text-neutral-700"> {{ submittedOn }}</span>
        </div>
        <div
          v-if="publishedOn"
          class="ds-mt-4 ds-font-basic ds-text-xs"
        >
          <span>{{ $t('Published on') }}</span>
          <span class="ds-text-neutral-700"> {{ publishedOn }}</span>
        </div>
      </template>
    </ReviewPreviewer>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { DrawerComponentName } from '@monolith/legacy/store/drawer';
import { mapState } from 'vuex';
import ReviewPreviewer from '@monolith/legacy/components/review/review-previewer.vue';
import { formatDate } from '@monolith/legacy/services/date-locale';

export default defineComponent({
  name: DrawerComponentName.OrderReviewDrawer,
  components: {
    ReviewPreviewer,
  },
  computed: {
    ...mapState('reviews', ['review']),
    submittedOn(): string {
      return formatDate(this.review.created_at, 'PPpp');
    },
    publishedOn(): string {
      if (this.review?.published_at) {
        return formatDate(this.review.published_at, 'PPpp');
      }
      return null;
    },
  },
});
</script>
