import Product, { FormattedProduct, Variant } from '@monolith/legacy/types/product';
import { ShapeProperties } from '@monolith/legacy/types/product-shape';
import { FetchProductParams } from '@monolith/legacy/types/api';
import { SaveBarMode } from './types';
import i18n from '@monolith/legacy/services/i18n';
import { getProduct, getProducts, updateBrandProductCount } from '@monolith/legacy/services/api/brand-me';
import http from '@monolith/legacy/services/api/http';
import Analytics from '@monolith/legacy/services/analytics';
import UserClick from '@monolith/legacy/services/analytics/events/user-click.event';
import { createProduct, updateProduct, ProductDto } from '@monolith/legacy/services/product';
import { getTags, Tag } from '@monolith/legacy/services/metas/tag';
import { isPreorderDateExpired } from '@monolith/legacy/services/product-variants';

export enum EditionMode {
  ProductActivation = 'product-activation', // misleading name since discount setting feature
  Order = 'order',
}

export enum SelectionMode {
  All,
  None,
}

export enum StockParams {
  InStock = 'in_stock',
  OutOfStock = 'out_of_stock',
}

export enum ActionWithConfirmation {
  ApplyDiscount = 'discount_on',
  RemoveDiscount = 'discount_off',
  Archive = 'archive',
}

export type ProductAction = StockParams | ActionWithConfirmation;

interface Products {
  [index: string]: Product;
}

export interface State {
  products: Products;
  order: string[];
  notPersistedOrder: string[];
  notPersistedDiscountRate: number;
  selected: string[];
  searchQuery: string;
  onlyDisplayRequiresUpdate: boolean;
  pagination: {
    page: number;
    perPage: number;
    total: number;
  };
  loading: boolean;
  editionMode: EditionMode;
  pendingAction: ProductAction | null;
  reloadNeeded: number | null;
  errorFromApi: object | null;
}

const initialState: State = {
  products: {},
  order: [],
  notPersistedOrder: [],
  notPersistedDiscountRate: null,
  selected: [],
  searchQuery: '',
  onlyDisplayRequiresUpdate: false,
  pagination: {
    page: 1,
    perPage: 50,
    total: 0,
  },
  loading: false,
  editionMode: EditionMode.ProductActivation,
  pendingAction: null,
  reloadNeeded: null,
  errorFromApi: null,
};

export const productPropertiesCategories = {
  home_materials: [
    // v1
    1, 49,
    // v2
    372, 677, 494, 712,
    // v3
    1246, 1316,
  ],
  grocery_ubd: [
    // v1
    29, 49,
    // v2
    446, 711, 494, 712,
    // v3
    1262, 1316,
  ],
  grocery_dmd: [
    // v1
    29, 49,
    // v2
    446, 711, 494, 712,
    // v3
    1262, 1316,
  ],
  grocery_ingredients_list: [
    // v1
    29, 49,
    // v2
    446, 711, 494, 712,
    // v3
    1262, 1288, 1316,
  ],
  cosmetic_inci_list: [
    // v1
    32, 49,
    // v2
    537, 698, 494, 712,
    // v3
    1279, 1316,
  ],
  cosmetic_dmd: [
    // v1
    32,
    // v2
    537, 698,
    // v3
    1279,
  ],
  contains_alcohol: [
    // v1
    29,
    // v2
    446, 711,
    // v3
    1262,
  ],
};

export const buildAdditionalDatas = () => {
  return [
    {
      key: 'dimensions',
      name: i18n.global.t('Dimensions'),
      type: 'input',
    },
    {
      key: 'net_weight',
      name: i18n.global.t('Net weight'),
      type: 'input',
    },
    {
      key: 'capacity',
      name: i18n.global.t('Capacity'),
      type: 'input',
    },
    {
      key: 'fashion_composition',
      name: i18n.global.t('Composition'),
      type: 'text-area',
    },
    {
      key: 'home_materials',
      name: i18n.global.t('Materials'),
      type: 'text-area',
      category: productPropertiesCategories.home_materials,
    },
    {
      key: 'grocery_ubd',
      name: i18n.global.t('UBD'),
      type: 'input',
      category: productPropertiesCategories.grocery_ubd,
    },
    {
      key: 'grocery_dmd',
      name: i18n.global.t('DMD'),
      type: 'input',
      category: productPropertiesCategories.grocery_dmd,
    },
    {
      key: 'grocery_ingredients_list',
      name: i18n.global.t('List of ingredients'),
      type: 'text-area',
      category: productPropertiesCategories.grocery_ingredients_list,
    },
    {
      key: 'cosmetic_inci_list',
      name: i18n.global.t('INCI list'),
      type: 'text-area',
      category: productPropertiesCategories.cosmetic_inci_list,
    },
    {
      key: 'cosmetic_dmd',
      name: i18n.global.t('DMD'),
      type: 'input',
      category: productPropertiesCategories.cosmetic_dmd,
    },
  ];
};

const emptyShapeProperties: ShapeProperties = {
  capacity_unit: null,
  capacity: null,
  dimensions_unit: null,
  height: null,
  length: null,
  weight_unit: null,
  weight: null,
  width: null,
};

export default {
  namespaced: true,
  state: initialState,
  actions: {
    resetSelectedProducts({ commit }): void {
      commit('RESET_SELECTED');
    },

    setSearchQuery({ commit, dispatch }, query: string): void {
      commit('SET_SEARCH_QUERY', query);
      commit('SET_CURRENT_PAGE', 1);
      dispatch('fetchProducts', {
        showAll: false,
        keepProductCache: false,
      });
    },

    toggleDisplayRequiresUpdate({ commit, dispatch }): void {
      commit('TOGGLE_DISPLAY_REQUIRES_UPDATE');
      commit('SET_CURRENT_PAGE', 1);
      dispatch('fetchProducts', {
        showAll: false,
        keepProductCache: false,
      });
    },

    async fetchProducts(
      { commit, state },
      { showAll, keepProductCache }: { showAll: boolean; keepProductCache: boolean }
    ): Promise<void> {
      const { page, perPage } = state.pagination;

      commit('TOGGLE_LOADING', true);

      const data = await getProducts({
        params: {
          page,
          perPage,
          _fields:
            'id,name,link,options{id,sku,out_of_stock},images,wholesale_price{amount,currency},original_wholesale_price{amount,currency},discount_rate',
          query: state.searchQuery,
          'filters[requireupdate]': state.onlyDisplayRequiresUpdate ? 1 : 0,
          'filters[with_options]': showAll ? 0 : 1,
        },
      });

      commit('SET_PRODUCTS', {
        products: Object.fromEntries(data.data.map((item) => [item.id, item])),
        order: data.data.map(({ id }) => `${id}`),
        keepOldProducts: keepProductCache,
      });
      commit('SET_SELECTED', []);
      commit('SET_CURRENT_PAGE', data.meta.current_page);
      commit('SET_ITEMS_PER_PAGE', data.meta.per_page);
      commit('SET_TOTAL_ITEMS', data.meta.total);
      commit('TOGGLE_LOADING', false);
    },

    async fetchProduct({ commit }, params: FetchProductParams): Promise<void> {
      commit('TOGGLE_LOADING', true);
      const data = await getProduct(params.id);
      commit('SET_PRODUCT', data);
      commit('TOGGLE_LOADING', false);
    },

    changeEditionMode({ commit }, mode: EditionMode): void {
      commit('CHANGE_EDITION_MODE', mode);
    },

    changePendingAction({ commit, dispatch }, action: StockParams | ActionWithConfirmation | null) {
      commit('CHANGE_PENDING_ACTION', action);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((Object as any).values(StockParams).includes(action)) {
        return dispatch('massAction', action);
      }
      return Promise.resolve();
    },

    resetPendingAction({ commit }) {
      commit('CHANGE_PENDING_ACTION', null);
    },

    async changeCurrentPage({ commit, dispatch }, page: number) {
      commit('SET_CURRENT_PAGE', page);
      return dispatch('fetchProducts', {
        showAll: false,
        keepProductCache: false,
      });
    },

    toggleProductSelection({ commit, state }, id: number): void {
      commit(
        'SET_SELECTED',
        state.selected.includes(`${id}`) ? state.selected.filter((item) => `${id}` !== item) : [...state.selected, `${id}`]
      );
    },

    toggleWholeSelection({ commit, dispatch, state }, mode: SelectionMode): void {
      commit('SET_SELECTED', mode === SelectionMode.All ? state.order : []);
      if (mode === SelectionMode.All) {
        return;
      }
      return dispatch('resetWantedDiscountRate');
    },

    toggleActive({ dispatch, commit, state }, action: StockParams) {
      return http()
        .post('/api/me/brand/products/mass-action', {
          product_ids: state.selected,
          action,
        })
        .then(({ data }) => {
          commit('UPDATE_PRODUCTS', Object.fromEntries(data.data.map((item) => [item.id, item])));
          dispatch('toggleWholeSelection', SelectionMode.None);
        });
    },

    changeOrder({ commit }, products: Products[]) {
      commit(
        'CHANGE_ORDER',
        products.map(({ id }) => `${id}`)
      );
    },

    persistReorder({ state, commit }) {
      return http()
        .post('/api/me/brand/products/reorder', {
          product_ids: state.notPersistedOrder,
          page: state.pagination.page,
          perPage: state.pagination.page === 1 ? state.notPersistedOrder.length : state.pagination.perPage,
        })
        .then(({ data }) => {
          commit(
            'SET_ORDER',
            data.data.map(({ id }) => `${id}`)
          );
          commit('CHANGE_EDITION_MODE', EditionMode.ProductActivation);
        });
    },

    setNewProduct({ state, commit }, product: Product) {
      commit('SET_PRODUCT', product);

      commit(
        'SET_ORDER',
        state.notPersistedOrder.map((id) => `${id}`)
      );

      commit('SET_PRODUCTS', {
        products: Object.fromEntries(state.order.map((id) => [id, state.products[id]])),
        order: state.order.map((id) => `${id}`),
      });
    },

    // NOT REIMPLEMENTED YET
    // isProductReorderingDirty: (state) => {
    //     if (
    //       !state.products.length ||
    //       !state.productsReorderingNotPersisted.length
    //     ) {
    //       return false;
    //     }

    //     for (let i = 0; i < state.products.length; i++) {
    //       if (
    //         state.productsReorderingNotPersisted[i] &&
    //         state.products[i].id !== state.productsReorderingNotPersisted[i].id
    //       ) {
    //         return true;
    //       }
    //     }

    //     return false;
    //   },

    productSaveRequested({ dispatch }): void {
      dispatch('fetchProducts', {
        showAll: false,
        keepProductCache: false,
      });
    },
    createProduct({ commit, dispatch, rootGetters, state }, payload: ProductDto): Promise<unknown> {
      commit('TOGGLE_LOADING', true);

      return createProduct(rootGetters.brand.id, payload)
        .then((product) => {
          commit('SET_PRODUCT', product);
          commit('SET_ORDER', [product.id, ...state.order]);
          commit('TOGGLE_LOADING', false);
          if (rootGetters.userIsCandidateBrand) {
            Analytics.track(
              new UserClick({
                component: 'brand_self_onboarding_catalog_upload',
                action: 'product_added_manually',
                id_brand: rootGetters.brand.id,
              })
            );
          } else {
            Analytics.track(
              new UserClick({
                component: 'brand_bo_product_form',
                action: 'product_created',
                brand_id: rootGetters.brand.id,
              })
            );
          }
          dispatch('account/changeSaveBarMode', SaveBarMode.Disabled, {
            root: true,
          });
          commit('TRIGGER_RELOAD_NEEDED');
        })
        .catch(({ response }) => {
          commit('TRIGGER_ERROR_FROM_API', response?.data);
        });
    },
    persistProduct({ commit, dispatch, rootState }, payload: ProductDto & { id: number }): Promise<void> {
      commit('TOGGLE_LOADING', true);

      return updateProduct(payload.id, rootState.brand.id, payload)
        .then((product) => {
          commit('SET_PRODUCT', product);
          commit('TOGGLE_LOADING', false);

          Analytics.track(
            new UserClick({
              component: 'brand_bo_product_form',
              action: 'product_updated',
              brand_id: rootState.brand.id,
            })
          );
          dispatch('account/changeSaveBarMode', SaveBarMode.Disabled, {
            root: true,
          });
          commit('TRIGGER_RELOAD_NEEDED');
        })
        .catch(({ response }) => {
          commit('TRIGGER_ERROR_FROM_API', response?.data);
        });
    },
    async massAction({ commit, dispatch, getters, state }, action) {
      // When activating items, prevent submitting IDs of preorder items that have expired
      const productIds = action === StockParams.InStock
        ? getters.getSelectedProduct.filter((id: number) => !getters.getSelectedProductsWithExpiredAvailableAt.find((product: Product) => +product.id === +id))
        : getters.getSelectedProduct;

      if(!productIds.length) {
        return dispatch('resetSelectedProducts');
      }

      const data = await http().post('/api/me/brand/products/mass-action', {
        product_ids: productIds,
        action,
        ...(getters.isDiscountPendingAction && {
          discount_rate: state.notPersistedDiscountRate,
        }),
      });
      dispatch('resetWantedDiscountRate');
      dispatch('resetPendingAction');
      if (action === ActionWithConfirmation.Archive) {
        commit('DELETE_PRODUCTS', getters.getSelectedProduct);
        dispatch('account/brandOnboarding/fetchBrandOnboardingAttributes', { force: true }, { root: true });
        return dispatch('resetSelectedProducts');
      }
      commit('UPDATE_PRODUCTS', Object.fromEntries(data.data.data.map((item) => [item.id, item])));
      return dispatch('resetSelectedProducts');
    },
    setWantedDiscountRate({ commit }, rate: number) {
      return commit('SET_NOT_PERSISTED_DISCOUNT_RATE', rate);
    },
    resetWantedDiscountRate({ commit }) {
      return commit('SET_NOT_PERSISTED_DISCOUNT_RATE', null);
    },
    async updateProductCount({ dispatch, rootState }, count: number) {
      updateBrandProductCount(count).then(() => {
        const brand = { ...rootState.brand, product_count: count };
        dispatch('updateBrand', { brand }, { root: true });
      });
    },
  },
  mutations: {
    // PAGINATION AND UI

    TRIGGER_RELOAD_NEEDED(state: State): void {
      state.reloadNeeded = Date.now();
    },

    TRIGGER_ERROR_FROM_API(state: State, error = {}): void {
      state.errorFromApi = error;
    },

    TOGGLE_LOADING(state: State, value: boolean): void {
      state.loading = value;
    },

    CHANGE_EDITION_MODE(state: State, mode: EditionMode): void {
      state.editionMode = mode;
    },

    CHANGE_PENDING_ACTION(state: State, action: ProductAction): void {
      state.pendingAction = action;
    },

    SET_CURRENT_PAGE(state: State, page: number): void {
      state.pagination.page = page;
    },

    SET_SEARCH_QUERY(state: State, query: string): void {
      state.searchQuery = query;
    },

    TOGGLE_DISPLAY_REQUIRES_UPDATE(state: State): void {
      state.onlyDisplayRequiresUpdate = !state.onlyDisplayRequiresUpdate;
    },

    SET_ITEMS_PER_PAGE(state: State, items: number): void {
      state.pagination.perPage = items;
    },

    SET_TOTAL_ITEMS(state: State, items: number): void {
      state.pagination.total = items;
    },

    // PRODUCT

    SET_PRODUCTS(
      state: State,
      { products, order, keepOldProducts = false }: { products: Products; order: string[]; keepOldProducts: boolean }
    ): void {
      if (keepOldProducts) {
        state.products = { ...state.products, ...products };
      } else {
        state.products = { ...products };
      }

      state.order = [...order];
    },

    SET_ORDER(state: State, order: string[]): void {
      state.order = [...order];
    },

    UPDATE_PRODUCTS(state: State, products: Products): void {
      state.products = { ...state.products, ...products };
    },

    DELETE_PRODUCTS(state: State, productsIds: string[]): void {
      state.order = state.order.filter((id) => !productsIds.includes(`${id}`));
      state.products = Object.fromEntries(
        Object.values(state.products)
          .filter((product) => !productsIds.includes(`${product.id}`))
          .map((product) => [product.id, product])
      );
    },

    SET_PRODUCT(state: State, product: Product): void {
      state.products = { ...state.products, [product.id]: product };
    },

    SET_SELECTED(state: State, ids: string[]): void {
      state.selected = [...ids];
    },

    RESET_SELECTED(state: State): void {
      state.selected = [];
    },

    CHANGE_ORDER(state: State, order: string[]): void {
      state.notPersistedOrder = [...order];
    },
    SET_NOT_PERSISTED_DISCOUNT_RATE(state: State, rate: number): void {
      state.notPersistedDiscountRate = rate;
    },
  },
  getters: {
    getEditionMode: (state: State): EditionMode => state.editionMode,
    isCurrentModeActivation: (state: State): boolean => state.editionMode === EditionMode.ProductActivation,
    getTotalItems: (state: State): number => state.pagination.total,
    getItemsPerPage: (state: State): number => state.pagination.perPage,
    getCurrentPage: (state: State): number => state.pagination.page,
    getOrderedProducts: (state: State): Product[] => state.order.map((id) => state.products[id]),
    getSortedProducts: (state: State): Product[] => state.notPersistedOrder.map((id) => state.products[id]),
    getProducts: (state: State): Products => state.products,
    getProduct:
      (state: State) =>
      (id: number): Product | undefined =>
        state.products[id],
    getFormattedProduct:
      ({ products }: { products: Product[] & Partial<Variant>[] }) =>
      (id: number): FormattedProduct | undefined => {
        const res = products[id]
          ? Object.assign({}, products[id], {
              ...products[id],
              description: products[id].description_auto_translate
                ? products[id].description_original_content
                : products[id].description,

              tags: Object.values(getTags()).map((tag: Tag) => ({
                ...tag,
                checked: products[id].tags.includes(tag.label),
              })),
              vat_rate: Number(products[id]['vat_rate']) ? Number(products[id]['vat_rate']) : 0,
              hs_code: products[id]['hs_code'] ? products[id]['hs_code'] : '',
              discount_rate: Number(products[id]['discount_rate']) ? Number(products[id]['discount_rate']) : 0,
              made_in: products[id].made_in?.id || null,
              properties: buildAdditionalDatas().map((property) => ({
                ...property,
                original_content: products[id].properties.find(({ key }) => key === property.key)?.original_content ?? null,
              })),
              images: products[id].images.map((image, index) => ({
                filename: image,
                height: '100',
                width: '100',
                order: index,
              })),
              shape_properties: products[id].shape_properties ?? {
                ...emptyShapeProperties,
              },
              variants: products[id].variants.map((variant) => {
                const images = variant.images?.map((image, index) => {
                  return {
                    filename: image,
                    order: index,
                  };
                });
                return {
                  ...variant,
                  images,
                  shape_properties: variant.shape_properties ?? {
                    ...emptyShapeProperties,
                  },
                };
              }),
            })
          : undefined;

        return res;
      },
    isProductSelected:
      (state: State) =>
      (id: number): boolean =>
        state.selected.includes(`${id}`),
    getSelectedCount: (state: State): number => state.selected.length,
    getSelectedProduct: (state: State) => state.selected,
    isOrderChanged: (state: State): boolean => state.notPersistedOrder !== state.order,
    isLoading: (state: State): boolean => state.loading,

    getProductsCount: (state: State): number => Object.keys(state.products).length,
    getSelectedProducts: (state: State) => state.selected.map((productId: string) => state.products[productId]),
    getSelectedProductsWithExpiredAvailableAt: (_, getters) =>
      getters.getSelectedProducts.filter((product: Product) => isPreorderDateExpired(product)),
    getOrderCount: (state: State): number => state.order.length,
    getDisplayOnlyRequiresCategory: (state: State): boolean => state.onlyDisplayRequiresUpdate,
    isDiscountPendingAction: (state: State): boolean => state.pendingAction === ActionWithConfirmation.ApplyDiscount,
    isNotPersistedDiscountRateSet: (state: State): boolean => state.notPersistedDiscountRate !== null,
    notPersistedDiscountRate: (state: State) => state.notPersistedDiscountRate,
  },
};
