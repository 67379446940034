import Dinero from 'dinero.js';
import { captureException } from '@core/plugins/sentry';

export const priceFormatter = (price: Dinero.Options, format: string, locale: string): string => {
  try {
    return Dinero(price)
      .setLocale(locale)
      .toFormat(format ?? undefined);
  } catch (error) {
    captureException(error);
    // dont break the JS;
    return '';
  }
};
