import { OFFERS_STORE_ID } from '@monolith/legacy/store/offers';
import type { OffersState } from '@monolith/legacy/store/offers';
import type { OffersGetters } from '@monolith/legacy/store/offers/getters';
import type { OffersActions } from '@monolith/legacy/store/offers/actions';
import type { OffersMutations } from '@monolith/legacy/store/offers/mutations';

import { createNamespacedHelpers } from 'vuex-composition-helpers';

const { useGetters, useActions, useMutations } = createNamespacedHelpers<
  OffersState,
  OffersGetters,
  OffersActions,
  OffersMutations
>(OFFERS_STORE_ID);

export default {
  useGetters,
  useActions,
  useMutations,
};
