import http from '@monolith/legacy/services/api/http';
import { StrictUnion } from '@monolith/legacy/types';
import {
  Item,
  ItemFromApi,
  PreorderCart,
  PreorderCartResponseDeserialized,
  PreorderCartResponseMapped,
} from '@monolith/legacy/types/cart-preorder';
import { StockStatus } from '@monolith/legacy/services/product-variants';
import { deserialize } from '@monolith/legacy/services/utils/jsonapi-parser';
import { AxiosResponse } from 'axios';
import { OrderingGetPreordersCartData } from '@ankorstore/api-internal/data-contracts';
import { isEnabled } from '@monolith/legacy/services/features';
import { getGlobalConfig } from '@monolith/legacy/services/global-config';

export type PreorderProductUpdate = {
  productVariantId: string | null;
  quantity: number;
};
export type PreorderCartItemUpdate = {
  preorderItemId: string | null;
  quantity: number;
};
export type PreorderBulkUpdate = StrictUnion<PreorderProductUpdate | PreorderCartItemUpdate>;

export const getPreorderCart = async (): Promise<PreorderCartResponseDeserialized> => {
  const result: AxiosResponse<OrderingGetPreordersCartData> = await http().get(
    '/api/internal/v1/ordering/preorders-cart?include=preorders,preorders.preorderItems,preorders.brand,preorders.preorderItems.productVariant'
  );

  return deserialize(result?.data).data;
};

export const updatePreorderSelection = async (selectedPreorderIds: string[]) => {
  const result = await http().post(
    '/api/internal/v1/ordering/preorders-cart/-actions/update-selection?include=preorders,preorders.preorderItems,preorders.brand,preorders.preorderItems.productVariant',
    { selectedPreorderIds }
  );
  return deserialize(result?.data);
};

export const updatePreorderItemsBulk = async (updates: PreorderBulkUpdate[]) => {
  const result = await http().post('/api/internal/v1/ordering/preorders-cart/-actions/update-items', {
    data: {
      attributes: {
        items: updates,
      },
    },
  });

  return deserialize(result?.data);
};

export const mapResponse = (preorderCartData: PreorderCartResponseDeserialized): PreorderCartResponseMapped => {
  const checkout = {
    type: 'checkout',
    id: preorderCartData.id,
    order_informations: {
      order_items_count_main: preorderCartData.itemCount,
      type: 'order_informations' as const,
    },
    carts: [],
    total_amount_with_vat: preorderCartData.totalAmountWithVat,
    total_amount_vat: preorderCartData.totalAmountVat,
    total_amount: preorderCartData.totalBaseAmount,
    grand_total_amount_with_vat: preorderCartData.grandTotalAmountWithVat,
    brandsCountry: preorderCartData.preorders.data.map((preorder) => preorder.brand.data.countryIsoCode),
  };

  if (preorderCartData.preorders.data.length === 0) {
    return checkout;
  }

  preorderCartData.preorders.data.forEach((preorder) => {
    const items = <Item[]>preorder.preorderItems.data.map((preorderItem: ItemFromApi) => {
      return {
        type: 'preorder' as const,
        id: preorderItem.id,
        quantity: preorderItem.quantity,
        unit_price: preorderItem.unitPrice,
        amountBeforeDiscount: preorderItem.amountBeforeDiscount,
        amount: preorderItem.baseAmount,
        canBeBought: preorderItem.canBeBought,
        variant: {
          id: preorderItem.productVariant.data.id,
          name: preorderItem.productVariant.data.name,
          stock: preorderItem.productVariant.data.stock,
          options: preorderItem.productVariant.data.options,
          out_of_stock:
            !preorderItem.productVariant.data.stock.isAlwaysInStock &&
            preorderItem.productVariant.data.stock.status === StockStatus.OutOfStock,
          unit_multiplier: preorderItem.productVariant.data.unitMultiplier,
          images: [preorderItem.productVariant.data.imagePath],
          path: preorderItem.productVariant.data.path,
          available_at: preorderItem.productVariant.data.availableAt,
        },
      };
    });
    const cart: PreorderCart = {
      type: 'preorder-cart' as const,
      id: preorder.brand.data.id,
      preorderId: preorder.id,
      itemCount: preorder.itemCount,
      offerDiscounts: preorder.offerDiscounts,
      hasDiscounts: preorder.hasDiscounts,
      globalDiscountPercentage: preorder.globalDiscountPercentage,
      hasMultiDiscounts: preorder.hasMultiDiscounts,
      metadata: preorder.metadata,
      brand: {
        id: preorder.brand.data.id,
        uuid: preorder.brand.data.id,
        name: preorder.brand.data.name,
        link: preorder.brand.data.link,
        isEnabled: preorder.brand.data.isEnabled,
        canShipToRetailerCountry: preorder.brand.data.canShipToRetailerCountry,
        countryIsoCode: preorder.brand.data.countryIsoCode,
        images: {
          squared: preorder.brand.data.logoImagePath,
          rounded: preorder.brand.data.logoImagePath,
          large: preorder.brand.data.logoImagePath,
        },
      },
      items,
      selected: preorder.isSelected,
      minimum: {
        above:
          (isEnabled('OXP-2047') ? getGlobalConfig().minimum_order_amount.amount : preorder.preorderMinimumAmount.amount) <=
          preorder.totalBaseAmount.amount,
        amount: isEnabled('OXP-2047') ? getGlobalConfig().minimum_order_amount : preorder.preorderMinimumAmount,
      },
      total_amount: preorder.totalBaseAmount,
      total_amount_before_discount: preorder.totalAmountBeforeDiscount,
    };
    const existingIndex = checkout.carts.findIndex((existingCart) => existingCart.id === cart.id);

    if (existingIndex !== -1) {
      checkout.carts[existingIndex].items = [...checkout.carts[existingIndex].items, ...cart.items];
    } else {
      checkout.carts.push(cart);
    }
  });

  return checkout;
};

export const getPreorderRetailer = async () => {
  const result = await http().get('/api/internal/v1/ordering/preorders-cart/retailer', {
    noProgressBar: true,
  });

  return deserialize(result?.data)?.data;
};

export const checkoutPreordersCart = async (paymentTerm) => {
  const result = await http().post(
    '/api/internal/v1/ordering/preorders-cart/-actions/checkout',
    paymentTerm
      ? {
          paymentTerm,
        }
      : undefined
  );

  return result?.data;
};

export const updateIbanSource = async (sourceId: string) => {
  const result = await http().post('/api/internal/v1/ordering/preorders-cart/retailer/-actions/update-iban-source', {
    stripeIbanSource: sourceId,
  });

  return result?.data;
};

export const getPaymentTerms = async () => {
  const result = await http().get('/api/internal/v1/ordering/preorders-cart/-actions/get-payment-terms', {
    noProgressBar: true,
  });

  return deserialize(result?.data);
};
