<template>
  <RecommendationsWidget
    v-if="showRelatedBrands"
    data-testid="related-brands"
    :widget-proposition="valueProposition"
    :label="$t('retailer.related-brands.title')"
    :algorithm="relatedBrands.algorithm"
    :items="relatedBrands.brands"
    :min-number-records-to-show="1"
  />
</template>

<script setup lang="ts">
import { ref, watchEffect, computed } from 'vue';
import { RecommendationService } from '@bc/discovery/domain/recommendation';
import RecommendationsWidget from '../recommendations-widget/recommendations-widget.vue';

const props = defineProps({
  brandId: {
    type: [String, Number],
    required: true,
  },
  valueProposition: {
    type: String,
    default: 'Similar brands you might like',
  },
});

const relatedBrands = ref<Awaited<ReturnType<typeof RecommendationService.getRelatedBrands>>>();
const showRelatedBrands = computed(() => relatedBrands.value?.brands.length > 0);

watchEffect(async () => {
  relatedBrands.value = await RecommendationService.getRelatedBrands(props.brandId);
});
</script>
