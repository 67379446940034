import { useStore } from 'vuex';
import searchStore from '../store/index';
import { SEARCH_STORE_ID } from '../store/constants';

export function useSearchStore() {
  const store = useStore();

  if (!store.hasModule(SEARCH_STORE_ID)) {
    store.registerModule(SEARCH_STORE_ID, searchStore);
  }
}
