<template>
  <AkAlert
    class="label-info"
    type="info"
    :title="title"
    icon="info-circle"
    :hide-icon="false"
    :border="false"
  >
    {{ content }}
  </AkAlert>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LabelInfo',
  props: {
    title: {
      type: String,
      required: true,
      default: null,
    },
    content: {
      type: String,
      required: true,
      default: null,
    },
  },
});
</script>

<style scoped lang="scss">
.label-info {
  @apply ds-font-basic ds-my-5;

  :deep(.icon-info-circle) {
    @apply ds-text-info-700;
  }
}
</style>
