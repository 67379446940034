import { changePosition } from '@monolith/legacy/services/change-position';
import { State } from '.';
import {
  updatePosition,
  getRetailerProductLikeCount,
  resetRetailerProductLikeCount,
  markTooltipAsSeen,
  UpdateAList,
  fetchList,
} from '@monolith/legacy/services/api/wishlist';
import { trackProductWishlistOrderChanged } from './analytics';
import { isEnabled } from '@monolith/legacy/services/features';
import List, { Status } from '@monolith/legacy/types/wishList';
import { Sort } from '@monolith/legacy/types/sorting';

export const actions = {
  updateUserListPosition({ commit, getters }, payload: { listId: string; newIndex: number }): Promise<List> {
    const lists = getters.getUserLists as List[];
    const listToMove = getters.getList(payload.listId);
    const reorderedLists = changePosition(lists, listToMove, payload.newIndex);

    commit('UPDATE_POSITIONS', [getters.getDefaultList, ...reorderedLists]);

    const movedList = getters.getList(payload.listId);

    trackProductWishlistOrderChanged(movedList);

    return updatePosition(movedList);
  },

  async sortWishlist({ commit, getters }, { listId, column }): Promise<void> {
    let direction = Sort.Asc;
    if (column === getters.getSortColumnByListId(listId)) {
      direction = getters.getSortDirectionByListId(listId) === Sort.Asc ? Sort.Desc : Sort.Asc;
    }

    const { products, meta } = await UpdateAList(listId, {
      sort: `${column}:${direction}`,
    });

    commit('CHANGE_SORT', {
      sort: {
        direction,
        column,
      },
      listId,
    });

    commit('UPDATE_PRODUCTS_OF_LIST', {
      products,
      listId,
      meta,
    });
  },
  async markTooltipAsSeen(): Promise<void> {
    await markTooltipAsSeen();
  },

  async fetchRetailerProductLikeCount({ commit }): Promise<void> {
    /* TODO : REMOVE */
    if (!isEnabled('wishlist_menu')) {
      return;
    }
    commit('UPDATE_PRODUCT_LIKE_COUNT', await getRetailerProductLikeCount());
  },

  async resetRetailerProductLikeCount({ commit }): Promise<void> {
    // set count to 0 so the badge is not displayed
    commit('UPDATE_PRODUCT_LIKE_COUNT', {
      shouldDisplayTooltip: false,
      count: 0,
    } as State['productLikeCount']);

    commit('UPDATE_PRODUCT_LIKE_COUNT', await resetRetailerProductLikeCount());
  },

  async updateAList({ commit, dispatch }, { listId }): Promise<void> {
    const { products, meta } = await UpdateAList(listId);

    await dispatch(
      'shop/storeBrands',
      {
        brands: products.map(({ brand }) => ({ [brand.id]: brand })),
      },
      { root: true }
    );

    commit('UPDATE_PRODUCTS_OF_LIST', {
      products,
      listId,
      meta,
    });

    dispatch('setStatus', Status.success);
    commit('SET_STATUS_OF_WISHLIST', {
      listId,
      status: Status.success,
    });
  },

  async showMoreProducts({ commit, dispatch, getters }, { listId, page }): Promise<void> {
    const column = getters.getSortColumnByListId(listId);
    const direction = getters.getSortDirectionByListId(listId);

    const data = await UpdateAList(listId, { page, sort: `${column}:${direction}` });

    commit('ADD_PRODUCTS_TO_LIST', {
      products: data.products,
      meta: data.meta,
      listId,
    });
    dispatch('setStatus', Status.success);
    commit('SET_STATUS_OF_WISHLIST', {
      listId,
      status: Status.success,
    });
  },

  async fetchWishlist({ commit, dispatch }): Promise<void> {
    await dispatch('setStatus', Status.pending);
    const data = await fetchList();

    commit(
      'SET_LISTS',
      Object.fromEntries(
        data.map((list) => [
          list.id,
          {
            ...list,
            productsChecked: [],
            products: [],
            sort: {
              column: 'addedAt',
              direction: 'desc',
            },
            editable: false,
            status: Status.success,
          },
        ])
      )
    );
    const defaultList = data.find((list) => {
      return list.default === true;
    });
    if (defaultList?.products_count > 0) {
      dispatch('toggleCollapsed', { id: defaultList.id, isCollapsed: false });
    }

    await dispatch('setStatus', Status.success);
  },

  async toggleCollapsed({ commit, dispatch }, { id, isCollapsed }): Promise<void> {
    commit('SET_COLLAPSE', { id, isCollapsed });
    if (!isCollapsed) {
      commit('SET_STATUS_OF_WISHLIST', {
        listId: id,
        status: Status.pending,
      });
      await dispatch('updateAList', { listId: id });
      commit('SET_STATUS_OF_WISHLIST', {
        listId: id,
        status: Status.success,
      });
    }
  },
};

export default actions;
