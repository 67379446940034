<template>
  <AisCustomHits>
    <CatalogGrid
      :key="redraw"
      :has-error="status === 'error'"
      :is-searching="isSearching"
      :tiles="items"
      :tiles-per-row="tilesPerRow"
      :config="gridConfig"
    >
      <template #error>
        <AkParagraph
          class="ds-mb-7"
          data-testid="noResultMessage"
        >
          {{ $t('@@core_discovery.search.no_results.msg.error') }}
        </AkParagraph>
      </template>
      <template #skeleton>
        <SkeletonCard :tile-type="config.template" />
      </template>
      <template
        v-for="slot in relatedCollections.slots"
        :key="`${slot.name}-${slot.insertionIndex}-banner`"
        #[slot.name]
      >
        <component
          :is="slot.content"
          v-if="isVNode(slot.content)"
          @collection-clicked="handleRelatedCollectionClicked"
        />
        <CollectionBanner
          v-else
          :section="slot.content"
          data-testid="collection-banner"
          @collection-clicked="handleRelatedCollectionClicked"
        />
      </template>
      <template #default="{ tile, index }">
        <CatalogTile
          :id="tile.id"
          :key="tile.id"
          class="ds-h-full"
          :config="config"
          :item="tile"
          :page="page"
          :index="index"
          data-testid="product"
        />
      </template>
      <template #noResults>
        <slot name="noResults" />
      </template>
    </CatalogGrid>
  </AisCustomHits>
</template>

<script lang="ts">
import { watch, watchEffect, defineComponent, ref, computed, PropType, nextTick, isVNode } from 'vue';
import CatalogTile from '../components/tile/catalog-tile.vue';
import AisCustomHits from '../components/custom-ais/custom-hits.vue';
import { Config, CatalogGridInsertion } from '@bc/discovery/domain/catalog';
import { useActions } from 'vuex-composition-helpers';
import { useAisState } from '../composables/use-ais-state';
import useGlobals from '@core/composables/use-globals';
import { ProductHit } from '@monolith/legacy/types/product';
import { type BrandHit } from '@bc/discovery/domain/catalog';
import { CollectionBanner, CollectionTypes } from '@bc/discovery/ui/collection';
import { SkeletonCard } from '@bc/discovery/ui/cards/skeleton-card';
import { CatalogGrid, CatalogUiTypes } from '@bc/discovery/ui/catalog';
import { ProductAdapter } from '@bc/discovery/domain/product';
import { DiscoveryTrackingEvents } from '@bc/discovery/domain/tracking';
import { useCatalogTilesPerRow } from '../composables/use-catalog-tiles-per-row';
import useCatalogTrackingData from '../composables/use-catalog-tracking-data';
import { mapRefinements } from '../services/tracking';
import { useEventBus } from '@bc/shared';

export default defineComponent({
  name: 'CatalogHits',
  components: { CollectionBanner, SkeletonCard, AisCustomHits, CatalogGrid, CatalogTile },
  props: {
    config: {
      type: Object as PropType<Config>,
      required: true,
    },
    relatedCollectionInsertions: {
      type: Object as PropType<CatalogGridInsertion>,
      default: undefined,
    },
  },
  setup(props) {
    const { eventBus } = useEventBus();
    const {
      aisState: { status, hits, pagination, refinements },
    } = useAisState();
    const items = computed(() => transformItems(hits.value));
    const page = computed(() => pagination.value.page);
    const catalogTrackingData = useCatalogTrackingData();
    const trackingValueProposition = computed(() => ({
      value_proposition: catalogTrackingData.valueProposition.value,
      value_proposition_id: catalogTrackingData.valuePropositionId.value,
    }));
    const trackingFilters = computed(() => mapRefinements(catalogTrackingData.config.value, refinements.value));

    const { lang } = useGlobals();
    const { endProgressBar } = useActions(['endProgressBar']);
    const { storeProductsFromAlgolia } = useActions(['storeProductsFromAlgolia']);
    const tilesPerRow = useCatalogTilesPerRow();

    const isProductHit = (item: ProductHit | BrandHit): item is ProductHit => 'brand' in item;

    const transformItems = (items: Array<ProductHit | BrandHit>) => {
      let transformedItems = [];
      if (items?.length) {
        transformedItems = items.map((item) => ProductAdapter.hitAdapter(lang, item));
        if (items?.every(isProductHit)) {
          storeProductsFromAlgolia({ products: transformedItems });
        }
      }
      endProgressBar();
      return transformedItems;
    };

    const isSearching = computed(() => status.value === 'loading' || status.value === 'stalled' || isWaitingForSlots.value);

    const relatedCollections = ref({
      slots: [],
      config: {},
    });
    const gridConfig = ref<CatalogUiTypes.GridConfig>(undefined);

    watchEffect(() => {
      const value = {
        slots: [],
        config: {},
      };
      if (props.relatedCollectionInsertions?.status === 'idle') {
        value.slots = props.relatedCollectionInsertions.slots;
        value.config = props.relatedCollectionInsertions.slots.reduce((acc, slot) => {
          acc[slot.name] = { row: slot.insertionRowIndex + 1 };
          return acc;
        }, {});
      }
      nextTick(() => {
        relatedCollections.value = value;
        gridConfig.value = value.config;
      });
    });

    const handleRelatedCollectionClicked = (collection: CollectionTypes.CollectionCard) => {
      eventBus.emit(DiscoveryTrackingEvents.RELATED_COLLECTION_CLICKED, {
        collectionId: collection.uuid,
        itemType: collection.itemType,
      });
    };

    const isWaitingForSlots = computed(() => props.relatedCollectionInsertions?.status === 'loading');

    const redraw = ref(0);
    watch(
      status,
      () => {
        if (status.value === 'idle') {
          nextTick(() => {
            redraw.value = redraw.value + 1;
          });
        }
      },
      { immediate: true }
    );

    return {
      isSearching,
      redraw,
      status,
      items,
      page,
      tilesPerRow,
      gridConfig,
      handleRelatedCollectionClicked,
      isWaitingForSlots,
      relatedCollections,
      trackingFilters,
      trackingValueProposition,
      transformItems,
      ...catalogTrackingData,
      isVNode,
    };
  },
});
</script>
