import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import { GroupedResults } from '@bc/discovery/domain/search';
import { isEnabled } from '@monolith/legacy/services/features';

export { SEARCH_STORE_ID } from './constants';
export const HISTORICAL_SEARCH_KEY = 'historicalSearch';
export const RECENT_SEARCH_GROUP = 'recent-search';
export const PREFILTERED_PREFIX = 'suggestion-prefiltered-';
export const MAX_RESULTS = 10;
export const MAX_NUM_CATEGORY_RESULTS = 2;
export const MAX_NUM_RECENT_SEARCHES = isEnabled('RET-1653') ? 10 : 4;
export const MAX_NUM_RECENT_SEARCHES_WITH_QUERY = isEnabled('RET-1653') ? 10 : 2;
export const MAX_SUGGESTION = 4;
export const SHOW_ALL_ID = -998;
export const R2B_LINK_ID = -999;
export const R2B_LINK_HREF = '/account/suggestbrand';
export const DEFAULT_ARROW_POSITION = -1;
export const DefaultResults: GroupedResults = {
  trending: [],
  suggestions: [],
  categories: [],
  brands: [],
  searchAll: null,
  r2bLink: null,
  history: [],
  boutiques: [],
};

export const state = {
  originalUserInput: '',
  isOpen: false,
  arrowPosition: DEFAULT_ARROW_POSITION,
  shouldDisplayBrandResults: false,
  results: DefaultResults,
  resultsIds: [],
  userLocalHistory: [],
};
export type SearchState = typeof state;

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
