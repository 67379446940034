<script lang="ts">
import { trackOrderCompleted } from '@monolith/legacy/services/analytics/segment-ecommerce-v2/track';
import { Checkout } from '@monolith/legacy/types/cart';
import { VNode, defineComponent, h } from 'vue';

export default defineComponent({
  name: 'CheckoutThanksAnalytics',
  props: {
    checkout: {
      type: Object as () => Checkout,
      required: true,
    },
  },
  mounted() {
    if (this.checkout) {
      trackOrderCompleted(this.checkout);
    }
  },
  render(): VNode {
    return h('div', {});
  },
});
</script>
