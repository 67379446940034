<template>
  <div>
    <slot :errors="errors" />
    <slot
      name="submit"
      :disabled="isDisabled"
      :handle-submit="handleSubmit"
    >
      <AkButton
        type="button"
        size="xl"
        color="primary"
        class="ds-my-4"
        :disabled="isDisabled"
        @click="formBus.emit('submit')"
      >
        {{ $t('Submit') }}
      </AkButton>
    </slot>
  </div>
</template>

<script lang="ts">
import {
  TFormValue,
  IFormValuesObject,
  IFormErrors,
  FormValuesObject,
  IFormUpdateValueEventPayload,
  IFormUpdateErrorEventPayload,
} from '@monolith/legacy/components/review/AkForm/types';
import { defineComponent, PropType, Ref, ref, provide, computed, watch } from 'vue';
import { AkButton } from '@ankorstore/design-system';
import { EventEmitter } from '@bc/shared';
import { cloneDeep } from 'lodash-es';

export default defineComponent({
  name: 'AkForm',
  components: {
    AkButton,
  },
  props: {
    values: {
      type: Object as PropType<IFormValuesObject>,
      default: undefined,
    },
    modelValue: {
      type: Object as PropType<IFormValuesObject>,
      default: undefined,
    },
  },
  emits: ['submit', 'submit-error', 'update:modelValue'],
  setup(props, { emit }) {
    const defaultValues: IFormValuesObject = Object.assign(
      Object.create(null),
      props.values && typeof props.values === 'object' ? props.values : {},
      props.modelValue && typeof props.modelValue === 'object' ? props.modelValue : {}
    );

    const formBus = new EventEmitter();
    const fields = new Map();
    const proxyValues: Ref<IFormValuesObject> = ref({});
    const errors: Ref<IFormErrors> = ref({});

    const isValidating = ref<boolean>(false);
    const isSubmitting = ref<boolean>(false);
    const submissionAttempts = ref<number>(0);

    const isSubmitted = computed<boolean>(() => submissionAttempts.value > 0);
    const isValid = computed<boolean>(() => Object.values(errors.value).every((error) => error === undefined));
    const isDisabled = computed<boolean>(() => (isSubmitted.value && !isValid.value) || isSubmitting.value);

    function getFieldDefaultValue(name: string, groups: string[]): TFormValue {
      return FormValuesObject.getValue(defaultValues, name, groups);
    }

    function registerField(field) {
      const { name, groups, controlComponentRef, validateField } = field;
      fields.set(name, { groups, controlComponentRef, validateField });
      FormValuesObject.initializeValue(proxyValues, name, getFieldDefaultValue(name, groups), groups);
      errors.value = {
        ...errors.value,
        [field.name]: undefined,
      };
    }

    formBus.on('register-field', registerField);
    formBus.on('update-value', (payload: IFormUpdateValueEventPayload) => {
      FormValuesObject.setValue(proxyValues, payload.name, payload.value, payload.groups);
    });
    formBus.on('update-error', (payload: IFormUpdateErrorEventPayload) => {
      errors.value = {
        ...errors.value,
        [payload.name]: payload.error,
      };
    });
    formBus.on('submit', handleSubmit);

    watch(
      () => proxyValues.value,
      () => emit('update:modelValue', cloneDeep(proxyValues.value)),
      { deep: true }
    );

    provide('formBus', formBus);
    provide('proxyValues', proxyValues);
    provide('errors', errors);
    provide('formState', {
      isSubmitted,
      isValidating,
    });
    provide('groups', []);

    async function validateForm(): Promise<void> {
      isValidating.value = true;
      const fieldArray = Array.from(fields.values());
      for (const field of fieldArray) {
        await field.validateField();
      }
      isValidating.value = false;
    }

    async function handleSubmit(): Promise<void> {
      isSubmitting.value = true;
      submissionAttempts.value++;
      await validateForm();
      if (isValid.value) {
        emit('submit', cloneDeep(proxyValues.value));
      } else {
        emit('submit-error', cloneDeep(errors.value));
      }
      isSubmitting.value = false;
    }

    return {
      formBus,
      proxyValues,
      errors,
      handleSubmit,
      isValidating,
      isSubmitting,
      isSubmitted,
      isValid,
      isDisabled,
      submissionAttempts,
    };
  },
});
</script>
