import { isLoggedInGuard } from '@core/routes/router-guard';
import type { RouteRecordRaw } from 'vue-router';

export const ConnectedHomeRoutes: RouteRecordRaw[] = [
  {
    name: 'connected',
    path: '/connected',
    beforeEnter: isLoggedInGuard,
    component: () => import('./pages/connected-home.vue'),
  },
];
