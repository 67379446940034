import { Category } from '@monolith/legacy/types/api/category';
import { ProductCategories, ProductCategory } from '@monolith/legacy/types/product-category';
import { RouteLocationNormalized } from 'vue-router';
import { getCategories } from '../api/categories';
import { flatten } from '../utils/flatten';
import { memoize } from 'lodash-es';

function getName(category: Category) {
  const names = category.full_name.split('>');
  return names[names.length - 1]?.trim();
}

function fixFullName(category: ProductCategory): ProductCategory {
  let { name: full_name, parent } = category;

  while (parent) {
    full_name = parent.name ? `${parent.name} > ${full_name}` : full_name;
    parent = parent.parent;
  }

  return {
    ...category,
    full_name,
  };
}

function toProductCategory(rootCategory?: ProductCategory, parent?: ProductCategory): (category: Category) => ProductCategory {
  return (category) => {
    const productCategory: ProductCategory = fixFullName({
      type: 'product_category',
      id: category.id,
      level: category.level,
      path: rootCategory ? category.path.replace('/shop', rootCategory.path) : category.path,
      full_name: category.full_name,
      name: getName(category),
      parent,
    });

    productCategory.children = category.children.map(toProductCategory(rootCategory, productCategory));

    return productCategory;
  };
}

interface CreateRootCategoryParams {
  name?: string;
  path?: string;
  children?: ProductCategory[];
}

export const createRootCategory = ({ name = '', path = '/shop', children = [] }: CreateRootCategoryParams): ProductCategory => ({
  type: 'product_category',
  full_name: '',
  id: -1,
  level: -1,
  name,
  path,
  children,
});

export function findCategoryFromRoute(categories: ProductCategories, route: RouteLocationNormalized) {
  return categories?.graph.find((category) => category.path === route?.path);
}

export const getProductCategories = memoize(async function getProductCategories(path?: string): Promise<ProductCategories> {
  const categories = await getCategories();

  const rootCategory = createRootCategory({ path });
  rootCategory.children = categories.map(toProductCategory(rootCategory, rootCategory));

  const graph = [rootCategory, ...flatten(rootCategory.children, (category) => category.children)];

  const productCategories = {
    tree: rootCategory,
    graph,
  };

  return productCategories;
});
