import { ActionContext } from 'vuex';
import { getWalletBalance, getWalletStatement, getWalletAmountAvailable } from '@monolith/legacy/services/api/wallet';
import { WalletBalance, WalletOperation } from '@monolith/legacy/types/wallet';
import { PaginationItem } from '@monolith/legacy/types/pagination';
import { Amount } from '@core/types/amount';

interface WalletCartBalance {
  available_amount: Amount;
  total_amount: Amount;
}
interface WalletState {
  wallet: WalletBalance;
  cart: WalletCartBalance;
  wallet_balance_fetched: boolean;
  wallet_operations: WalletOperation[];
  wallet_operations_pagination: PaginationItem[];
}

const initialState: WalletState = {
  wallet: {
    balance_amount: null,
  },
  cart: {
    available_amount: null,
    total_amount: null,
  },
  wallet_balance_fetched: false,
  wallet_operations: [],
  wallet_operations_pagination: [],
};

export default {
  namespaced: true,
  state: initialState,
  actions: {
    fetchWalletBalance: async ({ commit, state, rootGetters }: ActionContext<WalletState, any>): Promise<void> => {
      if (!state.wallet_balance_fetched) {
        const { balance_amount } = await getWalletBalance(rootGetters.userIsRetailer);
        commit('SET_WALLET_BALANCE', balance_amount);
        commit('SET_WALLET_BALANCE_FETCHED', true);
      }
    },
    fetchWalletStatement: async ({ commit, rootGetters }: ActionContext<WalletState, any>, url?: string): Promise<void> => {
      const { wallet, wallet_operations, wallet_operations_pagination } = await getWalletStatement({
        userIsRetailer: rootGetters.userIsRetailer,
        url,
      });
      commit('SET_WALLET_BALANCE', wallet.balance_amount);
      commit('SET_WALLET_OPERATIONS', wallet_operations);
      commit('SET_WALLET_OPERATIONS_PAGINATION', wallet_operations_pagination);
    },
    fetchWalletAmountsAvailable: async ({ commit }: ActionContext<WalletState, any>): Promise<void> => {
      const { totalAmount, availableAmount } = await getWalletAmountAvailable();
      commit('SET_CART_TOTAL_AMOUNT', totalAmount);
      commit('SET_CART_AVAILABLE_AMOUNT', availableAmount);
    },
  },
  mutations: {
    SET_WALLET_BALANCE(state: WalletState, value: Amount): void {
      state.wallet.balance_amount = value;
    },
    SET_CART_TOTAL_AMOUNT(state: WalletState, value: Amount): void {
      state.cart.total_amount = value;
    },
    SET_CART_AVAILABLE_AMOUNT(state: WalletState, value: Amount): void {
      state.cart.available_amount = value;
    },
    SET_WALLET_BALANCE_FETCHED(state: WalletState): void {
      state.wallet_balance_fetched = true;
    },
    SET_WALLET_OPERATIONS(state: WalletState, value: WalletOperation[]): void {
      state.wallet_operations = value;
    },
    SET_WALLET_OPERATIONS_PAGINATION(state: WalletState, value: PaginationItem[]): void {
      state.wallet_operations_pagination = value;
    },
  },
  getters: {
    hasWalletBalance(state: Pick<WalletState, 'wallet'>): boolean {
      const amount = state.wallet.balance_amount?.amount;
      return !!amount && amount > 0;
    },
    hasWalletOperations(state: WalletState): boolean {
      const operationsCount = state.wallet_operations?.length;
      return !!operationsCount && operationsCount > 0;
    },
    hasWalletAvailableAmountInCart(state: WalletState): boolean {
      return state.cart?.available_amount?.amount > 0;
    },
    walletBallance(state: Pick<WalletState, 'wallet'>): Amount {
      return state.wallet.balance_amount;
    },
  },
};
