import { RouteLocationNormalized } from 'vue-router';
import AnalyticsEvent from './analytics/events/analytics-event';
import { getPageInfo } from './analytics/get-page-info';
import { getExperiments, getBrowserId } from './features';
import { getUserTraitsFromStore } from './analytics/get-user-traits';
import { SegmentUserTraits } from '@monolith/legacy/types/segment';
import { mergeWith, isNumber, isString } from 'lodash-es';
import { isEnabled } from '@monolith/legacy/services/features';

type EventName = string;

function isVueRouterRoute(route: RouteLocationNormalized): boolean {
  return route && route.name !== null && route.matched.length > 0;
}

function getHashParameters(hash = '#'): Record<string, string> {
  const hashParameters: Record<string, string> = {};

  new URLSearchParams(hash.substring(1)).forEach((value, key) => {
    hashParameters[key] = value;
  });

  return hashParameters;
}

const getPageOverride = () => ({
  path: window.location.pathname,
  url: window.location.href,
  referrer: window.document.referrer,
  search: window.location.search,
  hash: window.location.hash,
  title: window.document.title,
});

export default class Analytics {
  public static async page(
    args?: { to: RouteLocationNormalized; from: RouteLocationNormalized },
    userTraits?: SegmentUserTraits
  ): Promise<void> {
    const location = args?.to ? { pathname: args.to.path, hash: args.to.hash } : window.location;
    const traits: SegmentUserTraits = userTraits || (await getUserTraitsFromStore());
    const pageInfo = getPageInfo(location);
    const properties: {
      __experiments: Record<string, string>[];
      __browser_id: string;
      referrer?: string;
      url?: string;
      hash: Record<string, string>;
      path?: string;
    } = {
      __experiments: this.mapExperiments(),
      __browser_id: getBrowserId(),
      ...getPageOverride(),
      hash: getHashParameters(location.hash),
    };

    if (isVueRouterRoute(args?.from)) {
      // Override the Segment referrer if coming from a client side route.
      // Client side routing does not change the referrer. It will always be
      // the original route that loaded the Vue application (SPA) by default
      properties.referrer = Analytics.createReferrer(args.from, window.location);
    }

    if (pageInfo) {
      return window.analytics?.page(pageInfo.category, pageInfo.name, {
        ...properties,
        ...pageInfo?.properties,
      });
    }

    return window.analytics?.page(properties, {
      context: {
        traits,
      },
    });
  }

  public static async track(event: AnalyticsEvent, userTraits?: SegmentUserTraits): Promise<void> {
    const traits: SegmentUserTraits = userTraits || (await getUserTraitsFromStore());

    if (isEnabled('gtm-snippet')) {
      const userId = window.analytics?.user()?.id();
      const dataLayer = {
        event: event.name,
        ...(userId && { userId }),
        ...event.properties,
        __experiments: this.mapExperiments(),
        __browser_id: getBrowserId(),
      };
      window.dataLayer?.push(dataLayer);
    }

    return window.analytics?.track(
      event.name,
      {
        ...event.properties,
        __experiments: this.mapExperiments(),
        __browser_id: getBrowserId(),
      },
      {
        context: {
          traits,
          page: getPageOverride(),
        },
      }
    );
  }

  private static readonly eventBuffer = new Map<EventName, { timeoutId: number; events: AnalyticsEvent[] }>();
  /**
   * Buffer events of the same name using the specified buffer time and merge all before tracking.
   * The buffer time resets on each call for the same event using a debounce technique.
   */
  public static trackMergeBuffer(
    event: AnalyticsEvent,
    customMerge: (objValue: unknown, srcValue: unknown, key: string, object) => unknown = undefined,
    bufferTimeMs = 500
  ): void {
    const buffer = Analytics.eventBuffer.get(event.name) ?? {
      timeoutId: undefined,
      events: [],
    };

    if (buffer.timeoutId !== undefined) {
      clearTimeout(buffer.timeoutId);
    }

    buffer.timeoutId = window.setTimeout(() => {
      const mergedEvents: AnalyticsEvent = mergeWith(
        { name: event.name, properties: {} },
        ...Analytics.eventBuffer.get(event.name).events,
        (objValue: unknown, srcValue: unknown, key: string, object) => {
          const mergedValue = customMerge?.(objValue, srcValue, key, object);

          if (mergedValue) {
            return mergedValue;
          }

          if (Array.isArray(objValue) && objValue.every((item) => isNumber(item) || isString(item))) {
            return Array.from(new Set(objValue.concat(srcValue)));
          }
          return undefined;
        }
      );

      Analytics.eventBuffer.delete(event.name);
      Analytics.track(mergedEvents);
    }, bufferTimeMs);

    buffer.events = buffer.events.concat(event);

    Analytics.eventBuffer.set(event.name, buffer);
  }

  public static identify(userId: number | string, userTraits: SegmentUserTraits): void {
    return window.analytics?.identify(userId.toString(), userTraits);
  }

  private static mapExperiments(): Array<Record<string, string>> {
    const experiments = getExperiments();
    return Object.keys(experiments).map(function (experimentName: string): Record<string, string> {
      return {
        experiment_name: experimentName,
        variant_name: experiments[experimentName],
      };
    });
  }

  private static createReferrer(route: RouteLocationNormalized, location: Location): string {
    return `${location.origin}${route.fullPath}`;
  }
}
