import Analytics from '@monolith/legacy/services/analytics';
import UserClick from '@monolith/legacy/services/analytics/events/user-click.event';
import ViewTile from '@monolith/legacy/services/analytics/events/view-tile.event';
import type { IdPosition } from '@bc/shared';
import { memoize } from 'lodash-es';
import type { ESProductHit } from '@bc/discovery/domain/catalog';
import { getCustomTrackMergeFn, getSectionId, SectionType, SectionItemType, SectionCreatedEvent } from '@bc/shared';
import { getProductSnippetTracking } from '@bc/discovery/domain/tracking';
import { ref } from 'vue';
import type { ProductProperty } from '@monolith/legacy/services/analytics/properties/product-property';
import type { BrandProperty } from '@monolith/legacy/services/analytics/properties/brand-property';

const matchHypens = /-/g;

export const mergeViewEvents = getCustomTrackMergeFn(['position', 'id_product']);

// RecommendedProducts

export type TrackProductTileClickPayload = {
  productId: string;
  position: number;
};
export type TrackProductTileViewPayload = {
  productId: number;
  position: number;
};
export type TrackRecommendedProductsSectionCreatedPayload = {
  products: IdPosition[];
};

export const getRecommendedProductsTracking = memoize(() => {
  const valueProposition = 'loved_by_retailers_like_you';
  const sectionType = SectionType.RecoCarousel;
  const sectionId = getSectionId({
    valueProposition: valueProposition,
    sectionType: sectionType,
  });
  return {
    trackRecommendedProductClick: (payload: TrackProductTileClickPayload) => {
      Analytics.track(
        new UserClick({
          id_section: sectionId,
          component: sectionType,
          action: 'click_loved_by_retailers_like_you',
          itemtype: SectionItemType.Product,
          product_id: payload.productId,
          position: payload.position,
        })
      );
    },
    trackRecommendedProductView: (payload: TrackProductTileViewPayload) => {
      Analytics.trackMergeBuffer(
        new ViewTile({
          id_section: sectionId,
          id_product: [
            {
              id: payload.productId,
              position: payload.position,
            },
          ],
          position: payload.position,
          value_proposition: valueProposition,
          section_type: sectionType,
        }),
        mergeViewEvents
      );
    },
    trackRecommendedProductSectionCreated: (payload: TrackRecommendedProductsSectionCreatedPayload) => {
      Analytics.track(
        new SectionCreatedEvent({
          id_section: sectionId,
          section_type: sectionType,
          value_proposition: valueProposition,
          itemtype: SectionItemType.Product,
          id_product: payload.products,
        })
      );
    },
  };
});

// RecommendedCategories

export type TrackRecommendedCategoryProductSectionCreatedPayload = {
  categorySlugEn: string;
  products: IdPosition[];
};

export type TrackRecommendedCategoryProductTileViewPayload = {
  position: number;
  productId: number;
  categorySlugEn: string;
};

export type TrackRecommendedCategoryProductTileClickPayload = {
  position: number;
  categorySlugEn: string;
  itemType: SectionItemType.Product | SectionItemType.Brand;
  product_id?: string;
  brand_id?: string;
};

export const getRecommendedCategoriesTracking = memoize(() => {
  const getFormattedSlug = memoize((categorySlug: string) => categorySlug.replace(matchHypens, '_'));
  const getValueProposition = (categorySlugEn: string) => `${getFormattedSlug(categorySlugEn)}_you_may_like`;
  const getClickAction = (categorySlugEn: string) => `click_${getFormattedSlug(categorySlugEn)}_you_may_like`;
  const sectionType = SectionType.RecoCarousel;
  const sectionId = memoize((categoryName: string) =>
    getSectionId({
      valueProposition: getValueProposition(categoryName),
      sectionType: sectionType,
    })
  );
  return {
    trackRecommendedCategoriesProductSectionCreated: (payload: TrackRecommendedCategoryProductSectionCreatedPayload) => {
      Analytics.track(
        new SectionCreatedEvent({
          id_section: sectionId(payload.categorySlugEn),
          section_type: sectionType,
          value_proposition: getValueProposition(payload.categorySlugEn),
          itemtype: SectionItemType.Product,
          id_product: payload.products,
        })
      );
    },
    trackRecommendedCategoriesProductViewed: (payload: TrackRecommendedCategoryProductTileViewPayload) => {
      Analytics.trackMergeBuffer(
        new ViewTile({
          id_section: sectionId(payload.categorySlugEn),
          id_product: [
            {
              id: payload.productId,
              position: payload.position,
            },
          ],
          itemtype: SectionItemType.Product,
          position: payload.position,
          value_proposition: getValueProposition(payload.categorySlugEn),
          section_type: sectionType,
        }),
        mergeViewEvents
      );
    },
    trackRecommendedCategoriesProductClick: (payload: TrackRecommendedCategoryProductTileClickPayload) => {
      const clickData = {
        id_section: sectionId(payload.categorySlugEn),
        component: sectionType,
        action: getClickAction(payload.categorySlugEn),
        itemtype: payload.itemType,
        position: payload.position,
      };
      if (payload.product_id) {
        clickData['product_id'] = payload.product_id;
      } else if (payload.brand_id) {
        clickData['brand_id'] = payload.brand_id;
      }
      Analytics.track(new UserClick(clickData));
    },
  };
});

type GetProductTrackingParams = {
  valueProposition: string;
  component: string;
  sectionType: SectionType;
};
export const getProductCarouselTracking = ({ valueProposition, component, sectionType }: GetProductTrackingParams) => {
  const sectionId = getSectionId({
    valueProposition,
    sectionType,
  });

  const productSnippetTracking = getProductSnippetTracking(ref(sectionId), ref(undefined), ref(sectionType));
  return {
    sectionId,
    trackSectionCreated: async (payload: { products: ESProductHit[] }) => {
      await Analytics.track(
        new SectionCreatedEvent({
          id_section: sectionId,
          section_type: sectionType,
          value_proposition: valueProposition,
          itemtype: SectionItemType.Product,
          id_product: payload.products.map((product, index) => ({ id: product.id, position: index })),
        })
      );
    },
    trackCtaClicked: async () => {
      await Analytics.track(
        new UserClick({
          id_section: sectionId,
          component,
          action: 'cta_clicked',
          itemtype: 'collection',
          value_proposition: valueProposition,
        })
      );
    },
    trackProductClicked: async (payload: { product: ESProductHit; position: number }) => {
      await Analytics.track(
        new UserClick({
          id_section: sectionId,
          component,
          action: 'product_clicked',
          itemtype: SectionItemType.Product,
          value_proposition: valueProposition,
          productId: payload.product.id,
          position: payload.position,
        })
      );
    },
    trackBrandClicked: (payload: { product: ESProductHit; position: number }) => {
      Analytics.track(
        new UserClick({
          id_section: sectionId,
          component,
          action: 'brand_clicked',
          itemtype: SectionItemType.Product,
          value_proposition: valueProposition,
          productId: payload.product.id,
          brandId: payload.product.brand.id,
          position: payload.position,
        })
      );
    },
    trackQuickAddToCartClicked: async (payload: { product: ESProductHit; position: number }) =>
      productSnippetTracking.trackQuickAddToCartClicked(payload),
    trackVariantAddedToCart: async (payload: {
      product: ESProductHit;
      position: number;
      quantity: number;
      productProperty: ProductProperty;
      brandProperty: BrandProperty;
    }) => productSnippetTracking.trackVariantAddedToCart(payload),
    trackProductLiked: async (payload: { product: ESProductHit; position: number }) =>
      productSnippetTracking.trackProductLiked({
        product: payload.product,
        position: payload.position,
        id_product: [
          {
            id: payload.product.id,
            position: payload.position,
          },
        ],
      }),
    trackProductDisliked: async (payload: { product: ESProductHit }) => productSnippetTracking.trackProductDisliked(payload),
  };
};
