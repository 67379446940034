<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { CaseReasonStep } from '@monolith/legacy/types/order-retailer-rejection';
import { OrderIssueClickedAction } from '@monolith/legacy/services/analytics/properties/order-issue-property';
import { orderIssueAnalyticsMixin } from '@monolith/legacy/components/account/orders/order/order-retailer-reject/mixins/order-issue-analytics';

export default defineComponent({
  name: 'BaseCase',
  mixins: [orderIssueAnalyticsMixin],
  props: {
    hasFormErrors: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  emits: ['set-loader'],
  data() {
    return {
      unsubscribe: () => {},
    };
  },
  computed: {
    ...(mapGetters('orderRetailerRejection', ['getCurrentStep']) as { getCurrentStep: () => { key: CaseReasonStep } }),
    showBackButton(): boolean {
      return !(
        this.getCurrentStep.key === CaseReasonStep.TrackWithLink ||
        this.getCurrentStep.key === CaseReasonStep.ContactBrand ||
        this.getCurrentStep.key === CaseReasonStep.OrderInfo
      );
    },
  },
  mounted() {
    this.unsubscribe = this.$store.subscribeAction((action) => {
      if (action.type !== 'salesforce/popinClosed') {
        return;
      }

      this.trackClickedAction(OrderIssueClickedAction.CLOSE);
    });
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  methods: {
    setLoader(value: boolean): void {
      this.$emit('set-loader', value);
    },
  },
});
</script>
