import {
  getCart,
  updateItemsQuantities,
  getAvailableCredit,
  updateBrandSelection as _updateBrandSelection,
} from '@monolith/legacy/modules/cart-2/service';
import { useStore } from '@core/composables/use-store';
import { computed, ref } from 'vue';
import { Cart2, NotificationCallback, PurchasableAggregate } from '@monolith/legacy/modules/cart-2/types';
import { capturePurchasingException } from '@core/plugins/sentry/helper';
import { Amount } from '@core/types/amount';
import { throttle } from 'lodash-es';
import { BrandDiscount, BrandDiscountContext } from '@monolith/legacy/types/api/brand-discount';
import { getPersonalDiscountWithUuid } from '@monolith/legacy/services/api/brand-discount';
import usePrices from '@core/composables/use-price';
import {
  getAllOutOfStockProducts,
  getBrandIdsFromCart,
  getBrandsFromCart,
  getBrandsFromCartWithoutProductsDetails,
  calculatePercentageMinimumReached
} from '@monolith/legacy/modules/cart-2/utils';
import { StockAlert, createBackInStockAlert, getBackInStockAlerts } from '@monolith/legacy/services/back-in-stock/back-in-stock';
import { isEnabled } from '@monolith/legacy/services/features';
import i18n from '@monolith/legacy/services/i18n';
import { ToasterType } from '@ankorstore/design-system';
import { trackCartPreviewViewed, trackCartViewed2Ms } from '@monolith/legacy/services/analytics/segment-ecommerce-v2/track';
import { useNotifications } from './use-notifications';
import { getPromisedDeliveryDates } from '@monolith/legacy/services/api/promised-delivery-date';
import { captureException } from '@core/plugins/sentry';
import { PromisedDeliveryDate } from '@monolith/legacy/types/api/promised-delivery-date';
import { productsCount } from './use-counters';
import { getBrandDataFromAggregate } from '../utilities/cart';

// * Global state * //
const cart = ref<Cart2>(null);
const restockAlerts = ref<string[]>(null);
const discounts = ref<BrandDiscount[]>([]);
const regularOrderCreditAvailable = ref<Amount>(null);
const preOrderCreditAvailable = ref<Amount>(null);
const shouldDisplayEmptyCart = ref<boolean>(false);
const promisedDeliveryDates = ref<PromisedDeliveryDate[]>([]);

// Dependencies
const { createNotification, createGenericErrorNotification } = useNotifications();

const setCartState = (cartState: Cart2): void => {
  cart.value = cartState;
  shouldDisplayEmptyCart.value = !(cartState.itemAggregates?.data?.[0]?.items?.data?.length > 0);
  productsCount.value = cartState.totalItemCount;
};

// * quantities updates * //
// performQuantitiesUpdates is a throttled function that will update the cart with the pending quantities updates
let pendingQuantitiesUpdates: Record<string, number> = {};
const performQuantitiesUpdates = throttle(async () => {
  if (Object.keys(pendingQuantitiesUpdates).length === 0) {
    return;
  }
  try {
    setCartState(await updateItemsQuantities(cart?.value?.id, pendingQuantitiesUpdates));
    pendingQuantitiesUpdates = {};

    return;
  } catch (e) {
    capturePurchasingException(new Error('Error updating item quantity', { cause: e }));
    createGenericErrorNotification(true);
    return;
  }
}, 2000);

export function useCart() {
  const { formatPrice } = usePrices();
  const store = useStore();
  const retailer = store.getters.retailer;

  const isLoading = ref<number>(0);

  const fetchCart = async (): Promise<void> => {
    isLoading.value++;
    try {
      setCartState(await getCart(retailer.cart_uuid));

      return Promise.resolve();
    } catch (e) {
      capturePurchasingException(new Error('Error fetching cart on cart page', { cause: e }));
      throw e;
    } finally {
      isLoading.value--;
    }
  };

  const fetchPersonalBrandDiscounts = async (cart: Cart2): Promise<void> => {
    const brandIds = getBrandIdsFromCart(cart);
    try {
      discounts.value = await getPersonalDiscountWithUuid(BrandDiscountContext.cartPage, brandIds);
    } catch (e) {
      capturePurchasingException(e.message);
    }
  };

  const fetchAvailableCredit = async (retailerId: string): Promise<void> => {
    isLoading.value++;
    try {
      const { regularOrderCredit, preOrderCredit } = await getAvailableCredit(retailerId);

      regularOrderCreditAvailable.value = regularOrderCredit;
      preOrderCreditAvailable.value = preOrderCredit;
    } catch (e) {
      capturePurchasingException(new Error('Error fetching retailer available credit on cart page', { cause: e }));
    } finally {
      isLoading.value--;
    }
  };

  const fetchPromisedDeliveryDate = async (cart: Cart2): Promise<void> => {
    if (isEnabled('promised_delivery_date')) {
      try {
        const brandUuids = getBrandIdsFromCart(cart);
        if (brandUuids?.length) {
          promisedDeliveryDates.value = await getPromisedDeliveryDates(brandUuids);
        }
      } catch (e) {
        captureException(e);
      }
    }
  };

  const initCart = async (): Promise<void> => {
    // Todo: Check error handling at the component page level
    const startLoading = performance.now();
    fetchAvailableCredit(retailer.uuid);
    await fetchCart();

    if (!cart.value) {
      return;
    }
    const loadingDuration = performance.now() - startLoading;
    fetchPersonalBrandDiscounts(cart.value);
    fetchRestockAlerts(getAllOutOfStockProducts(cart.value));
    fetchPromisedDeliveryDate(cart.value).then(() => {
      trackCartViewed2Ms(cart.value.id, getBrandsFromCart(cart.value, promisedDeliveryDates.value), loadingDuration);
    });

    // No longer used (maybe in the future if the savedForLater is no more fetched in the cart)
    // fetchCounts(retailer.uuid);
  };

  // * User actions * //

  const trackCartPreviewViewedWrapper = () => {
    trackCartPreviewViewed(retailer.id, getBrandsFromCartWithoutProductsDetails(cart.value))
  }

  const updateItemQuantity = async (itemId: string, quantity: number): Promise<void> => {
    pendingQuantitiesUpdates[itemId] = quantity;
    await performQuantitiesUpdates();
  };

  const updateBrandSelection = async (aggregate: PurchasableAggregate, selected: boolean): Promise<void> => {
    try {
      setCartState(
        await _updateBrandSelection(
          cart.value.id,
          cart.value.itemAggregates?.data.map((a) => ({
            ...a,
            selected: a.id === aggregate.id ? selected : a.selected,
          }))
        )
      );
    } catch (e) {
      capturePurchasingException(new Error('Error updating brands selection from cart', { cause: e }));
      createGenericErrorNotification(true);
    }
  };

  const removeBrand = async (aggregate: PurchasableAggregate): Promise<void> => {
    try {
      setCartState(
        await updateItemsQuantities(
          cart.value.id,
          Object.fromEntries(aggregate.items?.data?.map((item) => [item.purchasableItemID, 0]))
        )
      );
    } catch (e) {
      capturePurchasingException(new Error('Error removing brand from cart', { cause: e }));
      createGenericErrorNotification(true);
    }
  };

  const showInternationalShippingReimbursement = (cartBrand: PurchasableAggregate): boolean => {
    return (
      isEnabled('international_shipping_messages') &&
      ((cartBrand?.brand?.data?.country?.isoCode === 'GB' && retailer?.country?.iso_code !== 'GB') ||
        (cartBrand?.brand?.data?.country?.isoCode !== 'GB' && retailer?.country?.iso_code === 'GB')) &&
      cartBrand.hasReachedBrandMinimumOrderValue
    );
  };

  const itemAggregates = computed(() => {
    return cart.value?.itemAggregates?.data;
  });

  const cartSelectionTotal = computed(() => formatPrice(cart?.value?.selectionTotalAmount, '$0.00'));

const cartTotalAmount = computed(() => {
  return itemAggregates.value?.reduce((acc, item) => {
    acc.amount += item.totalAmountAfterDiscount.amount;
    return acc;
  }, { amount: 0, currency: itemAggregates.value[0]?.totalAmountAfterDiscount?.currency });
});

  const formatCartTotalAmount = computed(() => formatPrice(cartTotalAmount.value, '$0.00'));

  const cartBrandsCount = computed(() => {
    return (
      cart?.value?.itemAggregates?.data?.filter((aggregate) => aggregate?.hasReachedBrandMinimumOrderValue && aggregate?.selected)
        .length || 0
    );
  });

  const getPercentageMinimumReached = (aggregate: PurchasableAggregate) => {
    const brand = getBrandDataFromAggregate(aggregate);
    return calculatePercentageMinimumReached({
      totalAmount: aggregate.totalAmountAfterDiscount,
      minAmount: brand.minimumOrderValue,
    });
  };
  // * Restock alerts * //

  const isStockAlertLoading = computed(() => restockAlerts.value === null);

  const fetchRestockAlerts = async (variantsIds: string[]): Promise<void> => {
    try {
      restockAlerts.value = (await getBackInStockAlerts(variantsIds, 'uuid')).map(
        (alert: StockAlert) => alert.product_variant_uuid
      );
      return Promise.resolve();
    } catch (e) {
      capturePurchasingException(new Error('Error fetching restock alerts', { cause: e }));
      return Promise.reject(e);
    }
  };

  const createRestockAlert: NotificationCallback = async (item) => {
    try {
      restockAlerts.value.push((await createBackInStockAlert(item.id, 'uuid')).product_variant_uuid);
      createNotification(
        i18n.global.t('retailer.cart.product.restockAlertCreated.success', { product: item.name }),
        ToasterType.Success
      );
    } catch (e) {
      createNotification(
        i18n.global.t('retailer.cart.product.restockAlertCreated.error'),
        ToasterType.Error,
        i18n.global.t('retailer.cart.toaster.error.actionLabel'),
        () => createRestockAlert(item)
      );
      capturePurchasingException(new Error('Error creating restock alert', { cause: e }));
    }
  };

  return {
    cart,
    cartBrandsCount,
    cartSelectionTotal,
    createRestockAlert,
    discounts,
    regularOrderCreditAvailable,
    preOrderCreditAvailable,
    fetchAvailableCredit,
    getBrandDataFromAggregate,
    getPercentageMinimumReached,
    initCart,
    isLoading,
    isStockAlertLoading,
    restockAlerts,
    itemAggregates,
    removeBrand,
    setCartState,
    shouldDisplayEmptyCart,
    showInternationalShippingReimbursement,
    updateItemQuantity,
    fetchPromisedDeliveryDate,
    promisedDeliveryDates,
    updateBrandSelection,
    fetchCart,
    formatCartTotalAmount,
    trackCartPreviewViewedWrapper
  };
}
