import { computed } from 'vue';
import { useRoute } from '@core/composables/use-router';

const useIsCheckout = () => {
  const route = useRoute();
  const routeName = computed(() => route.value?.name);
  const isCheckoutPage = computed(() => routeName.value === 'checkout' || routeName.value === 'payment');

  return {
    isCheckoutPage,
  };
};

export default useIsCheckout;
