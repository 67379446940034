<template>
  <div>
    <BrandCatalogTabs
      v-if="showCollectionTab"
      ref="tabs"
      v-model:active="activeTab"
      data-testid="brandCatalogTabs"
      :brand="brand"
      :has-collections="hasCollections"
      :ordered-items-number="orderedItemsNumber"
    />
    <BrandCatalogProducts
      v-if="activeTab === BrandCatalogView.Products"
      data-testid="brandCatalogProducts"
      :brand="brand"
    />
    <BrandCatalogCollections
      v-if="activeTab === BrandCatalogView.Collections"
      data-testid="brandCatalogCollections"
      :brand="brand"
    />
    <BrandCatalogOrdered
      v-if="activeTab === BrandCatalogView.Ordered && orderedItemsNumber"
      data-testid="brandCatalogOrdered"
      :items="orderedItems"
      :brand="brand"
      :ordered-brand="orderedBrand"
      :last-page="orderedItemsMeta?.last_page ?? 1"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import BrandCatalogTabs, { BrandCatalogView } from '@monolith/legacy/components/brand/catalog/brand-catalog-tabs.vue';
import BrandCatalogProducts from '@monolith/legacy/components/brand/catalog/brand-catalog-products.vue';
import BrandCatalogCollections from '@monolith/legacy/components/brand/catalog/brand-catalog-collections.vue';
import BrandCatalogOrdered from '@monolith/legacy/components/brand/catalog/brand-catalog-ordered.vue';

import { getOrderedItemsFromBrand } from '@monolith/legacy/services/reorder/reorder';
import { captureRetailerReorderException } from '@core/plugins/sentry/helper';
import { mapGetters } from 'vuex';
import { Brand } from '@monolith/legacy/types/api/brand';
import { scrollToRef } from '@core/utilities/scroll';
import { Meta } from '@monolith/legacy/types/pagination';

const TAB_BUY_AGAIN = 'buy-again';

export default defineComponent({
  name: 'BrandCatalog',
  components: {
    BrandCatalogTabs,
    BrandCatalogProducts,
    BrandCatalogCollections,
    BrandCatalogOrdered,
  },
  props: {
    brand: {
      type: Object as PropType<Brand>,
      required: true,
    },
  },
  data() {
    return {
      activeTab: BrandCatalogView.Products as BrandCatalogView,
      BrandCatalogView,
      orderedItems: null,
      orderedBrand: null,
      orderedItemsMeta: {} as Meta,
    };
  },
  computed: {
    ...mapGetters(['userIsRetailer']),
    hasCollections(): boolean {
      return !!this.brand.has_product_collection;
    },
    showCollectionTab(): boolean {
      return this.hasCollections || this.orderedItems;
    },
    orderedItemsNumber(): number {
      return this.orderedItemsMeta?.total ?? 0;
    },
  },
  async mounted() {
    if (this.userIsRetailer) {
      await this.fetchOrderedProducts();
    }
    this.scrollToTabs();
  },
  created() {
    this.activateBuyAgainTab();
  },
  methods: {
    async fetchOrderedProducts() {
      try {
        const {
          data: { products, brand },
          meta,
        } = await getOrderedItemsFromBrand(this.brand.id, {
          perPage: 10,
          page: 1,
        });
        if (products?.length) {
          this.orderedItems = products;
        }
        this.orderedBrand = brand;
        this.orderedItemsMeta = meta;
      } catch (e) {
        captureRetailerReorderException(e);
      }
    },
    activateBuyAgainTab(): void {
      if (this.$route?.query?.tab === TAB_BUY_AGAIN) {
        this.activeTab = this.BrandCatalogView.Ordered;
      }
    },
    scrollToTabs() {
      if (this.$route?.query?.tab === TAB_BUY_AGAIN) {
        scrollToRef(this.$refs.tabs);
      }
    },
  },
});
</script>
