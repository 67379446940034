import { ref } from 'vue';
import { CartToasterConfigType, NotificationCallback } from '../types';
import { ToasterType } from '@ankorstore/design-system';
import i18n from '@monolith/legacy/services/i18n';

export function useNotifications() {
  const cartNotification = ref<CartToasterConfigType>(null);

  // * Notifications * //
  // Todo: move this to dedicated composable + component (design system)
  const createNotification = (
    message: string,
    type: ToasterType = ToasterType.Success,
    actionLabel: string = null,
    cb: NotificationCallback = null
  ) => {
    if ((actionLabel && !cb) || (!actionLabel && cb)) {
      throw new Error('notification actionLabel and cb must be both defined or undefined');
    }
    return (cartNotification.value = { type, message, actionLabel, cb });
  };

  const createGenericErrorNotification = (withReloadPage = false) => {
    return createNotification(
      i18n.global.t('retailer.cart.genericErrorLabel'),
      ToasterType.Error,
      withReloadPage ? i18n.global.t('retailer.cart.toaster.reload.actionLabel') : null,
      withReloadPage ? () => Promise.resolve(location.reload()) : null
    );
  };

  return {
    cartNotification,
    createNotification,
    createGenericErrorNotification,
  };
}
