import { captureException } from '@core/plugins/sentry';
import { Mutation } from '@monolith/legacy/store/mutation-types';
import { patchFastPayment } from '@monolith/legacy/services/api/payment-configuration';

export default {
  namespaced: true,
  actions: {
    async changeFastPayment({ commit }, isFastPaymentActive: boolean) {
      try {
        await patchFastPayment(isFastPaymentActive);
        commit(Mutation.UPDATE_FAST_PAYMENT, isFastPaymentActive, {
          root: true,
        });
      } catch (e) {
        captureException(e);
      }
    },
  },
};
