import http from '@monolith/legacy/services/api/http';
import {
  BulkEditDraftsPayload,
  CatalogIntegrationSetting,
  ExportCatalogue,
  CatalogueIntegrationBatch,
  ProductIntegration,
  GoogleBatchPayload,
  CsvBatchPayload,
  XlsxBatchPayload,
  SpreadsheetMappingPayload,
  SpreadsheetMappingResponse,
} from '@monolith/legacy/types/account/account-products/catalog-integration/product-integration';

export type CatalogIntegrationSettingResponse = {
  data: { attributes: CatalogIntegrationSetting };
};

export type CatalogueIntegrationBatchResponse = {
  data: { attributes: CatalogueIntegrationBatch };
};

export type ExportCatalogueResponse = {
  data: { attributes: ExportCatalogue };
};

export const apiConfigs = {
  headers: {
    Accept: 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json',
  },
};

export type integrationsGetParameters = {
  page?: number;
  perPage?: number;
  sort?: string[];
  direction?: string;
  lang?: string;
  batch_id?: number;
  type?: string;
  status?: string;
  workflow?: string;
  'issue-type'?: string[];
  'issue-field'?: string[];
  output_format?: string;
  'filter[hasIssues]'?: boolean;
};

export type PaginationPayload = {
  'page[limit]'?: number;
  output_format?: string;
};

export const getCatalogIntegration = async (parameters: integrationsGetParameters) => {
  const apiResponse = await http().get(`/api/internal/v1/catalog-integration/integrations`, {
    params: parameters,
  });

  return apiResponse?.data;
};

export const getSingleCatalogIntegration = async (uuid: string) => {
  const apiResponse = await http().get(`/api/internal/v1/catalog-integration/integrations/${uuid}`);

  return apiResponse?.data;
};

export const getIntegrationStatistics = async () => {
  const apiResponse = await http().get('/api/internal/v1/catalog-integration/integrations/-actions/statistics');
  return apiResponse?.data;
};

export const getBatches = async (parameters: integrationsGetParameters): Promise<CatalogueIntegrationBatchResponse> => {
  parameters['filter[workflow]'] = parameters['workflow'];
  delete parameters['workflow'];
  const queryParam = new URLSearchParams(parameters as any);
  const apiResponse = await http().get(`/api/internal/v1/catalog-integration/product-integration-batches?${queryParam}`);

  return apiResponse?.data;
};

export const putInternalState = async (uuid: string, payload): Promise<ProductIntegration> => {
  const apiPayload = {
    data: {
      type: 'integrations',
      id: uuid,
      attributes: { internal_state: payload },
    },
  };
  const apiResponse = await http().patch(`/api/internal/v1/catalog-integration/integrations/${uuid}`, apiPayload, apiConfigs);
  return apiResponse?.data;
};

export const deleteIntegrations = (ids: string[]): Promise<CatalogIntegrationSettingResponse> => {
  const apiPayload = {
    data: [],
  };
  apiPayload.data = ids.map((id) => ({
    id,
    type: 'integrations',
  }));

  return http().delete('/api/internal/v1/catalog-integration/integrations/-actions/delete-many', {
    ...apiConfigs,
    data: apiPayload,
  });
};

export const purgeIntegrations = (filter: { noIssues?: boolean }): Promise<unknown> => {
  const { noIssues } = filter;
  let apiRoute = '/api/internal/v1/catalog-integration/integrations/-actions/purge';
  if (noIssues) {
    apiRoute = apiRoute + `?filter[hasIssues]=false`;
  }
  return http().delete(apiRoute, {
    ...apiConfigs,
  });
};

export const approveIntegrations = (ids: string[]): Promise<void> => {
  let apiPayload, apiRoute;

  if (ids.length) {
    apiRoute = '/api/internal/v1/catalog-integration/integrations/-actions/approve-many';
    apiPayload = {
      data: [],
    };
    apiPayload.data = ids.map((id) => ({
      id,
      type: 'integrations',
    }));
  } else {
    apiRoute =
      '/api/internal/v1/catalog-integration/integrations/-actions/approve-all?filter[hasIssues]=false&filter[status][]=importation%3Asuccessful';
    apiPayload = {};
  }

  return http().post(apiRoute, apiPayload, apiConfigs);
};

export const updateIntegrations = async (payload: BulkEditDraftsPayload): Promise<void> => {
  const {
    filter: { productsUuids, noIssues },
    update,
  } = payload;

  let apiRoute = `/api/internal/v1/catalog-integration/integrations/-actions/update-all`;
  let filterQuery = '';
  if (productsUuids?.length) {
    const filterArray = productsUuids.map((uuid, index) => `filter[id][${index}]=${uuid}`);
    filterQuery = `?${filterArray.join('&')}`;
  } else if (noIssues) {
    filterQuery = `?filter[hasIssues]=false`;
  } else {
    filterQuery = `?filter[status][]=importation%3Asuccessful`;
  }
  apiRoute = apiRoute + filterQuery;
  const apiPayload = {
    data: {
      internalState: update,
    },
  };
  await http().patch(apiRoute, apiPayload, apiConfigs);
};

export const postGoogleIntegrationBatches = async (payload: GoogleBatchPayload): Promise<CatalogueIntegrationBatchResponse['data']> => {
  const postRoute = '/api/internal/v1/catalog-integration/product-integration-batches/google-batches';
  const apiPayload = {
    data: {
      type: 'product-integration-batches',
      attributes: payload,
    },
  };
  const apiResponse: CatalogueIntegrationBatchResponse = await http().post(postRoute, apiPayload, apiConfigs);
  return apiResponse?.data;
};

export const postCsvIntegrationBatches = async (payload: CsvBatchPayload): Promise<CatalogueIntegrationBatchResponse['data']> => {
  const postRoute = '/api/internal/v1/catalog-integration/product-integration-batches/csv-batches';
  const apiPayload = {
    data: {
      type: 'product-integration-batches',
      attributes: payload,
    },
  };
  const apiResponse: CatalogueIntegrationBatchResponse = await http().post(postRoute, apiPayload, apiConfigs);
  return apiResponse?.data;
};

export const postXlsxIntegrationBatches = async (payload: XlsxBatchPayload): Promise<CatalogueIntegrationBatchResponse['data']> => {
  const postRoute = '/api/internal/v1/catalog-integration/product-integration-batches/xlsx-batches';
  const apiPayload = {
    data: {
      type: 'product-integration-batches',
      attributes: payload,
    },
  };
  const apiResponse: CatalogueIntegrationBatchResponse = await http().post(postRoute, apiPayload, apiConfigs);
  return apiResponse?.data;
};

export const getCatalogIntegrationSettings = async (uuid: string): Promise<CatalogIntegrationSettingResponse> => {
  const apiResponse = await http().get(`/api/internal/v1/catalog-integration/brand-configs/${uuid}`, apiConfigs);
  return apiResponse?.data;
};

export const generateSpreadsheet = async (uuid: string): Promise<CatalogIntegrationSettingResponse> => {
  const apiResponse = await http().post(`/api/internal/v1/catalog-integration/brand-configs/${uuid}/spreadsheet`, {}, apiConfigs);
  return apiResponse?.data;
};

export const removeSpreadsheet = async (uuid: string): Promise<CatalogIntegrationSettingResponse> => {
  const apiResponse = await http().delete(`/api/internal/v1/catalog-integration/brand-configs/${uuid}/spreadsheet`, apiConfigs);
  return apiResponse?.data;
};

export const postCatalogExports = async (outputFormat: string): Promise<ExportCatalogueResponse> => {
  const apiPayload = {
    data: {
      type: 'catalog-exports',
      attributes: {
        output_format: outputFormat,
      },
    },
  };

  const apiResponse = await http().post('/api/internal/v1/catalog-exports/exports', apiPayload, apiConfigs);
  return apiResponse?.data;
};

export const getCatalogExports = async (params: PaginationPayload): Promise<ExportCatalogueResponse> => {
  const apiResponse = await http().get('/api/internal/v1/catalog-exports/exports', {
    params: params,
    ...apiConfigs,
  });
  return apiResponse?.data;
};

export const createSpreadsheetMapping = async ({
  name,
  mapping,
}: SpreadsheetMappingPayload): Promise<SpreadsheetMappingResponse> => {
  const configuration = mapping.map((match) => {
    return {
      identifier: match.identifier,
      field: match.field,
      missingValue: match.missingValue,
      emptyValue: match.emptyValue,
      behavior: match.behavior,
    };
  });

  const apiPayload = {
    data: {
      type: 'product-spreadsheet-mappings',
      attributes: {
        name,
        configuration: configuration,
      },
    },
  };

  const apiResponse = await http().post(
    '/api/internal/v1/catalog-integration/product-spreadsheet-mappings',
    apiPayload,
    apiConfigs
  );
  return apiResponse?.data?.data;
};

export const getAllSpreadsheetMappings = async (): Promise<SpreadsheetMappingResponse[]> => {
  const apiResponse = await http().get(`/api/internal/v1/catalog-integration/product-spreadsheet-mappings`);
  return apiResponse?.data?.data;
};
