import { Cart2, PurchasableAggregate, PurchasingListItem } from "@monolith/legacy/modules/cart-2/types";

  export const getBrandDataFromAggregate = (aggregate: PurchasableAggregate) => {
    return aggregate.brand?.data;
  };

  export const getProductsFromBrandAggregate = (aggregate: PurchasableAggregate) => {
    return aggregate?.items?.data;
  };

  export const getPurchasableItemFromItemAggregate = (product: PurchasingListItem) => {
    return product?.purchasableItem?.data;
  };

  export const findPurchasableItemByVariantId = (cart: Cart2, variantUuid: string) => {
    for (const itemAggregate of cart.itemAggregates.data) {
      for (const item of itemAggregate.items.data) {
        if (item.purchasableItemID === variantUuid) {return item;}
      }
    }
    return null;
  };
