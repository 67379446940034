<template>
  <div class="brand-page">
    <div
      v-if="brand"
      id="brandPage"
      :key="brand.id"
      class="brand"
    >
      <Breadcrumb
        separator="slash"
        :links="getBreadcrumb()"
        :last="brand.name"
        show-google-structure-data
        data-testid="brand-breadcrumb"
      />
      <BrandHeader
        v-if="brand"
        :brand="brand"
      />
      <BrandDescription
        v-if="brand"
        :brand="brand"
        :total-review-count="totalReviewCount"
        :average-review="averageReview"
        :have-reviews-and-statistics="haveReviewsAndStatistics"
        @ratings-clicked="scrollToReviews"
      />
      <div class="brand-top-buttons">
        <ReportPopin
          v-if="userIsRetailer"
          :brand-id="brandId"
        />
      </div>

      <BrandCatalog
        v-if="!brand.coming_soon && brand.isOpenedInUserCountry"
        :class="{ 'ds-mt-5': isUserAuthenticated }"
        :brand="brand"
      />

      <div
        v-else-if="brand.isOpenedInUserCountry"
        class="brand-not-available"
      >
        <AkIcon
          symbol="clock-history"
          size="md"
        />
        <span class="brand-not-available-title">{{ $t('Coming soon') }}</span>
        <span class="brand-not-available-text">
          {{ $t('Interested in the brand {brand}?', { brand: brand.name }) }}<br>
          {{ $t('We will alert you as soon as the products are online') }}
        </span>
        <ComingSoonAlertButton
          :id="brand.id"
          :small="false"
        />
      </div>

      <div
        v-else
        class="brand-not-available"
      >
        <span class="icon icon-notification" />
        <span class="brand-not-available-title">
          {{ $t('Not available in your country') }}
        </span>
        <span class="brand-not-available-text">
          {{ $t('Interested in the brand {brand}?', { brand: brand.name }) }}<br>
          {{ $t('We will alert you as soon as the products are available in your country.') }}
        </span>
        <ComingSoonAlertButton
          :id="brand.id"
          :small="false"
        />
      </div>
      <RecommendedBrands
        :brand-id="brandId"
        class="ds-mt-4 ds-mb-6 ds-overflow-x-hidden"
      />
      <template v-if="haveReviewsAndStatistics">
        <IntersectionObserver
          :threshold="0.6"
          @change="reviewSummaryObserverChanged(arguments)"
        >
          <div
            ref="reviews"
            data-testid="reviewsSummary"
            class="ds-bg-neutral-100 ds-mb-8"
          >
            <ReviewSummary
              :statistics="statisticsReview"
              :brand-name="brandName"
              :compact="userIsRetailer"
            />
          </div>
        </IntersectionObserver>
        <ReviewsListWrapper
          :reviews="brandReviews"
          :total-reviews="totalReviewCount"
          :is-fetching="isFetchingReviews"
          @show-all-reviews="getAllBrandReviews()"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import Analytics from '@monolith/legacy/services/analytics';
import { sitewideOfferMixin } from '@monolith/legacy/mixins/sitewide-offer';
import { AkIcon } from '@ankorstore/design-system';
import { Brand } from '@monolith/legacy/types/api/brand';
import ReviewSummary from '@monolith/legacy/components/review/review-summary.vue';
import ReviewsListWrapper from '@monolith/legacy/components/review/reviews-list-wrapper.vue';
import ReportPopin from '@monolith/legacy/components/brand/report-popin/report-popin.vue';
import { getBrandReviews, getReviewsAndStatistics } from '@monolith/legacy/services/reviews/reviews';
import { captureRetailerCoreDiscoveryException } from '@core/plugins/sentry/helper';
import { scrollToRef } from '@core/utilities/scroll';
import IntersectionObserver from '@monolith/legacy/components/global/interception-observer.vue';
import { ReviewsBreakdown } from '@monolith/legacy/components/review/types';
import { ReviewsSummaryViewEvent } from '@monolith/legacy/services/analytics/events/reviews/view-events';
import BrandCatalog from '@monolith/legacy/components/brand/catalog/brand-catalog.vue';
import Breadcrumb from '@monolith/legacy/components/breadcrumb.vue';
import { BreadcrumbLink } from '@monolith/legacy/types/breadcrumb';
import ComingSoonAlertButton from '@monolith/legacy/components/coming-soon-alert-button.vue';
import { DisplayAddress } from '@monolith/legacy/types/api/product';
import { defineComponent } from 'vue';
import { BrandDiscountContext } from '@monolith/legacy/types/api/brand-discount';
import BrandDescription from '@monolith/legacy/components/brand/brand-description.vue';
import BrandHeader from '@monolith/legacy/components/brand/brand-header.vue';
import { RecommendedBrands } from '@bc/discovery';
import {isEnabled, FeatureFlag} from '@monolith/legacy/services/features';

export type IBrandPageBrand = Required<
  Brand & { isRefreshed?: boolean; event_special_discount?: number; display_address: DisplayAddress }
>;

type BrandId = number;

export default defineComponent({
  name: 'Brand',
  components: {
    AkIcon,
    BrandCatalog,
    Breadcrumb,
    ComingSoonAlertButton,
    IntersectionObserver,
    ReportPopin,
    ReviewsListWrapper,
    ReviewSummary,
    BrandDescription,
    BrandHeader,
    RecommendedBrands,
  },
  mixins: [sitewideOfferMixin],
  props: {
    brandId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      linksPaginationTag: [],
      brandsReco: null,
      brandReviews: [],
      isFetchingReviews: false,
      statisticsReview: null,
      BrandDiscountContext,
      FeatureFlag,
    };
  },
  computed: {
    ...mapGetters(['user', 'retailer', 'userIsRetailer', 'isUserAuthenticated']),
    ...mapGetters('shop', ['brandById']),
    brand() {
      return this.brandById(this.brandId) as IBrandPageBrand;
    },
    totalReviewCount(): number {
      return this.statisticsReview?.breakdown?.total;
    },
    averageReview() {
      return this.statisticsReview?.average;
    },
    reviewBreakdown(): ReviewsBreakdown {
      return (
        this.statisticsReview?.breakdown ?? {
          total: 0,
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
        }
      );
    },
    brandName() {
      return this.brand?.name;
    },
    hasBrandReviews(): boolean {
      return Boolean(this.brandReviews?.length);
    },
    hasStatisticAverageReview(): boolean {
      if (Array.isArray(this.statisticsReview?.average)) {
        return false;
      }
      return Boolean(this.statisticsReview?.average);
    },
    haveReviewsAndStatistics(): boolean {
      return this.hasBrandReviews && this.hasStatisticAverageReview;
    },
  },
  watch: {
    brandId: {
      immediate: true,
      async handler(newBrandId: BrandId) {
        await this.fetchBrand();

        if (this.isUserAuthenticated && newBrandId) {
          await this.getReviewsData(newBrandId);
        }
      },
    },
  },
  updated() {
    document.querySelector('#brandPage')?.classList.remove('min-height');
  },
  beforeMount() {
    this.fetchBrand();
  },
  beforeUnmount() {
    document.getElementById('linksTagBrandPrev')?.remove();
    document.getElementById('linksTagBrandNext')?.remove();
  },
  methods: {
    async fetchBrand(): Promise<void> {
      if (this.brand?.isRefreshed) {
        return;
      }
      return this.$store.dispatch('shop/fetchBrand', {
        brand_id: this.brandId,
      });
    },
    getBreadcrumb(): BreadcrumbLink[] {
      const breadcrumb = [{ href: '/', title: 'Ankorstore', notRouterLink: true }] as BreadcrumbLink[];
      if (this.brand?.main_category) {
        breadcrumb.push({
          href: this.brand?.main_category?.path,
          title: this.brand?.main_category?.name,
          notRouterLink: true,
        });
      }

      return breadcrumb;
    },

    async getReviewsData(brandId) {
      this.isFetchingReviews = true;
      try {
        const [brandReviews, statisticsReview] = await getReviewsAndStatistics(brandId);
        if (brandReviews.length && statisticsReview) {
          this.brandReviews = brandReviews;
          this.statisticsReview = statisticsReview;
        }
      } catch (error) {
        captureRetailerCoreDiscoveryException(error, [
          { label: 'component', value: 'brand' },
          { label: 'action', value: 'Get reviews data' },
        ]);
        this.brandReviews = null;
        this.statisticsReview = null;
      }
      this.isFetchingReviews = false;
    },

    async getAllBrandReviews() {
      this.isFetchingReviews = true;
      try {
        this.brandReviews = await getBrandReviews(this.brandId, { all: 1 });
      } catch (error) {
        captureRetailerCoreDiscoveryException(error, [
          { label: 'component', value: 'brand' },
          { label: 'action', value: 'Get brand reviews' },
        ]);
      }
      this.isFetchingReviews = false;
    },
    scrollToReviews() {
      scrollToRef(this.$refs.reviews);
    },
    async reviewSummaryObserverChanged([entry, unobserve]) {
      if (entry.isIntersecting) {
        const payload = {
          brand_id: this.brandId,
          retailer_id: this.retailer.id,
        };
        await Analytics.track(new ReviewsSummaryViewEvent({ payload }));

        unobserve();
      }
    },
    isEnabled,
  },
});
</script>

<style lang="scss" scoped>
@import '@css/vue-import';

.ak-breadcrumb {
  padding: 30px 0;
}

.brand {
  &.min-height {
    min-height: 5000px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.brand-snippet--light__discount {
  position: relative;
  top: 0;
  left: 0;
  margin-right: 16px;
}

.like-button:before {
  position: relative;
  bottom: inherit;
}

.brand-top-buttons {
  @apply md:ds-hidden ds-flex ds-mt-4 ds-justify-between;
}
</style>
