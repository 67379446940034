<template>
  <div
    v-if="conversations"
    class="message-container"
  >
    <div class="previous-messages">
      <AkButton
        v-if="conversations && hasPreviousMessages"
        link
        @click="showPreviousMessages()"
      >
        {{ $t('Previous messages') }}
      </AkButton>
    </div>
    <div
      v-if="conversations.messages"
      id="message-area"
    >
      <div
        v-for="message in conversations.messages.edges.slice().reverse()"
        :key="message.data.id"
        :class="[
          amITheAuthor(conversations.id, message.data.id) ? 'message message-right' : 'message message-left',
          isMessageUnread(conversations, message.data) ? 'message-new' : '',
        ]"
      >
        <div class="message-icon">
          <p v-if="!isBrandDisplay(message.data)">
            {{
              getInitialName(
                message.data.author.entity.business.user_first_name,
                message.data.author.entity.business.user_last_name
              )
            }}
          </p>
          <p v-else>
            <template v-if="!amITheAuthor(conversations.id, message.data.id)">
              <router-link
                :to="message.data.author.entity.link"
                @click="trackOutConversations()"
              >
                <img :src="imgCdnUrl(message.data.author.entity.images.rounded, '64')" />
              </router-link>
            </template>
            <template v-else>
              <img :src="imgCdnUrl(message.data.author.entity.images.rounded, '64')" />
            </template>
          </p>
        </div>
        <div class="message-content">
          <div class="message-date">
            <p>
              <span
                v-if="amITheAuthor(conversations.id, message.data.id)"
                class="identity"
                >{{ $t('You') }} -
              </span>
              <span
                v-else-if="!isBrandDisplay(message.data)"
                class="identity"
                >{{ message.data.author.entity.name }} -
                {{ message.data.author.entity.business.user_first_name }}
                {{ message.data.author.entity.business.user_last_name }} -
              </span>
              <template v-else>
                <router-link
                  :to="message.data.author.entity.link"
                  @click="trackOutConversations()"
                >
                  <span class="identity identity--underline">
                    {{ message.data.author.entity.name }}
                  </span>
                  -
                </router-link>
              </template>
              <span data-testid="message-created-at-date">{{ getCreatedAt(message) }}</span>
            </p>
          </div>
          <div class="message-data">
            <Nl2Br
              tag="p"
              :text="message.data.content"
            />
            <MessageBoxAttachments
              v-if="message.data.attachments.length"
              :attachments="message.data.attachments"
              :conversation-id="conversations.id"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { conversationsMixin } from '@core/mixins/conversations';
import Analytics from '@monolith/legacy/services/analytics';
import { imgCdnUrl } from '@bc/shared';
import { defineComponent } from 'vue';
import { RetailerGoToBrandPage } from '@monolith/legacy/services/analytics/events/conversations/conversations-events';
import { AkButton } from '@ankorstore/design-system';
import { formatDate } from '@monolith/legacy/services/date-locale';
import MessageBoxAttachments from '@monolith/legacy/components/messages/message-box-attachments.vue';
import Nl2Br from '@monolith/legacy/components/global/nl2br.vue';
import { OriginProperty } from '@monolith/legacy/services/analytics/properties/origin-property';

export default defineComponent({
  name: 'MessageBox',
  components: { AkButton, MessageBoxAttachments, Nl2Br },
  mixins: [conversationsMixin],
  props: {
    conversations: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('conversations', ['amITheAuthor', 'doesConversationHaveHistory']),
    hasPreviousMessages() {
      return this.doesConversationHaveHistory(this.conversations.id);
    },
  },
  setup() {
    return {
      imgCdnUrl,
    };
  },
  mounted() {
    const container = document.querySelector('.message-container');
    if (this.conversations.unread_messages_count > 0) {
      this.$store.dispatch('conversations/messageRead', {
        conversation: this.conversations,
        conversationIds: [this.conversations.id],
      });
    }

    this.$store._vm.$on('new-message', () => {
      setTimeout(function () {
        container.scrollTo(0, container.scrollHeight);
      }, 500);
    });
  },
  methods: {
    showPreviousMessages(): void {
      if (this.conversations.messages?.links?.next !== null) {
        this.$store.dispatch('conversations/loadOlderMessages', {
          nextUrl: this.conversations.messages.links.next,
          conversationId: this.conversations.id,
        });
      }
    },
    trackOutConversations(): void {
      Analytics.track(
        new RetailerGoToBrandPage(
          {
            id: this.conversations.recipient.entity.id,
          },
          {
            id: this.conversations.sender.entity.id,
          },
          this.conversations.messages.edges[0].data.origin as OriginProperty
        )
      );
    },
    getCreatedAt(message): string {
      return formatDate(message?.data?.created_at, 'Pp');
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';

.previous-messages {
  @apply ds-flex ds-w-full ds-justify-center;
}

.message-container {
  max-height: 560px;
  overflow-y: auto;
  padding: 20px;
}

@include media-breakpoint-up(lg) {
  .ak-account-messages {
    .message-container {
      max-height: none;
    }
  }
}

.message {
  max-width: 90%;
  @include media-breakpoint-up(lg) {
    max-width: 75%;
  }
  display: flex;
  margin-bottom: 20px;
  &-icon {
    width: 38px;
    height: 38px;
    @apply ds-bg-neutral-500;
    border-radius: 50%;
    margin: 22px 16px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      margin-bottom: 0;
      font-weight: bold;
      text-transform: uppercase;
      @apply ds-text-white;
    }
    img {
      display: block;
      height: 38px;
      width: 38px;
      border-radius: 50%;
      @apply ds-border ds-border-solid ds-border-neutral-300;
    }
  }
  &-date {
    font-size: 12px;
    p {
      margin-bottom: 8px;
      span {
        @apply ds-text-neutral-700;
      }
      span:first-child {
        @apply ds-text-primary;
      }
    }
  }
  &-content {
    width: calc(100% - 56px);
  }
  &-data {
    @apply ds-bg-white;
    padding: 15px 18px;
    border-radius: 5px;
    @apply ds-border ds-border-solid ds-border-neutral-300;
    position: relative;
    &:before {
      display: block;
      content: '';
      width: 10px;
      height: 10px;
      background-image: url('/images/bulle-left.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 8px;
      left: -8px;
    }
    p {
      word-wrap: break-word;
      overflow-wrap: break-word;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &-new {
    .message-data {
      p {
        font-weight: bold;
      }
    }
  }
  &-right {
    flex-direction: row-reverse;
    margin-left: 10%;
    @include media-breakpoint-up(lg) {
      margin-left: 25%;
    }
    .message-data {
      @apply ds-border ds-border-solid ds-border-neutral-300 ds-bg-neutral-300 ds-text-sm;
      &:before {
        right: -8px;
        background-image: url('/images/bulle-right.svg');
        left: auto;
      }
    }
    .message-icon {
      margin: 22px 0 0 16px;
      @apply ds-text-sm;
    }
    .message-date {
      text-align: right;
    }
  }
  &.awaiting {
    .message-content {
      max-width: 142px;
      width: 100%;
    }
    .message-data {
      @apply ds-text-neutral-700;
      font-size: 60px;
      margin-top: 6px;
      p {
        margin-top: -12px;
        margin-bottom: 15px;
        line-height: 9px;
      }
    }
    .message-icon {
      margin-top: 2px;
    }
  }
}

.identity {
  &--underline {
    @apply ds-underline;
  }
}
</style>
