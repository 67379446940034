import { Ref, ref, watchEffect } from 'vue';
import { getCatalogConfig } from '../services/catalog-config/catalog-config-service';
import { Context, type Config } from '@bc/discovery/domain/catalog';
import getIndexName, { IndexName } from '@monolith/legacy/services/analytics/algolia/index-name';
import type { AkLocale } from '@core/types/base';

type UseCatalogContentParams = {
  context: Ref<Context>;
  selectedCategoryId: Ref<number>;
  rootCategoryId?: Ref<number>;
  lang: Ref<AkLocale>;
};

export const useCatalogContent = ({ context, selectedCategoryId, lang, rootCategoryId }: UseCatalogContentParams) => {
  const indexName = ref<string>();
  const config = ref<Config>();

  watchEffect(async () => {
    if (!context.value) {
      return;
    }

    const indexMap = {
      [Context.SEARCH]: getIndexName(IndexName.Products),
      [Context.CATEGORY_PRODUCTS]: getIndexName(IndexName.Products),
      [Context.CATEGORY_BRANDS]: rootCategoryId?.value
        ? getIndexName(IndexName.BrandsSortByCategoryRanking, rootCategoryId.value)
        : getIndexName(IndexName.Brands),
      [Context.NEW_PRODUCTS]: getIndexName(IndexName.Products),
      [Context.NEW_BRANDS]: getIndexName(IndexName.Brands),
      [Context.PREORDER_PRODUCTS]: getIndexName(IndexName.Products),
      [Context.PREORDER_BRANDS]: getIndexName(IndexName.Brands),
      [Context.WHOLESALE]: getIndexName(IndexName.Products),
    };

    config.value = await getCatalogConfig(context.value, selectedCategoryId.value, lang.value);
    indexName.value = indexMap[context.value];
  });

  return {
    indexName,
    config,
  };
};
