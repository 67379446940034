<template>
  <div class="popup-container">
    <AkHeading4 class="ds-mb-4">
      {{ t('popup.preorderArrived.heading') }}
    </AkHeading4>
    <div class="popup-container__shadow-box">
      <div class="popup-container__shadow-box__flex-frame">
        <div>
          <AkIcon
            class="popup-container__icon"
            symbol="lightbulb"
            size="md"
          />
        </div>
        <div class="ds-text-left">
          <AkParagraph class="ds-font-semibold">
            <i18n-t keypath="popup.preorderArrived.subHeading">
              <template #accent>
                <span class="popup-container__shadow-box__betta-label">{{ t('global.beta') }}</span>
              </template>
            </i18n-t>
          </AkParagraph>
          <AkParagraph class="ds-font-semibold">
            {{ t('popup.preorderArrived.listTitle') }}
          </AkParagraph>
          <ul class="popup-container__list">
            <li>{{ t('popup.preorderArrived.listItem1') }}</li>
            <li>{{ t('popup.preorderArrived.listItem2') }}</li>
            <li>{{ t('popup.preorderArrived.listItem3') }}</li>
          </ul>
          <AkLink
            :href="t('popup.preorderArrived.faq.url')"
            target="_blank"
            size="sm"
            rel="noopener noreferrer"
          >
            {{ t('popup.preorderArrived.faq.cta') }}
          </AkLink>
        </div>
      </div>
    </div>
    <AkParagraph class="popup-container__hint">
      {{ t('popup.preorderArrived.footerText') }}
    </AkParagraph>
    <AkButton
      size="lg"
      @click="onClick"
    >
      {{ t('popup.preorderArrived.cta') }}
    </AkButton>
  </div>
</template>

<script setup lang="ts">
import { AkHeading4, AkIcon, AkParagraph, AkLink, AkButton } from '@ankorstore/design-system';
import { useI18n } from 'vue-i18n';

const emit = defineEmits(['popup-close']);

const { t } = useI18n();

const onClick = (): void => {
  emit('popup-close');
};
</script>

<style scoped lang="scss">
@import '@css/vue-import';

.popup-container {
  @apply ds-pt-5;
  &__shadow-box {
    @apply ds-rounded-md ds-bg-white ds-py-6 md:ds-py-4 ds-px-4 ds-mb-5;
    box-shadow:
      0 2px 4px -1px rgba(0, 0, 0, 0.06),
      0 4px 6px -1px rgba(0, 0, 0, 0.1);
    &__flex-frame {
      @apply ds-flex ds-gap-4;
    }
    &__betta-label {
      @apply ds-text-warning-700 ds-lowercase;
    }
  }
  &__icon {
    @apply ds-rounded-full ds-bg-error-100 ds-w-[36px] ds-h-[36px] ds-flex ds-items-center ds-justify-center ds-text-warning-700;
  }
  &__list {
    @apply ds-list-disc ds-pl-5 ds-pt-2;
    li {
      @apply ds-mb-3;
    }
  }
  &__hint {
    @apply ds-font-semibold ds-mb-5 ds-text-neutral-900;
  }
}
</style>
