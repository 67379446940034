import { getInjectedContent } from "@monolith/legacy/services/injected-content";
import { GlobalMetaTagName } from "@monolith/legacy/services/initial-state";

export interface CategoriesMeta {
  idToName: { [id: number]: string };
  nameToId?: { [name: string]: number };
}

const categoriesMeta: CategoriesMeta['idToName'] = getInjectedContent(GlobalMetaTagName.Categories);
const categories: CategoriesMeta = {
  idToName: categoriesMeta,
  nameToId: {},
};

for (const id in categories) {
  categories.nameToId[categories[id]] = Number(id);
}
export const getCategories = (): CategoriesMeta => categories;
