import AnalyticsEvent from './analytics-event';
import { ProductProperty, createProductProperty } from '../properties/product-property';

export default class ProductUnlikedEvent extends AnalyticsEvent {
  public readonly name = 'Product Unliked';
  public properties: {
    product: ProductProperty;
    id_section?: string;
  };

  constructor({ id, name, wholesalePrice }: ProductProperty, id_section?: string) {
    super();
    this.properties = {
      product: createProductProperty({ id, name, wholesalePrice }),
    };
    if (id_section) {
      this.properties.id_section = id_section;
    }
  }
}
