<template>
  <div
    v-if="currentFilter"
    class="toggle-widget"
  >
    <AkCheckbox
      class="toggle-widget__item"
      :value="currentFilter.attributeName"
      :checked="!!modelValue.value"
      :text="currentFilter.label"
      @change="setValue($event)"
    />
    <AkIcon
      v-if="currentFilter.icon"
      :symbol="currentFilter.icon"
      class="toggle-widget__icon"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { AkCheckbox } from '@ankorstore/design-system';
import { ToggleWidgetComponent } from '../../types/filter.types';
import { ContactFilterType, ValueContactFilter } from '../../types/api.types';

export default defineComponent({
  name: 'ToggleWidget',
  components: {
    AkCheckbox,
  },
  props: {
    filter: {
      type: Object as PropType<ToggleWidgetComponent>,
      required: true,
    },
    modelValue: {
      type: Object as PropType<ValueContactFilter>,
      default: () => ({}),
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const currentFilter = ref(props?.filter);
    const setValue = (val?: string) => {
      emit('update:modelValue', {
        type: ContactFilterType.Boolean,
        name: props.filter.attributeName,
        value: val || undefined,
      });
    };

    return { setValue, currentFilter };
  },
});
</script>
<style scoped lang="scss">
.toggle-widget {
  @apply ds-px-4;

  &__item {
    @apply ds-inline-flex ds-w-auto #{!important};
  }

  &__icon {
    @apply ds-bg-neutral-300 ds-rounded-full ds-ml-1 ds-mr-1 ds-p-1;
    @apply ds-text-xs #{!important};

    &:before {
      @apply ds-text-primary;
    }
  }
}
</style>
