import { Image, RichTextItem } from '@core/types/prismic';
import type { Tile } from '@bc/discovery';
import { ProductHit } from '@monolith/legacy/types/product';

export enum PromotionTileType {
  SpecialTile1 = 'special_tile_1',
  SpecialTile2 = 'special_tile_2',
  SpecialTile1GuestUsers = 'special_tile_1_guest_users',
  SpecialTile2GuestUsers = 'special_tile_2_guest_users',
}

export interface PrismicSpecialTile1 {
  slice_type: PromotionTileType.SpecialTile1 | PromotionTileType.SpecialTile1GuestUsers;
  items: Array<{
    text_content: RichTextItem[];
    font_size: number;
    text_colour: string;
  }>;
  primary: {
    position: string;
    background_image: Image;
    cta_link: string;
    background_colour: string;
    background_image_hover: Image;
    background_colour_hover: string;
    cta_button_text: string;
    cta_button_text_colour: string;
    cta_button_text_colour_on_hover: string;
    cta_button_background_colour: string;
    cta_background_colour_on_hover: string;
  };
}

export interface PrismicSpecialTile2 {
  slice_type: PromotionTileType.SpecialTile2 | PromotionTileType.SpecialTile2GuestUsers;
  primary: {
    position: string;
    background_image: Image;

    cta_link: string;

    foreground_image: Image;
  };
}

export type PrismicSpecialTile = PrismicSpecialTile1 | PrismicSpecialTile2;

export type SpecialTile = SpecialTile1Interface | SpecialTile2Interface;

export interface SpecialTileGlobal extends Tile {
  type: PromotionTileType;
  position?: number;
  path?: string;
  product?: ProductHit;
  items?: Array<{
    text_content: RichTextItem[];
    font_size: number;
    text_colour: string;
  }>;
  eventName?: string;
  primary?: {
    position: string;
    background_image: Image;
    cta_link: string;
    background_colour?: string;
    background_image_hover?: Image;
    background_colour_hover?: string;
    cta_button_text?: string;
    cta_button_text_colour?: string;
    cta_button_text_colour_on_hover?: string;
    cta_button_background_colour?: string;
    cta_background_colour_on_hover?: string;
    foreground_image?: Image;
  };
}

export interface SpecialTile1Interface extends SpecialTileGlobal {
  primary: SpecialTileGlobal['primary'] & {
    background_colour: string;
    background_image_hover: Image;
    background_colour_hover: string;
    cta_button_text: string;
    cta_button_text_colour: string;
    cta_button_text_colour_on_hover: string;
    cta_button_background_colour: string;
    cta_background_colour_on_hover: string;
  };
}

export interface SpecialTile2Interface extends SpecialTileGlobal {
  eventName?: string;
  primary: SpecialTileGlobal['primary'] & {
    foreground_image: Image;
  };
}
