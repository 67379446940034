import http from '@monolith/legacy/services/api/http';
import { deserialize } from '@monolith/legacy/services/utils/jsonapi-parser';
import { Warehouse } from '@monolith/legacy/types/fulfillment';

export const getWarehouses = async () => {
  const url = '/api/internal/v1/fulfillment/warehouses';
  let response = await http().get(url);
  response = deserialize(response.data);
  return response.data;
};

export const getWarehouse = async (id): Promise<Warehouse> => {
  const url = `/api/internal/v1/fulfillment/warehouses/${id}`;
  let response = await http().get(url);
  response = deserialize(response.data);
  return response.data;
};
