import Dinero, { DineroObject } from 'dinero.js';
import Analytics from '@monolith/legacy/services/analytics';
import { Checkout, Item } from '@monolith/legacy/types/cart';
import Product from '@monolith/legacy/types/product';
import { CartViewedEvent2 } from '../events/cart-viewed.event';
import { OrderCompletedEvent } from '@monolith/legacy/services/analytics/events/order-completed.event';
import { CheckoutStartedEvent } from '@monolith/legacy/services/analytics/events/checkout-started.event';
import { ProductListViewedEvent } from '@monolith/legacy/services/analytics/events/product-list-viewed.event';
import { ProductViewedEvent } from '@monolith/legacy/services/analytics/events/product-viewed.event';
import { Order as SegmentOrder, Product as SegmentProduct, Checkout as SegmentCheckout } from '../events/segment-ecommerce-types';
import { isPreorder } from '@monolith/legacy/services/product-variants';
import { PreorderCartViewedEvent } from '@monolith/legacy/services/analytics/events/preorder-cart-viewed.event';
import { CartPreviewViewedEvent } from '@monolith/legacy/services/analytics/events/cart-preview-viewed.event';
import UserClick from '@monolith/legacy/services/analytics/events/user-click.event';

export interface TrackProductListViewedParams {
  listId: string;
  category: string;
  products: SegmentProduct[];
}

export const trackProductListViewed = ({ listId, category, products }: TrackProductListViewedParams) =>
  Analytics.track(
    new ProductListViewedEvent({
      list_id: listId,
      category,
      products,
    })
  );

const mapToProduct = (product: Product) => ({
  product_id: product.id,
  sku: `${product.id}`,
  category: product.categories?.map(({ name }) => name).join(','),
  name: product.name,
  brand: product.brand?.name,
  price: Dinero(product.original_wholesale_price as DineroObject).toUnit(),
  url: product.link,
  image_url: product.images?.[0],
  availability: isPreorder(product) ? 'preorder' : null,
});

const mapFromCartItemToProduct = (brandName: string) => (item: Item) => ({
  ...mapToProduct(item.product),
  brand: brandName,
  quantity: item.multiplied_quantity,
  variant: item.option?.name,
});

export const trackProductViewed = (product: Product) => Analytics.track(new ProductViewedEvent(mapToProduct(product)));

/**
 * track cart page release 2 loading time in miliseconds
 */
export const trackCartViewed2Ms = (cartId: string, brands: object[], cartLoadingTime: number) => {
  Analytics.track(
    new CartViewedEvent2({
      tracking_version: '2.0',
      cart_id: cartId,
      brands,
      loading_time: cartLoadingTime,
    })
  );
};

export const trackCartPreviewViewed = (currentUserId: string, brands: object[]) => {
  Analytics.track(
    new CartPreviewViewedEvent({
      id_user: currentUserId,
      brands,
    })
  );
};

export const trackGoToBrandPageCartPreview= (brandUuid: string) => {
  Analytics.track(
    new UserClick({
      component: 'cart_preview',
      action: 'click_brand',
      uuid_brand: brandUuid,
    })
  );
};

export const trackGoToCartCartPreview= () => {
  Analytics.track(
    new UserClick({
      component: 'cart_preview',
      action: 'go_to_cart',
    })
  );
};

export const cartIsEligibleForCheckout = (cart) => {
  return cart.minimum.above === true && cart.saved_for_later === false;
};

const amountToDecimal = (amount: number): number => {
  return parseFloat((amount / 100).toFixed(2));
};

export const trackOrderCompleted = (checkout: Checkout) => {
  const orders: SegmentOrder[] = checkout.carts.filter(cartIsEligibleForCheckout).map((cart) => ({
    order_id: `${cart.id}`,
    subtotal: amountToDecimal(cart.total_amount.amount),
    total: amountToDecimal(cart.total_amount_with_vat?.amount),
    tax: amountToDecimal(cart.total_amount_vat?.amount),
    currency: cart.total_amount?.currency,
    products: cart.items.map(mapFromCartItemToProduct(cart.brand?.name)),
    is_first_order_on_this_brand: cart.is_first_order_on_this_brand,
  }));

  if (orders.length === 0) {
    return;
  }

  Analytics.track(
    new OrderCompletedEvent({
      checkout_id: `${checkout.id}`,
      orders,
      total_amount: amountToDecimal(checkout.total_amount.amount),
      total_amount_vat: amountToDecimal(checkout.total_amount_vat.amount),
      total_amount_with_vat: amountToDecimal(checkout.total_amount_with_vat.amount),
      is_first_checkout: checkout.is_first_checkout,
    })
  );
};

export const trackCheckoutStarted = (checkout: Checkout) => {
  const orders: SegmentCheckout[] = checkout.carts.filter(cartIsEligibleForCheckout).map((cart) => ({
    checkout_id: `${checkout.id}`,
    order_id: `${cart.id}`,
    value: cart.total_amount_with_vat?.amount,
    tax: cart.total_amount_vat?.amount,
    currency: cart.total_amount?.currency,
    products: cart.items.map(mapFromCartItemToProduct(cart.brand?.name)),
    is_first_order_on_this_brand: cart.is_first_order_on_this_brand,
    is_first_checkout: checkout.is_first_checkout,
  }));

  orders.forEach((order) => Analytics.track(new CheckoutStartedEvent(order)));
};

export const trackPreorderCartViewed = (preorderCartId: string, brands: object[]) => {
  Analytics.track(
    new PreorderCartViewedEvent({
      tracking_version: '1.0',
      preorder_cart_id: preorderCartId,
      brands,
    })
  );
};
