import {
  PREORDER_ADD_TO_CART,
  PREORDER_CALCULATE_CHECKOUT,
  PREORDER_CART_BULK_UPDATE,
  PREORDER_DELETE_ITEM,
  PREORDER_GET_CURRENT_CHECKOUT,
  PREORDER_GET_CURRENT_CHECKOUT_TOTALS,
  PREORDER_UPDATE_BULK,
  PREORDER_UPDATE_ITEM_QUANTITY,
  PREORDER_GET_PAYMENT_TERMS,
} from '@monolith/legacy/store/cart-preorder/action-types';
import * as Mutations from '@monolith/legacy/store/cart-preorder/mutation-types';
import { CartType, PreorderCartResponseMapped } from '@monolith/legacy/types/cart-preorder';

import { debounce } from 'lodash-es';
import {
  PreorderBulkUpdate,
  getPreorderCart,
  updatePreorderItemsBulk,
  mapResponse,
  getPaymentTerms,
} from '@monolith/legacy/services/api/cart-preorder';

import { capturePurchasingException } from '@core/plugins/sentry/helper';

const BULK_UPDATE_TIMEOUT = 2000;

export default {
  [PREORDER_UPDATE_BULK]: debounce(async ({ commit, getters, dispatch }) => {
    const updates = getters.getUpdates;

    if (updates.length === 0) {
      return;
    }

    try {
      commit(Mutations.PREORDER_SET_TOTALS_LOADING, true);
      await updatePreorderItemsBulk(updates);
      await dispatch(PREORDER_GET_CURRENT_CHECKOUT_TOTALS, false);
    } finally {
      commit(Mutations.PREORDER_RESET_BULK_UPDATES);
      commit(Mutations.PREORDER_SET_TOTALS_LOADING, false);
      commit(Mutations.PREORDER_SET_CART_LOADING, false);
    }
  }, BULK_UPDATE_TIMEOUT),
  async [PREORDER_GET_CURRENT_CHECKOUT_TOTALS]({ commit }, withLoader = true): Promise<PreorderCartResponseMapped | undefined> {
    if (withLoader) {
      commit(Mutations.PREORDER_SET_TOTALS_LOADING, true);
    }

    try {
      const currentCheckout = await getPreorderCart();

      if (!currentCheckout) {
        return undefined;
      }

      const mappedCheckout = mapResponse(currentCheckout);

      commit(Mutations.PREORDER_REFRESH_CHECKOUT, mappedCheckout);
      commit(Mutations.PREORDER_SET_TOTALS_LOADING, false);
      commit(Mutations.PREORDER_SET_CART_LOADING, false);

      return mappedCheckout;
    } finally {
      if (withLoader) {
        commit(Mutations.PREORDER_SET_TOTALS_LOADING, false);
      }
    }
  },

  async [PREORDER_GET_CURRENT_CHECKOUT]({ commit }): Promise<void> {
    commit(Mutations.PREORDER_SET_CART_LOADING, true);

    try {
      const checkout = await getPreorderCart();
      if (checkout) {
        commit(Mutations.PREORDER_REFRESH_CHECKOUT, mapResponse(checkout));
        commit(Mutations.PREORDER_SET_TOTALS_LOADING, false);
        commit(Mutations.PREORDER_SET_CART_LOADING, false);
      }
    } finally {
      commit(Mutations.PREORDER_SET_CART_LOADING, false);
      commit(Mutations.PREORDER_SET_TOTALS_LOADING, false);
    }
  },

  async [PREORDER_CALCULATE_CHECKOUT]({ commit }) {
    commit(Mutations.PREORDER_CALCULATE_BRANDS_TOTALS, { cartType: CartType.Main });
    commit(Mutations.PREORDER_CALCULATE_CHECKOUT);
  },

  [PREORDER_CART_BULK_UPDATE]: async ({ dispatch, commit }, { update }: { update: PreorderBulkUpdate }) => {
    commit(Mutations.PREORDER_SET_CART_LOADING, true);
    commit(Mutations.PREORDER_UPDATE_BULK, update);
    commit(Mutations.PREORDER_SET_TOTALS_LOADING, true);
    dispatch(PREORDER_UPDATE_BULK);
  },

  async [PREORDER_ADD_TO_CART]({ dispatch, commit }, { variant, quantity }) {
    commit(Mutations.PREORDER_SET_CART_LOADING, true);

    let update: PreorderBulkUpdate;

    if (variant.uuid !== undefined) {
      update = {
        productVariantId: variant?.uuid,
        quantity,
      };
    } else {
      update = {
        productVariantId: variant?.id,
        quantity,
      };
    }
    dispatch(PREORDER_CART_BULK_UPDATE, { update });
  },

  async [PREORDER_DELETE_ITEM]({ dispatch, commit }, { brand, item }) {
    commit(Mutations.PREORDER_DELETE_ITEM, { brand, item });

    const update: PreorderBulkUpdate = {
      ...{ preorderItemId: item.id },
      quantity: 0,
    };

    dispatch(PREORDER_CART_BULK_UPDATE, { update });
  },

  async [PREORDER_UPDATE_ITEM_QUANTITY]({ dispatch, commit }, { brand, item, quantity }) {
    try {
      commit(Mutations.PREORDER_CHANGE_ITEM_QUANTITY, { brand, item, quantity });
    } catch (e) {
      capturePurchasingException(e);
    }

    dispatch(PREORDER_CALCULATE_CHECKOUT);

    let update: PreorderBulkUpdate;

    // NOTE: By using Quick add item could be not yet in the cart, but the retail customer can increase the quantity
    if (item.id !== null) {
      update = {
        ...{ preorderItemId: item.id },
        quantity,
      };
    } else {
      update = {
        productVariantId: item.variant?.id,
        quantity,
      };
    }

    dispatch(PREORDER_CART_BULK_UPDATE, { update });
  },

  async [PREORDER_GET_PAYMENT_TERMS]({ commit }) {
    try {
      const paymentTerms = await getPaymentTerms();

      commit(
        Mutations.PREORDER_SET_PAYMENT_TERMS,
        paymentTerms.data.map((term) => term.name)
      );

      return paymentTerms;
    } catch (e) {
      capturePurchasingException(e);
    }
  },
};
