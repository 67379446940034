<template>
  <section>
    <HomeHero :content="heroBanner" />
    <KspBlockContent
      :content="kspSection"
      class="ksp"
    />
    <CuratedSection :content="curatedSection" />
    <RegionBrands :content="regionBrandContent" />
    <BrandLogoCarousel :content="brandLogoCarouselContent" />
    <HomeTestimonials :content="homeTestimonialsContent" />
    <SocialProof
      class="social-proof"
      :content="socialProofContent"
    />
    <FeaturedBrands />
    <AnkorstartBanner :content="ankorstartBannerContent" />
    <RetailerFaq
      class="faq"
      :content="faqContent"
    />
    <TrustArea :content="trustArea" />
  </section>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue';
import CuratedSection from '@monolith/legacy/components/home/revamp/curated-section.vue';
import RegionBrands from '@monolith/legacy/components/home/region-brands.vue';
import HomeTestimonials from '@monolith/legacy/components/home/home-testimonials.vue';
import HomeHero from '@monolith/legacy/components/home-hero/home-hero.vue';
import SocialProof, { SocialProofContent, SocialProofItem } from '@monolith/legacy/components/home/social-proof.vue';
import FeaturedBrands from '@monolith/legacy/components/home/featured-brands.vue';
import AnkorstartBanner from '@monolith/legacy/components/home/revamp/ankorstart-banner.vue';
import RetailerFaq from '@monolith/legacy/components/retailer-faq.vue';
import TrustArea from '@monolith/legacy/components/home/revamp/trust-area.vue';

import {
  HomeContent,
  CuratedSectionContent,
  RegionBrandContent,
  TrustAreaContent,
  HomeBannerContent,
} from '@monolith/legacy/types/home/home';
import KspBlockContent from '@monolith/legacy/components/my-shop/ksp-block/ksp-block-content.vue';
import BrandLogoCarousel, { BrandLogoContent } from '@monolith/legacy/components/home/brand-logo-carousel.vue';
import { PrismicTestimonials } from '@monolith/legacy/types/prismic/home-testimonials';
import { FaqInterface } from '@monolith/legacy/types/faq';

export default defineComponent({
  name: 'HomeContainer',
  components: {
    AnkorstartBanner,
    CuratedSection,
    BrandLogoCarousel,
    FeaturedBrands,
    HomeHero,
    HomeTestimonials,
    KspBlockContent,
    RegionBrands,
    SocialProof,
    RetailerFaq,
    TrustArea,
  },
  props: {
    content: {
      type: Object as () => HomeContent,
      required: true,
    },
  },
  setup(props) {
    const curatedSection = computed((): CuratedSectionContent => {
      return {
        title: props.content.g_title[0].text,
        description: props.content.g_description,
        name: props.content.g_name[0].text,
        logo: props.content.g_logo,
        firstImage: props.content.g_image_1,
        secondImage: props.content.g_image_2,
        cta: props.content.g_cta,
        cta_link: props.content.g_cta_link,
      };
    });

    const kspSection = computed(() => {
      return {
        b_box_1_title: props.content.b_box_1_title,
        b_box_1_richtext: props.content.b_box_1_richtext,
        b_box_2_title: props.content.b_box_2_title,
        b_box_2_richtext: props.content.b_box_2_richtext,
        b_box_3_title: props.content.b_box_3_title,
        b_box_3_richtext: props.content.b_box_3_richtext,
      };
    });

    const brandLogoCarouselContent = computed((): BrandLogoContent => {
      return {
        images: props.content.i_images.map((image) => {
          return {
            brand_link: image.i_brand_link,
            brand_logo: image.i_brand_logo,
          };
        }),
      };
    });

    const regionBrandContent = computed((): RegionBrandContent => {
      return {
        country: props.content.h_country,
        other_country: props.content.h_other_country,
        other_country_title_override: props.content.h_other_country_title_override,
        title: props.content.h_title,
      };
    });

    const homeTestimonialsContent = computed((): PrismicTestimonials[] => {
      return props.content.j_testimonial.map((testimonials) => {
        return {
          quote: testimonials.j_quote,
          author_name: testimonials.j_author_name,
          author_subtitle: testimonials.j_author_subtitle,
        };
      });
    });

    const socialProofContent = computed((): SocialProofContent => {
      return {
        title: props.content.l_title_social,
        items: props.content.l_items.map((item): SocialProofItem => {
          return {
            href: item.l_href,
            image: item.l_image,
            description: item.l_description,
          };
        }),
      };
    });

    const ankorstartBannerContent = computed(() => {
      return {
        background: props.content.k_background,
        background_mobile: props.content.k_background_mobile,
        ctalabel: props.content.k_ctalabel,
        link: props.content.k_link,
        subtitle: props.content.k_subtitle,
        title: props.content.k_title,
      };
    });

    const faqContent = computed((): FaqInterface => {
      return {
        faq_link: props.content.faq_link,
        overridetitle: props.content.overridetitle,
        questions: props.content.questions,
      };
    });

    const trustArea = computed((): TrustAreaContent => {
      return {
        title: props.content.n_title,
        trustpilot: props.content.n_trustpilot,
        google: props.content.n_google,
      };
    });

    const heroBanner = computed((): HomeBannerContent => {
      return {
        cta_url: props.content.a_hero_url,
        background_image: props.content.a_background_image,
        foreground_image: props.content.a_foreground_image,
      };
    });

    return {
      ankorstartBannerContent,
      brandLogoCarouselContent,
      curatedSection,
      faqContent,
      heroBanner,
      homeTestimonialsContent,
      regionBrandContent,
      socialProofContent,
      trustArea,
      kspSection,
    };
  },
});
</script>
<style lang="scss" scoped>
.social-proof {
  @apply ds-mt-4;
}

.ksp {
  @apply ds-mt-6.5 ds-mb-2;
}

.faq {
  @apply ds-bg-neutral-300 ds-pt-1 ds-pb-1;
}
</style>
