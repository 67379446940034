import { jsonApiConfigs } from '@monolith/legacy/services/api/configs/jsonapi';
import http from '@monolith/legacy/services/api/http';
import { Franco, FrancoSummary } from '@monolith/legacy/types/api/franco';
import { deserialize } from '../utils/jsonapi-parser';
import qs from 'qs';

export const getBrandsFrancos = async (brandUuids: string[]): Promise<Franco[]> =>
  brandUuids
    ? (deserialize(
        (
          await http().get('/api/internal/v1/brands/franco', {
            ...jsonApiConfigs,
            params: {
              filter: { brandId: brandUuids },
            },
            paramsSerializer: (params) => qs.stringify(params),
          })
        ).data
      ).data.map(({ type, id, ...rest }) => rest) as Franco[])
    : [];

export const getBrandFrancoSettingsSummary = async (brandUuid: string): Promise<FrancoSummary> => {
  const apiRoute = `/api/internal/v1/brands/${brandUuid}?include=franco-settings-summary`;
  const response = await http().get(apiRoute, jsonApiConfigs);
  return deserialize(response.data)?.data['franco-settings-summary']?.data;
};
