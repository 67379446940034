<template>
  <div
    v-if="shouldShowBanner"
    class="home-hero"
  >
    <a
      :style="backgroundImageStyle"
      :href="ctaLink"
      class="home-hero__container"
    >
      <picture>
        <source
          :srcset="foregroundImageMobile"
          media="(max-width: 640px)"
        >
        <img
          :src="foregroundImage"
          :alt="foregroundImageAlt"
          class="home-hero__foreground"
        >
      </picture>
    </a>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { HomeBannerContent } from '@monolith/legacy/types/home/home';

const props = defineProps<{
  content: HomeBannerContent;
}>();

const shouldShowBanner = computed((): boolean => {
  return (
    !!props.content && !!props.content.background_image?.url && !!props.content.foreground_image?.url && !!props.content.cta_url
  );
});

const backgroundImage = computed((): string => {
  return props.content.background_image?.url;
});

const backgroundImageHeight = computed((): number => {
  return props.content.background_image?.dimensions?.height;
});

const backgroundImageMobileHeight = computed((): number => {
  return props.content.background_image?.mobile?.dimensions?.height;
});

const backgroundImageMobile = computed((): string => {
  return props.content.background_image?.mobile?.url;
});

const backgroundImageStyle = computed(() => {
  return {
    '--hero-background-image': backgroundImage.value ? `url(${backgroundImage.value})` : '',
    '--hero-background-image-height': backgroundImageHeight.value ? `${backgroundImageHeight.value}px` : 'auto',
    '--hero-background-image-mobile-height': backgroundImageMobileHeight.value
      ? `${backgroundImageMobileHeight.value}px`
      : 'auto',
    '--hero-background-image-mobile': backgroundImageMobile.value ? `url(${backgroundImageMobile.value})` : '',
  };
});

const ctaLink = computed((): string => {
  return props.content.cta_url || '';
});

const foregroundImage = computed((): string => {
  return props.content.foreground_image?.url || '';
});

const foregroundImageMobile = computed((): string => {
  return props.content.foreground_image?.mobile?.url || '';
});

const foregroundImageAlt = computed((): string => {
  return props.content.foreground_image?.alt || '';
});
</script>
<style lang="scss" scoped>
@import '@css/vue-import';

.home-hero {
  @include container;
  &__container {
    @apply ds-h-auto ds-bg-center ds-mt-4 sm:ds-mt-6;
    max-height: 540px;
    background-image: var(--hero-background-image-mobile, none);

    @include media-breakpoint-up(sm) {
      @apply ds-h-auto;
      max-height: 450px;
      background-image: var(--hero-background-image, none);
    }

    @include media-breakpoint-up(lg) {
      height: var(--hero-background-image-height);
      background-image: var(--hero-background-image, none);
    }
    @apply ds-flex ds-items-center ds-justify-center ds-bg-cover ds-rounded-md ds-overflow-hidden;
  }

  picture {
    @apply ds-max-h-[540px];
    img {
      @apply ds-max-h-[540px];
      img {
        @apply ds-max-h-[540px];
      }
    }
  }

  &__foreground {
    @apply ds-h-full ds-object-contain;
  }
}
</style>
<style lang="scss">
@import '@css/vue-import';

.hero-container {
  /* We need important to overwrite the styles are coming from blade */
  min-height: var(--hero-background-image-mobile-height) !important;

  @include media-breakpoint-up(lg) {
    min-height: var(--hero-background-image-height) !important;
  }
}
</style>
