import { CatalogueType, SectionType } from './enums';
import { AkNavMenuExtended } from '@ankorstore/design-system';
import { NavigationMethodType } from './enums';
import { CategoriesNavigationEvent } from './events';
import Analytics from '@monolith/legacy/services/analytics';
import UserClick from '@monolith/legacy/services/analytics/events/user-click.event';
import { trackProductClickWithAardvarkService, getNativeAdClickOrigin } from '@bc/advertisement';

export const evalSectionType = (catalogueType: CatalogueType) => {
  const sectionTypeMap: Record<CatalogueType, SectionType> = {
    [CatalogueType.Category]: SectionType.Category,
    [CatalogueType.Search]: SectionType.Search,
    [CatalogueType.BrandSearch]: SectionType.Brand,
    [CatalogueType.BusinessEvent]: SectionType.Event,
    [CatalogueType.Collection]: SectionType.Collection,
    [CatalogueType.New]: SectionType.New,
    [CatalogueType.Wholesale]: SectionType.Wholesale,
  };
  return sectionTypeMap[catalogueType];
};

export const getCategoryIdFromPath = (path: string): string => {
  if (path) {
    const regex = /-([\d]+)/g;
    const result = path.match(regex)?.slice(-1)?.[0].replace('-', '');
    return result || '';
  }
  return '';
};

export const trackNavigation = (item: AkNavMenuExtended): void => {
  const eventPayload = {
    id_category: `${item.meta?.id_category}`,
    name_category: item.meta?.name_category,
    path: item.url,
    navigation_method: NavigationMethodType.Menu,
    navigation_level: item.meta?.depth ? `L${item.meta?.depth}` : 'L1',
  };
  Analytics.track(new CategoriesNavigationEvent(eventPayload));
};

export const trackRelatedCollectionClicked = (collectionId: string, itemType: string, sectionId: string): void => {
  Analytics.track(
    new UserClick({
      component: 'reco_carousel',
      action: 'collection_clicked',
      itemType: `${itemType}_collection`,
      id_section: sectionId,
      id_collection: collectionId,
    })
  );
};

export const trackSponsoredProductClicked = async (params: {
  position: number;
  page: number;
  sectionId: string;
  eventTarget: Element;
  product: { id: number; aardvarkServeId: string; ad?: { metadata: { ad_id: string } } };
}) => {
  await Analytics.track(
    new UserClick({
      component: 'native_ads',
      action: 'product_clicked',
      itemType: 'product',
      productId: params.product.id,
      position: params.position,
      page_number: params.page,
      id_section: params.sectionId,
    })
  );
  const adId = params.product.aardvarkServeId || params.product.ad?.metadata.ad_id;
  trackProductClickWithAardvarkService(adId, getNativeAdClickOrigin(params.eventTarget));
};
