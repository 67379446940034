<template>
  <div :class="{ 'funnel-register': isNewSignup }">
    <Stepper
      v-if="isNewSignup"
      class="stepper"
      :current-step="currentStep"
    />
    <template v-if="isNewSignup">
      <div
        v-if="signupContent"
        class="funnel-register__description"
        data-testid="funnelRegisterDescriptionContainer"
      >
        <AkHeading1
          v-if="signupContent.title"
          class="description__title"
          data-testid="funnelRegisterTitle"
        >
          {{ signupContent.title }}
        </AkHeading1>
        <AkParagraph
          v-if="signupContent.description"
          class="description__paragraph"
          data-testid="funnelRegisterDescription"
        >
          {{ signupContent.description }}
        </AkParagraph>
        <AkParagraph
          v-if="signupContent.description2"
          class="description__paragraph"
          data-testid="funnelRegisterDescriptionBis"
        >
          {{ signupContent.description2 }}
        </AkParagraph>
      </div>
    </template>
    <div class="funnel-register__container">
      <EmailSignUpForm
        v-if="formType === RetailerFormType.EmailOnlySignUp"
        :analytic-props="analyticProps"
        @success="onEmailSignUpSuccess"
        @change-to-login="onChangeToLogin"
      />
      <RegisterForm
        v-if="formType === RetailerFormType.Register"
        :device-id="deviceId"
        @success="onRegisterSuccess"
      />
      <SecondStepForm
        v-if="formType === RetailerFormType.SecondStep"
        @success="onSecondStepSuccess"
      />
      <CategoriesForm
        v-if="formType === RetailerFormType.Categories"
        @success="onQualificationCategoriesSuccess"
      />
      <VatForm
        v-else-if="formType === RetailerFormType.Vat"
        :from-registration="true"
        :country-code="country"
        :tax-code="taxNumber"
        @success="onVatSuccess"
      />
      <ReferredConfirmation
        v-if="formType === RetailerFormType.ReferredConfirmed"
        @success="onFormSuccess"
      />
      <RequestInformationSignUpPopin
        v-if="formType === RetailerFormType.EarlyVerification"
        @success="onFormSuccess"
        @skip="onEarlyVerficationSkipped"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { RetailerFormType } from '@monolith/legacy/types/retailer-form-type';
import EmailSignUpForm from '@monolith/legacy/components/sign-up/email-sign-up-form.vue';
import RegisterForm from '@monolith/legacy/components/sign-up/register-form.vue';
import SecondStepForm from '@monolith/legacy/components/sign-up/second-step-form.vue';
import CategoriesForm from '@monolith/legacy/components/sign-up/common/categories-form.vue';
import VatForm from '@monolith/legacy/components/vat-form.vue';
import ReferredConfirmation from '@monolith/legacy/components/sign-up/common/referred-confirmation.vue';
import Analytics from '@monolith/legacy/services/analytics';
import { SignUpEventNames } from '@monolith/legacy/services/analytics/events/sign-up/common/event-names';
import SignUpEvent from '@monolith/legacy/services/analytics/events/sign-up/sign-up-event';
import { SignUpProps } from '@monolith/legacy/services/analytics/properties/sign-up-property';
import { FeatureFlag } from '@monolith/legacy/services/features';
import { mapGetters } from 'vuex';
import RequestInformationSignUpPopin from '@monolith/legacy/components/sign-up/request-information-sign-up-popin.vue';
import Stepper from '@monolith/legacy/modules/register/components/stepper.vue';
import { AkHeading1, AkParagraph } from '@ankorstore/design-system';

interface SuccessEvent {
  redirectUrl?: string;
}

interface SignUpContent {
  title: string;
  description?: string;
  description2?: string;
}

export default defineComponent({
  name: 'SignUp',
  components: {
    EmailSignUpForm,
    RegisterForm,
    SecondStepForm,
    CategoriesForm,
    VatForm,
    ReferredConfirmation,
    RequestInformationSignUpPopin,
    Stepper,
    AkHeading1,
    AkParagraph,
  },
  props: {
    analyticProps: {
      type: Object as PropType<SignUpProps>,
      required: false,
      default: () => null,
    },
    formType: {
      type: String as () => RetailerFormType,
      required: false,
      default: RetailerFormType.Login,
    },
    deviceId: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['redirect', 'success'],
  data() {
    return {
      country: null,
      RetailerFormType: RetailerFormType,
    };
  },
  computed: {
    ...mapGetters('signUp', [
      'liftPromoCode',
      'taxNumber',
      'email',
      'formValues',
      'getBestCategory',
      'isAnkorstart',
      'selectedCountryCode',
    ]),
    promoCode(): string {
      return this.$isEnabled(FeatureFlag.SignUpPersonalize) ? this.liftPromoCode : '';
    },
    hasEarlyVerification(): boolean {
      if (this.isAnkorstart) {
        return false;
      }
      const earlyVerificationFlag = this.$variant('early_verification');
      const blacklistedCountries = earlyVerificationFlag.split(',');
      return !blacklistedCountries.includes(this.selectedCountryCode);
    },
    currentStep(): number {
      switch (this.formType) {
        case RetailerFormType.Register:
          return 1;
        case RetailerFormType.SecondStep:
          return 2;
        case RetailerFormType.Categories:
          return 3;
        case RetailerFormType.Vat:
          return 4;
        default:
          return 1;
      }
    },
    signupContent(): SignUpContent | null {
      switch (this.formType) {
        case RetailerFormType.Register:
          return {
            title: this.$t('signup.step.1.title'),
            description: this.$t('signup.step.1.description'),
          };
        case RetailerFormType.SecondStep:
          return {
            title: this.$t('signup.step.2.title'),
            description: this.$t('signup.step.2.description'),
          };
        case RetailerFormType.Categories:
          return {
            title: this.$t('signup.step.3.title'),
            description: this.$t('signup.step.3.description'),
          };
        case RetailerFormType.Vat:
          return {
            title: this.$t('signup.step.4.title'),
            description: this.$t('signup.step.4.description'),
            description2: this.$t('signup.step.4.description.2'),
          };
        default:
          return null;
      }
    },
    isNewSignup(): boolean {
      return (
        this.formType === RetailerFormType.Register ||
        this.formType === RetailerFormType.SecondStep ||
        this.formType === RetailerFormType.Categories ||
        this.formType === RetailerFormType.Vat
      );
    },
  },
  methods: {
    onEmailSignUpSuccess(success: { email: string; nextStep: RetailerFormType }) {
      this.$emit('redirect', success.nextStep, {
        email: success.email,
      });
    },
    getCategoryRouteRedirect(): string {
      return `/shop/${this.getBestCategory}`;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onRegisterSuccess(formData: any) {
      this.$emit('redirect', this.RetailerFormType.SecondStep, formData);
    },
    onSecondStepSuccess() {
      this.$emit('redirect', this.RetailerFormType.Categories);
    },
    onQualificationCategoriesSuccess(e) {
      if (e && e.data && e.data.retailer && e.data.retailer.country) {
        if (!e.data.retailer.country.opened_to_retailers) {
          // If the country of the new retailer is not handled (cannot ship in this country),
          // Then we don't open the VAT popin.
          this.$emit('success');
          return;
        }
        this.country = e.data.retailer.country.iso_code;
      }
      this.$emit('redirect', this.RetailerFormType.Vat, {
        categories: e.model,
      });
    },
    onVatSuccess() {
      this.onFormSuccess();
    },
    onFormSuccess() {
      if (this.hasEarlyVerification) {
        const event: SuccessEvent = {};
        if (this.formType === this.RetailerFormType.EarlyVerification) {
          event.redirectUrl = '/account/settings?referral=signup';
        }
        this.$emit('success', event);
      } else {
        this.onEarlyVerficationSkipped();
      }
    },
    onEarlyVerficationSkipped() {
      const event: SuccessEvent = {};
      if (this.$isEnabled(FeatureFlag.RecommendationsRedirect) && this.formValues.categories) {
        event.redirectUrl = this.getCategoryRouteRedirect();
      }
      this.$emit('success', event);
    },
    onChangeToLogin() {
      Analytics.track(
        new SignUpEvent(SignUpEventNames.AlreadyAccountClickedEvent, {
          type: 'Click',
        })
      );
      this.$emit('redirect', RetailerFormType.Login);
    },
  },
});
</script>
<style lang="scss" scoped>
.stepper {
  @apply ds-py-2 md:ds-mt-7 ds-bg-white md:ds-bg-transparent;
}

.funnel-register {
  @apply ds-container ds-mx-auto ds-grid ds-gap-y-5 md:ds-gap-y-7 md:ds-gap-x-8 sm:ds-px-4;

  grid-template-areas:
    'stepper'
    'description'
    'container';

  grid-template-columns: 1fr;

  @media screen and (min-width: 992px) {
    grid-template-areas:
      'stepper stepper'
      'description container';
    grid-template-columns: 35fr 65fr;
  }

  &__container {
    @apply ds-mx-4 md:ds-mx-0;
    grid-area: container;
  }
  &__description {
    @apply ds-mt-3 md:ds-mt-0 ds-mx-4 md:ds-mx-0;
    grid-area: description;
    .description {
      &__title {
        @apply ds-mb-4;
      }
      &__paragraph {
        @apply ds-text-neutral-900 ds-mb-4;
      }
    }
  }
  .stepper {
    grid-area: stepper;
  }
}
</style>
<style lang="scss">
.stepper {
  & > .ds-block {
    @apply ds-w-full;
  }
  .ak-stepper {
    @apply ds-px-4;
  }
}
.funnel-register {
  &__container {
    form {
      @apply ds-p-5 ds-bg-white;
      .content {
        @apply ds-mt-0;
      }
    }
  }
  .stepper {
    @apply sm:ds-flex-col lg:ds-flex-row;
  }

  .step {
    &:not(.selected) {
      @apply sm:ds-hidden lg:ds-block;
    }
  }
}
</style>
