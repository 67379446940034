const Addresses = () => import('@monolith/legacy/modules/brand-account/pages/settings/addresses.page.vue');
const Shipping = () => import('@monolith/legacy/modules/brand-account/pages/settings/shipping.page.vue');
const ShopSettings = () => import('@monolith/legacy/modules/brand-account/pages/settings/shop-settings.page.vue');
const AccountSettingsPassword = () => import('@monolith/legacy/modules/shared/components/account-settings-password.vue');
const AccountTwoFactor = () => import('@monolith/legacy/modules/shared/components/two-factor.vue');
const CommunicationsConsent = () => import('@monolith/legacy/modules/brand-account/pages/settings/communications-consent.page.vue');
const CompanyDetails = () => import('@monolith/legacy/modules/brand-account/pages/settings/company-details.page.vue');
const BestPriceGuarantee = () => import('@monolith/legacy/modules/brand-account/pages/settings/best-price-guarantee.page.vue');
import Store from '@monolith/legacy/store/index';
import { FeatureFlag, isEnabled } from '@monolith/legacy/services/features';
import { MenuOptions } from './types/settings';
import { RouteLocationNormalized, RouteRecordRaw, NavigationGuardNext } from 'vue-router';

export const brandGuard = (
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
): NavigationGuardNext => (Store.getters['userIsBrand'] ? next : () => next({ name: 'settings' }));

export const liveBrandGuard = (
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
): NavigationGuardNext =>
  Store.getters['userIsCandidateBrand'] ? () => next({ name: 'settings' }) : brandGuard(_to, _from, next);

export const BPGGuard = (
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
): NavigationGuardNext => (isEnabled('pricing.bxp-1491.enrollment') ? next : () => next({ name: 'brandSettingsCompanyDetails' }));

export const twoFAGuard = (
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
): NavigationGuardNext =>
  isEnabled('auth.multi_factor_authentication') ? next : () => next({ name: 'brandSettingsCompanyDetails' });

export const communicationGuard = (
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
): NavigationGuardNext =>
  isEnabled('bxp-1371')
    ? next
    : () =>
        next({
          name: 'brandSettingsCompanyDetails',
        });

export const holidayModeGuard = (
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
): NavigationGuardNext => (isEnabled(FeatureFlag.HolidayMode) ? next : () => next({ name: 'brandSettingsCompanyDetails' }));

export const getBrandAccountSettingsRoutes = (): RouteRecordRaw[] =>
  Store.getters.userIsRetailer
    ? []
    : [
        {
          path: '',
          redirect: { name: MenuOptions.COMPANY_DETAILS },
        },
        {
          path: '/account/settings/company-details',
          name: MenuOptions.COMPANY_DETAILS,
          component: CompanyDetails,
          beforeEnter: (_to, _from, next) => {
            next = brandGuard(_to, _from, next);
            next?.();
          },
        },
        {
          path: '/account/settings/addresses',
          name: MenuOptions.ADDRESSES,
          component: Addresses,
          beforeEnter: (_to, _from, next) => {
            next = brandGuard(_to, _from, next);
            next && next();
          },
        },
        {
          path: '/account/settings/shipping',
          name: MenuOptions.SHIPPING,
          component: Shipping,
          beforeEnter: (_to, _from, next) => {
            next = brandGuard(_to, _from, next);
            next?.();
          },
        },
        {
          path: '/account/settings/shop-settings',
          name: MenuOptions.SHOP_SETTINGS,
          component: ShopSettings,
          beforeEnter: (_to, _from, next) => {
            next = brandGuard(_to, _from, holidayModeGuard(_to, _from, next));
            next?.();
          },
        },
        /** @deprecated - used only as redirect route for new separated address and shipping pages. */
        {
          path: '/account/settings/addresses-shipping',
          name: MenuOptions.ADDRESSES_AND_SHIPPING,
          redirect: () => ({ name: MenuOptions.SHIPPING }),
        },
        {
          path: '/account/settings/best-price-guarantee',
          name: MenuOptions.BPG,
          component: BestPriceGuarantee,
          beforeEnter: (_to, _from, next) => {
            next = BPGGuard(_to, _from, liveBrandGuard(_to, _from, next));
            next?.();
          },
        },
        {
          path: '/account/settings/communications',
          name: MenuOptions.COMMUNICATIONS,
          component: CommunicationsConsent,
          beforeEnter: (_to, _from, next) => {
            next = communicationGuard(_to, _from, liveBrandGuard(_to, _from, next));
            next?.();
          },
        },
        {
          path: '/account/settings/password',
          name: MenuOptions.PASSWORD,
          component: AccountSettingsPassword,
          beforeEnter: (_to, _from, next) => {
            next = liveBrandGuard(_to, _from, next);
            next?.();
          },
        },
        {
          path: '/account/settings/two-factor',
          name: MenuOptions.TWO_FACTOR,
          component: AccountTwoFactor,
          beforeEnter: (_to, _from, next) => {
            next = twoFAGuard(_to, _from, liveBrandGuard(_to, _from, next));
            next?.();
          },
        },
      ];
