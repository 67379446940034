<template>
  <div class="contact-brand">
    <AkAlert
      hide-icon
      class="ds-mb-4"
    >
      <div class="ds-flex ds-items-start">
        <AkIcon
          symbol="info-circle"
          size="md"
          class="ds-text-info-700 ds-mr-3"
        />
        {{
          $t(
            'Tracking link is missing from your order. Contact the brand to retrieve the tracking link to know the delivery status of your order.'
          )
        }}
      </div>
    </AkAlert>
    <div class="ds-flex ds-justify-center">
      <AkButton
        size="lg"
        outlined
        symbol="chat"
        @click="contactBrand"
      >
        {{ $t('Contact the brand') }}
      </AkButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapMutations, mapState, mapGetters } from 'vuex';

export default defineComponent({
  name: 'ContactBrand',
  computed: {
    ...mapState('legacyAccount', {
      order: 'orderDetail',
    }),
    ...mapGetters('legacyAccount', ['getOrderBrandProperty']),

  },
  methods: {
    ...mapMutations('orderRetailerRejection', ['SET_CONTACT_LINK_CLICKED']),
    contactBrand(): void {
      const url = `/account/messages/new?recipient_id=${this.$store.getters['legacyAccount/getOrderBrandProperty']('id')}`;

      this['SET_CONTACT_LINK_CLICKED']({ value: true });
      window.open(url, '_blank');
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';

.contact-brand {
  @apply ds-font-basic ds-pt-4;
}
</style>
