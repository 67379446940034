<template>
  <div
    ref="nav"
    class="lift-page-sticky"
  >
    <AkParagraph class="lift-page-sticky__text">
      {{ $t('Join the ecosystem where retailers and brands thrive') }}
    </AkParagraph>
    <AkButton
      :size="buttonSize"
      class="md:ds-text-base"
      @click="$emit('signup-click')"
    >
      {{ $t('Sign up to Shop') }}
    </AkButton>
  </div>
</template>
<script lang="ts">
import { loginPopinMixin } from '@core/mixins/login-popin';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LiftPageStickyNav',
  mixins: [loginPopinMixin],
  props: {
    brandId: {
      type: Number,
      required: true,
    },
  },
  emits: ['signup-click'],
  computed: {
    buttonSize() {
      return window.innerWidth < 767 ? 'md' : 'xl';
    },
  },
  mounted() {
    addEventListener('scroll', this.toggleNav);
  },
  unmounted() {
    removeEventListener('scroll', this.toggleNav);
  },
  methods: {
    toggleNav() {
      const nav = this.$refs.nav as any;
      nav.style.height = 'fit-content';
      if (window.innerWidth < 767) {
        nav.style.top = window.scrollY > 132 ? '0' : '-100px';
      } else {
        nav.style.bottom = window.scrollY > 132 ? '0' : '-70px';
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.lift-page-sticky {
  @apply ds-flex ds-w-full md:ds-justify-between ds-justify-end ds-pl-4 sm:ds-px-8 ds-py-4 ds-bg-warm-white ds-z-20 ds-items-center ds-shadow-md ds-fixed;
  bottom: -70px;
  transition: bottom 0.5s ease-in-out;
  height: 0;
  padding-right: 200px;

  @media (max-width: 768px) {
    top: -100px;
    bottom: initial;
    transition: top 0.5s ease-in-out;
    padding-right: 2rem;
  }

  &__text {
    @apply ds-mb-0 ds-font-bold ds-text-sm sm:ds-text-base ds-mr-4 md:ds-block ds-hidden;
  }
}
</style>
