import { Address } from '@monolith/legacy/types/address';
import Country from '@core/types/country';

const toAddress = function (
  place: google.maps.places.PlaceResult,
  getCountryId: (shortName: string) => Country | undefined
): Partial<Address> {
  const result: Partial<Address> = {};
  let streetAddress = '';

  for (const component of place.address_components) {
    const componentType = component.types[0];

    switch (componentType) {
      case 'street_number': {
        streetAddress = `${component.long_name} ${streetAddress}`;
        break;
      }
      case 'route': {
        streetAddress += component.short_name;
        break;
      }
      case 'postal_code': {
        result.postal_code = component.long_name;
        break;
      }
      case 'locality':
      case 'postal_town':
      case 'sublocality_level_1': {
        result.city = result.city || component.long_name;
        break;
      }
      case 'country': {
        result.country = getCountryId(component.short_name);
      }
    }
  }

  result.street = streetAddress;

  return result;
};

export { toAddress };
