import type { createApp } from "vue";
import autofocus from "vue-autofocus-directive";
import { VueMaskDirective } from "v-mask";
import { getGlobalConfig } from "@monolith/legacy/services/global-config";
import { getLang } from "@core/utilities/lang";
import { getInjectedContent } from "@monolith/legacy/services/injected-content";
import { Tag } from "@monolith/legacy/types/api/product";
import { GlobalMetaTagName, StateMetaTagName } from "@monolith/legacy/services/initial-state";

export default {
  install(app: ReturnType<typeof createApp>) {
    app.config.globalProperties.$lang = getLang();
    app.config.globalProperties.$cdn = getInjectedContent(GlobalMetaTagName.CDN) ?? 'img.ankorstore.com';
    app.config.globalProperties.$getTranslatedAttributeFromAlgolia = function (document, attribute) {
      return document?.[attribute]?.[getLang()] ?? document?.[attribute] ?? null;
    };
    app.config.globalProperties.$breakpoints = { lg: 992 };
    app.config.globalProperties.$isImpersonating = !!document
      .querySelector('.ak-global-container')
      ?.classList.contains('impersonnate');

    const categories = getInjectedContent<Record<string, string>>(GlobalMetaTagName.Categories);
    app.config.globalProperties.$categories = {
      idToName: categories,
      nameToId: {},
    };
    for (const id in categories) {
      app.config.globalProperties.$categories.nameToId[categories[id]] = Number(id);
    }
    app.config.globalProperties.$tags = getInjectedContent<Tag[]>(GlobalMetaTagName.Tags)?.reduce(
      (object, value) => Object.assign(object, { [value.name]: value }),
      {}
    );

    //Meta injections
    app.config.globalProperties.$managedLanguages = getInjectedContent(GlobalMetaTagName.ManagedLanguages);
    app.config.globalProperties.$globalConfig = getGlobalConfig();

    //Components and directive
    app.directive('autofocus', autofocus);
    app.directive('mask', VueMaskDirective);

    app.config.globalProperties.$currency = getInjectedContent(StateMetaTagName.Currency);
    app.config.globalProperties.$currenciesRates = getInjectedContent(StateMetaTagName.CurrenciesRates);
  },
};
