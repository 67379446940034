import { WalletBalance, WalletStatement, WalletAmountsAvailable } from '@monolith/legacy/types/wallet';
import http from './http';
import { memoize } from 'lodash-es';

export const getWalletBalance = memoize((isRetailer = false) => {
  return isRetailer
    ? http()
        .get('/api/me/wallet')
        .then((response) => response.data?.data as WalletBalance)
    : null;
});

export const getWalletStatement = memoize(({ userIsRetailer = false, url = null }) => {
  if (!userIsRetailer) {
    return null;
  }
  const fetchUrl = url || '/api/me/wallet-operation';

  return http()
    .get(fetchUrl)
    .then((response) => response.data?.data as WalletStatement);
});

export const getWalletAmountAvailable = () => {
  const fetchUrl = '/api/wallet/amounts-available';

  return http()
    .get(fetchUrl)
    .then((response) => response.data?.data as WalletAmountsAvailable);
};
