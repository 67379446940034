<!-- eslint-disable @intlify/vue-i18n/no-dynamic-keys -->
<template>
  <div>
    <template v-if="isRevamp">
      <div
        ref="popupItem"
        v-click-outside="closeWithSelection"
        class="ds-relative"
        @keydown.prevent.enter="closeWithSelection"
      >
        <InputSearch
          :model="searchTerm"
          :error="getError"
          data-testid="company-search-input"
          name="company_name"
          :label="question.label"
          :placeholder="question.placeholder"
          @focused="onInputFocused"
          @input="onInputChanged"
        />
        <AutocompleteDropdown
          v-if="isTextThreeCharactersDisplayed || isDropdownActivated"
          :options="isDropdownActivated ? filteredOptions : []"
          :selected-option-id="selectedOptionId"
          @select-option="selectOption"
          @button-tab="onDropdownButtonTab"
        >
          <div class="ds-flex ds-flex-wrap ds-items-center ds-my-3 ds-px-3">
            <AkParagraph
              v-if="isTextThreeCharactersDisplayed"
              class="ds-mb-0 ds-py-1"
              size="sm"
              data-testid="type-three-characters"
            >
              {{ $t('Type at least 3 characters to find your company') }}
            </AkParagraph>
            <div
              v-if="isDropdownActivated"
              data-testid="dropdownIsActivated"
              class="ds-flex ds-flex-wrap ds-items-center ds-pt-1"
            >
              <AkParagraph
                size="sm"
                class="company-search--is-not-in-list"
              >
                {{ $t('Company is not on the list?') }}
              </AkParagraph>
              <!-- we don't use here AkButton is that it doesn't support key events -->
              <button
                class="company-search--use-anyway-button"
                data-testid="useAnyways"
                @click.prevent="selectOption({ name: searchTerm })"
                @keydown="onSelectAnywayTab($event)"
              >
                {{ $t('Click here to use anyways') }}
              </button>
            </div>
          </div>
          <template #item="{ option }">
            <div
              class="company-search--name"
              data-testid="company-search-dropdown-item__name"
            >
              {{ option.name }}
            </div>
            <div
              class="company-search--address"
              data-testid="company-search-dropdown-item__address"
            >
              {{ companyAddress(option) }}
            </div>
          </template>
        </AutocompleteDropdown>
      </div>
    </template>
    <template v-else>
      <div :class="{ 'required-field': question.required }">
        <div
          v-click-outside="close"
          class="form-label-group ds-mb-3"
        >
          <input
            v-model.trim="searchTerm"
            type="organization"
            class="form-control company-search-input hide-placeholder-on-focus"
            data-testid="company-search-input"
            name="company_name"
            autocomplete="off"
            :class="{ 'is-invalid': !!errors.company_name }"
            :placeholder="$t(question.label)"
            :required="question.required"
            @focus="onInputFocused()"
            @input="onInputChanged()"
            @keydown.tab="onInputTab($event)"
          >

          <label
            class="company-search-input-label ds-pointer-events-none"
            data-testid="company-search-input-label"
          >
            <AkIcon
              v-show="isLabelIconDisplayed"
              class="company-search--label-icon"
              data-testid="company-search-input-label-icon"
              symbol="search"
              size="sm"
            />
            <span>{{ $t(question.label) }}</span>
          </label>

          <LoaderIcon
            v-if="isLoading"
            class="company-search--loader"
            small
          />
          <div class="company-search--dropdown-menu-group">
            <AkParagraph
              v-if="isTextThreeCharactersDisplayed"
              class="company-search--type-three-characters"
              size="sm"
              data-testid="type-three-characters"
            >
              {{ $t('Type at least 3 characters to find your company') }}
            </AkParagraph>
            <div
              v-if="isDropdownActivated"
              data-testid="dropdownIsActivated"
              class="ds-flex ds-flex-wrap ds-items-center ds-my-3"
            >
              <AkParagraph
                size="sm"
                class="company-search--is-not-in-list"
              >
                {{ $t('Company is not on the list?') }}
              </AkParagraph>
              <!-- we don't use here AkButton is that it doesn't support key events -->
              <button
                class="company-search--use-anyway-button"
                data-testid="useAnyways"
                @click.prevent="selectOption({ name: searchTerm })"
                @keydown="onSelectAnywayTab($event)"
              >
                {{ $t('Click here to use anyways') }}
              </button>
            </div>

            <ul
              v-if="isDropdownActivated"
              class="company-search--dropdown-menu"
              :class="{ invisible: !filteredOptions.length }"
            >
              <li
                v-for="(option, i) in filteredOptions"
                :key="i"
              >
                <button
                  class="company-search--dropdown-menu-item"
                  :class="{ 'company-search--selected-option': isSelected(option) }"
                  role="option"
                  type="button"
                  data-testid="company-search-dropdown-item"
                  @click.prevent="selectOption(option)"
                  @keydown.tab="onDropdownButtonTab(i)"
                >
                  <div
                    class="company-search--name"
                    data-testid="company-search-dropdown-item__name"
                  >
                    {{ option.name }}
                  </div>
                  <div
                    class="company-search--address"
                    data-testid="company-search-dropdown-item__address"
                  >
                    {{ companyAddress(option) }}
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <span
          v-if="
            state[question.alias] &&
              state[question.alias].$dirty &&
              state[question.alias].required &&
              state[question.alias].required.$invalid
          "
          data-testid="company-search-required"
        >
          {{ $t('Company name is required') }}
        </span>
        <span
          v-if="errors.company_search"
          data-testid="question-error-company"
          class="error-message"
        >
          {{ errors.company_search[0] }}
        </span>
        <QuestionFree
          :model="currentModel"
          :question="storeNameQuestion"
          :label="$t('Shop name')"
          :state="state"
          @change="selectShopName"
        />
        <span
          v-if="errors.store_name"
          data-testid="question-error-store"
          class="error-message"
        >
          {{ errors.store_name[0] }}
        </span>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ClickOutside } from '@ankorstore/vue-click-outside';
import { defineComponent } from 'vue';
import { Question } from '@monolith/legacy/types/question';
import { debounce } from 'lodash-es';
import { searchCompanies } from '@monolith/legacy/services/api/company-search';
import Company from '@monolith/legacy/types/api/company';
import { FeatureFlag } from '@monolith/legacy/services/features';
import QuestionFree from '@monolith/legacy/components/sign-up/questions/types/question-free.vue';
import LoaderIcon from '@monolith/legacy/components/loader-icon.vue';
import { AkParagraph, AkIcon } from '@ankorstore/design-system';
import { State } from '@monolith/legacy/types/state';
import { mapGetters } from 'vuex';
import { store_name } from '../mockQuestions';
import SignUpEvent from '@monolith/legacy/services/analytics/events/sign-up/sign-up-event';
import { SignUpEventNames } from '@monolith/legacy/services/analytics/events/sign-up/common/event-names';
import Analytics from '@monolith/legacy/services/analytics';
import InputSearch from '@monolith/legacy/components/sign-up/common/autocomplete/input-search.vue';
import AutocompleteDropdown from '@monolith/legacy/components/sign-up/common/autocomplete/autocomplete-dropdown.vue';

export default defineComponent({
  name: 'CompanySearch',
  components: {
    AkParagraph,
    AkIcon,
    LoaderIcon,
    QuestionFree,
    InputSearch,
    AutocompleteDropdown,
  },
  directives: {
    ClickOutside,
  },
  props: {
    question: {
      type: Object as () => Question,
      required: true,
    },
    state: {
      type: Object as () => State,
      required: true,
    },
    model: {
      type: Object,
      required: false,
      default: () => {},
    },
    errors: {
      type: Object,
      required: false,
      default: null,
    },
    isRevamp: {
      type: Boolean,
      required: false,
    },
    relatedAlias: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['change'],
  data() {
    return {
      searchTerm: '',
      currentModel: this.model || {},
      storeNameQuestion: store_name,
      selectedOption: {} as Company,
      selectedOptionId: 0,
      filteredOptions: [] as Company[],
      isComponentFocused: false,
      isLoading: false,
      isSuggestionSelected: false,
      FeatureFlag,
    };
  },
  computed: {
    ...mapGetters('signUp', ['selectedCountryCode']),
    getError() {
      return this.state[this.question.alias]?.$error ? this.$t('This field is required') : '';
    },
    countries() {
      return Object.fromEntries(
        Object.entries(this.$store.state.global.countries?.countries).map(([, value]) => [value.id, value])
      );
    },
    isTextThreeCharactersDisplayed() {
      return this.isComponentFocused && this.searchTerm?.length < 3;
    },
    isDropdownActivated() {
      return this.searchTerm?.length >= 3 && this.isComponentFocused;
    },
    isLabelIconDisplayed(): boolean {
      return !this.searchTerm?.length && !this.isComponentFocused;
    },
  },
  methods: {
    companyAddress(company: Company): string {
      return `${company.streetAddress}, ${this.countries[company.countryId]?.name}`;
    },
    onInputFocused() {
      this.isComponentFocused = true;
      this.onInputChanged();
    },
    onInputChanged(value?: string) {
      if (value) {
        this.searchTerm = value;
      }
      this.debouncedSearch();
    },
    debouncedSearch: debounce(function (this: Vue) {
      this.search();
    }, 500),
    async search() {
      if (this.searchTerm?.length < 3) {
        return;
      }
      this.isLoading = true;
      this.filteredOptions = await searchCompanies({
        name: this.searchTerm,
        countryCode: this.selectedCountryCode,
      });
      this.isLoading = false;
    },
    selectOption(option: Company, index?: number) {
      this.currentModel = { content: option.name };

      if (option.name) {
        this.$emit('change', 'store_name', { content: option.name }, true);
      }
      if (option.postalCode) {
        this.$emit('change', 'postal_code', { content: option.postalCode }, true);
      }
      if (option.city) {
        this.$emit('change', 'city', { content: option.city }, true);
      }

      if (option.streetAddress) {
        this.$emit('change', 'street', { content: option.streetAddress }, true);
        this.$emit('change', 'address', { content: option.streetAddress }, true);
      }
      this.$emit('change', this.question.alias, {
        content: option.externalId,
        answer_id: option.externalId ? this.question.answers[0].id : null,
      });
      if (option.shopTypeAnswerId) {
        this.$emit(
          'change',
          'shop_type',
          {
            answer_id: option.shopTypeAnswerId,
            content: null,
          },
          true
        );
      }

      if (option.companyNumber) {
        this.$store.commit('signUp/SET_FORM_VALUES', {
          taxNumber: option.companyNumber,
        });
      }

      if (option.streetAddress || option.city || option.postalCode) {
        if (!this.isSuggestionSelected) {
          Analytics.track(
            new SignUpEvent(SignUpEventNames.CompanySearchSuggestionClickedEvent, {
              type: 'Clicked',
            })
          );
          this.isSuggestionSelected = true;
          this.$store.dispatch('signUp/setCompanyWasSelectedFromDropdown', true);
        }
      }

      this.searchTerm = option.name;
      this.selectedOption = option;
      this.selectedOptionId = index === undefined ? 0 : index + 1;
      this.close();
    },
    selectShopName(_, answer) {
      const name = answer?.content || this.searchTerm;
      this.currentModel = { content: name };
      if (name) {
        this.$emit('change', 'store_name', { content: name }, true);
        this.close();
      }
    },
    close() {
      this.isComponentFocused = false;
    },
    closeWithSelection() {
      if (this.searchTerm !== this.currentModel.content) {
        this.selectShopName();
      }
      this.isComponentFocused = false;
    },
    isSelected(option: Company) {
      return option.companyNumber === this.selectedOption.companyNumber;
    },
    onInputTab(event) {
      if (event.shiftKey || !this.isDropdownActivated) {
        this.close();
      }
    },
    onSelectAnywayTab(event) {
      if (!event.shiftKey && !this.filteredOptions.length) {
        this.close();
      }
    },
    onDropdownButtonTab(index) {
      if (index === this.filteredOptions.length - 1) {
        this.close();
      }
    },
  },
});
</script>

<style scoped lang="scss">
.form-control {
  height: 35px;
}

.company-search-input {
  @apply ds-pr-5 #{!important};
}

.company-search--label-icon {
  @apply ds-mr-1 ds-relative ds-top-0.5;
}

.company-search-input:placeholder-shown ~ .company-search-input-label {
  top: -5px;
}

.company-search-input:focus ~ .company-search-input-label {
  @apply ds-top-0;
}

.company-search--loader {
  @apply ds-top-2.5 ds-right-0;
  @apply ds-absolute #{!important};
}

.company-search--is-not-in-list {
  @apply ds-mb-1 ds-mr-2;
}

.company-search--type-three-characters {
  @apply ds-mt-3;
}

.company-search--dropdown-menu-group {
  @apply ds-absolute ds-bg-white ds-z-10 ds-w-full;
}

.company-search--dropdown-menu {
  @apply ds-mt-2 ds--mx-3 ds-mb-0 ds-border ds-border-neutral-300 ds-bg-white ds-border-solid ds-overflow-auto ds-w-auto;
  max-height: 237px;
  scrollbar-color: rgba(200, 196, 191, var(--tw-bg-opacity)) transparent;
  scrollbar-width: 8px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    @apply ds-bg-neutral-500 ds-rounded-full;
  }
}

.company-search--selected-option {
  @apply ds-bg-warm-white;

  .company-search--address {
    @apply ds-text-neutral-900;
  }

  .company-search--name {
    @apply ds-font-semibold;
  }
}

.company-search--is-not-in-list,
.company-search--type-three-characters,
.company-search--name {
  @apply ds-text-neutral-900 ds-mb-1 ds-text-left;
}

.company-search--address {
  @apply ds-text-neutral-700 ds-text-left;
}

.company-search--dropdown-menu-item {
  @apply ds-px-3 ds-py-2 ds-w-full;

  &:hover,
  &:focus {
    @apply ds-outline-none ds-bg-neutral-300;
  }
}

.company-search--use-anyway-button {
  @apply ds-underline ds-mb-1;

  &:hover,
  &:focus {
    @apply ds-outline-none ds-opacity-80 ds-bg-white;
  }
}
</style>
