<template>
  <div class="ds-flex ds-flex-col ds-items-center ds-w-full ds-h-full ds-justify-center">
    <AkIcon
      class="response-thanks__icon"
      symbol="check2-circle"
    />
    <AkParagraph>{{ thankYou1 }}</AkParagraph>
    <AkParagraph class="ds-text-center">
      {{ thankYou2 }}
    </AkParagraph>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { ResponseDrawerType } from '@monolith/legacy/store/reviews';

export default defineComponent({
  name: 'BrandResponseThanks',
  computed: {
    ...mapGetters('reviews', ['currentReview', 'responseDrawerType']),
    thankYou1(): string {
      switch (this.responseDrawerType) {
        case ResponseDrawerType.public:
          return this.$t('@@retailer_xp.reviews.brand_response.thanks_1', {
            retailer: this.currentReview?.retailer?.first_name,
          });
        case ResponseDrawerType.report:
        case ResponseDrawerType.flag:
          return this.$t('@@retailer_xp.reviews.brand_report.thanks_1');
        default:
          return null;
      }
    },
    thankYou2(): string {
      switch (this.responseDrawerType) {
        case ResponseDrawerType.public:
          return this.$t('@@retailer_xp.reviews.brand_response.thanks_2');
        case ResponseDrawerType.report:
          return this.$t('@@retailer_xp.reviews.brand_report.thanks_2');
        case ResponseDrawerType.flag:
          return this.$t('@@retailer_xp.reviews.brand_flag.thanks_2');
        default:
          return null;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.response-thanks__icon {
  font-size: 1.75rem;
  line-height: 2rem;
}
</style>
