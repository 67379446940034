<template>
  <ais-breadcrumb
    :attributes="[
      'catalog_hierarchical_categories.v3.lvl0',
      'catalog_hierarchical_categories.v3.lvl1',
      'catalog_hierarchical_categories.v3.lvl2',
    ]"
    :class-names="{
      'ais-Breadcrumb': 'catalog__breadcrumb',
    }"
  >
    <template #default="{ items, refine, createURL }">
      <ul
        data-testid="categoriesList"
        class="catalog__breadcrumb-list catalog__breadcrumb-list--slash"
      >
        <li v-if="searchQuery">
          <a
            data-testid="breadcrumbLink"
            :href="createURL()"
            @click.prevent="refine()"
          >{{ $t('Results for: {searchquery}', { searchquery: searchQuery }) }}</a>
        </li>
        <li v-else>
          <a
            data-testid="breadcrumbLink"
            href="/shop"
          >{{ $t('Ankorstore') }}</a>
        </li>
        <li
          v-for="(item, idx) in items"
          :key="item.label"
        >
          <a
            v-if="item.value"
            :data-testid="`categoryLink_${idx}`"
            :href="createURL(item.value)"
            @click.prevent="refine(item.value)"
          >
            {{ getTranslatedCategories(item.label) }}
          </a>
          <span v-else> {{ getTranslatedCategories(item.label) }}</span>
        </li>
      </ul>
    </template>
  </ais-breadcrumb>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useCategories } from '@bc/discovery/domain/catalog';

export default defineComponent({
  name: 'CatalogBreadcrumb',
  props: {
    searchQuery: {
      type: String,
      default: undefined,
    },
  },
  setup() {
    const { getTranslatedCategories } = useCategories();
    return { getTranslatedCategories };
  },
});
</script>

<style scoped lang="scss">
.catalog {
  @mixin separator($symbol) {
    li:not(:last-child):after {
      content: $symbol;
    }
  }
  &__breadcrumb {
    @apply ds-col-span-2;
  }

  &__breadcrumb-list {
    @apply ds-flex ds-mb-0 ds-pt-4;
    flex-wrap: wrap;

    &--chevron {
      @include separator('>');
    }

    &--slash {
      @include separator('/');
    }

    li {
      @apply ds-text-neutral-500;

      a {
        @apply ds-text-primary;
        text-decoration: none;
      }

      &:not(:last-child):after {
        content: '>';
        @apply ds-text-primary ds-inline-block ds-mx-1;
      }
    }
  }
}
</style>
