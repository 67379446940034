import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import Store from '@monolith/legacy/store';
import { isEnabled } from '@monolith/legacy/services/features';
import { getPaymentServiceCustomer } from '@monolith/legacy/services/fulfillment/api';
import { PaymentServiceMandateStatus } from '@monolith/legacy/types/fulfillment-invoice';

export async function adsDashboardGuard(_to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) {
  await Store.dispatch('brandDashboardStore/fetchAdsCampaigns', { brandUuid: Store.getters['brand'].uuid, offset: 0 });
  next();
}

export async function sepaMandateAdsGuard(
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  if (!isEnabled('MNY_321')) {
    return next();
  }

  try {
    const { mandateStatus } = await getPaymentServiceCustomer(Store.getters['brand'].id);
    if (mandateStatus === PaymentServiceMandateStatus.ACTIVE) {
      next();
    } else {
      next({ name: 'brand-dashboard-ads' });
    }
  } catch (err) {
    next({ name: 'brand-dashboard-ads' });
  }
}
