import { computed, inject, onBeforeMount, PropType, ref, Ref, nextTick } from 'vue';
import {
  IFormErrors,
  IFormValuesObject,
  TFormValue,
  TFieldValidateValue,
  FormValuesObject,
  TFieldValidateFunction,
  IFormUpdateErrorEventPayload,
  IFormUpdateValueEventPayload,
} from '@monolith/legacy/components/review/AkForm/types';
import { EventEmitter } from '@bc/shared';

export const formFieldProps = {
  name: {
    type: String,
    required: true,
  },
  validate: {
    type: Function as PropType<TFieldValidateFunction>,
    default: undefined,
  },
};

export function useFormConnection(name: Ref<string>, validate: Ref<TFieldValidateFunction>) {
  const controlComponentRef = ref(null);
  const fieldIsValidating = ref(false);

  const formBus = inject<EventEmitter>('formBus');
  const proxyValues = inject<Ref<IFormValuesObject>>('proxyValues');
  const errors = inject<Ref<IFormErrors>>('errors');
  const formState = inject('formState');
  const groups = inject<string[]>('groups');

  const value = computed<TFormValue>({
    get() {
      return FormValuesObject.getValue(proxyValues.value, name.value, groups);
    },
    async set(newValue) {
      const payload: IFormUpdateValueEventPayload = {
        name: name.value,
        value: newValue,
        groups,
      };
      formBus.emit('update-value', payload);
      if (formState['isSubmitted'].value && validate?.value) {
        await validateField();
      }
    },
  });
  const errorMessage = computed<TFieldValidateValue>({
    get() {
      return errors.value[name.value]?.toString();
    },
    set(newError) {
      const payload: IFormUpdateErrorEventPayload = {
        name: name.value,
        error: newError,
      };
      formBus.emit('update-error', payload);
    },
  });
  const isDisabled = computed<boolean>(() => formState['isValidating'].value || fieldIsValidating.value);
  const hasError = computed<boolean>(() => typeof errorMessage.value === 'string');

  async function validateField() {
    if (typeof validate.value === 'function') {
      fieldIsValidating.value = true;
      errorMessage.value = (await validate.value(value.value)) || undefined;
      fieldIsValidating.value = false;
      nextTick(() => {
        controlComponentRef.value?.focus?.();
      });
    }
  }

  onBeforeMount(() => {
    formBus.emit('register-field', {
      name: name.value,
      groups,
      controlComponentRef,
      validateField,
    });
  });

  return {
    formBus,
    groups,
    controlComponentRef,
    value,
    errorMessage,
    hasError,
    isDisabled,
    validateField,
  };
}
