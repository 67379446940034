import Company from '@monolith/legacy/types/api/company';
import http from '@monolith/legacy/services/api/http';
import { captureException } from '@core/plugins/sentry';
import { memoize } from 'lodash-es';

export const searchCompanies = async ({ name, countryCode }): Promise<Company[]> => {
  return searchCompaniesMemoize({
    name: name.toLocaleLowerCase(),
    countryCode,
  });
};

const searchCompaniesMemoize = memoize(
  async ({ name, countryCode }): Promise<Company[]> => {
    try {
      const response = await http().get('/api/company-search', {
        params: { name, countryCode },
      });
      return response.data?.data || [];
    } catch (error) {
      if (error.response.status !== 403) {
        captureException(error);
      }

      return [];
    }
  },
  (...args) => JSON.stringify(args)
);
