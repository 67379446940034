<template>
  <div class="gsheet-modal-setup">
    <AkHeading4>
      {{ i18n.t('brands.catalogIntegration.gsheet.gsheetSetup.heading') }}
    </AkHeading4>
    <AkParagraph
      v-if="!isExportInProgress"
      class="gsheet-modal-setup__subHeading"
    >
      {{ i18n.t('brands.catalogIntegration.gsheet.gsheetSetup.subHeading') }}
    </AkParagraph>
    <LastExportCatalogue
      v-if="isExportInProgress || catalogueExportLoading"
      class="ds-mt-6"
      is-displayed
    />
    <div v-else>
      <div>
        <div class="gsheet-modal-setup__title">
          {{ i18n.t('brands.catalogIntegration.gsheet.gsheetSetup.importScope.title') }}
        </div>
        <div class="gsheet-modal-setup__input">
          <AkRadio
            :value="ImportScope.NewProductsOnly"
            boxed
            :is-checked="importScope === ImportScope.NewProductsOnly"
            name="gsheet-modal-setup-import-scope"
            :text="i18n.t('brands.catalogIntegration.gsheet.gsheetSetup.newProductsOnly.title')"
            :description="i18n.t('brands.catalogIntegration.gsheet.gsheetSetup.newProductsOnly.description')"
            @change="onChangeImportScope"
          />
          <AkRadio
            :value="ImportScope.NewAndExistingProducts"
            boxed
            :is-checked="importScope === ImportScope.NewAndExistingProducts"
            name="gsheet-modal-setup-import-scope"
            :text="i18n.t('brands.catalogIntegration.gsheet.gsheetSetup.newAndExistingProducts.title')"
            :description="i18n.t('brands.catalogIntegration.gsheet.gsheetSetup.newAndExistingProducts.description')"
            @change="onChangeImportScope"
          />
        </div>
      </div>
      <div>
        <div class="gsheet-modal-setup__title">
          {{ i18n.t('brands.catalogIntegration.gsheet.gsheetSetup.imagesImportMethod.title') }}
        </div>
        <div class="gsheet-modal-setup__input">
          <AkRadio
            :value="ImagesImportMethod.Upload"
            boxed
            :is-checked="imagesImportMethod === ImagesImportMethod.Upload"
            name="gsheet-modal-setup-images"
            :text="i18n.t('brands.catalogIntegration.gsheet.gsheetSetup.imagesUpload.title')"
            :description="i18n.t('brands.catalogIntegration.gsheet.gsheetSetup.imagesUpload.description')"
            @change="onChangeImagesImportMethod"
          />
          <AkRadio
            :value="ImagesImportMethod.Url"
            boxed
            :is-checked="imagesImportMethod === ImagesImportMethod.Url"
            name="gsheet-modal-setup-images"
            :text="i18n.t('brands.catalogIntegration.gsheet.gsheetSetup.imagesUrl.title')"
            :description="i18n.t('brands.catalogIntegration.gsheet.gsheetSetup.imagesUrl.description')"
            @change="onChangeImagesImportMethod"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed, ComputedRef } from 'vue';
import { AkParagraph, AkHeading4, AkRadio } from '@ankorstore/design-system';
import { ImagesImportMethod, ImportScope } from '@bc/brands/feature/catalog-integration/types/gsheet.types';
import LastExportCatalogue from '@monolith/legacy/components/account/account-products/last-export-catalogue.vue';
import useI18n from '@core/composables/use-i18n';
import { useStore } from '@core/composables/use-store';

export default defineComponent({
  name: 'GsheetSetup',
  components: {
    AkParagraph,
    AkHeading4,
    AkRadio,
    LastExportCatalogue,
  },
  props: {
    importScope: {
      type: String,
      default: null,
    },
    imagesImportMethod: {
      type: String,
      default: null,
    },
    catalogueExportLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['changeImportScope', 'changeImagesImportMethod'],
  setup(_,{ emit }) {
    const i18n = useI18n();
    const store = useStore();

    const isExportInProgress: ComputedRef<boolean> = computed(() => store.getters["account/productsIntegration/isExportInProgress"]);

    const onChangeImportScope = (_checked: boolean, value: ImportScope): void => {
      emit('changeImportScope', value);
    };

    const onChangeImagesImportMethod = (_checked: boolean, value: ImagesImportMethod): void => {
      emit('changeImagesImportMethod', value);
    };

    return {
      i18n,
      ImagesImportMethod,
      ImportScope,
      isExportInProgress,
      onChangeImportScope,
      onChangeImagesImportMethod,
    };
  }
});
</script>
<style scoped lang="scss">
@import '@css/vue-import';

.gsheet-modal-setup {
  &__input {
    @apply ds-flex ds-justify-center ds-mb-7 ds-gap-4 ds-text-left;
  }

  &__title {
    @apply ds-font-bold ds-mb-4 ds-text-left ds-text-base;
  }

  &__subHeading{
    @apply ds-py-2 ds-text-neutral-900 ds-mb-6;
  }
}
</style>
