<template>
  <div
    v-if="!isCheckoutPage"
    v-click-outside="handleClickOutside"
    class="notifications-panel"
  >
    <div
      v-click-outside="handleClickOutside"
      class="notifications-panel__toggle"
      role="button"
      @click="toggleNotifications"
    >
      <button
        :class="[{ 'badge-spacing': hasUnread }, 'notifications-panel__button']"
        type="button"
        :aria-label="$t('Notifications')"
      >
        <AkIcon
          symbol="bell"
          size="md"
        />
      </button>

      <UnreadCountBadge
        v-if="hasUnread"
        :unread-count="unreadCount"
      />
    </div>

    <div
      ref="feed"
      class="notifications-panel__feed"
    ></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ClickOutside } from '@ankorstore/vue-click-outside';
import UnreadCountBadge from '@monolith/legacy/components/notifications/unread-count-badge.vue';
import * as braze from '@monolith/legacy/services/braze';
import Analytics from '@monolith/legacy/services/analytics';
import { NotificationsPanelEvent } from '@monolith/legacy/services/analytics/events/notifications-panel-event';
import useIsCheckout from '@core/composables/use-enclosed-checkout';

export default defineComponent({
  name: 'NotificationsPanel',
  components: {
    UnreadCountBadge,
  },
  directives: {
    ClickOutside,
  },
  setup() {
    const { isCheckoutPage } = useIsCheckout();

    return {
      isCheckoutPage,
    };
  },
  data() {
    return {
      isPanelVisible: false,
      unreadCount: 0,
    };
  },
  computed: {
    hasUnread(): boolean {
      return this.unreadCount > 0;
    },
  },
  async mounted() {
    await braze.initializeBraze();
    // The order of braze calls is important
    // If in doubt refer to the braze documentation
    braze.subscribeToContentCardsUpdates(() => this.updateUnreadCount());
    braze.openSession();

    braze.requestContentCardsRefresh();
    this.updateUnreadCount();
  },
  unmounted() {
    braze.destroy();
  },
  methods: {
    handleClickOutside(): void {
      if (this.isPanelVisible) {
        this.isPanelVisible = false;
        Analytics.track(new NotificationsPanelEvent('close', 'click outside'));
        braze.hideContentCards();
      }
    },
    toggleNotifications(): void {
      if (this.isPanelVisible) {
        braze.hideContentCards();
        Analytics.track(new NotificationsPanelEvent('close', 'button'));
      } else {
        Analytics.track(new NotificationsPanelEvent('open', 'button'));
        braze.showContentCards(this.$refs.feed as Element);
      }

      this.isPanelVisible = !this.isPanelVisible;
    },
    updateUnreadCount(): void {
      this.unreadCount = braze.getCachedContentCards().getUnviewedCardCount();
    },
  },
});
</script>

<!-- Styles are not scoped because the braze library appends panel styles to the
document so we need to globally override them but still keep the CSS in context where it
is relevant -->
<style lang="scss">
.notifications-panel {
  &__toggle {
    @apply ds-relative;
  }

  &__button {
    &.badge-spacing {
      @apply ds-pr-2 md:ds-pr-0 md:ds-pl-2;
    }

    &:hover {
      @apply ds-text-neutral-700;
    }
  }

  &__feed {
    @apply ds-absolute ds-top-full ds-z-50;
    @apply ds--right-4 ds-w-screen;
    @apply md:ds-right-0 md:ds-w-max md:ds-max-w-md;

    .ab-feed {
      @apply ds-w-full md:ds-w-auto;
      @apply ds-border-none  ds-shadow-2xl #{!important};
      max-height: 510px;

      &.ab-effect-slide {
        transform: translate(0, 0) !important;
        transition: none !important;
      }

      .ab-feed-buttons-wrapper {
        @apply ds-flex ds-justify-between;
        @apply ds-bg-neutral-300 ds-shadow-none #{!important};

        i {
          @apply ds-text-primary #{!important};

          &.ab-close-button {
            @apply ds-hidden #{!important};
          }
        }
      }

      .ab-feed-body {
        @apply ds-pb-1;
        @apply ds-bg-neutral-100 ds-border-none #{!important};
      }

      .ab-card {
        @apply ds-border-neutral-300 ds-border-solid ds-border ds-rounded-sm ds-shadow-sm #{!important};

        .ab-card-body {
          @apply ds-text-left;

          .ab-title a {
            @apply ds-text-base #{!important};

            &:hover {
              @apply ds-text-primary #{!important};
            }
          }

          .ab-description {
            @apply ds-text-sm ds-leading-5 #{!important};

            .ab-url-area a {
              @apply ds-text-primary ds-underline #{!important};
            }
          }
        }

        .ab-unread-indicator {
          @apply ds-bg-accent-alt #{!important};

          &.read {
            @apply ds-bg-transparent #{!important};
          }
        }
      }

      .ab-close-button {
        @apply ds-opacity-100 #{!important};
      }
    }
  }
}
</style>
