<template>
  <AkTooltipInformation
    v-if="shouldShowTooltip"
    class="program_tooltip"
    placement="right"
    trigger="hover"
    type="invert"
    custom-class="tooltip-aks-plus"
    data-testid="tooltipAksPlus"
  >
    <AkIcon
      symbol="info-circle"
      size="md"
      class="ds-ml-1"
    />
    <template #container>
      <AkHeading4>
        <AnkorstorePlusBadge class="ds-mr-2" />{{ title }}
        <img
          class="perk__logo"
          src="/images/logo/ankorstore-plus-white.svg"
          :alt="t('ankorstorePlus.program.name')"
        >
      </AkHeading4>
      <AkParagraph size="sm">
        {{ t('ankorstorePlusPill.content') }}
      </AkParagraph>
      <RouterLink
        v-if="$route"
        :to="{ name: 'ankorstore-plus-signup' }"
      >
        <AkButton
          class="ds-mt-3"
          color="white"
        >
          {{ t('ankorstorePlus.cta.nonOptIn') }}
        </AkButton>
      </RouterLink>
    </template>
  </AkTooltipInformation>
</template>

<script lang="ts">
export default { name: 'AksPlusTooltip' };
</script>

<script setup lang="ts">
import { computed } from 'vue';
import AnkorstorePlusBadge from '@monolith/legacy/modules/promote-engage/subscription-program/UI/ankorstore-plus-badge.vue';
import { AkTooltipInformation, AkButton, AkParagraph } from '@ankorstore/design-system';
import { OfferProgramEligibility } from '@monolith/legacy/modules/promote-engage/services/offer';
import { useStore } from '@core/composables/use-store';
import useI18n from '@core/composables/use-i18n';

defineProps<{
  title: string;
}>();

const { t } = useI18n();
const store = useStore();

const ankorstorePlus = computed((): OfferProgramEligibility | null => {
  return store.getters['offers/ankorstorePlus'];
});

const shouldShowTooltip = computed(() => {
  return ankorstorePlus.value && !ankorstorePlus.value.optIn;
});
</script>

<style scoped lang="scss">
.program_tooltip,
.perk__logo {
  @apply ds-h-3 ds-inline-block;
}
</style>
