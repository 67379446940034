import { ProductCollectionItem } from '@monolith/legacy/types/account/product-collections';
import { Amount } from '@core/types/amount';

export enum BudgetInterval {
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Total = 'TOTAL',
}

interface CampaignInfoBudget {
  amount: number;
  formatted: string;
  currency: string;
}

export interface CampaignInfoApiResponse {
  type: string;
  id: string;
  budget: CampaignInfoBudget;
  budgetInterval: BudgetInterval;
  budgetIntervalAmount: CampaignInfoBudget;
  createdAt: string;
  endDate: string;
  externalBrandId: string;
  name: string;
  startDate: string;
  status: AdsCampaignStatus;
  updatedAt: string;
  meta?: { adsCount: number };
}

export interface CampaignInfo extends Omit<CampaignInfoApiResponse, 'startDate' | 'endDate'> {
  endDate: Date;
  startDate: Date;
}

export type CampaignListMeta = {
  page: {
    total: number;
  };
};

export enum AdsCampaignStatus {
  'ACTIVE' = 'ACTIVE',
  'DISABLED' = 'DISABLED',
  'PENDING' = 'PENDING',
  'ENDED' = 'ENDED',
}

export enum AdStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  PENDING = 'PENDING',
  ENDED = 'ENDED',
}

export enum AdsCampaignStatusAction {
  Enable = 'enable',
  Disable = 'disable',
  Stop = 'stop',
}

export interface CampaignInfoExtended extends CampaignInfo {
  weeklyBudget: number;
}

export interface CampaignSetupPayload {
  data: {
    type: 'ad-campaign-setups';
    attributes: CampaignSetupAttributes;
  };
}

export interface CampaignSetupAttributes {
  brandId: string;
  name: string;
  startDate: string;
  endDate: string;
  budgetInterval: BudgetInterval;
  budgetIntervalAmount: number;
  ads: {
    entityId: string;
    name: string;
  }[];
  legalAgreement: boolean;
}

export interface CampaignSetupResponse {
  brandId: string;
  adCampaignId: string;
  userId: string;
  impersonationId: string;
  legalAgreedAt: string;
  createdAt: string;
}

export interface AdsTableItem extends ProductCollectionItem {
  /** @property {[Amount,Amount]} priceRange The minimum and maximum price of product variants, first item is min price, last item is max price */
  priceRange: [Amount, Amount] | [Amount];
  totalStock: number;
}

export interface CampaignWizard extends CampaignInfo {
  adsProducts: AdsTableItem[];
  weeklyBudget: number;
}

export type AdInfo = {
  type: string;
  id: string;
  adCampaign: {
    data: {
      createdAt: string;
      endDate: string;
      name: string;
      externalBrandId: string;
      id: string;
      type: string;
      startDate: string;
      budget: { amount: number; formatted: string; currency: string };
      status: string;
      updatedAt: string;
    };
  };
  upstreamDocument: {
    data: {
      document: ProductDocument;
      id: string;
      type: string;
    };
  };
  adCampaignId: string;
  budget: { amount: number; formatted: string; currency: string };
  clickThroughRate: number;
  costPer: { amount: number; formatted: string; currency: string };
  costType: string;
  createdAt: string;
  currentCostPerTarget: number;
  endDate: string;
  externalEntityId: string;
  indexStatus: string;
  name: string;
  pacingFactor: number;
  startDate: string;
  status: AdStatus;
  subType: string;
  totalAddToCart: number;
  totalClicks: number;
  totalClicksGuest: number;
  totalClicksRetailer: number;
  totalCost: number;
  totalCostCount: number;
  totalImpressions: number;
  totalImpressionsGuest: number;
  totalImpressionsRetailer: number;
  updatedAt: string;
};

export type ProductDocument = {
  _tags: string[];
  brand: unknown;
  catalog_categories: { v3: number[] };
  catalog_hierarchical_categories: { v3: { lvl0: string[]; lvl1: string[] } };
  created_at: number;
  description: unknown;
  discount_event_price: null;
  discount_rate: number;
  display_name: string;
  ds_enrichment: unknown;
  hierarchical_categories: unknown;
  id: number;
  images: string[];
  is_discounted: boolean;
  is_new: boolean;
  is_searchable: boolean;
  link: string;
  made_in: unknown;
  margin: string[];
  name: {
    de: string;
    fi: string;
    sv: string;
    pt: string;
    en: string;
    it: string;
    fr: string;
    da: string;
    es: string;
    nl: string;
  };
  options: {
    out_of_stock: boolean;
    ian: string;
    id: number;
    sku: string;
    stock: {
      available_quantity: null;
      is_always_in_stock: boolean;
      id: number;
      status: string;
    };
    variation: null;
  }[];
  original_wholesale_price: { amount: number; currency: string };
  out_of_stock: boolean;
  position: number;
  product_type: { id: number };
  retail_price: { amount: number; currency: string };
  retail_prices: { EUR: number; GBP: number; SEK: number };
  score: unknown[];
  searchable_name: unknown;
  tags: unknown;
  tags_aggregatable: unknown;
  unit_multiplier: number;
  updated_at: number;
  uuid: string;
  variants: {
    out_of_stock: boolean;
    ian: string;
    options: unknown[];
    wholesale_price: { amount: number; currency: string };
    id: number;
    retail_prices: { EUR: number; GBP: number; SEK: number };
    sku: string;
    stock: {
      available_quantity: null;
      is_always_in_stock: boolean;
      id: number;
      status: string;
    };
    retail_price: { amount: number; currency: string };
  }[];
  wholesale_price: { amount: number; currency: string };
};
