<script setup lang="ts">
import { computed } from 'vue';
import { AisRangeInput } from 'vue-instantsearch/vue3/es';
import { Filter, NumericRangeFilter } from '@bc/discovery/ui/filters';
import useCurrency from '@core/composables/use-currency';
import { getCurrencySymbol } from '@core/utilities/price';
import { createDebouncedRefine } from '../utils/debounced-refine';

defineOptions({ name: 'CustomAisPriceRangeFilter' });

defineProps<{ filter: Filter }>();

const { $currency } = useCurrency();
const currencySymbol = computed(() => getCurrencySymbol({ amount: 0, currency: $currency.value, precision: 2 }));

const debouncedRefine = createDebouncedRefine();
</script>

<template>
  <AisRangeInput :attribute="filter.name">
    <template #default="{ currentRefinement, range, canRefine, refine }">
      <NumericRangeFilter
        :unit="currencySymbol"
        :multiplier="100"
        :disabled="!canRefine"
        :range="range"
        :model-value="currentRefinement"
        @update:model-value="debouncedRefine(refine, $event)"
      />
    </template>
  </AisRangeInput>
</template>
