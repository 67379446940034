<!-- eslint-disable vue/no-useless-v-bind -->
<template>
  <div class="referred-confirmation__container">
    <div class="title">
      <AkParagraph class="title__content">
        {{ $t('Congratulations, you’re all set!') }}
      </AkParagraph>
    </div>
    <div class="content">
      <AkParagraph class="referred-confirmation__paragraph">
        <i18n-t :keypath="'Start shopping {brandName} now with your {amount} offer.'">
          <template #brandName>
            {{ liftBrandName }}
          </template>
          <template #amount>
            {{ $root.formatPrice(liftCodeAmount) }}
          </template>
        </i18n-t>
      </AkParagraph>
      <div class="referred-confirmation__content">
        <AkButton
          size="xl"
          data-testid="start-shopping"
          class="button--extended"
          @click="handleConfirm"
        >
          <span class="referred-confirmation__cta">
            {{ $t('Start Shopping') }}
          </span>
        </AkButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Analytics from '@monolith/legacy/services/analytics';
import { SignUpEventNames } from '@monolith/legacy/services/analytics/events/sign-up/common/event-names';
import SignUpEvent from '@monolith/legacy/services/analytics/events/sign-up/sign-up-event';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'ReferredConfirmation',
  emits: ['success'],
  computed: {
    ...mapGetters('signUp', ['liftBrandName', 'liftCodeAmount']),
  },
  mounted() {
    Analytics.track(
      new SignUpEvent(SignUpEventNames.CongratsB2RViewedEvent, {
        type: 'Element Viewed',
      })
    );
  },
  methods: {
    handleConfirm() {
      Analytics.track(
        new SignUpEvent(SignUpEventNames.CongratsB2RCTAEvent, {
          type: 'Click',
        })
      );
      this.$emit('success');
    },
  },
});
</script>

<style scoped lang="scss">
.referred-confirmation {
  &__container {
    @apply ds-flex ds-flex-col ds-justify-center ds-h-full ds-mt-10;
    padding: 26px 18px;
  }
  &__paragraph {
    @apply ds-w-full ds-mb-6 ds-text-center;
  }
  &__content {
    @apply ds-w-full ds-text-center;
  }
  &__cta {
    @apply ds-text-sm md:ds-text-base;
  }
}

.title {
  @apply ds-text-center ds-my-3;
  &__content {
    @apply ds-mb-2 ds-text-3xl ds-font-bold;
  }
}
</style>
