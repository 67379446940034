<script setup lang="ts">
import { ref } from 'vue';
import { AkDrawer } from '@ankorstore/design-system';
import { AkDrawerProps } from './types';

const isOpen = defineModel({
  type: Boolean,
  default: false,
});
const setIsOpen = (value: boolean) => {
  if (value === isOpen.value) {
    return;
  }
  isOpen.value = value;
};

withDefaults(defineProps<AkDrawerProps>(), {
  withCloser: true,
  closeOnOutsideClick: true,
});

const openDrawer = () => {
  setIsOpen(true);
};
const closeDrawer = () => {
  setIsOpen(false);
};

defineExpose({ open: openDrawer, close: closeDrawer });

const drawer = ref<InstanceType<typeof AkDrawer> | null>(null);
</script>

<template>
  <Teleport to="body">
    <div
      class="drawer-wrapper"
      :class="{ 'drawer-wrapper--open': isOpen }"
    >
      <AkDrawer
        v-bind="$props"
        ref="drawer"
        :is-open="isOpen"
        :overlay="false"
        :disable-scroll="false"
        @update:open="openDrawer()"
        @update:close="closeDrawer()"
      >
        <slot :close-drawer="() => closeDrawer()" />
      </AkDrawer>
    </div>
  </Teleport>
</template>

<style scoped lang="scss">
.drawer-wrapper {
  @apply ds-bg-primary/0;

  z-index: 1000;
  position: fixed;
  transition: background-color 300ms;

  &--open {
    @apply ds-bg-primary/80 ds-inset-0;
  }
}
</style>
