<template>
  <div class="ds-relative">
    <label
      v-if="isRevamp"
      class="placeholder"
      for="password-input"
    >
      <div class="ds-flex ds-items-center ds-mb-2">
        <span class="ds-mr-1 ds-text-neutral-700">
          {{ $t('Create your password') }}
        </span>
        <AkTooltipInformation
          trigger="hover"
          placement="top"
          type="success"
          symbol="info-circle"
        >
          <AkIcon symbol="info-circle" />
          <template #container>
            <span>
              {{ $t('retailer.registration.passwordTooltip.title') }}
            </span>
            <ul class="ds-list-disc ds-pl-3 ds-pt-2">
              <li>{{ $t('retailer.registration.passwordTooltip.minimumLength') }}</li>
              <li>{{ $t('retailer.registration.passwordTooltip.upperCase') }}</li>
              <li>{{ $t('retailer.registration.passwordTooltip.lowerCase') }}</li>
              <li>{{ $t('retailer.registration.passwordTooltip.numbers') }}</li>
              <li>{{ $t('retailer.registration.passwordTooltip.symbols') }}</li>
            </ul>
          </template>
        </AkTooltipInformation>
      </div>
    </label>
    <AkInput
      v-if="isRevamp"
      ref="registerPasswordInput"
      v-model="value"
      :type="display ? 'text' : 'password'"
      :class="vuelidateFieldClassName(state.password)"
      :has-error="hasError"
      data-testid="registerPasswordInput"
      name="password"
      :placeholder="$t('Password')"
      autocomplete="new-password"
      @input="onAkInput"
      @change="onChange"
      @focus="focused = true"
      @focusout="focused = false"
    />
    <div
      v-else
      class="required-field form-label-group"
    >
      <input
        id="password-input"
        v-model="value"
        :type="display ? 'text' : 'password'"
        class="form-control hide-placeholder-on-focus"
        data-testid="registerPasswordInput"
        name="password"
        :placeholder="$t('Password')"
        autocomplete="new-password"
        @input="onInput"
        @change="onChange"
        @focus="focused = true"
        @focusout="focused = false"
      />
      <label
        class="placeholder"
        for="password-input"
      >
        <div class="ds-flex ds-items-center">
          <span class="ds-mr-1">
            {{ $t('Create your password') }}
          </span>
          <AkTooltipInformation
            trigger="hover"
            placement="top"
            type="success"
            symbol="info-circel"
          >
            <AkIcon symbol="info-circle" />
            <template #container>
              <span>
                {{ $t('retailer.registration.passwordTooltip.title') }}
              </span>
              <ul class="ds-list-disc ds-pl-3 ds-pt-2">
                <li>{{ $t('retailer.registration.passwordTooltip.minimumLength') }}</li>
                <li>{{ $t('retailer.registration.passwordTooltip.upperCase') }}</li>
                <li>{{ $t('retailer.registration.passwordTooltip.lowerCase') }}</li>
                <li>{{ $t('retailer.registration.passwordTooltip.numbers') }}</li>
                <li>{{ $t('retailer.registration.passwordTooltip.symbols') }}</li>
              </ul>
            </template>
          </AkTooltipInformation>
        </div>
      </label>
    </div>
    <template v-if="value">
      <AkButton
        :class="['ds-absolute ds-right-0 ds-top-0', { 'ds-top-[28px] ds-right-2': isRevamp }]"
        symbol="eye"
        size="lg"
        link
        @click="display = !display"
      />
      <div class="ds-mt-1">
        <ProgressBarStatic
          :progress="progress.number"
          :color-style-fn="(progress) => (progress >= 100 ? 'ds-bg-accent-700' : 'ds-bg-neutral-700')"
        />
      </div>
      <div
        v-if="value"
        class="ds-flex ds-items-center ds-mt-1"
      >
        <span
          v-if="progress.label"
          class="ds-text-xs"
          data-testid="passwordStrength"
        >
          {{ progress.label }}
        </span>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
// @ts-nocheck
import { passwordStrength } from 'check-password-strength';
import { vuelidateFieldClassName } from '@core/utilities/fieldClassName';
import ProgressBarStatic from '@monolith/legacy/modules/carts/progress-bar-static/progress-bar-static.vue';
import { AkTooltipInformation } from '@ankorstore/design-system';
import { FeatureFlag } from '@monolith/legacy/services/features';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AkPassword',
  components: { ProgressBarStatic, AkTooltipInformation },
  props: {
    state: {
      type: Object,
      required: true,
    },
    isRevamp: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['input', 'change'],
  data() {
    return {
      value: '',
      display: false,
      focused: false,
      FeatureFlag,
    };
  },
  computed: {
    hasError() {
      return (
        (this.state.password && this.state.password.$dirty && this.state.password.passwordMinlength === false) ||
        (this.state.password && this.state.password.$dirty && this.state.password.required === false)
      );
    },
    progress() {
      const labels = {
        'Too weak': { label: `${this.$t('Too weak')}`, number: 20 },
        Weak: { label: `${this.$t('Weak')}`, number: 40 },
        Medium: { label: `${this.$t('Medium')}`, number: 70 },
        Strong: { label: `${this.$t('Strong')}`, number: 100 },
      };
      return labels[passwordStrength(this.value).value];
    },
  },
  methods: {
    vuelidateFieldClassName,
    onInput(event) {
      this.$emit('input', event.target.value);
    },
    onChange(event) {
      this.$emit('change', event.target.value);
    },
    onAkInput(value) {
      this.$emit('input', value);
    },
  },
});
</script>
