<template>
  <transition
    name="fade"
    duration="3000"
    @after-enter="handleAfterEnter"
  >
    <div
      v-if="isShow"
      class="notification"
      :class="getHeightContent() ? 'notification-two-raws' : 'notification-on-raw'"
    >
      <div class="notification-icon-wrapper ds-py">
        <AkIcon
          class="notification-icon"
          symbol="check"
          size="md"
        />
      </div>
      <div ref="notificationContent">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, nextTick, ref } from 'vue';

export default defineComponent({
  name: 'ReorderNotification',
  setup() {
    const isShow = ref(false);
    const notificationContent = ref();

    const openNotification = () => {
      isShow.value = !isShow.value;
      nextTick(() => {
        getHeightContent();
      });
    };

    const closeNotification = () => {
      isShow.value = false;
    };

    const handleAfterEnter = () => {
      closeNotification();
    };

    const getHeightContent = () => {
      return notificationContent.value?.offsetHeight > 20;
    };

    return {
      isShow,
      openNotification,
      handleAfterEnter,
      notificationContent,
      getHeightContent,
    };
  },
});
</script>

<style lang="scss" scoped>
.notification {
  @apply ds-rounded-md ds-font-basic ds-flex ds-justify-center ds-items-center ds-py-4 ds-px-2.5 ds-bg-success-700 ds-absolute ds-top-0 ds-z-50 ds-w-full ds-text-white;
}

.notification-two-raws {
  @apply ds-items-start;
}

.notification-on-raw {
  @apply ds-items-center;
}
.notification-icon-wrapper {
  @apply ds-mr-1;
}

.notification-icon {
  background-color: rgba(255, 255, 255, 0.16);
  @apply ds-text-white ds-rounded-full ds-p-0.5;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter,
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
