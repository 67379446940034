<script setup lang="ts">
import { computed } from 'vue';

import ProductSnippet from '@monolith/legacy/components/product/product-snippet-async.vue';

import { Config, Context, ESProductHit, ESSponsoredProductHit } from '@bc/discovery/domain/catalog';
import { SimilarProductsButton } from '@bc/discovery/ui/recommendation';

import useCatalogTrackingData from '../../composables/use-catalog-tracking-data';
import { useSimilarProductsDrawer } from '../../composables/use-similar-products-drawer';

const { isSponsored, item, page, index, config } = defineProps<{
  config: Config;
  item: ESProductHit | ESSponsoredProductHit;
  page: number;
  index: number;
  isSponsored: boolean;
}>();

defineEmits<{
  clicked: [event: MouseEvent];
}>();

const { trackingSectionId } = useCatalogTrackingData();

const isSponsoredProductTile = computed(() => isSponsored || 'aardvarkServeId' in item || ('ad' in item && Boolean(item.ad)));

const { openSimilarProductsDrawer } = useSimilarProductsDrawer({
  product: item,
  pageNumber: page,
  position: index,
});

const hasBrandLink = computed(() => config.context !== Context.BRAND);
const additionalLikeTrackingData = computed(() => {
  const additionalData = {
    id_section: trackingSectionId.value,
    component: isSponsoredProductTile.value ? 'native_ads' : undefined,
  };
  if (isSponsoredProductTile.value) {
    additionalData['page'] = page + 1;
    additionalData['position'] = index;
    additionalData['itemType'] = 'product';
    additionalData['productId'] = item.id;
    additionalData['component'] = 'native_ads';
  }
  return additionalData;
});
</script>

<template>
  <ProductSnippet
    ref="product-snipet"
    class="product-snippet-fullwidth"
    :item="item"
    :index="index"
    :page-number="page + 1"
    :with-brand-link="hasBrandLink"
    :product-brand="item.brand"
    :additional-like-tracking-data="additionalLikeTrackingData"
    :is-sponsored="isSponsoredProductTile"
    :with-similar-products-drawer="config.withSimilarProductsDrawer"
    @clicked="(event) => $emit('clicked', event)"
  >
    <template
      v-if="$isEnabled('RET-3124')"
      #topRightActions="{ isHovered }"
    >
      <SimilarProductsButton
        :is-focused="isHovered"
        @click="openSimilarProductsDrawer"
      />
    </template>
  </ProductSnippet>
</template>

<style lang="scss" scoped>
@import '@css/vue-import';
.product-snippet-fullwidth {
  @apply ds-mb-0 ds-w-full;
}
</style>
