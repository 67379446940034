<template>
  <div class="product-type-attributes">
    <div
      v-if="isLoading"
      class="ds-w-full"
    >
      <div class="ds-w-1/4 ds-mb-2.5">
        <AkSkeleton />
      </div>
      <div class="ds-w-full ds-h-6.5 ds-border ds-border-neutral-600 ds-border-solid ds-rounded-md ds-overflow-hidden">
        <AkSkeleton :rounded="false" />
      </div>
    </div>
    <template v-else>
      <div class="product-type-attributes__wrapper">
        <div
          v-for="attribute in productTypeAttributes"
          :key="attribute.id"
          class="product-type-attributes__item"
        >
          <component
            :is="getAttributeComponent(attribute.type)"
            :model-value="model[attribute.id]"
            :attribute="attribute"
            :errors="(errors && errors[attribute.id]) || (v$.model[attribute.id].$error ? v$.model[attribute.id].$errors[0].$message : [])"
            @update:model-value="onAttributeChange(attribute.id, $event)"
          />
        </div>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { captureException } from '@core/plugins/sentry';
import { getProductTypeAttributesList } from '../../services/api';
import ProductTypeMultiselect from './product-type-multiselect.vue';
import ProductTypeSelect from './product-type-select.vue';
import {
  ProductTypeAttributesData,
  ProductTypeAttributesItem,
  ProductTypeAttributesRequirement,
  ProductTypeAttributesTypes,
} from '@bc/brands/feature/product-management/types/product-type-attributes';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import i18n from '@monolith/legacy/services/i18n';
import { useI18n } from 'vue-i18n';
import { AkSkeleton, AkSelect, AkMultiSelect } from '@ankorstore/design-system';
import { ProductAttribute } from '@monolith/legacy/types/product';

type ComponentDataType = {
  model: object;
  productTypeAttributes: ProductTypeAttributesItem[];
  isLoading: boolean;
};

export default defineComponent({
  name: 'ProductTypeAttributes',
  components: {
    ProductTypeMultiselect,
    ProductTypeSelect,
    AkSkeleton,
    AkSelect,
    AkMultiSelect,
  },
  props: {
    modelValue: {
      type: Array as PropType<ProductAttribute[]>,
      default: () => null,
    },
    productTypeId: {
      type: Number,
      required: true,
    },
    errors: {
      type: Object as PropType<Record<string, string[]>>,
      default: () => {},
    },
  },
  emits: ['update:modelValue'],
  setup() {
    const i18n = useI18n();
    return { v$: useVuelidate(), i18n };
  },
  validations() {
    return {
      model: this.attributesValidations,
    };
  },
  data(): ComponentDataType {
    return {
      productTypeAttributes: [],
      model: {},
      isLoading: false,
    };
  },
  computed: {
    attributesValidations() {
      const result = {};
      this.productTypeAttributes?.length &&
        this.productTypeAttributes.forEach((item: ProductTypeAttributesItem) => {
          const requiredValidator = helpers.withMessage(i18n.global.t('This field is required'), required);
          result[item.id] = { required: item?.required ? requiredValidator : false };
        });
      return result;
    },
    attributesValues() {
      const result = {};
      this.productTypeAttributes?.length &&
        this.productTypeAttributes.forEach((item: ProductTypeAttributesItem) => {
          const presetValue = this.modelValue?.find((attr) => attr.name === item.id)?.value;
          result[item.id] = presetValue ?? (item.type === ProductTypeAttributesTypes.MULTISELECT ? [] : '');
        });
      return result;
    },
  },
  watch: {
    productTypeAttributes(value) {
      if (value && value.length) {
        this.model = this.attributesValues;
        this.updateAttribute();
      }
    },
    productTypeId() {
      this.getAttributes();
    },
  },
  async mounted() {
    this.getAttributes();
  },
  methods: {
    getAttributeComponent(type: ProductTypeAttributesTypes): string {
      switch (type) {
        case ProductTypeAttributesTypes.MULTISELECT:
          return 'ProductTypeMultiselect';
        case ProductTypeAttributesTypes.SELECT:
          return 'ProductTypeSelect';
        default:
          return null;
      }
    },
    async getAttributes() {
      this.isLoading = true;
      try {
        const data: ProductTypeAttributesData[] = await getProductTypeAttributesList(this.productTypeId);
        this.productTypeAttributes = data.map((item: ProductTypeAttributesData) => {
          return {
            id: item.id,
            type: item.attributes.attributeType,
            label: item.attributes.label,
            inputHint: item.attributes.inputHint || '',
            inputTip: item.attributes.inputTip || '',
            required: item.attributes.requirement === ProductTypeAttributesRequirement.MANDATORY,
            options: item.attributes.options,
          } as ProductTypeAttributesItem;
        });
      } catch (e) {
        captureException(e);
      } finally {
        this.isLoading = false;
      }
    },
    setAttributes(value: object) {
      return Object.entries(value)
        .filter(([name, value]) => this.attributesValidations[name]?.required || value)
        .map(([name, value]) => ({ name, value }));
    },
    onAttributeChange(attributeId: string, value: string | number) {
      this.model[attributeId] = value;
      this.updateAttribute();
    },
    updateAttribute() {
      this.$emit('update:modelValue', this.setAttributes(this.model));
    },
  },
});
</script>
<style lang="scss" scoped>
.product-type-attributes__wrapper {
  @apply ds-flex ds-gap-3;
}

.product-type-attributes__item {
  @apply ds-flex-1;
}
</style>
