<template>
  <div>
    <AkModal
      ref="csvUploadModal"
      size="full"
      full-height
      full-height-sticky-footer
      back-navigation
      :close-on-validation="false"
      class="csv-upload-modal"
      :validate-button-text="currentStep.nextBtnText"
      :validate-button-disabled="nextButtonDisabled"
      :validate-button-loading="nextButtonLoading"
      :secondary-button-text="currentStep.prevBtnText"
      @back="handleBack"
      @cancelled="closeCsvUploadModal"
      @validated="handleNext"
      @secondary-button-clicked="handleBack(true)"
    >
      <!-- "has-autocomplete" is fix for dynamic AkSelect in AkModal-->
      <div class="has-autocomplete">
        <div class="ds-px-9 ds-pt-8">
          <AkSteps
            v-if="currentStep.name !== CsvUploadStepName.Upload"
            :current-step="currentStepIndex + 1"
            :steps="steps"
          />
          <div class="ds-mt-8">
            <CsvUploadImport
              v-show="currentStep.name === CsvUploadStepName.Upload"
              ref="CsvUploadImport"
              data-testid="csvUploadImport"
              class="ds-mx-auto ds-max-w-4xl"
              @file-uploaded="fileUploaded"
            />
            <CsvUploadPreview
              v-if="currentStep.name === CsvUploadStepName.Preview"
              :file-headers="rows[0]"
              :file-name="fileName"
              :products-count="productsCount"
              :product-attributes-count="productAttributesCount"
              class="ds-mx-auto ds-max-w-6xl"
            />
            <CsvUploadMatch
              v-if="currentStep.name === CsvUploadStepName.Match"
              :file-headers="rows[0]"
              :first-row="rows[1]"
              :mapping="mapping"
              class="ds-mx-auto ds-max-w-6xl"
              @mapping-changed="onMappingChanged"
            />
          </div>
        </div>
      </div>
      <template #footer>
        <div
          v-if="currentStepIndex === steps.length"
          class="ds-flex ds-items-center"
        >
          <AkTooltipInformation
            data-testid="importTooltip"
            type="invert"
            trigger="hover"
            placement="top"
            :content="$t('brand.account.products.csvUploadModal.matchHeaders.tooltip')"
          >
            <AkIcon
              symbol="info-circle"
              size="md"
              class="ds-mr-2"
            />
          </AkTooltipInformation>
          <AkParagraph class="ds-mr-2">
            {{ $t('brand.account.products.csvUploadModal.matchHeaders.footer') }}
          </AkParagraph>
          <a
            class="ds-underline ds-inline ds-text-info-700"
            target="_blank"
            :href="$t('brand.account.products.csv-upload-modal.upload-import.learn-more-link')"
          >
            {{ $t('global.learnMore') }}
          </a>
        </div>
      </template>
    </AkModal>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import CsvUploadImport from '@bc/brands/feature/catalog-integration/components/csv/csv-upload-import.vue';
import CsvUploadPreview from '@bc/brands/feature/catalog-integration/components/csv/csv-upload-preview.vue';
import CsvUploadMatch from '@bc/brands/feature/catalog-integration/components/csv/csv-upload-match.vue';
import { AkSteps, AkStepsStep, AkTooltipInformation } from '@ankorstore/design-system';
import { createSpreadsheetMapping, /*getAllSpreadsheetMappings*/ } from '@monolith/legacy/services/api/brand-catalog-integration';
import {
  MappingItem,
  CsvBatchPayload,
  XlsxBatchPayload,
  SpreadsheetMappingResponse,
  ImportFileType,
} from '@monolith/legacy/types/account/account-products/catalog-integration/product-integration';
import { captureException } from '@core/plugins/sentry';
import Analytics from '@monolith/legacy/services/analytics';
import UserClick from '@monolith/legacy/services/analytics/events/user-click.event';

enum CsvUploadStepName {
  Upload = 'Upload',
  Preview = 'Preview',
  Match = 'Match',
}

interface UploadStep extends AkStepsStep {
  name: CsvUploadStepName;
  title: string;
  completed: boolean;
  nextBtnText?: string;
  prevBtnText?: string;
}

interface ComponentData {
  currentStepIndex: number;
  CsvUploadStepName: typeof CsvUploadStepName;
  rows: string[][];
  fileName: string;
  fileType: ImportFileType;
  fileKey: string;
  sheetIndex: number;
  productsCount: number;
  overwriteDrafts: boolean;
  mapping: MappingType;
  lastSavedMappingResponse: SpreadsheetMappingResponse;
  nextButtonLoading: boolean;
}

type fileUploadedPayload = Pick<ComponentData, 'rows' | 'fileName' | 'sheetIndex' | 'fileKey' | 'fileType' | 'overwriteDrafts' | 'productsCount'>;

type MappingType = Record<number, string>;

export default defineComponent({
  name: 'CsvUploadModal',
  components: {
    CsvUploadImport,
    CsvUploadPreview,
    CsvUploadMatch,
    AkSteps,
    AkTooltipInformation,
  },
  emits: ['success'],
  data(): ComponentData {
    return {
      currentStepIndex: 0,
      CsvUploadStepName,
      rows: [],
      fileName: '',
      fileType: ImportFileType.CSV,
      fileKey: '',
      sheetIndex: 0,
      productsCount: 0,
      overwriteDrafts: false,
      mapping: null,
      lastSavedMappingResponse: null,
      nextButtonLoading: false,
    };
  },
  computed: {
    ...mapGetters(['brand']),
    ...mapState('account/productsIntegration', ['isCsvUploadModalOpen']),
    steps(): UploadStep[] {
      return [
        {
          name: CsvUploadStepName.Preview,
          title: this.$t('brand.account.products.csvUploadModal.previewFile.title'),
          completed: this.currentStepIndex > 1,
          nextBtnText: this.$t('brand.account.products.csvUploadModal.previewFile.validateButtonText'),
          prevBtnText: this.$t('brand.account.products.csvUploadModal.previewFile.backButtonText'),
        },
        {
          name: CsvUploadStepName.Match,
          title: this.$t('brand.account.products.csvUploadModal.matchHeaders.title'),
          completed: this.currentStepIndex > 2,
          nextBtnText: this.$t('brand.account.products.csvUploadModal.matchHeaders.validateButtonText'),
          prevBtnText: this.$t('Back'),
        },
      ];
    },
    currentStep(): UploadStep {
      if (this.currentStepIndex === 0) {
        return {
          name: CsvUploadStepName.Upload,
          title: this.$t('brand.account.products.csvUploadModal.uploadProducts.title'),
          completed: this.currentStepIndex > 1,
          nextBtnText: this.$t('brand.account.products.csvUploadModal.uploadProducts.validateButtonText'),
        }
      } else {
        return this.steps[this.currentStepIndex - 1] || {};
      }
    },
    productAttributesCount(): number {
      return this.rows[0]?.length;
    },
    nextButtonDisabled(): boolean {
      switch (this.currentStepIndex) {
        case 0:
          return !this.fileName || !this.fileKey;
        case 2:
          return !this.mappingIsValid;
        default:
          return false;
      }
    },
    mappingIsValid(): boolean {
      return !!this.mapping && Object.values(this.mapping)?.indexOf('sku') !== -1;
    },
    mappingChanged(): boolean {
      const savedMapping = this.convertMappingResponseToMapping(this.lastSavedMappingResponse);
      return JSON.stringify(savedMapping) !== JSON.stringify(this.mapping);
    },
  },
  watch: {
    isCsvUploadModalOpen(val) {
      if (val) {
        this.currentStepIndex = 0;
        (this.$refs.csvUploadModal as DS['AkModal']).openModal();
      } else {
        (this.$refs.csvUploadModal as DS['AkModal']).close();
      }
    },
  },
  async created() {
  // Disabling the feature temporarily
  /*
    const savedMappings = await getAllSpreadsheetMappings();
    this.lastSavedMappingResponse = savedMappings?.pop();
    this.mapping = this.convertMappingResponseToMapping(this.lastSavedMappingResponse);
  */
  },
  methods: {
    ...mapActions('account/productsIntegration', ['importFromCsv', 'importFromXlsx', 'closeCsvUploadModal']),
    onMappingChanged(mapping: MappingType): void {
      this.mapping = { ...mapping };
    },
    convertMappingResponseToMapping(mappingResponse: SpreadsheetMappingResponse): MappingType {
      const mappingObject = mappingResponse?.attributes?.configuration;
      if (!mappingObject) {
        return null;
      }
      return Object.keys(mappingObject).reduce((acc, key) => {
        const value = mappingObject[key];
        acc[value.field] = value.identifier;
        return acc;
      }, {});
    },
    handleBack(isSecondaryButtonClicked?: boolean): void {
      switch (this.currentStep.name) {
        case CsvUploadStepName.Upload:
          this.closeCsvUploadModal();
          break;
        case CsvUploadStepName.Preview:
          if (isSecondaryButtonClicked) {
            this.trackAnalytics('choose_different_file');
          }
          this.currentStepIndex = this.currentStepIndex - 1;
          break;
        case CsvUploadStepName.Match:
          this.currentStepIndex = this.currentStepIndex - 1;
          break;
      }
    },
    async handleNext(): Promise<void> {
      if (this.currentStep.name === CsvUploadStepName.Preview) {
        this.trackAnalytics('match_data');
        this.currentStepIndex = this.currentStepIndex + 1;
      }
      else if (this.currentStep.name === CsvUploadStepName.Match) {
        this.trackAnalytics('final_import');
        await this.submit();
        this.closeCsvUploadModal();
      } else {
        this.currentStepIndex = this.currentStepIndex + 1;
      }
    },
    async trackAnalytics(action: string): Promise<void> {
      Analytics.track(
        new UserClick({
          component: 'brand_bo_catalogue_management',
          action: action,
          brand_id: this.brand.id,
          brand_name: this.brand.name,
        })
      );
    },
    fileUploaded({ rows, fileName, fileKey, fileType, sheetIndex, overwriteDrafts, productsCount }: fileUploadedPayload): void {
      this.rows = rows;
      this.fileName = fileName;
      this.fileType = fileType;
      this.fileKey = fileKey;
      this.sheetIndex = sheetIndex;
      this.overwriteDrafts = overwriteDrafts;
      this.productsCount = productsCount;
      if (this.mapping) {
        const keys = Object.keys(this.mapping);
        const length = rows[0]?.length;
        if (keys.length > length) {
          const keysToRemove = keys.slice(length);
          keysToRemove.forEach(key => {
            delete this.mapping[key];
          });
        }
      }
      this.trackAnalytics(`import_products_${fileType}`);
    },
    async createIntegrationBatch(mappingId: string, fileType: ImportFileType): Promise<void> {
      if (fileType === ImportFileType.CSV) {
        const batchPayload: CsvBatchPayload = {
          payload: {
            spreadsheet_upload_file_id: this.fileKey,
            mapping_id: mappingId,
          }
        };

        await this.importFromCsv(batchPayload);
      } else if (fileType === ImportFileType.XLSX) {
        const batchPayload: XlsxBatchPayload = {
          payload: {
            spreadsheet_upload_file_id: this.fileKey,
            mapping_id: mappingId,
            file_spreadsheet_sheet: this.sheetIndex,
          }
        };

        await this.importFromXlsx(batchPayload);
      }
    },
    async submit(): Promise<void> {
      try {
        this.nextButtonLoading = true;
        let mappingId: string;
        if (this.mappingChanged) {
          const payload = this.createMappingPayload(this.mapping);
          const newMappingResponse = await createSpreadsheetMapping({ name: 'Name', mapping: payload });
          mappingId = newMappingResponse.id;
        } else {
          mappingId = this.lastSavedMappingResponse?.id;
        }
        await this.createIntegrationBatch(mappingId, this.fileType);
        this.$emit('success');
      } catch (error) {
        captureException(error);
      } finally {
        this.nextButtonLoading = false;
      }
    },
    createMappingPayload(mapping: MappingType): MappingItem[] {
      if (!mapping) {
        return [];
      }
      return Object.keys(mapping)
        .filter((key) => mapping[key])
        .map((key) => ({
          identifier: mapping[key],
          field: key,
        }));
    },
  },
});
</script>

<style lang="scss" scoped>
.csv-upload-modal {
  :deep(.ak-modal__body) {
    min-height: calc(100vh - 4rem);
    overflow-y: clip;
    @apply ds-flex ds-flex-col ds-justify-between;
  }
}
</style>
