import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { Conversation, ConversationTypeMember } from '@monolith/legacy/types/conversation';
import { isBefore, parseISO } from 'date-fns';

export const conversationsMixin = defineComponent({
  name: 'ConversationsMixin',
  computed: {
    ...mapGetters('conversations', ['getConversationById', 'getConversationByRecipientEntityId']),
    ...mapGetters(['user']),
  },
  methods: {
    fetchConversationById(id: number): Conversation {
      const conversation = this.getConversationById(id);

      if (!conversation) {
        this.$store.dispatch('conversations/loadConversation', id);
      } else if (!conversation.messages) {
        this.$store.dispatch('conversations/loadConversationMessages', id);
      }

      return conversation;
    },
    fetchConversationByRecipientId(id: number, type: ConversationTypeMember): Conversation {
      if (!this.getConversationByRecipientEntityId(id)) {
        type === ConversationTypeMember.brand
          ? this.$store.dispatch('conversations/loadConversationWithBrand', id)
          : this.$store.dispatch('conversations/loadConversationWithRetailer', id);
      }
      return this.getConversationByRecipientEntityId(id);
    },
    isBrandDisplay(message): boolean {
      return message.author.entity.type === 'brand';
    },
    isMessageUnread(conversation, message): boolean {
      if (message.author.id === conversation.sender.id) {
        return false;
      }
      if (!conversation.sender.last_seen_at) {
        return true;
      }

      return isBefore(parseISO(message.created_at), parseISO(conversation.sender.last_seen_at));
    },
    getInitialName(firstName, lastName) {
      return firstName[0] + lastName[0];
    },
  },
});
