<template>
  <div
    v-if="content.length > 0"
    class="home-testimonials"
    data-testid="homeTestimonials"
  >
    <AkCarousel
      :per-page="1"
      :arrows="false"
    >
      <AkCarouselItem
        v-for="(testimonial, index) in content"
        :key="index"
      >
        <AkTestimonial
          :author-name="testimonial.author_name"
          :author-subtitle="testimonial.author_subtitle"
          :quote="testimonial.quote"
          :quote-bold="false"
          alignement="center"
        />
      </AkCarouselItem>
    </AkCarousel>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { PrismicTestimonials } from '@monolith/legacy/types/prismic/home-testimonials';
import { AkCarousel, AkCarouselItem, AkTestimonial } from '@ankorstore/design-system';

export default defineComponent({
  name: 'HomeTestimonials',
  components: { AkCarousel, AkTestimonial, AkCarouselItem },
  props: {
    content: {
      type: Array as () => PrismicTestimonials[],
      required: true,
    },
  },
});
</script>
<style lang="scss">
.home-testimonials {
  .splide__track {
    /** Marketing new color schema */
    /* stylelint-disable-next-line */
    background-color: #bacac7;
  }

  .ak-testimonial {
    @apply ds-p-4 md:ds-p-9;
    &__sub-content {
      @apply ds-text-primary ds-mx-auto;
      max-width: 960px;
    }
    &__author-name {
      @apply ds-text-accent-700;
    }
  }
}
</style>
