import { memoize } from 'lodash-es';
import http from './http';
import { BuisnessEventValue, BusinessEventPeriode, HasEventPage } from '@monolith/legacy/types/business-event';

export const toBoolean = (response: { data: HasEventPage }) => Boolean(response.data?.data.has_event_page);

export const hasEventPage = async (): Promise<boolean> => http().get('/api/me/business-event/haseventpage').then(toBoolean);

export const getEvents = async (): Promise<BusinessEventPeriode[]> =>
  await http()
    .get('/api/me/business-event/currents')
    .then((response) => response.data?.data);

export const getGlobalEvents = memoize(
  async (): Promise<BusinessEventPeriode[]> =>
    await http()
      .get('/api/business-event/currents')
      .then((response) => response.data?.data)
);

export const getDiscountBrandEvent = async (eventId: number): Promise<any> =>
  http()
    .get<BuisnessEventValue>(`/api/brand-configuration/business-event/${eventId}`)
    .then((response) => response.data?.data);

export const setDiscountBrandEvent = async (brandId: number, eventId: number, discount: number): Promise<number> =>
  http()
    .post<BuisnessEventValue>('/api/brand-configuration/business-event', {
      brand_id: brandId,
      business_event_id: eventId,
      discount,
    })
    .then((response) => response.data?.data.discount);

export const deleteDiscountBrandEvent = async (eventId: number): Promise<any> =>
  http()
    .delete(`/api/brand-configuration/business-event/${eventId}`)
    .then((response) => response.data?.data);
