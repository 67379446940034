<template>
  <div
    v-if="!isCatalogIntegrationEnabled"
    class="ak-account__header"
  >
    <Breadcrumb
      :links="[
        {
          href: 'products-list',
          title: $t('Products'),
          notRouterLink: false,
        },
      ]"
      :last="$t('All products')"
    />

    <AkButton
      v-if="showOrganiseButton"
      v-bind="getOrganiseButtonConfig()"
      @on-click="organize()"
    >
      {{ getOrganiseButtonText() }}
    </AkButton>
  </div>

  <div
    v-else
    class="header-integrations"
  >
    <div class="header-integrations__tabs">
      <AkTabBar
        ref="akTabBar"
        name="productsTabBar"
        :active="tabIndexActive"
        :items="tabsConfiguration"
        @tab-bar-click="triggerChangeTabEvent($event)"
      />

      <AkButton
        v-if="tabIndexActive === 1 && getAllNonReadyDraftsCount > 0"
        symbol="download"
        data-testid="DownloadDraftsCSV"
        link
        @click="downloadDrafts"
      >
        {{ $t('brand.account.products.downloadDrafts') }}
      </AkButton>
      <AkButton
        v-if="isOrganiseButtonEnabled"
        v-bind="getOrganiseButtonConfig()"
        @on-click="organize()"
      >
        {{ getOrganiseButtonText() }}
      </AkButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { AkTabBarItemInterface } from '@ankorstore/design-system';
import Breadcrumb from '@monolith/legacy/components/breadcrumb.vue';
import { mapActions, mapGetters } from 'vuex';
import Analytics from '@monolith/legacy/services/analytics';
import UserClick from '@monolith/legacy/services/analytics/events/user-click.event';

export enum TabAvailable {
  Products = 'products-list',
  Drafts = 'product-drafts-list',
}

export default defineComponent({
  name: 'ProductsHeader',
  components: { Breadcrumb },
  props: {
    isCatalogIntegrationEnabled: {
      type: Boolean,
      required: true,
      default: false,
    },
    showOrganiseButton: {
      type: Boolean,
      required: true,
      default: true,
    },
    nextActiveTab: {
      default: null,
      type: String as () => TabAvailable,
      required: false,
    },
  },
  emits: ['onTabChanged', 'onOrganise'],
  data: function () {
    return {
      activeTab: TabAvailable.Products,
    };
  },
  computed: {
    ...mapGetters(['brand']),
    ...mapGetters('account/productsIntegration', {
      draftTotalItems: 'getTotalItems',
      getAllNonReadyDraftsCount: 'getAllNonReadyDraftsCount',
    }),
    ...mapGetters('account/products', { productTotalItems: 'getTotalItems' }),
    tabIndexActive(): number {
      return Object.keys(TabAvailable).findIndex((key) => TabAvailable[key] === this.activeTab);
    },
    isOrganiseButtonEnabled(): boolean {
      return this.showOrganiseButton && TabAvailable.Products === this.activeTab;
    },
    isProductDraft(): boolean {
      return TabAvailable.Drafts === this.$route.name;
    },
    tabsConfiguration(): Omit<AkTabBarItemInterface, 'pillContent'>[] {
      const tabsConfiguration = [];
      tabsConfiguration.push({
        label: this.$t('Products'),
        contentId: TabAvailable.Products,
        pillContent: this.productTotalItems + '',
      });

      tabsConfiguration.push({
        label: this.$t('Drafts'),
        contentId: TabAvailable.Drafts,
        pillContent: this.draftTotalItems + '',
      });

      return tabsConfiguration;
    },
  },
  watch: {
    $route: {
      handler() {
        this.activeTab = this.isProductDraft ? TabAvailable.Drafts : TabAvailable.Products;
        this.$emit('onTabChanged', this.activeTab);
      },
      immediate: true,
    },
    nextActiveTab(newValue: TabAvailable) {
      this.activeTab = newValue;
    },
  },
  async created() {
    await this.fetchIntegrationStatistic();
    await this.fetchProducts({
      showAll: false,
      keepProductCache: true,
    });
  },
  methods: {
    ...mapActions('account/productsIntegration', ['fetchIntegrationStatistic']),
    ...mapActions('account/products', ['fetchProducts']),
    organize() {
      this.$emit('onOrganise', true);
    },
    getOrganiseButtonConfig(): object {
      return {
        symbol: 'list',
        outlined: true,
      };
    },
    getOrganiseButtonText(): string {
      return this.$t('Organize');
    },
    triggerChangeTabEvent(akTabEvent: HTMLElement): void {
      const currentTab = this.activeTab;
      this.activeTab = akTabEvent.getAttribute('content-id') as TabAvailable;
      if (TabAvailable.Products === currentTab) {
        this.$store.dispatch('account/products/resetSelectedProducts');
      } else {
        this.$store.dispatch('account/productsIntegration/resetSelectedProducts');
      }
      this.$emit('onTabChanged', this.activeTab);
      this.$router.push({ name: this.activeTab });
    },
    async trackDownloadDraftsCSV(): Promise<void> {
      await Analytics.track(
        new UserClick({
          component: 'brand_bo_catalogue_management_import_status',
          action: 'download_drafts_csv',
          brand_id: this.brand?.id,
          brand_name: this.brand?.name,
        })
      );
    },
    downloadDrafts() {
      this.trackDownloadDraftsCSV();
      window.location.href = '/api/internal/v1/drafts/export';
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';

.header-integrations {
  @apply ds-overflow-hidden;

  &__tabs {
    @apply ds-relative ds-z-10 ds-overflow-hidden;
    @apply ds-flex ds-items-center ds-justify-between ds-pt-4 ds-mx-4 lg:ds-mx-5;
    .ak-tab-bar {
      &__items {
        @apply ds-border-primary;
      }
    }
  }

  &::after {
    content: '';

    @apply ds-flex ds-relative ds-mx-4 lg:ds-mx-5;
    @apply ds--top-0.5;
    @apply ds-border-neutral-300 ds-border-solid ds-border-b-2;
    @include media-breakpoint-up(lg) {
      width: calc(100% - 47px);
    }
  }
}
</style>
