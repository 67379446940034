import { h, ref } from 'vue';
import { useDrawer } from '@bc/shared';
import { SectionType } from '@bc/discovery/domain/tracking';
import { SimilarProductsGrid } from '@bc/discovery/ui/recommendation';
import { RecommendationAlgoType } from '@monolith/legacy/types/analytics/recommendation-tracking';
import { RecommendationService } from '@bc/discovery/domain/recommendation';
import { captureRetailerCoreDiscoveryException } from '@core/plugins/sentry/helper';
import Product from '@monolith/legacy/types/product';
import { ESProductHit, ESSponsoredProductHit } from '@bc/discovery/domain/catalog';
import useBreakpoints from '@monolith/legacy/modules/design-system-candidates/breakpoints/use-breakpoints';
import { getSimilarProductsRecommendationTracking } from '../services/tracking';
import { useRouter } from 'vue-router';

import useCatalogTrackingData from './use-catalog-tracking-data';

export const useSimilarProductsDrawer = ({
  product,
  pageNumber,
  position,
}: {
  product: ESProductHit | ESSponsoredProductHit;
  pageNumber: number;
  position: number;
}) => {
  const router = useRouter();
  const { openDrawer, closeDrawer } = useDrawer();
  const { isMobile, isXlMobile, isTablet, isXlTablet } = useBreakpoints();
  const {
    drawerSectionId,
    trackSimilarProductSectionCreated,
    trackShowSimilarProductsButtonClicked,
    trackSimilarProductTileClick,
    trackQuickAddToCartClicked,
  } = getSimilarProductsRecommendationTracking({ productId: product.id });
  const { trackingSectionId, catalogType } = useCatalogTrackingData();

  const handleNavigation = () => {
    closeDrawer();
    const unregister = router.afterEach(() => {
      window.scrollTo(0, 0);
      unregister();
    });
  };

  const handleProductClicked = () => {
    handleNavigation();
    trackSimilarProductTileClick({
      productId: product.id,
      action: 'product_clicked',
      position,
    });
  };

  const handleBrandClicked = () => {
    handleNavigation();
    trackSimilarProductTileClick({
      productId: product.id,
      brandId: product.brand.id,
      action: 'brand_clicked',
      position,
    });
  };

  const handleQuickAddToCartClicked = () => {
    trackQuickAddToCartClicked({
      productId: product.id,
      position,
    });
  };

  const openSimilarProductsDrawer = async () => {
    const isLoading = ref(true);
    const items = ref<Product[]>();

    trackShowSimilarProductsButtonClicked({
      productId: product.id,
      sectionId: trackingSectionId.value,
      pageNumber,
      position,
      component: catalogType.value,
    });

    openDrawer(
      () =>
        h(SimilarProductsGrid, {
          items,
          isLoading,
          parentSectionId: trackingSectionId.value,
          pageNumber,
          parentComponent: catalogType.value,
          additionalLikeTrackingData: {
            id_section: drawerSectionId,
            itemType: 'product',
            component: SectionType.RecoCarousel,
          },
          onClicked: handleProductClicked,
          onBrandClicked: handleBrandClicked,
          onQuickAddToCartClicked: handleQuickAddToCartClicked,
        }),
      {
        right: true,
        width: isMobile.value || isXlMobile.value || (isTablet.value && !isXlTablet.value) ? '100%' : '690px',
      }
    );

    try {
      items.value = Object.values(
        await RecommendationService.getSimilarProductsFromCrossOrSameBrands(product.id, [
          RecommendationAlgoType.ProductsSameBrands,
          RecommendationAlgoType.productsCrossbrands,
        ])
      )
        .flat()
        .reverse();
    } catch (e) {
      closeDrawer();
      captureRetailerCoreDiscoveryException(e);
    } finally {
      isLoading.value = false;
      trackSimilarProductSectionCreated({ products: items.value });
    }
  };

  return { openSimilarProductsDrawer };
};
