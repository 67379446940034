import AnalyticsEvent from '../analytics-event';
import { BrandProperty, createBrandProperty } from '@monolith/legacy/services/analytics/properties/brand-property';
import { createRetailerProperty, RetailerProperty } from '../../properties/retailer-property';
import { OriginProperty } from '../../properties/origin-property';

export class RetailerGoToBrandPage extends AnalyticsEvent {
  public readonly name = 'Brand Page link in conversation page clicked';
  public properties: {
    brand: BrandProperty;
    retailer: RetailerProperty;
    origin: OriginProperty;
  };

  constructor(brand: BrandProperty, retailer: RetailerProperty, origin: OriginProperty) {
    super();
    this.properties = {
      brand: createBrandProperty(brand),
      retailer: createRetailerProperty(retailer),
      origin: origin,
    };
  }
}
