import { ShapeProperties } from '@monolith/legacy/types/product-shape';

export function sanitiseShapeProperties(shapeProperties: ShapeProperties): ShapeProperties {
  if (shapeProperties === null) {
    return null;
  }

  const { capacity } = shapeProperties;
  const capacity_unit = capacity ? shapeProperties.capacity_unit : null;

  const { height, length, width } = shapeProperties;
  const dimensions_unit = height || length || width ? shapeProperties.dimensions_unit : null;

  const { weight } = shapeProperties;
  const weight_unit = weight ? shapeProperties.weight_unit : null;

  return {
    capacity,
    capacity_unit,
    dimensions_unit,
    height,
    length,
    weight,
    weight_unit,
    width,
  };
}
