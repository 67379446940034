import { AnswerType, QuestionType } from '@monolith/legacy/types/question';

export const PHONE_COUNTRY_CODE_ALIAS = 'phone_number_country_code';
export const SHOP_TYPE = 'shop_type';
export const COMPANY_SEARCH = 'company_search';
export const ANKORSTART = 'ankorstart';
export const ANKORSTART_YES = 'ankorstart_yes';

export const extraContentAnswers = [
  AnswerType.Longtext,
  AnswerType.Text,
  AnswerType.CategoryFirstLevel,
  AnswerType.CompanySearch,
];

export const questionTypeMap = new Map([
  [QuestionType.Free, { component: 'QuestionFree' }],
  [QuestionType.Checkbox, { component: 'QuestionCheckboxes' }],
  [QuestionType.SingleChoiceDropdown, { component: 'QuestionSelect', label: true }],
  [QuestionType.Radio, { component: 'QuestionRadio', label: true }],
  [QuestionType.CompanySearch, { component: 'CompanySearch' }],
  [QuestionType.CountrySelect, { component: 'CountrySelect' }],
  [QuestionType.Phone, { component: 'QuestionPhone' }],
  [QuestionType.Password, { component: 'QuestionPassword' }],
  [QuestionType.Date, { component: 'QuestionDate' }],
  [QuestionType.GoogleMapAutocomplete, { component: 'GoogleAutocomplete' }],
  [
    QuestionType.Promocode,
    {
      component: 'QuestionPromocode',
      featureFlag: 'sign_up_promocode',
    },
  ],
  [
    QuestionType.Website,
    {
      component: 'QuestionWebsite',
    },
  ],
]);
