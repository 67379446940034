import { FETCH_OFFERS_AND_EVENTS, FETCH_R2B_OFFER_CONFIG } from '@monolith/legacy/store/offers/action-types';
import { ActionContext } from 'vuex';
import { getOfferConfig } from '@monolith/legacy/services/api/r2b';
import { OffersState } from '@monolith/legacy/store/offers/index';
import {
  SET_BUSINESS_EVENTS,
  SET_OFFERS_ELIGIBILITY,
  SET_OFFERS_ELIGIBILITY_FETCHED,
  SET_R2B_OFFERS,
  SET_R2B_OFFERS_FETCHED,
} from '@monolith/legacy/store/offers/mutation-types';
import { getGlobalEvents } from '@monolith/legacy/services/api/business-events';
import { offerProgrammesV1 } from '@monolith/legacy/modules/promote-engage/services/offer';

const offersActions = {
  [FETCH_R2B_OFFER_CONFIG]: async ({ commit, state, rootGetters }: ActionContext<OffersState, unknown>): Promise<void> => {
    if (!state.r2b.offers_config_fetched) {
      const result = await getOfferConfig(rootGetters.userIsRetailer);

      commit(SET_R2B_OFFERS, result);
      commit(SET_R2B_OFFERS_FETCHED, true);
    }
  },
  [FETCH_OFFERS_AND_EVENTS]: async ({ commit, state, rootGetters }) => {
    if (!state.offerEligibility.eligibilityFetched && rootGetters.userIsRetailer) {
      await offerProgrammesV1()
        .then((result) => {
          commit(SET_OFFERS_ELIGIBILITY, result);
          commit(SET_OFFERS_ELIGIBILITY_FETCHED, true);
        })
        .catch(() => {
          commit(SET_OFFERS_ELIGIBILITY, []);
          commit(SET_OFFERS_ELIGIBILITY_FETCHED, true);
        });
    }

    await getGlobalEvents().then(async (events) => {
      await commit(SET_BUSINESS_EVENTS, events);
    });
  },
};

export default offersActions;
export type OffersActions = typeof offersActions;
