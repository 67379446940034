<template>
  <div
    v-if="shouldShowBanner"
    class="ankorstart-banner"
  >
    <div
      class="ankorstart-banner__content"
      :style="'background-image:url(' + backgroundUrl + ')'"
      data-testid="ankorstartBanner"
    >
      <prismic-rich-text
        v-if="content.title"
        class="ankorstart-banner__title"
        :field="content.title"
        data-testid="ankorstartBannerTitle"
      />
      <prismic-rich-text
        v-if="content.subtitle"
        class="ankorstart-banner__subtitle"
        :field="content.subtitle"
        data-testid="ankorstartBannerSubtitle"
      />
      <a
        v-if="content.link && content.ctalabel"
        :href="content.link"
        target="_blank"
        @click="triggerClick"
      >
        <AkButton
          size="xl"
          class="ankorstart-banner__btn"
          data-testid="ankorstartBannerBtn"
        >{{ content.ctalabel }}</AkButton></a>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue';
import { AkButton } from '@ankorstore/design-system';

import { PrismicAnkorstartBanner } from '@monolith/legacy/types/prismic/ankorstart-banner';

import Analytics from '@monolith/legacy/services/analytics';
import UserClick from '@monolith/legacy/services/analytics/events/user-click.event';
import { useBreakpoints } from '@monolith/legacy/modules/design-system-candidates';

export default defineComponent({
  name: 'AnkorstartBanner',
  components: {
    AkButton,
  },
  props: {
    content: {
      type: Object as () => PrismicAnkorstartBanner,
      required: true,
    },
  },
  setup(props) {
    const { isMobile, isXlMobile } = useBreakpoints();

    const shouldShowBanner = computed((): boolean => {
      return (
        !!props.content.background &&
        !!props.content.background_mobile.url &&
        props.content.link !== null &&
        props.content.ctalabel !== null
      );
    });

    const backgroundUrl = computed((): string => {
      if (!isXlMobile.value && !isMobile.value) {
        return props.content.background.url;
      } else {
        return props.content.background_mobile.url;
      }
    });

    const triggerClick = async () => {
      await Analytics.track(
        new UserClick({
          component: 'unlogged_hp',
          action: 'click_ankorstart_cta',
        })
      );
    };

    return {
      shouldShowBanner,
      triggerClick,
      backgroundUrl,
    };
  },
});
</script>
<style lang="scss" scoped>
.ankorstart-banner {
  @apply ds-max-w-screen-2xl ds-mx-auto md:ds-px-6 ds-w-full;
  &__content {
    @apply ds-mx-auto ds-text-center ds-p-5 md:ds-p-6.5 md:ds-my-6.5 ds-bg-no-repeat ds-bg-cover md:ds-rounded-xl;
  }
  &__title {
    @apply ds-font-bold ds-mb-4 ds-text-3xl md:ds-text-4xl;
  }
  &__subtitle {
    @apply ds-mb-6 ds-text-xl md:ds-text-2xl;
  }
}
</style>
<style lang="scss">
.ankorstart-banner {
  &__subtitle {
    strong {
      /** Marketing new color schema */
      /* stylelint-disable-next-line */
      color: #ff3367;
    }
  }
}
</style>
