import { SeverityLevel } from '@sentry/vue';
import { captureException, withScope } from './index';
import { setTag, setContext, setUser } from '@sentry/browser';

type ExpectedException = Error | string | number | object;

export enum Squad {
  SQD_RETAILER_CORE_DISCOVERY = 'Retailer Core Discovery',
  SQD_RETAILER_REORDER_XP = 'Retailer Reorder XP',
  SQD_ORDER_SHIPPING = 'Order Shipping',
  SQD_LOYALTY = 'Loyalty',
  SQD_FINTECH = 'Fintech',
  SQD_FULFILLMENT = 'Fulfillment',
  SQD_PURCHASING = 'Purchasing',
  SQD_MONETIZATION = 'Monetization',
  SQD_BRANDS = 'Brands',
  SQD_ORDER_MANAGEMENT = 'Order Management',
}

export interface SentryTag {
  label: string;
  value: string;
}
export function sentryCaptureException(tags: SentryTag[], exception: ExpectedException, level: SeverityLevel = 'error') {
  withScope((scope) => {
    scope.setLevel(<SeverityLevel>level);
    tags.forEach((tag) => scope.setTag(tag.label, tag.value));
    captureException(exception);
  });
}

function getSquadExceptionCapturer(squad: Squad) {
  return function (exception: ExpectedException, tags: SentryTag[] = [], level: SeverityLevel = 'error') {
    sentryCaptureException([{ label: 'squad', value: squad }, ...tags], exception, level);
  };
}

export const setSentryMetadata = (state) => {
  if (state.user) {
    setUser({ id: state.user.id.toString() });
  }

  if (state.retailer) {
    setTag('user_type', 'retailer');
    setContext('retailer', {
      name: state.retailer.name,
      id: state.retailer.id,
    });
  }

  if (state.brand) {
    setTag('user_type', 'brand');
    setContext('brand', {
      name: state.brand.name,
      id: state.brand.id,
    });
  }
};

export const captureRetailerCoreDiscoveryException = getSquadExceptionCapturer(Squad.SQD_RETAILER_CORE_DISCOVERY);

export const captureRetailerReorderException = getSquadExceptionCapturer(Squad.SQD_RETAILER_REORDER_XP);

export const captureOrderShippingException = getSquadExceptionCapturer(Squad.SQD_ORDER_SHIPPING);

export const captureLoyaltyException = getSquadExceptionCapturer(Squad.SQD_LOYALTY);

export const captureFulfillmentException = getSquadExceptionCapturer(Squad.SQD_FULFILLMENT);

export const captureRetailerFintechException = getSquadExceptionCapturer(Squad.SQD_FINTECH);

export const capturePurchasingException = getSquadExceptionCapturer(Squad.SQD_PURCHASING);

export const captureMonetizationException = getSquadExceptionCapturer(Squad.SQD_MONETIZATION);

export const captureBrandsException = getSquadExceptionCapturer(Squad.SQD_BRANDS);

export const captureOrderManagementException = getSquadExceptionCapturer(Squad.SQD_ORDER_MANAGEMENT);
