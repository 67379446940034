<template>
  <div class="vat-input">
    <InputBoxes
      v-if="rules.boxes"
      v-model="vatNumber"
      :label="labelText || $t('I have a VAT number')"
      :length="rules.length"
      :regex="rules.regex"
      :is-disabled="isDisabled"
      @change="onChange"
    />
    <div
      v-else
      class="input-field"
      :class="{ valid: isValid && !isDisabled }"
    >
      <label>{{ $t('I have a VAT number') }}</label>
      <div class="input-container">
        <input
          v-model="vatNumber"
          class="form-control"
          :disabled="isDisabled"
          type="text"
          autocorrect="off"
          autocapitalize="off"
          :autocomplete="'random-' + Math.round(Math.random() * 1000)"
          spellcheck="false"
          @change="onChange"
        />
      </div>
    </div>
    <span
      v-if="displayFormatIsInvalid"
      class="error-message"
    >{{ $t('Format not good') }}</span>
    <span
      v-if="errors && errors.vat_number && isDisabled"
      class="error-message"
    >{{ errors.vat_number[0] }}</span>
  </div>
</template>

<script lang="ts">
import InputBoxes from '@monolith/legacy/components/input-boxes.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VatInput',
  components: { InputBoxes },
  props: {
    isoCode: {
      type: String,
      default: 'FR',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    initialValue: {
      type: String,
      default: null,
      required: false,
    },
    labelText: {
      type: String,
      default: null,
      required: false,
    },
  },
  emits: ['change'],
  data() {
    return {
      errors: null,
      val: this.initialValue || '',
    };
  },
  computed: {
    vatNumber: {
      get: function (): string {
        return this.val ?? '';
      },
      set: function (value: string) {
        this.val = value;
      },
    },
    displayFormatIsInvalid(): boolean {
      if (!this.rules.boxes) {
        return false;
      }
      return this.vatNumber.length === this.rules.length && !this.vatNumber.toUpperCase().match(this.rules.regex);
    },
    isValid(): boolean {
      if (this.rules.boxes && this.vatNumber.length !== this.rules.length) {
        return false;
      }

      return !!this.vatNumber.toUpperCase().match(this.rules.regex);
    },
    rules(): {
      length: number;
      regex: string;
      boxes: boolean;
    } {
      switch (this.isoCode) {
        case 'DE':
          return { length: 11, regex: '^DE\\d{9}$', boxes: true };
        case 'CH':
          return {
            length: 15,
            regex: '^CHE-\\d{3}\\.\\d{3}\\.\\d{3} (MWST|TVA|IVA)$',
            boxes: false,
          };
        case 'AT':
          return { length: 11, regex: '^ATU[A-Z\\d]{8}', boxes: true };
        case 'ES':
          return {
            length: 11,
            regex: '^ES([A-Z]\\d{7}[A-Z]|\\d{8}[A-Z]|[A-Z]\\d{8})$',
            boxes: true,
          };
        case 'BE':
          return { length: 12, regex: '^BE\\d{10}$', boxes: true };
        case 'FR':
          return { length: 13, regex: '^FR[A-Z\\d]{2}\\d{9}$', boxes: true };
        case 'LU':
          return { length: 10, regex: '^LU\\d{8}$', boxes: true };
        case 'NL':
          return { length: 14, regex: '^NL\\d{9}B\\d{2}$', boxes: true };
        case 'GB':
          return { length: 11, regex: '^GB\\d{9}$', boxes: true };
        case 'SE':
          return { length: 14, regex: '^SE\\d{10}01$', boxes: true };
        case 'IE':
          return {
            length: null,
            regex: '^IE\\s?[0-9A-Z*+]{7}\\s?[A-Z]{1,2}$',
            boxes: false,
          };
        case 'IT':
          return { length: 13, regex: '^IT\\d{11}$', boxes: true };
        case 'FI':
          return { length: 10, regex: '^FI\\d{7}\\d{1}$', boxes: true };
        case 'CY':
          return { length: 11, regex: '^CY\\d{8}[A-Z]$', boxes: true };
        case 'GR':
          return { length: 11, regex: '^EL\\d{9}$', boxes: true };
        case 'MT':
          return { length: 10, regex: '^MT\\d{8}$', boxes: true };
        case 'SI':
          return { length: 10, regex: '^SI\\d{8}$', boxes: true };
        case 'LV':
          return { length: 13, regex: '^LV\\d{11}$', boxes: true };
        case 'LT':
          return { length: 11, regex: '^LT\\d{9}$', boxes: true };
        case 'SK':
          return { length: 12, regex: '^SK\\d{10}$', boxes: true };
        case 'EE':
          return { length: 11, regex: '^EE\\d{9}$', boxes: true };
        case 'PT':
          return { length: 11, regex: '^PT\\d{9}$', boxes: true };
        case 'DK':
          return { length: 10, regex: '^DK\\d{8}$', boxes: true };
        case 'PL':
          return { length: 12, regex: '^PL\\d{10}$', boxes: true };
        case 'RO':
          return { length: null, regex: '^RO\\d{1,10}$', boxes: false };
        case 'BG':
          return { length: 11, regex: '^HU\\d{9}$', boxes: true };
        case 'HR':
          return { length: 13, regex: '^HR\\d{11}$', boxes: true };
        case 'HU':
          return { length: 10, regex: '^HU\\d{8}$', boxes: true };
      }
      return { length: 13, regex: '^FR[A-Z\\d]{2}\\d{9}$', boxes: true };
    },
  },
  watch: {
    // Initial value generation is async and can be done after the component is loaded
    initialValue(initialValue) {
      if (initialValue && !this.val) {
        this.val = initialValue;
      }
    },
  },
  created() {
    this.vatNumber = this.initialValue;
  },
  methods: {
    onChange() {
      this.$emit('change', {
        value: this.vatNumber.replace(/\s/g, '').toUpperCase(),
        isValid: this.rules.boxes ? this.isValid : true,
      });
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';

.input-container {
  input {
    height: 2em;
  }
}
</style>
