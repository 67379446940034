<template>
  <div class="lift-page">
    <LiftHeader
      :brand-img="brandImg('large')"
      :brand-name="brand.name"
      :brand-id="brand.id"
      @login-click="handleLogin"
    />
    <LiftHero
      :lift-code="liftPromocode"
      :brand-id="brand.id"
      :brand-name="brand.name"
      :brand-country="brand.country"
      @signup-click="handleRegister"
    />
    <StickyNav
      :brand-id="brand.id"
      @signup-click="handleRegister"
    />
    <LiftProducts
      :products="bestBrandProducts"
      :shipping-lift-time="{
        minimum: brand.shipping_lead_time_details.minimum,
        maximum: brand.shipping_lead_time_details.maximum,
      }"
      :minimum-order-amount="getGlobalConfig().minimum_order_amount"
      :free-shipping-minimum-amount="getGlobalConfig().free_shipping_minimum_amount"
    />
    <LiftUsp :minimum-order-amount="getGlobalConfig().minimum_order_amount" />
    <div class="bottom-section">
      <RetailerReviews />
      <RetailerFaq />
      <LiftJoin
        :brand-img="brandImg('large')"
        :brand-name="brand.name"
        :brand-id="brand.id"
        @signup-click="handleRegister"
      />
      <LoginPopinWrapper ref="loginPopinWrapper" />
    </div>
  </div>
</template>

<script lang="ts">
//@ts-nocheck
import LiftHeader from '@monolith/legacy/components/lift/common/lift-header.vue';
import LiftHero from '@monolith/legacy/components/lift/common/lift-hero.vue';
import StickyNav from '@monolith/legacy/components/lift/common/sticky-nav.vue';
import LiftProducts from '@monolith/legacy/components/lift/common/lift-products.vue';
import LiftUsp from '@monolith/legacy/components/lift/common/lift-usp.vue';
import RetailerFaq from '@monolith/legacy/components/retailer-faq.vue';
import RetailerReviews from '@monolith/legacy/components/retailer-reviews/retailer-reviews.vue';
import LiftJoin from '@monolith/legacy/components/lift/common/join.vue';
import LoginPopinWrapper from '@monolith/legacy/components/popins/login-popin-wrapper.vue';
import Analytics from '@monolith/legacy/services/analytics';
import { LiftPromocode } from '@monolith/legacy/types/order';
import LiftPageViewedEvent from '@monolith/legacy/services/analytics/events/lift/lift-event-page-viewed';
import LiftPageSignUpClickedEvent from '@monolith/legacy/services/analytics/events/lift/lift-page-signup-clicked';
import LiftPageLoginClickedEvent from '@monolith/legacy/services/analytics/events/lift/lift-page-login-clicked';
import { loginPopinMixin } from '@core/mixins/login-popin';
import { LiftBrand } from '@monolith/legacy/types/lift';
import { RetailerFormType } from '@monolith/legacy/types/retailer-form-type';
import { defineComponent } from 'vue';
import { getGlobalConfig } from '@monolith/legacy/services/global-config';

export default defineComponent({
  name: 'LiftPage',
  components: {
    LiftHeader,
    LiftHero,
    StickyNav,
    LiftProducts,
    LiftUsp,
    RetailerReviews,
    RetailerFaq,
    LiftJoin,
    LoginPopinWrapper,
  },
  mixins: [loginPopinMixin],
  props: {
    brand: {
      type: Object as () => LiftBrand,
      required: true,
    },
    bestBrandProducts: {
      type: Array,
      required: true,
    },
    liftPromocode: {
      type: Object as () => LiftPromocode,
      required: true,
    },
  },
  mounted() {
    Analytics.track(
      new LiftPageViewedEvent({
        type: 'Viewed',
        brand_id: this.$props.brand.id.toString(),
      })
    );
  },
  methods: {
    brandImg(type: 'rounded' | 'large') {
      const image = this.brand?.images?.[type];
      return `https://${this.$cdn}${image}?auto=compress&fm=pjpg&w=1536&dpr=2`;
    },
    handleRegister() {
      Analytics.track(
        new LiftPageSignUpClickedEvent({
          type: 'Click',
          brand_id: this.$props.brand.id.toString(),
        })
      );
      this.openLoginPopin(RetailerFormType.Register, {
        email: '',
        brandId: `${this.brand.id}`,
      });
    },
    handleLogin() {
      Analytics.track(
        new LiftPageLoginClickedEvent({
          type: 'Click',
          //@ts-ignore
          // where does brandId come from ??
          brand_id: this.$props.brandId,
        })
      );
      this.openLoginPopin(RetailerFormType.Login);
    },
    getGlobalConfig,
  },
});
</script>
<style lang="scss" scoped>
@import '@css/vue-import';

.lift-page {
  @apply ds-bg-info-100;
}

.bottom-section {
  @apply ds-bg-warm-white;
}
</style>
