import { StockStatus } from '@monolith/legacy/services/product-variants';
import { Amount } from '@core/types/amount';
import { CountryIsoCode } from '@core/types/country';
import { ApplicableFeeOfferName } from '@monolith/legacy/modules/cart-2/types';

export type Variant = {
  id: string;
  name: string;
  out_of_stock?: boolean;
  unit_multiplier: number;
  images?: string[];
  path: string;
  available_at?: string;
  options: {
    name: string;
    value: string;
  }[];
  stock: {
    isAlwaysInStock: boolean;
    status: StockStatus;
  };
};

export type VariantApi = {
  availableAt: string;
  imagePath: string;
  unitMultiplier: number;
  id: string;
  name: string;
  out_of_stock?: boolean;
  unit_multiplier: number;
  images?: string[];
  path: string;
  available_at?: string;
  options: {
    name: string;
    value: string;
  }[];
  stock: {
    isAlwaysInStock: boolean;
    status: StockStatus;
  };
};

export type PreorderCartResponseDeserialized = {
  type: string;
  id: number | string | null;
  itemCount: number;
  totalAmountWithVat: Amount;
  totalAmountVat: Amount;
  totalBaseAmount: Amount;
  grandTotalAmountWithVat?: Amount;
  preorders: { data: PreorderCartDeserialized[] };
};

export type PreorderCartDeserialized = Omit<PreorderCart, 'brand' | 'items' | 'selected' | 'minimum'> & {
  brand: { data: CartBrand };
  preorderItems: { data: ItemFromApi[] };
  metadata: PreorderMetadata;
  isSelected: boolean;
  preorderMinimumAmount: Amount;
  totalBaseAmount: Amount;
  totalAmountBeforeDiscount: Amount;
};

export type PreorderCartResponseMapped = {
  type: string;
  id: number | string | null;
  total_amount_with_vat: Amount;
  total_amount_vat: Amount;
  total_amount: Amount;
  grand_total_amount_with_vat: Amount;
  carts: PreorderCart[];
  firstInit?: boolean;
  brandsCountry: CountryIsoCode[];
  order_informations?: {
    order_items_count_main: number;
    type: 'order_informations';
  };
};

export interface PreorderCart {
  type: 'preorder-cart';
  id: string;
  preorderId: string;
  brand: CartBrand;
  items: Item[];
  selected?: boolean;
  itemCount: number;
  minimum: {
    above: boolean;
    amount: Amount;
  };
  total_amount: Amount | null;
  total_amount_before_discount: Amount | null;
  offerDiscounts: [{ amount: Amount; name: string }];
  hasDiscounts: boolean;
  globalDiscountPercentage: number;
  hasMultiDiscounts: boolean;
  metadata: PreorderMetadata;
}

export type PreorderMetadata = {
  actualShippingFeeAmount: Amount;
  amountLeftForFreeShipping?: Amount;
  offerName?: ApplicableFeeOfferName;
  isDiscovery: boolean;
  isEligibleForDiscoveryFreeShipping: boolean;
  brandNonEligibleForAkPlusFreeShipping: boolean;
  francoAmount: number;
  discountedShippingAmount: Amount;
  shippingFeeAmountVat: Amount;
};
export type CartBrand = {
  logoImagePath?: string;
  id: string;
  uuid: string;
  name: string;
  link: string;
  countryIsoCode?: CountryIsoCode;
  isEnabled: boolean;
  canShipToRetailerCountry: boolean;
  images: {
    squared: string;
    rounded: string;
    large: string;
  };
};

export interface ItemFromApi {
  productVariant: { data: VariantApi };
  baseAmount: Amount;
  unitPrice: Amount;
  type: 'preorder';
  id: number | null | string;
  quantity: number;
  unit_price: Amount;
  amount: Amount;
  amountBeforeDiscount: Amount;
  canBeBought: boolean;
  unit_multiplier?: number;
}

export interface Item {
  type: 'preorder';
  id: number | null | string;
  quantity: number;
  unit_price: Amount;
  amount: Amount;
  amountBeforeDiscount: Amount;
  canBeBought: boolean;
  variant?: Variant;
  unit_multiplier?: number;
}

export enum CartType {
  Main = 'main',
}
