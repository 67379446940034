<template>
  <div
    ref="brandCatalogOrderedRef"
    class="brand-catalog-ordered"
  >
    <div
      class="brand-catalog-ordered__list--reorder"
    >
      <ReorderProductCard
        v-for="(item, index) in currentItems"
        :key="item.id"
        :brand-id="orderedBrand.id"
        :brand-name="orderedBrand.name"
        :product="item"
        :restock-alerts="restockAlerts"
        :tracking-data="getCardTrackingData(index)"
      />
    </div>
    <div
      v-if="lastPage > 1"
      class="brand-catalog-ordered__pagination"
    >
      <AkPagination
        :current-page="currentPage"
        :last-page="lastPage"
        @change="handlePageChange"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue';
import { Brand } from '@monolith/legacy/types/api/brand';
import { OrderedProduct } from '@monolith/legacy/types/re-order';

import Analytics from '@monolith/legacy/services/analytics';
import { SectionType } from '@bc/discovery';
import { type IdPosition, getSectionId } from '@bc/shared';
import { SectionCreatedEvent, SectionItemType } from '@monolith/legacy/services/analytics/events/section_created.event';
import UserClick from '@monolith/legacy/services/analytics/events/user-click.event';
import ReorderProductCard from '@monolith/legacy/modules/reorder/product-card/reorder-product-card.vue';
import { AkPagination } from '@ankorstore/design-system';
import { getOutOfStockVariantsIds } from '@monolith/legacy/services/product-variants';
import { useStockStatus } from '@monolith/legacy/modules/reorder/stock-status/composables/stock-status';
import { getOrderedItemsFromBrand, OrderedBrand } from '@monolith/legacy/services/reorder/reorder';
import { isEnabled } from '@monolith/legacy/services/features';
import { scrollToRef } from '@core/utilities/scroll';
import { ReorderProductCardTrackingData } from '@monolith/legacy/modules/reorder/product-card/tracking';

const valueProposition = 'Buy again';
const ANALYTICS_COMPONENT_NAME = 'brand_page_buy_again';

export default defineComponent({
  name: 'BrandCatalogOrdered',
  components: { AkPagination, ReorderProductCard },
  props: {
    brand: {
      type: Object as () => Brand,
      default: null,
    },
    orderedBrand: {
      type: Object as () => OrderedBrand,
      default: null,
    },
    items: {
      type: Array as () => OrderedProduct[],
      default: () => [],
    },
    lastPage: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const { fetchStockAlerts, restockAlerts } = useStockStatus();
    const currentPage = ref(1);
    const currentItems = ref([] as OrderedProduct[]);
    const brandCatalogOrderedRef = ref(null);

    onMounted(async () => {
      const positions: IdPosition[] = props.items?.map((item, index) => ({ id: item.id, position: index }));
      trackSectionCreated(positions);
      await handleFetchStockAlerts(props.items);
      currentItems.value = props.items;
    });

    const sectionId = computed(() => {
      return getSectionId({
        sectionType: SectionType.Brand,
        valueProposition,
      });
    });

    const getCardTrackingData = (index): ReorderProductCardTrackingData => {
      return {
        parentComponent: ANALYTICS_COMPONENT_NAME,
        lbValueProposition: 'reorder_from_buy_again',
        sectionId: sectionId.value,
        index: index,
      };
    };

    const fetchOrderedProducts = async () => {
      const data = await getOrderedItemsFromBrand(props.brand.id, {
        perPage: 10,
        page: currentPage.value,
      });
      const products = data?.data.products || [];
      if (products.length) {
        await handleFetchStockAlerts(products);
        currentItems.value = products;
      }
    };

    const handleFetchStockAlerts = async (productsData: OrderedProduct[]) => {
      const outOfStockVariantsIds = getOutOfStockVariantsIds(productsData);

      if (outOfStockVariantsIds.length) {
        await fetchStockAlerts(outOfStockVariantsIds);
      }
    };

    const handlePageChange = async (pageNumber: number) => {
      currentPage.value = pageNumber;
      await fetchOrderedProducts();
      scrollToRef(brandCatalogOrderedRef);
    };

    const trackSectionCreated = (idProduct: IdPosition[]): void => {
      Analytics.track(
        new SectionCreatedEvent({
          section_type: SectionType.Brand,
          page_number: 1,
          itemtype: SectionItemType.Product,
          value_proposition: valueProposition,
          id_product: idProduct,
          id_section: sectionId.value,
          id_brand: [],
          filters: [],
        })
      );
    };

    const handleProductClicked = (item: OrderedProduct, position: number) => {
      Analytics.track(
        new UserClick({
          component: 'brand_page',
          action: 'product_clicked',
          itemType: 'product',
          id_section: sectionId.value,
          page_number: 1,
          productId: item.id,
          position,
        })
      );
    };

    return {
      restockAlerts,
      fetchStockAlerts,
      brandCatalogOrderedRef,
      sectionId,
      currentItems,
      currentPage,
      getCardTrackingData,
      handlePageChange,
      handleProductClicked,
      isEnabled,
    };
  },
});
</script>

<style lang="scss" scoped>
.brand-catalog-ordered {
  @apply ds-mb-4;
}

.brand-catalog-ordered__list--default {
  @apply ds-px-4 md:ds-px-5 xl:ds-px-6 ds-grid ds-grid-cols-1 md:ds-grid-cols-2 lg:ds-grid-cols-3 xl:ds-grid-cols-4 ds-gap-5;
}

.brand-catalog-ordered__list--reorder {
  @apply ds-grid ds-gap-4 sm:ds-gap-5 ds-mt-4 sm:ds-mt-5;
  @apply ds-grid-cols-2 md:ds-grid-cols-3 lg:ds-grid-cols-4 xl:ds-grid-cols-5;
}

.brand-catalog-ordered__pagination {
  @apply ds-mt-5 sm:ds-mt-8;
}
</style>
