<template>
  <div
    v-if="collection?.title"
    class="collection-snippet"
    :class="{ 'no-click': noClick }"
  >
    <div class="collection-snippet__image">
      <ImageLazyload
        :src="imgUrl"
        :alt="collection.title"
      />
    </div>
    <div class="collection-snippet__content">
      <AkHeading2 class="collection-snippet__title">
        <router-link
          v-if="!noClick"
          :to="collection.link"
          class="ak-stretched-link"
        >
          {{ collection.title }}
        </router-link>
        <template v-else>
          {{ collection.title }}
        </template>
      </AkHeading2>
      <div
        v-if="collection.item_count && showProductCount"
        class="collection-snippet__count"
      >
        {{ $tc('{count} Product | {count} Product | {count} Products', collection.item_count) }}
      </div>
      <div
        v-if="collection.item_count && showBrandCount"
        class="collection-snippet__count"
      >
        {{ $tc('{count} Brand | {count} Brand | {count} Brands', collection.item_count) }}
      </div>

      <AkParagraph
        v-if="typeof collection.description === 'string'"
        class="collection-snippet__description ds-line-clamp-3"
        :class="{ 'collection-snippet__description--maxWidth': !fullWidthDescription }"
      >
        {{ collection.description }}
      </AkParagraph>
      <PrismicRichText
        v-else
        :class="{
          'collection-snippet__description': true,
          'collection-snippet__description--maxWidth': !fullWidthDescription,
        }"
        :field="collection.description"
        :html-serializer="{ paragraph: ({ text }) => `<p class='ds-font-basic ds-font-normal ds-text-base'>${text}</p>` }"
      />
      <div
        v-if="showExploreLabel"
        class="collection-snippet__link"
      >
        {{ $t('Explore collection') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ProductCollection } from '@monolith/legacy/types/account/product-collections';
import ImageLazyload from '@monolith/legacy/components/global/image-lazyload.vue';
import { imgCdnUrl } from '@bc/shared';
import { AkHeading2, AkParagraph } from '@ankorstore/design-system';
import { PrismicRichText } from '@prismicio/vue';
import { RouterLink } from 'vue-router';

export default defineComponent({
  name: 'CollectionSnippet',
  components: { ImageLazyload, AkHeading2, AkParagraph, PrismicRichText, RouterLink },
  props: {
    collection: {
      type: Object as () => ProductCollection,
      required: true,
    },
    noClick: {
      type: Boolean,
      default: false,
    },
    isAbsoluteImageUrl: {
      type: Boolean,
      default: false,
    },
    fullWidthDescription: {
      type: Boolean,
      default: true,
    },
    showExploreLabel: {
      type: Boolean,
      default: true,
    },
    showProductCount: {
      type: Boolean,
      default: true,
    },
    showBrandCount: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imgUrl() {
      return this.isAbsoluteImageUrl ? this.collection.cover_image : imgCdnUrl(this.collection.cover_image);
    },
  },
});
</script>

<style lang="scss">
.collection-snippet {
  @apply ds-rounded-md;
  &__image {
    @apply ds-rounded-md;
  }
  .image__wrapper {
    @apply ds-h-full ds-transition ds-duration-100 ds-ease-out;
    margin: 0 !important;
    min-height: 0 !important;
    aspect-ratio: auto !important;
  }
  &:not(.no-click).collection-snippet {
    &,
    img {
      @apply ds-transition ds-duration-100 ds-ease-out ds-cursor-pointer;
    }
    &:hover {
      @apply ds-bg-warm-white ds-text-neutral-900;
      .image__wrapper {
        @apply ds-scale-105 ds-opacity-80;
      }
    }
  }
  &__description {
    &--maxWidth {
      max-width: 562px;
    }
  }
}
</style>
