<template>
  <div class="retailer-reviews__review">
    <div class="ds-flex ds-flex-col ds-items-center ds-justify-center ds-h-full">
      <img
        :src="imgCdnUrl(item.authorImage, '64')"
        :alt="item.authorName"
        class="retailer-reviews__review-image"
      />
      <AkParagraph
        class="ds-font-bold ds-mb-1"
        data-testid="author-name"
      >
        {{ item.authorName }}
      </AkParagraph>
      <AkParagraph
        class="ds-mb-5 ds-text-neutral-700"
        data-testid="shop-name"
      >
        {{ item.authorShopName }}
      </AkParagraph>
    </div>
    <AkParagraph
      class="ds-h-10"
      data-testid="review-item-body"
    >
      {{ item.body }}
    </AkParagraph>
  </div>
</template>
<script lang="ts">
//@ts-nocheck
import { defineComponent, PropType } from 'vue';
import { RetailerReviewAttributes } from '@monolith/legacy/types/retailer-review';
import { AkParagraph } from '@ankorstore/design-system';
import { imgCdnUrl } from '@bc/shared';

export default defineComponent({
  name: 'RetailerReviewItem',
  components: {
    AkParagraph,
  },
  props: {
    item: {
      type: Object as PropType<RetailerReviewAttributes>,
      required: true,
    },
  },
  setup() {
    return {
      imgCdnUrl,
    };
  },
});
</script>
<style lang="scss" scoped>
.retailer-reviews {
  &__review {
    @apply ds-bg-white ds-flex ds-flex-col ds-items-center md:ds-mx-4 ds-mr-2 ds-px-4 lg:ds-px-8 md:ds-py-9 ds-py-7 ds-text-center;
    min-height: 300px;
  }

  &__review-image {
    @apply ds-h-8 ds-w-8 ds-rounded-full ds-object-cover ds-mb-2;
  }
}
</style>
