<template>
  <div data-login-popin>
    <AkModal
      ref="AkLoginPopin"
      data-testid="login-popin-wrapper-modal"
      class="login-popin-modal"
      :class="{ 'login-max-size': !isEmailOnlyRevamp }"
      :size="modalSize"
      :with-padding="false"
      :with-closer="!showFullPage"
    >
      <div
        v-if="showLoginPopin"
        data-testid="login-popin"
      >
        <LoginPopin
          v-if="properties"
          :show-full-page="showFullPage"
          :view="properties.view"
          :email="properties.email"
          :success-callback="properties.successCallback"
          :brand-id="properties.data.brandId"
          :is-reminded="properties.data.isReminded"
          :brand-name="properties.data.brandName"
          :lift-code="properties.data.liftCode"
        />
      </div>
    </AkModal>
  </div>
</template>

<script lang="ts">
import { loginPopinMixin, LoginPopinProps } from "@core/mixins/login-popin";
import LoginPopin from "@monolith/legacy/components/popins/login-popin.vue";
import { AkModal } from "@ankorstore/design-system";
import { RetailerFormType } from "@monolith/legacy/types/retailer-form-type";
import { FeatureFlag } from "@monolith/legacy/services/features";
import { defineComponent } from "vue";
import { getInjectedContent } from "@monolith/legacy/services/injected-content";
import { StateMetaTagName } from "@monolith/legacy/services/initial-state";

export default defineComponent({
  name: 'LoginPopinWrapper',
  components: {
    LoginPopin,
    AkModal,
  },
  mixins: [loginPopinMixin],
  data() {
    return {
      properties: null as LoginPopinProps,
      showLoginPopin: true,
    };
  },
  computed: {
    showFullPage(): boolean {
      if (!this.properties) {
        return false;
      }

      return ![
        RetailerFormType.EmailOnlySignUp,
        RetailerFormType.Login,
        RetailerFormType.ResetPassword,
        RetailerFormType.ResetPasswordInput,
      ].includes(this.properties.view);
    },
    isEmailOnlyRevamp(): boolean {
      return (
        (this.properties?.view === RetailerFormType.EmailOnlySignUp || this.properties?.view === RetailerFormType.Login) &&
        this.$isEnabled(FeatureFlag.RegistrationFunnelRevamp)
      );
    },
    modalSize() {
      if (this.isEmailOnlyRevamp) {
        return '5xl';
      }

      return this.showFullPage ? 'full' : 'max';
    },
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);
    if (window.location.pathname === '/register') {
      window['didomiOnReady'] = window['didomiOnReady'] ?? [];
      window['didomiOnReady'].push((Didomi) => {
        if (Didomi.shouldConsentBeCollected()) {
          Didomi.on('notice.hidden', () => this.openRegisterPageLogin(params));
        } else {
          this.openRegisterPageLogin(params);
        }
      });
    }
    const user = getInjectedContent(StateMetaTagName.User);
    if (window.location.hash === '#register' && !user) {
      this.openLoginPopin(RetailerFormType.Register, { email: params.get('email') ?? '' }, () => {
        window.location.reload();
      });
    }
  },
  methods: {
    openRegisterPageLogin(params) {
      this.showLoginPopin = false;
      this.openLoginPopin((params.get('step') as RetailerFormType) ?? RetailerFormType.Register, {
        email: params.get('email') ?? '',
        //@ts-ignore
        checkedTerms: !!params.get('checkedTerms'),
      }).then(() => {
        this.observeMutations();
      });
    },
    observeMutations() {
      const mutationObserver = new MutationObserver((mutations) => {
        for (const mutation of mutations) {
          //@ts-ignore
          const classes = mutation.target.classList;
          if (
            mutation.attributeName === 'class' &&
            classes.contains('login-popin-modal') &&
            !classes.contains('modal-enter-active') &&
            !classes.contains('modal-leave-active')
          ) {
            this.showLoginPopin = true;
            mutationObserver.disconnect();
            return;
          }
        }
      });
      mutationObserver.observe(document.querySelector('[data-login-popin]'), {
        subtree: true,
        characterData: true,
        attributes: true,
        attributeFilter: ['style', 'class'],
      });
    },
    openModal(props: LoginPopinProps) {
      this.properties = props;
      //@ts-ignore
      (this.$refs.AkLoginPopin as InstanceType<typeof AkModal>).openModal();
    },
  },
});
</script>

<style lang="scss">
@import '@css/vue-import';

.login-popin-modal {
  -webkit-overflow-scrolling: touch;
  @apply ds-max-h-full ds-w-full;

  .ak-modal__closer {
    @apply ds-z-10 ds-top-2;
  }

  %ak-modal__size-full {
    @apply ds-max-w-full;

    &.ak-modal__container {
      @apply md:ds-h-full;
    }
  }

  &.login-max-size {
    .ak-modal__container {
      max-width: 800px;
    }

    .ak-modal__size-full.ak-modal__container {
      @extend %ak-modal__size-full;
    }
  }

  .ak-modal__container {
    @apply ds-h-full md:ds-h-auto;
  }

  .ak-modal__size-full {
    @extend %ak-modal__size-full;
  }
}
</style>
