<template>
  <div
    class="lastExportStatus"
    data-testid="lastExportStatus"
  >
    <LastBatchStatus
      v-if="lastExport"
      :is-displayed="isDisplayed"
      :title="title"
      :init-batch="lastExport"
      :status="status"
      listen-to="account/productsIntegration/exportCatalogue"
      :fetch-batch="fetchLastExportCatalogue"
      :batch-type="batchType"
      @toggle="$emit('toggle', $event)"
    >
      <template #completed>
        <AkButton
          size="md"
          outlined
          color="white"
          symbol="external-link"
          type="button"
          class="ds-mt-3"
          data-testid="lastExportOpenSpreadsheet"
          @click="openGeneratedSpreadSheet"
        >
          {{ $t('Open in Google Spreadsheet') }}
        </AkButton>
      </template>
      <template #inprogress>
        <span class="content__text">
          {{
            $t(
              'This process may take several minutes. You will be able to download your file from the Products page when it’s ready.'
            )
          }}
        </span>
      </template>
      <template #failed>
        <span class="content__text">{{
          $t('An error occurred during the import and the process has been canceled. Please, try again later.')
        }}</span>
      </template>
    </LastBatchStatus>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ExportCatalogueStatus, BatchStatusType } from '@monolith/legacy/types/account/account-products/catalog-integration/product-integration';
import LastBatchStatus from '@monolith/legacy/components/account/account-products/last-batch-status.vue';
import { mapActions, mapGetters } from 'vuex';
import Analytics from '@monolith/legacy/services/analytics';
import UserClick from '@monolith/legacy/services/analytics/events/user-click.event';

export default defineComponent({
  name: 'LastExportCatalogue',
  components: { LastBatchStatus },
  props: {
    isDisplayed: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  emits: ['toggle'],
  data: function () {
    return {
      batchType: BatchStatusType.EXPORT,
      title: {
        in_progress: this.$t('Google Spreadsheet export in progress'),
        error: this.$t('Catalogue export failed'),
        completed: this.$t('Catalogue export'),
      },
      status: {
        completed: [ExportCatalogueStatus.COMPLETED],
        error: [ExportCatalogueStatus.ERROR],
        in_progress: [ExportCatalogueStatus.IN_PROGRESS, ExportCatalogueStatus.WAITING],
      },
    };
  },
  computed: {
    ...mapGetters(['brand']),
    ...mapGetters('account/productsIntegration', { lastExport: 'getLastExport' }),
  },
  async mounted() {
    await this.fetchLastExportCatalogue();
  },
  methods: {
    ...mapActions('account/productsIntegration', ['fetchLastExportCatalogue']),
    openGeneratedSpreadSheet(): void {
      Analytics.track(
        new UserClick({
          component: 'brand_bo_catalogue_management',
          action: 'export_catalogue',
          brand_id: this.brand.id,
          brand_name: this.brand.name,
          cta: 'open_spreadsheet',
        })
      );
      window.open(this.lastExport?.google_spreadsheet_url, '_blank');
    },
  },
});
</script>
