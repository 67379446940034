<template>
  <div>
    <div
      class="ds-flex ds-cursor-pointer"
      @click="openPopin"
    >
      <AkIcon
        symbol="flag"
        size="sm"
      />
      <AkParagraph
        data-testid="reportPopinTrigger"
        class="ds-m-0 ds-ml-1 ds-underline hover:ds-text-neutral-700 ds-text-primary"
        size="sm"
      >
        {{ triggerText }}
      </AkParagraph>
    </div>
    <AkModal
      ref="reportPopin"
      size="lg"
      class="report-popin"
      @cancelled="resetState"
    >
      <div data-testid="reportPopinModalContent">
        <ReportReasonForm
          v-if="!isSubmitResultStep"
          v-model="formData"
          :step="currentStep"
          class="ds-mb-5"
          data-testid="reportReasonForm"
        />
        <ReportSubmitResultStep
          v-else
          :has-error="submitFailed"
          data-testid="reportConfirmation"
        />
        <div
          class="ds-flex ds-flex-col md:ds-flex-row ds-justify-center ds-mt-4"
          data-testId="reportPopinModalContent"
        >
          <AkButton
            class="ds-order-last md:ds-order-first md:ds-mr-2 ds-w-full md:ds-w-auto"
            data-testid="reportPopinBackButton"
            color="white"
            @click="handleBackButtonClicked"
          >
            {{ backButtonText }}
          </AkButton>
          <AkButton
            class="ds-order-first md:ds-order-last ds-mb-2 md:ds-mb-0 ds-w-full md:ds-w-auto"
            data-testid="reportPopinNextButton"
            :disabled="isNextStepDisabled"
            @click="handleNextButtonClicked"
          >
            {{ nextButtonText }}
          </AkButton>
        </div>
      </div>
    </AkModal>
  </div>
</template>

<script lang="ts">
//@ts-nocheck
import { defineComponent, PropType } from 'vue';
import { mapGetters } from 'vuex';
import { AkButton, AkIcon, AkParagraph, AkModal } from '@ankorstore/design-system';
import Analytics from '@monolith/legacy/services/analytics';
import {
  ReportClickEvent,
  ValidateReportAdditionalInfoClickEvent,
  ValidateReportReasonsClickEvent,
  ReportPopinClickEventPayload,
} from '@monolith/legacy/services/analytics/events/reports/click-events';
import ReportReasonForm from '@monolith/legacy/components/brand/report-popin/report-reasons-form.vue';
import ReportSubmitResultStep from '@monolith/legacy/components/brand/report-popin/report-submit-result-step.vue';
import { ReportFormData, ReportPopinStep } from './types';
import { sendReport } from '@monolith/legacy/services/report/report';
import { captureRetailerCoreDiscoveryException } from '@core/plugins/sentry/helper';

type ReportPopinData = {
  currentStep: ReportPopinStep;
  formData: ReportFormData;
  submitFailed: boolean;
};

export default defineComponent({
  name: 'ReportPopin',
  components: {
    AkIcon,
    AkParagraph,
    AkModal,
    AkButton,
    ReportReasonForm,
    ReportSubmitResultStep,
  },
  props: {
    brandId: {
      type: Number as PropType<number>,
      required: true,
    },
    productId: {
      type: Number as PropType<number | null>,
      default: null,
    },
  },
  data(): ReportPopinData {
    return {
      currentStep: ReportPopinStep.ISSUE_SELECTION as ReportPopinStep,
      formData: {
        reasons: [],
        description: '',
      },
      submitFailed: false,
    };
  },
  computed: {
    ...mapGetters(['retailer']),
    isProductReport(): boolean {
      return Number.isInteger(this.productId);
    },
    isNextStepDisabled(): boolean {
      return this.formData.reasons.length === 0;
    },
    isSubmitResultStep(): boolean {
      return this.currentStep === ReportPopinStep.ISSUE_SUBMIT_RESULT;
    },
    triggerText(): string {
      return this.isProductReport
        ? this.$t('@@ret_core_discovery.reportPopin.productTrigger')
        : this.$t('@@ret_core_discovery.reportPopin.brandBrandTrigger');
    },
    backButtonText(): string {
      switch (this.currentStep) {
        case ReportPopinStep.ISSUE_MESSAGE:
          return this.$t('@@ret_core_discovery.reportPopin.button.back.back');
        case ReportPopinStep.ISSUE_SUBMIT_RESULT:
          return this.$t('@@ret_core_discovery.reportPopin.button.back.close');
        case ReportPopinStep.ISSUE_SELECTION:
        default:
          return this.$t('@@ret_core_discovery.reportPopin.button.back.cancel');
      }
    },
    nextButtonText(): string {
      switch (this.currentStep) {
        case ReportPopinStep.ISSUE_SUBMIT_RESULT:
          return this.$t('@@ret_core_discovery.reportPopin.button.next.backToHome');
        case ReportPopinStep.ISSUE_SELECTION:
        case ReportPopinStep.ISSUE_MESSAGE:
        default:
          return this.$t('@@ret_core_discovery.reportPopin.button.next.confirm');
      }
    },
    clickTrackPayload(): ReportPopinClickEventPayload {
      return {
        payload: {
          brand_id: this.brandId,
          retailer_id: this.retailer.id,
          ...(this.isProductReport && { product_id: this.productId }),
        },
      };
    },
  },
  methods: {
    resetState() {
      this.currentStep = ReportPopinStep.ISSUE_SELECTION;
      this.formData.reasons = [];
      this.formData.description = '';
    },
    openPopin() {
      Analytics.track(new ReportClickEvent(this.clickTrackPayload));
      (this.$refs.reportPopin as InstanceType<typeof AkModal>).openModal();
    },
    closePopin() {
      (this.$refs.reportPopin as InstanceType<typeof AkModal>).close();
      this.resetState();
    },
    incrementStep() {
      this.currentStep = Math.min(this.currentStep + 1, ReportPopinStep.ISSUE_SUBMIT_RESULT);
    },
    decrementStep() {
      this.currentStep = Math.min(this.currentStep - 1, ReportPopinStep.ISSUE_SELECTION);
    },
    handleBackButtonClicked() {
      if (this.currentStep === ReportPopinStep.ISSUE_MESSAGE) {
        this.decrementStep();
      } else {
        this.closePopin();
      }
    },
    async handleNextButtonClicked() {
      if (ReportPopinStep.ISSUE_SELECTION === this.currentStep) {
        this.incrementStep();
        Analytics.track(new ValidateReportReasonsClickEvent(this.clickTrackPayload));
      } else if (ReportPopinStep.ISSUE_MESSAGE === this.currentStep) {
        try {
          await sendReport({
            reasons: this.formData.reasons.map((reason) => reason.id),
            description: this.formData.description,
            reportable_type: this.isProductReport ? 'product' : 'brand',
            reportable_id: this.isProductReport ? this.productId : this.brandId,
          });
        } catch (error) {
          this.submitFailed = true;
          captureRetailerCoreDiscoveryException(error, [
            { label: 'component', value: 'ReportPopin' },
            { label: 'action', value: 'Send report' },
          ]);
        } finally {
          this.incrementStep();
          Analytics.track(new ValidateReportAdditionalInfoClickEvent(this.clickTrackPayload));
        }
      } else {
        window.location.href = '/';
      }
    },
  },
});
</script>
<style lang="scss">
.report-popin {
  z-index: 200 !important;
}
</style>
