import { getInjectedContent } from "@monolith/legacy/services/injected-content";
import { GlobalMetaTagName } from "@monolith/legacy/services/initial-state";

export interface Tag {
  type: 'tag';
  id: number;
  name: string;
  label: string;
  checked?: boolean;
}

export interface TagsMetaReduced {
  [name: string]: Tag;
}

const tagMeta: Tag[] = getInjectedContent(GlobalMetaTagName.Tags);

export const getTags = (): TagsMetaReduced =>
  tagMeta.reduce((object, value) => Object.assign(object, { [value.name]: value }), {});
