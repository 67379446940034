import { createI18n } from 'vue-i18n';
import { en } from '@client/lang'; // only import en by default to reduce bundle size!

const loadedLanguages = ['en']; // our default language that is preloaded

const numberFormats = {
  en: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
    decimal: {
      style: 'decimal',
    },
  },
  fr: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
    decimal: {
      style: 'decimal',
    },
  },
  es: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
    decimal: {
      style: 'decimal',
    },
  },
  de: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
    decimal: {
      style: 'decimal',
    },
  },
  nl: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
    decimal: {
      style: 'decimal',
    },
  },
  it: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
    decimal: {
      style: 'decimal',
    },
  },
  sv: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
    decimal: {
      style: 'decimal',
    },
  },
  fi: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
    decimal: {
      style: 'decimal',
    },
  },
  da: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
    decimal: {
      style: 'decimal',
    },
  },
};
const i18nInstance = createI18n({
  messages: { en },
  allowComposition: true,
  locale: 'en',
  fallbackLocale: 'en',
  silentFallbackWarn: process.env.NODE_ENV !== 'development',
  numberFormats,
});
type AnkorI18n = typeof i18nInstance;
/**
 * Read the lang code from the html tag and load the corresponding language file
 */
export async function setupI18n() {
  const lang = document.documentElement.lang;
  if (lang) {
    await loadLanguageAsync(lang, i18nInstance);
  }
}

export async function loadLanguageAsync(lang: string, i18n: AnkorI18n): Promise<string> {
  // If the same language
  if (i18n.global.locale === lang) {
    return setI18nLanguage(lang, i18n);
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return setI18nLanguage(lang, i18n);
  }

  // If the language hasn't been loaded yet, here is the magic dynamic lazy loading
  // `.default` is import, else translation keys including unsupported JS characters
  // will be skipped silently
  // Update: Lazy loading doesn't work with aliases, which is why we are importing it this way.
  const languages = await import('@client/lang');
  const messages = languages[lang] as {
    default: typeof en;
  };

  // @ts-ignore
  i18n.global.setLocaleMessage(lang, messages);
  loadedLanguages.push(lang);

  return setI18nLanguage(lang, i18n);
}

/** Will switch the language currently served from the i18n instance. */
export function setI18nLanguage(lang: string, i18n): string {
  if (!i18n || !lang) {
    throw Error('Lang and i18n are required');
  }
  i18n.global.locale = lang;

  return lang;
}

/**
 * Get the country name from the country code
 * Using the Intl.DisplayNames API
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DisplayNames/of
 */
export function getCountryNameFromCountryCode(countryCode: string, currentLang = countryCode): string {
  if (countryCode) {
    const regionNamesIntl = new Intl.DisplayNames([currentLang], { type: 'region' });
    return regionNamesIntl.of(countryCode);
  }
  return '';
}

export const getLocale = () => i18nInstance.global.locale;

export default i18nInstance;
