import AnalyticsEvent from './analytics-event';
import { PreviewCart } from './segment-ecommerce-types';

export class CartPreviewViewedEvent extends AnalyticsEvent {
  public readonly name = 'Cart Previewed';

  constructor(public readonly properties: PreviewCart) {
    super();
  }
}
