import { MutationTree, Module } from 'vuex';

import products from './products';
import payments from './payments';
import campaigns from './campaigns';
import clients from './clients';
import business from './business';
import soleTrader from './soleTrader';
import privateOrdersIntegration from './private-orders-integration';
import publicOrdersIntegration from './public-orders-integration';
import information from './information';
import fulfillment from './fulfillment';
import productsIntegration from './account-products/products-integration';
import storepage from './storepage';

import { ToasterConfigType } from '@ankorstore/design-system';
import { SaveBarMode } from './types';
import paymentConfiguration from '@monolith/legacy/store/account/payment-configuration';
import settings from '@monolith/legacy/modules/brand-account/store/settings.store';
import brandOnboarding from '@monolith/legacy/modules/brand-onboarding/store';
import nafoCustomer from '@monolith/legacy/store/account/nafo-customer';
import fulfillableItems from '@monolith/legacy/store/account/fulfillable-items';
import { BrandContactManagementStore as contactManagement } from '@bc/brands';
import { BrandCampaignsManagementStore as campaignsManagement } from '@bc/brands';

export interface AccountState {
  saveBarMode: SaveBarMode;
  routeTo: string;
  isPopinDisplayed: boolean;
  isSaveBarPending: boolean;
  cancelEditProductState: number | null;
  cancelEditProductDraftState: number | null;
  cancelCreateCampaignState: number | null;
}

export const createInitialState = (): AccountState => ({
  saveBarMode: SaveBarMode.Disabled,
  routeTo: null,
  isPopinDisplayed: false,
  isSaveBarPending: false,
  cancelEditProductState: null,
  cancelEditProductDraftState: null,
  cancelCreateCampaignState: null,
});

export const mutations: MutationTree<AccountState> = {
  SET_SAVEBAR_MODE(state: AccountState, type: SaveBarMode): void {
    state.saveBarMode = type;
  },
  SET_SAVEBAR_PENDING(state: AccountState, pending: boolean): void {
    state.isSaveBarPending = pending;
  },
  SET_DISPLAYED_POPIN(state: AccountState, value: boolean): void {
    state.isPopinDisplayed = value;
  },
  CANCEL_EDIT_PRODUCT(state: AccountState): void {
    state.cancelEditProductState = Date.now();
  },
  CANCEL_EDIT_PRODUCT_DRAFT(state: AccountState): void {
    state.cancelEditProductDraftState = Date.now();
  },
  CANCEL_CREATE_CAMPAIGN(state: AccountState): void {
    state.cancelCreateCampaignState = Date.now();
  },
};

export const actions = {
  changeSaveBarMode({ state, commit }, type: SaveBarMode): void {
    if (type !== state.saveBarMode) {
      commit('SET_SAVEBAR_MODE', type);
    }
  },
  setSaveBarPending({ commit }, disabled: boolean): void {
    commit('SET_SAVEBAR_PENDING', disabled);
  },
  displayDiscardChanges(): void {},
  cancelEditProduct({ commit }): void {
    commit('CANCEL_EDIT_PRODUCT');
  },
  cancelEditProductDraft({ commit }): void {
    commit('CANCEL_EDIT_PRODUCT_DRAFT');
  },
  cancelCreateCampaign({ commit }): void {
    commit('CANCEL_CREATE_CAMPAIGN');
  },
  setAsDisplayedPopin({ commit }, value: boolean): void {
    commit('SET_DISPLAYED_POPIN', value);
  },
  //payload is needed to pass propsto the toaster via subscribed actions
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  displayAccountToaster(_payload: ToasterConfigType): void {},
};

export const getters = {
  getSaveBarMode: (state: AccountState): SaveBarMode => state.saveBarMode,
  isSaveBarEnabled: (state: AccountState): boolean => state.saveBarMode !== SaveBarMode.Disabled,
  isPopinDisplayed: (state: AccountState): boolean => state.isPopinDisplayed,
  isSaveBarPending: (state: AccountState): boolean => state.isSaveBarPending,
};

export default {
  namespaced: true,
  state: createInitialState(),
  modules: {
    products,
    payments,
    campaigns,
    clients,
    contactManagement,
    campaignsManagement,
    business,
    soleTrader,
    privateOrdersIntegration,
    publicOrdersIntegration,
    information,
    fulfillment,
    productsIntegration,
    paymentConfiguration,
    storepage,
    settings,
    brandOnboarding,
    nafoCustomer,
    fulfillableItems,
  },
  actions,
  mutations,
  getters,
} as unknown as Module<AccountState, unknown>;
