<template>
  <div
    v-if="areVisibleSpecialTiles"
    class="ds-w-full ds-h-full"
  >
    <SpecialTile1
      v-if="isVisibleSpecialTile1"
      :tile="tile"
      :data-testid="dataTestIdSpecialTile1"
      @click="$emit('special-tile-1-clicked')"
    />
    <SpecialTile2
      v-else-if="isVisibleSpecialTile2"
      :tile="tile"
      :data-testid="dataTestIdSpecialTile2"
      @click="$emit('onSpecialTile2Clicked')"
    />
  </div>
</template>

<script lang="ts">
//TODO: Tile type mismatch
import { defineComponent, PropType, computed } from 'vue';

import SpecialTile1 from '@monolith/legacy/modules/promote-engage/promotion-tile/special-tile-1.vue';
import SpecialTile2 from '@monolith/legacy/modules/promote-engage/promotion-tile/special-tile-2.vue';
import { PromotionTileType, SpecialTileGlobal } from '@monolith/legacy/modules/promote-engage/promotion-tile/types';

export default defineComponent({
  name: 'PromotionTile',
  components: {
    SpecialTile1,
    SpecialTile2,
  },
  props: {
    tile: {
      type: Object as PropType<SpecialTileGlobal>,
      required: true,
    },
  },
  emits: ['special-tile-1-clicked', 'onSpecialTile2Clicked'],
  setup(props) {
    const isVisibleSpecialTile1 = computed((): boolean => {
      return props.tile.type === PromotionTileType.SpecialTile1 || props.tile.type === PromotionTileType.SpecialTile1GuestUsers;
    });
    const isVisibleSpecialTile2 = computed((): boolean => {
      return props.tile.type === PromotionTileType.SpecialTile2 || props.tile.type === PromotionTileType.SpecialTile2GuestUsers;
    });
    const dataTestIdSpecialTile1 = computed((): string => {
      return props.tile.type === PromotionTileType.SpecialTile1
        ? 'specialTile1ForAuthenticatedUsers'
        : 'specialTile1ForGuestUsers';
    });
    const dataTestIdSpecialTile2 = computed((): string => {
      return props.tile.type === PromotionTileType.SpecialTile2
        ? 'specialTile2ForAuthenticatedUsers'
        : 'specialTile2ForGuestUsers';
    });
    const areVisibleSpecialTiles = computed((): boolean => {
      return isVisibleSpecialTile1.value || isVisibleSpecialTile2.value;
    });

    return {
      areVisibleSpecialTiles,
      dataTestIdSpecialTile1,
      dataTestIdSpecialTile2,
      isVisibleSpecialTile1,
      isVisibleSpecialTile2,
    };
  },
});
</script>
