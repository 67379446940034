import AnalyticsEvent from '../analytics-event';
import { getSourceByPath } from '@monolith/legacy/services/analytics/events/home/source-property';

export default class RetailerReviewsSection extends AnalyticsEvent {
  public readonly name = 'Retailer Reviews Section';
  public properties: {
    type: string;
    source: string;
  };

  constructor(type) {
    super();
    this.properties = {
      type,
      source: getSourceByPath(),
    };
  }
}
