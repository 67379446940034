<template>
  <div class="question-preference-group">
    <div class="question-preference-group-photo">
      <img
        :src="category.image.url"
        :alt="category.image.alt"
      >
    </div>
    <div class="ds-w-full">
      <div
        class="ds-text-xl ds-mb-4 ds-font-semibold"
        data-testid="questionPreferenceGroupCategoryName"
      >
        {{ category.name }}
      </div>
      <div
        class="question-preference-group--category-buttons"
        :class="{ 'question-preference-group--mobile': isMobile, expanded: isExpanded }"
      >
        <AkButton
          v-for="child in subCategories"
          :key="child.id"
          class="question-preference-group--category-button"
          :class="{
            selected: subCategoryIsSelected(child),
          }"
          data-testid="questionPreferenceGroupCategoryItemButton"
          @click="categorySelection(child)"
        >
          {{ child.name }}
        </AkButton>
      </div>
      <AkButton
        class="question-preference-group-view-more-button"
        data-testid="questionPreferenceGroupViewMoreButton"
        @click="toggleView()"
      >
        <span class="question-preference-group-view-more-button-text">{{
          isExpanded
            ? $t('retailer.registration.question.preferences.viewLess.cta')
            : $t('retailer.registration.question.preferences.viewMore.cta')
        }}</span>
        <AkIcon
          class="ds-inline-block"
          :symbol="isExpanded ? 'chevron-up' : 'chevron-down'"
        />
      </AkButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ProductCategory } from '@monolith/legacy/types/product-category';
import { State } from '@monolith/legacy/types/state';
import { Question } from '@monolith/legacy/types/question';
import { withBreakpoints } from '@monolith/legacy/modules/design-system-candidates';

export default defineComponent({
  name: 'QuestionPreferenceGroup',
  mixins: [withBreakpoints],
  props: {
    question: {
      type: Object as PropType<Question>,
      required: true,
    },
    category: {
      type: Object as PropType<ProductCategory>,
      required: true,
    },
    state: {
      type: Object as () => State,
      required: true,
    },
    model: {
      type: [Object, Array],
      required: true,
    },
  },
  emits: ['change'],
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    subCategories() {
      return this.category.children;
    },
  },
  methods: {
    categorySelection(category: ProductCategory) {
      this.$emit(
        'change',
        this.question.alias,
        this.categoryIsSelected(category) ? this.removeCategoryItem(category) : this.addCategoryItem(category)
      );
    },
    categoryIsSelected(category: ProductCategory): boolean {
      return Array.isArray(this.model) ? this.model.find((item: { content: number }) => item.content === category.id) : false;
    },
    removeCategoryItem(category: ProductCategory) {
      return Array.isArray(this.model) ? this.model.filter((item: { content: number }) => item.content !== category.id) : [];
    },
    addCategoryItem(category: ProductCategory) {
      return Array.isArray(this.model) ? [...this.model, this.newModelItem(category)] : [this.newModelItem(category)];
    },
    newModelItem(category: ProductCategory) {
      return {
        answer_id: this.question.answers.find((answer) => +answer.prefilled_content === category.id)?.id,
        content: category.id,
      };
    },
    subCategoryIsSelected(child: ProductCategory): boolean {
      if (Array.isArray(this.model)) {
        return this.model.some((item: { content: number }) => item.content === child.id);
      }
      return false;
    },
    toggleView() {
      this.isExpanded = !this.isExpanded;
    },
  },
});
</script>

<style lang="scss" scoped>
.question-preference-group {
  @apply ds-flex ds-px-4 ds-pt-5 ds-pb-4 md:ds-pb-5 md:ds-pl-0 md:ds-pr-8 ds--mx-5 ds-mb-6 md:ds-ml-7 ds-gap-5 ds-rounded-lg ds-bg-white;
  max-width: 676px;

  .question-preference-group--category-buttons {
    @apply ds-flex ds-flex-wrap ds-gap-x-2 ds-gap-y-4;

    .question-preference-group--category-button {
      @apply ds-bg-warm-white ds-text-primary ds-inline ds-overflow-hidden;
      text-overflow: ellipsis;
      max-width: 80%;

      &.selected {
        @apply ds-bg-success-700 ds-text-warm-white;
      }
    }
  }
}

.question-preference-group--mobile {
  @apply ds-overflow-hidden;
  max-height: 176px;
  transition: max-height 0.5s cubic-bezier(0.8, 0.1, 0.1, 0.8);
  &.expanded {
    max-height: 2000px;
  }
}

.question-preference-group-photo {
  @apply ds-overflow-hidden ds-rounded-lg ds--ml-7 ds-bg-success-700 ds-shrink-0 ds-self-center ds-hidden md:ds-block;
  width: 224px;
  height: 224px;
}

.question-preference-group-view-more-button {
  @apply md:ds-hidden ds-mt-6 ds-w-full ds-inline-flex ds-items-center ds-justify-between;
  @apply ds-bg-white ds-text-primary #{!important};
}

.question-preference-group-view-more-button-text {
  @apply ds-overflow-hidden;
  text-overflow: ellipsis;
}
</style>
