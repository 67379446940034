import {
  GroupedResults,
  ItemResult,
  StaticItem,
  RecentSearchItem,
  DynamicResults,
  StaticResults,
} from '@bc/discovery/domain/search';
import { MAX_RESULTS, SHOW_ALL_ID } from './index';
import { uniq } from 'lodash-es';
import { isEnabled } from '@monolith/legacy/services/features';

const searchGetters = {
  arrowPosition: (state): number => state.arrowPosition,
  originalUserInput: (state): string => state.originalUserInput,
  results: (state): GroupedResults => {
    if (isEnabled('RET-1653')) {
      const next = { ...state.results };
      if (next.history.length) {
        delete next.trending;
      }
      let maxHistoryItems;
      let maxSuggestionItems;
      const history = Array.from(next.history);
      const suggestions = Array.from(next.suggestions);
      if (next.history.length >= 5 && next.suggestions.length >= 5) {
        maxHistoryItems = maxSuggestionItems = MAX_RESULTS / 2;
      } else if (next.history.length >= 5) {
        maxSuggestionItems = next.suggestions.length;
        maxHistoryItems = MAX_RESULTS - maxSuggestionItems;
      } else {
        maxHistoryItems = next.history.length;
        maxSuggestionItems = MAX_RESULTS - maxHistoryItems;
      }
      history.splice(Math.max(maxHistoryItems, 0));
      suggestions.splice(Math.max(maxSuggestionItems, 0));
      next.history = history;
      next.suggestions = suggestions;
      return next;
    }
    return state.results;
  },
  resultsDynamic: (state): DynamicResults => {
    const next = { ...state.results };
    if (isEnabled('RET-1653') && next.history.length) {
      delete next.trending;
    }
    delete next.r2bLink;
    delete next.searchAll;
    return next;
  },
  resultsStatic: (state): StaticResults => ({
    searchAll: state.results.searchAll,
    r2bLink: state.results.r2bLink,
  }),
  resultsIds: (state): Array<number> => state.resultsIds,
  isOpen: (state): boolean => state.isOpen,
  getRecentSearches: (state): Array<RecentSearchItem> => {
    return state.originalUserInput
      ? state.userLocalHistory?.filter(
          (h) => !uniq(state.results.suggestions?.map((x) => x.name?.toLowerCase())).includes(h.name?.toLowerCase())
        )
      : state.userLocalHistory;
  },
  hasSuggestions: (state): boolean => {
    let hasElements = false;
    for (const prop in state.results) {
      const isNotEmptyKey = Array.isArray(state.results[prop]) ? state.results[prop].length : state.results[prop];
      if (prop !== 'r2bLink' && !!isNotEmptyKey) {
        hasElements = true;
        break;
      }
    }
    return hasElements;
  },
  currentSelectedItem: (state, getters): ItemResult | StaticItem => {
    let result;
    for (const prop in state.results) {
      const resultItem = state.results[prop];
      if (Array.isArray(resultItem)) {
        result = resultItem.find((r) => r.id === getters.currentSelectionId);
      } else if (resultItem?.id === getters.currentSelectionId) {
        result = resultItem;
      }
      if (result) {
        break;
      }
    }
    return result;
  },
  currentSelectionId: (state): number | string => state.resultsIds[state.arrowPosition],
  currentSelectionName: (state, getters): string =>
    getters.currentSelectionId === SHOW_ALL_ID ? state.originalUserInput : getters.currentSelectedItem?.name,
};

export default searchGetters;
export type SearchGetters = typeof searchGetters;
