<template>
  <div class="sepa-resume">
    <div class="sepa-resume__header">
      <i18n-t
        v-if="!noLimitDate"
        keypath="Please proceed to payment before {paymentDate} by making a SEPA transfer of {price} to the following bank account."
        tag="span"
      >
        <template #paymentDate>
          <b data-testid="sepa-payment-date">{{ paymentDate }}</b>
        </template>
        <template #price>
          <b>{{ $root.formatPrice(amount) }}</b>
        </template>
      </i18n-t>
      <i18n-t
        v-else
        keypath="Please proceed to payment by making a SEPA transfer of {0} to the following bank account."
        tag="span"
      >
        <b>{{ $root.formatPrice(amount) }}</b>
      </i18n-t>
    </div>
    <div class="sepa-resume__container">
      <div class="sepa-resume__container-item ds-flex ds-flex-row ds-justify-between">
        <span class="label">
          {{ $t('Beneficiary') }}
        </span>
        <span class="value">
          {{ sepaCreditTransferInformation.beneficiary }}
        </span>
      </div>
      <div class="sepa-resume__container-item ds-flex ds-flex-row ds-justify-between">
        <span class="label">
          {{ $t('IBAN') }}
        </span>
        <span class="value">
          {{ sepaCreditTransferInformation.iban }}
        </span>
      </div>
      <div class="sepa-resume__container-item ds-flex ds-flex-row ds-justify-between">
        <span class="label">
          {{ $t('BIC') }}
        </span>
        <span class="value">
          {{ sepaCreditTransferInformation.bic }}
        </span>
      </div>
      <div class="sepa-resume__container-item ds-flex ds-flex-row ds-justify-between">
        <span class="label">
          {{ $t('Amount') }}
        </span>
        <span class="value">
          {{ $root.formatPrice(amount) }}
        </span>
      </div>
      <div class="sepa-resume__container-item ds-flex ds-flex-row ds-justify-between">
        <span class="label">
          {{ $t('Payment reference') }}
        </span>
        <span class="value">
          {{ sepaCreditTransferInformation.payment_reference }}
        </span>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { addDate, formatDate, TypeToAdd } from '@monolith/legacy/services/date-locale';

export default defineComponent({
  name: 'AccountPaymentsSepaResume',
  props: {
    checkoutDate: {
      type: String,
      required: true,
    },
    amount: {
      type: Object,
      required: true,
    },
    sepaCreditTransferInformation: {
      type: Object,
      required: true,
    },
    noLimitDate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    paymentDate(): string {
      return formatDate(addDate(this.checkoutDate, 14, TypeToAdd.Days), 'P');
    },
  },
});
</script>
<style lang="scss" scoped>
@import '@css/vue-import';

.sepa-resume {
  @apply ds-border ds-border-solid ds-border-neutral-300;
  margin-bottom: 20px;
  &__header {
    @apply ds-bg-white;
    padding: 20px;
    text-align: left;
  }
  &__container {
    &-item {
      @apply ds-border-t ds-border-solid ds-border-neutral-300;
      padding: 10px 15px;
      @apply ds-bg-white;
      &:nth-child(odd) {
        @apply ds-bg-warm-white;
      }
    }
  }
  .label {
    &:after {
      content: ' :';
    }
  }
  .value {
    font-weight: bold;
  }
}
</style>
