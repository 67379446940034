import http from '@monolith/legacy/services/api/http';
import type { AxiosResponse } from 'axios';
import { ReportReasonResponse, ReportPayload, SendReportResponse } from '@monolith/legacy/components/brand/report-popin/types';

export const getReportReasons = async (): Promise<ReportReasonResponse> => {
  const { data } = await http().get<ReportReasonResponse>(`/api/reports/reasons`);

  return data;
};

export const sendReport = async (payload: ReportPayload): Promise<SendReportResponse> => {
  const { data } = await http().post<AxiosResponse<SendReportResponse>>(`/api/reports`, payload);
  return data as SendReportResponse;
};
