import {
  getBrandRetailerRelation,
  getWarehouses,
  updateBrandRetailerRelation as _updateBrandRetailerRelation,
} from '@monolith/legacy/services/fulfillment/api';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@monolith/legacy/store';
import { OrderAutomationValidation, OrderTypeOptions } from '@monolith/legacy/types/order';
import { AutomaticOrderValidationMode } from '@monolith/legacy/types/fulfillment';

export interface State {
  warehouses: [];
  // We will use this map to save the relation between the current connected brand with a retailer
  brandRetailerRelation: Record<string, OrderAutomationValidation | null>;
}

const initialState: State = {
  warehouses: [],
  brandRetailerRelation: {},
};

export const getters: GetterTree<State, RootState> = {
  isFulfillmentEnabled(_state: State, _getters, _rootState, rootGetters) {
    return rootGetters.user && !rootGetters.userIsRetailer ? rootGetters.user.business.brand?.fulfillment_enabled : false;
  },
  isFulfillmentEligible(_state: State, getters, _rootState, rootGetters) {
    return !getters.isFulfillmentEnabled && rootGetters.brand?.is_fulfillment_eligible;
  },
  getRetailerAutomationMode:
    (state: State) =>
    (retailerUUID: string): OrderAutomationValidation => {
      return state.brandRetailerRelation[retailerUUID] ?? OrderAutomationValidation.NOT_SET;
    },
  haveOrderAutomation: (_state: State, getters, _rootState, rootGetters) => {
    const isExternalOrder = rootGetters['legacyAccount/orderType'] === OrderTypeOptions.EXTERNAL;
    const automaticOrderValidationMode = rootGetters['brand']?.automatic_order_validation_mode;
    return (
      getters.isFulfillmentEnabled &&
      !isExternalOrder &&
      automaticOrderValidationMode !== AutomaticOrderValidationMode.AUTOMATIC_CONFIRM_AND_FULFILL
    );
  },
};

export const actions: ActionTree<State, RootState> = {
  getWarehouses: async ({ commit }) => {
    const res = await getWarehouses();
    commit('SET_WAREHOUSES', res);
  },
  orderSaveRequested() {},
  orderSaveCanceled() {},
  async getBrandRetailerRelation({ state, commit }, { retailerUUID }: { retailerUUID: string }) {
    // Check if the relation is already saved in the store
    if (state.brandRetailerRelation[retailerUUID]) {
      return;
    }
    try {
      const relation = await getBrandRetailerRelation(retailerUUID);
      let result: OrderAutomationValidation | null = null;
      if (relation.data.automaticOrderValidationMode === null) {
        result = OrderAutomationValidation.NOT_SET;
      } else if (relation.data.automaticOrderValidationMode) {
        result = OrderAutomationValidation.AUTOMATED;
      } else {
        result = OrderAutomationValidation.MANUAL;
      }

      commit('SET_RETAILER_RELATION', { retailerUUID, relation: result });
    } catch (error) {
      commit('SET_RETAILER_RELATION', { retailerUUID, relation: OrderAutomationValidation.NOT_SET });
    }
    return;
  },
  async updateBrandRetailerRelation(
    { commit },
    { retailerUUID, relation }: { retailerUUID: string; relation: OrderAutomationValidation }
  ) {
    await _updateBrandRetailerRelation(retailerUUID, {
      automaticOrderValidationMode: relation === OrderAutomationValidation.AUTOMATED,
    });
    commit('SET_RETAILER_RELATION', { retailerUUID, relation });
  },
};

export const mutations: MutationTree<State> = {
  SET_WAREHOUSES(state: State, wareHouses): void {
    state.warehouses = wareHouses;
  },
  SET_RETAILER_RELATION(
    state: State,
    { retailerUUID, relation }: { retailerUUID: string; relation: OrderAutomationValidation | null }
  ) {
    state.brandRetailerRelation[retailerUUID] = relation;
  },
};

const stateModule: Module<State, RootState> = {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
export default stateModule;
