<template>
  <div
    v-if="isStaff"
    class="ak-impersonate"
  >
    <div class="ak-impersonate__content">
      <AkIcon
        symbol="controller2"
        size="sm"
      />
      <strong>{{ $t('Staff mode') }}</strong>
      {{ $t('Country restrictions are disabled') }}
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StaffBanner',
  computed: {
    ...mapGetters(['isStaff']),
  },
});
</script>

<style lang="scss" scoped>
@import '@css/vue-import';

.ak-impersonate {
  @apply ds-bg-discount-700;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
}

.ak-impersonate__content {
  @include container;

  font-size: 10px;
  @apply ds-text-white;
  line-height: 1;
  text-align: center;

  span {
    position: relative;
    top: 1px;
    margin-right: 0.4em;
  }
}
</style>
