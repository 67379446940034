import { CaseReason, CaseReasonStep } from '@monolith/legacy/types/order-retailer-rejection';
import { isEnabled } from '@monolith/legacy/services/features';

const basicItemsConfig = {
  steps: {
    orderInfo: {
      stepNumber: 1,
      label: 'General order information',
      key: CaseReasonStep.OrderInfo,
    },
    productsCheckList: {
      stepNumber: 2,
      label: 'Product information',
      key: CaseReasonStep.ProductsCheckList,
    },
    productsList: {
      stepNumber: 2,
      label: 'Product information',
      key: CaseReasonStep.ProductsList,
    },
    summary: {
      stepNumber: 3,
      label: 'Issue report summary',
      key: CaseReasonStep.Summary,
    },
  },
};

let damagedItemsConfig = null,
  missingItemsConfig = null;

if (isEnabled('damaged_items_form_v2')) {
  damagedItemsConfig = {
    steps: {
      orderInfo: {
        stepNumber: 1,
        label: 'General order information',
        key: CaseReasonStep.OrderInfo,
      },
      parcelReception: {
        stepNumber: 2,
        label: 'Parcel reception',
        key: CaseReasonStep.ParcelReception,
      },
      parcelDetails: {
        stepNumber: 3,
        label: 'Inside the parcel',
        key: CaseReasonStep.ParcelDetails,
      },
      productsCheckList: {
        stepNumber: 4,
        label: 'Product information',
        key: CaseReasonStep.ProductsCheckList,
      },
      productsList: {
        stepNumber: 4,
        label: 'Product information',
        key: CaseReasonStep.ProductsList,
      },
      summary: {
        stepNumber: 5,
        label: 'Issue report summary',
        key: CaseReasonStep.Summary,
      },
    },
  };
} else {
  damagedItemsConfig = {
    ...basicItemsConfig,
  };
}

if (isEnabled('missing_items_form_v2')) {
  missingItemsConfig = {
    steps: {
      trackWithLink: {
        stepNumber: 1,
        label: 'General order information',
        key: CaseReasonStep.TrackWithLink,
      },
      orderInfo: {
        stepNumber: 1,
        label: 'General order information',
        key: CaseReasonStep.OrderInfo,
      },
      parcelReception: {
        stepNumber: 2,
        label: 'Parcel reception',
        key: CaseReasonStep.ParcelReception,
      },
      productsCheckList: {
        stepNumber: 3,
        label: 'Product information',
        key: CaseReasonStep.ProductsCheckList,
      },
      productsList: {
        stepNumber: 3,
        label: 'Product information',
        key: CaseReasonStep.ProductsList,
      },
      summary: {
        stepNumber: 4,
        label: 'Issue report summary',
        key: CaseReasonStep.Summary,
      },
    },
  };
} else {
  missingItemsConfig = {
    steps: {
      trackWithLink: {
        stepNumber: 1,
        label: 'General order information',
        key: CaseReasonStep.TrackWithLink,
      },
      ...basicItemsConfig.steps,
    },
  };
}

const configuration = {
  damagedItems: damagedItemsConfig,
  wrongItems: {
    ...basicItemsConfig,
  },
  missingItems: missingItemsConfig,
  defectiveItems: {
    ...basicItemsConfig,
  },
  notReceived: {
    steps: {
      trackWithLink: {
        stepNumber: 1,
        label: 'General order information',
        key: CaseReasonStep.TrackWithLink,
      },
      contactBrand: {
        stepNumber: 1,
        label: 'General order information',
        key: CaseReasonStep.ContactBrand,
      },
      summary: {
        stepNumber: 2,
        label: 'Issue report summary',
        key: CaseReasonStep.Summary,
      },
    },
  },
  common: {
    allowedImageTypes: ['image/jpg', 'image/jpeg', 'image/png'],
    allowedFileTypes: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    maxFileSize: 10 * 1024 * 1024,
    maxPackagingFileQuantity: 3,
    maxFileLabelLength: 30,
    maxProductFileQuantity: 10,
    maxDescriptionLength: 800,
    content: {},
  },
};

const caseReasons = [
  CaseReason.DamagedItems,
  CaseReason.WrongItems,
  CaseReason.MissingItems,
  CaseReason.DefectiveItems,
  CaseReason.NotReceived,
];

caseReasons.forEach((reason) => {
  const currentReason = configuration[reason];
  const lastStepKey = Object.keys(currentReason.steps).slice(-1);
  currentReason.maxStepsCount = currentReason.steps[lastStepKey].stepNumber;
});

export const config = configuration;
