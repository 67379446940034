import { concat, uniqBy } from 'lodash-es';
import { IdPosition } from '../properties/id_position';

const isIdPosition = (obj: unknown): obj is IdPosition => {
  return typeof obj === 'object' && 'position' in obj && Number.isInteger(obj.position);
};

export const getCustomTrackMergeFn = (keys: string[]) => {
  return (objValue: unknown, srcValue: unknown, key: string) => {
    if (keys.includes(key)) {
      return uniqBy(concat(srcValue, objValue), (iterable) => {
        if (Boolean(iterable) && typeof iterable === 'object' && 'position' in iterable) {
          return iterable.position;
        }
        return iterable;
      })
        .filter((value) => value !== undefined)
        .sort((a, b) => {
          if (isIdPosition(a) && isIdPosition(b)) {
            return a.position - b.position;
          }
          return 0;
        });
    }
    return null;
  };
};
