import http from '@monolith/legacy/services/api/http';
import { captureRetailerReorderException } from '@core/plugins/sentry/helper';

export enum StockAlertTypes {
  BackInStock = 'back_in_stock',
}

export interface StockAlert {
  id: string;
  product_variant_id: number;
  product_variant_uuid: string;
  type: StockAlertTypes;
  created_at: string;
}

export type IdType = 'id' | 'uuid';

export const getBackInStockAlerts = async (
  product_variant_ids: number[] | string[],
  idType: IdType = 'id'
): Promise<StockAlert[]> => {
  try {
    const { data } = await http().get('/api/stock-alerts/back_in_stock', {
      data: {
        // Depending on the type of the product variant id, we need to send a different query parameter
        // cf. https://github.com/ankorstore/ankorstore/pull/35651
        // Todo: refactor this to use uuids everywhere
        [`product_variant_${idType}s`]: idType === 'uuid' ? product_variant_ids : product_variant_ids.join(','),
      },
    });
    return data?.data || [];
  } catch (e) {
    captureRetailerReorderException(e);
    return null;
  }
};

export const createBackInStockAlert = async (variantId: number | string, idType: IdType = 'id'): Promise<StockAlert> => {
  try {
    const { data } = await http().post('/api/stock-alerts', {
      // Depending on the type of the product variant id, we need to send a different payload parameter
      // cf. https://github.com/ankorstore/ankorstore/pull/35651
      // Todo: refactor this to use uuids everywhere
      [`product_variant_${idType}`]: variantId,
      type: StockAlertTypes.BackInStock,
    });
    return data?.data || null;
  } catch (e) {
    captureRetailerReorderException(e);
    return null;
  }
};

export const removeBackInStockAlert = async (uuid: string): Promise<void> => {
  try {
    return await http().delete(`/api/stock-alerts/${uuid}`);
  } catch (e) {
    captureRetailerReorderException(e);
    return null;
  }
};
