import { Conversation } from '@monolith/legacy/types/conversation';
import { OriginProperty } from './origin-property';

export interface ConversationProperty {
  id: number;
  recipient: Recipient;
  sender: Sender;
  message: {
    id: number;
  };
  origin: OriginProperty;
}

export interface Sender {
  name: string;
  id: number;
  type: string;
}

export interface Recipient {
  name: string;
  id: number;
  type: string;
}

export function createRecipientProperty(recipientEntity): Recipient {
  return {
    name: recipientEntity.name,
    id: recipientEntity.id,
    type: recipientEntity.type,
  };
}

export function createSenderProperty(senderEntity): Sender {
  return {
    name: senderEntity.name,
    id: senderEntity.id,
    type: senderEntity.type,
  };
}

export function createConversationProperty(message: Conversation): ConversationProperty {
  const result: ConversationProperty = {
    id: message.id,
    recipient: createRecipientProperty(message.recipient.entity),
    sender: createSenderProperty(message.sender.entity),
    message: {
      id: message.messages?.edges[0].data.id,
    },
    origin: message.messages?.edges[0].data.origin,
  };

  return result;
}
