import { defineComponent } from 'vue';
import { formatPriceMixins } from './price/format';
import { getGlobalConfig } from '@monolith/legacy/services/global-config';

export const seeYouSoonOfferMinimumBrands = 4;
//TODO: migrate into utility file
export const promocodeMixin = defineComponent({
  name: 'PromocodeMixin',
  mixins: [formatPriceMixins],
  computed: {
    seeYouSoonOffer(): { amount: string; minimumBrands: number } {
      return {
        amount: this.formatPrice(getGlobalConfig().see_you_soon_offer_amount, '$0'),
        minimumBrands: seeYouSoonOfferMinimumBrands,
      };
    },
  },
});
