import { CaseReason, CaseReasonStep } from '@monolith/legacy/types/order-retailer-rejection';

export enum OrderIssueClickedAction {
  OPEN = 'Open',
  CLOSE = 'Close',
  NEXT = 'Next',
  BACK = 'Back',
  SUBMIT = 'Submit',
}

export enum OrderIssueCloseTheClaimAction {
  CLOSE_THE_CLAIM = 'Close the claim',
  YES = 'Yes',
  NO = 'No',
  CLOSE = 'Close',
}

export interface OrderIssueClickedProps {
  order_id: number;
  action: OrderIssueClickedAction;
  issue?: CaseReason;
  step?: CaseReasonStep;
  parcel_photo?: boolean;
  selected_products_count?: number;
  completed_products_count?: number;
  products_with_quantity_error?: number;
  products_with_description_error?: number;
  products_with_file_error?: number;
  default_form?: boolean;
  parcel_links?: number;
  parcel_links_clicked?: number;
  contact_link_clicked?: boolean;
}

export interface OrderIssueCloseTheClaimProps {
  action: OrderIssueCloseTheClaimAction;
  issue_reason: CaseReason;
  issue_id: number;
  order_id: number;
}
