<template>
  <div class="parcel-reservation ds-pt-6">
    <div>
      <AkParagraph :class="showSectionsExpander ? '' : 'ds-text-lg ds-font-semibold'">
        {{ content.title }}
      </AkParagraph>
      <AkRadio
        v-if="showRadio('show')"
        class="ds-mt-4 ds-pb-4"
        value="show"
        text=""
        :is-checked="showContent"
        name="parcel-reservation-options"
        data-testid="showParcelReservationOption"
        @change="switchContent"
      >
        <AkParagraph
          class="ds-mb-0 ds-font-semibold"
          :class="showContent ? 'ds-text-primary' : 'ds-text-neutral-900'"
        >
          {{ $t('Yes') }}
        </AkParagraph>
      </AkRadio>
      <AkRadio
        v-if="showRadio('hide')"
        class="ds-pb-4"
        value="hide"
        :text="$t('No')"
        :is-checked="hideContent"
        name="parcel-reservation-options"
        @change="switchContent"
      >
        <AkParagraph
          class="ds-mb-0 ds-font-semibold"
          :class="hideContent ? 'ds-text-primary' : 'ds-text-neutral-900'"
        >
          {{ $t('No') }}
        </AkParagraph>
      </AkRadio>
    </div>
    <div
      v-show="enableContent"
      class="ds-mt-2"
    >
      <AkParagraph class="ds-text-neutral-900">
        {{ content.uploaderLabel }}
      </AkParagraph>
      <FileUploader
        :images-only="false"
        @upload-file="uploadFile"
        @remove-file="removeFile"
        @set-loader="setLoader"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { CaseReason, FormFileType, ParcelIssueOption } from '@monolith/legacy/types/order-retailer-rejection';
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import FileUploader from '@monolith/legacy/components/account/orders/order/order-retailer-reject/components/file-uploader.vue';
import { orderIssueFileUploadingMixin } from '@monolith/legacy/components/account/orders/order/order-retailer-reject/mixins/order-issue-file-uploading';

export default defineComponent({
  name: 'ParcelReservation',
  components: {
    FileUploader,
  },
  mixins: [orderIssueFileUploadingMixin],
  emits: ['set-checked'],
  data() {
    return {
      enableContent: null,
    };
  },
  computed: {
    ...mapGetters('orderRetailerRejection', ['getCaseReason', 'showSectionsExpander']),
    content() {
      switch (this.getCaseReason) {
        case CaseReason.DamagedItems:
          return {
            title: this.$t('When you received the parcel, did you sign a damage reservation?'),
            uploaderLabel: this.$t('Attach damage reservation'),
          };
        case CaseReason.MissingItems:
          return {
            title: this.$t('When you received the parcel, did you sign a shortage reservation?'),
            uploaderLabel: this.$t('Attach reservation'),
          };
        default: {
          return {};
        }
      }
    },
    showContent(): boolean {
      return this.enableContent === true;
    },
    hideContent(): boolean {
      return this.enableContent === false;
    },
  },
  methods: {
    ...mapActions('orderRetailerRejection', ['setParcelIssueOption', 'uploadFormFile', 'removeFormFile']),
    showRadio(label: string): boolean {
      if (this.showSectionsExpander) {
        if (label === 'show') {
          return this.showContent;
        } else {
          return this.hideContent;
        }
      }

      return true;
    },
    switchContent(checked: boolean, radioValue = 'show') {
      const value = radioValue === 'show' && checked;
      this.enableContent = value;
      this.setParcelIssueOption({ issueType: ParcelIssueOption.DeliveryReservation, value });
      this.$emit('set-checked', true);
    },
    async uploadFile(file: File, callback): Promise<void> {
      const result = await this.uploadFormFile({ file, type: FormFileType.PackagingReservation });
      callback(result);
    },
    removeFile(file: File): void {
      this.removeFormFile({ file });
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';

:deep(.ak-radio__sidelabel) {
  @apply ds-mt-1;
}
</style>
