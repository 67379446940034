import { NavigationGuardNext } from 'vue-router';
import Store from '@monolith/legacy/store';
import http from '@monolith/legacy/services/api/http';
import { SaveBarMode } from '@monolith/legacy/store/account/types';

export async function campaignCreateGuard(to, _from, next: NavigationGuardNext) {
  const {
    data: { top_locale },
  } = await http().get(`/api/me/brand/campaigns/locale-segments`);
  to.params.defaultSegment = to.query.defaultSegment;
  to.params.defaultLanguage = to.query.defaultLanguage;
  to.params.topLocale = top_locale;
  Store.dispatch('account/contactManagement/fetchFilters');
  Store.dispatch(
    'account/changeSaveBarMode',
    SaveBarMode.CreateCampaign
  );
  await Store.dispatch('account/contactManagement/fetchContacts', true);
  if (!Store.getters['account/contactManagement/noFiltersApplied']) {
    Store.dispatch('account/contactManagement/fetchContacts');
  }
  next();
}
