<template>
  <a
    ref="buttonMore"
    :class="['button-more', 'show-more']"
    @click.prevent="click()"
  >{{ $t('Read more') }}</a>
</template>

<script lang="ts">
import { Brand } from '@monolith/legacy/types/api/brand';
import Analytics from '@monolith/legacy/services/analytics';
import { BrandReadMoreEvent } from '@monolith/legacy/services/analytics/events/brand-read-more.event';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ReadMore',
  props: {
    brand: {
      type: Object as () => Brand,
      required: true,
    },
  },
  methods: {
    click() {
      Analytics.track(new BrandReadMoreEvent(this.brand));
      (this.$refs.buttonMore as HTMLElement).parentElement?.classList.add('show-all-text');
    },
  },
});
</script>
