import { RetailerFormType } from '@monolith/legacy/types/retailer-form-type';
import { SET_FORM_VALUES } from './mutations-types';
import { validatePromoCode } from '@monolith/legacy/services/api/promocode';
import { captureException } from '@core/plugins/sentry';
import { CountryIso } from '@core/types/country';

interface SignUpPromocode {
  code: string;
  brand: {
    name: string;
    url: string;
  };
  promocode: {
    amount: string;
  };
  amount: number;
}
interface SignUpFormValues {
  companyWasSelectedFromDropdown?: boolean;
  email?: string;
  taxNumber?: string;
  isPhoneValid?: boolean;
  isAnkorstart?: boolean;
  selectedCountryCode?: CountryIso;
  promoCode?: SignUpPromocode;
  categories?: string[];
}
interface SignUpFunnelState {
  formType?: keyof RetailerFormType;
  formValues: SignUpFormValues;
}

const initialState: SignUpFunnelState = {
  formType: null,
  formValues: {},
};

export default {
  namespaced: true,
  state: initialState,
  actions: {
    setCompanyWasSelectedFromDropdown: async ({ commit }, companyWasSelectedFromDropdown) => {
      commit('SET_FORM_VALUES', {
        companyWasSelectedFromDropdown,
      });
    },
    setPhoneValidity: async ({ commit }, isPhoneValid) => {
      commit('SET_FORM_VALUES', {
        isPhoneValid,
      });
    },
    setCountryCode: async ({ commit }, countryCode) => {
      commit('SET_FORM_VALUES', {
        selectedCountryCode: countryCode,
      });
    },
    setInitialPromocode: async ({ commit }, { promocode, brandName, liftAmount }) => {
      commit('SET_FORM_VALUES', {
        promoCode: {
          code: promocode,
          brand: {
            name: brandName,
          },
          amount: liftAmount,
        },
      });
      try {
        const response = await validatePromoCode(promocode);
        commit('SET_FORM_VALUES', {
          promoCode: {
            code: promocode,
            ...response,
          },
        });
      } catch (error) {
        commit('SET_FORM_VALUES', {
          promoCode: {
            code: promocode,
          },
        });
        captureException(error);
      }
    },
  },
  mutations: {
    [SET_FORM_VALUES](state: SignUpFunnelState, values: SignUpFormValues): void {
      state.formValues = { ...state.formValues, ...values };
    },
  },
  getters: {
    liftBrandName(state: SignUpFunnelState): string {
      return state.formValues.promoCode?.brand?.name;
    },
    liftBrandUrl(state: SignUpFunnelState): string {
      return state.formValues.promoCode?.brand?.url;
    },
    liftCodeAmount(state: SignUpFunnelState): string {
      return state.formValues.promoCode?.promocode?.amount;
    },
    liftPromoCode(state: SignUpFunnelState): string {
      return state.formValues.promoCode?.code;
    },
    formValues(state: SignUpFunnelState): SignUpFormValues {
      return state.formValues;
    },
    getBestCategory(state: SignUpFunnelState) {
      if (state.formValues.categories) {
        const categories = state.formValues.categories;
        const sortedCategories = Object.entries(categories).sort(([, value1], [, value2]) => value2.length - value1.length);
        if (sortedCategories[0][1].length === 1) {
          return sortedCategories[0][1][0];
        } else {
          return sortedCategories[0][0];
        }
      }
      return null;
    },
    isAnkorstart(state: SignUpFunnelState): boolean {
      return !!state.formValues.isAnkorstart;
    },
    email(state: SignUpFunnelState): string {
      return state.formValues.email;
    },
    taxNumber(state: SignUpFunnelState): string {
      return state.formValues.taxNumber;
    },
    selectedCountryCode(state: SignUpFunnelState): string {
      return state.formValues.selectedCountryCode || CountryIso.France;
    },
    companyWasSelectedFromDropdown(state: SignUpFunnelState): boolean {
      return !!state.formValues.companyWasSelectedFromDropdown;
    },
    isPhoneValid(state: SignUpFunnelState): boolean {
      return !!state.formValues.isPhoneValid;
    },
  },
};
