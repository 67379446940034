<template>
  <section
    class="lift-page-join"
    :style="{
      backgroundImage: `linear-gradient(to bottom, rgba(0,0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${brandImg})`,
    }"
  >
    <div class="lift-page-join__top">
      <img
        class="lift-page-join__logo"
        src="/images/logo/logo-white.svg"
        alt="ankorstore"
      />
      <img
        alt="ankorstore_cross"
        src="/images/cross.svg"
        class="lift-page-join__cross"
      />
      <AkParagraph class="lift-page-join__brand-name">
        {{ brandName }}
      </AkParagraph>
    </div>
    <div class="lift-page-join__content">
      <AkParagraph class="lift-page-join__text">
        {{ $t('Join ankorstore to shop {name} products', { name: brandName }) }}
      </AkParagraph>
    </div>
    <div class="lift-page-join__bottom">
      <AkButton
        :size="buttonSize"
        color="white"
        data-testid="join-register-button"
        class="lift-page-lift__button"
        @click="$emit('signup-click')"
      >
        {{ $t('Sign up to Shop') }}
      </AkButton>
    </div>
  </section>
</template>
<script lang="ts">
import { loginPopinMixin } from '@core/mixins/login-popin';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LiftJoin',
  //TODO: is this actually needed ? no usage of loginPopinMixin
  mixins: [loginPopinMixin],
  props: {
    brandName: {
      type: String,
      required: true,
    },
    brandImg: {
      type: String,
      required: true,
    },
    brandId: {
      type: Number,
      required: true,
    },
  },
  emits: ['signup-click'],
  computed: {
    buttonSize() {
      return window.innerWidth < 767 ? 'md' : 'xl';
    },
  },
});
</script>
<style lang="scss" scoped>
@import '@css/vue-import';
.lift-page-join {
  @apply ds-bg-primary ds-flex ds-flex-col ds-text-center ds-px-6 ds-py-5 md:ds-pt-7 md:ds-pb-9 ds-bg-no-repeat ds-bg-cover;
  filter: grayscale(50%);

  &__top {
    @apply ds-flex ds-items-center ds-justify-center ds-w-full ds-p-4;
  }

  &__brand-name {
    @apply ds-text-lg md:ds-text-4xl ds-font-basic ds-mb-0 ds-text-white ds-font-bold ds-hidden md:ds-block;
  }

  &__content {
    @apply ds-pt-7 ds-pb-0 md:ds-pt-8 md:ds-pb-6;
  }

  &__text {
    @apply ds-text-white ds-text-2xl md:ds-text-4xl ds-font-bold;
  }

  &__bottom {
    @apply ds-flex ds-items-center ds-justify-center ds-w-full ds-pt-2 ds-pb-6 md:ds-text-base;
  }

  &__logo {
    @apply ds-w-auto;
    max-height: 30px;
  }

  &__cross {
    @apply ds-mx-2 ds-hidden md:ds-block;
    width: 20px;
  }
}
</style>
