<template>
  <div>
    <form class="login-form">
      <div class="header">
        <p class="title">
          {{ $t('platform.2fa.form.title') }}
        </p>
      </div>
      <div class="content">
        <div>
          <AkInput
            v-model="code"
            :label="$t('platform.2fa.form.code.label')"
            type="text"
            autofocus
            required
            class="mt-1 block w-full"
          />
        </div>
        <div
          v-if="error"
          class="global-error"
        >
          {{ error }}
        </div>
        <div class="pwd">
          <AkButton
            class="forgot-pwd"
            link
            size="md"
            @click.prevent="$emit('change-to-two-factor-recovery')"
          >
            {{ $t('platform.2fa.switch.recovery') }}
          </AkButton>
        </div>
        <div class="ds-flex ds-items-center ds-justify-end ds-mt-4">
          <AkButton
            class="button--extended ds-mt-3"
            size="xl"
            @click="confirm2faWithCode"
          >
            {{ $t('Confirm') }}
          </AkButton>
        </div>
      </div>
    </form>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import { AkButton } from '@ankorstore/design-system';
import { confirmWithCode } from '@monolith/legacy/services/api/two-factor-authentification';

export default defineComponent({
  name: 'LoginTwoFactor',
  components: { AkButton },
  emits: ['change-to-two-factor-recovery'],
  setup() {
    const status = ref('login');
    const error = ref('');
    const code = ref('');

    const confirm2faWithCode = () => {
      confirmWithCode(code.value)
        .then(() => {
          error.value = '';
          window.location.href = '/';
        })
        .catch((err) => {
          error.value = err.response.data.message;
        });
    };

    return {
      confirm2faWithCode,
      code,
      error,
      status,
    };
  },
});
</script>
