import { BreakpointsState, CLIENT_DEVICE, BREAKPOINT_PX_LIMIT } from '@monolith/legacy/modules/design-system-candidates/breakpoints/types';

export function addMatchMediaQueryListener(
  mediaQueryList: MediaQueryList,
  changeCallback: (event: MediaQueryListEvent) => void
): void {
  typeof mediaQueryList.addEventListener === 'function'
    ? mediaQueryList.addEventListener('change', changeCallback)
    : mediaQueryList.addListener(changeCallback);
}

export function removeMatchMediaQueryListener(
  mediaQueryList: MediaQueryList,
  changeCallback: (event: MediaQueryListEvent) => void
): void {
  typeof mediaQueryList.removeEventListener === 'function'
    ? mediaQueryList.removeEventListener('change', changeCallback)
    : mediaQueryList.removeListener(changeCallback);
}

export function getMediaQueryStrings(): string[] {
  return [
    // mobile
    `(max-width: ${BREAKPOINT_PX_LIMIT.SM - 1}px)`,
    // xlMobile
    `(min-width: ${BREAKPOINT_PX_LIMIT.SM}px) and (max-width: ${BREAKPOINT_PX_LIMIT.MD - 1}px)`,
    // tablet
    `(min-width: ${BREAKPOINT_PX_LIMIT.MD}px) and (max-width: ${BREAKPOINT_PX_LIMIT.XL - 1}px)`,
    // xlTablet
    `(min-width: ${BREAKPOINT_PX_LIMIT.LG}px) and (max-width: ${BREAKPOINT_PX_LIMIT.XL - 1}px)`,
    // desktop
    `(min-width: ${BREAKPOINT_PX_LIMIT.XL}px)`,
    // xlDesktop
    `(min-width: ${BREAKPOINT_PX_LIMIT.XXL}px)`,
  ];
}

export function getMediaQueryLists(): MediaQueryList[] {
  return getMediaQueryStrings().map((query) => window.matchMedia(query));
}

export function getNewStateUpdated(
  event: MediaQueryListEvent,
  queries: MediaQueryList[],
  state: BreakpointsState
): BreakpointsState {
  const newState = { ...state };
  switch (event.media) {
    case queries[0].media:
      newState.isMobile = event.matches;
      break;
    case queries[1].media:
      newState.isXlMobile = event.matches;
      break;
    case queries[2].media:
      newState.isTablet = event.matches;
      break;
    case queries[3].media:
      newState.isXlTablet = event.matches;
      break;
    default:
    case queries[4].media:
      newState.isDesktop = event.matches;
      break;
    case queries[5].media:
      newState.isXlDesktop = event.matches;
      break;
  }
  return getNewStateUpdatedWithCurrentDevice(newState);
}

export function getNewStateUpdatedWithCurrentDevice(state: BreakpointsState): BreakpointsState {
  const newState = { ...state };
  if (state.isMobile || state.isXlMobile) {
    newState.currentDevice = CLIENT_DEVICE.mobile;
  } else if (state.isTablet || state.isXlTablet) {
    newState.currentDevice = CLIENT_DEVICE.tablet;
  } else if (state.isDesktop || state.isXlDesktop) {
    newState.currentDevice = CLIENT_DEVICE.desktop;
  }
  return newState;
}
