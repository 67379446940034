import AnalyticsEvent from '@monolith/legacy/services/analytics/events/analytics-event';
import { OrderIssueClickedProps, OrderIssueCloseTheClaimProps } from '@monolith/legacy/services/analytics/properties/order-issue-property';

export class OrderIssueClicked extends AnalyticsEvent {
  public readonly name = 'Order Issue Clicked';

  constructor(public properties: OrderIssueClickedProps) {
    super();
  }
}

export class OrderIssueCloseTheClaim extends AnalyticsEvent {
  public readonly name = 'Order Issue Close the claim';

  constructor(public properties: OrderIssueCloseTheClaimProps) {
    super();
  }
}
