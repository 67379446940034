import { SearchCategory } from '@monolith/legacy/types/api/category';

const getRootCategories = (categories: Record<string, SearchCategory>): SearchCategory[] => {
  return Object.values(categories).filter((category) => category.level === 0);
};

const getSubcategories = (categories: Record<string, SearchCategory>): SearchCategory[] => {
  return Object.values(categories).filter((category) => category.level === 1);
};

const getRootCategory = (rootCategories: SearchCategory[], subcategory: SearchCategory): SearchCategory => {
  return rootCategories.find((category) => category.children.includes(subcategory.id));
};

export const getRootCategoryById = (categories: Record<string, SearchCategory>, categoryId: number): SearchCategory => {
  const searchCategory = categories?.[categoryId];

  if (searchCategory?.level > 0) {
    return Object.values(categories).find((category) => category.children.includes(searchCategory.id));
  }

  return searchCategory;
};

export const getSubcategoryById = (categories: Record<string, SearchCategory>, categoryId: number): SearchCategory => {
  const category = categories?.[categoryId];

  return category?.level ? category : null;
};

export const getCategoriesHrefs = (path: string, categories: Record<string, SearchCategory>): Record<string | number, string> => {
  const categoriesHrefs = {};
  const rootCategories = getRootCategories(categories);
  const subcategories = getSubcategories(categories);

  rootCategories.forEach((category) => {
    categoriesHrefs[category.id] = createCategoryRoute(path, [category]);
  });

  subcategories.forEach((subcategory) => {
    const rootCategory = getRootCategory(rootCategories, subcategory);

    categoriesHrefs[subcategory.id] = createCategoryRoute(path, [rootCategory, subcategory]);
  });

  return categoriesHrefs;
};

export const createCategoryRoute = (path: string, categories: SearchCategory[]): string => {
  if (!categories || !categories.length) {
    return '';
  }

  const categoryPath = categories.map((category) => `${category.slug}-${category.id}`).join('/');

  return `${path}/${categoryPath}`;
};
