<template>
  <div
    class="cart-button"
    @mouseover="isRetailerCartPreviewShow = true"
    @mouseleave="isRetailerCartPreviewShow = false"
  >
    <RetailerCartPreview v-if="isRetailerCartPreviewShow && (isDesktop || isXlDesktop)" />
    <component
      :is="isClientRoutingEnabled() ? 'router-link' : 'a'"
      v-bind="cartButtonProperties()"
      :class="{
        'cart-button__link': true,
        'ds-relative': true,
      }"
      data-testid="retailerCartLink"
      @click="triggerLoadingFeedback"
    >
      <div class="cart-button__icon">
        <AkIcon
          class="cart-button__icon-item"
          symbol="bag"
        />
        <BadgeCountArticles />
      </div>
    </component>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import BadgeCountArticles from '@monolith/legacy/components/cart/badge-count-articles.vue';
import { useBreakpoints, withBreakpoints } from '@monolith/legacy/modules/design-system-candidates';
import Analytics from '@monolith/legacy/services/analytics';
import { HeaderCartClickEvent } from '@monolith/legacy/services/analytics/events/cart/cart-clicks-event';
import { defineComponent, ref } from 'vue';
import { hasRoute } from '@core/routes/router-util';
import RetailerCartPreview from '@monolith/legacy/components/header/retailer-cart-preview.vue';

export default defineComponent({
  name: 'RetailerCartButton',
  components: { BadgeCountArticles, RetailerCartPreview },
  mixins: [withBreakpoints],

  setup() {
    const isRetailerCartPreviewShow = ref(false);
    const { isDesktop, isXlDesktop } = useBreakpoints();
    return { isRetailerCartPreviewShow, isDesktop, isXlDesktop };
  },

  computed: {
    ...mapGetters('headerBadges', ['getProductBadgesCount']),
  },
  methods: {
    cartButtonProperties() {
      return this.isClientRoutingEnabled() ? { to: '/carts' } : { href: '/carts' };
    },
    isClientRoutingEnabled(): boolean {
      const regexCheckoutThankYouPage = /checkout\/(\d){1,}\/thanks/g;
      return hasRoute(this.$router, regexCheckoutThankYouPage);
    },
    triggerLoadingFeedback(): void {
      Analytics.track(new HeaderCartClickEvent(this.getProductBadgesCount));
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';
.cart-button {
  $root: &;
  &:hover :not(&__link--loading) &__icon,
  &:hover :not(&__link--loading) &__text {
    @apply ds-text-neutral-700;
  }
  &__icon {
    @apply ds-relative ds-bg-cold-800 ds-flex ds-items-center ds-rounded-full ds-py-1.5 ds-px-3 ds-transition-transform ds-duration-700 hover:ds-scale-105 hover:ds-bg-cold-900;
    &:deep(.icon-bag) {
      font-size: 1.25rem;
    }
    &-item {
      @apply ds-text-white ds-pr-1;
    }
  }
  @apply ds-relative ds-h-full ds-font-basic ds-whitespace-normal ds-m-0 xl:ds-ml-2 ds-pb-0 xl:ds-p-0 ds-flex ds-items-center;
}
:deep(.cart-button__icon .icon-bag) {
  font-size: 1.20rem;
}
</style>
