<template>
  <div
    v-if="afterDiscount"
    class="item-total-price"
  >
    <AkParagraph
      v-if="hasDiscount"
      :size="textSize"
      class="item-total-price__discount"
      data-testid="priceBeforeDiscount"
    >
      {{ formatPrice(beforeDiscount) }}
    </AkParagraph>
    <AkParagraph
      :class="{ 'item-total-price--discounted': hasDiscount }"
      :size="textSize"
      weight="bold"
      data-testid="actualPrice"
    >
      {{ formatPrice(afterDiscount)
      }}<span
        v-if="isUpperThanGlobalBrandDiscount"
        data-testid="extraDiscountLabel"
      >{{ t('global.asterisk') }}</span>
    </AkParagraph>
  </div>
</template>
<script lang="ts" setup>
import { AkParagraph } from '@ankorstore/design-system';
import { computed, PropType } from 'vue';
import { Amount } from '@core/types/amount';
import usePrice from '@core/composables/use-price';
import useI18n from '@core/composables/use-i18n';

defineOptions({
  name: 'ItemTotalPrice',
});
const props = defineProps({
  beforeDiscount: {
    type: Object as PropType<Amount>,
    required: true,
  },
  afterDiscount: {
    type: Object as PropType<Amount>,
    required: true,
  },
  textSize: {
    type: String as PropType<'base' | 'lg' | 'sm' | 'xs'>,
    default: 'base',
  },
  isUpperThanGlobalBrandDiscount: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const { formatPrice } = usePrice();
const { t } = useI18n();

const hasDiscount = computed<boolean>(() => {
  return !!props.beforeDiscount && props.beforeDiscount.amount > props.afterDiscount.amount;
});
</script>
<style lang="scss" scoped>
.item-total-price {
  @apply ds-flex ds-gap-4 sm:ds-flex-col lg:ds-flex-row ds-justify-end;
  &__discount {
    @apply ds-line-through;
  }
  &--discounted {
    @apply ds-text-discount-700;
  }
}
</style>
