import http from '@monolith/legacy/services/api/http';
import { BenefitsAnkorsorePlus } from '@monolith/legacy/types/touchpoints/benefits-ankorsore-plus';

export const getPerksProduct = async (productId: number): Promise<BenefitsAnkorsorePlus> => {
  const response = await http().get(`/api/internal/v1/ankorstoreplus/perks/product/${productId}`);

  return response.data?.data;
};

export const getPerksBrand = async (brandId: number): Promise<BenefitsAnkorsorePlus> => {
  const response = await http().get(`/api/internal/v1/ankorstoreplus/perks/brand/${brandId}`);

  return response.data?.data;
};
