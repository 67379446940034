import { defineComponent } from 'vue';
import Analytics from '@monolith/legacy/services/analytics';
import UserClick from '@monolith/legacy/services/analytics/events/user-click.event';
import { HelpCenterLinkLocation } from '@monolith/legacy/services/analytics/properties/help-center-link-property';
import { mapGetters } from 'vuex';

export const helpCenterLinkAnalyticsMixin = defineComponent({
  name: 'HelpCenterLinkAnalyticsMixin',
  data() {
    return {
      HelpCenterLinkLocation,
    };
  },
  computed: {
    ...mapGetters({
      loggedInRetailer: 'retailer',
      loggedInBrand: 'brand',
    }),
  },
  methods: {
    async trackHelpCenterLinkClicked(component: HelpCenterLinkLocation) {
      await Analytics.track(
        new UserClick({
          component: component,
          action: 'Help center link clicked',
          page_name: this.$route?.name ?? null,
          brand_id: this.loggedInBrand?.id ?? null,
          retailer_id: this.loggedInRetailer?.id ?? null,
        })
      );
    },
  },
});
