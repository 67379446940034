import http from '@monolith/legacy/services/api/http';
import { getInjectedContent } from '@monolith/legacy/services/injected-content';
import { DynamicMetaTagName } from '@monolith/legacy/services/initial-state';
import { Brand } from '@monolith/legacy/types/api/brand';

const state = {
  globals: {}, // Algolia keys, etc.
  brandHistory: {},
  brands: {},
};

const shopBrand = getInjectedContent<Brand>(DynamicMetaTagName.ShopBrands);
if (shopBrand) {
  state.brands[shopBrand.id] = shopBrand;
}

const getters = {
  getBrands: (state) => state.brands,
  brandById:
    (state, _getters, _rootState, { shouldApplyCountryRestrictions, userCountry }) =>
    (id) =>
      !state.brands[id]
        ? false
        : {
            ...state.brands[id],
            isOpenedInUserCountry: shouldApplyCountryRestrictions
              ? (state.brands[id]?.opened_countries?.includes(userCountry) ?? false)
              : true,
          },
};

const actions = {
  fetchBrand({ commit }, { brand_id }) {
    return http()
      .get(`/api/brands/${brand_id}`)
      .then(({ data }) => {
        const brand = data.data;
        commit('STORE_BRAND', {
          ...brand,
          ...{ isRefreshed: true },
        });
        return brand;
      });
  },
  storeBrand({ commit }, { brand }) {
    commit('STORE_BRAND', brand);
  },
  storeBrands({ commit }, { brands }) {
    commit('STORE_BRANDS', brands);
  },
};

const mutations = {
  SHOP_SET_GLOBALS(state, globals) {
    state.globals = globals;
  },
  STORE_BRAND(state, brand) {
    // https://vuejs.org/v2/guide/list.html#Caveats
    // Vue.set(state.brands, brand.id, brand);
    state.brands[brand.id] = brand;
  },
  STORE_BRANDS(state, brands) {
    // https://vuejs.org/v2/guide/list.html#Caveats
    state.brands = { ...brands, ...state.brands };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export { SHOP_STORE_ID } from './constants';
export type ShopState = typeof state;
export type ShopGetters = typeof getters;
export type ShopActions = typeof actions;
export type ShopMutations = typeof mutations;
