import { SearchCategory } from '@monolith/legacy/types/api/category';
import { RouteLocationNormalized } from 'vue-router';
import type { URLManager, URLParameterValues } from '../url-manager/types';
import * as URLManagerCategory from '../url-manager/url-manager-category';

const addParamsToUrl = (url, params) => {
  const regex = /^[^?#]+\?/g;
  return url.match(regex) ? `&${params}` : `?${params}`;
};

const { URLParameterKey, createFilterParam, getCategoriesIds, createAttributeParams, createSortBy } = URLManagerCategory;

export const URLManagerSearch: URLManager = {
  ...URLManagerCategory,
  getCategoriesFromRoute: (categories: Record<string, SearchCategory>, route: RouteLocationNormalized): SearchCategory[] => {
    if (!route.query?.categories) {
      return [];
    }
    const categoriesFromUrl = JSON.parse('[' + route.query?.categories + ']') ?? '';

    return categoriesFromUrl.map((id) => categories[id]);
  },
  createURL(parameterValues: URLParameterValues, basePath: string): string {
    if (!parameterValues) {
      return '';
    }

    let url = basePath ?? '';

    if (parameterValues.textQuery) {
      url += addParamsToUrl(url, `${URLParameterKey.textQuery}=${encodeURIComponent(parameterValues.textQuery)}`);
    }

    if (parameterValues.currentPageIndex > 0) {
      url += addParamsToUrl(url, `${URLParameterKey.Page}=${parameterValues.currentPageIndex + 1}`);
    }

    if (parameterValues.selectedCategories?.length > 0) {
      url += addParamsToUrl(url, `${URLParameterKey.categories}=${getCategoriesIds(parameterValues.selectedCategories)}`);
    }

    const hashParams = [
      createFilterParam(URLParameterKey.MadeIn, parameterValues.selectedMadeIns),
      createFilterParam(URLParameterKey.Values, parameterValues.selectedValues),
      createFilterParam(URLParameterKey.Discount, parameterValues.selectedDiscounts),
      createFilterParam(URLParameterKey.Brands, parameterValues.selectedFilterBrands),
      createFilterParam(URLParameterKey.Shipping, parameterValues.selectedShipping),
      createFilterParam(URLParameterKey.Margin, parameterValues.selectedMargins),
      createFilterParam(URLParameterKey.loyaltyOffer, parameterValues.selectedLoyaltyOffer),
      createFilterParam(
        URLParameterKey.Location,
        parameterValues.selectedLocations?.map((location) => ({
          id: location.id,
          label: location.id,
        }))
      ),
      createFilterParam(
        URLParameterKey.retailPriceRangeMin,
        typeof parameterValues.retailPriceRange?.min === 'number'
          ? [
              {
                id: parameterValues.retailPriceRange.min?.toString(),
                label: parameterValues.retailPriceRange.min?.toString(),
              },
            ]
          : []
      ),
      createFilterParam(
        URLParameterKey.retailPriceRangeMax,
        typeof parameterValues.retailPriceRange?.max === 'number'
          ? [
              {
                id: parameterValues.retailPriceRange.max?.toString(),
                label: parameterValues.retailPriceRange.max?.toString(),
              },
            ]
          : []
      ),
      createAttributeParams(parameterValues.attributes),
      createSortBy(parameterValues),
    ]
      .filter(Boolean)
      .join('&');

    if (hashParams.length > 0) {
      url += `#${hashParams}`;
    }
    return url;
  },
};
