import { Comparator } from '@monolith/legacy/types/logic';

export const compare = (comparator: Comparator, value1: any, value2: any) => {
  switch (comparator) {
    case Comparator.EQ:
      return value1 === value2; // Possibly toString these to avoid type errors
    case Comparator.NOT_EQ:
      return value1 !== value2;
    case Comparator.IN:
      return value2.includes(value1);
    case Comparator.NOT_IN:
      return !value2.includes(value1);
    case Comparator.SUPERIOR:
      return value1 > value2;
    case Comparator.INFERIOR:
      return value1 < value2;
  }
};
