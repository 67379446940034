import { Business } from '@monolith/legacy/types/api/business';
import { Brand } from '@monolith/legacy/types/api/brand';
import http from './http';

export function saveEoriNumber(eori_number: string): Promise<Business> {
  return http()
    .patch(`/api/me/eori`, { eori_number })
    .then((response) => response.data?.data);
}

export function saveStorepage(data: object): Promise<Brand> {
  return http()
    .put(`/api/me/brand/shop-page`, data)
    .then((response) => response?.data?.data);
}

export function getMe(): Promise<unknown> {
  return http()
    .get('/api/me')
    .then((response) => response.data?.data);
}
