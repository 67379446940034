import { ref, onMounted, onUnmounted, Ref } from 'vue';
import {
  addMatchMediaQueryListener,
  removeMatchMediaQueryListener,
  getMediaQueryLists,
  getNewStateUpdated,
  getNewStateUpdatedWithCurrentDevice,
} from '@monolith/legacy/modules/design-system-candidates/breakpoints/utils';
import { BreakpointsState, CLIENT_DEVICE } from '@monolith/legacy/modules/design-system-candidates/breakpoints/types';

type RefState = {
  isMobile: Ref<boolean>;
  isXlMobile: Ref<boolean>;
  isTablet: Ref<boolean>;
  isXlTablet: Ref<boolean>;
  isDesktop: Ref<boolean>;
  isXlDesktop: Ref<boolean>;
  currentDevice: Ref<CLIENT_DEVICE>;
};

function unpackStateRefs(refState: RefState): BreakpointsState {
  return Object.entries(refState).reduce((acc, [key, ref]) => {
    acc[key] = ref.value;
    return acc;
  }, {}) as BreakpointsState;
}

function packStateRefs(state: BreakpointsState, refState: RefState) {
  Object.entries(state).forEach(([key, value]) => {
    refState[key].value = value;
  });
}

export default function useBreakpoints() {
  const queries = getMediaQueryLists();

  const state = {
    isMobile: ref<boolean>(queries[0].matches),
    isXlMobile: ref<boolean>(queries[1].matches),
    isTablet: ref<boolean>(queries[2].matches),
    isXlTablet: ref<boolean>(queries[3].matches),
    isDesktop: ref<boolean>(queries[4].matches),
    isXlDesktop: ref<boolean>(queries[5].matches),
    currentDevice: ref<CLIENT_DEVICE>(null),
  };

  packStateRefs(getNewStateUpdatedWithCurrentDevice(unpackStateRefs(state)), state);

  function onMediaQueryChange(event) {
    packStateRefs(getNewStateUpdated(event, queries, unpackStateRefs(state)), state);
  }

  onMounted(() => {
    queries.forEach((query) => {
      addMatchMediaQueryListener(query, onMediaQueryChange);
    });
  });

  onUnmounted(() =>
    queries.forEach((query) => {
      removeMatchMediaQueryListener(query, onMediaQueryChange);
    })
  );

  return state;
}
