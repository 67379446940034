import { BrandRetailerRelation, JsonApiResponse, UpdateBrandRetailerRelationPayload } from '@monolith/legacy/types/fulfillment';
import http from '@monolith/legacy/services/api/http';
import { deserialize } from '@monolith/legacy/services/utils/jsonapi-parser';

export const getBrandRetailerRelation = async (retailerId: string): Promise<JsonApiResponse<BrandRetailerRelation>> => {
  const response = await http().get(`/api/internal/v1/brands/retailers/${retailerId}`);
  return deserialize(response.data);
};

export const updateBrandRetailerRelation = async (retailerId: string, payload: UpdateBrandRetailerRelationPayload) => {
  return http().patch(`/api/internal/v1/brands/retailers/${retailerId}`, payload);
};
