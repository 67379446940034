import { computed, ComputedRef } from 'vue';
import { useBreakpoints } from '@monolith/legacy/modules/design-system-candidates';

export const CATALOG_MOBILE_TILES_PER_ROW = 2;
export const CATALOG_TABLET_TILES_PER_ROW = 3;
export const CATALOG_DESKTOP_TILES_PER_ROW = 4;

export const useCatalogTilesPerRow = (): ComputedRef<number> => {
  const { isDesktop, isXlTablet } = useBreakpoints();
  return computed(() => {
    if (isDesktop.value) {
      return CATALOG_DESKTOP_TILES_PER_ROW;
    }
    if (isXlTablet.value) {
      return CATALOG_TABLET_TILES_PER_ROW;
    }
    return CATALOG_MOBILE_TILES_PER_ROW;
  });
};
