<template>
  <div class="ds-flex ds-gap-4 ds-flex-wrap">
    <QuickFilter
      v-for="filter in filtersWithASlot"
      :key="filter.name + filter.type"
      :title="filter.title"
      :sub-title="`${filter.count}`"
      :selected="filter.count > 0"
      :data-testid="filter.uid"
    >
      <template #badgeBefore>
        <AnkorstorePlusBadge v-if="filter.badgeType === 'aks_plus'" />
      </template>
      <template #title>
        <slot
          :name="`${filter.type}Title`"
          :filter="filter"
        ></slot>
      </template>
      <template #badgeAfter>
        <img
          v-if="filter.badgeType === 'aks_plus'"
          class="perk__logo"
          :src="filter.count > 0 ? '/images/logo/ankorstore-plus-white.svg' : '/images/logo/ankorstore-plus.svg'"
          :alt="$t('ankorstorePlus.program.name')"
        />
      </template>
      <slot
        :name="filter.type"
        :filter="filter"
      ></slot>
    </QuickFilter>
  </div>
</template>

<script setup lang="ts">
import { computed, useSlots } from 'vue';
import QuickFilter from './quick-filter.vue';
import { Filter } from './types';
import AnkorstorePlusBadge from '@monolith/legacy/modules/promote-engage/subscription-program/UI/ankorstore-plus-badge.vue';

const props = defineProps<{ filters: Filter[] }>();
const slots = useSlots();

const filtersWithASlot = computed(() => props.filters.filter((filter) => filter.type in slots || `${filter.type}Title` in slots));
</script>

<style scoped lang="scss">
.perk__logo {
  height: 0.75rem;
}
</style>
