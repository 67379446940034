import AnalyticsEvent from '@monolith/legacy/services/analytics/events/analytics-event';
import { NavigationMethodType } from './enums';

export class CategoriesNavigationEvent extends AnalyticsEvent {
  public readonly name = 'Navigation categories';

  constructor(
    public readonly properties: {
      id_category: string;
      name_category: string;
      path: string;
      navigation_method: NavigationMethodType;
      navigation_level: string;
    }
  ) {
    super();
  }
}
