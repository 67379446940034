<!-- eslint-disable vue/no-useless-v-bind -->
<template>
  <div>
    <ul
      class="ak-breadcrumb"
      :class="separator"
    >
      <li
        v-for="(link, index) in start"
        :key="index"
      >
        <a
          v-if="link.notRouterLink"
          :href="url(link)"
          @click="followLink($event, link)"
        >{{ link.title }}</a>
        <router-link
          v-else
          :to="url(link)"
        >
          {{ link.title }}
        </router-link>
      </li>

      <li v-if="last">
        {{ last }}
      </li>

      <li v-else>
        {{ end.title }}
      </li>
    </ul>

    <component
      :is="'script'"
      v-if="showGoogleStructureData"
      type="application/ld+json"
    >
      {{ JSON.stringify(breadcrumbListStructureData) }}
    </component>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { BreadcrumbLink } from '@monolith/legacy/types/breadcrumb';

export default defineComponent({
  name: 'Breadcrumb',
  props: {
    links: {
      type: Array as () => BreadcrumbLink[],
      required: true,
    },
    separator: {
      type: String,
      default: 'chevron',
    },
    showGoogleStructureData: {
      type: Boolean,
      default: false,
    },
    last: {
      type: String,
      default: null,
    },
  },
  computed: {
    breadcrumbListStructureData() {
      const breadcrumbList = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [],
      };
      //@ts-ignore
      breadcrumbList.itemListElement = this.getBreadcrumbLinks();

      return breadcrumbList;
    },
    start(): BreadcrumbLink[] {
      return this.last ? this?.links : this?.links.filter((_link, index) => index < this.links.length - 1);
    },
    end(): BreadcrumbLink {
      return this?.links[this?.links.length - 1];
    },
  },
  methods: {
    followLink(event: Event, link): void {
      if ('click' in link) {
        link.click();
        event.preventDefault();
        return;
      }
    },
    url: (link): string => {
      if (link.to) {
        //@ts-ignore
        return { name: link.to, hash: link.hash ? `#${link.hash}` : undefined };
      } else if (link.href) {
        return link.href;
      }

      return '';
    },
    getBreadcrumbLinks() {
      //TODO: why return object or array and assign it to an array ?
      if (!this.links) {
        return {};
      }

      const breadcrumbLinks = [];

      this.links.forEach((link, index) => {
        if ((link.href || link.to) && link.title) {
          const href = link.href ? link.href : link.to;
          breadcrumbLinks.push({
            '@type': 'ListItem',
            position: index + 1,
            name: link.title,
            item: window.location.origin + href,
          });
        }
      });

      return breadcrumbLinks;
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';

.ak-breadcrumb {
  display: flex;
  margin-bottom: 0;
  flex-wrap: wrap;
  padding: 30px 0;

  li {
    @apply ds-text-neutral-500;

    a {
      @apply ds-text-primary;
      text-decoration: none;
    }

    &:not(:last-child):after {
      content: '>';
      display: inline-block;
      margin: 0 4px;
      @apply ds-text-primary;
    }
  }

  &.chevron {
    li:not(:last-child):after {
      content: '>';
    }
  }

  &.slash {
    li:not(:last-child):after {
      content: '/';
    }
  }
}
</style>
