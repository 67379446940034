import { Amount } from "@core/types/amount";
import { Locale } from "@core/types/base";
import { getInjectedContent } from "./injected-content";
import { GlobalMetaTagName } from "@monolith/legacy/services/initial-state";

export type CountryConfig = {
  tax_number_label: string;
  tax_number_length: number;
  business_identifier_type: string;
  locale: Locale;
  sole_trader_number_label: string;
  sole_trader_label: string;
  eori_customs_site_link: string;
};

export interface GlobalConfig {
  free_shipping_minimum_amount: Amount;
  checkout_shipping_fees_amount: Amount;
  minimum_order_amount: Amount;
  r2b_bonus: Amount;
  prefix: string;
  countries_config: Record<string, CountryConfig>;
  language_config: {
    language_label: string;
    language_flag_emoji: string;
    prismic_locale: string;
    brand_terms_of_service_link: string;
    retailer_terms_of_service_link: string;
    privacy_policy_link: string;
    cookies_policy_link: string;
    legal_notice_link: string;
    contact_form_link: string;
    r2b_iframe: string;
    lift_day_link_brand: string;
    lift_day_link: string;
    lift_day_link_get_ready: string;
    lift_day_link_shop_now: string;
    packaging_requirements_link: string;
    brand_accelerator_program: string;
    faq_brand_accelerator_program: string;
    faq_best_price_guarantee: string;
    brand_eori_assistance_link: string;
    api_integration_link: string;
    retailer_eori_assistance_link: string;
    faq_link: string;
    link_widget: string;
    google_customer_review: string;
    faq_holiday_mode_link: string;
  };
  aegis_enabled: boolean;
  new_welcome_offer_amount: Amount;
  new_welcome_offer_minimum_checkout_amount: Amount;
  see_you_soon_offer_amount: Amount;
  opened_locales: string[];
  FAQLink: string;
  brand_page_nb_items_per_page: number;
  category_page_nb_brands_per_page: number;
  braze?: {
    api_key: string;
    base_url: string;
  };
  ravelin: {
    api_public_key: string;
    cookie_domain: string;
  };
}

export const getGlobalConfig = (): GlobalConfig => getInjectedContent(GlobalMetaTagName.GlobalConfig);
