import http from "@monolith/legacy/services/api/http";
import { NativeAdClickOrigin, TracClickPayload } from "@bc/advertisement/domain";
import { handleAdsHttpError } from "@bc/advertisement/domain/handle-http-error";
import { getBrowserId } from "@monolith/legacy/services/features";

/**
 * Get Native Ad click origin depending on click event target.
 * @param target
 * @returns {NativeAdClickOrigin}
 */
export const getNativeAdClickOrigin = (target: Element): NativeAdClickOrigin => {
  switch (true) {
    case Boolean(target.closest(`.${NativeAdClickOrigin.BrandName}`)):
      return NativeAdClickOrigin.BrandName;
    case Boolean(target.closest(`.${NativeAdClickOrigin.QuickAddToCart}`)):
      return NativeAdClickOrigin.QuickAddToCart;
    case Boolean(target.closest(`.${NativeAdClickOrigin.AddToFavourites}`)):
      return NativeAdClickOrigin.AddToFavourites;
    case Boolean(target.closest(`.${NativeAdClickOrigin.WholesalePrice}`)):
      return NativeAdClickOrigin.WholesalePrice;
    default:
      return NativeAdClickOrigin.Product;
  }
};

/**
 * Call Aardvark log endpoint to track the click of a native ad.
 * @param nativeAdId
 * @param origin
 * @returns {Promise<void>}
 */
export const trackClickOfNativeAds = async (nativeAdId: string, origin = NativeAdClickOrigin.Product): Promise<void> => {
  const tracClickPayload: TracClickPayload = {
    data: {
      type: 'serve-logs',
      attributes: {
        type: 'CLICK',
        origin,
      },
      relationships: {
        item: {
          data: {
            id: nativeAdId,
            type: 'items',
          },
        },
      },
    },
  };

  try {
    await http().post(`/api/aardvark/v1/serve/log?_fp=${getBrowserId()}`, tracClickPayload);
  } catch (error) {
    if (http().isAxiosError(error)) {
      handleAdsHttpError(error, 'track-click-of-native-ads');
    }

    return Promise.resolve();
  }
};
