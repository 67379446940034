<template>
  <div :class="[{ 'ds-border-b ds-border-solid ds-border-neutral-600': showSectionsExpander }]">
    <div :class="['ds-flex ds-justify-between ds-items-start', { 'ds-pt-3': showSectionsExpander }]">
      <div
        v-if="showSectionsExpander"
        class="ds-text-lg ds-font-bold ds-mb-5"
      >
        {{ $t('Parcel reception') }}
      </div>
      <AkButton
        v-if="showSectionsExpander"
        class="parcel-reception__expand-btn"
        outlined
        data-testid="expandParcelReceptionBtn"
        @click="expandContent"
      >
        <AkIcon
          :symbol="expanded ? 'chevron-up' : 'chevron-down'"
          size="md"
        />
      </AkButton>
    </div>
    <div
      v-show="isExpanded"
      :class="showSectionsExpander ? 'ds-mb-6' : ''"
    >
      <UploadPackaging
        with-switcher
        @set-checked="setUploadPackagingChecked"
        @set-loader="setLoader"
      />
      <ParcelReservation
        v-show="showParcelReservation"
        @set-checked="setParcelReservationChecked"
        @set-loader="setLoader"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import UploadPackaging from '@monolith/legacy/components/account/orders/order/order-retailer-reject/components/upload-packaging.vue';
import ParcelReservation from '@monolith/legacy/components/account/orders/order/order-retailer-reject/components/parcel-reservation.vue';
import { CaseReasonStep, ParcelIssueOption } from '@monolith/legacy/types/order-retailer-rejection';
import { orderIssueFileUploadingMixin } from '@monolith/legacy/components/account/orders/order/order-retailer-reject/mixins/order-issue-file-uploading';

export default defineComponent({
  name: 'ParcelReception',
  components: {
    UploadPackaging,
    ParcelReservation,
  },
  mixins: [orderIssueFileUploadingMixin],
  emits: ['set-checked', 'set-loader'],
  data() {
    return {
      expanded: false,
      uploadPackagingReady: false,
      parcelReservationReady: false,
    };
  },
  computed: {
    ...(mapGetters('orderRetailerRejection', ['getCurrentStep', 'isParcelIssueOptionsChecked', 'showSectionsExpander']) as {
      getCurrentStep: () => { key: CaseReasonStep };
      isParcelIssueOptionsChecked: () => (option: ParcelIssueOption) => boolean;
      showSectionsExpander: () => boolean;
    }),
    isExpanded(): boolean {
      return this.getCurrentStep.key === CaseReasonStep.Summary ? this.expanded : true;
    },
    showParcelReservation(): boolean {
      return this.uploadPackagingReady && this.isParcelIssueOptionsChecked(ParcelIssueOption.ParcelExternalIssues);
    },
  },
  methods: {
    expandContent() {
      this.expanded = !this.expanded;
    },
    setUploadPackagingChecked(value: boolean): void {
      this.uploadPackagingReady = value;
      this.handleChecked();
    },
    setParcelReservationChecked(value: boolean): void {
      this.parcelReservationReady = value;
      this.handleChecked();
    },
    handleChecked(): void {
      const checked = this.uploadPackagingReady && (this.showParcelReservation ? this.parcelReservationReady : true);
      this.$emit('set-checked', checked);
    },
    setLoader(value: boolean): void {
      this.$emit('set-loader', value);
    },
  },
});
</script>

<style scoped lang="scss">
.parcel-reception__expand-btn.ak-button.ak-button--outlined {
  @apply ds-mr-2 ds-pr-0;
  background-color: transparent !important;
  box-shadow: none !important;
}
</style>
