<template>
  <div>
    <LabelInfo
      v-if="showComponent('LabelInfo')"
      :title="$t('What is a wrong item?')"
      :content="
        $t(
          'A wrong item does not correspond to the SKU that you ordered. A wrong item can also be an item of a different color or size from the one that you selected on your order.'
        )
      "
    />
    <ProductsCheckList v-show="showComponent('ProductsCheckList')" />
    <ProductsList
      v-if="getSelectedProducts.length"
      v-show="showComponent('ProductsList')"
      ref="productsListRef"
      :is-active="showComponent('ProductsList')"
      :with-attachments="true"
      :with-description="true"
      @set-loader="setLoader"
      @products-validation="productsDataChanged"
      @all-products-removed="backToCheckList"
    />
    <CaseActions
      :next-step-ready="nextStepReady()"
      :show-back-button="showBackButton"
      @set-loader="setLoader"
      @submit="$emit('submit')"
    />
  </div>
</template>

<script lang="ts">
import BaseItemsCase from '@monolith/legacy/components/account/orders/order/order-retailer-reject/cases/base-items-case.vue';
import LabelInfo from '@monolith/legacy/components/account/orders/order/order-retailer-reject/components/label-info.vue';
import ProductsCheckList from '@monolith/legacy/components/account/orders/order/order-retailer-reject/components/products-check-list.vue';
import ProductsList from '@monolith/legacy/components/account/orders/order/order-retailer-reject/components/products-list.vue';
import CaseActions from '@monolith/legacy/components/account/orders/order/order-retailer-reject/components/case-actions.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'WrongItems',
  components: {
    LabelInfo,
    ProductsList,
    ProductsCheckList,
    CaseActions,
  },
  extends: BaseItemsCase,
  emits: ['submit'],
});
</script>
