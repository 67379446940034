/**
 * NOTE: This module is going to be deprecated soon in favor of the new carts and checkout modules.
 * @deprecated
 */
export const SET_TAX_NUMBER = 'SET_TAX_NUMBER';
export const SET_TAX_NUMBER_VALUE = 'SET_TAX_NUMBER_VALUE';
export const TOGGLE_SKIP_TAX_NUMBER = 'TOGGLE_SKIP_TAX_NUMBER';
export const SET_BUSINESS_VALIDATION_STEP = 'SET_BUSINESS_VALIDATION_STEP';
export const SET_HOKODO_COMPANY_ID = 'SET_HOKODO_COMPANY_ID';
export const TOGGLE_HOKODO_LOADING = 'TOGGLE_HOKODO_LOADING';
export const SET_CART_BUTTON_LOADING = 'SET_CART_BUTTON_LOADING';
export const SET_TRACKING = 'SET_TRACKING';
export const CART_MINIMUM_REACHED = 'CART_MINIMUM_REACHED';
export const CART_MINIMUM_HAS_GONE = 'CART_MINIMUM_HAS_GONE';
export const TOGGLE_CART_SAVE_FOR_LATER = 'TOGGLE_CART_SAVE_FOR_LATER';
export const REMOVE_LOCAL_CART = 'REMOVE_LOCAL_CART';
export const REFRESH_CHECKOUT = 'REFRESH_CHECKOUT';
export const CHANGE_ITEM_QUANTITY = 'CHANGE_ITEM_QUANTITY';
export const ADD_ITEM = 'ADD_ITEM';
export const CALCULATE_BRANDS_TOTALS = 'CALCULATE_BRANDS_TOTALS';
export const CALCULATE_CHECKOUT = 'CALCULATE_CHECKOUT';
export const CHANGE_VAT_EXEMPTION = 'CHANGE_VAT_EXEMPTION';
export const CART_API_CART_CALL_INCREMENT = 'CART_API_CART_CALL_INCREMENT';
export const CART_API_CART_CALL_DECREMENT = 'CART_API_CART_CALL_DECREMENT';
export const DELETE_ITEM = 'DELETE_ITEM';
export const CLEAR_TAX_NUMBER_ERRORS = 'CLEAR_TAX_NUMBER_ERRORS';
export const CLEAR_COMPANY_ID_ERRORS = 'CLEAR_COMPANY_ID_ERRORS';
export const SET_COMPANY_ID_ERRORS = 'SET_COMPANY_ID_ERRORS';
export const SET_TAX_NUMBER_ERRORS = 'SET_TAX_NUMBER_ERRORS';
export const SET_CART_LOADING = 'SET_CART_LOADING';
export const UPDATE_BULK = 'CART_UPDATE_BULK';
export const RESET_BULK_UPDATES = 'RESET_BULK_UPDATES';
export const SET_TOTALS_LOADING = 'SET_TOTALS_LOADING';
export const SET_RECARGO_ERRORS = 'SET_RECARGO_ERRORS';
export const CHANGE_RECARGO_ENABLED = 'CHANGE_RECARGO_ENABLED';
export const CLEAR_RECARGO_ERRORS = 'CLEAR_RECARGO_ERRORS';
export const CHANGE_EORI = 'CHANGE_EORI';
export const CLEAR_EORI_ERRORS = 'CLEAR_EORI_ERRORS';
export const SET_EORI_SUCCESS = 'SET_EORI_SUCCESS';
export const SET_EORI_ERRORS = 'SET_EORI_ERRORS';
export const SET_LAST_PROMOCODE_CALL_RESULT = 'SET_LAST_PROMOCODE_CALL_RESULT';
export const SET_CHECKOUT_START_LOADING_TIME = 'SET_CHECKOUT_START_LOADING_TIME';
// New cart mutations
export const SET_BRANDS_DISCOUNTS = 'SET_BRANDS_DISCOUNTS';
export const SET_SAVED_FOR_LATER = 'SET_SAVED_FOR_LATER';
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';
export const SET_ORDER_SUMMARY = 'SET_ORDER_SUMMARY';
export const SET_ITEMS_COUNT = 'SET_ITEMS_COUNT';
export const MOVE_CART = 'MOVE_CART';
