import { ProductCollection } from '@monolith/legacy/types/account/product-collections';
import { LanguageIsoCode } from '@core/types/base';
import { ManagedLanguages } from '@monolith/legacy/types/global';

const faqBaseUrl = 'https://knowledgecommunity.force.com/faq/s/article/How-do-I-create-a-collection';
const availableFaqLanguages = ['de', 'en', 'es', 'fr', 'it'];

export const getProductCollectionsCustomFilter = (productCollection: ProductCollection) => {
  if (!productCollection) {
    return '';
  }

  return `aks_custom__product_collection=${productCollection.id}`;
};

export const getProductCollectionsFaqUrl = (managedLanguages: ManagedLanguages, language: LanguageIsoCode) => {
  const urlObject = new URL(faqBaseUrl);

  const pickedLanguage: keyof ManagedLanguages = availableFaqLanguages.includes(language) ? language : 'en';
  const faqLanguage = managedLanguages[pickedLanguage].faq_locale;
  urlObject.searchParams.set('language', faqLanguage);

  return urlObject.toString();
};
