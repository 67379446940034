import { ShipmentCarrier } from './shipping/shipment-carrier';
import { OrderIssue } from '@monolith/legacy/types/order-issue';
import { ShipmentMethod } from '@monolith/legacy/types/shipping/shipment-method';
import { Amount } from '@core/types/amount';
import { Currency } from 'dinero.js';
import { OrderStatus } from './order-status';
import { AkBadge } from '@ankorstore/design-system';
import { BillingItemType } from '@monolith/legacy/types/billing-item';
import { CancellationReasonOptionsValue } from '@monolith/legacy/types/orders';
import { ShippingEstimation, ShippingMethod, ShippingParcel } from '@monolith/legacy/types/api/shipping';
import { AnkorstoreLabelFormat } from '@monolith/legacy/types/shipping';
import { CountryIsoCode } from '@core/types/country';

type AkBadgeColor = ReturnType<typeof AkBadge.props.color.type>;

export interface OrderDetail {
  type: string;
  id: number;
  checkout_id: number;
  status: OrderStatus | OrderStatuses;
  status_details: StatusDetails;
  brandRetailerRelationship?: BrandRetailerRelationship;
  is_vat_shifted: boolean;
  order_updates?: null[] | null;
  metadata: {
    shippingMethod: string;
    address: {
      countryCode: CountryIsoCode;
      postalCode: string;
      city: string;
      addressLine: string;
    };
    contactPerson: {
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
    };
  };
  order_updates_summary?: {
    item_updates: [];
    old_total_amount: Amount;
    old_total_amount_vat: Amount;
    old_total_amount_with_vat: Amount;
    old_recargo_amount: Amount;
    old_retailer_net_amount: Amount;
  };
  opened_retailer_cancellation_request: boolean;
  shipping_lead_time: ShippingLeadTime;
  shipping_is_international: boolean;
  shipping_provider: ShipmentCarrier;
  brand_net_amount: Amount;
  can_be_shipped_with_shipping_labels: boolean;
  shipping_label_error?: null;
  shipping_method?: ShipmentMethod;
  temporary_parcels?: null[] | null;
  last_shipping_label_quote?: null;
  shipping_label?: null;
  show_alcohol_warning: boolean;
  show_manual_invoice_download_warning?: boolean;
  public_app_synchronization: OrderPublicAppSynchronization;
  vat_application_details?: null;
  invoices?: InvoicesEntity[] | null;
  created_at: string;
  submitted_at: string;
  updated_at: string;
  brand: Brand;
  retailer: Retailer;
  items?: OrderItemEntity[] | null;
  total_amount_with_vat: Amount;
  total_amount_vat: Amount;
  total_amount: Amount;
  billing_items?: BillingItemsEntity[] | null;
  shipping_address: Address;
  billing_address: Address;
  available_reject_reasons: AvailableRejectReason[];
  tracked_packages?: TrackedPackage[];
  tags?: string[];
  priority_tag?: PriorityTag;
  item_count?: number;
  tracking_link?: string;
  will_add_tracking_later?: boolean;
  order_issue?: OrderIssue;
  order_issue_visibility: {
    brand: object;
  };
  send_order_issue_to_brand_enabled: [];
  shipped_at: string;
  brand_reject_type?: string;
  has_reverted_shipping_labels?: boolean;
}

export enum BrandRetailerRelationshipType {
  LIFT = 'lift_order',
  NETWORK = 'network',
}

interface BrandRetailerRelationship {
  data: { type: BrandRetailerRelationshipType; id: string };
}

export interface PriorityTag {
  name: PriorityStatuses | string;
  is_active?: boolean;
  isActive?: boolean;
}

export interface StatusDetails {
  key: string;
  title: string;
  paragraph: string;
}
export interface ShippingLeadTime {
  minimum_estimated_shipping_date: string;
  maximum_estimated_shipping_date: string;
  minimum_estimated_shipping_day: string;
  maximum_estimated_shipping_day: string;
}

/** Invoice shape associated with legacy OrderDetails payload */
export interface InvoicesEntity {
  type: string;
  generated: boolean;
  title: string;
  number: string;
  path: string;
}

/** Invoice shape associated with new master order payload */
export interface Invoice {
  isGenerated: boolean;
  title: string;
  number: string;
  path: string;
}

export interface Brand {
  type: string;
  id: number;
  accepted_at: string;
  active: boolean;
  name: string;
  link: string;
  instagram_followers?: null;
  instagram_username: string;
  properties?: null[] | null;
  coming_soon: boolean;
  minimum: Amount;
  images: Images;
  tags?: null[] | null;
  story: string;
  story_auto_translate: boolean;
  opened_countries?: string[] | null;
  original_language: string;
  original_language_label: string;
  first_accepted_at: string;
  shipping_lead_time_details: ShippingLeadTimeDetails;
  business_events?: null[] | null;
  monthly_follow_up: boolean;
  addresses: Addresses;
  display_address: DisplayAddress;
  country: Country;
  is_product_variants_enabled: boolean;
  lift_promocode: LiftPromocode;
  business: Business;
  currency: Currency;
  stripe: Stripe;
  pending_actions: PendingActions;
  allowed_vat_rates?: number[] | null;
  observed_shipping_lead_time: number;
  made_in?: string[] | null;
  fulfillment_enabled?: boolean;
}
export interface Images {
  squared: string;
  rounded: string;
  large: string;
}

export type ShippingLeadTimeDetails = Omit<LeadTimeDetails, 'type'> & {
  type?: 'shipping_lead_time';
};

export interface LeadTimeDetails {
  type: string;
  name: string;
  minimum: number;
  maximum: number;
}
export interface Addresses {
  billing: Address;
  shipping: Address;
}
export interface Address {
  type: string;
  id?: number | null;
  name: string;
  organisation_name: string;
  city: string;
  postal_code: string;
  street: string;
  country: Country1;
}
export interface Country1 {
  type: string;
  id: number;
  name: string;
  iso_code: string;
  calling_code: number;
  opened_to_retailers: boolean;
  opened_to_brands: boolean;
}
export interface DisplayAddress {
  city: string;
  country: string;
  region: string;
  postal_code: string;
}
export interface Country {
  id: number;
  created_at: string;
  updated_at: string;
  iso_code: string;
  calling_code: number;
  opened_to_retailers: boolean;
  opened_to_brands: boolean;
  display_made_in: boolean;
  name: Name;
}
export interface Name {
  da: string;
  de: string;
  en: string;
  es: string;
  fi: string;
  fr: string;
  it: string;
  nl: string;
  sv: string;
}
export interface LiftPromocode {
  id: number;
  code: string;
  expires_at: string;
  amount: Amount;
  minimum_order: Amount;
  minimum_checkout: Amount;
  retailer_id?: null;
  brand_id: number;
}
export interface Business {
  type: string;
  id: number;
  country: Country1;
  user_first_name: string;
  user_last_name: string;
  addresses?: Address[] | null;
  company_name: string;
  tax_number: string;
  vat_number: string;
  business_identifier: string;
  eori_number: string;
  vat_exemption: boolean;
  above_intracomm_threshold: boolean;
  vat_info_updated: boolean;
  check_vat_on_ship_from_country: boolean;
}
export interface Stripe {
  account_created: boolean;
  status: string;
}
export interface PendingActions {
  orders: number;
  referrals: number;
  payments: number;
  events: number;
}
export interface Retailer {
  type: string;
  id: number;
  name: string;
  url?: null;
  can_get_wholesale_price: boolean;
  country: Country1;
  postal_code: string;
  city: string;
  new_welcome_promocode_active: boolean;
  see_you_soon_promocode_active: boolean;
  business: Business1;
  phone_number: string;
  email: string;
  shop_type?: string;
  subscription_status?: boolean;
}

export interface Business1 {
  type: string;
  id: number;
  country: Country1;
  user_first_name: string;
  user_last_name: string;
  addresses?: Address[] | null;
  company_name?: null;
  tax_number: string;
  vat_number: string;
  business_identifier: string;
  eori_number: string;
  vat_exemption: boolean;
  above_intracomm_threshold: boolean;
  vat_info_updated: boolean;
}

export interface OrderItemEntity {
  type: string;
  id: string;
  orderedProduct: {
    data: {
      images: string[];
      fulfillableId: string;
      name: string;
      options: any[];
      pageUrl: string;
      id: string;
      type: string;
      sku: string;
    };
  };
  internalId: number;
  batchQuantity: number;
  unitQuantity: number;
  unitPrice: Amount;
  totalPrice: Amount;
  oldAmount: Amount;
  oldMultipliedQuantity: number;
  oldQuantity: number;
}

export interface Option {
  type: string;
  id: number;
  name: string;
  sku: string;
  unit_multiplier: number;
  created_at: string;
  variation?: {
    name: string;
  };
  out_of_stock: boolean;
  ian: string;
}

export interface Product {
  type: string;
  id: number;
  link: string;
  created_at: string;
  name: string;
  unit_multiplier: number;
  retail_price: Amount;
  wholesale_price: Amount;
  discount_rate: number;
  original_wholesale_price: Amount;
  images?: string[] | null;
  discount_event_price?: null;
}
export interface BillingItemsEntity {
  type: string;
  id: number;
  amount: Amount;
  amount_vat: Amount;
  amount_with_vat: Amount;
  vat_rate: number;
  billing_item_type: string;
  display_vat: boolean;
  vat_shifted: boolean;
  label: string;
  vat_label: string;
}
export interface AvailableRejectReason {
  rejectType: string;
  rejectTypeLabel: string;
}
export interface OrderPublicAppSynchronization {
  platform_name: string;
  error?: string;
  type?: string;
  platformName?: string;
}
export interface TrackedPackage {
  tracking_link: string;
}

export interface EditedOrderItems {
  [key: number]: OrderItemEntity[];
}

export enum PriorityStatuses {
  PENDING = 'pending',
  SOON = 'soon',
  LATE = 'late',
}

export enum SyncedColumnValues {
  SYNCED = 'SYNCED',
  SYNC_ERROR = 'SYNC_ERROR',
  NOT_SYNCED = 'NOT_SYNCED',
}

export enum TrackingLinkColumnValues {
  TRACK_ORDER = 'TRACK_ORDER',
  ADD_TRACKING = 'ADD_TRACKING',
  NONE = 'NONE',
}

export enum OrdersTableColumns {
  CUSTOMER = 'customer',
  PRIORITY = 'priority',
  STATUS = 'status',
  DEADLINE = 'deadline',
  REFERENCE = 'reference',
  CREATED_AT = 'created_at',
  NET_AMOUNT = 'net_amount',
  TRACKING_LINK = 'tracking_link',
  STOCK_ORIGIN = 'stock_origin',
  ORDER_TYPE = 'order_type',
  SYNCH = 'synch',
  CHEVRON_ICON = 'chevron_icon',
  METADATA = 'metadata',
}

export enum OrderStatuses {
  PENDING_VALIDATION = 'pending_validation',
  TO_SHIP = 'to_ship',
  PENDING_RECEPTION = 'pending_reception',
  PENDING_SHIPMENT = 'pending_shipment',
  REJECTED = 'rejected',
  RECEPTION_REFUSED = 'reception_refused',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  BRAND_PAID = 'brand_paid',
  FULFILLMENT_REQUESTED = 'fulfillment_requested',
  SHIPPED = 'shipped',
  PREPARED = 'prepared',
  ALL_STATUSES = 'all',
}

export interface StatusColumnData {
  statusCopy: string;
  subStatusCopy: string;
  name: string;
}

export interface StockOriginColumn {
  externalOrderType: string;
  orderUuid: string;
  stockOrigin: StockOriginOptions;
  shouldRetrieveFulfillability: boolean;
}

export interface OrderTableRow {
  [OrdersTableColumns.CUSTOMER]: string;
  [OrdersTableColumns.PRIORITY]: PriorityColumnData;
  [OrdersTableColumns.STATUS]: StatusColumnData;
  [OrdersTableColumns.DEADLINE]: string;
  [OrdersTableColumns.REFERENCE]: string;
  [OrdersTableColumns.CREATED_AT]: string;
  [OrdersTableColumns.NET_AMOUNT]: string;
  [OrdersTableColumns.TRACKING_LINK]: TrackingLinkColumnData;
  [OrdersTableColumns.STOCK_ORIGIN]: StockOriginColumn;
  [OrdersTableColumns.ORDER_TYPE]: OrderTypeColumnData;
  [OrdersTableColumns.SYNCH]: SyncedColumnData;
  [OrdersTableColumns.CHEVRON_ICON]: string;
}

export interface PriorityColumnData {
  type: string;
  color: AkBadgeColor;
  label: string;
}

export interface OrderTypeColumnData {
  type: OrderTypeOptions;
  label: string;
  isOrderpay: boolean;
}

export interface SyncedColumnData {
  type: SyncedColumnValues;
  platform: string;
}

export interface TrackingLinkColumnData {
  label: TrackingLinkColumnValues;
  value: string;
}

export type OrdersColumnData = {
  name: OrdersTableColumns;
  label?: string;
};

export type PickupTimeSlot = '09-15' | '11-17' | '09-17';

export type LocalDateFormat = {
  key: string;
  value: PickupTimeSlot;
  label: string;
};

export enum StockOriginOptions {
  FULFILLMENT_CENTER = 'fulfillmentCenter',
  DIRECT_SHIPPING = 'directShipping',
}
export enum OrderTypeOptions {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export enum ExternalOrderTypeOptions {
  ORDERPAY = 'order_pay',
  NAFO = 'nafo',
}

export interface ItemUpdates {
  itemId: string;
  oldQuantity: number;
  oldMultipliedQuantity: number;
  oldAmount: Amount;
}

export enum ItemsType {
  ORDER_ITEMS = 'order-items',
}

export enum NafoCustomerType {
  Business = 'business',
  Consumer = 'consumer',
}

export interface NafoCustomer {
  firstName: string;
  lastName: string;
  street: string;
  postalCode: string;
  city: string;
  countryCode: string;
  businessName: string;
  email: string;
  phoneNumber: string;
  id: string;
  additionalAddress?: string;
  type: NafoCustomerType;
}

export interface MasterOrderBillingItem {
  id: number;
  type: BillingItemType;
  label: string;
  vatLabel: string;
  isVatShifted: boolean;
  percentage: number;
  isSpecialDeal: boolean;
  displayVat: true;
  amount: Amount;
  amountVat: Amount;
  amountWithVat: Amount;
  francoSetting: {
    francoAmount: Amount;
    francoFlatFeeAmount: Amount;
  };
}

export interface MasterOrderStatus {
  name: string;
  shortTitle: string;
  title: string;
  details: string;
  subStatus: string;
}

export interface MasterOrder {
  type: 'internal' | 'external';
  createdAt: string;
  reference: string;
  customReference: any;
  masterStatus: string;
  status: MasterOrderStatus;
  internalId: number;
  totals: Amount[];
  brand: Brand;
  updates: any;
  taxes: any[];
  priorityTag: { name: string };
  vat: {
    isShifted: boolean;
    applicationDetails: any;
  };
  deadlines: {
    toAccept: string;
    toShip: string;
    toActOnIssue: string;
  };
  synchronizations: any[];
  invoices?: Invoice[];
  isDiscovery?: boolean;
  shipping?: {
    shippingProvider: string;
    isInternational: boolean;
    hasShippingLabelGenerationError: boolean;
    hasRevertedShippingLabels: boolean;
    fees: any;
    shippingAddress: {
      address: {
        countryCode: string;
        postalCode: string;
        city: string;
        addressLine: string;
      };
      company: string;
      contactPerson: {
        firstName: string;
        lastName: string;
        fullName: string;
        phoneNumber: string;
        email: string;
      };
    };
    availableShippingMethods: ShippingMethod[];
    shipmentEstimation?: ShippingEstimation;
    shipment: {
      shippedAt: string;
      labelPrintingFormat: AnkorstoreLabelFormat;
      trackedPackagesMergedLabelUrl: string;
      trackedPackages: {
        labelUrl: string;
        parcel: ShippingParcel;
      }[];
      shipFromAddress: {
        address: {
          countryCode: CountryIsoCode;
          postalCode: string;
          city: string;
          addressLine: string;
        };
        company: string;
        contactPerson: {
          firstName: string;
          lastName: string;
          fullName: string;
          phoneNumber: string;
          email: string;
        };
      };
      pickup?: {
        date: string;
        readyTime: string;
        closeTime: string;
      };
      labelRevertReasons: { actionType: string; actionReason: string }[];
    };
  };
  tracking?: {
    trackingNumber: string;
    trackingLink: string;
    status: string;
  };
  issue?: any;
  billing: {
    billingItems: MasterOrderBillingItem[];
  };
  retailer: Partial<Retailer>;
  rejection: {
    brandRejectType: any;
    availableBrandRejectReasons: {
      rejectType: CancellationReasonOptionsValue;
      rejectTypeLabel: string;
    }[];
  };
  meta?: {
    showManualInvoiceDownloadWarning: boolean;
    showAlcoholWarning: boolean;
    casesVisibleToBrand: string[];
    casesAllowedToBeSentToBrand: { [K in string]: string };
  };
}

export enum OrderAutomationValidation {
  MANUAL = 'manual',
  AUTOMATED = 'automated',
  NOT_SET = 'not_set',
}

export enum OrderSources {
  PREORDER = 'preorder',
}

/**
 * We have different messages to show to the user depending on the context
 */
export enum OrderAutomationModalContext {
  FULFILLMENT = 'fulfillment',
  MYSELF = 'myself',
  ANKORSTORE = 'ankorstore',
}

export const ORDER_AUTOMATION_MODAL_CONTEXT_SESSION_KEY = 'ORDER_AUTOMATION_MODAL_CONTEXT';
