<template>
  <div>
    <div
      v-if="isEnabled(FeatureFlag.LegacyMapsWidget)"
      :class="{ 'ds-hidden': !isAutocompleteEnabled }"
    >
      <div class="required-field form-label-group">
        <input
          id="register_address"
          ref="register_address"
          v-model.trim="currentModel.address"
          type="text"
          class="form-control hide-placeholder-on-focus"
          data-testid="register-address-input"
          name="address"
          :class="[
            vuelidateFieldClassName(state['address']),
            {
              'has-autocomplete': isEnabled(FeatureFlag.RegisterGeodataVerification),
            },
          ]"
          autocomplete="address"
          :placeholder="$t('Shop address')"
          @input="addAddressListeners"
          @focusout="(event) => onFocusout(event)"
        >
        <label
          class="ds-pointer-events-none"
          data-testid="register-address-label"
        >{{ $t('Address') }}</label>
      </div>
    </div>
    <AkPlacesAutocomplete
      v-else-if="isAutocompleteEnabled"
      :is-revamp="isRevamp"
      :error="getError(question.alias)"
      :model="currentModel.address"
      :country-code="selectedCountryCode"
      @update-place="fillInAddress"
      @change="onExtraContentChange"
      @address-not-found="onAddressNotFound"
    />
    <template v-if="isRevamp">
      <div data-testid="registerAddressRevamp">
        <div :class="{ 'ds-hidden': isAutocompleteEnabled }">
          <AkInput
            id="register_street"
            ref="register_street"
            v-model="currentModel.street"
            :errors="getError('street')"
            data-testid="register-address-street-input"
            name="street"
            :label="$t('Street')"
            :placeholder="$t('Street')"
            @change="({ target }) => onExtraContentChange(target.value, 'street')"
          />
        </div>
        <div class="ds-mt-5">
          <div class="ds-flex ds-flex-col md:ds-flex-row ds-gap-4">
            <div class="item-wrapper ds-flex-1">
              <AkInput
                v-model="currentModel.postal_code"
                data-testid="register-address-postcode-input"
                name="postCode"
                :errors="errors?.postal_code?.[0] ?? getError('postal_code')"
                :label="$t('Postcode')"
                :placeholder="$t('Postcode')"
                @change="({ target }) => onExtraContentChange(target.value, 'postal_code')"
              />
            </div>
            <div class="item-wrapper ds-flex-1">
              <AkInput
                v-model="currentModel.city"
                data-testid="register-address-city-input"
                name="city"
                :errors="getError('city')"
                :label="$t('City')"
                :placeholder="$t('City')"
                @change="({ target }) => onExtraContentChange(target.value, 'city')"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <span
        v-if="state[question.alias] && state[question.alias].$dirty && state[question.alias].required.$invalid"
        data-testid="google-autocomplete-question-error"
        class="error-message"
      >{{ $t('This field is required') }}</span>
      <div :class="['required-field', vuelidateFieldClassName(state['street']), { 'ds-hidden': isAutocompleteEnabled }]">
        <div class="form-label-group">
          <input
            id="register_street"
            ref="register_street"
            v-model.lazy.trim="currentModel.street"
            type="text"
            class="form-control hide-placeholder-on-focus"
            data-testid="register-address-street-input"
            name="street"
            :class="vuelidateFieldClassName(state['street'])"
            :placeholder="$t('Street')"
            @change="({ target }) => onExtraContentChange(target['value'], 'street')"
          >
          <label class="ds-pointer-events-none">{{ $t('Street') }}</label>
        </div>
        <span
          v-show="state['street'] && state['street'].$dirty && !state['street'].required.$invalid"
          data-testid="google-autocomplete-street-error"
          class="error-message"
        >{{ $t('This field is required') }}</span>
      </div>
      <div class="ds-mt-5">
        <div class="ds-flex ds-flex-wrap">
          <div class="address-field">
            <div
              class="required-field"
              :class="vuelidateFieldClassName(state['postal_code'])"
            >
              <div class="form-label-group ds-mr-2">
                <input
                  v-model.trim="currentModel.postal_code"
                  type="text"
                  class="form-control hide-placeholder-on-focus"
                  data-testid="register-address-postcode-input"
                  name="postCode"
                  :class="vuelidateFieldClassName(state['postal_code'])"
                  autocomplete="postCode"
                  :placeholder="$t('Postcode')"
                  @change="({ target }) => onExtraContentChange(target['value'], 'postal_code')"
                >
                <label class="ds-pointer-events-none">{{ $t('Postcode') }}</label>
              </div>
              <span
                v-if="errors.postal_code"
                class="error-message"
              >
                {{ errors.postal_code[0] }}
              </span>
              <span
                v-if="state['postal_code'] && state['postal_code'].$dirty && state['postal_code'].required.$invalid"
                data-testid="google-autocomplete-postal-code-error"
                class="error-message"
              >{{ $t('This field is required') }}</span>
            </div>
          </div>
          <div class="address-field">
            <div
              class="required-field"
              :class="vuelidateFieldClassName(state['city'])"
            >
              <div class="form-label-group">
                <input
                  v-model.trim="currentModel.city"
                  type="text"
                  class="form-control hide-placeholder-on-focus"
                  data-testid="register-address-city-input"
                  name="city"
                  :class="vuelidateFieldClassName(state['city'])"
                  autocomplete="city"
                  :placeholder="$t('City')"
                  @change="({ target }) => onExtraContentChange(target['value'], 'city')"
                >
                <label class="ds-pointer-events-none">{{ $t('City') }}</label>
              </div>
              <span
                v-if="state['city'] && state['city'].$dirty && state['city'].required.$invalid"
                data-testid="google-autocomplete-city-error"
                class="error-message"
              >{{ $t('This field is required') }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { vuelidateFieldClassName } from '@core/utilities/fieldClassName';
import { State } from '@monolith/legacy/types/state';
import { FeatureFlag, isEnabled } from '@monolith/legacy/services/features';
import { AsyncScript, initScript } from '@monolith/legacy/modules/shared/services/load-async-script';
import { mapGetters } from 'vuex';
import AkPlacesAutocomplete from '@monolith/legacy/components/sign-up/common/ak-places-autocomplete.vue';
import { Question } from '@monolith/legacy/types/question';
import { toAddress } from '@monolith/legacy/modules/shared/utils/tooling';
import Analytics from '@monolith/legacy/services/analytics';
import SignUpEvent from '@monolith/legacy/services/analytics/events/sign-up/sign-up-event';
import { SignUpEventNames } from '@monolith/legacy/services/analytics/events/sign-up/common/event-names';
import { Address } from '@monolith/legacy/types/address';

export default defineComponent({
  name: 'GoogleAutocomplete',
  components: {
    AkPlacesAutocomplete,
  },
  props: {
    state: {
      type: Object as () => State,
      required: true,
    },
    model: {
      type: [Object, Array],
      required: true,
    },
    globalModel: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
      default: null,
    },
    question: {
      type: Object as () => Question,
      required: true,
    },
    isRevamp: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['change'],
  data() {
    return {
      currentModel: {
        city: '',
        street: '',
        postal_code: '',
        address: '',
      },
      isAutocompleteEnabled: false,
      isAddressUpdated: false,
      autocomplete: null,
      autocompleteListener: null,
      FeatureFlag,
    };
  },
  computed: {
    ...mapGetters('signUp', ['selectedCountryCode', 'companyWasSelectedFromDropdown']),
    isGeodataAvailable(): boolean {
      return (
        isEnabled(FeatureFlag.RegisterGeodataVerification) &&
        (!isEnabled(FeatureFlag.LegacyMapsWidget) ||
          (isEnabled(FeatureFlag.LegacyMapsWidget) && !!window.google?.maps?.places && !!this.$refs.register_address))
      );
    },
  },
  watch: {
    selectedCountryCode() {
      if (isEnabled(FeatureFlag.LegacyMapsWidget)) {
        this.autocomplete.setOptions({
          componentRestrictions: { country: this.selectedCountryCode },
        });
      }
    },
    globalModel: {
      handler(data) {
        const { city, postal_code, street, address } = data;
        this.currentModel.city = city?.content || this.currentModel.city;
        this.currentModel.postal_code = postal_code?.content || this.currentModel.postal_code;
        this.currentModel.street = street?.content || this.currentModel.street;
        this.currentModel.address = address?.content || this.currentModel.address;
      },
      deep: true,
    },
  },
  async mounted() {
    initScript(AsyncScript.GOOGLE_MAP, this.checkAutocompleteInit);
  },
  unmounted() {
    if (this.isAutocompleteEnabled && this.isGeodataAvailable) {
      this.destroyAutocomplete();
    }
  },
  methods: {
    getError(alias: string) {
      return this.state[alias]?.$error ? this.$t('This field is required') : '';
    },
    onExtraContentChange(value, alias): void {
      this.trackAddressChange();

      this.$emit('change', alias, {
        answer_id: '',
        content: value,
      });
    },
    trackAddressChange() {
      if (this.companyWasSelectedFromDropdown && !this.isAddressUpdated) {
        Analytics.track(
          new SignUpEvent(SignUpEventNames.SuggestedCompanyAddressChangedEvent, {
            type: 'Changed',
          })
        );
        this.isAddressUpdated = true;
      }
    },
    checkAutocompleteInit() {
      this.isAutocompleteEnabled = this.isGeodataAvailable;
      if (this.isAutocompleteEnabled && isEnabled(FeatureFlag.LegacyMapsWidget)) {
        const countries = [this.selectedCountryCode];
        const addressRef = this.$refs.register_address;
        if (!window.google?.maps?.places) {
          return;
        }
        this.autocomplete = new window.google.maps.places.Autocomplete(addressRef, {
          componentRestrictions: { country: countries },
          fields: ['formatted_address', 'address_components', 'geometry'],
          types: ['address'],
        });

        this.autocompleteListener = this.autocomplete.addListener('place_changed', this.fillInAddressLegacy);
      }
    },
    fillInAddressLegacy() {
      const place = this.autocomplete.getPlace();
      this.fillInAddress(place);
    },
    fillInAddress(addressPlace): void {
      const place = this.autocomplete?.getPlace() || addressPlace;
      let address = {
        postal_code: this.currentModel.postal_code + '',
        city: this.currentModel.city,
        street: this.currentModel.street,
      };
      if (place?.isAutocompleteEvent) {
        this.currentModel.street = this.currentModel.address;
        return;
      }
      if (!place?.address_components) {
        address.street = this.currentModel.address;
        place.formatted_address = this.currentModel.address;
      } else {
        this.currentModel.postal_code = '';
        this.currentModel.city = '';
        address = toAddress(place, (countryShortName) => {
          return this.$store.state.global.countries.countries[countryShortName];
        }) as Pick<Address, 'postal_code' | 'city' | 'street'>;
      }

      this.$emit('change', 'address', {
        content: place.formatted_address,
      });
      this.$emit('change', 'postal_code', {
        content: address.postal_code,
      });
      this.$emit('change', 'city', {
        content: address.city,
      });
      this.$emit('change', 'street', {
        content: address.street,
      });
    },
    destroyAutocomplete() {
      this.isAutocompleteEnabled = false;
      if (isEnabled(FeatureFlag.LegacyMapsWidget)) {
        this.autocompleteListener?.remove();
        document.querySelector('.pac-container')?.remove();
        window.google?.maps?.event.clearInstanceListeners(this.autocomplete);
      }
    },
    onFocusout(target?) {
      this.autocomplete.set('place', {
        isAutocompleteEvent: !target?.relatedTarget,
      });
    },
    onAddressNotFound(value: string) {
      if (!this.currentModel.street || (value && !value.includes(this.currentModel.street))) {
        this.currentModel.street = value;
      }
      this.destroyAutocomplete();
    },
    addAddressListeners() {
      const contentElement = document.querySelector('.pac-container');
      const addressNotFoundElement = document.getElementById('address_not_found');
      if (!contentElement || !!addressNotFoundElement) {
        return;
      }
      const contentText = this.$t('I don’t find my address');
      contentElement.insertAdjacentHTML(
        'beforeend',
        `<div id="address_not_found" data-testid="useAnyways" class="pac-item" ><span class=“pac-item-query">${contentText}</span></div>`
      );
      document.getElementById('address_not_found').addEventListener('mousedown', () => {
        this.onAddressNotFoundClick();
      });
    },
    onAddressNotFoundClick() {
      this.onFocusout();
      this.state['address'].$reset();
      this.state['street'].$reset();
      this.state['city'].$reset();
      this.state['postal_code'].$reset();
      this.onExtraContentChange(this.currentModel.street, 'street');
      this.onExtraContentChange(this.currentModel.address, 'address');
      this.destroyAutocomplete();
    },
    vuelidateFieldClassName,
    isEnabled,
  },
});
</script>

<style scoped lang="scss">
.address-field {
  flex: 0 0 50%;
  max-width: 50%;
}
</style>
