import AnalyticsEvent from '@monolith/legacy/services/analytics/events/analytics-event';

const CATEGORY = 'click_on_cart';
const ACTION_NAME = 'user_click';

enum ClickAction {
  CLICK_NORMAL_CART = 'click_normal_cart',
}

class CartClicksEvent extends AnalyticsEvent {
  public readonly name = ACTION_NAME;
  public properties: {
    category: string;
    action: ClickAction;
    url: string;
    items: number;
  };

  constructor(action: ClickAction, items: number) {
    super();

    this.properties = {
      category: CATEGORY,
      url: window.location.pathname,
      action,
      items,
    };
  }
}

export class HeaderCartClickEvent extends CartClicksEvent {
  constructor(items: number) {
    super(ClickAction.CLICK_NORMAL_CART, items);
  }
}
