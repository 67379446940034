<template>
  <div
    v-if="isVisibleHeaderPill"
    class="header-discount-pill"
  >
    <div v-if="showPill">
      <Pill
        :content="headerPill.pill"
        data-testid="pill"
      />
    </div>
    <div>
      <ProgramPill
        v-if="showAnkorstorePlus"
        class="ds-mr-6"
        :program-name-route="routeAnkorstorePlus"
        data-testid="programPill"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted, unref } from 'vue';

import ProgramPill from '@monolith/legacy/modules/promote-engage/subscription-program/program-pill.vue';
import Pill from '@monolith/legacy/modules/promote-engage/pills/pill.vue';

import { useStore } from '@core/composables/use-store';

import { HeaderPills } from '@monolith/legacy/types/api/pill';

import { getPill } from '@monolith/legacy/modules/promote-engage/services/pill';

export default defineComponent({
  name: 'HeaderPill',
  components: {
    ProgramPill,
    Pill,
  },
  setup() {
    const store = useStore();

    const userIsRetailer = computed(() => store.getters['userIsRetailer']);

    const headerPill = ref<HeaderPills>(null);

    const showAnkorstorePlus = computed((): boolean => {
      return !!unref(headerPill) && 'ankorstore_plus_opt_in' in unref(headerPill);
    });

    const showPill = computed((): boolean => {
      return !!unref(headerPill) && !!unref(headerPill).pill;
    });

    const routeAnkorstorePlus = computed((): string => {
      if (!!unref(headerPill) && !unref(headerPill).ankorstore_plus_opt_in) {
        return 'ankorstore-plus-signup';
      } else if (!!unref(headerPill) && unref(headerPill).ankorstore_plus_opt_in) {
        return 'ankorstore-plus';
      } else {
        return '';
      }
    });

    const isVisibleHeaderPill = computed((): boolean => {
      return !!unref(headerPill);
    });

    onMounted(async () => {
      if (unref(userIsRetailer)) {
        headerPill.value = await getPill();
      }
    });

    return {
      headerPill,
      isVisibleHeaderPill,
      routeAnkorstorePlus,
      showAnkorstorePlus,
      showPill,
    };
  },
});
</script>
<style lang="scss" scoped>
.header-discount-pill {
  @apply ds-flex ds-gap-6 ds-items-center;
}
</style>
