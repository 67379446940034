import { getGlobalConfig } from '@monolith/legacy/services/global-config';
import { LanguageIsoCode } from '@core/types/base';

export const getPrefix = () => getGlobalConfig().prefix;

export enum IndexName {
  Brands = 'brands',
  Products = 'products',
  ProductsDe = 'products_de',
  BlackFriday = 'brands_black_friday',
  QuerySuggestions = 'suggestions',
  BrandsSortByCategoryRanking = 'brands_sort_by_category_',
}

const getBrands = (): string => `${getPrefix()}${IndexName.Brands}`;

const getBrandsSortByCategoryRanking = (categoryId: number | string): string =>
  `${getPrefix()}${IndexName.BrandsSortByCategoryRanking}${categoryId}`;

const getProducts = (): string => `${getPrefix()}${IndexName.Products}`;

const getProductsDe = (): string => `${getPrefix()}${IndexName.ProductsDe}`;

const getBlackFriday = (): string => `${getPrefix()}${IndexName.BlackFriday}`;

const isProdOrPreprod = (): boolean => getPrefix().startsWith('preprod') || getPrefix().startsWith('production');

export const getPrefixedIndexName = ({ lang, indexName }: { lang: LanguageIsoCode; indexName: IndexName }): string =>
  isProdOrPreprod() ? `${getIndexName(indexName)}_${lang}` : getSimpleIndexName(indexName);

export const getSimpleIndexName = (indexName: IndexName): string =>
  process.env.NODE_ENV === 'development' ? `sandbox_core-discovery_${indexName}` : `${getPrefix()}${indexName}`;

export const getIndexName = (indexName: IndexName | string, categoryId?: number | string): string => {
  const indexNames: Record<IndexName, () => string> = {
    [IndexName.Brands]: getBrands,
    [IndexName.Products]: getProducts,
    [IndexName.ProductsDe]: getProductsDe,
    [IndexName.BlackFriday]: getBlackFriday,
    [IndexName.QuerySuggestions]: () => getSimpleIndexName(IndexName.QuerySuggestions),
    [IndexName.BrandsSortByCategoryRanking]: () => getBrandsSortByCategoryRanking(categoryId),
  };

  const getIndexName = indexNames[indexName] ?? (() => `${getPrefix()}${indexName}`);

  return getIndexName();
};

export default getIndexName;
