<script lang="ts">
import { computed, defineComponent, PropType, ref, toRefs } from 'vue';
import FilterWrapper from './components/filter-wrapper.vue';
import CheckboxFilterTree from './components/checkbox-filter-tree.vue';
import { Location, ParentItem } from '@bc/discovery/domain/catalog';
import { useCountryItems } from './composables/use-country-items';
import { useSortedLocations } from './composables/use-sorted-locations';
import { useLocalSearch } from './composables/use-local-search';
import { uniqBy } from 'lodash-es';

export default defineComponent({
  name: 'LocationFilter',
  components: {
    FilterWrapper,
    CheckboxFilterTree,
  },
  props: {
    locations: {
      type: Array as PropType<Location[]>,
      required: true,
    },
    locationFacetCounts: {
      type: Object as PropType<Record<string, number>>,
      required: true,
      default: () => ({}),
    },
    message: {
      type: String,
      default: null,
    },
    selectedLocations: {
      type: Array as PropType<Location[]>,
      required: true,
    },
    searchBarEnabled: {
      type: Boolean,
      default: false,
    },
    grid: {
      type: Boolean,
      default: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    withSeparator: Boolean,
    showCounts: {
      type: Boolean,
      default: false,
    },
    showHeading: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['selectedLocationsChanged', 'filterExpanded'],
  setup(props, { emit }) {
    const { locations, locationFacetCounts, selectedLocations } = toRefs(props);
    const locationMap = computed(() => new Map(locations.value.map((location) => [location.id, location])));
    const sortedLocations = useSortedLocations(locations, locationFacetCounts);
    const query = ref('');
    const locationSearch = useLocalSearch(locations, query, (location) => location.name);
    const isSelected = (id: string) => Boolean(selectedLocations.value?.find((location) => id === location.id));
    const locationSearchResults = computed<ParentItem[]>(() =>
      locationSearch.value.map((location) => ({
        id: location.id,
        label: location.name,
        selected: isSelected(location.id),
        children: [],
        count: location.count,
      }))
    );
    const { countryItems } = useCountryItems(sortedLocations, locationFacetCounts, selectedLocations);
    const itemsToDisplay = computed(() => (query.value ? locationSearchResults.value : countryItems.value));
    const locationNo = computed(() => selectedLocations.value.length);

    const findAncestors = (location: Location): Location[] => {
      const parent = locationMap.value.get(location.parent);
      return parent ? [parent, ...findAncestors(parent)] : [];
    };

    const updateSelectedItems = (updatedItems: ParentItem[]) => {
      const newSelectedLocations = updatedItems
        .flatMap((item) => [item, ...item.children])
        .filter((location) => location.selected)
        .map((location) => locationMap.value.get(location.id))
        .flatMap((item) => [item, ...findAncestors(item)]);

      if (query.value) {
        newSelectedLocations.push(...selectedLocations.value);
        query.value = '';
      }

      emit(
        'selectedLocationsChanged',
        uniqBy(newSelectedLocations, (location) => {
          return location.id;
        })
      );
    };

    const notifyExpanded = () => {
      emit('filterExpanded', props.expanded);
    };

    return {
      itemsToDisplay,
      locationNo,
      notifyExpanded,
      query,
      updateSelectedItems,
    };
  },
});
</script>

<template>
  <FilterWrapper :with-separator="withSeparator">
    <div
      v-if="showHeading"
      class="checkbox-filter-heading"
      @click="notifyExpanded"
    >
      <div class="ds-flex ds-items-center ds-mb-1">
        <AkHeading3 class="filter--title">
          {{ $t('Brand location') }}
        </AkHeading3>
        <div
          v-if="locationNo > 0"
          class="md:ds-invisible"
        >
          <AkPill
            class="ds-ml-2"
            :content="locationNo.toString()"
            color="accent-700"
            active
            text-color="accent-alt"
          />
        </div>
        <AkIcon
          class="md:ds-invisible ds-ml-auto ds-transition-all ds-duration-200 ds-ease-in-out"
          :class="{
            'ds-rotate-180': expanded,
          }"
          symbol="chevron-down"
          size="md"
        />
      </div>
      <AkParagraph
        v-if="message"
        size="xs"
        class="ds-mb-5"
      >
        {{ message }}
      </AkParagraph>
    </div>
    <div
      :class="{
        'location-filter ds-overflow-hidden md:ds-mb-5 ds-max-h-full ds-transition-all ds-duration-300 ds-ease-in-out': true,
        'filter--open ds-mb-5': expanded,
      }"
    >
      <AkInput
        v-if="searchBarEnabled"
        v-model="query"
        icon-left="search"
        type="search"
        :placeholder="$t('Search for country or region')"
      />

      <CheckboxFilterTree
        :items="itemsToDisplay"
        :grid="grid"
        :show-counts="showCounts"
        @input="updateSelectedItems"
      />
    </div>
  </FilterWrapper>
</template>

<style lang="scss" scoped>
@import '@css/vue-import';
.filter {
  &--open {
    max-height: 3000px;
  }
}
.filters-modal__dialog .location-filter {
  @apply ds-pr-4;
}
</style>
