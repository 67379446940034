<template>
  <div>
    <form
      class="register-form"
      :class="[
        {
          'has-autocomplete': $isEnabled(FeatureFlag.RegisterGeodataVerification),
        },
      ]"
      @submit.prevent="onSubmit"
    >
      <div class="content">
        <LoaderIcon
          v-if="loadingQuestions"
          small
          class="mb-3"
        />
        <template v-if="questions.length">
          <div
            v-for="question in questions"
            :key="`question-item-${question.id}`"
          >
            <div
              :key="question.alias"
              class="ds-mb-5"
              data-testid="question-item"
            >
              <QuestionItem
                :ref="question.question_type"
                :question="question"
                :state="v$.model"
                :model="model"
                :errors="errors"
                :is-revamp="true"
                @model-update="updateModel"
                @model-input="updateModel"
              />
            </div>
          </div>
        </template>
        <div>
          <div
            v-if="errors.global"
            class="error-message"
            data-testid="global-error"
          >
            {{ errors.global }}
          </div>
          <div
            v-if="errors.email"
            class="error-message"
            data-testid="global-error"
          >
            {{ errors.email[0] }}
          </div>
          <AkButton
            v-if="!sending"
            class="button--extended ds-mt-3"
            size="xl"
            data-testid="register-submit-button"
            type="submit"
          >
            {{ $t('Next') }}
          </AkButton>
          <LoaderIcon v-else />
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Analytics from '@monolith/legacy/services/analytics';
import { FeatureFlag, isEnabled } from '@monolith/legacy/services/features';
import { AkButton } from '@ankorstore/design-system';
import { SignUpEventNames } from '@monolith/legacy/services/analytics/events/sign-up/common/event-names';
import SignUpEvent from '@monolith/legacy/services/analytics/events/sign-up/sign-up-event';
import http from '@monolith/legacy/services/api/http';
import { patchQualificationQuestionsFromModel } from '@monolith/legacy/services/api/qualification-questions';
import { fetchQuestions, getQuestionsValidations } from '@monolith/legacy/services/signup/qualification-questions';
import { mapGetters } from 'vuex';
import { ANKORSTART, ANKORSTART_YES } from '@monolith/legacy/components/sign-up/questions/constants';
import { QuestionType } from '@monolith/legacy/types/question';
import { captureException } from '@core/plugins/sentry';
import LoaderIcon from '@monolith/legacy/components/loader-icon.vue';
import QuestionItem from '@monolith/legacy/components/sign-up/questions/question-item.vue';
import useVuelidate from '@vuelidate/core';

const STEP = 0;

export default defineComponent({
  name: 'RegisterForm',
  components: { AkButton, LoaderIcon, QuestionItem },
  props: {
    deviceId: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['success'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      model: {},
      questions: [],
      loadingQuestions: true,
      errors: {},
      isAnkorstart: false,
      sending: false,
      isSuggestionSelected: false,
      FeatureFlag: FeatureFlag,
    };
  },
  validations() {
    return {
      model: getQuestionsValidations(this.questions),
    };
  },
  computed: {
    ...mapGetters('signUp', ['email']),
    isRetailerAnkorstart(): boolean {
      const ankorstartQuestion = this.questions.find((question) => question.alias === ANKORSTART);
      return !!ankorstartQuestion?.answers.find(
        ({ id, alias }) => alias === ANKORSTART_YES && id === this.model[ANKORSTART]?.answer_id
      );
    },
  },
  async created() {
    try {
      this.questions = await fetchQuestions(STEP, this.$isEnabled(FeatureFlag.RegistrationFunnelMockedData));
    } catch (errors) {
      this.errors = errors;
    }
    this.loadingQuestions = false;
    Analytics.track(
      new SignUpEvent(SignUpEventNames.GeneralInfoViewedEvent, {
        type: 'Element Viewed',
      })
    );
  },
  methods: {
    getRegistrationModel() {
      const result = {};
      for (const item in this.model) {
        if (this.model[item].isRegister) {
          result[item] = this.model[item].content;
        }
      }
      return result;
    },
    getQuestionModel() {
      const result = {};
      for (const item in this.model) {
        if (!this.model[item].isRegister && this.model[item].answer_id) {
          result[item] = {
            content: this.model[item].content,
            answer_id: this.model[item].answer_id,
          };
        }
      }
      return result;
    },
    updateModel(alias, content, isRegister?) {
      const hadAlias = !!this.model[alias];
      this.model[alias] = { ...content, isRegister: !!isRegister };
      if (hadAlias && this.v$.model[alias]) {
        this.v$.model[alias].$touch();
      }
    },
    retailerQualificationPatch() {
      patchQualificationQuestionsFromModel(STEP, this.getQuestionModel())
        .then(() => {
          this.$emit('success', {
            isAnkorstart: this.isAnkorstart,
            ...this.model,
          });
        })
        .catch((errors) => {
          this.errors = errors;
        })
        .finally(() => {
          this.sending = false;
        });
    },
    async checkPromocode() {
      let isValid = true;
      const question = this.questions?.find(({ question_type }) => question_type === QuestionType.Promocode);
      const alias = question?.alias;
      if (!alias) {
        return isValid;
      }
      const promocodeModel = this.model[alias];
      if (promocodeModel?.content?.length) {
        if (!promocodeModel.brand) {
          const searchPromoCodeResult = await this.$refs[QuestionType.Promocode][0].$refs.QuestionPromocode.searchPromoCode();
          isValid = searchPromoCodeResult?.valid;
        }
      }
      return isValid;
    },
    async isValid() {
      this.v$.$touch();
      return (await this.checkPromocode()) && !this.v$.model.$invalid;
    },
    async onSubmit() {
      this.v$.$touch();
      const validForm = await this.isValid();
      if (!validForm) {
        Analytics.track(
          new SignUpEvent(SignUpEventNames.GeneralInfoMandatoryEvent, {
            type: 'Element Viewed',
          })
        );
        return;
      }
      this.errors = {};
      this.isAnkorstart = this.isRetailerAnkorstart;

      this.sending = true;
      const requestConfig = isEnabled(FeatureFlag.Ravelin)
        ? {
            headers: {
              'X-Device-Id': this.deviceId,
            },
          }
        : {};
      http()
        .post(
          '/auth/register',
          {
            email: this.email,
            is_ankorstart: this.isAnkorstart,
            ...this.getRegistrationModel(),
          },
          requestConfig
        )
        .then(() => {
          Analytics.track(
            new SignUpEvent(SignUpEventNames.GeneralInfoSubmittedEvent, {
              type: 'Click or Form Submit',
            })
          );

          window.dataLayer?.push({
            event: 'register',
          });

          this.retailerQualificationPatch();
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.errors) {
            this.errors = error.response.data.errors;
            Analytics.track(
              new SignUpEvent(SignUpEventNames.GeneralInfoMandatoryEvent, {
                type: 'Element Viewed',
                errors: error.response.data.errors,
              })
            );
          } else {
            this.errors = {
              global: this.$t('Cannot log in. Please refresh and retry!'),
            };
          }
          this.sending = false;
          return captureException('Registration error' + error);
        });
    },
  },
});
</script>

<style lang="scss">
@import '@css/vue-import';

.pac-container {
  z-index: 10000;
}

.register-form {
  .form-group select {
    padding: 0 10px;
  }

  .qualification-form-label {
    font-size: 12px;
  }
}
</style>

<style lang="scss" scoped>
.content {
  margin-top: 20px;
}
</style>
