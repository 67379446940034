import { deactivateIntegration, getActivePublicIntegration, patchIntegration, listExternalIntegrations } from '@monolith/legacy/services/api/public-orders-integration';
import {
  IntegrationPayload,
  IntegrationType,
  IntegrationConfiguration,
  IntegrationAvailableOrderStatus,
  IntegrationStatus,
} from '@monolith/legacy/types/integration';

export enum PublicIntegrationsMutations {
  UNINSTALL_INTEGRATION = 'UNINSTALL_INTEGRATION',
  SET_INTEGRATION = 'SET_INTEGRATION',
}

interface PublicIntegrationState {
  isEnabled: boolean;
  integrationId: string;
  accessToken: string;
  orderTags: string[];
  configuration: IntegrationConfiguration | null;
}
type State = {
  [key in Lowercase<IntegrationType>]?: PublicIntegrationState;
};

const defaultConfiguration = {
  order_default_financial_status: IntegrationAvailableOrderStatus.authorized,
  inventory_policy: null,
  inventory_synchronization: false,
  catalog_synchronization: false,
  catalog_continuous_synchronization: false,
};
const initialState: State = {
  shopify: {
    isEnabled: false,
    accessToken: null,
    integrationId: null,
    orderTags: [],
    configuration: defaultConfiguration,
  },
  woocommerce: {
    isEnabled: false,
    accessToken: null,
    integrationId: null,
    orderTags: [],
    configuration: null,
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    [PublicIntegrationsMutations.SET_INTEGRATION](state: State, payload: IntegrationPayload) {
      state[payload.integrationType.toLowerCase().replace('_', '')] = {
        isEnabled: true,
        integrationId: payload.integrationId,
        accessToken: payload.accessToken,
        orderTags: payload.orderTags,
        configuration: payload.configuration || null,
      };
    },
    [PublicIntegrationsMutations.UNINSTALL_INTEGRATION](state: State, { integrationType }: { integrationType: IntegrationType }) {
      const defaultIntegration = {
        isEnabled: false,
        accessToken: null,
        integrationId: null,
        orderTags: [],
        configuration: null,
      };

      defaultIntegration.configuration = integrationType === IntegrationType.SHOPIFY ? defaultConfiguration : null;

      state[integrationType.toLowerCase().replace('_', '')] = defaultIntegration;
    },
  },
  actions: {
    async getActiveIntegration({ commit }) {
      const data = await getActivePublicIntegration();
      if (data?.length > 0) {
        const integration = {
          integrationType: data[0].platformName,
          integrationId: data[0].id,
          accessToken: data[0].accessToken,
          orderTags: data[0].orderTags,
          configuration: data[0].configuration,
        };
        commit(PublicIntegrationsMutations.SET_INTEGRATION, integration);
      }
    },
    async getEnabledIntegrations({ commit }) {
      const data = await listExternalIntegrations();
      for (const integration of data) {
        if (integration.status === IntegrationStatus.ENABLED) {
          integration.platform = integration.platform === 'shopify' ? IntegrationType.SHOPIFYSALESCHANNEL : integration.platform;
          integration.platform = integration.platform === 'woocommerce' ? IntegrationType.WOOCOMMERCEPLUGIN : integration.platform;
          commit(PublicIntegrationsMutations.SET_INTEGRATION, {
            integrationType: integration.platform,
            integrationId: null,
            accessToken: null,
            orderTags: [],
            configuration: null,
          });
        }
      }
    },
    async uninstallIntegration({ commit, getters }, integrationType: IntegrationType) {
      const integrationId = getters.getIntegrationId(integrationType);
      await deactivateIntegration(integrationId);
      commit(PublicIntegrationsMutations.UNINSTALL_INTEGRATION, { integrationType });
    },
    async updateIntegration(
      { commit, getters },
      payload: {
        integrationType: IntegrationType;
        integrationOrderTags: string[];
        configuration: IntegrationConfiguration | null;
      }
    ) {
      const integrationId = getters.getIntegrationId(payload.integrationType);

      const result = await patchIntegration(integrationId, payload.integrationOrderTags, payload.configuration);

      // Only commit when response returns 200
      if (!result.errors) {
        commit(PublicIntegrationsMutations.SET_INTEGRATION, {
          integrationType: payload.integrationType,
          accessToken: result.accessToken,
          integrationId: result.id,
          orderTags: result.orderTags,
          configuration: result.configuration,
        });
      }

      return result;
    },
  },
  getters: {
    isActive: (state: State): ((integrationType: IntegrationType) => boolean) => {
      return (integrationType) => !!state[integrationType.toLowerCase().replace('_', '')]?.isEnabled;
    },
    isIntegrationActive: (_state, getters): boolean => {
      const integrations = Object.keys(IntegrationType);
      return !!integrations.find((integration) => getters.isActive(integration));
    },
    isAnyIntegrationActive: (state: State): boolean => {
      for (const integration in state) {
        if (state[integration].isEnabled) {
          return true;
        }
      }
      return false;
    },
    getIntegrationId:
      (state: State) =>
      (integrationType): string => {
        return state[integrationType.toLowerCase().replace('_', '')].integrationId;
      },
    getIntegrationOrderTags:
      (state: State) =>
      (integrationType): string => {
        return state[integrationType.toLowerCase().replace('_', '')].orderTags;
      },
    getIntegrationConfiguration:
      (state: State) =>
      (integrationType): string => {
        return state[integrationType.toLowerCase().replace('_', '')].configuration;
      },
  },
};
