<template>
  <div
    class="pill"
    data-testid="pill"
  >
    <div
      class="tooltip__trigger"
      data-testid="pillTrigger"
      @mouseover="trackHoverTooltip"
    >
      <AkParagraph
        weight="bold"
        size="sm"
      >
        {{ content.title }}
      </AkParagraph>
      <AkIcon
        class="symbol"
        symbol="chevron-down"
      />
    </div>
    <div class="tooltip">
      <div class="tooltip-box">
        <div class="tooltip-box__container">
          <AkParagraph size="sm">
            {{ content.description }}
          </AkParagraph>
        </div>
        <div
          v-if="content.link_to_brand"
          class="tooltip-box__link"
        >
          <RouterLink
            class="tooltip-box__brand-link"
            data-testid="pillBrandLink"
            :to="content.link_to_brand"
            @click="trackBrandClick"
          >
            {{ t('global.btn.seeBrandLink') }}
          </RouterLink>
        </div>
        <div
          v-if="showActionType"
          data-testid="pillAction"
          class="tooltip-box__action"
          :class="{ 'tooltip-box__action--copy': content.action_type === actionTypes.COPY }"
          @click="copyValue"
        >
          <AkIcon :symbol="isCopied ? 'check2-circle' : 'copy-keyboard'" />
          <template v-if="isCopied">
            {{ content.feedback }}
          </template>
          <template v-else>
            {{ content.action_label }}
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue';

import { AkParagraph, AkIcon } from '@ankorstore/design-system';

import { HeaderPills, actionTypes } from '@monolith/legacy/types/api/pill';

import Analytics from '@monolith/legacy/services/analytics';
import UserClick from '@monolith/legacy/services/analytics/events/user-click.event';
import { SectionCreatedEvent } from '@monolith/legacy/services/analytics/events/section_created.event';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Pill',
  components: {
    AkParagraph,
    AkIcon,
  },
  props: {
    content: {
      type: Object as () => HeaderPills['pill'],
      required: true,
    },
  },
  setup(props) {
    const isCopied = ref(false);

    const { t } = useI18n();

    const hasTriggered = ref(false);

    const showActionType = computed((): boolean => {
      return !!props.content.action_type;
    });

    function copyValue(): void {
      navigator.clipboard.writeText(props.content.action_label);
      isCopied.value = true;

      setTimeout(() => {
        isCopied.value = false;
      }, 3000);

      trackCopyPromocode();
    }

    function trackCopyPromocode(): void {
      Analytics.track(
        new UserClick({
          component: 'acquisition_offer_header_pill',
          action: 'copy_promocode',
          promocode: props.content.action_label,
        })
      );
    }

    function trackBrandClick(): void {
      Analytics.track(
        new UserClick({
          component: 'acquisition_offer_header_pill',
          action: 'click_brand_link',
          promocode: props.content.action_label,
        })
      );
    }

    function trackHoverTooltip(): void {
      if (!hasTriggered.value) {
        Analytics.track(
          new SectionCreatedEvent({
            value_proposition: 'hover_acquisition_offer_header_pill_promocode',
            promocode: props.content.action_label,
          })
        );
        hasTriggered.value = true;
      }
    }

    function trackSection(): void {
      Analytics.track(
        new SectionCreatedEvent({
          component: 'acquisition_offer_header_pill',
          action: 'display_promocode',
          value_proposition: 'display_acquisition_offer_header_pill_promocode',
          promocode: props.content.action_label,
        })
      );
    }

    onMounted(() => {
      trackSection();
    });

    return {
      actionTypes,
      isCopied,
      showActionType,
      copyValue,
      trackBrandClick,
      trackCopyPromocode,
      trackHoverTooltip,
      t,
    };
  },
});
</script>
<style lang="scss" scoped>
.pill {
  @apply ds-relative;
  &:hover {
    .symbol {
      @apply ds-rotate-180;
    }
    .tooltip-box {
      @apply ds-block;
    }
  }
}
.tooltip__trigger {
  @apply ds-flex ds-gap-2 ds-items-center ds-cursor-pointer ds-h-5;
}
.symbol {
  @apply ds-text-neutral-500;
}

.tooltip {
  @apply ds-absolute ds-z-10 ds-right-0 ds-pt-4;
}

.tooltip-box {
  @apply ds-p-5 ds-bg-warm-white ds-rounded-md ds-hidden ds-border ds-border-solid ds-border-neutral-300;
  min-width: 280px;
  &__container {
    @apply ds-text-neutral-900 ds-text-left ds-mb-4;
  }
  &__link {
    @apply ds-underline ds-text-left ds-mb-4;
  }
  &__action {
    @apply ds-flex ds-bg-white ds-p-2 ds-text-base ds-font-bold ds-rounded-md ds-gap-2;
    width: fit-content;
    &--copy {
      @apply ds-cursor-pointer;
    }
  }
}
</style>
